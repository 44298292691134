<template>
  <article class="modal-card" ref="modal">
    <b-loading v-if="fetchSubstituteRulesLoading || isLoading" :is-full-page="false" :active="true" />
    <template v-else>
      <header class="modal-card__header">
        <h1>{{ productLabel }} substitute management</h1>
      </header>
      <SubstituteRulesLocationsFilter
        v-show="filteredLocations.length > 0 || filteredSubstituteRules.length > 1"
        ref="substituteRulesLocationsFilter"
        :currentLocation="currentLocation"
        :filteredLocations="filteredLocations"
        @location-filter:confirm="onFilter"
      />

      <section class="modal-card__content">
        <template v-if="!substituteRules || !substituteRules.length">
          Nothing yet, create a new rule to start.
        </template>
        <template v-else>
          <ul class="substitute-rules">
            <li v-for="substituteRule in filteredSubstituteRules" :key="substituteRule.uuid" class="substitute-rule">
              <b-button
                size="is-small"
                type="is-danger"
                class="delete-substitute-rule-btn"
                icon-left="close"
                aria-label="remove substitute rule"
                @click="openDeleteSubstituteRuleModal(substituteRule.uuid)"
              />
              <SubstituteRuleForm
                :providerLabel="providerLabel"
                :disabled="isDisabled(substituteRule.uuid)"
                :hasChanged="currentChange === substituteRule.uuid"
                :substituteRuleForm="substituteRulesForms[substituteRule.uuid]"
                :selectedKitchens="substituteRulesForms[substituteRule.uuid].locationUuids"
                :selectedSubstitutes="substituteRulesForms[substituteRule.uuid].substitutes"
                :searchProductSubstitutes="substituteRulesForms[substituteRule.uuid].search"
                :searchLoading="substituteRulesForms[substituteRule.uuid].searchLoading"
                :searchMoreLoading="substituteRulesForms[substituteRule.uuid].searchMoreLoading"
                :productSubstitutesOptions="substituteRulesForms[substituteRule.uuid].productSubstitutesOptions"
                :kitchenOptions="substituteRulesForms[substituteRule.uuid].kitchenOptions"
                :mainBaseUnit="mainBaseUnit"
                :productUuid="productUuid"
                @update:search-product-substitutes="updateSearch($event, substituteRule.uuid)"
                @update:substitute-rule-form="updateSubstituteRuleForm($event, substituteRule.uuid)"
                @next-page="loadMoreProductSubstitutes(substituteRule.uuid)"
                @reset="resetForm(substituteRule.uuid)"
                @save-rule="onSaveRule(substituteRule.uuid)"
              />
            </li>
          </ul>
        </template>
      </section>
      <footer class="modal-card__footer">
        <b-button
          :type="currentChange ? 'is-light' : 'is-info'"
          :disabled="currentChange || hasUnsavedRule"
          @click="addSubstituteRule"
        >
          Add substitute rule
        </b-button>
        <b-button type="is-info" outlined @click="$emit('close')">Close</b-button>
      </footer>
    </template>
  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SubstituteRuleForm from '@/components/SubstitutesManagement/SubstituteRuleForm';
import DeleteSubstituteRuleModal from '@/components/SubstitutesManagement/DeleteSubstituteRuleModal';
import SubstituteRulesLocationsFilter from '@/components/SubstitutesManagement/SubstituteRulesLocationsFilter';
import { uniqueId, keyBy, difference, cloneDeep, isEqual } from 'lodash';

export default {
  name: 'SubstituteManagementModal',
  components: { SubstituteRuleForm, SubstituteRulesLocationsFilter },
  props: {
    productLabel: {
      type: String,
      required: true,
    },
    productUuid: {
      type: String,
      required: true,
    },
    providerUuid: {
      type: String,
      required: true,
    },
    providerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mainBaseUnit: null,
      isLoading: false,
      currentChange: null,
      substituteRules: [],
      productSubstitutes: [],
      filteredLocations: [],
      locationsAlreadyUsed: [],
      substituteRulesForms: {},
    };
  },
  computed: {
    ...mapState({
      fetchSubstituteRulesLoading: (state) => state.substitutesManagement.fetchSubstituteRules.pending,
      fetchLoadingProductSubstitutes: (state) => state.substitutesManagement.fetchProductSubstitutes.pending,
      parentLocations: (state) => state.locations.all,
      location: (state) => state.locations.current,
      currentSupplier: (state) => state.suppliers.current,
      currentHub: (state) => state.hubs.current,
      currentProduct: (state) => state.products.current,
    }),
    ...mapGetters('locations', {
      getLocationByUuid: 'getLocationByUuid',
    }),
    providerLabel() {
      return this.providerType === 'SUPPLIER' ? this.currentSupplier.label : this.currentHub.label;
    },
    substituteRulesByUuid() {
      return keyBy(this.substituteRules, 'uuid');
    },
    searchParams() {
      return {
        locationUuid: this.location.parent_uuid ?? this.location.uuid,
        providerType: this.providerType,
        providerUuid: this.providerUuid,
        perPage: 20,
      };
    },
    filteredSubstituteRules() {
      if (!this.filteredLocations.length) {
        return this.substituteRules;
      }

      const locationUuidsMap = keyBy(this.filteredLocations, 'uuid');
      return this.substituteRules.filter((substituteRule) =>
        substituteRule.locationUuids.some(({ uuid }) => !!locationUuidsMap[uuid])
      );
    },
    currentLocation() {
      if (this.location && this.location.parent_uuid) {
        const parent = this.parentLocations.find(({ uuid }) => uuid === this.location.parent_uuid);

        return parent;
      }
      return { locations: this.location.locations };
    },
    hasUnsavedRule() {
      return this.substituteRules.some(({ unsaved }) => unsaved);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([this.fetchOne(this.productUuid), this.fetchAllLocations()]);
      const { base_unit } = this.currentProduct;
      this.mainBaseUnit = base_unit;
      const { items } = await this.getProductSubstitutes({
        ...this.searchParams,
        page: 1,
      });

      this.productSubstitutes = items;

      await this.fetchSubstituteRules();
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions('substitutesManagement', ['getSubstituteRules', 'getProductSubstitutes', 'updateSubstituteRules']),
    ...mapActions('locations', { fetchAllLocations: 'fetchAllUnnested' }),
    ...mapActions('products', ['fetchOne']),
    resetForm(uuid) {
      const find = this.substituteRules.find((substituteRule) => substituteRule.uuid === uuid);
      if (!find) {
        return;
      }

      this.substituteRulesForms = {
        ...this.substituteRulesForms,
        [uuid]: this.setForm({ locationUuids: find.locationUuids, substitutes: find.substitutes }),
      };

      this.onUpdateSubstituteRuleForm(uuid);
    },
    updateSubstituteRuleForm(rule, uuid) {
      this.substituteRulesForms = {
        ...this.substituteRulesForms,
        [uuid]: rule,
      };

      this.onUpdateSubstituteRuleForm(uuid);
    },
    onUpdateSubstituteRuleForm(uuid) {
      const find = this.substituteRules.find((substituteRule) => substituteRule.uuid === uuid);
      const substituteRule = {
        locationUuids: find.locationUuids,
        substitutes: find.substitutes,
      };
      const formSubsituteRule = {
        locationUuids: this.substituteRulesForms[uuid].locationUuids,
        substitutes: this.substituteRulesForms[uuid].substitutes,
      };

      if (this.currentChange === uuid && isEqual(substituteRule, formSubsituteRule)) {
        this.currentChange = null;
      } else if (!isEqual(substituteRule, formSubsituteRule)) {
        this.currentChange = uuid;
      }
    },
    isDisabled(uuid) {
      if (!this.currentChange) {
        return false;
      }

      return uuid !== this.currentChange;
    },
    onFilter(locations) {
      this.filteredLocations = locations;

      this.$refs.filter.toggle();
    },
    updateSearch(searchValue, uuid) {
      this.substituteRulesForms = {
        ...this.substituteRulesForms,
        [uuid]: {
          ...this.substituteRulesForms[uuid],
          search: searchValue,
          searchPage: 1,
          searchLastPage: null,
          searchLoading: true,
        },
      };

      this.getProductSubstitutes({
        ...this.searchParams,
        search: searchValue,
        page: 1,
      })
        .then(({ items, last_page }) => {
          this.substituteRulesForms = {
            ...this.substituteRulesForms,
            [uuid]: {
              ...this.substituteRulesForms[uuid],
              searchLastPage: last_page,
              productSubstitutesOptions: [...items],
            },
          };
        })
        .finally(() => {
          this.substituteRulesForms = {
            ...this.substituteRulesForms,
            [uuid]: {
              ...this.substituteRulesForms[uuid],
              searchLoading: false,
            },
          };
          this.debounceSearch = null;
        });
    },
    async loadMoreProductSubstitutes(uuid) {
      if (this.substituteRulesForms[uuid].searchMoreLoading) {
        return;
      }
      const { search, searchPage, searchLastPage, productSubstitutesOptions } = this.substituteRulesForms[uuid];
      if (searchLastPage && searchLastPage <= searchPage) {
        return;
      }
      try {
        this.substituteRulesForms[uuid].searchMoreLoading = true;
        const { items, last_page } = await this.getProductSubstitutes({
          ...this.searchParams,
          search,
          page: searchPage + 1,
        });

        this.substituteRulesForms = {
          ...this.substituteRulesForms,
          [uuid]: {
            ...this.substituteRulesForms[uuid],
            searchPage: searchPage + 1,
            searchLastPage: last_page,
            productSubstitutesOptions: [...productSubstitutesOptions, ...items],
          },
        };
      } finally {
        this.substituteRulesForms[uuid].searchMoreLoading = false;
      }
    },
    addSubstituteRule() {
      const uuid = uniqueId();

      this.substituteRules.push({
        uuid,
        locationUuids: [],
        substitutes: [],
        unsaved: true,
      });

      this.substituteRulesForms = {
        ...this.substituteRulesForms,
        [uuid]: this.setForm({ locationUuids: [], substitutes: [] }),
      };
    },
    openDeleteSubstituteRuleModal(uuid) {
      const { locationUuids, unsaved } = this.substituteRulesByUuid[uuid];
      const locations = locationUuids.map(({ uuid }) => uuid);

      if (unsaved) {
        this.substituteRules = this.substituteRules.filter(
          ({ uuid: substituteRuleUuid }) => uuid !== substituteRuleUuid
        );
        delete this.substituteRulesForms[uuid];
        if (this.currentChange === uuid) {
          this.currentChange = null;
        }
        return;
      }

      const modal = this.$buefy.modal.open({
        component: DeleteSubstituteRuleModal,
        props: {
          isLoading: false,
        },
        events: {
          confirm: async () => {
            try {
              modal.props.isLoading = true;
              modal.$forceUpdate();

              await this.deleteSubstituteRule(locations);

              this.$buefy.toast.open({
                type: 'is-success',
                message: 'Substitute rule deleted successfully',
              });
              this.fetchSubstituteRules();
              modal.props.isLoading = false;
              modal.$forceUpdate();
              modal.close();
            } catch (err) {
              this.$buefy.toast.open({
                type: 'is-danger',
                duration: 2000,
                message: 'Unable to delete the substitute rule',
              });
            }
          },
        },
      });
    },
    deleteSubstituteRule(locationUuids) {
      return this.updateSubstituteRules({
        location_uuids: locationUuids,
        substitutes: [],
        product_uuid: this.productUuid,
        provider_type: this.providerType.toUpperCase(),
        provider_uuid: this.providerUuid,
      });
    },
    async checkRemovedLocation(uuid, rule) {
      if (!this.substituteRulesByUuid[uuid]) {
        return;
      }
      const previousLocations = this.substituteRulesByUuid[uuid].locationUuids.map(({ uuid }) => uuid);
      if (!previousLocations.length) {
        return;
      }
      const newLocations = rule.locationUuids.map(({ uuid }) => uuid);
      const locationsToreset = difference(previousLocations, newLocations);

      if (locationsToreset.length) {
        await this.deleteSubstituteRule(locationsToreset);
      }
    },
    async onSaveRule(uuid) {
      const { locationUuids, substitutes } = this.substituteRulesForms[uuid];

      try {
        await this.checkRemovedLocation(uuid, this.substituteRulesForms[uuid]);
        await this.updateSubstituteRules({
          location_uuids: locationUuids.map(({ uuid }) => uuid),
          substitutes: substitutes.map(
            ({ enabled, priority, pricingStrategy: pricing_strategy, product: { uuid: product_uuid } }) => {
              return {
                enabled,
                pricing_strategy,
                priority,
                product_uuid,
              };
            }
          ),
          product_uuid: this.productUuid,
          provider_type: this.providerType.toUpperCase(),
          provider_uuid: this.providerUuid,
        });
        this.$buefy.toast.open({
          type: 'is-success',
          duration: 2000,
          message: 'Substitute rule saved',
        });
        this.fetchSubstituteRules();
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 2000,
          message: 'Unable to save substitute rules',
        });
      }
    },
    formatSubstitutes(substitute) {
      const { enabled, pricing_strategy: pricingStrategy, priority, product } = substitute;

      return {
        enabled,
        pricingStrategy,
        priority,
        product,
      };
    },
    formatLocations(locationUuid) {
      const location = this.getLocationByUuid(locationUuid);
      let label = 'unknown';

      if (location) {
        label = location.label;
      }

      return {
        label,
        uuid: locationUuid,
      };
    },
    formatSubstituteRule(substituteRule) {
      const { location_uuids, substitutes: preFormattedSubstitutes } = substituteRule;
      const formattedLocations = location_uuids.map(this.formatLocations);
      const formattedSubstitutes = preFormattedSubstitutes.map(this.formatSubstitutes);

      return {
        uuid: uniqueId('substitute-rule'),
        locationUuids: formattedLocations,
        substitutes: formattedSubstitutes,
      };
    },
    setKitchenOptions(locationUuids) {
      const options = { ...this.currentLocation };
      const currentSelectedLocationUuids = locationUuids.map(({ uuid }) => uuid);

      options.locations = options.locations.map((location) => {
        return {
          ...location,
          disabled:
            this.locationsAlreadyUsed.includes(location.uuid) && !currentSelectedLocationUuids.includes(location.uuid),
        };
      });

      return options;
    },
    setForm({ locationUuids, substitutes }) {
      return {
        locationUuids: [...locationUuids],
        substitutes: cloneDeep(substitutes),
        kitchenOptions: this.setKitchenOptions(locationUuids),
        isLoading: false,
        searchLastPage: null,
        searchPage: 1,
        search: '',
        searchLoading: false,
        searchMoreLoading: false,
        productSubstitutesOptions: [...this.productSubstitutes],
        isEdit: true,
      };
    },
    setForms(substituteRules) {
      return substituteRules.reduce((forms, substituteRule) => {
        forms[substituteRule.uuid] = this.setForm({
          locationUuids: substituteRule.locationUuids,
          substitutes: substituteRule.substitutes,
        });

        return forms;
      }, {});
    },
    async fetchSubstituteRules() {
      try {
        this.currentChange = null;

        const preFormattedSubstituteRules = await this.getSubstituteRules({
          locationUuid: this.location.parent_uuid ?? this.location.uuid,
          productUuid: this.productUuid,
          providerType: this.providerType.toUpperCase(),
          providerUuid: this.providerUuid,
        });

        const childrenLocationUuids = this.currentLocation.locations.map(({ uuid }) => uuid);
        const preFormattedSubstituteRulesFiltered = preFormattedSubstituteRules.reduce((rules, substituteRule) => {
          const filteredLocations = substituteRule.location_uuids.filter((locationUuid) =>
            childrenLocationUuids.includes(locationUuid)
          );
          if (!filteredLocations.length) {
            return rules;
          }

          return [
            ...rules,
            {
              ...substituteRule,
              location_uuids: filteredLocations,
            },
          ];
        }, []);
        this.substituteRules = preFormattedSubstituteRulesFiltered.map(this.formatSubstituteRule);
        this.locationsAlreadyUsed = this.substituteRules.reduce((locations, { locationUuids }) => {
          locations = [...locations, ...locationUuids.map(({ uuid }) => uuid)];
          return locations;
        }, []);
        this.substituteRulesForms = this.setForms(this.substituteRules);
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 2000,
          message: `Unable to fetch substitute rules`,
        });
        this.$errorReporting.report(err);
      }
    },
    onResize() {
      this.$refs.substituteRulesLocationsFilter.updateDropdownContentPosition();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  position: relative;
  background: #fff;
  border: 0.5px solid #02091d;
  border-radius: 6px;
  min-height: 327px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    width: 750px;
  }

  .inner {
    width: 100%;
  }

  &__header {
    font-size: 18px;
  }

  &__header,
  &__footer {
    padding: 16px 24px;
  }

  &__header {
    border-bottom: 0.5px solid #74767b;
  }

  &__content {
    min-height: 200px;
    max-height: 75vh;
    overflow-y: auto;
    padding: 24px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.substitute-rules {
  gap: 30px;
}

.substitute-rule {
  position: relative;
  border: 1px dashed #02091d;
  border-radius: 6px;
  padding: 16px 24px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .delete-substitute-rule-btn {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
</style>
