<template>
  <article class="modal-card">
    <section class="inner">
      <header>
        <h1 class="subtitle">Shipping policies</h1>
      </header>
      <article v-show="filteredLocations.length > 0 || filteredPolicies.length > 1" class="location-filter">
        <b-dropdown ref="filter" expanded @active-change="(active) => (filterActive = active)">
          <template #trigger>
            <button
              id="filter-button"
              class="button is-fullwidth is-taster-primary"
              :class="{ 'is-outlined': filterActive }"
            >
              <span class="icon">
                <i class="mdi mdi-filter" />
              </span>
              <span v-show="filteredLocations.length === 0">Filter</span>
              <span v-show="filteredLocations.length > 0">Filter ({{ filteredLocations.length }})</span>
            </button>
          </template>
          <b-dropdown-item custom focusable>
            <LocationFilter
              :location-root="currentLocation"
              :selected-locations="filteredLocations"
              @location-filter:confirm="onFilter"
            />
          </b-dropdown-item>
        </b-dropdown>
      </article>
      <article v-if="listingPolicies" class="loading">
        <b-skeleton width="80%" />
        <b-skeleton width="40%" />
        <b-skeleton width="60%" />
      </article>
      <article v-else class="shipping-policy-rows" :class="{ 'has-no-rows': filteredPolicies.length === 0 }">
        <template v-if="filteredPolicies.length > 0">
          <div v-for="{ index, inner } in filteredPolicies" class="shipping-policy-row" :key="index">
            <ShippingPolicyInput
              :location-root="currentLocation"
              :policy="inner"
              :loading="inner.loading || false"
              :success="inner.success || false"
              @shipping-policy:save="(policy) => onSavePolicy(index, policy)"
            />
            <button class="remove" @click="() => onRemovePolicy(index)">&cross;</button>
          </div>
        </template>
        <template v-else>
          <p class="nothing">Nothing yet</p>
        </template>
      </article>
      <footer>
        <b-button type="is-taster-primary" @click="onAddPolicy">Add shipping policy</b-button>
        <b-button type="is-taster-primary" outlined @click="$emit('close')">Close</b-button>
      </footer>
    </section>
  </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ConfirmModal from '@/components/ShippingPolicy/ConfirmModal.vue';
import LocationFilter from '@/components/ShippingPolicy/LocationFilter.vue';
import ShippingPolicyInput from '@/components/ShippingPolicy/ShippingPolicyInput.vue';

function belongToCountry(country, location) {
  if (country.parent_uuid || country.locations.length === 0) {
    return false;
  }

  const children = new Set(country.locations.map(({ uuid }) => uuid));
  return children.has(location);
}

function includedInCountry(country, locations) {
  if (country.parent_uuid || country.locations.length === 0) {
    return false;
  }

  const children = new Set(country.locations.map(({ uuid }) => uuid));
  for (const location of locations) {
    if (children.has(location)) {
      return true;
    }
  }

  return false;
}

export default {
  name: 'EditShippingPolicies',
  components: {
    LocationFilter,
    ShippingPolicyInput,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    providerType: {
      type: String,
      required: true,
      validator(value) {
        const lower = value.toLowerCase();
        return lower === 'hub' || lower === 'supplier';
      },
    },
  },
  data() {
    return {
      filterActive: false,
      filteredLocations: [],
      policies: [],
    };
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      listingPolicies: (state) => state.shippingPolicies.listingPolicies,
    }),
    filteredPolicies() {
      return this.policies.map((inner, index) => ({ index, inner })).filter((envelope) => !envelope.inner.removed);
    },
  },
  mounted() {
    this.fetchShippingPolicies();
  },
  methods: {
    ...mapActions('shippingPolicies', [
      'listShippingPolicies',
      'addShippingPolicyToHub',
      'addShippingPolicyToSupplier',
      'removeShippingPolicyFromHub',
      'removeShippingPolicyFromSupplier',
      'updateHubShippingPolicy',
      'updateSupplierShippingPolicy',
    ]),
    fetchShippingPolicies() {
      const params = {};
      if (this.providerType === 'hub') {
        params.hubUuid = this.uuid;
      } else {
        params.supplierUuid = this.uuid;
      }

      if (this.filteredLocations.length > 0) {
        params.locationUuids = this.filteredLocations.map((location) => location.uuid);
      }

      this.policies = [];
      return this.listShippingPolicies(params).then((policies) => {
        this.policies = policies.filter((policy) => includedInCountry(this.currentLocation, policy.locations));
      });
    },
    onAddPolicy() {
      this.policies.push({
        ids: [],
        locations: this.filteredLocations.map((location) => location.uuid),
        actions: [],
        delivery_charge: null,
        delivery_charge_kind: null,
        delivery_charge_unit: null,
        threshold: null,
        threshold_type: null,
        threshold_unit: null,
      });
    },
    onFilter(locations) {
      this.filteredLocations = locations;

      this.$refs.filter.toggle();
      this.fetchShippingPolicies();
    },
    onRemovePolicy(index) {
      const policy = this.policies.find((_, idx) => index === idx);
      if (!policy) {
        return;
      }

      // If the policy is not even saved, we'll just removed from the list without confirmation
      if (policy.ids.length === 0) {
        this.policies = this.policies.map((policy, idx) => {
          if (index === idx) {
            policy.removed = true;
          }

          return policy;
        });
        return;
      }

      const modal = this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        canCancel: ['escape'],
        events: {
          confirm: async () => {
            const component = modal.$children[0];

            component.setPending(true);
            const policy = this.policies.find((_, idx) => index === idx);
            if (!policy) {
              return;
            }

            this.policies = this.policies.map((policy, idx) => {
              if (index === idx) {
                policy.removed = true;
              }

              return policy;
            });

            await this.deletePolicy(policy);
            component.setComplete(true);
          },
        },
      });
    },
    async onSavePolicy(index, policy) {
      this.policies = this.policies.map((policy, idx) => {
        if (index === idx) {
          policy.loading = true;
        }

        return policy;
      });

      // Saving new and existing shipping policies
      let ids = await this.savePolicy(policy);

      // Deleting unselected locations if necessary
      if (Array.isArray(policy.removedLocations) && policy.removedLocations.length > 0) {
        const removedIds = await this.deletePolicy(policy, true);
        removedIds.forEach((id) => {
          ids = ids.filter(({ uuid }) => uuid !== id.uuid);
        });
      }

      const timer = setTimeout(() => {
        if (!this.policies[index]) {
          return;
        }

        this.policies = this.policies.map((policy, idx) => {
          if (index === idx) {
            policy.success = false;
          }

          return policy;
        });
        clearTimeout(timer);
      }, 1000);
      this.policies = this.policies.map((policy, idx) => {
        if (index === idx) {
          policy.ids = ids;
          policy.loading = false;
          policy.success = true;
        }

        return policy;
      });
    },
    async savePolicy(policy) {
      const addAction = this.providerType === 'hub' ? this.addShippingPolicyToHub : this.addShippingPolicyToSupplier;
      const updateAction =
        this.providerType === 'hub' ? this.updateHubShippingPolicy : this.updateSupplierShippingPolicy;
      const currencyCode = this.currentLocation.location_country.currency_code;

      const ids = [];
      for (const locationUuid of policy.locations) {
        const id = policy.ids.find(({ location_uuid }) => location_uuid === locationUuid);
        const {
          actions,
          delivery_charge,
          delivery_charge_kind,
          delivery_charge_unit,
          threshold,
          threshold_type,
          threshold_unit,
        } = policy;

        const payload = {
          location_uuid: locationUuid,
          actions,
          threshold,
          threshold_currency_code: threshold_type === 'VOLUME' ? currencyCode : null,
          threshold_type,
          threshold_unit,
          delivery_charge,
          delivery_charge_currency_code: delivery_charge ? currencyCode : null,
          delivery_charge_kind,
          delivery_charge_unit,
        };

        if (id) {
          await updateAction({ uuid: this.uuid, policyUuid: id.uuid, payload });
          ids.push(id);
        } else {
          try {
            const res = await addAction({ uuid: this.uuid, payload });
            ids.push({ uuid: res.uuid, location_uuid: locationUuid });
          } catch (_) {
            // We ignore all conflict errors
          }
        }
      }

      return ids;
    },
    async deletePolicy(policy, partial = false) {
      const action =
        this.providerType === 'hub' ? this.removeShippingPolicyFromHub : this.removeShippingPolicyFromSupplier;

      const toRemove = policy.ids.filter(({ location_uuid }) => {
        const condition = belongToCountry(this.currentLocation, location_uuid);
        return partial ? condition && policy.removedLocations.includes(location_uuid) : condition;
      });

      for (const id of toRemove) {
        await action({ uuid: this.uuid, policyUuid: id.uuid });
      }

      return toRemove;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

.modal-card {
  background: #fff;
  border-radius: 8px;
  height: 100%;
  max-height: 80vh;
  min-width: 600px;

  header {
    border-bottom: 0.5px solid $taster-gray;
    padding-bottom: 8px;
    margin: 24px 32px 0px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin: 24px 32px;
  }

  section.inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    > article {
      padding: 0px 32px;
    }
  }

  .loading {
    margin: 16px 0px;
  }

  .location-filter {
    margin-top: 8px !important;
    margin-bottom: 16px !important;

    .dropdown::v-deep {
      .dropdown-menu {
        padding-top: 0px;

        .dropdown-content {
          border: solid 1px $taster-primary;
          border-top-width: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          box-shadow: none;
        }
      }

      &.is-active {
        #filter-button {
          background: transparent;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-bottom-width: 0px;
          box-shadow: none;

          &:active,
          &:focus,
          &:hover {
            color: $taster-primary;
          }
        }

        .dropdown-content {
          border-color: $taster-primary;
        }
      }
    }
  }

  .shipping-policy-rows {
    flex: 1;
    max-height: 656px;
    min-height: 384px;
    overflow-y: auto;

    padding-top: 16px !important;

    &.has-no-rows {
      overflow-y: hidden;
    }

    .nothing {
      color: #02091d;
      font-size: 16px;
      line-height: 16px;
    }

    .shipping-policy-row {
      position: relative;

      flex: none;
      border: dashed 1px #02091d;
      border-radius: 4px;
      margin: 16px 0px;
      padding: 8px 24px 16px 24px;

      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .remove {
        position: absolute;
        top: -12px;
        left: -12px;

        background: #de351b;
        border: none;
        border-radius: 100%;
        color: #fff;
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }
  }

  .subtitle {
    color: #02091d;
  }
}
</style>
