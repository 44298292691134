<template>
  <b-field grouped>
    <b-field expanded label-position="on-border" label="Price">
      <b-input
        type="number"
        step="0.001"
        v-model.number="itemPrice"
        size="is-small"
        :disabled="updateDisabled"
      ></b-input>
    </b-field>
    <b-field label-position="on-border" label="Currency">
      <b-input v-model="itemUnit" size="is-small" disabled>{{ currentLocation.location_country.currency }}</b-input>
    </b-field>
    <b-field>
      <b-button
        :disabled="!isModified"
        outlined
        icon-left="content-save"
        size="is-small"
        class="is-info"
        @click="save"
        v-if="isItemMenuEditor() && !updateDisabled"
      >
        Save
      </b-button>
    </b-field>
  </b-field>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['selling_price', 'unit', 'updateDisabled'],
  data: () => ({
    isModified: false,
  }),
  methods: {
    save() {
      this.isModified = false;
      this.$emit('save');
    },
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
    }),
    itemPrice: {
      get() {
        return this.selling_price;
      },
      set(value) {
        this.isModified = true;
        this.$emit('update:selling_price', value);
      },
    },
    itemUnit: {
      get() {
        return this.unit;
      },
      set(value) {
        this.isModified = true;
        this.$emit('update:unit', value);
      },
    },
  },
};
</script>

<style scoped>
div.field {
  margin-bottom: 0;
}
.fix-unit-field {
  width: 120px;
  margin-right: 0.75rem;
}
</style>
