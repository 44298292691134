<template>
  <ValidationObserver class="field" :class="{ 'has-errors': hasErrors() }" tag="div" v-slot="{ errors: globalErrors }">
    <label class="label" :class="{ 'has-text-taster-critical': globalErrors.kind && globalErrors.kind.length > 0 }">
      Delivery charges
    </label>
    <div class="inner">
      <ValidationProvider
        ref="typeProvider"
        class="cell"
        rules="required"
        :skip-if-empty="false"
        tag="div"
        vid="kind"
        v-slot="{ errors }"
      >
        <div class="control has-icons-right is-expanded">
          <div class="select is-fullwidth is-multiple" :class="{ 'is-taster-critical': errors.length > 0 }">
            <select v-model="kind" @blur="onBlur">
              <option :value="null" disabled hidden selected>Selection</option>
              <option value="FIXED">Constant</option>
              <option value="PER_UNIT">Per article</option>
              <option value="PER_WEIGHT">Per weight</option>
            </select>
            <div class="icon is-right">
              <i class="mdi mdi-chevron-down mdi-24px" />
            </div>
          </div>
        </div>
        <p v-show="errors.length > 0" class="help is-taster-critical">Select a delivery charge</p>
      </ValidationProvider>
      <ValidationProvider
        ref="valueProvider"
        class="cell"
        rules="required|min_value:0"
        tag="div"
        vid="value"
        v-slot="{ errors }"
      >
        <div class="control is-clearfix is-expanded">
          <div class="input has-addons-right" :class="{ 'is-taster-critical': errors.length > 0 }">
            <input v-model.number="value" type="number" @blur="onBlur" />
            <p class="addon is-right">
              {{ currency }}
            </p>
          </div>
        </div>
        <p v-show="errors.length > 0" class="help is-taster-critical">Set a positive number</p>
      </ValidationProvider>
      <template v-if="kind && kind !== 'FIXED'">
        <p class="cell is-narrow">
          <template v-if="articleUnit && kind === 'PER_UNIT'"> Per {{ articleUnit }} </template>
          <template v-else-if="kind === 'PER_WEIGHT'"> Per kg </template>
          <template v-else> Per </template>
        </p>
        <ValidationProvider
          v-if="!articleUnit && kind === 'PER_UNIT'"
          ref="unitProvider"
          class="cell"
          rules="required"
          tag="div"
          vid="unit"
          v-slot="{ errors }"
        >
          <div class="control is-clearfix is-expanded">
            <input
              v-model="unit"
              type="text"
              class="input"
              :class="{ 'is-taster-critical': errors.length > 0 }"
              @blur="onBlur"
            />
          </div>
          <p v-show="errors.length > 0" class="help is-taster-critical">Provide a unit</p>
        </ValidationProvider>
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'DeliveryChargeInput',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  model: {
    event: 'delivery-charge:input',
    prop: 'deliveryCharges',
  },
  props: {
    articleUnit: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      required: true,
    },
    deliveryCharges: {
      type: Object,
      default: () => ({
        kind: null,
        unit: null,
        value: null,
      }),
    },
  },
  computed: {
    value: {
      get() {
        return this.deliveryCharges.value;
      },
      set(value) {
        this.$emit('delivery-charge:input', { ...this.deliveryCharges, value });
      },
    },
    kind: {
      get() {
        return this.deliveryCharges.kind;
      },
      set(kind) {
        const patch = { ...this.deliveryCharges, kind };
        if (kind !== 'PER_UNIT') {
          patch.unit = null;
        }

        this.$emit('delivery-charge:input', patch);
      },
    },
    unit: {
      get() {
        return this.deliveryCharges.unit;
      },
      set(unit) {
        this.$emit('delivery-charge:input', { ...this.deliveryCharges, unit });
      },
    },
  },
  methods: {
    hasErrors() {
      if (!this.$refs.typeProvider || !this.$refs.valueProvider) {
        return false;
      }

      let invalid = this.$refs.typeProvider.errors.length > 0 || this.$refs.valueProvider.errors.length > 0;
      if (this.$refs.unitProvider) {
        invalid |= this.$refs.unitProvider.errors.length > 0;
      }

      return invalid;
    },
    onBlur() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

input[type='number'] {
  appearance: textfield;
}

.select.is-taster-critical select,
.input.is-taster-critical input,
input.is-taster-critical {
  color: $taster-critical;
}

.field {
  margin: 0px !important;

  &.has-errors {
    margin-bottom: -16px !important;
  }

  .inner {
    display: flex;
    gap: 16px;

    p.cell {
      align-items: flex-start;
      display: inline-flex;
      justify-content: center;
      padding-top: calc(0.375em - 1px);
    }

    .cell {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;

      &:first-child,
      &.is-narrow {
        flex: none;
      }
    }
  }

  .input {
    &.has-addons-right {
      padding-right: 2.25em;
    }

    input {
      background: transparent;
      border: none;
      font-size: 1em;
      outline: none;
      width: 100%;
    }

    .addon {
      position: absolute;
      top: 0px;

      padding: calc(0.375em - 1px);
      padding-right: calc(0.75em - 1px);
      user-select: none;
      z-index: 4;
    }

    .addon.is-right {
      color: #949494;
      right: 0px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

.help {
  line-height: 12px;
}
</style>
