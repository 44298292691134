import { shippingPolicyTypes } from '../mutation-types';
import hubsApi from '@/apis/products/hubs';
import shippingPoliciesApi from '@/apis/products/shippingPolicies';
import suppliersApi from '@/apis/products/suppliers';

export default {
  namespaced: true,
  state: {
    hubs: {},
    suppliers: {},
    addingPolicy: false,
    addingPolicyError: null,
    listingPolicies: false,
    listingPoliciesError: null,
    updatingPolicy: false,
    updatingPolicyError: null,
    removingPolicy: false,
    removingPolicyError: null,
  },
  mutations: {
    [shippingPolicyTypes.ADD_SHIPPING_POLICY_REQUEST](state) {
      state.addingPolicy = true;
      state.addingPolicyError = null;
    },
    [shippingPolicyTypes.ADD_SHIPPING_POLICY_SUCCESS](state, { hubUuid = null, supplierUuid = null, policy }) {
      if (!hubUuid && !supplierUuid) {
        throw new TypeError('You must provide a hub or supplier UUID to use this mutation');
      }

      if (hubUuid) {
        state.hubs[hubUuid] = state.hubs[hubUuid] ? [...state.hubs[hubUuid], policy] : [policy];
      } else {
        state.suppliers[supplierUuid] = state.suppliers[supplierUuid]
          ? [...state.suppliers[supplierUuid], policy]
          : [policy];
      }

      state.addingPolicy = false;
    },
    [shippingPolicyTypes.ADD_SHIPPING_POLICY_FAILURE](state, err) {
      state.addingPolicy = false;
      state.addingPolicyError = err;
    },
    [shippingPolicyTypes.LIST_SHIPPING_POLICY_REQUEST](state) {
      state.listingPolicies = true;
      state.listingPoliciesError = null;
    },
    [shippingPolicyTypes.LIST_SHIPPING_POLICY_SUCCESS](state) {
      state.listingPolicies = false;
    },
    [shippingPolicyTypes.LIST_SHIPPING_POLICY_FAILURE](state, err) {
      state.listingPolicies = false;
      state.listingPoliciesError = err;
    },
    [shippingPolicyTypes.UPDATE_SHIPPING_POLICY_REQUEST](state) {
      state.updatingPolicy = true;
      state.updatingPolicyError = null;
    },
    [shippingPolicyTypes.UPDATE_SHIPPING_POLICY_SUCCESS](
      state,
      { hubUuid = null, supplierUuid = null, policyUuid, policy }
    ) {
      if (!hubUuid && !supplierUuid) {
        throw new TypeError('You must provide a hub or supplier UUID to use this mutation');
      }

      const replacePolicy = (p) => (p.uuid === policyUuid ? policy : p);
      if (hubUuid) {
        state.hubs[hubUuid] = (state.hubs[hubUuid] || []).map(replacePolicy);
      } else {
        state.suppliers[supplierUuid] = (state.suppliers[supplierUuid] || []).map(replacePolicy);
      }

      state.updatingPolicy = false;
    },
    [shippingPolicyTypes.UPDATE_SHIPPING_POLICY_FAILURE](state, err) {
      state.updatingPolicy = false;
      state.updatingPolicyError = err;
    },
    [shippingPolicyTypes.REMOVE_SHIPPING_POLICY_REQUEST](state) {
      state.removingPolicy = true;
      state.removingPolicyError = null;
    },
    [shippingPolicyTypes.REMOVE_SHIPPING_POLICY_SUCCESS](state, { hubUuid = null, supplierUuid = null, policyUuid }) {
      if (!hubUuid && !supplierUuid) {
        throw new TypeError('You must provide a hub or supplier UUID to use this mutation');
      }

      const filterPolicy = (p) => p.uuid === policyUuid;
      if (hubUuid) {
        state.hubs[hubUuid] = (state.hubs[hubUuid] || []).filter(filterPolicy);
      } else {
        state.suppliers[supplierUuid] = (state.suppliers[supplierUuid] || []).filter(filterPolicy);
      }

      state.removingPolicy = false;
    },
    [shippingPolicyTypes.REMOVE_SHIPPING_POLICY_FAILURE](state, err) {
      state.removingPolicy = false;
      state.removingPolicyError = err;
    },
  },
  actions: {
    async addShippingPolicyToHub({ commit, state }, { uuid, payload }) {
      if (state.addingPolicy) {
        throw new Error('You cannot add a new policy while doing adding another one');
      }

      commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_REQUEST);
      try {
        const policy = await hubsApi.addShippingPolicy(uuid, payload);
        commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_SUCCESS, { hubUuid: uuid, policy });

        return policy;
      } catch (err) {
        commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async addShippingPolicyToSupplier({ commit, state }, { uuid, payload }) {
      if (state.addingPolicy) {
        throw new Error('You cannot add a new policy while doing the same operation');
      }

      commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_REQUEST);
      try {
        const policy = await suppliersApi.addShippingPolicy(uuid, payload);
        commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_SUCCESS, { supplierUuid: uuid, policy });

        return policy;
      } catch (err) {
        commit(shippingPolicyTypes.ADD_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async listShippingPolicies({ commit, state }, { hubUuid, supplierUuid, locationUuids }) {
      if (state.listingPolicies) {
        throw new Error('You are already listing shipping policies');
      }

      commit(shippingPolicyTypes.LIST_SHIPPING_POLICY_REQUEST);
      try {
        const policies = await shippingPoliciesApi.list({ hubUuid, supplierUuid, locationUuids });
        commit(shippingPolicyTypes.LIST_SHIPPING_POLICY_SUCCESS);

        return policies.map((policy) => {
          const ids = policy.uuids.map((uuid, idx) => ({
            uuid,
            location_uuid: policy.locations[idx],
          }));

          delete policy.uuids;
          policy.ids = ids;

          return policy;
        });
      } catch (err) {
        commit(shippingPolicyTypes.LIST_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async updateHubShippingPolicy({ commit, state }, { uuid, policyUuid, payload }) {
      if (state.updatingPolicy) {
        throw new Error('You cannot update a policy while doing updating another one');
      }

      commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_REQUEST);
      try {
        const policy = await hubsApi.updateShippingPolicy(uuid, policyUuid, payload);
        commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_SUCCESS, { hubUuid: uuid, policyUuid, policy });

        return policy;
      } catch (err) {
        commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async updateSupplierShippingPolicy({ commit, state }, { uuid, policyUuid, payload }) {
      if (state.updatingPolicy) {
        throw new Error('You cannot update a policy while doing updating another one');
      }

      commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_REQUEST);
      try {
        const policy = await suppliersApi.updateShippingPolicy(uuid, policyUuid, payload);
        commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_SUCCESS, { supplierUuid: uuid, policyUuid, policy });

        return policy;
      } catch (err) {
        commit(shippingPolicyTypes.UPDATE_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async removeShippingPolicyFromHub({ commit, state }, { uuid, policyUuid }) {
      if (state.removingPolicy) {
        throw new Error('You cannot remove a policy while deleting another one');
      }

      commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_REQUEST);
      try {
        await hubsApi.removeShippingPolicy(uuid, policyUuid);
        commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_SUCCESS, { hubUuid: uuid, policyUuid });
      } catch (err) {
        commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
    async removeShippingPolicyFromSupplier({ commit, state }, { uuid, policyUuid }) {
      if (state.removingPolicy) {
        throw new Error('You cannot remove a policy while deleting another one');
      }

      commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_REQUEST);
      try {
        await suppliersApi.removeShippingPolicy(uuid, policyUuid);
        commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_SUCCESS, { supplierUuid: uuid, policyUuid });
      } catch (err) {
        commit(shippingPolicyTypes.REMOVE_SHIPPING_POLICY_FAILURE, err);
        throw err;
      }
    },
  },
};
