<template>
  <div>
    <section class="hero is-taster-bright-pink">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Item Manager</h1>
          <h2 class="subtitle">Manage delicious items</h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-6">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Recipes</p>
                </div>
              </div>

              <div class="content">
                <ul>
                  <li>Link Recipes to Products</li>
                  <li>Check Recipes Margin</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/items" class="card-footer-item">Manage Recipes</a>
            </footer>
          </div>
        </div>
        <div class="column is-6">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Products</p>
                </div>
              </div>

              <div class="content">
                <ul>
                  <p></p>
                  <li>Manage Product Informations</li>
                  <li>Manage Product Prices</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/products" class="card-footer-item">Manage Products</a>
            </footer>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Suppliers</p>
                </div>
              </div>
              <div class="content">
                <ul>
                  <li>Manage Supplier Informations</li>
                  <li>Create Supplier Products</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/suppliers" class="card-footer-item">Manage Suppliers</a>
            </footer>
          </div>
        </div>
        <div class="column is-6">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Hubs</p>
                </div>
              </div>
              <div class="content">
                <ul>
                  <li>Manage Hub Informations</li>
                  <li>Associate Products to Hubs</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/hubs" class="card-footer-item">Manage Hubs</a>
            </footer>
          </div>
        </div>
        <!-- <div class="column is-3">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Healthchecks</p>
                </div>
              </div>
              <div class="content">
                <ul>
                  <li>Check Item Manager Errors</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/healthchecks" class="card-footer-item">Healthchecks</a>
            </footer>
          </div>
        </div> -->
        <!-- <div class="column is-3">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Platforms</p>
                </div>
              </div>

              <div class="content">
                <ul>
                  <li>Create Menus</li>
                  <li>Publish Menus</li>
                </ul>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/#/platforms" class="card-footer-item">Manage Menus</a>
            </footer>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'home',
};
</script>
