import clientHook from '@/apis';

const ProductsManagerClient = require('@tasterkitchens/products-manager-client');

const categoriesApi = new ProductsManagerClient.CategoriesApi();
categoriesApi.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  fetchAll() {
    return clientHook(categoriesApi).appLegacyApisV2CategoriesListCategories({ summary: true });
  },
};
