<template>
  <div class="box modal-card overflow">
    <h3 class="subtitle">Edit modifier Group</h3>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching this modifier group. </b-message>
    <div v-if="!fetchError">
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <ModifierGroupForm
        v-if="locations && locations.length && modifierGroup && modifierGroup.locations && menuItems"
        :modifierGroup.sync="modifierGroup"
        :menuItems="menuItems"
        :isSaving="isSaveLoading"
        :currentLocation="currentLocation"
        @save="update"
        @cancel="$emit('close')"
        @addLocation="addLocation"
        @removeLocation="removeLocation"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { modifierGroupsTypes } from '@/store/mutation-types';
import { errorToaster } from '@/mixins';
import ModifierGroupForm from '@/components/ModifierGroup/ModifierGroupForm.vue';

export default {
  props: ['uuid'],
  mixins: [errorToaster],
  components: { ModifierGroupForm },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentModifierGroup: (state) => state.modifierGroups.current,
      locations: (state) => state.locations.all,
      menuItems: (state) => state.menuItems.all,
      isSaveLoading: (state) => state.modifierGroups.updateOne.pending || state.modifierGroups.updateLocation.pending,
      isFetchLoading: (state) => state.modifierGroups.fetchOne.pending,
      fetchError: (state) => state.modifierGroups.fetchOne.error,
      saveError: (state) => state.modifierGroups.updateOne.error,
    }),
    modifierGroup: {
      get() {
        return { ...this.currentModifierGroup };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  methods: {
    ...mapActions('modifierGroups', ['fetchOne', 'updateOne', 'addLocation', 'removeLocation', 'updateLocation']),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll' }),
    ...mapActions('menuItems', { fetchAllMenuItems: 'fetchAll' }),
    ...mapMutations('modifierGroups', { setCurrent: modifierGroupsTypes.SET_CURRENT }),
    update() {
      this.updateLocation(this.currentLocation.uuid)
        .then(() => this.updateOne())
        .then(() => this.$emit('close'));
    },
  },
  mounted() {
    if (this.$store.state.locations.all === null || this.$store.state.locations.all.length == 0) {
      this.fetchAllLocations();
    }
    if (this.$store.state.menuItems.all === null || this.$store.state.menuItems.all.length == 0) {
      this.fetchAllMenuItems();
    }
    this.fetchOne(this.uuid);
  },
};
</script>

<style scoped>
.overflow {
  max-height: 95vh;
  overflow-y: auto;
}
</style>
