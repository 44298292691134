<template>
  <div class="box modal-card">
    <h3 class="subtitle">New product</h3>
    <ProductForm
      :categories="categories"
      :product.sync="product"
      :isSaving="isSaveLoading"
      :productCreation="true"
      @save="create"
      @cancel="$emit('close')"
      v-if="categories.length"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import ProductForm from '@/components/Supplier/ProductForm.vue';
import { suppliersTypes } from '@/store/mutation-types';
import { errorToaster } from '@/mixins';

export default {
  components: { ProductForm },
  mixins: [errorToaster],
  computed: {
    ...mapState({
      categories: (state) => state.categories.all,
      currentLocation: (state) => state.locations.current,
      newProduct: (state) => state.suppliers.newProduct,
      isSaveLoading: (state) => state.suppliers.createProduct.pending,
      saveError: (state) => state.suppliers.createProduct.error,
    }),
    product: {
      get() {
        const localizedInformation = { ...this.getLocalizedInformations() };

        localizedInformation.kitchen_price = { ...this.getLocalizedPrice() }; // NEW
        localizedInformation.supplier_price = { ...this.getLocalizedPrice() };
        localizedInformation.batch_size = localizedInformation.batch_size || 1;

        return { ...this.newProduct, new_localized_information: localizedInformation };
      },
      set(value) {
        this.setNewProduct(value);
      },
    },
  },
  methods: {
    ...mapActions('categories', { fetchCategories: 'fetchAll' }),
    ...mapActions('suppliers', ['buildProduct', 'createProduct']),
    ...mapGetters('products', ['getLocalizedPrice', 'getLocalizedInformations', 'getUpdatablePlatforms']),
    ...mapMutations('suppliers', { setNewProduct: suppliersTypes.SET_NEW_PRODUCT }),
    create() {
      this.addSupplierConversion(this.newProduct);
      this.createProduct()
        .then(() => this.$emit('close'))
        .then(() => this.$router.push('/products/' + this.newProduct.uuid));
    },
    addSupplierConversion(newProduct) {
      var localizedInformation = newProduct.new_localized_information;
      if (!localizedInformation.logistics_costs) {
        delete localizedInformation.logistics_costs;
      }
      if (!localizedInformation.kitchen_price) {
        delete localizedInformation.kitchen_price;
      }
      if (!localizedInformation.supplier_price) {
        delete localizedInformation.supplier_price;
      }
      if (!localizedInformation.catalogue_unit) {
        delete localizedInformation.catalogue_unit;
      }
      var vendorUnit;
      if (localizedInformation.supplier_price.type == 'PER_CATALOGUE_UNIT') {
        vendorUnit = localizedInformation.catalogue_unit;
      } else {
        vendorUnit = localizedInformation.packaging_unit;
      }
      var isSupplierUnitAlreadyInConversions = newProduct.conversions.filter(
        (conversion) => conversion.unit == vendorUnit
      );
      if (isSupplierUnitAlreadyInConversions.length == 0) {
        newProduct.conversions.push({
          quantity: 1,
          unit: vendorUnit,
        });
      }
    },
  },
  created() {
    !this.categories.length && this.fetchCategories();
    this.buildProduct();
  },
};
</script>
