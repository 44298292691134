<template>
  <div class="card" @click="isSearching = true">
    <div class="card-content">
      <template v-if="!isSearching">
        <b-icon icon="plus" size="is-large" />
      </template>
      <template v-else>
        <b-autocomplete
          class="menu-item-search"
          v-model="searchMenuItem"
          placeholder="Search"
          open-on-focus
          clearable
          :data="filteredSearch"
          field="label"
          @select="(option) => addItem(option)"
        >
        </b-autocomplete>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MenuDetailsNewMenuItem',
  data() {
    return {
      isSearching: false,
      searchMenuItem: '',
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menuItems.all,
      combos: (state) => state.combos.all,
      currentConcept: (state) => state.concepts.current,
    }),
    ...mapGetters('menuItems', ['filterMenuItemsByConcept']),
    ...mapGetters('combos', ['filterCombosByConcept']),
    filteredMenuItemsByConcept() {
      return [
        ...this.filterMenuItemsByConcept(this.currentConcept.uuid),
        ...this.filterCombosByConcept(this.currentConcept.uuid),
      ];
    },
    filteredSearch() {
      return this.filteredMenuItemsByConcept.filter((item) =>
        item.label.toLowerCase().includes(this.searchMenuItem.toLowerCase())
      );
    },
  },
  mounted() {
    this.$el.addEventListener('click', (e) => e.stopPropagation());
    document.body.addEventListener('click', () => {
      this.isSearching = false;
      this.searchMenuItem = '';
    });
  },
  methods: {
    addItem(option) {
      if (option) {
        this.$emit('addItem', option.uuid);
        this.isSearching = false;
        this.searchMenuItem = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  opacity: 0.5;

  .card-content {
    text-align: center;
    padding: 32px 20px;

    .menu-item-search {
      padding: 6px 0;

      /deep/ div.dropdown-menu {
        max-width: none;
      }
    }
  }
}
.card:hover {
  opacity: 1;
  cursor: pointer;
}
</style>
