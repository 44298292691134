import Vue from 'vue';
import menusApi from '@/apis/platforms/menus.js';
import platformMenusApi from '@/apis/platforms/platformMenus.js';
import copyMenusApi from '@/apis/platforms/copyMenus.js';
import { menusTypes } from '../mutation-types.js';

import { isEmpty } from 'lodash';

export default {
  namespaced: true,
  state: {
    all: [],
    allGeneric: [],
    currentMenuItems: [],
    currentMenus: {},
    currentMenuReferential: {},
    conceptLocationMenuItems: {},
    currentConceptLocationMenuItems: [],
    current: null,
    statuses: [],
    generic: null,
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchAllGeneric: {
      pending: false,
      error: null,
    },
    fetchCurrentMenuItems: {
      pending: false,
      error: null,
    },
    fetchConceptLocationMenuItems: {
      pending: false,
      error: null,
    },
    fetchCurrentMenu: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    fetchStatuses: {
      pending: false,
      error: null,
    },
    fetchStatus: {
      pending: false,
      error: null,
      target: null,
    },
    checkMenu: {
      pending: false,
      error: null,
      target: null,
    },
    testOne: {
      pending: false,
      error: null,
      target: null,
    },
    uploadOne: {
      pending: false,
      error: null,
      target: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    copyMenu: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [menusTypes.FETCH_ALL_SUCCESS](state, menus) {
      state.all = menus;
      state.fetchAll.pending = false;
    },
    [menusTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [menusTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [menusTypes.FETCH_ALL_GENERIC_SUCCESS](state, menus) {
      state.allGeneric = menus;
      state.fetchAllGeneric.pending = false;
    },
    [menusTypes.FETCH_ALL_GENERIC_FAILURE](state, error) {
      state.allGeneric = null;
      state.fetchAllGeneric.pending = false;
      state.fetchAllGeneric.error = error;
    },
    [menusTypes.FETCH_ALL_GENERIC_REQUEST](state) {
      state.fetchAllGeneric.pending = true;
    },
    [menusTypes.FETCH_ONE_SUCCESS](state, menu) {
      state.current = menu;
      state.fetchOne.pending = false;
    },
    [menusTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [menusTypes.FETCH_ONE_REQUEST](state) {
      state.fetchOne.pending = true;
    },
    [menusTypes.SET_CURRENT_MENU](state, menu) {
      state.current = menu;
    },
    [menusTypes.UPDATE_ONE_SUCCESS](state) {
      state.updateOne.pending = false;
      state.updateOne.error = null;
      state.updateOne.target = null;
    },
    [menusTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
      state.updateOne.target = null;
    },
    [menusTypes.UPDATE_ONE_REQUEST](state, restaurantUuid) {
      state.updateOne.pending = true;
      state.updateOne.target = restaurantUuid;
    },
    [menusTypes.ADD_CATEGORY](state, { position, newCategory }) {
      state.current.categories.splice(position, 0, newCategory);
    },
    [menusTypes.EDIT_CATEGORY](state, { position, newCategory }) {
      state.current.categories.splice(position, 1, newCategory);
    },
    [menusTypes.DELETE_CATEGORY](state, position) {
      state.current.categories.splice(position, 1);
    },
    [menusTypes.FETCH_STATUSES_SUCCESS](state, statuses) {
      state.statuses = statuses;
      state.fetchStatuses.pending = false;
    },
    [menusTypes.FETCH_STATUSES_FAILURE](state, error) {
      state.statuses = null;
      state.fetchStatuses.pending = false;
      state.fetchStatuses.error = error;
    },
    [menusTypes.FETCH_STATUSES_REQUEST](state) {
      state.fetchStatuses.pending = true;
    },
    [menusTypes.FETCH_STATUS_SUCCESS](state) {
      state.fetchStatus.pending = false;
      state.fetchStatus.error = null;
      state.fetchStatus.error = null;
      state.fetchStatus.target = null;
    },
    [menusTypes.FETCH_STATUS_FAILURE](state, { error }) {
      state.fetchStatus.pending = false;
      state.fetchStatus.error = error;
      state.fetchStatus.target = null;
    },
    [menusTypes.FETCH_STATUS_REQUEST](state, restaurantUuid) {
      state.fetchStatus.pending = true;
      state.fetchStatus.target = restaurantUuid;
    },
    [menusTypes.CHECK_MENU_SUCCESS](state) {
      state.checkMenu.pending = false;
      state.checkMenu.error = null;
      state.checkMenu.target = null;
    },
    [menusTypes.CHECK_MENU_FAILURE](state, { error }) {
      state.checkMenu.pending = false;
      state.checkMenu.error = error;
      state.checkMenu.target = null;
    },
    [menusTypes.CHECK_MENU_REQUEST](state, restaurantUuid) {
      state.checkMenu.pending = true;
      state.checkMenu.target = restaurantUuid;
    },
    [menusTypes.TEST_MENU_SUCCESS](state) {
      state.testOne.pending = false;
      state.testOne.error = null;
      state.testOne.target = null;
    },
    [menusTypes.TEST_MENU_FAILURE](state, { error }) {
      state.testOne.pending = false;
      state.testOne.error = error;
      state.testOne.target = null;
    },
    [menusTypes.TEST_MENU_REQUEST](state, restaurantUuid) {
      state.testOne.pending = true;
      state.testOne.target = restaurantUuid;
    },
    [menusTypes.UPLOAD_MENU_SUCCESS](state) {
      state.uploadOne.pending = false;
      state.uploadOne.error = null;
      state.uploadOne.target = null;
    },
    [menusTypes.UPLOAD_MENU_FAILURE](state, { error }) {
      state.uploadOne.pending = false;
      state.uploadOne.error = error;
      state.uploadOne.target = null;
    },
    [menusTypes.UPLOAD_MENU_REQUEST](state, restaurantUuid) {
      state.uploadOne.pending = true;
      state.uploadOne.target = restaurantUuid;
    },
    [menusTypes.SWAP_CATEGORIES](state, categoriesSorted) {
      state.current.categories = [...categoriesSorted];
    },
    [menusTypes.SWAP_MENUITEMS_INLINE](state, { position, rowIndex, itemsPerRow, newOrder }) {
      state.current.categories[position].menu_item_uuids.splice(rowIndex * itemsPerRow, itemsPerRow, ...newOrder);
    },
    [menusTypes.SWAP_MENUITEMS_INGROUPS](state, { position, menuItemsSorted }) {
      state.current.categories[position].menu_item_uuids = [...menuItemsSorted];
    },
    [menusTypes.UPDATE_CHECK_STATUS](state, { restaurantUuid, checkResult }) {
      const index = state.statuses.findIndex((status) => status.restaurant_uuid === restaurantUuid);
      Vue.set(state.statuses, index, checkResult);
    },
    [menusTypes.ADD_CHECK_STATUS](state, checkResult) {
      state.statuses.push({ ...checkResult });
    },
    [menusTypes.CREATE_ONE_SUCCESS](state, genericMenu) {
      state.generic = genericMenu;
      state.createOne.pending = false;
    },
    [menusTypes.CREATE_ONE_FAILURE](state, error) {
      state.generic = null;
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [menusTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [menusTypes.FETCH_CURRENT_MENU_ITEMS_SUCCESS](state, menuItems) {
      state.currentMenuItems = menuItems.menu_item_uuids;
      state.fetchCurrentMenuItems.pending = false;
    },
    [menusTypes.FETCH_CURRENT_MENU_ITEMS_FAILURE](state, error) {
      state.currentMenuItems = [];
      state.fetchCurrentMenuItems.pending = false;
      state.fetchCurrentMenuItems.error = error;
    },
    [menusTypes.FETCH_CURRENT_MENU_ITEMS_REQUEST](state) {
      state.fetchCurrentMenuItems.pending = true;
    },
    [menusTypes.FETCH_CURRENT_MENU_SUCCESS](state, menu) {
      state.currentMenus[menu.location_uuid] = state.currentMenus[menu.location_uuid] || {};
      state.currentMenus[menu.location_uuid][menu.concept_uuid] =
        state.currentMenus[menu.location_uuid][menu.concept_uuid] || {};
      state.currentMenus[menu.location_uuid][menu.concept_uuid][menu.platform_uuid] = menu;
      state.fetchCurrentMenu.pending = false;
      state.currentMenuReferential = menu;
    },
    [menusTypes.FETCH_CURRENT_MENU_FAILURE](state, error) {
      // state.currentMenus = {};
      state.fetchCurrentMenu.pending = false;
      state.fetchCurrentMenu.error = error;
    },
    [menusTypes.FETCH_CURRENT_MENU_REQUEST](state) {
      state.fetchCurrentMenu.pending = true;
    },
    [menusTypes.SET_CURRENT_MENU_REFERENTIAL](state, menu) {
      state.currentMenuReferential = menu;
    },
    [menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_SUCCESS](state, response) {
      state.conceptLocationMenuItems[response.locationUuid] =
        state.conceptLocationMenuItems[response.locationUuid] || {};
      state.conceptLocationMenuItems[response.locationUuid][response.conceptUuid] = response.menuItems;
      state.fetchConceptLocationMenuItems.pending = false;
    },
    [menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_FAILURE](state, error) {
      state.fetchConceptLocationMenuItems.pending = false;
      state.fetchConceptLocationMenuItems.error = error;
    },
    [menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_REQUEST](state) {
      state.fetchConceptLocationMenuItems.pending = true;
    },
    [menusTypes.SET_CURRENT_CONCEPT_LOCATION_MENU_ITEMS](state, conceptLocationsMenuItems) {
      state.currentConceptLocationMenuItems = conceptLocationsMenuItems;
    },
    [menusTypes.COPY_MENU_REQUEST](state) {
      state.copyMenu.pending = true;
      state.copyMenu.error = null;
    },
    [menusTypes.COPY_MENU_SUCCESS](state) {
      state.copyMenu.pending = false;
    },
    [menusTypes.COPY_MENU_FAILURE](state, error) {
      state.copyMenu.pending = false;
      state.copyMenu.error = error;
    },
  },
  actions: {
    async fetchAllGeneric({ commit }, opts) {
      opts = opts || {};

      commit(menusTypes.FETCH_ALL_GENERIC_REQUEST);

      return menusApi
        .fetchAll(opts)
        .then((menus) => commit(menusTypes.FETCH_ALL_GENERIC_SUCCESS, menus))
        .catch((error) => commit(menusTypes.FETCH_ALL_GENERIC_FAILURE, error));
    },
    async fetchAll({ commit, rootState }, opts) {
      opts = opts || {};

      if (isEmpty(opts)) {
        opts.location_uuids = [rootState.locations.current.uuid];
      }

      commit(menusTypes.FETCH_ALL_REQUEST);

      return menusApi
        .fetchAll(opts)
        .then((menus) => commit(menusTypes.FETCH_ALL_SUCCESS, menus))
        .catch((error) => commit(menusTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, { locationUuid, conceptUuid, locationUuids }) {
      commit(menusTypes.FETCH_ONE_REQUEST);

      return menusApi
        .fetchOne(locationUuid, conceptUuid, locationUuids)
        .then((menu) => commit(menusTypes.FETCH_ONE_SUCCESS, menu))
        .catch((error) => commit(menusTypes.FETCH_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(menusTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.location_uuid;
      delete data.concept_uuid;
      delete data.active;
      delete data.label;
      delete data.labels;
      delete data.description;
      delete data.generic_uuid;

      return menusApi
        .updateOne(state.current.uuid, data)
        .then(() => commit(menusTypes.UPDATE_ONE_SUCCESS))
        .catch((error) => commit(menusTypes.UPDATE_ONE_FAILURE, error));
    },
    async fetchStatuses({ commit }, opts) {
      opts = opts || {};

      commit(menusTypes.FETCH_STATUSES_REQUEST);

      return platformMenusApi
        .fetchStatuses(opts)
        .then((statuses) => commit(menusTypes.FETCH_STATUSES_SUCCESS, statuses))
        .catch((error) => commit(menusTypes.FETCH_STATUSES_FAILURE, error));
    },
    async fetchStatus({ commit, getters }, restaurantUuid) {
      commit(menusTypes.FETCH_STATUS_REQUEST, restaurantUuid);

      return platformMenusApi
        .fetchStatus(restaurantUuid)
        .then((checkResult) => {
          if (getters.getStatusByRestaurantUuid(restaurantUuid)) {
            commit(menusTypes.UPDATE_CHECK_STATUS, { restaurantUuid, checkResult });
          } else {
            commit(menusTypes.ADD_CHECK_STATUS, checkResult);
          }
          commit(menusTypes.FETCH_STATUS_SUCCESS);
        })
        .catch((error) => commit(menusTypes.FETCH_STATUS_FAILURE, { error }));
    },
    async checkMenu({ commit, getters }, restaurantUuid) {
      commit(menusTypes.CHECK_MENU_REQUEST, restaurantUuid);

      return platformMenusApi
        .checkMenu(restaurantUuid)
        .then((checkResult) => {
          if (getters.getStatusByRestaurantUuid(restaurantUuid)) {
            commit(menusTypes.UPDATE_CHECK_STATUS, { restaurantUuid, checkResult });
          } else {
            commit(menusTypes.ADD_CHECK_STATUS, checkResult);
          }
          commit(menusTypes.CHECK_MENU_SUCCESS);
        })
        .catch((error) => commit(menusTypes.CHECK_MENU_FAILURE, { error }));
    },
    async testMenu({ commit }, restaurantUuid) {
      commit(menusTypes.TEST_MENU_REQUEST, restaurantUuid);

      return platformMenusApi
        .testMenu(restaurantUuid)
        .then(() => {
          commit(menusTypes.TEST_MENU_SUCCESS);
        })
        .catch((error) => commit(menusTypes.TEST_MENU_FAILURE, { error }));
    },
    async uploadMenu({ commit }, restaurantUuid) {
      commit(menusTypes.UPLOAD_MENU_REQUEST, restaurantUuid);

      return platformMenusApi
        .uploadMenu(restaurantUuid)
        .then(() => commit(menusTypes.UPLOAD_MENU_SUCCESS))
        .catch((error) => commit(menusTypes.UPLOAD_MENU_FAILURE, error));
    },
    async saveMenu({ commit }, restaurantUuid) {
      commit(menusTypes.UPDATE_ONE_REQUEST, restaurantUuid);

      return platformMenusApi
        .saveMenu(restaurantUuid)
        .then(() => commit(menusTypes.UPDATE_ONE_SUCCESS))
        .catch((error) => commit(menusTypes.UPDATE_ONE_FAILURE, error));
    },
    async addLocation({ commit, state, rootState }, { genericMenuUuid }) {
      commit(menusTypes.FETCH_ONE_REQUEST);

      genericMenuUuid = genericMenuUuid || state.current.generic_uuid;

      const menuAddLocationRequest = {
        location_uuids: [...rootState.locations.current.location_tree],
        uuid: rootState.locations.current.uuid,
      };

      return menusApi
        .addLocation(genericMenuUuid, menuAddLocationRequest)
        .then((menu) => commit(menusTypes.FETCH_ONE_SUCCESS, menu))
        .catch((error) => commit(menusTypes.FETCH_ONE_FAILURE, error));
    },
    async removeLocation({ commit, state, rootState }) {
      commit(menusTypes.FETCH_ONE_REQUEST);

      const genericMenuUuid = state.current.generic_uuid;

      const menuRemoveLocationRequest = {
        uuid: rootState.locations.current.uuid,
      };

      return menusApi
        .removeLocation(genericMenuUuid, menuRemoveLocationRequest)
        .then((menu) => commit(menusTypes.FETCH_ONE_SUCCESS, menu))
        .catch((error) => commit(menusTypes.FETCH_ONE_FAILURE, error));
    },
    async createOne({ commit, rootState }) {
      commit(menusTypes.CREATE_ONE_REQUEST);

      const menuCreationRequest = {
        concept_uuid: rootState.concepts.current.uuid,
        label: rootState.concepts.current.label + ' Menu',
      };

      return menusApi
        .createMenu(menuCreationRequest)
        .then((genericMenu) => commit(menusTypes.CREATE_ONE_SUCCESS, genericMenu))
        .catch((error) => commit(menusTypes.CREATE_ONE_FAILURE, error));
    },
    async fetchMenuItemsUsedInMenus({ commit }, opts) {
      var locationUuid = null;
      if (opts.location.parent_uuid === null) {
        locationUuid = opts.location.location_country.uuid;
      } else {
        locationUuid = opts.location.uuid;
      }

      commit(menusTypes.FETCH_CURRENT_MENU_ITEMS_REQUEST);

      return menusApi
        .getMenuItems(locationUuid, opts.conceptUuid, opts.platformUuid)
        .then((menus) => commit(menusTypes.FETCH_CURRENT_MENU_ITEMS_SUCCESS, menus))
        .catch((error) => commit(menusTypes.FETCH_CURRENT_MENU_ITEMS_FAILURE, error));
    },
    async fetchMenuItemsUsedInConceptLocation({ commit }, opts) {
      var locationUuid = null;
      var conceptUuid = opts.conceptUuid;
      if (opts.location.parent_uuid === null) {
        locationUuid = opts.location.location_country.uuid;
      } else {
        locationUuid = opts.location.uuid;
      }

      commit(menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_REQUEST);
      return menusApi
        .getMenuItemsConceptLocation(locationUuid, opts.conceptUuid)
        .then((menuItems) =>
          commit(menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_SUCCESS, { menuItems, locationUuid, conceptUuid })
        )
        .catch((error) => commit(menusTypes.FETCH_CONCEPT_LOCATION_MENU_ITEMS_FAILURE, error));
    },
    async fetchCurrentMenu({ commit }, opts) {
      if (opts.location.parent_uuid === null) {
        opts.countryUuid = opts.location.location_country.uuid;
        opts.locationUuid = opts.location.uuid;
      } else {
        opts.locationUuid = opts.location.uuid;
      }

      commit(menusTypes.FETCH_CURRENT_MENU_REQUEST);

      return menusApi
        .getMenuInformations(opts)
        .then((menus) => commit(menusTypes.FETCH_CURRENT_MENU_SUCCESS, menus))
        .catch((error) => commit(menusTypes.FETCH_CURRENT_MENU_FAILURE, error));
    },
    async copyMenu({ commit }, opts) {
      commit(menusTypes.COPY_MENU_REQUEST);
      return copyMenusApi
        .run(opts)
        .then(() => commit(menusTypes.COPY_MENU_SUCCESS))
        .catch((error) => commit(menusTypes.COPY_MENU_FAILURE, error));
    },
  },
  getters: {
    getStatusByRestaurantUuid: (state) => (restaurantUuid) =>
      state.statuses.find((status) => status.restaurant_uuid === restaurantUuid),
  },
};
