<template>
  <div>
    <b-tabs expanded>
      <b-tab-item label="General Info" icon="account-box">
        <b-field label="Name" label-position="on-border" :message="labelMessage()" :type="labelMessageType()">
          <b-input type="text" v-model="value.label" required></b-input>
        </b-field>
        <b-field grouped class="shrink">
          <b-field
            label="Category"
            label-position="on-border"
            :message="categoryMessage()"
            :type="categoryMessageType()"
            expanded
          >
            <b-select placeholder="Select a category" v-model="categoryUuid" required>
              <option v-for="category in categories" :value="category.uuid" :key="category.uuid">
                {{ category.label }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Reference" label-position="on-border" expanded>
            <b-input type="text" v-model="value.product_reference"></b-input>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field
            label="Yield ratio"
            label-position="on-border"
            :message="yieldRatioMessage()"
            :type="yieldRatioMessageType()"
            expanded
          >
            <b-input type="number" step="0.01" v-model.number="value.yield_ratio" required></b-input>
          </b-field>
          <b-field label="Shelf life" label-position="on-border" expanded>
            <b-input type="number" v-model.number="value.shelf_life"></b-input>
          </b-field>
        </b-field>
        <b-field label="Tags" label-position="on-border">
          <b-taginput v-model="value.tags" ellipsis icon="label" placeholder="Add a tag" />
        </b-field>
        <b-field v-if="isParentLocation()" :label="`Translation ${currentLocation.id}`" label-position="on-border">
          <b-input type="text" v-model="value.new_localized_information.label"></b-input>
        </b-field>
      </b-tab-item>
      <b-tab-item label="Units" icon="equal">
        <div>
          <b-field
            v-if="isParentLocation()"
            :type="baseUnitMessageType()"
            label="Base Unit"
            label-position="on-border"
            :message="baseUnitMessage()"
          >
            <b-select placeholder="Select a unit" v-model="value.base_unit" :disabled="!productCreation" required>
              <option v-for="(unit, index) in baseUnits" :value="unit" :key="index">{{ unit }}</option>
            </b-select>
          </b-field>
          <b-field
            v-if="isParentLocation()"
            :type="catalogueUnitMessageType()"
            label="Catalogue Unit"
            label-position="on-border"
            :message="catalogueUnitMessage()"
          >
            <b-input v-model="value.new_localized_information.catalogue_unit" type="text" required />
          </b-field>
          <b-field v-else label="Catalogue Unit" label-position="on-border">
            <b-input type="text" :value="getCatalogueUnit()" disabled />
          </b-field>
          <b-field v-if="isParentLocation()" class="shrink" grouped>
            <b-field
              :type="packagingUnitMessageType()"
              label="Packaging Unit"
              label-position="on-border"
              :message="packagingUnitMessage()"
              expanded
            >
              <b-input v-model="value.new_localized_information.packaging_unit" type="text" required />
            </b-field>
            <b-field
              :type="batchSizeMessageType()"
              label="Batch Size"
              label-position="on-border"
              :message="batchSizeMessage()"
              expanded
            >
              <b-input v-model.number="value.new_localized_information.batch_size" type="number" required />
            </b-field>
            <b-field
              :type="grossWeightMessageType()"
              label="Packaging Gross Weight"
              label-position="on-border"
              :message="grossWeightMessage()"
              expanded
            >
              <b-input
                v-model.number="value.new_localized_information.gross_weight_per_packaging_unit"
                type="number"
                :required="isGrossWeightRequired"
              />
            </b-field>
          </b-field>
          <b-field v-else class="shrink" grouped>
            <b-field label="Packaging Unit" label-position="on-border" expanded>
              <b-input type="text" :value="getPackagingUnit()" disabled />
            </b-field>
            <b-field label="Packaging Gross Weight" label-position="on-border" expanded>
              <b-input type="number" :value="getGrossWeight()" disabled />
            </b-field>
            <b-field label="Batch Size" label-position="on-border" expanded>
              <b-input type="number" :value="getBatchSize()" disabled />
            </b-field>
          </b-field>
        </div>
        <div>
          <div style="margin-bottom: 16px">
            <b>Conversions</b>
            <b-button
              icon-left="plus"
              size="is-small"
              class="button-gap"
              @click="() => value.conversions.push({ quantity: 0, unit: '' })"
              >Add</b-button
            >
            <p class="is-size-7 is-italic">All following measures should be equivalent.</p>
          </div>
          <b-field grouped v-for="(conversion, index) in value.conversions" :key="index">
            <b-field>
              <b-input
                type="number"
                step="0.001"
                size="is-small"
                rounded
                expanded
                v-model.number="conversion.quantity"
              ></b-input>
              <b-input type="text" size="is-small" rounded placeholder="Unit" v-model="conversion.unit"></b-input>
            </b-field>
            <b-button
              icon-left="delete"
              size="is-small"
              :disabled="!canRemoveConversion(index)"
              @click="() => removeConversion(index)"
              >Remove</b-button
            >
          </b-field>
        </div>
      </b-tab-item>
      <b-tab-item label="Prices" icon="flag">
        <p class="title is-6" style="text-align: center">{{ currentLocation.id }}</p>
        <div style="margin-bottom: 16px">
          <b>Supplier Price</b>
          <b-button
            icon-left="plus"
            size="is-small"
            class="button-gap is-dark"
            outlined
            v-if="displayAddSupplyPriceButton"
            @click="addSupplierPrice()"
            >Add</b-button
          >
        </div>
        <div v-if="!displayAddSupplyPriceButton">
          <b-field grouped class="shrink">
            <b-field>
              <b-taglist attached>
                <b-tag type="is-link">{{ supplierPriceFormatted }}</b-tag>
              </b-taglist>
            </b-field>
            <b-field
              label="Price"
              label-position="on-border"
              :message="supplierPriceMessage()"
              :type="supplierPriceMessageType()"
            >
              <b-input
                placeholder="8.54"
                expanded
                type="number"
                step="0.01"
                v-model.number="value.new_localized_information.supplier_price.price"
                required
              ></b-input>
            </b-field>
            <b-field
              label="Price Unit Type"
              label-position="on-border"
              :message="supplierPriceTypeMessage()"
              :type="supplierPriceTypeMessageType()"
            >
              <b-select
                placeholder="Select type"
                v-model="value.new_localized_information.supplier_price.type"
                required
              >
                <option value="PER_CATALOGUE_UNIT">CATALOGUE UNIT</option>
                <option value="PER_PACKAGING_UNIT">PACKAGING UNIT</option>
              </b-select>
            </b-field>
          </b-field>
        </div>
        <div style="margin-bottom: 16px">
          <b>Logistics Costs</b>
          <b-button icon-left="plus" size="is-small" class="button-gap is-dark" outlined @click="addLogisticsCosts()"
            >Add</b-button
          >
        </div>
        <div v-if="displayLogisticsCosts">
          <b-field
            grouped
            v-for="(logisticCost, index) in value.new_localized_information.logistics_costs"
            :key="index"
            class="shrink"
          >
            <b-field>
              <b-taglist attached>
                <b-tag type="is-link">{{ logsisticsCostsFormatted(logisticCost) }}</b-tag>
              </b-taglist>
            </b-field>
            <b-field
              label="Price"
              label-position="on-border"
              :message="logisticsCostsPriceMessage(logisticCost.price)"
              :type="logisticsCostsPriceMessageType(logisticCost.price)"
            >
              <b-input
                placeholder="8.54"
                expanded
                type="number"
                step="0.01"
                v-model.number="logisticCost.price"
                required
              ></b-input>
            </b-field>
            <b-field
              label="Price Type"
              label-position="on-border"
              :message="logisticsCostsPriceTypeMessage(logisticCost.type)"
              :type="logisticsCostsPriceTypeMessageType(logisticCost.type)"
            >
              <b-select placeholder="Select type" v-model="logisticCost.type" required>
                <option value="PER_PACKAGING_UNIT">PER PACKAGING UNIT</option>
                <option value="PER_GROSS_WEIGHT">PER Kg</option>
              </b-select>
            </b-field>
            <b-field label="Label" label-position="on-border" expanded>
              <b-input v-model="logisticCost.label"></b-input>
            </b-field>
            <b-field>
              <b-button
                v-if="logisticCost"
                icon-left="delete"
                rounded
                outlined
                type="is-danger"
                size="is-small"
                @click="deleteLogisticsCosts(value, index)"
              ></b-button>
            </b-field>
          </b-field>
        </div>
        <div style="margin-bottom: 16px">
          <b>Kitchen Price</b>
          <b-button
            icon-left="plus"
            size="is-small"
            class="button-gap is-dark"
            outlined
            v-if="displayAddKitchenPriceButton"
            @click="addKitchenPrice()"
            >Add</b-button
          >
        </div>
        <div v-if="!displayAddKitchenPriceButton">
          <b-field grouped class="shrink">
            <b-field>
              <b-taglist attached>
                <b-tag type="is-link">{{ kitchenPriceFormatted }}</b-tag>
              </b-taglist>
            </b-field>
            <b-field
              label="Price"
              label-position="on-border"
              :message="kitchenPriceMessage()"
              :type="kitchenPriceMessageType()"
            >
              <b-input
                placeholder="8.54"
                expanded
                type="number"
                step="0.01"
                v-model.number="value.new_localized_information.kitchen_price.price"
                required
              ></b-input>
            </b-field>
            <b-field
              label="Price Type"
              label-position="on-border"
              :message="kitchenPriceTypeMessage()"
              :type="kitchenPriceTypeMessageType()"
            >
              <b-select placeholder="Select type" v-model="value.new_localized_information.kitchen_price.type" required>
                <option value="PER_CATALOGUE_UNIT">CATALOGUE UNIT</option>
                <option value="PER_PACKAGING_UNIT">PACKAGING UNIT</option>
              </b-select>
            </b-field>
            <b-field>
              <b-button
                v-if="value.new_localized_information.kitchen_price && !isParentLocation()"
                rounded
                outlined
                type="is-danger"
                icon-left="delete"
                size="is-small"
                @click="() => (value.new_localized_information.kitchen_price = {})"
              ></b-button>
            </b-field>
          </b-field>
        </div>
        <div style="margin-bottom: 16px">
          <b>VAT</b>
          <b-field
            label="VAT Rate"
            label-position="on-border"
            style="margin-top: 16px"
            :message="vatRateTypeMessage()"
            :type="vatRateTypeMessageType()"
          >
            <b-input
              placeholder="12.50"
              expanded
              type="number"
              step="0.01"
              v-model.number="value.new_localized_information.vat_rate"
              required
            ></b-input>
          </b-field>
        </div>
      </b-tab-item>
      <b-tab-item label="One Source" icon="tablet">
        <b-field label="Orderable State">
          <b-select
            :placeholder="getCountryOrderableState()"
            v-model="value.new_localized_information.orderable"
            size="is-small"
            expanded
          >
            <option :value="true">True</option>
            <option :value="false">False</option>
            <option :value="null" v-if="!isParentLocation()">Same as parent</option>
          </b-select>
        </b-field>
      </b-tab-item>
    </b-tabs>
    <b-field grouped style="flex-direction: row-reverse">
      <b-button
        class="button button-gap is-primary"
        @click="save"
        :loading="isSaving"
        :disabled="createOrUpdateDisabled"
      >
        <span v-if="value.uuid">Save</span>
        <span v-else>Create</span>
      </b-button>
      <b-button @click="$emit('cancel')">Cancel</b-button>
    </b-field>
    <p v-if="saveMessage" class="is-size-7 is-italic has-text-right has-text-weight-light has-text-danger">
      {{ saveMessage }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { displayFormatter } from '@/mixins';
export default {
  mixins: [displayFormatter],
  props: ['product', 'isSaving', 'categories', 'productCreation'],
  data() {
    return {
      baseUnits: ['L', 'kg', 'each'],
      value: { ...this.product },
    };
  },
  watch: {
    product(newValue, oldValue) {
      if (newValue.uuid !== oldValue.uuid) {
        this.value = { ...newValue };
      }
    },
    value: {
      handler() {
        this.$emit('update:product', { ...this.value });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('products', ['getLocalizedPrice']),
    ...mapState({
      currentLocation: (state) => state.locations.current,
      createOrUpdatePriceDisable: (state) =>
        state.products.updateProductLocalizedInfo.pending || state.products.createProductLocalizedInfo.pending,
    }),
    categoryUuid: {
      get() {
        if (this.value.category_uuid) return this.value.category_uuid;

        return this.value.category ? this.value.category.uuid : '';
      },
      set(value) {
        if (this.value.category_uuid) {
          this.value.category_uuid = value;
        } else {
          this.$set(this.value, 'category_uuid', value);
        }
      },
    },
    createOrUpdateDisabled: function () {
      if (this.areMandatoryInformationsFilled) {
        if (this.productCreation) {
          if (this.areCurrentLocalizedInformations) {
            if (
              !this.isPackagingUnitFilled ||
              !this.isBaseUnitInConversions ||
              !this.isCatalogueUnitInConversions ||
              !this.isPackagingUnitInConversions
            ) {
              return true;
            }
            if (!this.isSupplierPriceTotallyFilled) {
              return true;
            }
            if (!this.isVatRateFilled) {
              return true;
            }
            if (this.isOrderableState == null) {
              return true;
            }
            if (
              this.isKitchenPriceEmptyOrTotallyFilled &&
              this.areLogisticsCostsEmptyOrTotallyFilled &&
              this.isCatalogueUnitTotallyFilled
            ) {
              return false;
            }
            return true;
          }
          return true;
        }
        if (!this.productCreation) {
          if (this.areCurrentLocalizedInformations) {
            if (
              (this.isParentLocation() &&
                this.isPackagingUnitFilled &&
                this.isCatalogueUnitInConversions &&
                this.isCatalogueUnitInConversions &&
                this.isPackagingUnitInConversions &&
                this.isSupplierPriceEmptyOrTotallyFilled &&
                this.isKitchenPriceEmptyOrTotallyFilled &&
                this.areLogisticsCostsEmptyOrTotallyFilled &&
                this.isCatalogueUnitTotallyFilled &&
                this.isVatRateFilled &&
                this.areAllPackagingUnitsInConversions) ||
              (!this.isParentLocation() &&
                this.areCountryUnitsFilled &&
                this.isCatalogueUnitInConversions &&
                this.isSupplierPriceEmptyOrTotallyFilled &&
                this.isKitchenPriceEmptyOrTotallyFilled &&
                this.areLogisticsCostsEmptyOrTotallyFilled &&
                this.isCatalogueUnitTotallyFilled)
            ) {
              return false;
            }
            return true;
          }
          return false;
        }
      }
      return true;
    },
    areMandatoryInformationsFilled() {
      return this.value.label && this.value.yield_ratio && this.categoryUuid;
    },
    areCurrentLocalizedInformations() {
      return this.value.new_localized_information;
    },
    isPackagingUnitFilled() {
      return this.value.new_localized_information.packaging_unit;
    },
    isVatRateFilled() {
      return this.value.new_localized_information.vat_rate != null;
    },
    isOrderableState() {
      return this.value.new_localized_information.orderable;
    },
    areUnitsFilled() {
      return this.value.new_localized_information.catalogue_unit && this.value.new_localized_information.packaging_unit;
    },
    areCountryUnitsFilled() {
      return this.getPackagingUnit();
    },
    isSupplierPriceTotallyFilled() {
      return (
        this.value.new_localized_information.supplier_price &&
        this.value.new_localized_information.supplier_price.price &&
        this.value.new_localized_information.supplier_price.type
      );
    },
    isSupplierPriceEmpty() {
      if (!this.value.new_localized_information.supplier_price) {
        return true;
      }
      if (Object.keys(this.value.new_localized_information.supplier_price).length == 0) {
        return true;
      }
      return false;
    },
    isSupplierPriceEmptyOrTotallyFilled() {
      return this.isSupplierPriceEmpty || this.isSupplierPriceTotallyFilled;
    },
    isCatalogueUnitTotallyFilled() {
      if (
        this.value.new_localized_information.supplier_price &&
        this.value.new_localized_information.supplier_price.type &&
        this.value.new_localized_information.supplier_price.type == 'PER_CATALOGUE_UNIT'
      ) {
        if (
          (!this.value.new_localized_information.catalogue_unit && this.isParentLocation()) ||
          (!this.getCatalogueUnit() && !this.isParentLocation())
        ) {
          return false;
        }
      }
      if (
        this.value.new_localized_information.kitchen_price &&
        this.value.new_localized_information.kitchen_price.type &&
        this.value.new_localized_information.kitchen_price.type == 'PER_CATALOGUE_UNIT'
      ) {
        if (
          (!this.value.new_localized_information.catalogue_unit && this.isParentLocation()) ||
          (!this.getCatalogueUnit() && !this.isParentLocation())
        ) {
          return false;
        }
      }
      return true;
    },
    isKitchenPriceTotallyFilled() {
      return (
        this.value.new_localized_information.kitchen_price &&
        this.value.new_localized_information.kitchen_price.price &&
        this.value.new_localized_information.kitchen_price.type
      );
    },
    isKitchenPriceEmpty() {
      if (!this.value.new_localized_information.kitchen_price) {
        return true;
      }
      if (Object.keys(this.value.new_localized_information.kitchen_price).length == 0) {
        return true;
      }
      return false;
    },
    isKitchenPriceEmptyOrTotallyFilled() {
      return this.isKitchenPriceEmpty || this.isKitchenPriceTotallyFilled;
    },
    areLogisticsCostsTotallyFilled() {
      for (let logisticCost of this.value.new_localized_information.logistics_costs) {
        if (!(logisticCost && logisticCost.price && logisticCost.type)) {
          return false;
        }
        if (
          logisticCost.type == 'PER_GROSS_WEIGHT' &&
          !this.value.new_localized_information.gross_weight_per_packaging_unit
        ) {
          return false;
        }
      }
      return true;
    },
    areLogisticsCostsEmptyOrTotallyFilled() {
      return !this.value.new_localized_information.logistics_costs || this.areLogisticsCostsTotallyFilled;
    },
    isGrossWeightRequired() {
      if (!this.value.new_localized_information.logistics_costs) {
        return false;
      }
      for (let logisticCost of this.value.new_localized_information.logistics_costs) {
        if (logisticCost.type == 'PER_GROSS_WEIGHT') {
          return true;
        }
      }
      return false;
    },
    isBaseUnitInConversions() {
      if (this.value.base_unit) {
        var isBaseUnittAlreadyInConversions = this.value.conversions.filter(
          (conversion) => conversion.unit == this.value.base_unit
        );
        if (isBaseUnittAlreadyInConversions.length == 0) {
          return false;
        }
      }
      return true;
    },
    isCatalogueUnitInConversions() {
      if (this.value.new_localized_information && this.value.new_localized_information.catalogue_unit) {
        var isCatalogueUnittAlreadyInConversions = this.value.conversions.filter(
          (conversion) => conversion.unit == this.value.new_localized_information.catalogue_unit
        );
        if (isCatalogueUnittAlreadyInConversions.length == 0) {
          return false;
        }
      }
      return true;
    },
    isPackagingUnitInConversions() {
      if (this.value.new_localized_information && this.value.new_localized_information.packaging_unit) {
        var isPackagingUnittAlreadyInConversions = this.value.conversions.filter(
          (conversion) => conversion.unit == this.value.new_localized_information.packaging_unit
        );
        if (isPackagingUnittAlreadyInConversions.length == 0) {
          return false;
        }
      }
      return true;
    },
    areAllPackagingUnitsInConversions() {
      if (this.value.localized_informations && this.value.localized_informations.length > 0) {
        for (let localizedInformation of this.value.localized_informations) {
          if (
            localizedInformation &&
            localizedInformation.packaging_unit &&
            this.value.new_localized_information &&
            this.value.new_localized_information.location_uuid != localizedInformation.location_uuid
          ) {
            var isPackagingUnittAlreadyInConversions = this.value.conversions.filter(
              (conversion) => conversion.unit == localizedInformation.packaging_unit
            );
            if (isPackagingUnittAlreadyInConversions.length == 0) {
              return false;
            }
          }
        }
      }
      return true;
    },
    displayAddSupplyPriceButton: function () {
      if (
        this.productCreation ||
        (this.value.new_localized_information &&
          this.value.new_localized_information.supplier_price &&
          Object.keys(this.value.new_localized_information.supplier_price).length > 0)
      ) {
        return false;
      }
      return true;
    },
    displayAddKitchenPriceButton: function () {
      if (
        this.value.new_localized_information &&
        this.value.new_localized_information.kitchen_price &&
        Object.keys(this.value.new_localized_information.kitchen_price).length > 0
      ) {
        return false;
      }
      return true;
    },
    displayLogisticsCosts: function () {
      if (
        this.value.new_localized_information &&
        this.value.new_localized_information.logistics_costs &&
        this.value.new_localized_information.logistics_costs.length > 0 &&
        Object.keys(this.value.new_localized_information.logistics_costs[0]).length > 0
      ) {
        return true;
      }
      return false;
    },
    supplierPriceFormatted() {
      if (
        this.value.new_localized_information &&
        this.value.new_localized_information.supplier_price &&
        this.value.new_localized_information.supplier_price.type == 'PER_CATALOGUE_UNIT' &&
        this.value.new_localized_information.catalogue_unit
      ) {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / ${
          this.value.new_localized_information.catalogue_unit
        }`;
      } else if (
        this.value.new_localized_information &&
        this.value.new_localized_information.supplier_price &&
        this.value.new_localized_information.supplier_price.type == 'PER_PACKAGING_UNIT' &&
        this.value.new_localized_information.packaging_unit
      ) {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / ${
          this.value.new_localized_information.packaging_unit
        }`;
      }
      return `${this.getDeviseSign(this.value.new_localized_information.currency)}`;
    },
    kitchenPriceFormatted() {
      if (
        this.value.new_localized_information &&
        this.value.new_localized_information.kitchen_price &&
        this.value.new_localized_information.kitchen_price.type == 'PER_CATALOGUE_UNIT' &&
        this.value.new_localized_information.catalogue_unit
      ) {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / ${
          this.value.new_localized_information.catalogue_unit
        }`;
      } else if (
        this.value.new_localized_information &&
        this.value.new_localized_information.kitchen_price &&
        this.value.new_localized_information.kitchen_price.type == 'PER_PACKAGING_UNIT' &&
        this.value.new_localized_information.packaging_unit
      ) {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / ${
          this.value.new_localized_information.packaging_unit
        }`;
      }
      return `${this.getDeviseSign(this.value.new_localized_information.currency)}`;
    },
    saveMessage() {
      var messages = [
        this.labelMessage(),
        this.categoryMessage(),
        this.yieldRatioMessage(),
        this.baseUnitMessage(),
        this.catalogueUnitMessage(),
        this.packagingUnitMessage(),
        this.baseUnitInConversionsMessage(),
        this.catalogueUnitInConversionsMessage(),
        this.grossWeightMessage(),
        this.packagingUnitInConversionsMessage(),
        this.supplierPriceMessage(),
        this.supplierPriceTypeMessage(),
        this.kitchenPriceMessage(),
        this.kitchenPriceTypeMessage(),
        this.vatRateTypeMessage(),
        this.orderableStateMessage(),
        this.allPackingUnitsInConversionMessage(),
      ];
      for (let message of messages) {
        if (message) {
          return message;
        }
      }
      return '';
    },
  },
  methods: {
    save() {
      if (!this.value.new_localized_information.location_uuid) {
        this.value.new_localized_information.location_uuid = this.currentLocation.uuid;
      }
      this.$emit('save');
    },
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    getCountryLocalizedInformation() {
      var countryLocalizedInformation = {};
      if (!this.currentLocation.parent_uuid) {
        if (this.product.localized_informations) {
          countryLocalizedInformation = this.product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
          );
          if (countryLocalizedInformation && countryLocalizedInformation.length > 0) {
            return countryLocalizedInformation[0];
          }
        }
        return {};
      } else {
        if (this.product.localized_informations) {
          countryLocalizedInformation = this.product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.parent_uuid
          );
          if (countryLocalizedInformation && countryLocalizedInformation.length > 0) {
            return countryLocalizedInformation[0];
          }
        }
        return {};
      }
    },
    getCatalogueUnit() {
      return this.getCountryLocalizedInformation().catalogue_unit;
    },
    getPackagingUnit() {
      return this.getCountryLocalizedInformation().packaging_unit;
    },
    getGrossWeight() {
      return this.getCountryLocalizedInformation().gross_weight_per_packaging_unit;
    },
    getBatchSize() {
      return this.getCountryLocalizedInformation().batch_size;
    },
    getCountryOrderableState() {
      var countryOrderableState = this.getCountryLocalizedInformation().orderable;
      if (countryOrderableState == true) {
        return 'True';
      }
      if (countryOrderableState == false) {
        return 'False';
      }
      return 'Select Orderable State';
    },
    addLocalizedInformations() {
      this.$set(this.value.new_localized_information, 'currency', this.currentLocation.location_country.currency_code);
      this.$set(this.value.new_localized_information, 'location_id', this.currentLocation.id);
      this.$set(this.value.new_localized_information, 'location_uuid', this.currentLocation.uuid);
    },
    addSupplierPrice() {
      if (!this.value.new_localized_information) {
        this.$set(this.value, 'new_localized_information', {});
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'supplier_price', { ...this.getLocalizedPrice });
      } else if (!this.value.new_localized_information.supplier_price) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'supplier_price', { ...this.getLocalizedPrice });
      } else if (Object.keys(this.value.new_localized_information.supplier_price).length == 0) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'supplier_price', { ...this.getLocalizedPrice });
      }
    },
    addKitchenPrice() {
      if (!this.value.new_localized_information) {
        this.$set(this.value, 'new_localized_information', {});
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'kitchen_price', { ...this.getLocalizedPrice });
      } else if (!this.value.new_localized_information.kitchen_price) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'kitchen_price', { ...this.getLocalizedPrice });
      } else if (Object.keys(this.value.new_localized_information.kitchen_price).length == 0) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'kitchen_price', { ...this.getLocalizedPrice });
      }
    },
    addLogisticsCosts() {
      if (!this.value.new_localized_information) {
        this.$set(this.value, 'new_localized_information', {});
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'logistics_costs', [{ ...this.getLocalizedPrice }]);
      } else if (!this.value.new_localized_information.logistics_costs) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'logistics_costs', [{ ...this.getLocalizedPrice }]);
      } else if (Object.keys(this.value.new_localized_information.logistics_costs).length == 0) {
        this.addLocalizedInformations();
        this.$set(this.value.new_localized_information, 'logistics_costs', [{ ...this.getLocalizedPrice }]);
      } else {
        this.addLocalizedInformations();
        this.value.new_localized_information.logistics_costs.push({ ...this.getLocalizedPrice });
      }
    },
    logsisticsCostsFormatted(logisticCost) {
      if (this.value.new_localized_information && logisticCost && logisticCost.type == 'PER_GROSS_WEIGHT') {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / kg`;
      } else if (
        this.value.new_localized_information &&
        logisticCost &&
        logisticCost.type == 'PER_PACKAGING_UNIT' &&
        this.value.new_localized_information.packaging_unit
      ) {
        return `${this.getDeviseSign(this.value.new_localized_information.currency)} / ${
          this.value.new_localized_information.packaging_unit
        }`;
      }
      return `${this.getDeviseSign(this.value.new_localized_information.currency)}`;
    },
    deleteLogisticsCosts(value, index) {
      value.new_localized_information.logistics_costs.splice(index, 1);
    },
    canRemoveConversion(index) {
      if (index != -1) {
        var conversion = this.value.conversions[index];
        if (
          conversion.unit == this.value.base_unit ||
          (this.value.new_localized_information &&
            this.value.new_localized_information.packaging_unit &&
            conversion.unit == this.value.new_localized_information.packaging_unit)
        ) {
          return false;
        }
      }
      return true;
    },
    removeConversion(index) {
      this.value.conversions.splice(index, 1);
    },
    labelMessage() {
      if (!this.value.label) {
        return 'Missing Label';
      }
      return '';
    },
    labelMessageType() {
      if (!this.value.label) {
        return 'is-danger';
      }
      return 'is-light';
    },
    categoryMessage() {
      if (!this.categoryUuid) {
        return 'Missing category';
      }
      return '';
    },
    categoryMessageType() {
      if (!this.categoryUuid) {
        return 'is-danger';
      }
      return 'is-light';
    },
    yieldRatioMessage() {
      if (this.value.yield_ratio < 0) {
        return 'Yield Ratio must be > 0';
      }
      if (this.value.yield_ratio > 1) {
        return 'Yield Ratio must be < 1';
      }
      return '';
    },
    yieldRatioMessageType() {
      if (this.value.yield_ratio < 0) {
        return 'is-danger';
      }
      if (this.value.yield_ratio > 1) {
        return 'is-danger';
      }
      return 'is-light';
    },
    baseUnitMessage() {
      if (this.isParentLocation() && !this.value.base_unit) {
        return 'Missing Base Unit';
      }
      return '';
    },
    baseUnitMessageType() {
      if (this.isParentLocation() && !this.value.base_unit) {
        return 'is-danger';
      }
      return 'is-light';
    },
    catalogueUnitMessage() {
      if (
        this.isParentLocation() &&
        !this.value.new_localized_information.catalogue_unit &&
        !this.isCatalogueUnitTotallyFilled
      ) {
        return 'Missing Catalogue Unit';
      }
      return '';
    },
    catalogueUnitMessageType() {
      if (
        this.isParentLocation() &&
        !this.value.new_localized_information.catalogue_unit &&
        !this.isCatalogueUnitTotallyFilled
      ) {
        return 'is-danger';
      }
      return 'is-light';
    },
    catalogueUnitInConversionsMessage() {
      if (!this.isCatalogueUnitInConversions) {
        return `Missing Catalogue Unit in Conversions`;
      }
      return '';
    },
    baseUnitInConversionsMessage() {
      if (!this.isBaseUnitInConversions) {
        return `Missing Base Unit in Conversions`;
      }
      return '';
    },
    packagingUnitInConversionsMessage() {
      if (!this.isPackagingUnitInConversions) {
        return `Missing Packaging Unit in Conversions`;
      }
      return '';
    },
    grossWeightMessage() {
      if (
        this.isParentLocation() &&
        this.value.new_localized_information &&
        this.value.new_localized_information.logistics_costs
      ) {
        for (let logisticCost of this.value.new_localized_information.logistics_costs) {
          if (
            logisticCost.type == 'PER_GROSS_WEIGHT' &&
            !this.value.new_localized_information.gross_weight_per_packaging_unit
          ) {
            return 'Missing Gross Weight';
          }
        }
      }
      return '';
    },
    grossWeightMessageType() {
      if (
        this.isParentLocation() &&
        this.value.new_localized_information &&
        this.value.new_localized_information.logistics_costs
      ) {
        for (let logisticCost of this.value.new_localized_information.logistics_costs) {
          if (
            logisticCost.type == 'PER_GROSS_WEIGHT' &&
            !this.value.new_localized_information.gross_weight_per_packaging_unit
          ) {
            return 'is-danger';
          }
        }
      }
      return 'is-light';
    },
    packagingUnitMessage() {
      if (this.isParentLocation() && !this.value.new_localized_information.packaging_unit) {
        return 'Missing Packaging Unit';
      }
      return '';
    },
    packagingUnitMessageType() {
      if (this.isParentLocation() && !this.value.new_localized_information.packaging_unit) {
        return 'is-danger';
      }
      return 'is-light';
    },
    batchSizeMessageType() {
      const batchSize = this.value.new_localized_information.batch_size;
      return this.isParentLocation() && (!batchSize || batchSize < 1) ? 'is-danger' : 'is-light';
    },
    batchSizeMessage() {
      const batchSize = this.value.new_localized_information.batch_size;
      if (!batchSize) {
        return 'Missing Batch Size';
      } else if (batchSize < 1) {
        return 'Batch Size cannot be lesser than 1';
      }

      return null;
    },
    supplierPriceMessage() {
      if (
        this.value.new_localized_information.supplier_price &&
        Object.keys(this.value.new_localized_information.supplier_price).length > 0 &&
        !this.value.new_localized_information.supplier_price.price
      ) {
        return 'Missing Supplier Price';
      }
      if (
        this.value.new_localized_information.supplier_price &&
        Object.keys(this.value.new_localized_information.supplier_price).length > 0 &&
        this.value.new_localized_information.supplier_price.price == 0
      ) {
        return 'Price must be > 0';
      }
      return '';
    },
    supplierPriceMessageType() {
      if (
        this.value.new_localized_information.supplier_price &&
        Object.keys(this.value.new_localized_information.supplier_price).length > 0 &&
        !this.value.new_localized_information.supplier_price.price
      ) {
        return 'is-danger';
      }
      return 'is-light';
    },
    supplierPriceTypeMessage() {
      if (
        this.value.new_localized_information.supplier_price &&
        Object.keys(this.value.new_localized_information.supplier_price).length > 0 &&
        !this.value.new_localized_information.supplier_price.type
      ) {
        return 'Missing Supplier Price Unit Type';
      }
      return '';
    },
    supplierPriceTypeMessageType() {
      if (
        this.value.new_localized_information.supplier_price &&
        Object.keys(this.value.new_localized_information.supplier_price).length > 0 &&
        !this.value.new_localized_information.supplier_price.type
      ) {
        return 'is-danger';
      }
      return 'is-light';
    },
    kitchenPriceMessage() {
      if (
        this.value.new_localized_information.kitchen_price &&
        Object.keys(this.value.new_localized_information.kitchen_price).length > 0 &&
        !this.value.new_localized_information.kitchen_price.price
      ) {
        return 'Missing Kitchen Price';
      }
      if (
        this.value.new_localized_information.kitchen_price &&
        Object.keys(this.value.new_localized_information.kitchen_price).length > 0 &&
        this.value.new_localized_information.kitchen_price.price == 0
      ) {
        return 'Price must be > 0';
      }
      return '';
    },
    kitchenPriceMessageType() {
      if (
        this.value.new_localized_information.kitchen_price &&
        Object.keys(this.value.new_localized_information.kitchen_price).length > 0 &&
        !this.value.new_localized_information.kitchen_price.price
      ) {
        return 'is-danger';
      }
      return 'is-light';
    },
    kitchenPriceTypeMessage() {
      if (
        this.value.new_localized_information.kitchen_price &&
        Object.keys(this.value.new_localized_information.kitchen_price).length > 0 &&
        !this.value.new_localized_information.kitchen_price.type
      ) {
        return 'Missing Kitchen Price Unit Type';
      }
      return '';
    },
    kitchenPriceTypeMessageType() {
      if (
        this.value.new_localized_information.kitchen_price &&
        !this.value.new_localized_information.kitchen_price.type
      ) {
        return 'is-danger';
      }
      return 'is-light';
    },
    logisticsCostsPriceMessage(price) {
      if (!price) {
        return 'Missing Logistics Costs';
      }
      if (price == 0) {
        return 'Price must be > 0';
      }
      return '';
    },
    logisticsCostsPriceMessageType(price) {
      if (!price) {
        return 'is-danger';
      }
      return 'is-light';
    },
    logisticsCostsPriceTypeMessage(type) {
      if (!type) {
        return 'Missing Logistics Costs Type';
      }
      return '';
    },
    logisticsCostsPriceTypeMessageType(type) {
      if (!type) {
        // if (this.value.new_localized_information.logistics_costs && this.value.new_localized_information.logistics_costs.length > 0 && !type) {
        return 'is-danger';
      }
      return 'is-light';
    },
    vatRateTypeMessage() {
      if (this.isParentLocation() && this.value.new_localized_information.vat_rate == null) {
        return 'Missing VAT rate';
      }
      return '';
    },
    vatRateTypeMessageType() {
      if (this.isParentLocation() && this.value.new_localized_information.vat_rate == null) {
        return 'is-danger';
      }
      return 'is-light';
    },
    orderableStateMessage() {
      if (this.isParentLocation() && this.value.new_localized_information.orderable == null) {
        return 'Missing Orderable State';
      }
      return '';
    },
    orderableStateMessageType() {
      if (this.isParentLocation() && !this.value.new_localized_information.orderable) {
        return 'is-danger';
      }
      return 'is-light';
    },
    allPackingUnitsInConversionMessage() {
      if (this.isParentLocation() && !this.areAllPackagingUnitsInConversions) {
        return 'Packaging Unit is not in Conversions for at least one country';
      }
      return '';
    },
    allPackingUnitsInConversionMessageType() {
      if (this.isParentLocation() && !this.areAllPackagingUnitsInConversions) {
        return 'is-danger';
      }
      return 'is-light';
    },
  },
};
</script>

<style scoped>
.shrink.field.is-grouped .field {
  flex-shrink: 1;
}
.button-gap {
  margin-left: 8px;
}
</style>
