<template>
  <div>
    <b-message v-if="isLoading" class="is-info">Footer Loading....</b-message>
    <b-message v-if="noInfo() && !isLoading" class="is-warning">No Price Information</b-message>
    <b-table
      narrowed
      striped
      bordered
      hoverable
      :data="pricesPerMenus"
      v-if="pricesPerMenus.length"
      default-sort="menuLabel"
    >
      <template slot-scope="props">
        <b-table-column field="menuLabel" label="Menu" v-if="props.row.menuLabel" sortable>
          {{ props.row.menuLabel }}
        </b-table-column>
        <b-table-column field="price" label="Selling Price" v-if="props.row.price">
          {{ props.row.price }}
        </b-table-column>
        <b-table-column field="price" label="Selling Price (Without VAT)" v-if="props.row.price">
          {{ props.row.priceWithoutVAT }}
        </b-table-column>
        <b-table-column field="price" label="Kitchen Food Cost" v-if="props.row.grossFoodCost">
          {{ props.row.grossFoodCost }}
        </b-table-column>
        <b-table-column field="price" label="Net Food Cost" v-if="props.row.netFoodCost">
          {{ props.row.netFoodCost }}
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['menus', 'menuItemVariants', 'menuItemCost'],
  computed: {
    ...mapState({
      isLoading: (state) => state.gatewayMenus.fetchMenuItemVariants.pending || state.gatewayMenus.fetchMenus.pending,
      locationsUnnested: (state) => state.locations.allUnnested,
    }),
    pricesPerMenus() {
      var prices = [];
      var grossFoodCost = '';
      var netFoodCost = '';
      var menuItemVariants = this.menuItemVariants.filter((variant) => !variant.is_deleted);
      for (let menuItemVariant of menuItemVariants) {
        var vat = menuItemVariant.vat / 100;
        var menu = this.menus.find((menu) => menu.default_variant_uuid == menuItemVariant.variant_uuid);
        if (menu) {
          var sellingPrice = menuItemVariant.selling_price.toFixed(2);
          var sellingPriceWithoutVAT = `${(sellingPrice / (1 + vat)).toFixed(2)}`;
          if (this.menuItemCost) {
            netFoodCost = `${Math.round(
              100 - ((sellingPrice / (1 + vat) - this.menuItemCost.net_price) * 100) / (sellingPrice / (1 + vat))
            )}%`;
            grossFoodCost = `${Math.round(
              100 - ((sellingPrice / (1 + vat) - this.menuItemCost.kitchen_price) * 100) / (sellingPrice / (1 + vat))
            )}%`;
          }
          prices.push({
            menuLabel: menu.label,
            price: `${sellingPrice} ${menuItemVariant.selling_currency}`,
            priceWithoutVAT: `${sellingPriceWithoutVAT} ${menuItemVariant.selling_currency}`,
            grossFoodCost: grossFoodCost,
            netFoodCost: netFoodCost,
          });
        }
      }
      return prices;
    },
  },
  methods: {
    noInfo() {
      if (!this.menuItemVariants || !this.menus) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}
</style>
