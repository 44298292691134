import clientHook from '@/apis';

// const GatewayPlatformClient = require('@tasterkitchens/gateway-platforms-client');
const GatewayPlatformClientV2 = require('@tasterkitchens/gateway-platforms-v2-client');

const menusAPI = new GatewayPlatformClientV2.MenuManagerApi();
menusAPI.apiClient.basePath = process.env.VUE_APP_GATEWAY_PLATFORMS_V2_ENDPOINT;

export default {
  fetchMenus(queryParams) {
    queryParams = { ...queryParams, ...{ isDeleted: false } };
    return clientHook(menusAPI).listMenus(queryParams);
  },
  fetchMenuItemsLibrary(queryParams) {
    queryParams = { ...queryParams };
    return clientHook(menusAPI).listMenuItemsLibrary(queryParams);
  },
  fetchMenuItemVariants(queryParams) {
    var menuItemUUID = queryParams.menu_item_uuid;
    delete queryParams.menu_item_uuid;
    return clientHook(menusAPI).listMenuItemVariants(menuItemUUID, queryParams);
  },
};
