<template>
  <section class="section" v-if="hub">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column">
          <h1 class="title">{{ hub.label }}</h1>
          <h2 class="subtitle is-6">
            <div>{{ hub.mail }}</div>
            <div>{{ hub.address }}</div>
            <div>{{ hub.phone }}</div>
          </h2>
        </div>
        <div class="column is-narrow">
          <div class="field is-grouped">
            <b-button
              type="is-info"
              icon-left="truck"
              size="is-small"
              outlined
              @click="() => openEditShippingPoliciesModal(hub.uuid)"
            >
              Edit shipping policies
            </b-button>
            <b-button
              type="is-info"
              icon-left="pencil"
              size="is-small"
              outlined
              @click="() => openEditHubModal(hub.uuid)"
            >
              Edit
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h5 class="title is-5">Products ({{ filteredProducts.length }})</h5>
        </div>
        <div class="column is-narrow">
          <b-button type="is-rounded is-primary" icon-left="plus" @click="openAddProductModal"> Add product </b-button>
        </div>
      </div>
      <b-field>
        <b-input size="is-medium" expanded rounded v-model="computedSearchTerm" placeholder="Search for a product..." />
      </b-field>
      <b-table
        v-if="!isFetchLoading || (hub.products && hub.products.length)"
        @click="(row) => $router.push('/products/' + row.uuid)"
        class="is-hoverable is-fullwidth is-striped"
        hoverable
        striped
        :data="filteredProducts"
        paginated
        per-page="100"
        default-sort="label"
        default-sort-direction="asc"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <template #default="props">
          <b-table-column field="label" label="Name" sortable :class="getRowClass(props.row)">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column field="supplier.label" label="Supplier" sortable>
            <router-link :to="'/suppliers/' + props.row.supplier.uuid">{{ props.row.supplier.label }}</router-link>
          </b-table-column>
          <b-table-column field="product_reference" label="Ref">
            {{ getProductReference(props.row) }}
          </b-table-column>
          <b-table-column field="kitchen_price" label="Kitchen Price">
            {{ getKitchenPrice(props.row) }}
          </b-table-column>
          <b-table-column>
            <b-field grouped>
              <b-button size="is-small" type="is-info" @click.stop="openSubstituteProductsModal(props.row.uuid)">
                Substitutes rules
              </b-button>
              <b-button
                style="margin-left: 8px"
                icon-left="pencil"
                size="is-small"
                type="is-primary"
                outlined
                @click.stop="openProductReferenceDialog(props.row)"
              >
                Ref
              </b-button>
              <b-button
                :type="getExclusionDecorator(props.row) ? 'is-danger' : 'is-success'"
                :icon-left="getExclusionDecorator(props.row) ? 'close' : 'check'"
                size="is-small"
                style="margin-left: 8px; min-width: 164px"
                :outlined="!getExclusionDecorator(props.row)"
                :loading="actionsTriggered.indexOf(props.row.uuid) > -1"
                @click.stop="() => onExclusionToggle(props.row)"
              >
                <template v-if="getExclusionDecorator(props.row)"> Excluded from policies </template>
                <template v-else> Included in policies </template>
              </b-button>
              <b-button
                style="margin-left: 8px"
                icon-left="pencil"
                size="is-small"
                outlined
                disabled
                type="is-info"
                @click.stop="openEditProductModal(props.row.uuid)"
              >
                Edit
              </b-button>
              <b-button
                style="margin-left: 8px"
                size="is-small"
                icon-left="delete"
                @click.stop="openRemoveProductDialog(props.row)"
              >
                Delete
              </b-button>
            </b-field>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { displayFormatter } from '@/mixins';
import { mapState, mapActions, mapMutations } from 'vuex';
import { hubsTypes } from '@/store/mutation-types';

import EditProduct from '../Product/EditProduct.vue';
import EditShippingPolicies from '../ShippingPolicy/EditShippingPolicies.vue';
import ChooseProduct from './ChooseProduct.vue';
import EditHub from './EditHub.vue';
import SubstituteManagementModal from '@/components/SubstitutesManagement/SubstituteManagementModal';

export default {
  name: 'ShowHub',
  mixins: [displayFormatter],
  data() {
    return {
      actionsTriggered: [],
      editProductModal: null,
    };
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      hub: (state) => state.hubs.current,
      isFetchLoading: (state) => state.hubs.fetchOne.pending,
      fetchError: (state) => state.hubs.fetchOne.error,
      searchTerm: (state) => state.hubs.searchTerm,
    }),
    filteredProducts() {
      var filteredProducts = [];
      var productUuuids = [];
      for (let productIndex in this.hub.products) {
        var product = this.hub.products[productIndex];
        var corresponding_hubs = product.hubs.filter(
          (hub) => hub.location_uuid == this.currentLocation.uuid && this.hub.uuid == hub.uuid
        );
        if (
          corresponding_hubs.length > 0 &&
          !productUuuids.includes(product.uuid) &&
          product.label.toLowerCase().includes(this.searchTerm.toLowerCase())
        ) {
          filteredProducts.push(product);
          productUuuids.push(product.uuid);
        }
      }
      return filteredProducts;
    },
    productUuidsWithSameReference() {
      var productUuidsWithSameReference = {};
      this.filteredProducts.forEach((product) => {
        var productReference = this.getProductReference(product);
        if (productReference != undefined) {
          if (productUuidsWithSameReference[productReference] == undefined) {
            productUuidsWithSameReference[productReference] = [];
            productUuidsWithSameReference[productReference].push(product.uuid);
          } else {
            productUuidsWithSameReference[productReference].push(product.uuid);
          }
        }
      });
      return productUuidsWithSameReference;
    },
    computedSearchTerm: {
      get() {
        return this.searchTerm;
      },
      set(value) {
        this.setSearchTerm(value);
      },
    },
    displayLocalizedPrice() {
      return !(this.currentLocation.parent_uuid === null);
    },
  },
  methods: {
    ...mapActions('hubs', ['fetchOne', 'addProduct', 'removeProduct', 'updateProductHubAssociation']),
    ...mapMutations('hubs', { setSearchTerm: hubsTypes.SET_SEARCH_TERM }),
    ...mapActions('products', ['buildLocalizedInformations']),
    openAddProductModal() {
      this.$buefy.modal.open({
        component: ChooseProduct,
        parent: this,
        hasModalCard: true,
        events: {
          close: (productUuid) =>
            this.addProduct(productUuid).then(() => {
              var corresponding_product = this.filteredProducts.find((product) => product.uuid === productUuid);
              if (corresponding_product) {
                this.openProductReferenceDialog(corresponding_product);
              }
            }),
        },
      });
    },
    openEditHubModal(id) {
      this.$buefy.modal.open({
        component: EditHub,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openEditProductModal(id) {
      this.$buefy.modal.open({
        component: EditProduct,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openEditShippingPoliciesModal(uuid) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditShippingPolicies,
        props: { uuid, providerType: 'hub' },
      });
    },
    openSubstituteProductsModal(productUuid) {
      const product = this.hub.products.find(({ uuid }) => uuid === productUuid);

      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: SubstituteManagementModal,
        props: {
          productLabel: product.label,
          productUuid: product.uuid,
          providerType: 'HUB',
          providerUuid: this.hub.uuid,
        },
      });
    },
    openRemoveProductDialog(product) {
      this.$buefy.dialog.confirm({
        title: `Remove product from ${this.hub.label}`,
        message: `Are you sure you want to <b>remove</b> ${product.label}?`,
        confirmText: 'Remove product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeProduct(product.uuid),
      });
    },
    getExclusionDecorator(product) {
      const associatedHub = product.hubs.find(
        (hub) => hub.location_uuid === this.currentLocation.uuid && this.hub.uuid === hub.uuid
      );
      return associatedHub ? associatedHub.exclude_from_shipping_policies || false : false;
    },
    getProductReference(product) {
      const associatedHub = product.hubs.find(
        (hub) => hub.location_uuid === this.currentLocation.uuid && this.hub.uuid === hub.uuid
      );
      return associatedHub ? associatedHub.product_reference : '';
    },
    onExclusionToggle(product) {
      const associatedHub = product.hubs.find(
        (hub) => hub.location_uuid === this.currentLocation.uuid && this.hub.uuid === hub.uuid
      );
      if (!associatedHub) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          duration: 2000,
          message: `Product ${product.label} does not appear to be associated with the current hub!`,
        });
        return;
      }

      const nextState = !associatedHub.exclude_from_shipping_policies;

      if (this.actionsTriggered.indexOf(product.uuid) > -1) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          duration: 2000,
          message: `An action is already submitted on product ${product.label}`,
        });
        return;
      }

      this.actionsTriggered.push(product.uuid);
      this.updateProductHubAssociation({
        productUuid: product.uuid,
        excludeFromShippingPolicies: nextState,
      })
        .then(() => {
          this.$buefy.toast.open({
            type: 'is-info',
            position: 'is-bottom',
            duration: 2000,
            message: nextState
              ? `Product ${product.label} is now excluded from hub shipping policies`
              : `Product ${product.label} is now included in hub shipping policies`,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: 'is-danger',
            position: 'is-bottom',
            duration: 2000,
            message: `Unable to update exclusion from shipping policies rule for product ${product.label}`,
          });
        })
        .finally(() => {
          this.actionsTriggered = this.actionsTriggered.filter((uuid) => uuid !== product.uuid);
        });
    },
    openProductReferenceDialog(product) {
      this.$buefy.dialog.prompt({
        message: `Product Reference : <b> ${product.label} - ${this.hub.label} (${this.currentLocation.label}) </b>`,
        inputAttrs: {
          value: this.getProductReference(product),
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.updateProductHubAssociation({
            productUuid: product.uuid,
            productReference: value,
          });
        },
      });
    },
    getRowClass(product) {
      var productRefrence = this.getProductReference(product);
      if (
        productRefrence in this.productUuidsWithSameReference &&
        this.productUuidsWithSameReference[productRefrence].length > 1
      )
        return 'has-background-danger';
    },
    getKitchenPrice(product) {
      var localizedInformation = this.getLocalizedInformations(product);
      if (
        localizedInformation &&
        localizedInformation.kitchen_price &&
        Object.keys(localizedInformation.kitchen_price).length > 0 &&
        localizedInformation.kitchen_price.price
      ) {
        var unit = this.getKitchenPriceUnit(localizedInformation);
        var deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.kitchen_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getKitchenPriceUnit(localizedInformation) {
      if (localizedInformation.kitchen_price.type == 'PER_CATALOGUE_UNIT') {
        return localizedInformation.catalogue_unit;
      } else {
        return localizedInformation.packaging_unit;
      }
    },
    getLocalizedInformations(product) {
      var localizedInformation = null;
      if (product && product.localized_informations && product.localized_informations.length > 0) {
        var localizedInformations = product.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
        );
        if (localizedInformations.length > 0) {
          localizedInformation = { ...localizedInformations[0], location_id: location.id };
        }
      }
      return localizedInformation;
    },
  },
  mounted() {
    this.fetchOne({ uuid: this.$route.params.hubId });
  },
};
</script>

<style scoped>
tr:hover {
  cursor: pointer;
}

.column.is-narrow .button.is-small {
  margin: 0em 0.25em;
}
</style>
