<template>
  <div class="box modal-card">
    <h3 class="subtitle">Edit supplier</h3>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching this supplier. </b-message>
    <div v-else>
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <SupplierForm
        v-if="supplier.uuid"
        v-model="supplier"
        :parent-localized-information="parentLocalizedInformation"
        :current-location="currentLocation"
        :is-saving="isSaveLoading"
        :is-fetching-localized-info="isFetchingLocalizedInfo"
        :tags="tags"
        :showKitchensSpecific="isSupplyAdmin"
        remote-fetching
        @localized-supplier:fetch="getLocalizedInfo"
        @save="update"
        @cancel="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SupplierForm from '@/components/Supplier/SupplierForm.vue';
import { errorToaster } from '@/mixins';
import { suppliersTypes } from '@/store/mutation-types';

export default {
  name: 'EditSupplier',
  components: { SupplierForm },
  mixins: [errorToaster],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      tags: (state) => state.suppliers.tags,
      currentSupplier: (state) => state.suppliers.current,
      isFetchingLocalizedInfo: (state) => state.suppliers.fetchLocalizedInformation.pending,
      fetchError: (state) => state.suppliers.fetchOne.error,
      isSaveLoading: (state) => state.suppliers.updateOne.pending,
      saveError: (state) => state.suppliers.updateOne.error,
      currentLocation: (state) => state.locations.current,
    }),
    isSupplyAdmin() {
      return this.$auth.getUserRoles().includes('Supply Admin');
    },
    isFetchLoading() {
      return this.$store.state.suppliers.fetchOne.pending;
    },
    supplier: {
      get() {
        return this.currentSupplier;
      },
      set(value) {
        this.setCurrentSupplier(value);
      },
    },
    parentLocalizedInformation() {
      if (this.supplier.parentLocalizedInformation) {
        let { location_uuid, available_for_ordering, use_translation_with_provider } =
          this.supplier.parentLocalizedInformation;
        let parentLocation = this.currentSupplier.localized_informations.find(
          (info) => info.location_uuid === location_uuid
        );
        if (available_for_ordering !== undefined) {
          parentLocation.available_for_ordering = available_for_ordering;
        }
        if (use_translation_with_provider != undefined) {
          parentLocation.use_translation_with_provider = use_translation_with_provider;
        }
      }
      return this.currentSupplier.localized_informations
        ? this.currentSupplier.localized_informations.find(({ location_uuid }) => {
            return location_uuid === this.currentLocation.uuid && this.currentLocation.parent_uuid === null;
          })
        : null;
    },
  },
  mounted() {
    if (this.$store.state.suppliers.tags === null || this.$store.state.suppliers.tags.length == 0) {
      this.fetchTags();
    }

    this.fetchOne({ uuid: this.id }).then(() => {
      if (this.currentLocation.parent_uuid) {
        this.getLocalizedInfo(this.currentLocation.parent_uuid);
      }
      this.getLocalizedInfo(this.currentLocation.uuid);
    });
  },
  methods: {
    ...mapActions('suppliers', [
      'fetchOne',
      'updateOne',
      'fetchTags',
      'fetchLocalizedInformation',
      'addLocalizedInformation',
      'updateLocalizedInformation',
    ]),
    ...mapMutations('suppliers', { setCurrentSupplier: suppliersTypes.SET_CURRENT_SUPPLIER }),
    getLocalizedInfo(locationUuid) {
      return this.fetchLocalizedInformation({
        supplierUuid: this.id,
        locationUuid,
      });
    },
    async update() {
      const parentLocalizedInformation = { ...this.supplier.parentLocalizedInformation };
      delete this.supplier.parentLocalizedInformation;
      const localizedInfos = Array.isArray(this.supplier.localized_informations)
        ? [...this.supplier.localized_informations]
        : [];
      await this.updateOne();

      // Creating or updating localized informations
      this.supplier = { ...this.supplier, localized_informations: localizedInfos };
      if (parentLocalizedInformation) {
        const { use_translation_with_provider, available_for_ordering, location_uuid, update } =
          parentLocalizedInformation;
        if (update && location_uuid !== undefined) {
          await this.updateLocalizedInformation({
            supplierUuid: this.supplier.uuid,
            locationUuid: location_uuid,
            payload: { available_for_ordering, use_translation_with_provider },
          });
        } else if (location_uuid !== undefined) {
          await this.addLocalizedInformation({
            supplierUuid: this.supplier.uuid,
            payload: {
              location_uuid,
              available_for_ordering,
              use_translation_with_provider,
            },
          });
        }
      }
      for (const info of localizedInfos) {
        const {
          account_code,
          contact_email,
          mails,
          location_uuid,
          update,
          use_translation_with_provider,
          available_for_ordering,
        } = info;

        // Skipping unupdated localized information
        if (update === undefined) {
          continue;
        }

        if (update) {
          await this.updateLocalizedInformation({
            supplierUuid: this.supplier.uuid,
            locationUuid: location_uuid,
            payload: { account_code, contact_email, mails, use_translation_with_provider, available_for_ordering },
          });
        } else {
          await this.addLocalizedInformation({
            supplierUuid: this.supplier.uuid,
            payload: {
              account_code,
              contact_email,
              mails,
              location_uuid,
              use_translation_with_provider,
              available_for_ordering,
            },
          });
        }
      }

      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.box {
  min-width: 760px;
}
</style>
