import platformInformationsApi from '@/apis/platforms/platformInformations.js';
import { itemsInformationsTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    all: [],
    current: null,
    allByMajorDimensions: {},
    currentItemsInformationsReferential: {},
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      error: null,
      pending: false,
    },
    createOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    uploadPicture: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [itemsInformationsTypes.FETCH_ALL_SUCCESS](state, itemsInformations) {
      state.all = itemsInformations;
      itemsInformations.forEach(function (itemsInformation) {
        state.allByMajorDimensions[itemsInformation.location_uuid] =
          state.allByMajorDimensions[itemsInformation.location_uuid] || {};
        state.allByMajorDimensions[itemsInformation.location_uuid][itemsInformation.concept_uuid] =
          state.allByMajorDimensions[itemsInformation.location_uuid][itemsInformation.concept_uuid] || {};
        state.allByMajorDimensions[itemsInformation.location_uuid][itemsInformation.concept_uuid][
          itemsInformation.platform_uuid
        ] =
          state.allByMajorDimensions[itemsInformation.location_uuid][itemsInformation.concept_uuid][
            itemsInformation.platform_uuid
          ] || [];
        state.allByMajorDimensions[itemsInformation.location_uuid][itemsInformation.concept_uuid][
          itemsInformation.platform_uuid
        ].push(itemsInformation);
      });
      state.fetchAll.pending = false;
    },
    [itemsInformationsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [itemsInformationsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [itemsInformationsTypes.FETCH_ONE_REQUEST](state) {
      state.current = null;
      state.fetchOne.pending = true;
      state.fetchOne.error = null;
    },
    [itemsInformationsTypes.FETCH_ONE_SUCCESS](state, itemsInformations) {
      state.fetchOne.pending = false;
      state.current = itemsInformations;
    },
    [itemsInformationsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [itemsInformationsTypes.UPDATE_ONE_SUCCESS](state, itemInformations) {
      const index = state.all.findIndex(({ uuid }) => itemInformations.uuid === uuid);
      if (index > -1) {
        state.all.splice(index, 1, itemInformations);
      }
      state.updateOne.pending = false;
      state.updateOne.error = null;
      state.updateOne.target = null;
    },
    [itemsInformationsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
      state.updateOne.target = null;
    },
    [itemsInformationsTypes.UPDATE_ONE_REQUEST](state, itemsInformations) {
      state.updateOne.pending = true;
      state.updateOne.target = itemsInformations;
    },
    [itemsInformationsTypes.REMOVE_ONE_SUCCESS](state, itemInformations) {
      const index = state.all.findIndex(({ uuid }) => itemInformations.uuid === uuid);
      if (index > -1) {
        state.all.splice(index, 1);
      }
      state.updateOne.pending = false;
      state.updateOne.error = null;
      state.updateOne.target = null;
    },
    [itemsInformationsTypes.REMOVE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
      state.updateOne.target = null;
    },
    [itemsInformationsTypes.REMOVE_ONE_REQUEST](state, itemsInformations) {
      state.updateOne.pending = true;
      state.updateOne.target = itemsInformations;
    },
    [itemsInformationsTypes.CREATE_ONE_SUCCESS](state, itemsInformations) {
      state.current = itemsInformations;
      state.all.push(itemsInformations);
      state.createOne.pending = false;
    },
    [itemsInformationsTypes.CREATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [itemsInformationsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [itemsInformationsTypes.SET_CURRENT_ITEMS_INFORMATIONS_REFERENTIAL](state, itemsInformations) {
      state.currentItemsInformationsReferential = itemsInformations;
    },
    [itemsInformationsTypes.UPLOAD_PICTURE_SUCCESS](state, itemInformations) {
      const index = state.all.findIndex(({ uuid }) => itemInformations.uuid === uuid);
      if (index > -1) {
        state.all.splice(index, 1, itemInformations);
      }
      state.uploadPicture.pending = false;
    },
    [itemsInformationsTypes.UPLOAD_PICTURE_FAILURE](state, error) {
      state.current = null;
      state.uploadPicture.pending = false;
      state.uploadPicture.error = error;
    },
    [itemsInformationsTypes.UPLOAD_PICTURE_REQUEST](state) {
      state.uploadPicture.pending = true;
    },
  },
  actions: {
    async fetchAll({ commit, rootState }, opts) {
      commit(itemsInformationsTypes.FETCH_ALL_REQUEST);

      const stateParameters = {
        locationUuids: [...rootState.locations.current.location_tree],
      };
      const request = { ...opts, ...stateParameters };

      return platformInformationsApi
        .fetchAll(request)
        .then((itemsInformations) => commit(itemsInformationsTypes.FETCH_ALL_SUCCESS, itemsInformations))
        .catch((error) => commit(itemsInformationsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, uuid) {
      commit(itemsInformationsTypes.FETCH_ONE_REQUEST);

      return platformInformationsApi
        .fetchOne(uuid)
        .then((itemInformations) => commit(itemsInformationsTypes.FETCH_ONE_SUCCESS, itemInformations))
        .catch((error) => commit(itemsInformationsTypes.FETCH_ONE_FAILURE, error));
    },
    async createOne({ commit }, creationRequest) {
      commit(itemsInformationsTypes.CREATE_ONE_REQUEST);
      return platformInformationsApi
        .createOne(creationRequest)
        .then((itemsInformations) => commit(itemsInformationsTypes.CREATE_ONE_SUCCESS, itemsInformations))
        .catch((error) => commit(itemsInformationsTypes.CREATE_ONE_FAILURE, error));
    },
    async updateOne({ commit }, itemInformationsUpdated) {
      var updateRequest = { ...itemInformationsUpdated };
      commit(itemsInformationsTypes.UPDATE_ONE_REQUEST);
      const itemInformationUuid = updateRequest.uuid;
      delete updateRequest.uuid;
      return platformInformationsApi
        .updateOne(itemInformationUuid, updateRequest)
        .then((itemInformations) => commit(itemsInformationsTypes.UPDATE_ONE_SUCCESS, itemInformations))
        .catch((error) => commit(itemsInformationsTypes.UPDATE_ONE_FAILURE, error));
    },
    async removeOne({ commit }, information) {
      var removeRequest = { ...information, active: false };
      commit(itemsInformationsTypes.REMOVE_ONE_REQUEST);
      const itemInformationUuid = removeRequest.uuid;
      delete removeRequest.uuid;
      return platformInformationsApi
        .updateOne(itemInformationUuid, removeRequest)
        .then((itemInformations) => commit(itemsInformationsTypes.REMOVE_ONE_SUCCESS, itemInformations))
        .catch((error) => commit(itemsInformationsTypes.REMOVE_ONE_FAILURE, error));
    },
    async uploadPicture({ commit }, body) {
      commit(itemsInformationsTypes.UPLOAD_PICTURE_REQUEST);
      return platformInformationsApi
        .uploadPicture(
          body.conceptUuid,
          body.itemUuid,
          body.locationUuid,
          body.picture,
          body.platformUuid,
          body.basewidth
        )
        .then((itemInformations) => commit(itemsInformationsTypes.UPLOAD_PICTURE_SUCCESS, itemInformations))
        .catch((error) => commit(itemsInformationsTypes.UPLOAD_PICTURE_FAILURE, error));
    },
  },
};
