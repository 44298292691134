<template>
  <div class="box modal-card">
    <b-loading :active="multipleComputePriceLoading" v-if="!fetchError"></b-loading>
    <b-loading :active="isLoading" v-if="!fetchError"></b-loading>
    <h3 class="subtitle">Menu Item Recipe</h3>
    <div class="is-divider" :data-content="$t('add.choose')"></div>
    <b-field grouped>
      <b-autocomplete
        expanded
        rounded
        v-model="searchTerm"
        :placeholder="$t('search.menu-item-placeholder')"
        icon="magnify"
        field="label"
        :data="filteredData"
        :append-to-body="true"
        @select="(option) => selectMenuItem(option)"
      >
        <template slot="empty">{{ $t('search.empty') }}</template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'ImportMenuItemRecipe',
  props: ['locationUuids'],
  data: () => ({
    searchTerm: '',
    multipleComputePriceLoading: false,
  }),
  methods: {
    ...mapActions('menuItems', ['fetchAll']),
    ...mapActions('items', ['computeItemPrice']),
    selectMenuItem(menuItemSelected) {
      this.multipleComputePriceLoading = true;
      var allCombinations = [];
      for (let locationUUID of this.locationUuids) {
        var location = this.locationsUnnested.find((location) => location.uuid == locationUUID);
        if (!location.is_deleted) {
          for (let recipeElement of menuItemSelected.recipe) {
            allCombinations.push({
              quantity: recipeElement.quantity,
              unit: recipeElement.unit,
              uuid: recipeElement.uuid,
              locationUUID: locationUUID,
              label: recipeElement.label,
            });
          }
        }
      }
      Promise.all(
        allCombinations.map((combination) =>
          this.computeItemPrice({
            uuid: combination.uuid,
            quantity: combination.quantity,
            unit: combination.unit,
            label: combination.label,
            locationUuids: this.getLocationUUIDs(combination.locationUUID),
            save: false,
          })
        )
      ).then((responses) => {
        this.multipleComputePriceLoading = false;
        var addItem = true;
        for (let response of responses) {
          var prices = response.prices;
          var label = response.opts.label;
          if (
            !(
              prices &&
              prices.cost_details &&
              prices.cost_details.kitchen_cost != null &&
              prices.cost_details.supplier_cost != null
            )
          ) {
            addItem = false;
            this.$buefy.dialog.confirm({
              title: `Adding ${label}`,
              message: `You can not <b>add</b> this ${label} to recipe: <br> ${this.getAlertMessage(
                prices,
                response.opts
              )}`,
              type: 'is-danger',
              hasIcon: true,
              cancelText: `Go to ${label} page`,
              onCancel: () => this.goToItems(),
            });
          }
        }
        if (addItem) {
          this.$emit('selected', menuItemSelected);
          this.closeModal();
        }
      });
    },
    getAlertMessage(prices, opts) {
      var locationLabel = this.getLocationLabel(opts.locationUuids[0]);
      if (prices == null || prices.cost_details == null) {
        return `Item Price is not defined for <b>'${opts.unit}'</b> in <b> ${locationLabel} </b>`;
      }
      if (!this.current.item_measure.map((item_measure) => item_measure.unit).includes(opts.unit)) {
        return `<b>'${opts.unit}'</b> is not in ${opts.label}' s item measures`;
      }
      if (prices.cost_details.kitchen_cost == null) {
        return `Kitchen Price of <b>${opts.quantity} ${opts.unit}</b> of <b>${opts.label}</b> can not be computed in <b> ${locationLabel} </b>`;
      }
      if (prices.cost_details.supplier_cost == null) {
        return `Supplier Price of <b>${opts.quantity} ${opts.unit}</b> of <b>${opts.label}</b> can not be computed in <b> ${locationLabel} </b>`;
      }
      return `Price Issue in <b> ${locationLabel} </b>`;
    },
    getLocationLabel(locationUUID) {
      var location = this.locationsUnnested.filter((location) => location.uuid == locationUUID);
      if (location && location.length > 0) {
        return location[0].label;
      }
      return '';
    },
    getLocationUUIDs(locationUUID) {
      var location = this.locationsUnnested.find((location) => location.uuid == locationUUID);
      if (!location.parent_uuid) {
        return [location.uuid];
      }
      return [location.uuid, location.parent_uuid];
    },
    goToItems() {
      return this.toPage('/items/' + this.current.uuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    closeModal: function () {
      this.$parent.close();
    },
  },
  computed: {
    ...mapState(['concepts']),
    ...mapState({
      menuItems: (state) => state.menuItems.all,
      fetchError: (state) => state.menuItems.fetchAll.error,
      locationsUnnested: (state) => state.locations.allUnnested,
      isLoading: (state) => state.menuItems.fetchAll.pending,
    }),
    ...mapGetters('menuItems', ['filterMenuItemsByLabel']),
    currentConcept: function () {
      return this.concepts.currentConcept;
    },
    filteredData() {
      if (this.menuItems) {
        return this.menuItems.filter((option) => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
      }
      return [];
    },
  },
  mounted() {
    this.fetchAll();
  },
};
</script>
