<template>
  <section class="section" v-if="supplier">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">{{ supplier.label }}</h1>
          <h2 class="subtitle is-6">
            <div>{{ supplier.mail }}</div>
            <div>{{ supplier.address }}</div>
            <div>{{ supplier.phone }}</div>
          </h2>
        </div>
        <div class="column is-narrow"></div>
      </div>
      <div class="columns">
        <div class="column">
          <h5 class="title is-5">Products ({{ supplier.products.length }})</h5>
        </div>
        <div class="column is-narrow">
          <div class="field is-grouped">
            <b-button
              type="is-info"
              icon-left="truck"
              size="is-small"
              outlined
              @click="() => openEditShippingPoliciesModal(supplier.uuid)"
            >
              Edit shipping policies
            </b-button>
            <b-button
              type="is-info"
              icon-left="pencil"
              size="is-small"
              outlined
              @click="openEditSupplierModal(supplier.uuid)"
            >
              Edit
            </b-button>
            <b-button
              type="is-primary"
              :disabled="canCreateProduct()"
              icon-left="plus"
              size="is-small"
              outlined
              @click="openNewProductModal"
            >
              New product
            </b-button>
          </div>
        </div>
      </div>
      <b-table
        class="is-hoverable is-fullwidth is-striped"
        @click="(row) => $router.push('/products/' + row.uuid)"
        hoverable
        striped
        :data="supplier.products"
        paginated
        per-page="10"
        default-sort="label"
        default-sort-direction="asc"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <template slot-scope="props">
          <b-table-column field="label" label="Name" searchable sortable>
            <b>{{ props.row.label }}</b>
          </b-table-column>
          <b-table-column field="product_reference" label="Reference" searchable sortable>
            {{ props.row.product_reference }}
          </b-table-column>
          <b-table-column field="Supplier Price" label="Supplier Price">
            {{ getSupplierPrice(props.row) }}
          </b-table-column>
          <b-table-column field="Kitchen Price" label="Kitchen Price">
            {{ getKitchenPrice(props.row) }}
          </b-table-column>
          <b-table-column>
            <b-field grouped>
              <b-button size="is-small" type="is-info" @click.stop="openSubstituteProductsModal(props.row.uuid)">
                Substitutes rules
              </b-button>
              <b-button
                style="margin-left: 8px"
                icon-left="pencil"
                size="is-small"
                outlined
                type="is-info"
                disabled
                @click.stop="openEditProductModal(props.row.uuid)"
              >
                Edit
              </b-button>
            </b-field>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { displayFormatter } from '@/mixins';

import EditProduct from '@/views/Product/EditProduct';
import EditShippingPolicies from '../ShippingPolicy/EditShippingPolicies.vue';
import NewProduct from './Product/NewProduct';
import EditSupplier from './EditSupplier.vue';
import SubstituteManagementModal from '@/components/SubstitutesManagement/SubstituteManagementModal';

export default {
  name: 'ShowSuplier',
  mixins: [displayFormatter],
  data: () => ({
    editProductModal: null,
  }),
  computed: {
    ...mapState({
      supplier: (state) => state.suppliers.current,
      isFetchLoading: (state) => state.suppliers.fetchOne.pending,
      fetchError: (state) => state.suppliers.fetchOne.error,
      currentLocation: (state) => state.locations.current,
    }),
    displayLocalizedPrice() {
      return !(this.currentLocation.parent_uuid === null);
    },
  },
  methods: {
    ...mapActions('suppliers', ['fetchOne']),
    ...mapActions('products', { removeProduct: 'removeOne' }),
    openEditSupplierModal(id) {
      this.$buefy.modal.open({
        component: EditSupplier,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openNewProductModal() {
      this.$buefy.modal.open({
        component: NewProduct,
        parent: this,
        hasModalCard: true,
      });
    },
    openEditProductModal(id) {
      this.$buefy.modal.open({
        component: EditProduct,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openEditShippingPoliciesModal(uuid) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditShippingPolicies,
        props: { uuid, providerType: 'supplier' },
      });
    },
    openSubstituteProductsModal(productUuid) {
      const product = this.supplier.products.find(({ uuid }) => uuid === productUuid);

      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: SubstituteManagementModal,
        props: {
          productLabel: product.label,
          productUuid: product.uuid,
          providerType: 'SUPPLIER',
          providerUuid: this.supplier.uuid,
        },
      });
    },
    openRemoveProductDialog(product) {
      this.$buefy.dialog.confirm({
        title: 'Deleting product',
        message: `Are you sure you want to <b>delete</b> ${product.label}?`,
        confirmText: 'Delete product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeProduct({ supplierUuid: this.supplier.uuid, productUuid: product.uuid }),
      });
    },
    getSupplierPrice(product) {
      var localizedInformation = this.getLocalizedInformations(product);
      if (
        localizedInformation &&
        localizedInformation.supplier_price &&
        Object.keys(localizedInformation.supplier_price).length > 0 &&
        localizedInformation.supplier_price.price
      ) {
        var unit = this.getSupplierPriceUnit(product, localizedInformation);
        var deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.supplier_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getSupplierPriceUnit(product, localizedInformation) {
      if (localizedInformation.supplier_price.type == 'PER_CATALOGUE_UNIT') {
        return this.getCatalogueUnit(product);
      } else {
        return this.getPackagingUnit(product);
      }
    },
    getKitchenPrice(product) {
      var localizedInformation = this.getLocalizedInformations(product);
      if (
        localizedInformation &&
        localizedInformation.kitchen_price &&
        Object.keys(localizedInformation.kitchen_price).length > 0 &&
        localizedInformation.kitchen_price.price
      ) {
        var unit = this.getKitchenPriceUnit(product, localizedInformation);
        var deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.kitchen_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getKitchenPriceUnit(product, localizedInformation) {
      if (localizedInformation.kitchen_price.type == 'PER_CATALOGUE_UNIT') {
        return this.getCatalogueUnit(product);
      } else {
        return this.getPackagingUnit(product);
      }
    },
    getLocalizedInformations(product) {
      var localizedInformation = null;
      if (product && product.localized_informations && product.localized_informations.length > 0) {
        var localizedInformations = product.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
        );
        if (localizedInformations.length > 0) {
          localizedInformation = { ...localizedInformations[0], location_id: location.id };
        }
      }
      return localizedInformation;
    },
    getCountryLocalizedInformation(product) {
      if (!this.currentLocation.parent_uuid) {
        return (
          product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
          )[0] || {}
        );
      } else {
        return (
          product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.parent_uuid
          )[0] || {}
        );
      }
    },
    getCatalogueUnit(product) {
      return this.getCountryLocalizedInformation(product).catalogue_unit;
    },
    getPackagingUnit(product) {
      return this.getCountryLocalizedInformation(product).packaging_unit;
    },
    getSupplierProductsCount() {
      if (this.supplier.products) {
        return this.supplier.products.length;
      }
      return 0;
    },
    canCreateProduct() {
      if (this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    creationProductMessage() {
      if (this.currentLocation.parent_uuid) {
        return 'Product can only be created from Country Location';
      }
      return '';
    },
  },
  mounted() {
    this.fetchOne({ uuid: this.$route.params.supplierId, includeLocalizedInfos: true });
  },
};
</script>

<style scoped>
tr:hover {
  cursor: pointer;
}

.column.is-narrow .button.is-small {
  margin: 0em 0.25em;
}
</style>
