<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">Suppliers {{ suppliers && `(${suppliers.length})` }}</h1>
        </div>
        <div class="column is-narrow">
          <b-button type="is-rounded is-primary" icon-left="plus" @click="openNewSupplierModal">
            New supplier
          </b-button>
        </div>
      </div>
      <b-message type="is-danger" v-if="error"> An error has occured while fetching suppliers. </b-message>
      <div v-if="!error">
        <b-loading :active.sync="isLoading"></b-loading>
        <b-field>
          <b-input
            size="is-medium"
            expanded
            rounded
            v-model="computedSearchTerm"
            placeholder="Search for a supplier..."
          ></b-input>
        </b-field>
        <b-table
          v-if="!isLoading"
          class="is-hoverable is-fullwidth is-striped"
          @click="(row) => $router.push('/suppliers/' + row.uuid)"
          hoverable
          striped
          :data="filteredSuppliers"
          paginated
          per-page="10"
          :current-page.sync="currentPage"
          default-sort="label"
          default-sort-direction="asc"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Name" sortable>
              <b>{{ props.row.label }}</b>
            </b-table-column>
            <b-table-column field="mail" label="Email">
              {{ props.row.mail }}
            </b-table-column>
            <b-table-column field="address" label="Address">
              {{ props.row.address }}
            </b-table-column>
            <b-table-column field="website" label="Website">
              {{ props.row.website }}
            </b-table-column>
            <b-table-column>
              <div class="field is-grouped">
                <b-button
                  type="is-info"
                  icon-left="truck"
                  size="is-small"
                  outlined
                  @click.stop="openEditShippingPoliciesModal(props.row.uuid)"
                >
                  Shipping policies
                </b-button>
                <b-button
                  type="is-info"
                  icon-left="pencil"
                  size="is-small"
                  outlined
                  @click.stop="openEditSupplierModal(props.row.uuid)"
                >
                  Edit
                </b-button>
                <b-button
                  type="is-danger"
                  size="is-small"
                  icon-left="delete"
                  outlined
                  @click.stop="openRemoveSupplierDialog(props.row)"
                >
                  Delete
                </b-button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { suppliersTypes } from '@/store/mutation-types';
import EditShippingPolicies from '../ShippingPolicy/EditShippingPolicies.vue';
import EditSupplier from './EditSupplier.vue';
import NewSupplier from './NewSupplier.vue';

export default {
  data: () => ({
    currentPage: 1,
  }),
  computed: {
    ...mapState({
      searchTerm: (state) => state.suppliers.searchTerm,
      suppliers: (state) => state.suppliers.all,
      isLoading: (state) => state.suppliers.fetchAll.pending,
      error: (state) => state.suppliers.fetchAll.error,
    }),
    filteredSuppliers() {
      return this.suppliers.filter((supplier) => supplier.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    computedSearchTerm: {
      get() {
        return this.searchTerm;
      },
      set(value) {
        this.setSearchTerm(value);
      },
    },
  },
  methods: {
    ...mapActions('suppliers', ['fetchAll', 'removeOne']),
    ...mapMutations('suppliers', { setSearchTerm: suppliersTypes.SET_SEARCH_TERM }),
    openNewSupplierModal() {
      this.$buefy.modal.open({
        component: NewSupplier,
        parent: this,
        hasModalCard: true,
      });
    },
    openEditSupplierModal(id) {
      this.$buefy.modal.open({
        component: EditSupplier,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openEditShippingPoliciesModal(uuid) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditShippingPolicies,
        props: { uuid, providerType: 'supplier' },
      });
    },
    openRemoveSupplierDialog(supplier) {
      this.$buefy.dialog.confirm({
        title: 'Deleting supplier',
        message: `Are you sure you want to <b>delete</b> ${supplier.label}? \n <b>All products</b> from this supplier will also be <b> deleted </b>.`,
        confirmText: 'Delete supplier',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeOne(supplier.uuid),
      });
    },
  },
  mounted() {
    if (this.$store.state.suppliers.all === null || this.$store.state.suppliers.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}

.button.is-small {
  margin: 0em 0.25em;
}
</style>
