<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <b-button
          class="is-info"
          outlined
          icon-left="plus-circle"
          @click="openNewModifierGroupModal"
          v-if="isItemMenuEditor()"
        >
          Add Modifier Group
        </b-button>
      </div>
    </div>
    <Container
      @drop="onDrop"
      drag-handle-selector=".column-drag-handle"
      v-if="modifierGroupsSorted.every((modifierGroup) => modifierGroup != null)"
    >
      <Draggable v-for="modifierGroup in modifierGroupsSorted" :key="modifierGroup.uuid">
        <MenuItemModiferGroupCard
          v-if="modifierGroup.uuid"
          :item="menuItem"
          :modifierGroup="modifierGroup"
          :currentLocation="currentLocation"
          :requiredStateUpdating="requiredStateUpdating"
          itemType="menuItem"
          @update="updateRequiredModifierGroup"
          @remove="deleteModifierGroup(modifierGroup)"
          @edit="openEditModifierGroupModal(modifierGroup.uuid)"
        />
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';
import { auth, DragAndDrop } from '@/mixins';
import { menuItemsTypes } from '@/store/mutation-types';

import EditModifierGroup from '@/views/ModifierGroup/EditModifierGroup.vue';
import ChooseModifierGroup from '@/views/ModifierGroup/ChooseModifierGroup.vue';
import MenuItemModiferGroupCard from './MenuItemModifierGroupCard.vue';

export default {
  mixins: [auth, DragAndDrop],
  props: ['modifiers'],
  components: { MenuItemModiferGroupCard, Container, Draggable },
  computed: {
    ...mapState({
      currentModifierGroup: (state) => state.modifierGroups.current,
      currentLocation: (state) => state.locations.current,
      locations: (state) => state.locations.all,
      menuItem: (state) => state.menuItems.current,
      modifierGroups: (state) => state.menuItems.currentModifierGroups,
      requiredStateUpdating: (state) => state.menuItems.updateModifierGroup.pending,
    }),
    modifierGroupsSorted: {
      get() {
        if (!this.modifierGroups) return [];
        return this.menuItem.modifier_group_uuids_sorted.map((uuid) =>
          this.modifierGroups.find((modifierGroup) => modifierGroup.uuid == uuid)
        );
      },
    },
  },
  methods: {
    ...mapActions('menuItems', [
      'fetchModifierGroups',
      'removeModifierGroup',
      'addModifierGroup',
      'updateModifierGroup',
      'fetchOne',
    ]),
    ...mapMutations('menuItems', { updateCurrentModifiersGroups: menuItemsTypes.UPDATE_CURRENT_MODIFIER_GROUPS }),
    openNewModifierGroupModal() {
      this.$buefy.modal.open({
        component: ChooseModifierGroup,
        parent: this,
        hasModalCard: true,
        events: {
          close: (modifierGroupUuid) => this.addModifierGroup(modifierGroupUuid).then(() => this.fetchModifierGroups()),
        },
      });
    },
    openEditModifierGroupModal(uuid) {
      this.$buefy.modal.open({
        component: EditModifierGroup,
        props: { uuid },
        parent: this,
        hasModalCard: true,
        events: {
          close: () => this.updateCurrentModifiersGroups(this.currentModifierGroup),
        },
      });
    },
    onDrop(dropResult) {
      var modifierGroupUuidsSorted = this.applyDrag(this.modifierGroupsSorted, dropResult).map(
        (modifierGroup) => modifierGroup.uuid
      );
      this.$emit('update:modifierGroupUuidsSorted', modifierGroupUuidsSorted);
    },
    updateRequiredModifierGroup(isRequired, modifierGroupUuid) {
      this.updateModifierGroup({
        modifierGroupUuid: modifierGroupUuid,
        isRequired: isRequired,
      });
    },
    deleteModifierGroup(modifierGroup) {
      if (modifierGroup.location_uuids) {
        if (
          modifierGroup.location_uuids.length == 1 &&
          modifierGroup.location_uuids.includes(this.currentLocation.uuid)
        ) {
          return this.removeModifierGroup(modifierGroup.uuid);
        }
      }
      this.$buefy.dialog.confirm({
        title: 'Deleting Modifier Group',
        message: `The current Modifier Group is used accross countries. <br/> - You can <b> disable it for current location </b> clicking on 'Edit' and then 'Remove' without deleting it for all countries. <br/> - If you want to <b> remove it for all countries </b>, click on 'Remove'`,
        confirmText: 'Remove',
        onConfirm: () => this.removeModifierGroup(modifierGroup.uuid),
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
  },
};
</script>
