<template>
  <ValidationProvider
    ref="provider"
    rules="min_length:1"
    class="field"
    :class="{ 'has-error': hasError, 'is-active': active, 'is-hover': mouseOver }"
    :detect-input="false"
    :skip-if-empty="false"
    tag="div"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    v-slot="{ errors }"
  >
    <label class="label" :class="{ 'has-text-taster-critical': errors.length > 0 }">
      {{ label }}
    </label>
    <div
      ref="trigger"
      class="control has-icons-right is-expanded is-clearfix"
      @click="toggle"
      @contextmenu.prevent="toggle"
    >
      <input
        :disabled="disabled"
        type="text"
        class="input"
        :class="{ 'is-taster-critical': errors.length > 0 }"
        autocomplete="on"
        :value="placeholder"
        readonly
      />
      <span class="icon is-right">
        <i class="mdi mdi-24px" :class="{ 'mdi-chevron-down': !active, 'mdi-chevron-up': active }" />
      </span>
    </div>
    <p v-show="errors.length > 0" class="help is-taster-critical">Please select at least 1 location</p>
    <Portal>
      <div v-show="active" class="popup">
        <div
          ref="popup"
          class="inner"
          :class="{ 'has-error': errors.length > 0, 'is-active': active, 'is-hover': mouseOver }"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <LocationFilter
            :location-root="locationRoot"
            :selected-locations="locations"
            @location-filter:confirm="onConfirm"
          />
        </div>
      </div>
    </Portal>
  </ValidationProvider>
</template>

<script>
import { computePosition, getScrollParents, hide } from '@floating-ui/dom';
import { Portal } from '@linusborg/vue-simple-portal';
import { ValidationProvider } from 'vee-validate';
import LocationFilter from './LocationFilter.vue';

export default {
  name: 'LocationDropdown',
  components: {
    LocationFilter,
    Portal,
    ValidationProvider,
  },
  model: {
    prop: 'locations',
    event: 'location-dropdown:update',
  },
  props: {
    locationRoot: {
      type: Object,
      required: true,
      validator(value) {
        return 'locations' in value;
      },
    },
    locations: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Where',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      hasError: false,
      mouseOver: false,
      scrollParents: [],
      skipFirstValidation: true,
    };
  },
  computed: {
    placeholder() {
      const labels = [];
      for (const location of this.locations) {
        if (labels.length === 4) {
          labels.push('…');
          break;
        }

        labels.push(location.label);
      }

      return labels.length > 0 ? labels.join(', ') : 'Selection';
    },
  },
  watch: {
    active(value) {
      if (value) {
        return;
      }

      if (this.skipFirstValidation) {
        this.skipFirstValidation = false;
      } else {
        this.$refs.provider.validate();
      }
    },
    locations(value) {
      this.$refs.provider.validate(value);
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside);
    for (const el of this.scrollParents) {
      el.removeEventListener('scroll', this.updatePosition);
      el.removeEventListener('resize', this.updatePosition);
    }
  },
  created() {
    document.addEventListener('click', this.onClickOutside);
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollParents = [...getScrollParents(this.$refs.trigger), ...getScrollParents(this.$refs.popup)];

      this.$refs.popup.style.width = `${this.$refs.trigger.offsetWidth}px`;
      for (const el of this.scrollParents) {
        el.addEventListener('scroll', this.updatePosition);
        el.addEventListener('resize', this.updatePosition);
      }

      this.active = true;
      this.$refs.provider.initialValue = [...this.locations];
      this.$refs.provider.initialized = true;

      if (this.locations.length > 0) {
        this.$refs.provider.validate(this.locations);
      }

      this.updatePosition().then(() => (this.active = false));
    });
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }

      this.active = !this.active;
      if (!this.active) {
        return;
      }

      computePosition(this.$refs.trigger, this.$refs.popup, {
        placement: 'bottom-start',
        middleware: [hide()],
      }).then(
        ({
          x,
          y,
          middlewareData: {
            hide: { referenceHidden },
          },
        }) => {
          if (this.$refs.popup) {
            Object.assign(this.$refs.popup.style, {
              left: `${x}px`,
              top: `${y}px`,
              visibility: referenceHidden ? 'hidden' : 'visible',
            });
          }
        }
      );
    },
    onClickOutside(event) {
      if (event.target === this.$refs.popup || event.target === this.$refs.trigger) {
        return;
      }

      let children = this.$refs.popup.querySelectorAll('*');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      children = this.$refs.trigger.querySelectorAll('*');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      this.active = false;
    },
    onMouseOver() {
      this.mouseOver = true;
    },
    onMouseOut() {
      this.mouseOver = false;
    },
    onConfirm(locations) {
      this.toggle();

      const payload = locations.sort((left, right) => {
        const leftLabel = left.label.toUpperCase();
        const rightLabel = right.label.toUpperCase();

        if (leftLabel < rightLabel) {
          return -1;
        } else if (leftLabel > rightLabel) {
          return 1;
        }

        return 0;
      });

      this.$refs.provider.syncValue(payload);
      this.$refs.provider.validate(payload).then((result) => {
        this.hasError = !result.valid;

        this.$refs.provider.applyResult(result);
        this.$emit('location-dropdown:update', payload);
      });
    },
    async updatePosition() {
      if (!this.$refs.popup || !this.$refs.trigger) {
        return;
      }

      this.$refs.popup.style.width = `${this.$refs.trigger.offsetWidth}px`;
      const {
        x,
        y,
        middlewareData: {
          hide: { referenceHidden },
        },
      } = await computePosition(this.$refs.trigger, this.$refs.popup, {
        placement: 'bottom-start',
        middleware: [hide()],
      });
      if (this.$refs.popup) {
        Object.assign(this.$refs.popup.style, {
          left: `${x}px`,
          top: `${y}px`,
          visibility: referenceHidden ? 'hidden' : 'visible',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

.field {
  margin: 0px !important;

  &.has-error {
    margin-bottom: -16px !important;
  }

  &.is-active .input[readonly] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-width: 0px;
  }

  &.is-active .input[readonly],
  &.is-hover .input[readonly] {
    border-color: #b5b5b5 !important;
  }

  .input[readonly] {
    cursor: pointer;
    user-select: none;

    &:active,
    &:focus {
      border-color: rgba(10, 10, 10, 0.1);
    }

    &.is-taster-critical {
      border-color: $taster-critical !important;
      box-shadow: none !important;
      color: $taster-critical !important;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

.popup {
  position: absolute;
  left: 0;
  top: 0;

  .inner {
    position: relative;

    background: #fff;
    border: solid 1px rgba(10, 10, 10, 0.1);
    border-top-width: 0px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 16px 24px 8px;
    z-index: 99;

    &.has-error {
      border-color: $taster-critical !important;
    }

    &.is-active,
    &.is-hover {
      border-color: #b5b5b5;
    }
  }
}
</style>
