<template>
  <div class="box modal-card">
    <h3 class="title is-5">Edit {{ product.label }}</h3>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching this supplier. </b-message>
    <div v-if="!fetchError">
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <ProductForm
        :product.sync="product"
        :categories="categories"
        :productCreation="false"
        @save="update"
        :isSaving="isSaveLoading"
        v-if="product.uuid && categories.length"
        @cancel="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import ProductForm from '@/components/Supplier/ProductForm.vue';
import { productsTypes } from '@/store/mutation-types';
import { errorToasterDetailled } from '@/mixins';

export default {
  props: ['id'],
  mixins: [errorToasterDetailled],
  components: { ProductForm },
  computed: {
    ...mapState({
      categories: (state) => state.categories.all,
      currentLocation: (state) => state.locations.current,
      current: (state) => state.products.current,
      isFetchLoading: (state) => state.products.fetchOne.pending,
      fetchError: (state) => state.products.fetchOne.error,
      isSaveLoading: (state) =>
        state.products.updateOne.pending ||
        state.products.updateProductLocalizedInfo.pending ||
        state.products.createProductLocalizedInfo.pending,
      saveError: (state) =>
        state.products.updateOne.error ||
        state.products.updateProductLocalizedInfo.error ||
        state.products.createProductLocalizedInfo.error,
    }),
    product: {
      get() {
        return { ...this.current, ...{ new_localized_information: this.getLocalizedInformations() } };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  methods: {
    ...mapActions('categories', { fetchCategories: 'fetchAll' }),
    ...mapActions('products', ['fetchOne', 'updateOne', 'createLocalizedInformation', 'updateLocalizedInformation']),
    ...mapMutations('products', { setCurrent: productsTypes.SET_CURRENT }),
    update() {
      var productLocationInformationRequest = null;
      if (this.current.new_localized_information) {
        var localizedInformationRequest = this.current.new_localized_information;
        if (!localizedInformationRequest.logistics_costs) {
          delete localizedInformationRequest.logistics_costs;
        }
        if (!localizedInformationRequest.kitchen_price) {
          delete localizedInformationRequest.kitchen_price;
        }
        if (!localizedInformationRequest.supplier_price) {
          delete localizedInformationRequest.supplier_price;
        }
        if (!localizedInformationRequest.catalogue_unit) {
          delete localizedInformationRequest.catalogue_unit;
        }
        if (!localizedInformationRequest.packaging_unit) {
          delete localizedInformationRequest.packaging_unit;
        }
        delete localizedInformationRequest.location_id;
        delete localizedInformationRequest.uuid;
        productLocationInformationRequest = {
          ...localizedInformationRequest,
          supplierUUID: this.current.supplier.uuid,
          productUUID: this.current.uuid,
        };
      }
      this.updateOne()
        .then(() => {
          if (productLocationInformationRequest) {
            if (this.isLocalizedInformationsUpdate(productLocationInformationRequest)) {
              this.updateLocalizedInformation(productLocationInformationRequest);
            } else {
              this.createLocalizedInformation({
                ...productLocationInformationRequest,
                ...{ price: 0, currency: this.currentLocation.location_country.currency_code },
              });
            }
          }
        })
        .then(() => this.$emit('close'));
    },
    getLocalizedInformations() {
      var localizedInformation = {};
      if (this.current && this.current.localized_informations && this.current.localized_informations.length > 0) {
        var localizedInformations = this.current.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
        );
        if (localizedInformations.length > 0) {
          localizedInformation = { ...localizedInformations[0], location_id: location.id };
        }
      }
      return localizedInformation;
    },
    isLocalizedInformationsUpdate(productLocationInformationRequest) {
      var existingLocationUuids = this.current.localized_informations.map((info) => info.location_uuid);
      var requestLocationUuid = productLocationInformationRequest.location_uuid;
      return existingLocationUuids.includes(requestLocationUuid);
    },
  },
  mounted() {
    !this.categories.length && this.fetchCategories();
    this.fetchOne(this.id);
  },
};
</script>
