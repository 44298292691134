import summaryAPI from '@/apis/items/summary';
import healthchecksAPI from '@/apis/healthchecks/healthchecks';
import productUsageAPI from '@/apis/products/productUsage';
import { summaryTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    healtchecks_results: [],
    conceptProducts: [],
    prices: [],
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchConceptProducts: {
      pending: false,
      error: null,
    },
    fetchHealthchecksResults: {
      pending: false,
      error: null,
    },
    refreshHealthchecksResults: {
      pending: false,
      error: null,
    },
    refreshProductUsageResults: {
      pending: false,
      error: null,
    },
    fetchPrices: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [summaryTypes.FETCH_HEALTHCHECKS_RESULTS_REQUEST](state) {
      state.fetchHealthchecksResults.pending = true;
      state.fetchHealthchecksResults.error = null;
    },
    [summaryTypes.FETCH_HEALTHCHECKS_RESULTS_SUCCESS](state, { healtchecks_results }) {
      state.fetchHealthchecksResults.pending = false;
      state.healtchecks_results = healtchecks_results;
    },
    [summaryTypes.FETCH_HEALTHCHECKS_RESULTS_FAILURE](state, error) {
      state.healtchecks_results = [];
      state.fetchHealthchecksResults.pending = false;
      state.fetchHealthchecksResults.error = error;
    },
    [summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_REQUEST](state) {
      state.refreshHealthchecksResults.pending = true;
      state.refreshHealthchecksResults.error = null;
    },
    [summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_SUCCESS](state) {
      state.refreshHealthchecksResults.pending = false;
    },
    [summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_FAILURE](state, error) {
      state.refreshHealthchecksResults.pending = false;
      state.refreshHealthchecksResults.error = error;
    },
    [summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_REQUEST](state) {
      state.refreshProductUsageResults.pending = true;
      state.refreshProductUsageResults.error = null;
    },
    [summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_SUCCESS](state) {
      state.refreshProductUsageResults.pending = false;
    },
    [summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_FAILURE](state, error) {
      state.refreshProductUsageResults.pending = false;
      state.refreshProductUsageResults.error = error;
    },
    [summaryTypes.FETCH_CONCEPT_PRODUCTS_REQUEST](state) {
      state.fetchConceptProducts.pending = true;
      state.fetchConceptProducts.error = null;
    },
    [summaryTypes.FETCH_CONCEPT_PRODUCTS_SUCCESS](state, conceptProducts) {
      state.fetchConceptProducts.pending = false;
      state.conceptProducts = conceptProducts;
    },
    [summaryTypes.FETCH_CONCEPT_PRODUCTS_FAILURE](state, error) {
      state.conceptProducts = null;
      state.fetchConceptProducts.pending = false;
      state.fetchConceptProducts.error = error;
    },
    [summaryTypes.FETCH_PRICES_REQUEST](state) {
      state.fetchPrices.pending = true;
      state.fetchPrices.error = null;
    },
    [summaryTypes.FETCH_PRICES_SUCCESS](state, prices) {
      state.fetchPrices.pending = false;
      state.prices = prices;
    },
    [summaryTypes.FETCH_PRICES_FAILURE](state, error) {
      state.prices = null;
      state.fetchPrices.pending = false;
      state.fetchPrices.error = error;
    },
  },
  actions: {
    async fetchHealthcheckResults({ commit, rootState }) {
      commit(summaryTypes.FETCH_HEALTHCHECKS_RESULTS_REQUEST);
      return summaryAPI
        .fetchHealthcheckResults(rootState.locations.current.uuid)
        .then((healtchecks_results) => commit(summaryTypes.FETCH_HEALTHCHECKS_RESULTS_SUCCESS, { healtchecks_results }))
        .catch((error) => commit(summaryTypes.FETCH_HEALTHCHECKS_RESULTS_FAILURE, error));
    },
    async refreshHealthcheckResults({ commit }, opts) {
      commit(summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_REQUEST);
      return healthchecksAPI
        .run(opts)
        .then(() => commit(summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_SUCCESS))
        .catch((error) => commit(summaryTypes.REFRESH_HEALTHCHECKS_RESULTS_FAILURE, error));
    },
    async refreshProductUsageResults({ commit }, opts) {
      commit(summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_REQUEST);
      return productUsageAPI
        .run(opts)
        .then(() => commit(summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_SUCCESS))
        .catch((error) => commit(summaryTypes.REFRESH_PRODUCT_USAGE_RESULTS_FAILURE, error));
    },
    async fetchConceptProducts({ commit }, opts) {
      commit(summaryTypes.FETCH_CONCEPT_PRODUCTS_REQUEST);
      if (opts.location.parent_uuid === null) {
        opts.countryUuid = opts.location.location_country.uuid;
        opts.locationUuid = opts.location.uuid;
      } else {
        opts.locationUuid = opts.location.uuid;
      }

      return summaryAPI
        .fetchConceptProducts(opts)
        .then((conceptProducts) => commit(summaryTypes.FETCH_CONCEPT_PRODUCTS_SUCCESS, conceptProducts))
        .catch((error) => commit(summaryTypes.FETCH_CONCEPT_PRODUCTS_FAILURE, error));
    },
    async fetchPrices({ commit }, opts) {
      commit(summaryTypes.FETCH_PRICES_REQUEST);
      return summaryAPI
        .fetchPrices(opts)
        .then((prices) => commit(summaryTypes.FETCH_PRICES_SUCCESS, prices))
        .catch((error) => commit(summaryTypes.FETCH_PRICES_FAILURE, error));
    },
  },
};
