<template>
  <section class="section">
    <b-message type="is-danger" v-if="fetchError">
      {{ fetchError.body.detail }}
    </b-message>
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <div class="container" v-if="combo">
      <div class="columns">
        <div class="column">
          <h1 class="title">{{ combo.label }}</h1>
        </div>
        <div class="column is-narrow" v-if="isItemComboRecipeEditor()">
          <router-link to="/menu-items" class="button"> Cancel </router-link>
          <b-button
            type="is-primary"
            :loading="isSaveLoading"
            icon-left="content-save"
            style="margin-left: 8px"
            @click="save"
          >
            Save
          </b-button>
        </div>
      </div>
      <b-tabs size="medium" type="is-boxed" vertical>
        <b-tab-item>
          <template slot="header"> General </template>
          <div class="columns">
            <div class="column">
              <h5 class="title is-5">Internal Informations</h5>
              <MenuItemForm
                :label.sync="label"
                :category.sync="category"
                :tags.sync="tags"
                :categories="categories"
                :menuItemTags="menuItemTags"
                :displayMep="false"
                :displayCategory="true"
              />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Platforms</template>
          <ComboPlatform />
        </b-tab-item>
        <b-tab-item>
          <template slot="header"> Modifiers Groups </template>
          <ComboModifierGroup :modifiers="modifierGroups" :modifierGroupUuidsSorted.sync="modifierGroupUuidsSorted" />
        </b-tab-item>
        <b-tab-item>
          <template slot="header"> Pictures </template>
          <MenuItemPictures
            :menuItem="currentCombo"
            :sendPicture="sendPicture"
            :itemInformations="currentComboInformations"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { conceptsTypes } from '@/store/mutation-types';
import ComboPlatform from '@/components/Combo/ComboPlatform.vue';
import ComboModifierGroup from '@/components/Combo/ComboModifierGroup.vue';
import MenuItemPictures from '@/components/MenuItem/Pictures/MenuItemPictures.vue';
import MenuItemForm from '@/components/MenuItem/MenuItemForm.vue';
import { auth, errorToaster } from '@/mixins';

export default {
  components: { ComboPlatform, ComboModifierGroup, MenuItemForm, MenuItemPictures },
  mixins: [auth, errorToaster],
  watch: {
    currentLocation(newVal, oldVal) {
      if (newVal.uuid !== oldVal.uuid) {
        this.fetchItemInformations({ itemUuid: this.currentCombo.uuid });
      }
    },
    concepts(newVal) {
      if (newVal && newVal.length > 0) {
        if (!this.currentConcept || (this.currentConcept && Object.keys(this.currentConcept) == 0)) {
          if (
            this.concepts &&
            this.currentCombo &&
            this.currentCombo.concept_uuids &&
            this.currentCombo.concept_uuids.length == 1
          ) {
            this.setCurrentConcept(
              this.concepts.filter((concept) => concept.uuid == this.currentCombo.concept_uuids)[0]
            );
          }
        }
      }
    },
    currentCombo(newVal) {
      if (newVal) {
        if (!this.currentConcept || (this.currentConcept && Object.keys(this.currentConcept) == 0)) {
          if (
            this.concepts &&
            this.currentCombo &&
            this.currentCombo.concept_uuids &&
            this.currentCombo.concept_uuids.length == 1
          ) {
            this.setCurrentConcept(
              this.concepts.filter((concept) => concept.uuid == this.currentCombo.concept_uuids)[0]
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      currentConcept: (state) => state.concepts.current,
      currentComboInformations: (state) => state.itemsInformations.all,
      isLoading: (state) =>
        state.combos.fetchOne.pending ||
        state.combos.fetchModifierGroups.pending ||
        state.itemsInformations.uploadPicture.pending ||
        state.itemsInformations.createOne.pending ||
        state.itemsInformations.updateOne.pending,
      fetchError: (state) => state.combos.fetchOne.error,
      modifierGroups: (state) => state.modifierGroups.all,
      categories: (state) => state.combos.categories,
      currentCombo: (state) => state.combos.current,
      currentLocation: (state) => state.locations.current,
      menuItemTags: (state) => state.menuItems.tags,
      isSaveLoading: (state) =>
        state.combos.updateOne.pending ||
        state.combos.updateLocation.pending ||
        state.itemsInformations.uploadPicture.pending,
      saveError: (state) => state.combos.updateOne.error || state.combos.updateLocation.error,
    }),
    combo: {
      get() {
        return this.currentCombo;
      },
      set(value) {
        this.setCurrent(value);
      },
    },
    label: {
      get() {
        return this.combo.label;
      },
      set(value) {
        this.setCurrent({ ...this.combo, label: value });
      },
    },
    category: {
      get() {
        return this.combo.category;
      },
      set(value) {
        this.setCurrent({ ...this.combo, category: value });
      },
    },
    modifierGroupUuidsSorted: {
      get() {
        return this.combo.modifier_group_uuids_sorted;
      },
      set(value) {
        this.setCurrent({ ...this.combo, modifier_group_uuids_sorted: value });
      },
    },
    tags: {
      get() {
        return this.combo.tags;
      },
      set(value) {
        this.setCurrent({ ...this.combo, tags: value });
      },
    },
  },
  methods: {
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('combos', ['fetchOne', 'fetchCategories', 'fetchModifierGroups', 'updateLocation', 'updateOne']),
    ...mapActions('itemsInformations', {
      fetchItemInformations: 'fetchAll',
      updateItemInformation: 'updateOne',
      uploadPicture: 'uploadPicture',
    }),
    ...mapActions('locations', { fetchLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('menuItems', ['fetchTags']),
    ...mapActions('modifierGroups', { fetchAllModifierGroups: 'fetchAll' }),
    ...mapMutations('combos', { setCurrent: 'SET_CURRENT' }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
    ...mapActions('platforms', { fetchAllPlatforms: 'fetchAll' }),
    save() {
      // Promise.all(this.currentComboInformations.map((comboInformation) => this.updateItemInformation(comboInformation)))
      //   .then(this.updateLocation(this.currentLocation.uuid))
      //   .then(() => this.updateOne())
      //   .then(() => this.$router.push('/menu-items'));
      if (this.currentCombo.location_uuids.includes(this.currentLocation.uuid)) {
        this.updateLocation(this.currentLocation.uuid)
          .then(() => this.updateOne())
          .then(() => this.$router.push('/menu-items'));
      } else {
        this.updateOne().then(() => this.$router.push('/menu-items'));
      }
    },
    sendPicture(file, conceptUuid, platformUuid, locationUuid, basewidth) {
      this.uploadPicture({
        itemUuid: this.currentCombo.uuid,
        conceptUuid: conceptUuid,
        locationUuid: locationUuid,
        picture: file,
        platformUuid: platformUuid,
        basewidth: basewidth,
      });
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    if (!this.checkExistence(this.$store.state.locations.all)) {
      this.fetchLocations();
    }
    if (!this.checkExistence(this.$store.state.locations.allUnnested)) {
      this.fetchUnnestedLocations();
    }
    if (!this.checkExistence(this.$store.state.concepts.all)) {
      this.fetchConcepts();
    }
    if (!this.checkExistence(this.$store.state.platforms.all)) {
      this.fetchAllPlatforms();
    }
    if (!this.checkExistence(this.$store.state.modifierGroups.all)) {
      this.fetchAllModifierGroups();
    }
    this.fetchOne(this.$route.params.comboId).then(() => this.fetchModifierGroups());
    if (!this.checkExistence(this.$store.state.combos.categories)) {
      this.fetchCategories();
    }
    if (!this.checkExistence(this.$store.state.menuItems.tags)) {
      this.fetchTags();
    }
    this.fetchItemInformations({ itemUuid: this.$route.params.comboId });
  },
};
</script>
