<template>
  <div class="check-comparison">
    <div v-if="isFetchStatusLoading">
      <b-loading :active.sync="isFetchStatusLoading"></b-loading>
    </div>
    <div v-else>
      <div class="check-header">
        <b-button
          @click.stop="$emit('compareMenu')"
          type="is-info"
          rounded
          :loading="isCheckLoading"
          :disabled="checkDisabled()"
        >
          Check
        </b-button>
        <div v-if="comparison.backup_date || comparison.check_date">
          <b-taglist attached>
            <b-tag type="is-dark">Last Check</b-tag>
            <b-tag type="is-info">{{ comparison.check_date }}</b-tag>
          </b-taglist>
        </div>
      </div>
      <div class="check-container box card overflow">
        <div class="card-content">
          <div v-if="comparison.platform_menu || comparison.taster_menu" class="comparison">
            <div class="menu">
              <figure class="image is-96x96">
                <img src="@/assets/images/logo.png" alt="Taster Logo" />
              </figure>
              <div v-if="comparison.taster_menu.categories">
                <div
                  class="menu-category"
                  :class="{ 'error-highlight': category.status === status.FAIL }"
                  v-for="category in comparison.taster_menu.categories"
                  :key="category.uuid"
                >
                  <div class="menu-category-header">
                    <h3 class="menu-category-title">{{ localizedText(category.labels) }}</h3>
                    <h4 v-if="localizedText(category.description)" class="menu-category-subtitle">
                      {{ localizedText(category.description) }}
                    </h4>
                  </div>
                  <div
                    class="menu-card"
                    v-for="menuItem in category.menu_items"
                    :key="`Taster-${category.uuid}-${menuItem.id}`"
                  >
                    <MenuItemCard
                      class="clickable-card"
                      :class="{ 'error-highlight': menuItem.status === status.FAIL }"
                      @click.native="openModalMenuItem(menuItem, comparison.platform_menu, origin.TASTER)"
                      :menu-item="localizedMenuItem(menuItem)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="border">
              <div class="circle">
                <b-icon icon="arrow-right" size="is-medium" type="is-light" />
              </div>
            </div>

            <div class="menu">
              <figure class="image is-96x96">
                <img :src="platform.logo" alt="Taster Logo" />
              </figure>
              <div v-if="comparison.platform_menu.categories">
                <div
                  class="menu-category"
                  :class="{ 'error-highlight': category.status === status.FAIL }"
                  v-for="category in comparison.platform_menu.categories"
                  :key="category.uuid"
                >
                  <div class="menu-category-header">
                    <h3 class="menu-category-title">{{ localizedText(category.labels) }}</h3>
                    <h4 v-if="localizedText(category.description)" class="menu-category-subtitle">
                      {{ localizedText(category.description) }}
                    </h4>
                  </div>
                  <div
                    class="menu-card"
                    v-for="menuItem in category.menu_items"
                    :key="`Taster-${category.uuid}-${menuItem.id}`"
                  >
                    <MenuItemCard
                      class="clickable-card"
                      :class="{ 'error-highlight': menuItem.status === status.FAIL }"
                      @click.native="openModalMenuItem(menuItem, comparison.taster_menu, origin.PLATFORM)"
                      :menu-item="localizedMenuItem(menuItem)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RestaurantsCheckComparisonErrors v-else :status="checkStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import MenuItemCard from '@/components/Menu/MenuItemCard.vue';
import RestaurantsCheckComparisonModal from '@/components/Menu/Restaurants/RestaurantsCheckComparisonModal.vue';
import RestaurantsCheckComparisonErrors from '@/components/Menu/Restaurants/RestaurantsCheckComparisonErrors.vue';
import { errorToasterDetailled } from '@/mixins';

const origin = {
  TASTER: 'taster',
  PLATFORM: 'platform',
};

const status = {
  SUCCESS: 'Success',
  FAIL: 'Fail',
};

export default {
  name: 'RestaurantsCheckComparison',
  mixins: [errorToasterDetailled],
  components: {
    MenuItemCard,
    RestaurantsCheckComparisonErrors,
  },
  props: {
    comparison: {
      type: Object,
      required: true,
    },
    platform: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      origin: origin,
      status: status,
    };
  },
  computed: {
    ...mapState({
      isFetchStatusLoading: (state) => state.menus.fetchStatus.pending,
      isCheckLoading: (state) => state.menus.checkMenu.pending,
      isSaveLoading: (state) => state.menus.checkMenu.pending,
      checkError: (state) => state.menus.checkMenu.error,
      saveError: (state) => state.menus.checkMenu.error,
    }),
    ...mapGetters('locations', ['getLanguageCodeByPlatform']),
    checkStatus() {
      if (this.checkError) {
        return this.checkError;
      } else {
        return this.comparison.status;
      }
    },
  },
  methods: {
    checkDisabled() {
      if (this.comparison.backup_date) {
        return false;
      }
      return true;
    },
    localizedText(obj) {
      const key = Object.keys(obj).find((key) => key.includes(this.getLanguageCodeByPlatform(this.platform.id)));
      if (key) {
        return obj[key];
      }
      return null;
    },
    getMatchingMenuItem(menuItem, menuToCompare, menuItemToCompare) {
      let matchingMenuItem;
      if (menuToCompare.categories) {
        const category = menuToCompare.categories.find((category) =>
          category.menu_items.some((menuItem) => menuItem.taster_uuid === menuItemToCompare.taster_uuid)
        );
        if (category) {
          matchingMenuItem = this.localizedMenuItem(
            category.menu_items.find((menuItem) => menuItem.taster_uuid === menuItemToCompare.taster_uuid)
          );
        }
      }
      return matchingMenuItem;
    },
    getComparison(menuItem, menuToCompare, origin) {
      let tasterMenuItem, platformMenuItem;
      if (origin === this.origin.TASTER) {
        tasterMenuItem = this.localizedMenuItem(menuItem);
        platformMenuItem = this.getMatchingMenuItem(menuItem, menuToCompare, tasterMenuItem);
      } else {
        platformMenuItem = this.localizedMenuItem(menuItem);
        tasterMenuItem = this.getMatchingMenuItem(menuItem, menuToCompare, platformMenuItem);
      }
      return { tasterMenuItem, platformMenuItem };
    },
    openModalMenuItem(menuItem, menuToCompare, origin) {
      const comparison = this.getComparison(menuItem, menuToCompare, origin);
      this.$buefy.modal.open({
        component: RestaurantsCheckComparisonModal,
        props: {
          comparison,
          logos: { tasterLogo: require('@/assets/images/logo.png'), platformLogo: this.platform.logo },
        },
        width: 1100,
        parent: this,
        hasModalCard: true,
      });
    },
    localizedMenuItem(menuItem) {
      const menuItemLocation = { ...menuItem };
      menuItemLocation.uuid = menuItemLocation.taster_uuid;
      menuItemLocation.label = this.localizedText(menuItem.labels);
      menuItemLocation.description = this.localizedText(menuItem.description);
      menuItemLocation.modifierGroups = [
        ...menuItem.modifier_groups.map(
          (modifierGroup) => (modifierGroup = { ...modifierGroup, label: this.localizedText(modifierGroup.labels) })
        ),
      ];
      menuItemLocation.picture_url = menuItemLocation.image_url;
      return menuItemLocation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.check-comparison {
  max-width: 1060px;
  margin: auto;

  .check-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .tags-container {
      .tags {
        margin: 0;
        margin-left: 5px;

        .tag {
          margin: 0;
        }
      }
    }
  }

  .check-container {
    .card {
      padding: 0;

      &-content {
        &-title {
          text-align: center;
          padding: 20px;

          .title {
            margin: 0;
          }
        }

        .comparison {
          min-height: 100px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .menu {
            width: 50%;
            padding: 20px 40px;

            .image {
              margin: 0 auto;
            }

            &-category {
              padding: 0 10px 10px 10px;
              border-radius: 10px;

              &-header {
                margin-bottom: 5px;
              }

              &-title {
                font-size: 18px;
                font-weight: 500;
                padding: 5px;
                border-radius: 5px;
              }

              &-subtitle {
                font-size: 14px;
                margin-bottom: 5px;
                padding: 0 5px;
              }
            }
            &-category:not(:last-child) {
              margin-bottom: 20px;
            }

            &-card {
              padding: 5px 0;

              .clickable-card:hover {
                cursor: pointer;
              }
            }
          }
          .border {
            width: 2px;
            border: solid 2px black;
            display: flex;
            justify-content: center;
            align-items: center;

            .circle {
              position: absolute;
              background-color: black;
              height: 50px;
              width: 50px;
              border-radius: 90px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.overflow {
  max-height: 95vh;
  overflow-y: auto;
}

.error-highlight {
  box-shadow: 0px 0px 10px 3px rgba(240, 51, 98, 0.7);
}
</style>
