<template>
  <div>
    <!-- default add action -->
    <MenuDetailsCategoryDivider :position="0" @addCategory="newCategory" />
    <!-- categories and add actions display -->
    <Container @drop="onDrop" lock-axis="y">
      <Draggable v-for="(category, index) in currentMenu.categories" :key="`draggable-${index}-${category.uuid}`">
        <MenuDetailsCategory
          :key="`category-${category.uuid}`"
          :position="index"
          @deleteCategory="deleteCategory"
          v-if="menuItems && combos"
          class="category"
          :platform="platform"
        />
        <MenuDetailsCategoryDivider :position="index + 1" :key="`line-${index}`" @addCategory="newCategory" />
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { menusTypes } from '@/store/mutation-types';
import { DragAndDrop } from '@/mixins';

import { Container, Draggable } from 'vue-smooth-dnd';
import MenuDetailsCategory from '@/components/Menu/MenuDetails/MenuDetailsCategory.vue';
import MenuDetailsCategoryDivider from '@/components/Menu/MenuDetails/MenuDetailsCategoryDivider.vue';

export default {
  name: 'MenuDetailsCategories',
  mixins: [DragAndDrop],
  props: ['platform'],
  components: {
    MenuDetailsCategory,
    MenuDetailsCategoryDivider,
    Container,
    Draggable,
  },
  computed: {
    ...mapState({
      currentConcept: (state) => state.concepts.current,
      currentMenu: (state) => state.menus.current,
      menuItems: (state) => state.menuItems.all,
      combos: (state) => state.combos.all,
    }),
    ...mapGetters('locations', ['getLanguageCode']),
  },
  methods: {
    ...mapActions('itemsInformations', { fetchAllItemsInformations: 'fetchAll' }),
    ...mapMutations('menus', {
      addCategory: menusTypes.ADD_CATEGORY,
      deleteCategory: menusTypes.DELETE_CATEGORY,
      swapCategories: menusTypes.SWAP_CATEGORIES,
    }),
    newCategory(position) {
      const category = {
        description: [],
        display: true,
        labels: [{ language_code: this.getLanguageCode, translation: 'New category' }],
        menu_item_uuids: [],
      };
      this.addCategory({ position: position, newCategory: category });
    },
    // TODO: handle scroll, style
    onDrop(dropResult) {
      const categoriesSorted = this.applyDrag(this.currentMenu.categories, dropResult);
      this.swapCategories(categoriesSorted);
    },
  },
  mounted() {
    this.fetchAllItemsInformations({
      platformUuid: this.platform.uuid,
      conceptUuid: this.currentConcept.uuid,
      mergeLocations: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.category {
  margin: 5px;
  background-color: white;
}
</style>
