import hubsAPI from '@/apis/products/hubs';
import productsAPI from '@/apis/products/products';
import { hubsTypes } from '../mutation-types';
import tagsAPI from '@/apis/products/tags';

export default {
  namespaced: true,
  state: {
    all: [],
    tags: [],
    current: null,
    searchTerm: '',
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    fetchLocalizedInformation: {
      pending: false,
      error: null,
    },
    createLocalizedInformation: {
      pending: false,
      error: null,
    },
    updateLocalizedInformation: {
      pending: false,
      error: null,
    },
    addProduct: {
      pending: false,
      error: null,
    },
    removeProduct: {
      pending: false,
      error: null,
    },
    updateProductHubAssociation: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [hubsTypes.FETCH_ALL_SUCCESS](state, hubs) {
      state.all = hubs;
      state.fetchAll.pending = false;
    },
    [hubsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [hubsTypes.FETCH_ALL_REQUEST](state) {
      state.current = null;
      state.fetchAll.pending = true;
    },
    [hubsTypes.FETCH_ONE_SUCCESS](state, hub) {
      state.current = hub;
      state.fetchOne.pending = false;
    },
    [hubsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [hubsTypes.FETCH_ONE_REQUEST](state) {
      state.fetchOne.pending = true;
    },
    [hubsTypes.UPDATE_ONE_SUCCESS](state, hub) {
      const index = state.all.findIndex((_hub) => _hub.uuid === hub.uuid);
      state.all.splice(index, 1, hub);
      state.current = hub;
      state.updateOne.pending = false;
    },
    [hubsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [hubsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
      state.updateOne.error = null;
    },
    [hubsTypes.CREATE_ONE_SUCCESS](state, hub) {
      state.current = hub;
      state.all.push(hub);
      state.createOne.pending = false;
      state.createOne.error = null;
    },
    [hubsTypes.CREATE_ONE_FAILURE](state, error) {
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [hubsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [hubsTypes.REMOVE_ONE](state, uuid) {
      const index = state.all.findIndex((hub) => hub.uuid === uuid);
      state.all.splice(index, 1);
    },
    [hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.fetchLocalizedInformation.pending = true;
      state.fetchLocalizedInformation.error = null;
    },
    [hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS](state, { hubUuid, info }) {
      if (state.current.uuid === hubUuid) {
        const localized_informations = state.current.localized_informations
          ? [...state.current.localized_informations, info]
          : [info];
        state.current = { ...state.current, localized_informations };
      }

      state.all = state.all.map((hub) => {
        if (hub.uuid === hubUuid) {
          hub.localized_informations = hub.localized_informations ? [...hub.localized_informations, info] : [info];
        }

        return hub;
      });
      state.fetchLocalizedInformation.pending = false;
    },
    [hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.fetchLocalizedInformation.pending = false;
      state.fetchLocalizedInformation.error = err;
    },
    [hubsTypes.CREATE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.createLocalizedInformation.pending = true;
      state.createLocalizedInformation.error = null;
    },
    [hubsTypes.CREATE_LOCALIZED_INFORMATION_SUCCESS](state, supplier) {
      state.createLocalizedInformation.pending = false;

      if (state.current.uuid === supplier.uuid) {
        state.current.localized_informations = supplier.localized_informations;
      }
      state.all = state.all.map((s) => {
        if (s.uuid === supplier.uuid) {
          s.localized_informations = supplier.localized_informations;
        }

        return s;
      });
    },
    [hubsTypes.CREATE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.createLocalizedInformation.pending = false;
      state.createLocalizedInformation.error = err;
    },
    [hubsTypes.UPDATE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.updateLocalizedInformation.pending = true;
      state.updateLocalizedInformation.error = null;
    },
    [hubsTypes.UPDATE_LOCALIZED_INFORMATION_SUCCESS](state, supplier) {
      state.updateLocalizedInformation.pending = false;

      if (state.current.uuid === supplier.uuid) {
        state.current.localized_informations = supplier.localized_informations;
      }
      state.all = state.all.map((s) => {
        if (s.uuid === supplier.uuid) {
          s.localized_informations = supplier.localized_informations;
        }

        return s;
      });
    },
    [hubsTypes.UPDATE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.updateLocalizedInformation.pending = false;
      state.updateLocalizedInformation.error = err;
    },
    [hubsTypes.REMOVE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.removeLocalizedInformation.pending = true;
      state.removeLocalizedInformation.error = null;
    },
    [hubsTypes.REMOVE_LOCALIZED_INFORMATION_SUCCESS](state, { hubUuid, locationUuid }) {
      state.removeLocalizedInformation.pending = false;

      if (state.current.uuid === hubUuid) {
        state.current.localized_informations = state.current.localized_informations.filter(
          (info) => info.location_uuid !== locationUuid
        );
      }
      state.all = state.all.map((s) => {
        if (s.uuid === hubUuid) {
          s.localized_informations = s.localized_informations.filter((info) => info.location_uuid === locationUuid);
        }
        return s;
      });
    },
    [hubsTypes.REMOVE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.removeLocalizedInformation.pending = false;
      state.removeLocalizedInformation.error = err;
    },
    [hubsTypes.ADD_PRODUCT_SUCCESS](state, product) {
      state.current.products.push(product);
      state.addProduct.pending = false;
    },
    [hubsTypes.ADD_PRODUCT_FAILURE](state, error) {
      state.addProduct.pending = false;
      state.addProduct.error = error;
    },
    [hubsTypes.ADD_PRODUCT_REQUEST](state) {
      state.addProduct.pending = true;
    },
    [hubsTypes.REMOVE_PRODUCT_SUCCESS](state, productUuid) {
      state.current.products.splice(
        state.current.products.findIndex(({ uuid }) => uuid === productUuid),
        1
      );
      state.removeProduct.pending = false;
    },
    [hubsTypes.REMOVE_PRODUCT_FAILURE](state, error) {
      state.removeProduct.pending = false;
      state.removeProduct.error = error;
    },
    [hubsTypes.REMOVE_PRODUCT_REQUEST](state) {
      state.removeProduct.pending = true;
    },
    [hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_SUCCESS](state, product) {
      const index = state.current.products.findIndex((currentProduct) => currentProduct.uuid === product.uuid);
      if (index !== -1) {
        state.current.products.splice(index, 1, product);
      }
      state.updateProductHubAssociation.pending = false;
    },
    [hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_FAILURE](state, error) {
      state.updateProductHubAssociation.pending = false;
      state.updateProductHubAssociation.error = error;
    },
    [hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_REQUEST](state) {
      state.updateProductHubAssociation.pending = true;
    },
    [hubsTypes.SET_CURRENT_HUB](state, hub) {
      state.current = { ...hub };
    },
    [hubsTypes.FETCH_TAGS](state, tags) {
      state.tags = tags;
    },
    [hubsTypes.SET_SEARCH_TERM](state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(hubsTypes.FETCH_ALL_REQUEST);

      return hubsAPI
        .fetchAll()
        .then((hubs) => commit(hubsTypes.FETCH_ALL_SUCCESS, hubs))
        .catch((error) => commit(hubsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, { uuid, includeLocalizedInfos = false }) {
      commit(hubsTypes.FETCH_ONE_REQUEST);

      return hubsAPI
        .fetchOne(uuid, { includeLocalizedInfos })
        .then((hub) => commit(hubsTypes.FETCH_ONE_SUCCESS, hub))
        .catch((error) => commit(hubsTypes.FETCH_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(hubsTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.creation_date;
      delete data.products;
      delete data.localized_informations;
      delete data.mail;

      if (!Array.isArray(data.mails) || data.mails.length === 0) {
        data.mails = null;
      }

      return hubsAPI
        .updateOne(state.current.uuid, data)
        .then((hub) => commit(hubsTypes.UPDATE_ONE_SUCCESS, hub))
        .catch((error) => commit(hubsTypes.UPDATE_ONE_FAILURE, error));
    },
    async createOne({ commit }, hub) {
      commit(hubsTypes.CREATE_ONE_REQUEST);

      const data = { ...hub };
      delete data.localized_informations;

      return hubsAPI
        .createOne(data)
        .then((hub) => commit(hubsTypes.CREATE_ONE_SUCCESS, hub))
        .catch((error) => commit(hubsTypes.CREATE_ONE_FAILURE, error));
    },
    buildOne({ commit }) {
      const hub = hubsAPI.buildOne();

      commit(hubsTypes.SET_CURRENT_HUB, hub);
    },
    async removeOne({ commit }, uuid) {
      return hubsAPI
        .removeOne(uuid)
        .then(() => commit(hubsTypes.REMOVE_ONE, uuid))
        .catch((error) => console.error(error));
    },
    fetchLocalizedInformation({ commit }, { hubUuid, locationUuid }) {
      commit(hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_REQUEST);
      return hubsAPI
        .fetchOneLocalizedInformation(hubUuid, locationUuid)
        .then((info) => {
          commit(hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS, { hubUuid, info });
          return info;
        })
        .catch((err) => commit(hubsTypes.FETCH_ONE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    addLocalizedInformation({ commit }, { hubUuid, payload }) {
      commit(hubsTypes.CREATE_LOCALIZED_INFORMATION_REQUEST);

      return hubsAPI
        .addLocalizedInformation(hubUuid, payload)
        .then((supplier) => commit(hubsTypes.CREATE_LOCALIZED_INFORMATION_SUCCESS, supplier))
        .catch((err) => commit(hubsTypes.CREATE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    updateLocalizedInformation({ commit }, { hubUuid, locationUuid, payload }) {
      commit(hubsTypes.UPDATE_LOCALIZED_INFORMATION_REQUEST);

      return hubsAPI
        .updateLocalizedInformation(hubUuid, locationUuid, payload)
        .then((supplier) => commit(hubsTypes.UPDATE_LOCALIZED_INFORMATION_SUCCESS, supplier))
        .catch((err) => commit(hubsTypes.UPDATE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    removeLocalizedInformation({ commit }, { hubUuid, locationUuid }) {
      commit(hubsTypes.REMOVE_LOCALIZED_INFORMATION_REQUEST);

      return hubsAPI
        .removeLocalizedInformation(hubUuid, locationUuid)
        .then(() =>
          commit(hubsTypes.REMOVE_LOCALIZED_INFORMATION_SUCCESS, {
            hubUuid,
            locationUuid,
          })
        )
        .catch((err) => commit(hubsTypes.REMOVE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    async addProduct({ commit, state, rootState }, productUuid) {
      commit(hubsTypes.ADD_PRODUCT_REQUEST);

      return productsAPI
        .addToHub(productUuid, state.current.uuid, rootState.locations.current.uuid)
        .then((product) => commit(hubsTypes.ADD_PRODUCT_SUCCESS, product))
        .catch((error) => commit(hubsTypes.ADD_PRODUCT_FAILURE, error));
    },
    async removeProduct({ commit, state, rootState }, productUuid) {
      commit(hubsTypes.REMOVE_PRODUCT_REQUEST);

      return productsAPI
        .removeFromHub(productUuid, state.current.uuid, rootState.locations.current.uuid)
        .then(() => commit(hubsTypes.REMOVE_PRODUCT_SUCCESS, productUuid))
        .catch((error) => commit(hubsTypes.REMOVE_PRODUCT_FAILURE, error));
    },
    async updateProductHubAssociation(
      { commit, state, rootState },
      { productUuid, productReference = undefined, excludeFromShippingPolicies = undefined }
    ) {
      commit(hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_REQUEST);
      try {
        const product = await productsAPI.updateProductHubAssociation(state.current.uuid, productUuid, {
          locationUuid: rootState.locations.current.uuid,
          productReference,
          excludeFromShippingPolicies,
        });
        commit(hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_SUCCESS, product);
      } catch (err) {
        commit(hubsTypes.UPDATE_PRODUCT_HUB_ASSOCIATION_FAILURE, err);
        throw err;
      }
    },
    async fetchTags({ commit }) {
      return tagsAPI
        .fetchAll()
        .then((tags) => commit(hubsTypes.FETCH_TAGS, tags))
        .catch((error) => console.error(error));
    },
  },
};
