<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <b-button
          class="is-info"
          outlined
          icon-left="plus-circle"
          @click="openNewModifierGroupModal"
          v-if="isItemMenuEditor()"
        >
          Add Modifier Group
        </b-button>
      </div>
    </div>
    <Container
      @drop="onDrop"
      drag-handle-selector=".column-drag-handle"
      v-if="modifierGroupsSorted.every((modifierGroup) => modifierGroup != null)"
    >
      <Draggable v-for="modifierGroup in modifierGroupsSorted" :key="modifierGroup.uuid">
        <MenuItemModiferGroupCard
          :item="combo"
          :modifierGroup="modifierGroup"
          :currentLocation="currentLocation"
          :requiredStateUpdating="true"
          itemType="combo"
          @remove="removeModifierGroup(modifierGroup.uuid)"
          @edit="openEditModifierGroupModal(modifierGroup.uuid)"
        />
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';
import { auth, DragAndDrop } from '@/mixins';
import { combosTypes } from '@/store/mutation-types';

import EditModifierGroup from '@/views/ModifierGroup/EditModifierGroup.vue';
import ChooseModifierGroup from '@/views/ModifierGroup/ChooseModifierGroup.vue';
import MenuItemModiferGroupCard from '@/components/MenuItem/ModifierGroup/MenuItemModifierGroupCard.vue';

export default {
  mixins: [auth, DragAndDrop],
  props: ['modifiers'],
  components: { MenuItemModiferGroupCard, Container, Draggable },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentModifierGroup: (state) => state.modifierGroups.current,
      locations: (state) => state.locations.all,
      combo: (state) => state.combos.current,
      modifierGroups: (state) => state.combos.currentModifierGroups,
    }),
    modifierGroupsSorted: {
      get() {
        if (!this.modifierGroups) return [];
        return this.combo.modifier_group_uuids_sorted.map((uuid) =>
          this.modifierGroups.find((modifierGroup) => modifierGroup.uuid == uuid)
        );
      },
    },
  },
  methods: {
    ...mapActions('combos', ['fetchModifierGroups', 'removeModifierGroup', 'addModifierGroup']),
    ...mapMutations('combos', { updateCurrentModifiersGroups: combosTypes.UPDATE_CURRENT_MODIFIER_GROUPS }),
    openNewModifierGroupModal() {
      this.$buefy.modal.open({
        component: ChooseModifierGroup,
        parent: this,
        hasModalCard: true,
        events: {
          close: (modifierGroupUuid) => this.addModifierGroup(modifierGroupUuid).then(() => this.fetchModifierGroups()),
        },
      });
    },
    openEditModifierGroupModal(uuid) {
      this.$buefy.modal.open({
        component: EditModifierGroup,
        props: { uuid },
        parent: this,
        hasModalCard: true,
        events: {
          close: () => this.updateCurrentModifiersGroups(this.currentModifierGroup),
        },
      });
    },
    onDrop(dropResult) {
      var modifierGroupUuidsSorted = this.applyDrag(this.modifierGroupsSorted, dropResult).map(
        (modifierGroup) => modifierGroup.uuid
      );
      this.$emit('update:modifierGroupUuidsSorted', modifierGroupUuidsSorted);
    },
  },
};
</script>
