<template>
  <b-field grouped>
    <b-field expanded label-position="on-border" label="Quantity">
      <b-input
        type="number"
        step="0.001"
        v-model.number="itemQuantity"
        size="is-small"
        :disabled="!isItemMenuItemRecipeEditor()"
      ></b-input>
    </b-field>
    <div class="fix-unit-field">
      <UnitField v-model="itemUnit" />
    </div>
    <b-field v-if="isItemMenuItemRecipeEditor()">
      <b-button
        :disabled="currentLocation.parent_uuid"
        outlined
        icon-left="delete"
        @click="$emit('remove')"
        size="is-small"
      >
        Remove
      </b-button>
    </b-field>
    <b-field v-if="isItemMenuItemRecipeEditor()">
      <b-button
        :disabled="!isModified || currentLocation.parent_uuid"
        outlined
        icon-left="content-save"
        size="is-small"
        class="is-info"
        @click="save"
      >
        Save
      </b-button>
    </b-field>
  </b-field>
</template>

<script>
import UnitField from '@/components/UnitField.vue';
import { auth } from '@/mixins';

//TODO: Align column
export default {
  mixins: [auth],
  components: { UnitField },
  props: ['quantity', 'unit', 'currentLocation'],
  data: () => ({
    isModified: false,
  }),
  methods: {
    save() {
      this.isModified = false;
      this.$emit('save');
    },
  },
  computed: {
    itemQuantity: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.isModified = true;
        this.$emit('update:quantity', value);
      },
    },
    itemUnit: {
      get() {
        return this.unit;
      },
      set(value) {
        this.isModified = true;
        this.$emit('update:unit', value);
      },
    },
  },
};
</script>

<style scoped>
div.field {
  margin-bottom: 0;
}
.fix-unit-field {
  width: 120px;
  margin-right: 0.75rem;
}
</style>
