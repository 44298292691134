import clientHook from '@/apis';
const PlatformsManagerClient = require('@tasterkitchens/platforms-manager-client-v2');

const platformInformationsApi = new PlatformsManagerClient.PlatformInformationsApi();
platformInformationsApi.apiClient.basePath = process.env.VUE_APP_PLATFORMS_ENDPOINT;

export default {
  fetchAll(opts) {
    return clientHook(platformInformationsApi).appApisV1PlatformInformationsGetItemsInformations(opts);
  },
  fetchOne(uuid) {
    return clientHook(platformInformationsApi).appApisV1PlatformInformationsGetItemInformations(uuid);
  },
  createOne(opts) {
    return clientHook(platformInformationsApi).appApisV1PlatformInformationsCreateItemInformations(opts);
  },
  updateOne(uuid, opts) {
    return clientHook(platformInformationsApi).appApisV1PlatformInformationsUpdateItemInformations(uuid, opts);
  },
  uploadPicture(conceptUuid, itemUuid, locationUuid, picture, platformUuid, basewidth) {
    return clientHook(platformInformationsApi).appApisV1PlatformInformationsUploadPicture(
      conceptUuid,
      itemUuid,
      locationUuid,
      picture,
      platformUuid,
      { basewidth }
    );
  },
};
