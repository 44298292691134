<template>
  <div class="category" @mouseover="active = true" @mouseleave="active = false" :class="{ categoryActive: active }">
    <b-collapse animation="slide" aria-id="contentIdForA11y3">
      <div slot="trigger" slot-scope="props" role="button" aria-controls="contentIdForA11y3">
        <MenuDetailsCategoryHeader
          :active="active"
          :category.sync="category"
          :open="props.open"
          v-if="category"
          @delete="$emit('deleteCategory', position)"
        />
      </div>
      <div>
        <div class="category-content" v-if="category">
          <Container
            v-for="(row, rowIndex) in rowsCount"
            :key="`${category.uuid}-row-${rowIndex}`"
            class="columns is-multiline"
            orientation="horizontal"
            :group-name="`${category.uuid}`"
            :get-child-payload="getChildPayload(rowIndex)"
            @drop="onDrop(rowIndex, $event)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
          >
            <Draggable
              v-for="(menuItem, itemIndex) in getRowItems(rowIndex)"
              :key="`${menuItem.uuid}-item-${itemIndex}`"
              class="column is-4"
            >
              <MenuItemCard
                :menu-item="{
                  ...menuItem,
                  ...getMenuItemCurrentLocation(menuItem),
                  ...getItemLocalizedInformations(menuItem),
                }"
                :concept-id="conceptId"
                :platformUuid="platform.uuid"
                @delete="deleteItem"
                @click.native="openModalMenuItem(menuItem)"
                class="card-clickable"
                deletable
                hoverable
              />
            </Draggable>
            <div v-if="row === rowsCount && isItemMenuEditor()" class="column is-4">
              <MenuDetailsNewMenuItem @addItem="addItem" />
            </div>
          </Container>
          <div v-if="!rowsCount" class="columns">
            <div class="column is-4" v-if="isItemMenuEditor()">
              <MenuDetailsNewMenuItem @addItem="addItem" />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { menusTypes } from '@/store/mutation-types';
import { auth, DragAndDrop } from '@/mixins';

import { Container, Draggable } from 'vue-smooth-dnd';
import MenuItemCard from '@/components/Menu/MenuItemCard.vue';
import MenuDetailsCategoryHeader from '@/components/Menu/MenuDetails/MenuDetailsCategoryHeader.vue';
import MenuDetailsNewMenuItem from '@/components/Menu/MenuDetails/MenuDetailsNewMenuItem.vue';
import MenuItemModal from '@/components/Menu/MenuItemModal.vue';

const ITEMS_PER_ROW = 3;

export default {
  name: 'MenuDetailsCategory',
  mixins: [auth, DragAndDrop],
  components: {
    MenuItemCard,
    MenuDetailsCategoryHeader,
    MenuDetailsNewMenuItem,
    Container,
    Draggable,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    platform: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
      swapEvents: {},
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.menus.current.categories,
      menuItems: (state) => state.menuItems.all,
      combos: (state) => state.combos.all,
      modifierGroups: (state) => state.modifierGroups.all,
      conceptId: (state) => state.concepts.current.uuid,
      itemsInformations: (state) => state.itemsInformations.all,
      currentLocation: (state) => state.locations.current,
    }),
    ...mapGetters('menuItems', ['getMenuItemCurrentLocation']),
    ...mapGetters('modifierGroups', ['getModifierGroupCurrentLocation']),
    category: {
      get() {
        return this.categories[this.position];
      },
      set(value) {
        this.editCategory({ position: this.position, newCategory: value });
      },
    },
    categoryMenuItems() {
      var elements = [];
      if (this.category.menu_item_uuids && !isEmpty(this.category.menu_item_uuids)) {
        elements = this.category.menu_item_uuids
          .map((menuItemUuid) => {
            return [...this.menuItems, ...this.combos].find((menuItem) => menuItem.uuid === menuItemUuid);
          })
          // only get the existing menuItems for the location
          .filter((element) => element);
      }
      return elements;
    },
    rowsCount() {
      return Math.ceil(this.category.menu_item_uuids.length / ITEMS_PER_ROW);
    },
  },
  methods: {
    ...mapMutations('menus', {
      editCategory: menusTypes.EDIT_CATEGORY,
      swapMenuItemsInLine: menusTypes.SWAP_MENUITEMS_INLINE,
      swapMenuItemsInGroups: menusTypes.SWAP_MENUITEMS_INGROUPS,
    }),
    deleteItem(menuItemUuid) {
      this.category = {
        ...this.category,
        menu_item_uuids: [...this.category.menu_item_uuids.filter((m) => m !== menuItemUuid)],
      };
    },
    addItem(menuItemUuid) {
      this.category = {
        ...this.category,
        menu_item_uuids: [...this.category.menu_item_uuids, menuItemUuid],
      };
    },
    getRowItems(rowIndex) {
      return this.categoryMenuItems.slice(rowIndex * ITEMS_PER_ROW, rowIndex * ITEMS_PER_ROW + ITEMS_PER_ROW);
    },
    onDrop(rowIndex, dropResult) {
      // same line drop
      if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
        const newOrder = this.applyDrag(this.getRowItems(rowIndex), dropResult).map((item) => item.uuid);
        this.swapMenuItemsInLine({
          position: this.position,
          rowIndex: rowIndex,
          itemsPerRow: ITEMS_PER_ROW,
          newOrder: newOrder,
        });
      }
      // different line drop
      else {
        if (dropResult.removedIndex !== null && dropResult.addedIndex === null) {
          this.swapEvents[rowIndex] = dropResult;
        } else if (dropResult.removedIndex === null && dropResult.addedIndex !== null) {
          this.swapEvents[rowIndex] = dropResult;
        }
        if (Object.keys(this.swapEvents).length === 2) {
          const itemToSwap = dropResult.payload;
          const menuItemsSorted = this.applyDragBetweenGroups(
            this.category.menu_item_uuids,
            itemToSwap,
            this.swapEvents,
            ITEMS_PER_ROW
          );
          this.swapMenuItemsInGroups({ position: this.position, menuItemsSorted: menuItemsSorted });
          // reset events
          this.swapEvents = {};
        }
      }
    },
    getChildPayload(rowIndex) {
      return this.getChildPayloadBetweenGroups(rowIndex, this.categoryMenuItems, ITEMS_PER_ROW);
    },
    openModalMenuItem(menuItem) {
      const menuItemLocation = {
        ...menuItem,
        ...this.getMenuItemCurrentLocation(menuItem),
        ...this.getItemLocalizedInformations(menuItem),
      };
      menuItemLocation.uuid = menuItem.uuid;
      menuItemLocation.modifierGroups = menuItemLocation.modifier_group_uuids_sorted.map((modifierGroupUuid) =>
        this.modifierGroups.find((modifierGroup) => modifierGroup.uuid === modifierGroupUuid)
      );
      menuItemLocation.modifierGroups = menuItemLocation.modifierGroups
        .map((modifierGroup) => this.getModifierGroupCurrentLocation(modifierGroup))
        .filter((modifierGroup) => modifierGroup);
      this.$buefy.modal.open({
        component: MenuItemModal,
        props: {
          menuItem: menuItemLocation,
          conceptId: this.conceptId,
          menuItems: this.menuItems,
          modifierGroups: this.modifierGroups,
          currentLocation: this.currentLocation,
        },
        parent: this,
        hasModalCard: true,
      });
    },
    getItemLocalizedInformations(menuItem) {
      const initialValue = {};
      const itemsInformations = this.itemsInformations.reduce((obj, item) => {
        return {
          ...obj,
          [item['item_uuid']]: item,
        };
      }, initialValue);
      if (!itemsInformations[menuItem.uuid]) {
        return {
          picture: '',
          selling_price: null,
          selling_unit_price: '',
        };
      } else {
        var pictures = itemsInformations[menuItem.uuid].pictures;
        var picture = '';
        if (pictures && pictures.formatted) {
          picture = pictures.formatted;
        }
        return {
          picture: picture,
          selling_price: itemsInformations[menuItem.uuid].selling_price,
          selling_unit_price: itemsInformations[menuItem.uuid].selling_unit_price,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.category {
  &-content {
    padding: 10px;

    .card-ghost {
      transition: transform 0.18s ease;
      transform: rotateZ(5deg);
    }

    .card-ghost-drop {
      transition: transform 0.18s ease-in-out;
      transform: rotateZ(0deg);
    }

    .card-clickable {
      cursor: pointer;
    }
  }
}
.categoryActive {
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
</style>
