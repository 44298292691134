<template>
  <div>
    <b-dropdown :disabled="!remainingCountries.length" hoverable aria-role="list">
      <button class="button is-primary" slot="trigger">
        <span>Description</span>
        <b-icon icon="plus-circle"></b-icon>
      </button>

      <b-dropdown-item
        v-for="country in remainingCountries"
        :key="country"
        aria-role="listitem"
        @click="addDescription(country)"
      >
        <flag :iso="country" :squared="false" style="margin-right: 8px" />{{ country }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="descriptions">
      <div v-for="(country, key) in value" :key="key" class="description-container">
        <span class="button is-light is-static" style="margin-right: 8px">
          <flag :iso="key" :squared="false" />
        </span>
        <b-field class="description-text-area">
          <b-input type="textarea" v-model="value[key]" />
        </b-field>
        <b-button @click="$delete(value, key)" type="is-primary" style="margin-left: 8px" v-if="isItemMenuEditor()">
          <b-icon icon="delete" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { auth } from '@/mixins';
import * as countryList from 'country-list';

const updatableCountries = ['gb', 'fr', 'es'];

export default {
  name: 'ConceptFormDescription',
  mixins: [auth],
  props: {
    description: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      value: { ...this.description },
      countries: countryList
        .getCodes()
        .map((country) => country.toLowerCase())
        .filter((country) => updatableCountries.includes(country)),
    };
  },
  computed: {
    remainingCountries: function () {
      if (this.value) {
        return this.countries.filter((country) => !Object.keys(this.value).includes(country));
      } else {
        return this.countries;
      }
    },
  },
  watch: {
    description(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.value = { ...newValue };
      }
    },
    value: {
      handler() {
        this.$emit('update:description', this.value);
      },
      deep: true,
    },
  },
  methods: {
    addDescription(country) {
      this.$set(this.value, country, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.descriptions {
  padding: 30px 0;

  .description-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
  }

  .description-text-area {
    flex: auto;
  }
}
</style>
