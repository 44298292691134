import modifierGroupsAPI from '@/apis/items/modifierGroups';
import { modifierGroupsTypes } from '../mutation-types.js';

import { cloneDeep } from 'lodash';

export default {
  namespaced: true,
  state: {
    all: [],
    current: null,
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    updateLocation: {
      pending: false,
      error: null,
    },
    removeLocation: {
      pending: false,
      error: null,
    },
    addLocation: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [modifierGroupsTypes.FETCH_ALL_SUCCESS](state, modifierGroups) {
      state.all = modifierGroups;
      state.fetchAll.pending = false;
    },
    [modifierGroupsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [modifierGroupsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [modifierGroupsTypes.FETCH_ONE_SUCCESS](state, modifierGroup) {
      state.current = modifierGroup;
      state.fetchOne.pending = false;
    },
    [modifierGroupsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [modifierGroupsTypes.FETCH_ONE_REQUEST](state) {
      state.current = null;
      state.fetchOne.pending = true;
    },
    [modifierGroupsTypes.UPDATE_ONE_SUCCESS](state, modifierGroup) {
      state.current = modifierGroup;
      state.updateOne.pending = false;
    },
    [modifierGroupsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [modifierGroupsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
    },
    [modifierGroupsTypes.CREATE_ONE_SUCCESS](state, modifierGroup) {
      state.all.push(modifierGroup);
      state.current = modifierGroup;
      state.createOne.pending = false;
      state.createOne.error = null;
    },
    [modifierGroupsTypes.CREATE_ONE_FAILURE](state, error) {
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [modifierGroupsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [modifierGroupsTypes.ADD_LOCATION_SUCCESS](state, { modifierGroup, locationUuid }) {
      const location = modifierGroup.locations.find(({ uuid }) => uuid === locationUuid);
      state.current.locations.push(location);
      state.addLocation.pending = false;
    },
    [modifierGroupsTypes.ADD_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.addLocation.pending = false;
      state.addLocation.error = error;
    },
    [modifierGroupsTypes.ADD_LOCATION_REQUEST](state) {
      state.addLocation.pending = true;
    },
    [modifierGroupsTypes.REMOVE_LOCATION_SUCCESS](state, locationUuid) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1
      );
      state.removeLocation.pending = false;
    },
    [modifierGroupsTypes.REMOVE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.removeLocation.pending = false;
      state.removeLocation.error = error;
    },
    [modifierGroupsTypes.REMOVE_LOCATION_REQUEST](state) {
      state.removeLocation.pending = true;
    },
    [modifierGroupsTypes.UPDATE_LOCATION_SUCCESS](state, { modifierGroup, locationUuid }) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1,
        modifierGroup.locations.find(({ uuid }) => uuid === locationUuid)
      );
      state.updateLocation.pending = false;
    },
    [modifierGroupsTypes.UPDATE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.updateLocation.pending = false;
      state.updateLocation.error = error;
    },
    [modifierGroupsTypes.UPDATE_LOCATION_REQUEST](state) {
      state.updateLocation.pending = true;
    },
    [modifierGroupsTypes.SET_CURRENT](state, modifierGroup) {
      state.current = modifierGroup;
    },
  },
  actions: {
    buildOne({ commit }) {
      const modifierGroup = modifierGroupsAPI.buildOne();

      commit(modifierGroupsTypes.SET_CURRENT, modifierGroup);
    },
    async fetchAll({ commit }) {
      commit(modifierGroupsTypes.FETCH_ALL_REQUEST);

      return modifierGroupsAPI
        .fetchAll()
        .then((modifierGroups) => commit(modifierGroupsTypes.FETCH_ALL_SUCCESS, modifierGroups))
        .catch((error) => commit(modifierGroupsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, id) {
      commit(modifierGroupsTypes.FETCH_ONE_REQUEST);

      return modifierGroupsAPI
        .fetchOne(id)
        .then((modifierGroup) => commit(modifierGroupsTypes.FETCH_ONE_SUCCESS, modifierGroup))
        .catch((error) => commit(modifierGroupsTypes.FETCH_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(modifierGroupsTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.location_uuids;
      delete data.concept_uuids;
      delete data.locations;
      delete data.menu_item_uuids;

      return modifierGroupsAPI
        .updateOne(state.current.uuid, data)
        .then((modifierGroup) => commit(modifierGroupsTypes.UPDATE_ONE_SUCCESS, modifierGroup))
        .catch((error) => commit(modifierGroupsTypes.UPDATE_ONE_FAILURE, error));
    },
    async createOne({ commit, state }) {
      commit(modifierGroupsTypes.CREATE_ONE_REQUEST);

      return modifierGroupsAPI
        .createOne(state.current)
        .then((modifierGroup) => commit(modifierGroupsTypes.CREATE_ONE_SUCCESS, modifierGroup))
        .catch((error) => commit(modifierGroupsTypes.CREATE_ONE_FAILURE, error));
    },
    async addLocation({ commit, state }, locationUuid) {
      commit(modifierGroupsTypes.ADD_LOCATION_REQUEST);

      modifierGroupsAPI
        .addLocation(state.current.uuid, locationUuid)
        .then((modifierGroup) => commit(modifierGroupsTypes.ADD_LOCATION_SUCCESS, { modifierGroup, locationUuid }))
        .catch((error) => commit(modifierGroupsTypes.ADD_LOCATION_FAILURE, error));
    },
    async removeLocation({ commit, state }, locationUuid) {
      commit(modifierGroupsTypes.REMOVE_LOCATION_REQUEST);
      modifierGroupsAPI
        .removeLocation(state.current.uuid, locationUuid)
        .then(() => commit(modifierGroupsTypes.REMOVE_LOCATION_SUCCESS, locationUuid))
        .catch((error) => commit(modifierGroupsTypes.REMOVE_LOCATION_FAILURE, error));
    },
    async updateLocation({ commit, state }, locationUuid) {
      commit(modifierGroupsTypes.UPDATE_LOCATION_REQUEST);

      const location = state.current.locations.find(({ uuid }) => uuid === locationUuid);
      const data = { ...location };

      delete data.uuid;
      data.menu_items.forEach((menuItem) => {
        delete menuItem.label;
        delete menuItem.category;
      });

      modifierGroupsAPI
        .updateLocation(state.current.uuid, location.uuid, data)
        .then((modifierGroup) =>
          commit(modifierGroupsTypes.UPDATE_LOCATION_SUCCESS, { modifierGroup, locationUuid: location.uuid })
        )
        .catch((error) => commit(modifierGroupsTypes.UPDATE_LOCATION_FAILURE, error));
    },
  },
  getters: {
    getModifierGroupCurrentLocation: (_state, _getters, rootState) => (modifierGroup) => {
      for (let locationUuid of [...rootState.locations.current.location_tree]) {
        const localizedModifierGroup = cloneDeep(modifierGroup.locations.find(({ uuid }) => uuid === locationUuid));

        if (localizedModifierGroup) {
          localizedModifierGroup.uuid = modifierGroup.uuid;
          return localizedModifierGroup;
        }
      }
    },
  },
};
