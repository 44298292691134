<template>
  <div>
    <div class="is-flex">
      <div class="title is-5" style="margin-bottom: 12px">Composition ({{ itemLocation.label }})</div>
      <b-button
        size="is-small"
        outlined
        class="is-info"
        v-if="!isDisabled && !computedIsEditable && isItemRecipeCreator()"
        @click="computedIsEditable = true"
        style="margin-left: 8px"
      >
        Edit
      </b-button>
      <b-button
        size="is-small"
        outlined
        class="is-grey"
        v-if="!isDisabled && computedIsEditable"
        @click="computedIsEditable = false"
        style="margin-left: 8px"
      >
        Close
      </b-button>
      <b-button
        size="is-small"
        outlined
        class="is-info"
        v-if="!isDisabled && computedIsEditable"
        @click="$emit('save')"
        style="margin-left: 8px"
      >
        Save
      </b-button>
    </div>
    <div>
      <b>{{ parentItemMeasure.quantity }} {{ parentItemMeasure.unit }}</b> of <b>{{ parentItemLabel }}</b> contains
      <b v-if="!computedIsEditable"
        >{{ quantity }} {{ unit }} of <b>{{ itemLabel }}</b></b
      >
      <b-field grouped v-else>
        <b-field label="Quantity" label-position="on-border" style="margin-bottom: 4px; margin-top: 8px">
          <b-input type="number" v-model.number="quantity" step=".001" size="is-small"> </b-input>
        </b-field>
        <b-field label="Unit" label-position="on-border" style="margin-top: 8px">
          <b-select placeholder="Select a unit" v-model="unit" size="is-small">
            <option value="g">g</option>
            <option value="ml">ml</option>
            <option value="each">each</option>
          </b-select>
        </b-field>
        <b-field style="margin-top: 8px">
          of <b>{{ itemLabel }}</b>
        </b-field>
      </b-field>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: [
    'itemQuantityInParent',
    'itemUnitInParent',
    'parentItemLabel',
    'itemLabel',
    'parentItemMeasure',
    'isEditable',
    'isDisabled',
    'itemLocation',
  ],
  data() {
    return {
      newIsEditable: this.isEditable,
      quantity: this.itemQuantityInParent,
      unit: this.itemUnitInParent,
    };
  },
  watch: {
    isEditable(value) {
      this.newIsEditable = value;
    },
    itemUnitInParent(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.unit = newValue;
      }
    },
    itemQuantityInParent(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.quantity = newValue;
      }
    },
    quantity() {
      this.$emit('update:itemQuantityInParent', this.quantity);
    },
    unit() {
      this.$emit('update:itemUnitInParent', this.unit);
    },
  },
  computed: {
    computedIsEditable: {
      get() {
        return this.newIsEditable;
      },
      set(value) {
        this.newIsEditable = value;
        this.$emit('update:isEditable', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid #8080802e;
}
</style>
