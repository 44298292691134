import { render, staticRenderFns } from "./MenuItemPictures.vue?vue&type=template&id=93b96418&scoped=true&"
import script from "./MenuItemPictures.vue?vue&type=script&lang=js&"
export * from "./MenuItemPictures.vue?vue&type=script&lang=js&"
import style0 from "./MenuItemPictures.vue?vue&type=style&index=0&id=93b96418&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b96418",
  null
  
)

export default component.exports