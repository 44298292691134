<template>
  <section class="section">
    <div class="container">
      <b-tabs size="medium" type="is-boxed" vertical v-model="activeTab">
        <b-tab-item v-for="concept in conceptsSorted" :key="concept.uuid">
          <template slot="header">
            <img :src="concept.logo" width="21" />
            <span class="concept__label">{{ concept.label }}</span>
          </template>
          <div class="columns">
            <div class="column is-flex" style="align-items: center">
              <img :src="concept.logo" width="75" style="margin-right: 25px" />
              <h1 class="title">{{ concept.label }}</h1>
            </div>
            <!-- <div class="column is-narrow is-flex" style="align-items: center;" v-if="(isItemMenuItemRecipeEditor() || isItemComboRecipeEditor()) && !currentLocation.parent_uuid">
              <b-dropdown hoverable aria-role="list">
                <button class="button is-primary" slot="trigger" icon-left="plus-circle">
                  <b-icon icon="plus-circle"></b-icon>
                  <span>Add to Concept</span>
                </button>
                <b-dropdown-item aria-role="listitem" @click="openAddItemModal(concept.uuid)" v-if="isItemMenuItemRecipeEditor()">
                  Menu item
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="openAddComboModal(concept.uuid)"  v-if="isItemComboRecipeEditor()">
                  Combo
                </b-dropdown-item>
              </b-dropdown>
            </div> -->
            <div class="column is-narrow is-flex" style="align-items: center">
              <b-dropdown
                :disabled="!getUpdatablePlatforms.length"
                hoverable
                aria-role="list"
                v-model="selectedPlatform"
                @select="selectPlatform"
                position="is-bottom-left"
              >
                <button class="button is-light" slot="trigger">
                  <span>
                    <img v-if="selectedPlatform" :src="selectedPlatform.logo" width="25" />
                    {{ selectedPlatform ? '' : 'Platforms' }}
                  </span>
                  <b-icon icon="menu-down"></b-icon>
                </button>
                <b-dropdown-item
                  v-for="platform in getUpdatablePlatforms.sort()"
                  :key="platform.uuid"
                  :value="platform"
                  aria-role="listitem"
                >
                  {{ platform.label }}
                  <img :src="platform.logo" width="25" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="column is-narrow is-flex" style="align-items: center">
              <b-switch v-model="showUsedMenuItems" passive-type="is-dark" type="is-success">
                {{ showUsedMenuItems ? 'Current' : 'Not Used' }}
              </b-switch>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-input
                size="is-medium"
                expanded
                rounded
                v-model="computedSearchTerm"
                placeholder="Search for a product..."
              ></b-input>
            </div>
          </div>
          <b-loading :active.sync="isFetchLoading"></b-loading>
          <b-notification :closable="false" v-if="menuItems && combos">
            <h5 class="title is-5">Categories</h5>
            <b-checkbox
              v-for="(category, index) in [...menuItemsCategoriesByConcept(concept.uuid), 'combo'].sort()"
              :key="index"
              :native-value="category"
              v-model="selectedCategories"
            >
              <span class="is-capitalized">{{ category ? category : 'NO CATEGORY' }}</span>
            </b-checkbox>
          </b-notification>
          <div v-if="selectedCategories.includes('combo')">
            <b-collapse animation="slide" aria-id="contentIdForA11y3">
              <div slot="trigger" slot-scope="props" role="button" aria-controls="contentIdForA11y3">
                <div style="display: flex">
                  <div style="padding-top: 13px">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-right'"> </b-icon>
                  </div>
                  <div class="category-header">
                    <div class="category-title">
                      <h2 class="subtitle">Combo</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="is-flex" style="flex-flow: wrap">
                <MenuItemCard
                  v-for="combo in filteredCombos"
                  :menuItem="{
                    ...getMenuItemCurrentLocation(combo),
                    ...combo,
                    ...getItemLocalizedInformations(combo),
                  }"
                  :key="combo.uuid"
                  :locationUuid="currentLocation.uuid"
                  :conceptId="concept.uuid"
                  :concept="concept"
                  :platformUuid="selectedPlatform.uuid"
                  :isMenuItem="false"
                  @delete="(comboUuid) => removeComboFromConcept({ comboUuid, conceptUuid: concept.uuid })"
                  @click.native="$router.push(`/combos/${combo.uuid}/edit`)"
                />
              </div>
            </b-collapse>
          </div>
          <div class="menu-items">
            <div class="category" v-for="(category, index) in filteredCategories(concept.uuid)" v-bind:key="index">
              <div class="container" v-if="filteredMenuItemsByCategory(category).length && itemsInformations">
                <div class="category" @mouseover="open = true" @mouseleave="open = false">
                  <b-collapse animation="slide" aria-id="contentIdForA11y3">
                    <div slot="trigger" slot-scope="props" role="button" aria-controls="contentIdForA11y3">
                      <div style="display: flex">
                        <div style="padding-top: 13px">
                          <b-icon :icon="props.open ? 'menu-down' : 'menu-right'"> </b-icon>
                        </div>
                        <div class="category-header">
                          <div class="category-title">
                            <h2 class="subtitle">{{ category }}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="is-flex" style="flex-flow: wrap" v-if="selectedPlatform">
                      <MenuItemCard
                        v-for="menuItem in filteredMenuItemsByCategory(category)"
                        :menuItem="{
                          ...getMenuItemCurrentLocation(menuItem),
                          ...menuItem,
                          ...getItemLocalizedInformations(menuItem),
                        }"
                        :conceptId="concept.uuid"
                        @delete="(menuItemUuid) => removeFromConcept({ menuItemUuid, conceptUuid: concept.uuid })"
                        @click.native="$router.push(`/menu-items/${menuItem.uuid}/edit`)"
                        v-bind:key="menuItem.uuid"
                        :isMenuItem="true"
                        :locationUuid="currentLocation.uuid"
                        :concept="concept"
                        :platformUuid="selectedPlatform.uuid"
                      />
                      <br />
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { auth, errorToasterDetailled } from '@/mixins';
import { conceptsTypes } from '@/store/mutation-types';
import { itemsInformationsTypes } from '@/store/mutation-types';
import { kitchenPlatformsTypes } from '@/store/mutation-types';
import { menusTypes } from '@/store/mutation-types';

import ChooseMenuItem from '@/views/ChooseMenuItem.vue';
import ChooseCombo from '@/views/ChooseCombo.vue';
import MenuItemCard from '@/components/MenuItem/MenuItemCard.vue';

export default {
  mixins: [auth, errorToasterDetailled],
  name: 'showconcept',
  components: {
    MenuItemCard,
  },
  data: () => ({
    currentPlatformIndex: 0,
    locations: [],
    showUsedMenuItems: true,
    open: true,
  }),
  methods: {
    ...mapActions('combos', {
      fetchCombos: 'fetchAll',
      removeComboFromConcept: 'removeFromConcept',
      addComboToConcept: 'addToConcept',
    }),
    ...mapActions('menuItems', [
      'removeFromConcept',
      'createMenuItem',
      'addMenuItemToConcept',
      'fetchAll',
      'fetchCategories',
      'addLocation',
    ]),
    ...mapActions('menus', {
      fetchMenuItemsUsedInMenus: 'fetchMenuItemsUsedInMenus',
      fetchMenu: 'fetchOne',
      fetchCurrentMenu: 'fetchCurrentMenu',
    }),
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('itemsInformations', { fetchAllItemsInformations: 'fetchAll', createOne: 'createOne' }),
    ...mapActions('platforms', { fetchAllPlatforms: 'fetchAll' }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
    ...mapMutations('itemsInformations', {
      setCurrentItemsInformations: itemsInformationsTypes.SET_CURRENT_ITEMS_INFORMATIONS_REFERENTIAL,
    }),
    ...mapMutations('menuItems', { setSearchFilters: 'SET_SEARCH_FILTERS', setCurrentMenuItem: 'SET_CURRENT' }),
    ...mapMutations('menus', { setCurrentMenu: menusTypes.SET_CURRENT_MENU_REFERENTIAL }),
    ...mapMutations('platforms', { setCurrentPlatform: kitchenPlatformsTypes.SET_CURRENT_PLATFORM }),
    openAddItemModal(conceptUuid) {
      this.$buefy.modal.open({
        component: ChooseMenuItem,
        props: { categories: this.categories },
        parent: this,
        hasModalCard: true,
        events: {
          created: (menuItemLabel, menuItemCategory) =>
            this.createMenuItem({ menuItemLabel, menuItemCategory, conceptUuid }).then(() =>
              this.$router.push(`/menu-items/${this.currentSelectedCreatedMenuItem.uuid}/edit`)
            ),
          selected: (menuItem, menuItemUuid) =>
            this.addMenuItemToConcept({ menuItemUuid, conceptUuid })
              .then(() => this.createPlatformInformation(menuItem, conceptUuid))
              .then(() => this.$router.push(`/menu-items/${this.currentSelectedCreatedMenuItem.uuid}/edit`)),
        },
      });
    },
    openAddComboModal(conceptUuid) {
      this.$buefy.modal.open({
        component: ChooseCombo,
        parent: this,
        hasModalCard: true,
        events: {
          close: (comboUuid) =>
            this.addComboToConcept({ comboUuid, conceptUuid }).then(() =>
              this.$router.push(`/combos/${this.currentSelectedCreatedCombo.uuid}/edit`)
            ),
        },
      });
    },
    createPlatformInformation(menuItem, conceptUuid) {
      Promise.all(
        this.getUpdatablePlatforms.map((platform) =>
          this.createOne({
            concept_uuid: conceptUuid,
            item_uuid: menuItem.uuid,
            location_uuid: this.currentLocation.uuid,
            platform_uuid: platform.uuid,
            selling_price: 0.0,
            selling_unit_price: this.currentLocation.location_country.currency,
          })
        )
      ).then(() => {
        if (!menuItem.location_uuids.includes(this.currentLocation.uuid)) this.addLocation(this.currentLocation.uuid);
      });
    },
    filteredMenuItemsByCategory(category) {
      return this.filteredMenuItems.filter((menuItem) => menuItem.category == category);
    },
    filteredCategories(conceptUUID) {
      return _.orderBy(
        this.menuItemsCategoriesByConcept(conceptUUID).filter((category) => this.selectedCategories.includes(category))
      );
    },
    getItemLocalizedInformations(menuItem) {
      if (Object.keys(this.currentItemsInformationsReferential).length > 0) {
        const initialValue = {};
        const itemsInformations = this.currentItemsInformationsReferential.reduce((obj, item) => {
          return {
            ...obj,
            [item['item_uuid']]: item,
          };
        }, initialValue);
        if (!itemsInformations[menuItem.uuid]) {
          return {
            picture: '',
            selling_price: null,
            selling_unit_price: '',
          };
        } else {
          var pictures = itemsInformations[menuItem.uuid].pictures;
          var picture = '';
          if (pictures && pictures.formatted) {
            picture = pictures.formatted;
          }
          return {
            picture: picture,
            selling_price: itemsInformations[menuItem.uuid].selling_price,
            selling_unit_price: itemsInformations[menuItem.uuid].selling_unit_price,
          };
        }
      }
    },
    selectPlatform(platform) {
      this.selectedPlatform = platform;
      this.$refs.dropdown.isActive = false;
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getConcepts() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.concepts.all)) {
        return this.fetchConcepts();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getPlatforms() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.platforms.all)) {
        return this.fetchAllPlatforms();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getMenuItems() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.menuItems.all)) {
        return this.fetchAll({ summary: true });
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getCombos() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.combos.all)) {
        return this.fetchCombos();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getCategories() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.menuItems.categories)) {
        return this.fetchCategories().then(() => {
          if (!this.checkExistence(this.categories)) {
            this.setSearchFilters({ ...this.searchFilters, categories: ['combo'] });
          } else {
            this.setSearchFilters({
              ...this.searchFilters,
              categories: ['combo', ...this.categories.map((category) => category.label)],
            });
          }
        });
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getCurrentMenu(opts) {
      // to benefit from then()
      if (
        this.$store.state.menus.currentMenus == null ||
        this.$store.state.menus.currentMenus[opts.location.uuid] == null ||
        this.$store.state.menus.currentMenus[opts.location.uuid][opts.conceptUuid] == null ||
        this.$store.state.menus.currentMenus[opts.location.uuid][opts.conceptUuid][opts.platformUuid] == null ||
        Object.keys(this.$store.state.menus.currentMenus[opts.location.uuid][opts.conceptUuid][opts.platformUuid])
          .length == 0
      ) {
        return this.fetchCurrentMenu({
          conceptUuid: opts.conceptUuid,
          location: opts.location,
          platformUuid: opts.platformUuid,
        });
      } else {
        this.setCurrentMenu(
          this.$store.state.menus.currentMenus[opts.location.uuid][opts.conceptUuid][opts.platformUuid]
        );
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getCurrentItemsInformations(opts) {
      // to benefit from then()
      if (
        this.$store.state.itemsInformations.allByMajorDimensions == null ||
        this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid] == null ||
        this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid] == null ||
        this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][
          opts.platformUuid
        ] == null ||
        this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][opts.platformUuid]
          .length == 0
      ) {
        this.fetchAllItemsInformations({
          conceptUuid: opts.conceptUuid,
          locationUuid: opts.locationUuid,
          platformUuid: opts.platformUuid,
          mergeLocations: true,
        }).then(() => {
          if (
            this.$store.state.itemsInformations.allByMajorDimensions != null &&
            this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid] != null &&
            this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid] != null &&
            this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][
              opts.platformUuid
            ] != null &&
            this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][
              opts.platformUuid
            ].length > 0
          ) {
            this.setCurrentItemsInformations(
              this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][
                opts.platformUuid
              ]
            );
          } else {
            this.setCurrentItemsInformations([]);
          }
        });
      } else {
        this.setCurrentItemsInformations(
          this.$store.state.itemsInformations.allByMajorDimensions[opts.locationUuid][opts.conceptUuid][
            opts.platformUuid
          ]
        );
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    fetchMenuAndComboInfoInContext(conceptUuid, location, platformUuid) {
      Promise.all([
        this.getCurrentItemsInformations({
          conceptUuid: conceptUuid,
          locationUuid: location.uuid,
          platformUuid: platformUuid,
        }),
        this.getCurrentMenu({
          conceptUuid: conceptUuid,
          location: location,
          platformUuid: platformUuid,
        }),
      ]);
    },
  },
  computed: {
    ...mapState({
      categories: (state) => state.menuItems.categories,
      menuItems: (state) => state.menuItems.all,
      combos: (state) => state.combos.all,
      concepts: (state) => state.concepts.all,
      conceptsSorted() {
        if (!this.concepts) {
          return [];
        }
        var conceptsByName = this.concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
        return conceptsByName.filter((concept) => !concept.is_deleted);
      },
      currentConcept: (state) => state.concepts.current,
      currentLocation: (state) => state.locations.current,
      currentMenuReferential: (state) => state.menus.currentMenuReferential,
      currentItemsInformationsReferential: (state) => state.itemsInformations.currentItemsInformationsReferential,
      currentMenus: (state) => state.menus.currentMenus,
      currentSelectedCreatedCombo: (state) => state.combos.current,
      currentSelectedCreatedMenuItem: (state) => state.menuItems.current,
      currentPlatform: (state) => state.platforms.current,
      searchFilters: (state) => state.menuItems.searchFilters,
      itemsInformations: (state) => state.itemsInformations.all,
      isFetchLoading: (state) =>
        state.menuItems.fetchAll.pending ||
        state.combos.fetchAll.pending ||
        state.menus.fetchCurrentMenu.pending ||
        state.itemsInformations.fetchAll.pending,
      currentItemsInformations: (state) => state.itemsInformations.allByMajorDimensions,
      isSaveLoading: (state) =>
        state.menuItems.createOne.pending ||
        state.menuItems.addOne.pending ||
        state.combos.createOne.pending ||
        state.combos.addConcept.pending,
      saveError: (state) =>
        state.menuItems.createOne.error ||
        state.menuItems.addOne.error ||
        state.combos.createOne.error ||
        state.combos.addConcept.error,
    }),
    ...mapGetters('menuItems', [
      'getMenuItemsByCategoryAndConcept',
      'menuItemsCategoriesByConcept',
      'filterMenuItemsByLabel',
      'getMenuItemCurrentLocation',
    ]),
    ...mapGetters('platforms', ['getUpdatablePlatforms']),
    filteredCombos() {
      if (!this.currentConcept) {
        return [];
      }
      var combos = this.combos.filter((combo) => combo.concept_uuids.includes(this.currentConcept.uuid));
      var comboUuids = [];
      if (Object.keys(this.currentMenuReferential).length > 0) {
        for (var restaurant_item of this.currentMenuReferential.restaurant_items) {
          if (restaurant_item.type == 'COMBO') {
            comboUuids.push(restaurant_item.uuid);
          }
        }
      }
      combos = combos.filter((combo) => comboUuids.includes(combo.uuid) === this.showUsedMenuItems);
      return this.searchFilters.term == ''
        ? combos
        : combos.filter((combo) => combo.label.toLowerCase().includes(this.searchFilters.term.toLowerCase()));
    },
    filteredMenuItems() {
      if (!this.currentConcept) {
        return [];
      }
      var menuItems = this.menuItems.filter((menuItem) => menuItem.concept_uuids.includes(this.currentConcept.uuid));
      var menuItemsUuids = [];
      if (Object.keys(this.currentMenuReferential).length > 0) {
        menuItemsUuids = this.currentMenuReferential.restaurant_items.map((restaurant_item) => restaurant_item.uuid);
      }
      menuItems = menuItems.filter((menuItem) => menuItemsUuids.includes(menuItem.uuid) === this.showUsedMenuItems);
      return this.searchFilters.term == ''
        ? menuItems
        : menuItems.filter((menuItem) => menuItem.label.toLowerCase().includes(this.searchFilters.term.toLowerCase()));
    },
    activeTab: {
      get() {
        return this.searchFilters.concept;
      },
      set(value) {
        this.setSearchFilters({ ...this.searchFilters, concept: value });
        this.setCurrentConcept(this.conceptsSorted[value]);
        if (this.currentPlatform) {
          this.fetchMenuAndComboInfoInContext(
            this.conceptsSorted[value].uuid,
            this.currentLocation,
            this.currentPlatform.uuid
          );
        }
      },
    },
    selectedCategories: {
      get() {
        return this.searchFilters.categories;
      },
      set(value) {
        this.setSearchFilters({ ...this.searchFilters, categories: value });
      },
    },
    computedSearchTerm: {
      get() {
        return this.searchFilters.term;
      },
      set(value) {
        this.setSearchFilters({ ...this.searchFilters, term: value });
      },
    },
    selectedPlatform: {
      get() {
        return this.currentPlatform;
      },
      set(value) {
        this.setCurrentPlatform(value);
        this.fetchMenuAndComboInfoInContext(this.currentConcept.uuid, this.currentLocation, value.uuid);
      },
    },
  },
  mounted() {
    Promise.all([this.getConcepts(), this.getPlatforms(), this.getCategories(), this.getCombos(), this.getMenuItems()])
      .then(() => {
        if (this.conceptsSorted.length > 0) {
          if (this.activeTab) {
            this.setCurrentConcept(this.conceptsSorted[this.activeTab]);
          } else {
            this.setCurrentConcept(this.conceptsSorted[0]);
          }
        }
        var updatablePlatforms = this.getUpdatablePlatforms;
        if (updatablePlatforms.length > 0) {
          if (this.selectedPlatform) {
            this.setCurrentPlatform(this.selectedPlatform);
          } else {
            this.setCurrentPlatform(updatablePlatforms[0]);
          }
        }
      })
      .then(() =>
        Promise.all([
          this.fetchMenuAndComboInfoInContext(
            this.currentConcept.uuid,
            this.currentLocation,
            this.currentPlatform.uuid
          ),
        ])
      );
  },
  watch: {
    currentLocation(newVal, oldVal) {
      if ((!oldVal || (oldVal && newVal.uuid !== oldVal.uuid)) && this.currentConcept && this.currentPlatform) {
        this.fetchMenuAndComboInfoInContext(this.currentConcept.uuid, newVal, this.currentPlatform.uuid);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.concept {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__label {
    margin-left: 12px;
  }
}

.category-header {
  padding: 10px;
  width: 100%;

  .category-title {
    font-weight: 600;
    font-size: 20px;

    display: flex;
    justify-content: space-between;
  }

  .category-subtitle {
    margin-top: 5px;
    font-weight: 400;
    font-size: 18px;
    min-height: 18px;
  }

  .label-input {
    font-size: 20px;
    font-weight: 600;
  }
  .desc-input {
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
