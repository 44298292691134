<template>
  <b-table
    class="substitutes-table"
    scrollable
    :draggable="!disabled"
    :mobile-cards="false"
    :data="selectedProductSubstitutes"
    @dragstart="dragstart"
    @drop="drop"
    @dragover="dragover"
    @dragleave="dragleave"
  >
    <template #empty>
      <p>Please add at least one product.</p>
      <b-table-column cell-class="substitute-cell" label="Product label" />
      <b-table-column cell-class="substitute-cell" label="Supplier" />
      <b-table-column cell-class="substitute-cell" label="References" />
      <b-table-column cell-class="substitute-cell" label="Pricing" />
      <b-table-column cell-class="substitute-cell" label="Actions" />
    </template>

    <template slot-scope="props">
      <b-table-column width="250" cell-class="substitute-cell" label="Product label">
        <div class="label-wrapper">
          <b-icon icon="menu" />
          {{ props.row.product.label }}
        </div>
      </b-table-column>

      <b-table-column cell-class="substitute-cell supplier-cell" label="Supplier">
        {{ providerLabel }}
      </b-table-column>

      <b-table-column cell-class="substitute-cell reference-cell" label="References">
        {{ props.row.product.reference || 'no reference' }}
      </b-table-column>

      <b-table-column cell-class="substitute-cell pricing-cell" label="Pricing">
        <b-select
          :disabled="disabled"
          :value="props.row.pricingStrategy"
          @input="$emit('update:substitute-pricing', { productUuid: props.row.product.uuid, pricingStrategy: $event })"
        >
          <option value="MAIN_PRICE">Main price</option>
          <option value="SUBSTITUTE_PRICE">Substitute price</option>
        </b-select>
      </b-table-column>

      <b-table-column cell-class="substitute-cell" label="Actions">
        <div class="actions-wrapper">
          <b-switch
            :disabled="disabled"
            type="is-info"
            :value="props.row.enabled"
            @input="$emit('update:substitute-enabled', { productUuid: props.row.product.uuid, enabled: $event })"
          />
          <b-button
            :disabled="disabled"
            @click="$emit('remove:substitute', { productUuid: props.row.product.uuid })"
            outlined
            type="is-danger"
            icon-right="delete"
            aria-label="remove substitute"
          />
        </div>
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'ProductSubstitutesTable',
  props: {
    selectedProductSubstitutes: {
      type: Array,
      default: () => [],
    },
    providerLabel: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggingRowIndex: null,
    };
  },
  methods: {
    dragstart(payload) {
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = 'copy';
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy';
      payload.event.target.closest('tr').classList.add('is-selected');
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected');
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected');
      const droppedOnRowIndex = payload.index;
      this.$emit('update:substitute-priority', {
        from: this.draggingRowIndex,
        to: droppedOnRowIndex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.substitutes-table ::v-deep {
  .table th:not(:first-child) .th-wrap {
    justify-content: center;
  }

  .table th {
    vertical-align: middle;
  }

  .table tr.is-selected {
    background-color: #209cee;
  }

  .table th:first-child,
  .table th:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .table td:first-child,
  .table td:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .pricing-cell {
    .control {
      text-align: center;
    }
  }

  .supplier-cell,
  .reference-cell {
    text-align: center;
  }

  .label-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: center;
  }

  .substitute-cell {
    vertical-align: middle;
  }

  .actions-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switch {
    margin-right: 0;
  }
}
</style>
