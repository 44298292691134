<template>
  <div>
    <b-tabs>
      <b-tab-item>
        <template #header> General Information </template>
        <HubInfoForm
          :hub="hub"
          @hub:update="(hub) => $emit('hub:update', hub)"
          :current-location="currentLocation"
          :localized-infos="parentLocalizedInformation"
        />
      </b-tab-item>
      <b-tab-item v-if="showKitchensSpecific">
        <template #header>
          <span v-show="updatedLocalizedInfo.length > 0" class="dirty-tab">
            <b-icon pack="fas" icon="asterisk" type="is-danger" size="is-small" />
          </span>
          Kitchens Specific Information
        </template>
        <HubLocalizedInfoForm
          :current-location="currentLocation"
          :localized-infos="hub.localized_informations || []"
          :parent-localized-infos="parentLocalizedInformation"
          :is-fetching-localized-info="isFetchingLocalizedInfo"
          :updated-locations="updatedLocations"
          :show-automatic-edi-integration="showAutomaticEdiIntegration"
          :has-automatic-edi-integration="hasAutomaticEdiIntegration"
          @update:automatic-edi-integration="$emit('update:has-automatic-edi-integration', $event)"
          @on-change:location="$emit('on-change:location', $event)"
          @localized-hub:update="onLocalizedHub"
        />
      </b-tab-item>
    </b-tabs>
    <div class="buttons is-right">
      <b-button type="is-primary" :disabled="errorMessage !== null" :loading="isSaving" @click="onSave">
        <template v-if="hub.uuid">Save</template>
        <template v-else>Create</template>
      </b-button>
      <b-button @click="$emit('cancel')">Cancel</b-button>
    </div>
    <p v-show="errorMessage" class="is-size-7 is-italic has-text-right has-text-weight-light has-text-danger">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import HubInfoForm from './HubInfoForm.vue';
import HubLocalizedInfoForm from './HubLocalizedInfoForm.vue';
import { union, keyBy } from 'lodash';

export default {
  name: 'HubForm',
  components: {
    HubInfoForm,
    HubLocalizedInfoForm,
  },
  model: {
    prop: 'hub',
    event: 'hub:update',
  },
  props: {
    automaticEdiIntegrationHandler: {
      type: Object,
      default: null,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    hub: {
      type: Object,
      required: true,
    },
    isFetchingLocalizedInfo: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: () => false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    showKitchensSpecific: {
      type: Boolean,
      default: false,
    },
    showAutomaticEdiIntegration: {
      type: Boolean,
      default: false,
    },
    hasAutomaticEdiIntegration: {
      type: Boolean,
      default: false,
    },
    parentLocalizedInformation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selectedLocations: [],
    };
  },
  computed: {
    locationsKeyByUuid() {
      return keyBy(this.currentLocation.locations, 'uuid');
    },
    availableLocations() {
      return [...this.currentLocation.locations].sort((left, right) => left.id.localeCompare(right.id));
    },
    errorMessage() {
      const { uuid, label, mails } = this.hub;

      if (!uuid && !label) {
        return 'Missing label';
      } else if (!Array.isArray(mails) || !mails.length) {
        return 'Missing hub mails';
      }

      return null;
    },
    updatedLocations() {
      return union(this.updatedLocalizedInfo, this.updatedLocalizedAutomaticEdiIntegration);
    },
    updatedLocalizedInfo() {
      if (this.currentLocation.parent_uuid !== null) {
        return [];
      }

      const localizedInfos = this.hub.localized_informations || [];
      return localizedInfos
        .filter((info) => info.update !== undefined)
        .map((info) => {
          const location = this.locationsKeyByUuid[info.location_uuid];

          return location ? location.id : undefined;
        })
        .filter((label) => label !== undefined);
    },
    updatedLocalizedAutomaticEdiIntegration() {
      if (this.currentLocation.parent_uuid !== null || !this.showAutomaticEdiIntegration) {
        return [];
      }

      const updatedLocation = [];

      for (const [locationUuid, { initialValue, updatedValue }] of Object.entries(
        this.automaticEdiIntegrationHandler
      )) {
        if (updatedValue !== null && updatedValue !== initialValue) {
          const location = this.locationsKeyByUuid[locationUuid];

          if (location && location.label) {
            updatedLocation.push(location.label);
          }
        }
      }

      return updatedLocation;
    },
  },
  methods: {
    getParentLocation() {
      return this.currentLocation.parent_uuid === null ? this.currentLocation.uuid : this.currentLocation.parent_uuid;
    },
    onLocationSelected(locations) {
      this.selectedLocations = locations;
    },
    onLocalizedHub(
      location_uuid,
      { account_code, contact_email, mails, use_translation_with_provider, available_for_ordering }
    ) {
      const localized_informations = Array.isArray(this.hub.localized_informations)
        ? [...this.hub.localized_informations]
        : [];

      const idx = localized_informations.findIndex((info) => info.location_uuid === location_uuid);
      if (idx > -1) {
        localized_informations[idx] = {
          update: true,
          ...localized_informations[idx],
          account_code,
          contact_email,
          mails,
          use_translation_with_provider,
          available_for_ordering,
        };
      } else {
        localized_informations.push({
          update: false,
          location_uuid,
          account_code,
          contact_email,
          mails,
          use_translation_with_provider,
          available_for_ordering,
        });
      }
      this.$emit('hub:update', {
        ...this.hub,
        localized_informations,
      });
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style scoped>
.dirty-tab {
  font-size: 8px;
}

.dirty-tab > * {
  margin: 0px !important;
}
</style>
