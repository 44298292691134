<template>
  <div class="box modal-card">
    <h3 class="subtitle">New supplier</h3>
    <SupplierForm
      v-model="supplier"
      :current-location="currentLocation"
      :tags="tags"
      :is-saving="isSaveLoading"
      @save="create"
      @cancel="$emit('close')"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SupplierForm from '@/components/Supplier/SupplierForm.vue';
import { errorToaster } from '@/mixins';
import { suppliersTypes } from '@/store/mutation-types';

export default {
  name: 'NewSupplier',
  components: { SupplierForm },
  mixins: [errorToaster],
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentSupplier: (state) => state.suppliers.current,
      tags: (state) => state.suppliers.tags,
      isSaveLoading: (state) => state.suppliers.createOne.pending,
      saveError: (state) => state.suppliers.createOne.error,
    }),
    supplier: {
      get() {
        return { ...this.currentSupplier };
      },
      set(value) {
        this.setCurrentSupplier(value);
      },
    },
  },
  methods: {
    ...mapActions('suppliers', ['buildOne', 'createOne', 'addLocalizedInformation', 'fetchTags']),
    ...mapMutations('suppliers', { setCurrentSupplier: suppliersTypes.SET_CURRENT_SUPPLIER }),
    displayShippingPolicyError(message, duration = 3000) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        duration,
        message,
      });
    },
    async create() {
      const localizedInfos = Array.isArray(this.supplier.localized_informations)
        ? [...this.supplier.localized_informations]
        : [];
      await this.createOne(this.supplier);

      // Creating new localized information
      for (const info of localizedInfos) {
        const { account_code, contact_email, mails, location_uuid } = info;
        await this.addLocalizedInformation({
          supplierUuid: this.currentSupplier.uuid,
          payload: { account_code, contact_email, mails, location_uuid },
        });
      }

      this.$emit('close');
      this.$router.push(`/suppliers/${this.currentSupplier.uuid}`);
    },
  },
  created() {
    this.buildOne();
    if (this.$store.state.suppliers.tags === null || this.$store.state.suppliers.tags.length == 0) {
      this.fetchTags();
    }
  },
};
</script>

<style scoped>
.box {
  min-width: 760px;
}
</style>
