import clientHook from '@/apis';
import { trimPayload } from './utils';

const ProductsManagerClient = require('@tasterkitchens/products-manager-client');

const hubsApi = new ProductsManagerClient.HubsApi();
hubsApi.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  buildOne() {
    const days = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    };

    return {
      ...new ProductsManagerClient.HubCreationRequest(),
      open_days: { ...days },
      delivery_days: { ...days },
    };
  },
  fetchAll() {
    return clientHook(hubsApi).appLegacyApisV2HubsListHubs({ summary: true });
  },
  fetchOne(uuid, { includeLocalizedInfos = false }) {
    return clientHook(hubsApi).appLegacyApisV2HubsGetHubByUuid(uuid, { includeLocalizedInfos });
  },
  createOne(hub) {
    return clientHook(hubsApi).appLegacyApisV2HubsCreateHub(hub);
  },
  updateOne(hubUUID, hub) {
    return clientHook(hubsApi).appLegacyApisV2HubsUpdateHubByUuid(hubUUID, hub);
  },
  removeOne(hubUUID) {
    return clientHook(hubsApi).appLegacyApisV2HubsDeleteHubByUuid(hubUUID);
  },
  fetchOneLocalizedInformation(hubUuid, locationUuid) {
    return clientHook(hubsApi).appLegacyApisV2HubsGetHubLocalizedInformation(hubUuid, locationUuid);
  },
  addLocalizedInformation(hubUuid, payload) {
    let data = trimPayload(payload);
    if ('contact_email' in payload && payload.contact_email !== null && payload.contact_email.length === 0) {
      data.contact_email = null;
    }
    return clientHook(hubsApi).appLegacyApisV2HubsAddHubLocalizedInformation(hubUuid, data);
  },
  updateLocalizedInformation(hubUuid, locationUuid, payload) {
    let data = trimPayload(payload);
    if ('contact_email' in payload && payload.contact_email !== null && payload.contact_email.length === 0) {
      data.contact_email = null;
    }
    return clientHook(hubsApi).appLegacyApisV2HubsUpdateHubLocalizedInformation(hubUuid, locationUuid, data);
  },
  removeLocalizedInformation(hubUuid, locationUuid) {
    return clientHook(hubsApi).appLegacyApisV2HubsRemoveHubLocalizedInformation(hubUuid, locationUuid);
  },
  addShippingPolicy(
    hubUuid,
    {
      location_uuid,
      actions,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
    }
  ) {
    if (!location_uuid) {
      throw new TypeError('You should always provide a location UUID to add a new shipping policy');
    }

    return clientHook(hubsApi).appLegacyApisV2HubsAddHubShippingPolicy(hubUuid, {
      location_uuid,
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    });
  },
  updateShippingPolicy(
    hubUuid,
    policyUuid,
    {
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    }
  ) {
    return clientHook(hubsApi).appLegacyApisV2HubsUpdateHubShippingPolicy(hubUuid, policyUuid, {
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    });
  },
  removeShippingPolicy(hubUuid, policyUuid) {
    return clientHook(hubsApi).appLegacyApisV2HubsRemoveHubShippingPolicy(hubUuid, policyUuid);
  },
};
