<template>
  <div>
    <b-field v-if="multipleLocations">
      <b-select v-model="editedLocation" placeholder="Select a location to edit" expanded>
        <option v-for="location of currentLocation.locations" :key="location.uuid" :value="location.uuid">
          {{ location.id }}
        </option>
      </b-select>
    </b-field>
    <div v-show="updatedLocations.length > 0" class="updated-locations">
      <span class="has-text-weight-normal is-size-7"> Local information updated for: </span>
      <b-tag v-for="location of updatedLocations" :key="location" type="is-danger">
        {{ location }}
      </b-tag>
    </div>
    <div v-if="editedLocation !== null && isFetchingLocalizedInfo">
      <b-skeleton />
      <b-skeleton width="40%" />
      <b-skeleton width="60%" />
    </div>
    <form v-else-if="editedLocation !== null">
      <b-message type="is-info" has-icon v-if="!isLocalized">
        Hub is not set for this kitchen, it uses country configuration
      </b-message>
      <b-field label="Account code">
        <b-input
          v-model="accountCode"
          type="text"
          placeholder="Enter a supplier specific account code for the location"
        />
      </b-field>
      <b-field label="Contact email">
        <b-input
          v-model="contactEmail"
          type="email"
          placeholder="Enter the mail that shall use the hub to contact the kitchen"
        />
      </b-field>
      <b-field label="Hub emails">
        <b-taginput
          v-model="mails"
          :before-adding="validateMailInput"
          placeholder="Enter the mail that shall be used to place orders to the hub"
        />
      </b-field>
      <b-field label="Use translation for supplier">
        <b-switch v-model="useTranslationWithProvider"></b-switch>
      </b-field>
      <b-field label="Available for ordering">
        <b-switch v-model="availableForOrdering"></b-switch>
      </b-field>
      <b-field v-if="showAutomaticEdiIntegration" label="Automatic EDI Integration">
        <b-switch
          v-model="automaticEdiIntegration"
          :disabled="!accountCode || !contactEmail"
          @input="$emit('update:automatic-edi-integration', $event)"
        />
      </b-field>
    </form>
  </div>
</template>

<script>
import { MAIL_REGEX } from '@/constants';

export default {
  name: 'HubLocalizedInfoForm',
  props: {
    currentLocation: {
      type: Object,
      required: true,
    },
    localizedInfos: {
      type: Array,
      default: () => [],
    },
    isFetchingLocalizedInfo: {
      type: Boolean,
      default: false,
    },
    updatedLocations: {
      type: Array,
      default: () => [],
    },
    showAutomaticEdiIntegration: {
      type: Boolean,
      default: false,
    },
    hasAutomaticEdiIntegration: {
      type: Boolean,
      default: false,
    },
    parentLocalizedInfos: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      automaticEdiIntegration: false,
      editedLocation: null,
    };
  },
  computed: {
    isLocalized() {
      return this.localizedInfos.find(({ location_uuid }) => location_uuid === this.editedLocation);
    },
    localizedInfo() {
      if (!this.localizedInfos || !this.editedLocation) {
        return {};
      }
      return this.localizedInfos.find((info) => info.location_uuid === this.editedLocation) || {};
    },
    accountCode: {
      get() {
        return this.localizedInfo.account_code ?? null;
      },
      set(value) {
        this.emitLocalizedUpdateEvent({ account_code: value });
      },
    },
    contactEmail: {
      get() {
        return this.localizedInfo.contact_email ?? null;
      },
      set(value) {
        this.emitLocalizedUpdateEvent({ contact_email: value });
      },
    },
    mails: {
      get() {
        return this.localizedInfo.mails ?? null;
      },
      set(value) {
        this.emitLocalizedUpdateEvent({ mails: value });
      },
    },
    useTranslationWithProvider: {
      get() {
        if (this.localizedInfo.hasOwnProperty('use_translation_with_provider')) {
          return this.localizedInfo.use_translation_with_provider;
        }
        if (this.parentLocalizedInfos) {
          return this.parentLocalizedInfos.use_translation_with_provider;
        }
        return false;
      },
      set(value) {
        this.emitLocalizedUpdateEvent({
          use_translation_with_provider: value,
        });
      },
    },
    availableForOrdering: {
      get() {
        if (this.localizedInfo.hasOwnProperty('available_for_ordering')) {
          return this.localizedInfo.available_for_ordering;
        }
        if (this.parentLocalizedInfos) {
          return this.parentLocalizedInfos.available_for_ordering;
        }
        return false;
      },
      set(value) {
        this.emitLocalizedUpdateEvent({
          available_for_ordering: value,
        });
      },
    },
    multipleLocations() {
      return this.currentLocation.parent_uuid === null;
    },
  },
  watch: {
    editedLocation(value) {
      this.$emit('on-change:location', value);
    },
    hasAutomaticEdiIntegration(value) {
      this.automaticEdiIntegration = value;
    },
  },
  mounted() {
    const isParent = this.currentLocation.parent_uuid === null;
    this.editedLocation = !isParent ? this.currentLocation.uuid : null;
  },
  methods: {
    emitLocalizedUpdateEvent(patch) {
      if (!this.editedLocation) {
        return;
      }

      this.$emit('localized-hub:update', this.editedLocation, { ...this.localizedInfo, ...patch });
    },
    validateMailInput(value) {
      if (!MAIL_REGEX.test(value)) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Invalid email',
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.updated-locations {
  display: flex;
  align-items: center;
  align-content: center;
}

.updated-locations span.is-size-7 {
  margin-right: 5px;
}
</style>
