<template>
  <div class="box modal-card">
    <div class="has-text-centered">
      <h4 class="title has-text-centered is-6">Select Concepts</h4>
      <div class="block">
        <b-checkbox
          size="is-small"
          v-model="selectedConcepts"
          v-for="concept in getActiveConcepts()"
          :native-value="concept.uuid"
          :key="concept.uuid"
        >
          {{ concept.label }}
        </b-checkbox>
      </div>
      <hr />
      <h4 class="title has-text-centered is-6">Select Locations</h4>
      <div class="block">
        <b-checkbox
          size="is-small"
          v-model="selectedLocations"
          v-for="location in getRelatedLocations()"
          :native-value="location.uuid"
          :key="location.uuid"
        >
          {{ location.label }}
        </b-checkbox>
      </div>
    </div>
    <hr />
    <b-button type="is-info" style="margin-bottom: 4px" @click="run()" :disabled="runDisabled()" expanded>
      Run Product Usage Refresh
    </b-button>
    <p class="is-size-7 is-italic has-text-right has-text-weight-light">
      NB : Run Product Usage Refresh takes around 5 minutes by Location
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['currentLocation', 'locationsUnnested', 'concepts'],
  data: () => ({
    selectedConcepts: [],
    selectedLocations: [],
  }),
  methods: {
    ...mapActions('summary', ['refreshProductUsageResults']),
    getActiveConcepts() {
      var concepts = this.concepts.filter((concept) => !concept.is_deleted);
      return concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
    },
    getRelatedLocations() {
      var parentUuid = null;
      if (!this.currentLocation.parent_uuid) {
        parentUuid = this.currentLocation.uuid;
      } else {
        parentUuid = this.currentLocation.parent_uuid;
      }
      return this.locationsUnnested
        .filter((location) => location.parent_uuid == parentUuid)
        .sort((location1, location2) => location1.label.localeCompare(location2.label));
    },
    runDisabled() {
      if (this.selectedLocations.length == 0) {
        return true;
      }
      return false;
    },
    run() {
      this.refreshProductUsageResults({ locationUuids: this.selectedLocations, conceptUuids: this.selectedConcepts });
      this.$parent.close();
    },
  },
  computed: {
    ...mapState({}),
  },
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
