<template>
  <form @submit.prevent="onSave">
    <LocationDropdown
      label="Select kitchens"
      class="product-substitutes-locations-dropdown"
      :disabled="disabled"
      :location-root="kitchenOptions"
      :locations="selectedKitchens"
      @location-dropdown:update="updateLocationUuids"
    />
    <SearchProductSubstitutes
      v-model="searchProductSubstitutesModel"
      :disabled="disabled"
      :product-substitute-options="filteredProductSubstitutes"
      :search-loading="searchLoading"
      :search-more-loading="searchMoreLoading"
      :mainBaseUnit="mainBaseUnit"
      @add:substitute="addSubstitute"
      @nextPage="$emit('next-page')"
    />
    <ProductSubstitutesTable
      :disabled="disabled"
      :providerLabel="providerLabel"
      :selectedProductSubstitutes="selectedSubstitutes"
      @update:substitute-pricing="updateSubstitutePricing"
      @update:substitute-enabled="updateSubstituteEnabled"
      @update:substitute-priority="updateSubstitutePriority"
      @remove:substitute="removeSubstitute"
    />
    <b-button v-if="hasChanged" type="is-warning" @click="$emit('reset')">Reset</b-button>
    <b-button :disabled="isSaveRuleDisable" type="is-info" native-type="submit">Save this rule</b-button>
  </form>
</template>

<script>
import LocationDropdown from '@/components/ShippingPolicy/LocationDropdown';
import SearchProductSubstitutes from '@/components/SubstitutesManagement/SearchProductSubstitutes';
import ProductSubstitutesTable from '@/components/SubstitutesManagement/ProductSubstitutesTable';

export default {
  name: 'SubstituteRuleForm',
  components: {
    LocationDropdown,
    SearchProductSubstitutes,
    ProductSubstitutesTable,
  },
  props: {
    productUuid: {
      type: String,
      required: true,
    },
    mainBaseUnit: {
      type: String,
      required: true,
    },
    selectedSubstitutes: {
      type: Array,
      default: () => [],
    },
    selectedKitchens: {
      type: Array,
      default: null,
    },
    kitchenOptions: {
      type: Object,
      required: true,
    },
    hasChanged: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    substituteRuleForm: {
      type: Object,
      required: true,
    },
    productSubstitutesOptions: {
      type: Array,
      required: true,
    },
    searchMoreLoading: {
      type: Boolean,
      default: false,
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
    searchProductSubstitutes: {
      type: String,
      default: '',
      required: true,
    },
    providerLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSaveRuleDisable() {
      return !this.isValidRule || this.disabled || !this.hasChanged;
    },
    substituteRuleFormModel: {
      get() {
        return this.substituteRuleForm;
      },
      set(updatedSubstituteRuleForm) {
        this.$emit('update:substitute-rule-form', updatedSubstituteRuleForm);
      },
    },
    filteredProductSubstitutes() {
      return this.productSubstitutesOptions.filter(
        (productSubstitute) =>
          !this.selectedSubstitutes.some(({ product: { uuid } }) => uuid === productSubstitute.uuid) &&
          productSubstitute.uuid !== this.productUuid
      );
    },
    searchProductSubstitutesModel: {
      get() {
        return this.searchProductSubstitutes;
      },
      set(newSearchValue) {
        this.$emit('update:search-product-substitutes', newSearchValue);
      },
    },
    hasAtleastOneSubstitute() {
      return !!this.selectedSubstitutes.length;
    },
    hasAtleastOneLocation() {
      return !!this.substituteRuleFormModel.locationUuids.length;
    },
    isValidRule() {
      return this.hasAtleastOneSubstitute && this.hasAtleastOneLocation;
    },
  },
  methods: {
    updateLocationUuids(locations) {
      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        locationUuids: locations,
      };
    },
    updateSubstitutePriority({ from, to }) {
      if (from === to) {
        return;
      }
      let newSubstitutes = [...this.substituteRuleForm.substitutes];
      const productSubstitute = newSubstitutes.splice(from, 1)[0];

      newSubstitutes.splice(to, 0, productSubstitute);
      newSubstitutes = this.refreshPriority(newSubstitutes);

      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        substitutes: newSubstitutes,
      };
    },
    refreshPriority(substitutes) {
      return substitutes.map((substitute, index) => ({
        ...substitute,
        priority: index + 1,
      }));
    },
    async removeSubstitute({ productUuid }) {
      let newSubstitutes = [...this.substituteRuleForm.substitutes];

      newSubstitutes = newSubstitutes.filter((productSubstitute) => productSubstitute.product.uuid !== productUuid);
      newSubstitutes = this.refreshPriority(newSubstitutes);

      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        substitutes: newSubstitutes,
      };
    },
    updateSubstituteEnabled({ productUuid, enabled }) {
      const substitutes = this.selectedSubstitutes.map((substitute) =>
        substitute.product.uuid === productUuid ? { ...substitute, enabled } : substitute
      );

      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        substitutes,
      };
    },
    updateSubstitutePricing({ productUuid, pricingStrategy }) {
      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        substitutes: this.selectedSubstitutes.map((substitute) =>
          substitute.product.uuid === productUuid ? { ...substitute, pricingStrategy } : substitute
        ),
      };
    },
    async addSubstitute(product) {
      const newSubstitute = {
        product,
        priority: this.selectedSubstitutes.length + 1,
        enabled: true,
        pricingStrategy: 'MAIN_PRICE',
      };

      this.substituteRuleFormModel = {
        ...this.substituteRuleFormModel,
        substitutes: [...this.selectedSubstitutes, newSubstitute],
      };
    },
    async onSave() {
      this.$emit('save-rule');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.product-substitutes-locations-dropdown {
  ::v-deep .label {
    font-weight: bold !important;
    font-size: 1rem !important;
  }
}
</style>
