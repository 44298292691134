<template>
  <article class="modal-card">
    <section class="inner">
      <template v-if="pending && !complete">
        <div class="pending" />
      </template>
      <template v-else-if="complete">
        <article class="complete">
          <button class="close" @click="$emit('close')">&cross;</button>
          <div class="feedback">
            <b-icon icon="check" />
          </div>
          <h1>Your shipping policy is delete</h1>
        </article>
      </template>
      <template v-else>
        <header>
          <h1>Are you sure?</h1>
        </header>
        <article class="body">
          Do you really want to delete this shipping policy? This process cannot be undone.
        </article>
        <footer>
          <button class="button is-taster-primary" @click="$emit('confirm')">Yes, delete it</button>
          <button class="button is-outlined is-taster-primary" @click="$emit('close')">No, cancel</button>
        </footer>
      </template>
    </section>
  </article>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data() {
    return {
      pending: false,
      complete: false,
    };
  },
  methods: {
    setPending(value) {
      this.pending = value;
    },
    setComplete(value) {
      this.complete = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

.modal-card {
  background: #fff;
  border: 0.5px solid #02091d;
  border-radius: 6px;
  padding: 16px 24px;
  width: 272px;

  .inner {
    width: 100%;
  }
}

.pending {
  display: flex;
  justify-content: center;
  padding: 32px 0px;

  &::after {
    @include loader;
    border-left-color: $taster-primary;
    border-bottom-color: $taster-primary;
    height: 40px;
    width: 40px;
  }
}

.complete {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;

  button.close {
    position: absolute;
    top: -8px;
    right: -16px;

    background: $taster-primary;
    border: none;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    line-height: 12px;
    padding: 0px;
    width: 20px;
  }

  .feedback {
    align-items: center;
    background: $taster-primary;
    border-radius: 100%;
    color: #fff;
    display: inline-flex;
    height: 30px;
    margin-bottom: 14px;
    justify-content: center;
    width: 30px;
  }
}

h1 {
  color: #02091d;
}

header {
  margin-bottom: 4px;

  h1 {
    font-size: 18px;
    line-height: 24px;
  }
}

.body {
  align-items: center;
  color: #747474;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
}

footer {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  .button {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
