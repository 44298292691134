<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">Hubs {{ hubs && `(${hubs.length})` }}</h1>
        </div>
        <div class="column is-narrow">
          <b-button type="is-rounded is-primary" icon-left="plus" @click="openNewSupplierModal"> New Hub </b-button>
        </div>
      </div>
      <b-message type="is-danger" v-if="error"> An error has occured while fetching hubs. </b-message>
      <div v-if="!error">
        <b-loading :active.sync="isLoading && !hubs.length"></b-loading>
        <b-table
          v-if="!isLoading || hubs.length"
          @click="(row) => $router.push('/hubs/' + row.uuid)"
          class="is-hoverable is-fullwidth is-striped"
          hoverable
          striped
          :data="hubs"
          paginated
          per-page="10"
          default-sort="label"
          default-sort-direction="asc"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Name" sortable>
              <b>{{ props.row.label }}</b>
            </b-table-column>
            <b-table-column field="mail" label="Email">
              {{ props.row.mail }}
            </b-table-column>
            <b-table-column field="address" label="Address">
              {{ props.row.address }}
            </b-table-column>
            <b-table-column field="website" label="Website">
              {{ props.row.website }}
            </b-table-column>
            <b-table-column>
              <div class="field is-grouped">
                <b-button
                  type="is-info"
                  icon-left="truck"
                  size="is-small"
                  outlined
                  @click.stop="openEditShippingPoliciesModal(props.row.uuid)"
                >
                  Shipping policies
                </b-button>
                <b-button
                  type="is-info"
                  icon-left="pencil"
                  size="is-small"
                  outlined
                  @click.stop="openEditHubModal(props.row.uuid)"
                >
                  Edit
                </b-button>
                <b-button
                  type="is-danger"
                  size="is-small"
                  icon-left="delete"
                  outlined
                  @click.stop="openRemoveHubDialog(props.row)"
                >
                  Delete
                </b-button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import EditShippingPolicies from '../ShippingPolicy/EditShippingPolicies.vue';
import EditHub from './EditHub';
import NewHub from './NewHub';

export default {
  computed: {
    ...mapState({
      hubs: (state) => state.hubs.all,
      isLoading: (state) => state.hubs.all.pending,
      error: (state) => state.hubs.fetchAll.error,
    }),
  },
  methods: {
    ...mapActions('hubs', ['fetchAll', 'removeOne']),
    openNewSupplierModal() {
      this.$buefy.modal.open({
        component: NewHub,
        parent: this,
        hasModalCard: true,
      });
    },
    openEditHubModal(id) {
      this.$buefy.modal.open({
        component: EditHub,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openEditShippingPoliciesModal(uuid) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditShippingPolicies,
        props: { uuid, providerType: 'hub' },
      });
    },
    openRemoveHubDialog(hub) {
      this.$buefy.dialog.confirm({
        title: 'Deleting hub',
        message: `Are you sure you want to <b>delete</b> ${hub.label}?`,
        confirmText: 'Delete hub',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeOne(hub.uuid),
      });
    },
  },
  mounted() {
    if (this.$store.state.hubs.all === null || this.$store.state.hubs.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}

.button.is-small {
  margin: 0em 0.25em;
}
</style>
