<template>
  <section>
    <b-dropdown v-model="perimetersSelected" multiple aria-role="list">
      <button class="button is-dark" type="button" slot="trigger">
        <span> Perimeters ({{ perimetersSelected.length }})</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(perimeter, index) in perimeters" :key="index" :value="perimeter" aria-role="listitem">
        <span> {{ perimeter }} </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-model="conceptsSelected" multiple aria-role="list">
      <button class="button is-dark" type="button" slot="trigger">
        <span> Concepts ({{ conceptsSelected.length }})</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(concept, index) in concepts" :key="index" :value="concept" aria-role="listitem">
        <span> {{ concept.label }} </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-if="locations.length > 1" v-model="locationsSelected" multiple aria-role="list">
      <button class="button is-dark" type="button" slot="trigger">
        <span> Locations ({{ locationsSelected.length }})</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(lcoation, index) in locations" :key="index" :value="lcoation" aria-role="listitem">
        <span> {{ lcoation.id }} </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-model="itemsTypesSelected" multiple aria-role="list">
      <button class="button is-dark" type="button" slot="trigger">
        <span> Item Types ({{ itemsTypesSelected.length }})</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(itemsType, index) in itemsTypes" :key="index" :value="itemsType" aria-role="listitem">
        <span> {{ itemsType }} </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-model="errorTypesSelected" multiple aria-role="list">
      <button class="button is-dark" type="button" slot="trigger">
        <span>Error Type ({{ errorTypesSelected.length }})</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(errorType, index) in errorTypes" :key="index" :value="errorType" aria-role="listitem">
        <span> {{ errorType }} </span>
      </b-dropdown-item>
    </b-dropdown>
  </section>
</template>

<script>
export default {
  props: [
    'errorTypes',
    'errorTypesSelected',
    'perimeters',
    'perimetersSelected',
    'itemsTypes',
    'itemsTypesSelected',
    'concepts',
    'conceptsSelected',
    'locations',
    'locationsSelected',
  ],
};
</script>

<style>
.dropdown-trigger.a.dropdown-item.is-active {
  background-color: #ff3860;
}
</style>
