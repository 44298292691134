import conceptsAPI from '@/apis/kitchens/concepts.js';
import { conceptsTypes } from '../mutation-types.js';

// const namespace = "concepts";

export default {
  namespaced: true,
  state: {
    categories: ['Asian', 'Burger', 'Kebab'],
    all: [],
    concepts: {},
    current: {},
    fetchAll: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    uploadPicture: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [conceptsTypes.FETCH_ALL_SUCCESS](state, concepts) {
      state.all = concepts;
      state.fetchAll.pending = false;
    },
    [conceptsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [conceptsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [conceptsTypes.SET_CURRENT_CONCEPT](state, concept) {
      state.current = concept;
    },
    [conceptsTypes.UPDATE_ONE_SUCCESS](state, concept) {
      const index = state.all.findIndex(({ uuid }) => concept.uuid === uuid);
      if (index > -1) {
        state.all.splice(index, 1, concept);
      }
      state.updateOne.pending = false;
    },
    [conceptsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [conceptsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
    },
    [conceptsTypes.CREATE_ONE_SUCCESS](state, concept) {
      state.current = concept;
      state.all.push(concept);
      state.createOne.pending = false;
      state.createOne.error = null;
    },
    [conceptsTypes.CREATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [conceptsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(conceptsTypes.FETCH_ALL_REQUEST);

      return (
        conceptsAPI
          .fetchAll()
          .then(({ data }) => commit(conceptsTypes.FETCH_ALL_SUCCESS, data))
          //   .then((concepts) => commit(conceptsTypes.FETCH_ALL_SUCCESS, concepts))
          .catch((error) => commit(conceptsTypes.FETCH_ALL_FAILURE, error))
      );
    },
    async fetchOne({ commit }, conceptUUID) {
      return conceptsAPI
        .fetchOne(conceptUUID)
        .then(function ({ data }) {
          commit(conceptsTypes.SET_CURRENT_CONCEPT, data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async updateOne({ commit, state }) {
      commit(conceptsTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.category;
      delete data.concept_information;
      delete data.uuid;
      delete data.restaurants;
      delete data.platform_pictures;
      delete data.logo;
      delete data.is_deleted;

      return conceptsAPI
        .updateOne(state.current.uuid, data)
        .then(({ data }) => commit(conceptsTypes.UPDATE_ONE_SUCCESS, data))
        .catch((error) => commit(conceptsTypes.UPDATE_ONE_FAILURE, error));
    },
    async uploadPicture({ commit }, { conceptUuid, picture, pictureType, platformUuid }) {
      commit(conceptsTypes.UPDATE_ONE_REQUEST);

      return conceptsAPI
        .uploadPicture(conceptUuid, picture, pictureType, platformUuid)
        .then(({ data }) => commit(conceptsTypes.UPDATE_ONE_SUCCESS, data))
        .catch((error) => commit(conceptsTypes.UPDATE_ONE_FAILURE, error));
    },
    async createOne({ commit }, conceptRequest) {
      commit(conceptsTypes.CREATE_ONE_REQUEST);

      return conceptsAPI
        .createOne(conceptRequest)
        .then(({ data }) => commit(conceptsTypes.CREATE_ONE_SUCCESS, data))
        .catch((error) => commit(conceptsTypes.CREATE_ONE_FAILURE, error));
    },
  },
  getters: {
    getConceptsCount: (state) => state.all.length,
  },
};
