<template>
  <div class="menu-container">
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching the menu. </b-message>
    <!-- Handles menu creation and enabling -->
    <MenuDetailsAddMenu
      class="menu-notification"
      @addLocation="addLocation"
      @createMenu="createMenu"
      @copyMenu="copyMenus"
    />
    <div v-if="!fetchError">
      <b-tabs size="is-small" type="is-boxed" horizontal destroy-on-hide v-model="currentPlatformIndex">
        <b-tab-item v-for="platform in getUpdatablePlatforms" :key="platform.uuid">
          <template slot="header">
            <img :src="platform.logo" width="21" />
            {{ platform.label }}
          </template>
          <br />
          <b-loading :active.sync="isFetchLoading"></b-loading>
          <template v-if="menuExists && menuItems && combos && modifierGroups">
            <!-- menu validate/cancel actions -->
            <div class="form-buttons" v-if="isItemMenuEditor()">
              <div>
                <b-button
                  v-if="currentLocation.uuid === currentMenu.location_uuid && currentLocation.parent_uuid"
                  class="btn-disable"
                  type="is-danger"
                  icon-left="delete"
                  @click="disableLocation"
                >
                  Disable location
                </b-button>
              </div>
              <div>
                <b-button @click="cancel"> Reset </b-button>
                <b-tooltip
                  :active="currentLocation.uuid !== currentMenu.location_uuid"
                  label="You can't save a menu that doesn't belong to your location"
                  position="is-bottom"
                >
                  <b-button
                    type="is-primary"
                    icon-left="content-save"
                    style="margin-left: 8px"
                    @click="saveMenu"
                    :disabled="currentLocation.uuid !== currentMenu.location_uuid"
                  >
                    Save
                  </b-button>
                </b-tooltip>
              </div>
            </div>

            <!-- Display menu categories -->
            <MenuDetailsCategories :platform="currentPlatform" />
          </template>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { isEmpty, cloneDeep } from 'lodash';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { auth } from '@/mixins';
import { menusTypes } from '@/store/mutation-types';

import MenuDetailsCategories from '@/components/Menu/MenuDetails/MenuDetailsCategories.vue';
import MenuDetailsAddMenu from '@/components/Menu/MenuDetails/MenuDetailsAddMenu.vue';

export default {
  name: 'MenuDetails',
  mixins: [auth],
  components: {
    MenuDetailsCategories,
    MenuDetailsAddMenu,
  },
  data() {
    return {
      initialMenu: null,
      currentPlatformIndex: 0,
    };
  },
  computed: {
    ...mapState({
      concept: (state) => state.concepts.current,
      isFetchLoading: (state) =>
        state.menus.fetchOne.pending || state.menus.copyMenu.pending || state.itemsInformations.fetchAll.pending,
      fetchError: (state) => state.menus.fetchOne.error,
      currentMenu: (state) => state.menus.current,
      locations: (state) => state.locations.all,
      currentLocation: (state) => state.locations.current,
      menusByConcept: (state) => state.menus.all,
      genericMenu: (state) => state.menus.generic,

      menuItems: (state) => state.menuItems.all,
      combos: (state) => state.combos.all,
      modifierGroups: (state) => state.modifierGroups.all,
    }),
    menuExists() {
      return this.currentMenu && !isEmpty(this.currentMenu);
    },
    currentPlatform() {
      if (this.currentPlatformIndex >= 0) {
        return this.getUpdatablePlatforms[this.currentPlatformIndex];
      }
      return null;
    },
    ...mapGetters('platforms', ['getUpdatablePlatforms']),
  },
  watch: {
    concept(newValue) {
      this.fetchMenu(this.currentLocation.uuid, newValue.uuid, this.currentLocation.location_tree);
      this.fetchAllItemsInformations({
        platformUuid: this.currentPlatform.uuid,
        conceptUuid: this.concept.uuid,
        mergeLocations: true,
      });
    },
    currentLocation(newValue) {
      this.fetchMenu(newValue.uuid, this.concept.uuid, newValue.location_tree);
    },
    currentMenu(newValue, oldValue) {
      if (!oldValue || newValue.uuid !== oldValue.uuid) {
        this.initialMenu = cloneDeep(this.currentMenu);
      }
    },
  },
  mounted() {
    if (this.currentLocation) {
      this.fetchMenu(this.currentLocation.uuid, this.concept.uuid, this.currentLocation.location_tree);
    }
  },
  methods: {
    ...mapActions('menus', {
      fetchOne: 'fetchOne',
      updateOne: 'updateOne',
      addLocation: 'addLocation',
      removeLocation: 'removeLocation',
      fetchAll: 'fetchAll',
      createOne: 'createOne',
      copyMenu: 'copyMenu',
    }),
    ...mapActions('itemsInformations', { fetchAllItemsInformations: 'fetchAll' }),
    ...mapMutations('menus', { setMenu: menusTypes.SET_CURRENT_MENU }),
    fetchMenu(locationUuid, conceptUuid, locationUuids) {
      if (!this.isFetchLoading) {
        this.fetchOne({
          locationUuid: locationUuid,
          conceptUuid: conceptUuid,
          locationUuids: locationUuids,
        }).then(() => {
          this.initialMenu = cloneDeep(this.currentMenu);
          // if a localized menu is not found
          // try to get the generic menu for the concept
          if (!this.menuExists) {
            this.fetchAll({ conceptUuid: this.concept.uuid });
          }
        });
      }
    },
    saveMenu() {
      this.updateOne();
      this.initialMenu = cloneDeep(this.currentMenu);
    },
    cancel() {
      this.setMenu(this.initialMenu);
      this.initialMenu = cloneDeep(this.currentMenu);
    },
    createMenu() {
      this.createOne().then(() => {
        if (this.genericMenu) {
          this.addLocation({ genericMenuUuid: this.genericMenu.uuid });
        }
      });
    },
    disableLocation() {
      // disable menu for current location
      this.removeLocation().then(() => {
        // get back the parent menu if exists
        this.fetchMenu(this.currentLocation.uuid, this.concept.uuid, this.currentLocation.location_tree);
      });
    },
    copyMenus(opts) {
      this.copyMenu(opts).then(() => {
        this.fetchMenu(this.currentLocation.uuid, this.concept.uuid, this.currentLocation.location_tree);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  max-width: 80%;

  .menu-notification {
    margin-bottom: 10px;
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .btn-disable {
      opacity: 0.7;
    }
    .btn-disable:hover {
      opacity: 1;
    }
  }
}
</style>
