<template>
  <div class="column is-4">
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <div
      class="box"
      v-if="!isLoading && !parentItem && item.location_uuid != currentLocation.uuid && isItemRecipeCreator()"
    >
      <h1 style="margin-bottom: 12px">{{ item.label }} has no specific recipe in {{ currentLocation.label }}</h1>
      <button @click="createRecipe()" class="button is-link is-small" style="margin-top: 8px">
        <b-icon icon="content-copy" type="is-white"></b-icon>
        <span class="has-text-white">Create Specific Recipe in {{ currentLocation.label }}</span>
      </button>
    </div>
    <div
      class="box"
      v-if="
        !isLoading &&
        !parentItem &&
        item.location_uuid == currentLocation.uuid &&
        currentLocation.parent_uuid &&
        isItemRecipeCreator()
      "
    >
      <h2 style="margin-bottom: 12px">{{ item.label }} has specific recipe in {{ currentLocation.label }}</h2>
      <button
        @click="$emit('remove', item)"
        v-if="isSpecificRecipe(item)"
        class="button is-danger is-small"
        style="margin-top: 8px"
      >
        <b-icon icon="delete" type="is-white"></b-icon>
        <span class="has-text-white">Remove {{ this.getLocation(item).id }} Recipe</span>
      </button>
    </div>
    <div class="box" v-if="!isLoading && parentItem">
      <div v-if="item.generic_uuid && item.location_uuid === currentLocation.uuid">
        <div>
          <ItemCompositionForm
            v-if="parentItem && parentItem.uuid && item && item.uuid && recipe && Object.keys(parentItem).length > 0"
            :itemQuantityInParent.sync="itemQuantityInParent"
            :itemUnitInParent.sync="itemUnitInParent"
            :parentItemLabel="parentItem.label"
            :itemLabel="item.label"
            :parentItemMeasure="parentItemMeasure"
            :isEditable.sync="isCompositionEditable"
            :isDisabled="false"
            :itemLocation="getLocation(item)"
            @save="saveComposition"
          />
          <button
            @click="$emit('remove', item)"
            v-if="isSpecificRecipe(item)"
            class="button is-danger is-small"
            style="margin-top: 8px"
          >
            <b-icon icon="delete" type="is-white"></b-icon>
            <span class="has-text-white">Remove {{ this.getLocation(item).id }} Recipe</span>
          </button>
        </div>
      </div>
      <div v-else-if="item && Object.keys(item).length > 0">
        <div>
          <ItemCompositionForm
            v-if="parentItem && parentItem.uuid && item && item.uuid && recipe && Object.keys(parentItem).length > 0"
            :itemQuantityInParent.sync="itemQuantityInParent"
            :itemUnitInParent.sync="itemUnitInParent"
            :parentItemLabel="parentItem.label"
            :itemLabel="item.label"
            :parentItemMeasure="parentItemMeasure"
            :isEditable.sync="isCompositionEditable"
            :isDisabled="true"
            :itemLocation="getLocation(item)"
          />
          <button @click="createRecipe()" class="button is-link is-small" style="margin-top: 8px">
            <b-icon icon="content-copy" type="is-white"></b-icon>
            <span class="has-text-white">Create Specific Recipe in {{ currentLocation.label }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="box">
      <div v-if="!isLoading">
        <div v-if="item.generic_uuid && item.location_uuid === currentLocation.uuid">
          <div class="is-flex">
            <div class="title is-5">{{ item.label }} ({{ getLocation(item).id }})</div>
            <b-button
              size="is-small"
              @click.stop="() => $router.push(`/items/${item.generic_uuid}`)"
              icon-right="open-in-new"
              type="is-text"
              rounded
            />
          </div>
          <div class="subtitle is-6" style="margin-bottom: 12px">{{ item.category }}</div>
          <ItemMeasureForm :item.sync="item" :isDisabled="true" />
          <ItemProductForm
            v-if="products.length && item && item.uuid"
            :product="itemProduct"
            :products="products"
            @set="setProduct"
            @unset="unsetProduct"
            :isDisabled="false"
          />
        </div>
        <div v-else-if="item && Object.keys(item).length > 0">
          <div class="is-flex">
            <div class="title is-5">{{ item.label }} ({{ getLocation(item).id }})</div>
          </div>
          <div class="subtitle is-6" style="margin-bottom: 12px">{{ item.category }}</div>
          <ItemMeasureForm :item.sync="item" :isDisabled="true" />
          <ItemProductForm
            v-if="products.length && item && item.uuid"
            :product="itemProduct"
            :products="products"
            @set="setProduct"
            @unset="unsetProduct"
            :isDisabled="true"
          />
        </div>
        <div v-else-if="genericItem && Object.keys(genericItem).length > 0">
          <AddLocationDialog
            :itemLabel="genericItem.label"
            :locationLabel="currentLocation.label"
            :canCancel="false"
            @confirm="createRecipe"
          />
        </div>
      </div>
      <div v-else class="loader"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import ComposeRecipe from '@/views/Composition/ComposeRecipe.vue';
import ItemMeasureForm from '@/components/Composition/Item/ItemMeasureForm.vue';
import ItemProductForm from '@/components/Composition/Item/ItemProductForm.vue';
import ItemCompositionForm from '@/components/Composition/Item/ItemCompositionForm.vue';
// import ItemLabelForm from '@/components/Composition/Item/ItemLabelForm.vue';
import AddLocationDialog from '@/components/Composition/AddLocationDialog.vue';
import itemsModule from '@/store/modules/items';
import { itemsTypes } from '@/store/mutation-types';
import { auth, errorToaster } from '@/mixins';

export default {
  mixins: [auth, errorToaster],
  components: { ItemMeasureForm, ItemProductForm, ItemCompositionForm, AddLocationDialog },
  props: ['itemUuid', 'parentItemUuid', 'parentItemUnit', 'products', 'menuItemRecipe'],
  data() {
    return {
      isModified: false,
      isCompositionEditable: false,
      isLabelEditable: false,
      unit: '',
      quantity: 0,
    };
  },
  watch: {
    itemUuid: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setCurrentItem(null);
          this.fetchOne(this.itemUuid)
            .then(() => {
              if (!this.currentItem.generic_uuid) {
                this.setCurrentItem(null);
                this.fetchGenericItem(this.itemUuid);
              } else {
                this.fetchGenericItem(this.currentItem.generic_uuid);
              }
            })
            .then(() => (this.isModified = false));
        }
      },
      immediate: true,
    },
    parentItemUuid: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (!newValue) {
            this.setCurrentParentItem(null);
          } else {
            this.fetchParentItem(this.parentItemUuid).then(() => {
              return this.fetchParentRecipe({
                quantity: this.parentItem.item_measure[0].quantity,
                unit: this.parentItem.item_measure[0].unit,
              });
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('itemComposition/items', {
      fetchOne: 'fetchOne',
      updateOne: 'updateOne',
      setProductItem: 'setProduct',
      unsetProductItem: 'unsetProduct',
      addLocation: 'addLocation',
      computeItemPrice: 'computeItemPrice',
    }),
    ...mapActions('itemComposition/genericItems', { fetchGenericItem: 'fetchOne' }),
    ...mapActions('itemComposition/parentItems', {
      fetchParentItem: 'fetchOne',
      updateChild: 'updateChild',
      fetchParentRecipe: 'fetchRecipe',
    }),
    ...mapMutations('itemComposition/items', { setCurrentItem: itemsTypes.SET_CURRENT }),
    ...mapMutations('itemComposition/parentItems', { setCurrentParentItem: itemsTypes.SET_CURRENT }),
    ...mapActions('products', {
      computeProductPrice: 'computeProductPrice',
    }),
    save() {
      this.updateOne().then(() => this.$emit('save'));
    },
    saveComposition() {
      this.isModified = false;
      this.isLabelEditable = false;
      this.isCompositionEditable = false;
      if (this.parentItem && this.parentItem.uuid) {
        var quantity = this.quantity ? this.quantity : this.itemQuantityInParent;
        var unit = this.unit ? this.unit : this.itemUnitInParent;
        this.computeItemPrice({
          uuid: this.item.uuid,
          quantity: quantity,
          unit: unit,
          locationUuids: this.getLocationUUIDs(),
          save: false,
        }).then(() => {
          if (
            this.currentItemPrice &&
            this.currentItemPrice.cost_details &&
            this.currentItemPrice.cost_details.kitchen_cost != null &&
            this.currentItemPrice.cost_details.supplier_cost != null
          ) {
            this.updateChild({
              quantity: quantity,
              unit: unit,
              itemUuid: this.item.uuid,
            }).then(() => this.$emit('save'));
          } else {
            this.quantity = this.itemQuantityInParent;
            this.unit = this.itemUnitInParent;
            this.$buefy.dialog.confirm({
              title: `Editing ${this.item.label}`,
              message: `You can not <b>edit</b> quantity: <br> ${this.getAlertMessage(this.item)}`,
              type: 'is-danger',
              hasIcon: true,
              cancelText: `Go to ${this.item.label}`,
              onCancel: () => this.goToItems(this.item),
            });
          }
        });
      }
    },
    getLocationUUIDs() {
      if (!this.currentLocation.parent_uuid) {
        return [this.currentLocation.uuid];
      }
      return [this.currentLocation.uuid, this.currentLocation.parent_uuid];
    },
    getAlertMessage(item) {
      if (this.currentItemPrice == null || this.currentItemPrice.cost_details == null) {
        return `Item Price is not defined for <b>'${item.unit}'</b>`;
      }
      if (this.currentItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price of <b>${item.quantity} ${item.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      if (this.currentItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price of <b>${item.quantity} ${item.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      return `<b>'${this.unit}'</b> is not in ${item.label}' s item measures`;
    },
    goToItems(item) {
      return this.toPage('/items/' + item.generic_uuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    setProduct(label, productUuid) {
      var quantity = this.quantity ? this.quantity : this.itemQuantityInParent;
      var unit = this.unit ? this.unit : this.itemUnitInParent;
      this.computeProductPrice({
        uuid: productUuid,
        quantity: quantity,
        unit: unit,
        location_uuids: this.currentLocation.location_tree,
        item_conversions: this.item.item_measure,
      }).then(() => {
        if (
          this.currentProductPrice &&
          this.currentProductPrice.kitchen_cost != null &&
          this.currentProductPrice.supplier_cost != null
        ) {
          this.setProductItem(productUuid).then(() => this.$emit('save'));
        } else {
          this.quantity = this.itemQuantityInParent;
          this.unit = this.itemUnitInParent;
          this.$buefy.dialog.confirm({
            title: `Adding ${label}`,
            message: `You can not <b>link</b> this product: <br> ${this.getProductAlertMessage(label, quantity, unit)}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: `Go to ${label}`,
            onCancel: () => this.goToProducts(productUuid),
          });
        }
      });
    },
    goToProducts(productUuid) {
      return this.toPage('/products/' + productUuid);
    },
    getProductAlertMessage(label, quantity, unit) {
      if (this.currentProductPrice && this.currentProductPrice.kitchen_price == null) {
        return `Kitchen Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      if (this.currentProductPrice && this.currentProductPrice.price == null) {
        return `Supplier Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      return `<b>Can not compute prices for <b>${quantity} ${unit}</b> of <b>${label}`;
    },
    unsetProduct(productUuid) {
      this.unsetProductItem(productUuid).then(() => this.$emit('save'));
    },
    addLocationToItem() {
      this.addLocation().then(() => this.$emit('save'));
    },
    createRecipe() {
      this.$buefy.modal.open({
        component: ComposeRecipe,
        parent: this,
        hasModalCard: true,
        props: { itemLocated: this.currentItem, genericItem: this.genericItem },
        events: {
          done: () => this.setLocalizedItem(),
        },
      });
    },
    setLocalizedItem() {
      this.fetchGenericItem(this.genericItem.uuid).then(() => {
        var itemLocalized = this.genericItem.locations.filter((location) => location.uuid == this.currentLocation.uuid);
        if (itemLocalized.length > 0) {
          if (this.parentItemUuid) {
            this.setCurrentItem(null);
            Promise.all([
              this.fetchParentRecipe({
                quantity: this.parentItem.item_measure[0].quantity,
                unit: this.parentItem.item_measure[0].unit,
              }),
              this.fetchOne(itemLocalized[0].item_uuid),
            ]).then(() => {
              this.$emit('save');
            });
          } else {
            this.setCurrentItem(null);
            this.fetchOne(itemLocalized[0].item_uuid).then(() => {
              this.$emit('save');
            });
          }
        }
      });
    },
    getLocation(item) {
      var locations = this.locationsUnnested.filter((location) => location.uuid == item.location_uuid);
      if (locations) {
        return locations[0];
      }
      return {};
    },
    isSpecificRecipe(item) {
      var locationItem = this.getLocation(item);
      if (locationItem.parent_uuid) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapState({
      currentItem: (state) => state.itemComposition.items.current,
      parentItem: (state) => state.itemComposition.parentItems.current,
      genericItem: (state) => state.itemComposition.genericItems.current,
      locationsUnnested: (state) => state.locations.allUnnested,
      locations: (state) => state.locations.all,
      recipe: (state) => state.itemComposition.parentItems.currentRecipe,
      fetchError: (state) =>
        state.itemComposition.items.computeItemPrice.error || state.products.computeProductPrice.error,
      isLoading: (state) =>
        state.itemComposition.items.fetchOne.pending ||
        state.itemComposition.items.computeItemPrice.pending ||
        state.itemComposition.parentItems.fetchOne.pending ||
        state.products.computeProductPrice.pending,
      currentLocation: (state) => state.locations.current,
      saveError: (state) => state.itemComposition.parentItems.updateChild.error,
      isSaveLoading: (state) => state.itemComposition.parentItems.updateChild.pending,
      currentItemPrice: (state) => state.itemComposition.items.itemPrices,
      currentProductPrice: (state) => state.products.productPrices,
    }),
    itemUnitInParent: {
      get() {
        if (this.parentItem && this.parentItem.uuid) {
          var itemFindInRecipe = this.recipe.find(({ item }) => item.uuid === this.item.uuid);
          if (itemFindInRecipe) {
            return itemFindInRecipe.unit;
          }
        } else if (this.menuItemRecipe) {
          var itemFindInMenuItemRecipe = this.menuItemRecipe.find(({ item }) => item.uuid === this.item.uuid);
          if (itemFindInMenuItemRecipe) {
            return itemFindInMenuItemRecipe.unit;
          }
        }
        return '';
      },
      set(value) {
        this.isModified = true;
        this.unit = value;
      },
    },
    itemQuantityInParent: {
      get() {
        if (this.parentItem && this.parentItem.uuid) {
          var itemFindInRecipe = this.recipe.find(({ item }) => item.uuid === this.item.uuid);
          if (itemFindInRecipe) {
            return itemFindInRecipe.quantity;
          }
        } else if (this.menuItemRecipe) {
          var itemFindInMenuItemRecipe = this.menuItemRecipe.find(({ item }) => item.uuid === this.item.uuid);
          if (itemFindInMenuItemRecipe) {
            return itemFindInMenuItemRecipe.quantity;
          }
        }
        return 0;
      },
      set(value) {
        this.isModified = true;
        this.quantity = value;
      },
    },
    parentItemMeasure() {
      return this.parentItem.item_measure.find((item_measure) => item_measure.unit === this.parentItemUnit);
    },
    item: {
      get() {
        return { ...this.currentItem };
      },
      set(value) {
        this.isModified = true;
        this.setCurrentItem(value);
      },
    },
    itemProduct() {
      if (!this.item.product_uuid) {
        return null;
      }

      const product = this.products.find((product) => product.uuid === this.item.product_uuid);

      if (!product || !product.uuid) {
        return null;
      }

      return product;
    },
  },
  beforeCreate() {
    if (!this.$store._modules.root.state.itemComposition) {
      this.$store.registerModule('itemComposition', { namespaced: true });
      this.$store.registerModule(['itemComposition', 'items'], itemsModule);
      this.$store.registerModule(['itemComposition', 'parentItems'], itemsModule);
      this.$store.registerModule(['itemComposition', 'genericItems'], itemsModule);
    }
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid #8080802e;
}
</style>
