import clientHook from '@/apis';
const PlatformsManagerClient = require('@tasterkitchens/platforms-manager-client-v2');

const menusApi = new PlatformsManagerClient.MenusApi();
menusApi.apiClient.basePath = process.env.VUE_APP_PLATFORMS_ENDPOINT;

export default {
  fetchAll(opts) {
    return clientHook(menusApi).appApisV1MenusListMenus(opts);
  },
  fetchOne(locationUuid, conceptUuid, locationUuids) {
    return clientHook(menusApi).appApisV1MenusGetMenuByLocation(locationUuid, conceptUuid, locationUuids);
  },
  updateOne(menuUuid, menu) {
    return clientHook(menusApi).appApisV1MenusUpdateMenu(menuUuid, menu);
  },
  addLocation(menuUuid, menuAddLocationRequest) {
    return clientHook(menusApi).appApisV1MenusAddLocationToMenu(menuUuid, menuAddLocationRequest);
  },
  removeLocation(menuUuid, menuRemoveLocationRequest) {
    return clientHook(menusApi).appApisV1MenusRemoveLocationFromMenu(menuUuid, menuRemoveLocationRequest);
  },
  createMenu(menuCreationRequest) {
    return clientHook(menusApi).appApisV1MenusCreateMenu(menuCreationRequest);
  },
  getMenuItems(locationUuid, conceptUuid, platformUuid) {
    return clientHook(menusApi).appApisV1MenusGetMenuItems(conceptUuid, locationUuid, platformUuid);
  },
  getMenuItemsConceptLocation(locationUuid, conceptUuid) {
    return clientHook(menusApi).appApisV1MenusGetMenuItemsLocationConcept(conceptUuid, locationUuid);
  },
  getMenuInformations(opts) {
    var requestOps = { unavailabilities: true };
    if (opts.conceptUuid) {
      requestOps.conceptUuid = opts.conceptUuid;
    }
    if (opts.countryUuid) {
      requestOps.countryUuid = opts.countryUuid;
    }
    if (opts.kitchenUuid) {
      requestOps.kitchenUuid = opts.kitchenUuid;
    }
    if (opts.locationUuid) {
      requestOps.locationUuid = opts.locationUuid;
    }
    if (opts.platformUuid) {
      requestOps.platformUuid = opts.platformUuid;
    }
    if (opts.restaurantUuid) {
      requestOps.restaurantUuid = opts.restaurantUuid;
    }
    if (opts.restaurantPlatformId) {
      requestOps.restaurantPlatformId = opts.restaurantPlatformId;
    }
    return clientHook(menusApi).appApisV1MenusGetMenuInformations(requestOps);
  },
};
