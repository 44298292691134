import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_PRODUCT_USAGE_ENDPOINT,
});

export default {
  run(opts) {
    return axios.post('/run_location_concept_extractor', {
      location_uuids: opts.locationUuids,
      concept_uuids: opts.conceptUuids,
    });
  },
};
