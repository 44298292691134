import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_HEALTHCHECKS_ENDPOINT,
});

export default {
  run(opts) {
    return axios.post('/run_healthchecks', { location_uuids: opts.locationUuids, concept_uuids: opts.conceptUuids });
  },
};
