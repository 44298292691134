import clientHook from '@/apis';
const ItemsManagerClient = require('@tasterkitchens/items-manager-client-v2');

const summaryApi = new ItemsManagerClient.SummaryApi();
summaryApi.apiClient.basePath = process.env.VUE_APP_ITEMS_ENDPOINT;

export default {
  fetchProductUsage(uuid) {
    return clientHook(summaryApi).appApisV1SummaryGetProductUsage(uuid, { recipes: true, menu_items: true });
  },
  fetchItemUsage(uuid) {
    return clientHook(summaryApi).appApisV1SummaryGetItemUsage(uuid, { recipes: true, menu_items: true });
  },
  fetchMenuItemUsage(uuid, locationUuids) {
    let opts = {
      combos: true,
      menuItems: true,
      modifierGroups: true,
      locationUuids: locationUuids,
    };
    return clientHook(summaryApi).appApisV1SummaryGetMenuItemUsage(uuid, opts);
  },
  fetchHealthcheckResults(locationUuid) {
    return clientHook(summaryApi).appApisV1SummaryGetHealthcheckResults(locationUuid);
  },
  fetchConceptProducts(opts) {
    let conceptProductRequest = {
      conceptUuid: opts.conceptUuid,
      countryUuid: opts.countryUuid,
      locationUuid: opts.locationUuid,
    };
    return clientHook(summaryApi).appApisV1SummaryGetConceptProductUuids(conceptProductRequest);
  },
  fetchPrices(opts) {
    return clientHook(summaryApi).appApisV1SummaryGetPrices(opts);
  },
};
