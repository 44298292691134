import clientHook from '@/apis';
import { trimPayload } from './utils';

const ProductsManagerClient = require('@tasterkitchens/products-manager-client');

const suppliersApi = new ProductsManagerClient.SuppliersApi();
suppliersApi.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  buildOne() {
    const days = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    };
    return {
      ...new ProductsManagerClient.SupplierCreationRequest(),
      open_days: { ...days },
      delivery_days: { ...days },
    };
  },
  buildOneProduct() {
    return { ...new ProductsManagerClient.ProductRequest(), yield_ratio: 1, conversions: [] };
  },
  fetchAll() {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersListSuppliers();
  },
  fetchOne(supplierUUID, { includeLocalizedInfos = false }) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersGetSupplierByUuid(supplierUUID, { includeLocalizedInfos });
  },
  createOne(supplier) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersCreateSupplier(supplier);
  },
  updateOne(supplierUUID, supplier) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersUpdateSupplierByUuid(supplierUUID, supplier);
  },
  removeOne(supplierUUID) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersDeleteSupplierByUuid(supplierUUID);
  },
  fetchOneLocalizedInformation(supplierUuid, locationUuid) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersGetSupplierLocalizedInformation(supplierUuid, locationUuid);
  },
  addLocalizedInformation(supplierUuid, payload) {
    let data = trimPayload(payload);
    if ('contact_email' in payload && payload.contact_email !== null && payload.contact_email.length === 0) {
      data.contact_email = null;
    }
    return clientHook(suppliersApi).appLegacyApisV2SuppliersAddSupplierLocalizedInformation(supplierUuid, data);
  },
  updateLocalizedInformation(supplierUuid, locationUuid, payload) {
    let data = trimPayload(payload);
    if ('contact_email' in payload && payload.contact_email !== null && payload.contact_email.length === 0) {
      data.contact_email = null;
    }
    return clientHook(suppliersApi).appLegacyApisV2SuppliersUpdateSupplierLocalizedInformation(
      supplierUuid,
      locationUuid,
      data
    );
  },
  removeLocalizedInformation(supplierUuid, locationUuid) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersRemoveSupplierLocalizedInformation(
      supplierUuid,
      locationUuid
    );
  },
  addShippingPolicy(
    supplierUuid,
    {
      location_uuid,
      actions,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
    }
  ) {
    if (!location_uuid) {
      throw new TypeError('You should always provide a location UUID to add a new shipping policy');
    }

    return clientHook(suppliersApi).appLegacyApisV2SuppliersAddSupplierShippingPolicy(supplierUuid, {
      location_uuid,
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    });
  },
  updateShippingPolicy(
    supplierUuid,
    policyUuid,
    {
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    }
  ) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersUpdateSupplierShippingPolicy(supplierUuid, policyUuid, {
      actions,
      threshold,
      threshold_currency_code,
      threshold_type,
      threshold_unit,
      delivery_charge,
      delivery_charge_currency_code,
      delivery_charge_kind,
      delivery_charge_unit,
    });
  },
  removeShippingPolicy(supplierUuid, policyUuid) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersRemoveSupplierShippingPolicy(supplierUuid, policyUuid);
  },
  createProduct(suuplierUUID, product) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsCreateSupplierProduct(suuplierUUID, product);
  },
  listProducts(supplierUUID) {
    return clientHook(suppliersApi).appLegacyApisV2SuppliersListSupplierProducts(supplierUUID);
  },
  getProduct(supplierUUID, productUUID) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsGetSupplierProductByUuid(supplierUUID, productUUID);
  },
  updateProduct(supplierUUID, productUUID, product) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsUpdateSupplierProductByUuid(
      supplierUUID,
      productUUID,
      product
    );
  },
  removeProduct(supplierUUID, productUUID) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsDeleteSupplierProductByUuid(supplierUUID, productUUID);
  },
  createProductLocalizedInformation(supplierUuid, productUuid, productLocationInformationCreationRequest) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsCreateProductLocalizedInformation(
      supplierUuid,
      productUuid,
      productLocationInformationCreationRequest
    );
  },
  updateProductLocalizedInformation(
    supplierUuid,
    productUuid,
    locationUuid,
    productLocationInformationCreationRequest
  ) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsUpdateProductLocalizedInformation(
      supplierUuid,
      productUuid,
      locationUuid,
      productLocationInformationCreationRequest
    );
  },
  removeProductLocalizedInformation(supplierUuid, productUuid, locationUuid) {
    return clientHook(suppliersApi).appLegacyApisV2ProductsRemoveProductLocalizedInformation(
      supplierUuid,
      productUuid,
      locationUuid
    );
  },
};
