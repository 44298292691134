import Vue from 'vue';
import FlagIcon from 'vue-flag-icon';
import App from './App.vue';
import auth from './auth';
import router from './router';
import store from './store';
import { locationsTypes } from './store/mutation-types.js';
import i18n from './i18n';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import errorReporting from '@/errorReporting';
import './validation';

Vue.use(auth);
Vue.use(Buefy);
Vue.use(FlagIcon);
Vue.use(errorReporting);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    const currentLocation = localStorage.getItem('currentLocation');

    if (currentLocation) {
      this.$store.commit(`locations/${locationsTypes.SET_CURRENT_LOCATION}`, JSON.parse(currentLocation));
    }
  },
  render: (h) => h(App),
}).$mount('#app');
