<template>
  <section>
    <header>
      <label class="checkbox is-taster-primary">
        <input v-model="selectAll" type="checkbox" @change="onSelectAllLocations" />
        <template v-if="selectAll"> Unselect all </template>
        <template v-else> Select all </template>
      </label>
    </header>
    <div class="locations">
      <template v-for="location in locations">
        <label :key="location.uuid" class="checkbox is-taster-primary">
          <input
            type="checkbox"
            :checked="isLocationSelected(location.uuid)"
            :label="location.label"
            :value="location.uuid"
            :disabled="isDisabled(location.uuid)"
            @change="onLocationSelected"
          />
          {{ location.label }}
        </label>
      </template>
    </div>
    <footer>
      <p :class="{ 'has-selection': selectedCount > 0 }">{{ selectedCount }} selected</p>
      <b-button type="is-taster-primary" @click="onValidateSelection">Confirm</b-button>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'LocationFilter',
  model: {
    prop: 'selectedLocations',
    event: 'location-filter:confirm',
  },
  props: {
    locationRoot: {
      type: Object,
      required: true,
      validator(value) {
        return 'locations' in value;
      },
    },
    selectedLocations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectAll: this.locationRoot.locations.length === this.selectedLocations.length,
      inner: [...this.selectedLocations],
    };
  },
  computed: {
    locations() {
      return [...this.locationRoot.locations].sort((left, right) => {
        const leftLabel = left.label.toUpperCase();
        const rightLabel = right.label.toUpperCase();

        if (leftLabel < rightLabel) {
          return -1;
        } else if (leftLabel > rightLabel) {
          return 1;
        }

        return 0;
      });
    },
    locationsLength() {
      return this.locationRoot.locations.filter(({ uuid }) => !this.isDisabled(uuid)).length;
    },
    selectedCount() {
      return this.inner.length;
    },
  },
  watch: {
    selectedLocations(newSelectedLocations) {
      this.inner = [...newSelectedLocations];
    },
  },
  methods: {
    isDisabled(locationUuid) {
      const isCheckboxDisabled = this.locationRoot.locations.some(
        ({ uuid, disabled }) => uuid === locationUuid && disabled
      );
      if (this.selectedLocations.some(({ uuid }) => uuid === locationUuid) && isCheckboxDisabled) {
        return false;
      }
      return isCheckboxDisabled;
    },
    isLocationSelected(uuid) {
      return this.inner.some((location) => location.uuid === uuid);
    },
    onLocationSelected(event) {
      const label = event.target.attributes.getNamedItem('label');
      if (!label) {
        return;
      }

      const nextSelection = event.target.checked
        ? [...this.inner, { uuid: event.target.value, label: label.value }]
        : this.inner.filter((location) => location.uuid !== event.target.value);

      this.selectAll = nextSelection.length === this.locationsLength;
      this.inner = nextSelection;
    },
    onSelectAllLocations() {
      this.inner = this.selectAll
        ? this.locations.reduce((locationsNotDisabled, { uuid, label }) => {
            if (!this.isDisabled(uuid)) {
              locationsNotDisabled.push({ uuid, label });
            }
            return locationsNotDisabled;
          }, [])
        : [];
    },
    onValidateSelection() {
      this.$emit('location-filter:confirm', this.inner);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

.checkbox {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  line-height: 16px;

  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
}

header {
  border-bottom: 0.5px solid $taster-gray;
  padding-bottom: 8px;
}

.locations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 16px;
  gap: 16px;
  padding: 12px 0px;
  max-height: 220px;
  overflow: auto;
}

footer {
  align-items: center;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;

  p {
    color: $taster-gray;
    font-size: 16px;
    line-height: 24px;

    &.has-selection {
      color: $taster-primary;
    }
  }
}
</style>
