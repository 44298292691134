import { gatewayMenusTypes } from '@/store/mutation-types.js';
import gatewayPlatforms from '@/apis/gatewayPlatforms/gatewayPlatforms.js';

export default {
  namespaced: true,
  state: {
    menus: [],
    menuItemsLibrary: [],
    menuItemVariants: [],
    fetchMenus: {
      pending: false,
      error: null,
    },
    fetchMenuItemsLibrary: {
      pending: false,
      error: null,
    },
    fetchMenuItemVariants: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [gatewayMenusTypes.FETCH_GATEWAY_MENUS_REQUEST](state) {
      state.fetchMenus.pending = true;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENUS_SUCCESS](state, menus) {
      state.fetchMenus.pending = false;
      state.menus = menus;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENUS_ERROR](state, err) {
      state.fetchMenus.pending = false;
      state.fetchMenus.error = err;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_REQUEST](state) {
      state.fetchMenuItemsLibrary.pending = true;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_SUCCESS](state, menuItemsLibrary) {
      state.fetchMenuItemsLibrary.pending = false;
      state.menuItemsLibrary = menuItemsLibrary;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_ERROR](state, err) {
      state.fetchMenuItemsLibrary.pending = false;
      state.fetchMenuItemsLibrary.error = err;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_REQUEST](state) {
      state.fetchMenuItemVariants.pending = true;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_SUCCESS](state, menuItemVariants) {
      state.fetchMenuItemVariants.pending = false;
      state.menuItemVariants = menuItemVariants;
    },
    [gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_ERROR](state, err) {
      state.fetchMenuItemVariants.pending = false;
      state.fetchMenuItemVariants.error = err;
    },
  },
  actions: {
    async getMenus({ commit }, queryParams) {
      try {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENUS_REQUEST);
        const response = await gatewayPlatforms.fetchMenus(queryParams);
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENUS_SUCCESS, response);
        return response;
      } catch (err) {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENUS_ERROR, err);
        throw err;
      }
    },
    async getMenuItemsLibrary({ commit }, queryParams) {
      try {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_REQUEST);
        const response = await gatewayPlatforms.fetchMenuItemsLibrary(queryParams);
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_SUCCESS, response);
        return response;
      } catch (err) {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_LIBRARY_ERROR, err);
        throw err;
      }
    },
    async getMenuItemVariants({ commit }, queryParams) {
      try {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_REQUEST);
        const response = await gatewayPlatforms.fetchMenuItemVariants(queryParams);
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_SUCCESS, response);
        return response;
      } catch (err) {
        commit(gatewayMenusTypes.FETCH_GATEWAY_MENU_ITEM_VARIANTS_ERROR, err);
        throw err;
      }
    },
  },
};
