<template>
  <div class="box modal-card">
    <h3 class="subtitle">Add combo</h3>
    <div class="is-divider" :data-content="$t('add.choose')"></div>
    <b-field grouped>
      <b-autocomplete
        expanded
        rounded
        v-model="searchTerm"
        placeholder="Search for an existing combo..."
        icon="magnify"
        field="label"
        :data="filteredData"
        @select="({ uuid }) => $emit('close', uuid)"
      >
        <template slot="empty">No combos found.</template>
      </b-autocomplete>
    </b-field>
    <div class="is-divider" :data-content="$t('add.create_one')"></div>
    <b-field
      :label="$t('menu-item.label')"
      label-position="on-border"
      :message="saveError && saveError.body.detail"
      :type="saveError ? 'is-danger' : ''"
    >
      <b-input placeholder="Chicken combo" v-model="label"></b-input>
    </b-field>
    <b-button class="button is-primary" :loading="isSaveLoading" @click="createCombo">Create</b-button>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

import { errorToaster } from '@/mixins';

export default {
  mixins: [errorToaster],
  data: () => ({
    searchTerm: '',
  }),
  watch: {},
  methods: {
    ...mapActions('combos', ['buildOne', 'fetchAll', 'createOne']),
    ...mapMutations('combos', { setCurrent: 'SET_CURRENT' }),
    createCombo: function () {
      this.createOne().then(() => {
        if (!this.saveError) {
          this.$emit('close', this.combo.uuid);
        }
      });
    },
  },
  computed: {
    ...mapState({
      combo: (state) => state.combos.current,
      combos: (state) => state.combos.all,
      currentConcept: (state) => state.concepts.current,
      isFetchLoading: (state) => state.combos.fetchAll.pending,
      isSaveLoading: (state) => state.combos.createOne.pending,
      saveError: (state) => state.combos.createOne.error,
    }),
    label: {
      get() {
        return this.combo.label;
      },
      set(value) {
        if (!this.saveError) {
          this.setCurrent({ label: value });
        }
      },
    },
    filteredData() {
      return this.combos.filter(
        (option) =>
          option.label.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          !option.concept_uuids.includes(this.currentConcept.uuid)
      );
    },
  },
  created() {
    this.buildOne();
    if (this.$store.state.combos.all === null || this.$store.state.combos.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>
