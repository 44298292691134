<template>
  <section class="section">
    <div style="float: right">
      <b-dropdown aria-role="list" position="is-bottom-left">
        <button class="button is-info" slot="trigger">
          <span>Actions</span>
          <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem" @click="openErrorDetailsExplanation">
          <div class="media">
            <b-icon class="media-left" icon="book"></b-icon>
            <div class="media-content">
              <h3>Issues Guide</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="openRefreshHealthchecksModal">
          <div class="media">
            <b-icon class="media-left" icon="refresh"></b-icon>
            <div class="media-content">
              <h3>Refresh Healthchecks</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-loading :active.sync="isFetchLoading"></b-loading>
    <div class="container">
      <HealthchecksFilter
        :concepts="concepts"
        :conceptsSelected="conceptsSelected"
        :locations="locations"
        :locationsSelected="locationsSelected"
        :errorTypes="errorTypes"
        :errorTypesSelected="errorTypesSelected"
        :perimeters="perimeters"
        :perimetersSelected="perimetersSelected"
        :itemsTypes="itemsTypes"
        :itemsTypesSelected="itemsTypesSelected"
      />
      <br />
      <b-table :data="selected_results" :paginated="true" :per-page="10" :bordered="true" default-sort="label">
        <template slot-scope="props">
          <b-table-column field="label" label="Label" width="40" sortable>{{ props.row.label }}</b-table-column>
          <b-table-column field="type" label="Item Type" width="40" sortable>
            <p v-if="props.row.type == 'MENU_ITEM'">Menu Item</p>
            <p v-if="props.row.type == 'ITEM'">Item</p>
            <p v-if="props.row.type == 'PRODUCT'">Product</p>
          </b-table-column>
          <b-table-column field="issues" label="Issues" width="40">
            <b-tag
              type="is-danger"
              v-for="(errorType, index) in getIssues(props.row)"
              :key="index"
              :value="errorType"
              >{{ errorType }}</b-tag
            >
          </b-table-column>
          <b-table-column field="location" label="Location" width="40">
            <b-tag type="is-link" :value="getLocation(props.row).id">{{ getLocation(props.row).id }}</b-tag>
          </b-table-column>
          <b-table-column field="detail" label="Details" width="40">
            <b-button
              type="is-small is-warning"
              @click="openErrorDetails(props.row)"
              v-if="
                Object.keys(props.row.backoffice_issues).length > 0 || Object.keys(props.row.customer_issues).length > 0
              "
              >Detail</b-button
            >
          </b-table-column>
          <b-table-column field="links" label="Links" width="40">
            <b-button type="is-small is-light" @click="goToCombo(props.row)" v-if="props.row.type == 'COMBO'"
              >Platform Information</b-button
            >
            <b-button type="is-small is-light" @click="goToMenuItem(props.row)" v-if="props.row.type == 'MENU_ITEM'"
              >Platform Information</b-button
            >
            <b-button type="is-small is-dark" @click="goToComposition(props.row)" v-if="props.row.type == 'MENU_ITEM'"
              >Recipe</b-button
            >
            <b-button type="is-small is-dark" @click="goToItems(props.row)" v-if="props.row.type == 'ITEM'"
              >Recipe</b-button
            >
            <b-button
              type="is-small is-dark"
              outlined
              @click="goToProducts(props.row)"
              v-if="props.row.type == 'PRODUCT'"
              >Product</b-button
            >
          </b-table-column>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { auth } from '@/mixins';

import HealthchecksFilter from '@/components/Healthchecks/HealthchecksFilter.vue';
import HealthchecksRefresh from '@/components/Healthchecks/HealthchecksRefresh.vue';

export default {
  mixins: [auth],
  components: { HealthchecksFilter },
  data() {
    return {
      concepts: [],
      conceptsSelected: [],
      locations: [],
      locationsSelected: [],
      backofficeErrors: [],
      backofficeErrorsSelected: [],
      customerErrors: [],
      customerErrorsSelected: [],
      errorTypesSelected: [],
      perimeters_mapper: {
        Backoffice: 'backoffice_issues',
        Customer: 'customer_issues',
      },
      perimeters: ['Backoffice', 'Customer'],
      perimetersSelected: ['Backoffice', 'Customer'],
      itemsTypes: [],
      itemsTypesSelected: [],
    };
  },
  watch: {
    currentLocation() {
      this.fetchHealthcheckResultsInCurrentLocation();
    },
    perimetersSelected() {
      this.errorTypesSelected = [...this.errorTypes];
    },
  },
  computed: {
    ...mapState({
      allConcepts: (state) => state.concepts.all,
      currentLocation: (state) => state.locations.current,
      healtchecks_results: (state) => state.summary.healtchecks_results,
      isFetchLoading: (state) => state.summary.fetchHealthchecksResults.pending,
      locationsUnnested: (state) => state.locations.allUnnested,
      selected_results() {
        return this.healtchecks_results
          .filter((result) => this.itemsTypesSelected.includes(result.type))
          .filter((result) => this.conceptsSelected.filter((concept) => result.concept_uuid == concept.uuid).length > 0)
          .filter(
            (result) => this.locationsSelected.filter((location) => result.location_uuid == location.uuid).length > 0
          )
          .filter(
            (result) =>
              this.perimetersSelected
                .map(
                  (perimeter) =>
                    result[this.perimeters_mapper[perimeter]] && Object.keys(result[this.perimeters_mapper[perimeter]])
                )
                .flat().length > 0
          )
          .filter(
            (result) =>
              this.errorTypesSelected.filter(
                (errorType) =>
                  Object.keys(result.backoffice_issues || {}).includes(errorType) ||
                  Object.keys(result.customer_issues || {}).includes(errorType)
              ).length > 0
          );
      },
      errorTypes() {
        var errorTypes = [];
        if (this.perimetersSelected.includes('Backoffice')) {
          errorTypes = [...errorTypes, ...this.backofficeErrors];
        }
        if (this.perimetersSelected.includes('Customer')) {
          errorTypes = [...errorTypes, ...this.customerErrors];
        }
        return errorTypes;
      },
    }),
  },
  methods: {
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('locations', { fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('summary', ['fetchHealthcheckResults']),
    fetchHealthcheckResultsInCurrentLocation() {
      this.fetchHealthcheckResults().then(() => {
        let backofficeErrorsTemp = [];
        let customerErrorsTemp = [];
        let conceptUuidsTemp = [];
        let locationUuidsTemp = [];
        let itemsTypesTemp = [];
        this.healtchecks_results.forEach(function (result) {
          backofficeErrorsTemp = [...backofficeErrorsTemp, ...Object.keys(result.backoffice_issues || {})];
          customerErrorsTemp = [...customerErrorsTemp, ...Object.keys(result.customer_issues || {})];
          conceptUuidsTemp.push(result.concept_uuid);
          locationUuidsTemp.push(result.location_uuid);
          itemsTypesTemp.push(result.type);
        });
        var conceptsTemp = this.allConcepts.filter((concept) => conceptUuidsTemp.includes(concept.uuid));
        this.concepts = [...conceptsTemp];
        this.conceptsSelected = [...conceptsTemp];
        var locationsTemp = this.locationsUnnested.filter((location) => locationUuidsTemp.includes(location.uuid));
        this.locations = [...locationsTemp];
        this.locationsSelected = [...locationsTemp];
        this.backofficeErrors = Array.from(new Set([...backofficeErrorsTemp]));
        this.backofficeErrorsSelected = Array.from(new Set([...backofficeErrorsTemp]));
        this.customerErrors = Array.from(new Set([...customerErrorsTemp]));
        this.customerErrorsSelected = Array.from(new Set([...customerErrorsTemp]));
        this.errorTypesSelected = [...this.backofficeErrorsSelected, ...this.customerErrorsSelected];
        this.itemsTypes = Array.from(new Set([...itemsTypesTemp]));
        this.itemsTypesSelected = Array.from(new Set([...itemsTypesTemp]));
      });
    },
    getLocation(row) {
      var locations = this.locationsUnnested.filter((location) => location.uuid == row.location_uuid);
      if (locations) {
        return locations[0];
      }
      return '';
    },
    getIssues(row) {
      var issues = [...Object.keys(row.backoffice_issues || {}), ...Object.keys(row.customer_issues || {})];
      return issues.sort((issue1, issue2) => issue1.localeCompare(issue2));
    },
    openErrorDetails(row) {
      let error_details = '';
      if (row.backoffice_issues && Object.keys(row.backoffice_issues).length > 0) {
        for (var backoffice_issue in row.backoffice_issues) {
          error_details += `<b> ${backoffice_issue} </b> <br />`;
          row.backoffice_issues[backoffice_issue].forEach(function (error) {
            error_details += `  ${error} <br />`;
          });
        }
      }
      if (row.customer_issues && Object.keys(row.customer_issues).length > 0) {
        for (var customer_issue in row.customer_issues) {
          error_details += `<b> ${customer_issue} </b> <br />`;
          row.customer_issues[customer_issue].forEach(function (error) {
            error_details += `  ${error} <br />`;
          });
        }
      }
      this.$buefy.dialog.alert({
        title: 'Detail',
        message: error_details,
        type: 'is-warning',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
    openErrorDetailsExplanation() {
      let error_details = '';
      error_details += `<b> Category </b> <br />`;
      error_details += ` Menu Item/Combo/Product does not have Category <br /> <br />`;

      error_details += `<b> Concept </b> <br />`;
      error_details += ` Menu Item/Combo is on Concept's Menu but not among Concept's Menu Items/Combos <br /> <br />`;

      error_details += `<b> Item Measure </b> <br />`;
      error_details += ` Item is used inside a recipe in a quantity not defined in its Item Measures <br /> <br />`;

      error_details += `<b> Item Not Linked </b> <br />`;
      error_details += ` Item has no recipe or product <br /> <br />`;

      error_details += `<b> Item Not Existing in Location </b> <br />`;
      error_details += ` Item Not Existing in location <br /> <br />`;

      error_details += `<b> Kitchen Price </b> <br />`;
      error_details += ` Menu Item/Item/Product's Kitchen Price is not defined or can not be computed <br />  <br />`;

      error_details += `<b> Picture </b> <br />`;
      error_details += ` Menu Item/Combo does not have a picture on a given Platform <br /> <br />`;

      error_details += `<b> Product Linked Not Existing </b> <br />`;
      error_details += ` Item's linked product does not exist anymore <br /> <br />`;

      error_details += `<b> Recipe </b> <br />`;
      error_details += ` Menu Item does not have recipe <br /> <br />`;

      error_details += `<b> Recipe Items </b> <br />`;
      error_details += ` Items in Menu Item's Recipe have issues <br /> <br />`;

      error_details += `<b> Selling Price </b> <br />`;
      error_details += ` Menu Item/Combo Selling price is <= 0  on a given Platform <br /> <br />`;

      error_details += `<b> Supplier Price </b> <br />`;
      error_details += ` Product does not have Supplier or Supplier Price < 0 <br /> <br />`;

      error_details += `<b> Yield Ratio </b> <br />`;
      error_details += ` Product's Yield ratio <= 0 or > 1 <br /> <br />`;

      this.$buefy.dialog.alert({
        title: 'Issues Guide',
        message: error_details,
        type: 'is-warning',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
    toPage(row, suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${row.location_uuid}`);
      window.open(routeData.href, '_blank');
    },
    goToItems(row) {
      if (this.hasAccessToRecipe()) {
        return this.toPage(row, '/items/' + row.uuid);
      }
      return '';
    },
    goToComposition(row) {
      if (this.hasAccessToRecipe()) {
        return this.toPage(row, '/composition/' + row.uuid);
      }
      return '';
    },
    goToMenuItem(row) {
      if (this.hasAccessToConcept()) {
        return this.toPage(row, '/menu-items/' + row.uuid + '/edit');
      }
      return '';
    },
    goToCombo(row) {
      if (this.hasAccessToConcept()) {
        return this.toPage(row, '/combos/' + row.uuid + '/edit');
      }
      return '';
    },
    goToProducts(row) {
      if (this.hasAccessToConcept()) {
        return this.toPage(row, '/products/' + row.uuid);
      }
      return '';
    },
    openRefreshHealthchecksModal() {
      this.$buefy.modal.open({
        component: HealthchecksRefresh,
        parent: this,
        hasModalCard: true,
        props: {
          currentLocation: this.currentLocation,
          locationsUnnested: this.locationsUnnested,
          concepts: this.allConcepts,
        },
      });
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getConcepts() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.concepts.all)) {
        return this.fetchConcepts();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getUnnestedLocations() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.locations.allUnnested)) {
        return this.fetchUnnestedLocations();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
  },
  mounted() {
    Promise.all([this.getConcepts(), this.getUnnestedLocations()]).then(() =>
      this.fetchHealthcheckResultsInCurrentLocation()
    );
  },
};
</script>

<style scoped lang="scss">
.concept {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__label {
    margin-left: 12px;
  }
}
.clickable {
  &:hover {
    cursor: pointer;
  }
}
</style>
