<template>
  <div>
    <b-field
      v-if="editable && this.newTitle.length >= 255"
      message="Maximum Category Length is 255 characters"
      type="is-danger"
    >
    </b-field>
    <div
      v-if="!editable"
      @click="editable = true"
      @mouseover="showEditable = true"
      @mouseleave="showEditable = false"
      class="empty-title"
    >
      {{ title }}
      <b-icon size="is-small" type="is-grey" icon="pencil" v-if="showEditable && isItemMenuEditor()" />
    </div>
    <div v-else>
      <b-input class="no-style" @keydown.native="handleChange" v-model="computedTitle" />
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  props: ['title'],
  mixins: [auth],
  data() {
    return {
      editable: false,
      showEditable: false,
      newTitle: this.title,
    };
  },
  methods: {
    handleChange({ keyCode }) {
      if (keyCode === 13 && this.newTitle.length && this.newTitle.length < 255) {
        this.$emit('update:title', this.newTitle);
        this.editable = false;
      }
    },
  },
  watch: {
    title() {
      this.newTitle = this.title;
    },
  },
  computed: {
    computedTitle: {
      get() {
        return this.newTitle;
      },
      set(value) {
        this.newTitle = value;
      },
    },
  },
  mounted() {
    this.$el.addEventListener('click', (e) => e.stopPropagation());
    document.body.addEventListener('click', () => {
      this.editable = false;
      this.showEditable = false;
      this.newTitle = this.title;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

/deep/ .no-style > .input {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid lightgray;
  background: transparent;
  transition: border-color 0.2s;
}
/deep/ .no-style > .input:focus {
  border-bottom: 1px solid $dark;
  border-width: 1px;
}

.empty-title {
  min-height: 20px;
  min-width: 20px;
}
</style>
