<template>
  <b-autocomplete
    expanded
    rounded
    v-model="searchTerm"
    :data="filteredItems"
    placeholder="Search for an existing item..."
    icon="magnify"
    field="label"
    @select="(option) => $emit('select', option)"
  >
    <template slot="empty">No results found</template>
  </b-autocomplete>
</template>

<script>
export default {
  props: ['items'],
  data: () => ({
    searchTerm: '',
  }),
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
};
</script>
