<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title" v-if="all">Recipes</h1>
          <h1 class="title" v-else>Recipes</h1>
          <b-loading :active.sync="isLoading"></b-loading>
        </div>
        <div class="column is-narrow">
          <b-button
            v-if="isItemRecipeCreator() && isParentLocation() && tabIndex == 0"
            type="is-link"
            @click="openCreateRecipeDialog"
            rounded
            >New Recipe</b-button
          >
        </div>
      </div>
      <b-tabs size="medium" type="is-toggle" expanded v-if="items && menuItems && concepts" v-model="tabIndex">
        <b-tab-item>
          <template slot="header">Items</template>
          <RecipeTable :all="items" :concepts="null" itemType="item" :menus="null" :menuItemsLibrary="null" />
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Menu Items</template>
          <div class="block" style="float: left" v-if="conceptsSorted">
            <b-radio
              size="is-small"
              v-model="selectedConcept"
              v-for="concept in conceptsSorted"
              :native-value="concept"
              :key="concept.uuid"
            >
              <img :src="concept.logo" width="30" />
            </b-radio>
            <hr />
            <RecipeTable
              :all="menuItems"
              :concepts="conceptsSorted"
              itemType="menuItem"
              :menus="menusSorted"
              :menuItemsLibrary="menuItemsLibrary"
            />
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { auth, errorToasterDetailled } from '@/mixins';
import { conceptsTypes } from '@/store/mutation-types';

import CreateRecipe from '@/components/Recipes/CreateRecipe.vue';
import RecipeTable from '@/components/Recipes/RecipeTable.vue';

export default {
  mixins: [auth, errorToasterDetailled],
  components: {
    RecipeTable,
  },
  watch: {
    tabIndex(newVal, oldVal) {
      if (newVal != oldVal && newVal == 1) {
        Promise.all([this.getConcepts()]).then(() => {
          this.selectedConcept = this.conceptsSorted[0];
          this.setCurrentConcept(this.conceptsSorted[0]);
          Promise.all([
            this.fetchAllMenuItems({ summary: true, conceptUuids: [this.currentConcept.uuid] }),
            this.getMenus(
              { conceptUuid: this.currentConcept.uuid },
              this.getMenuItemsLibrary({ concept_uuid: this.currentConcept.uuid })
            ),
          ]);
        });
      }
    },
    selectedConcept(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.setCurrentConcept(newVal);
        Promise.all([
          this.fetchAllMenuItems({ summary: true, conceptUuids: [this.currentConcept.uuid] }),
          this.getMenus(
            { conceptUuid: this.currentConcept.uuid },
            this.getMenuItemsLibrary({ concept_uuid: this.currentConcept.uuid })
          ),
        ]);
      }
    },
  },
  data: () => ({
    all: [],
    tabIndex: 0,
    selectedConcept: null,
  }),
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      currentConcept: (state) => state.concepts.current,
      currentLocation: (state) => state.locations.current,
      isLoading: (state) =>
        state.items.fetchAll.pending ||
        state.concepts.fetchAll.pending ||
        state.menuItems.fetchAll.pending ||
        state.gatewayMenus.fetchMenus.pending ||
        state.gatewayMenus.fetchMenuItemsLibrary.pending,
      items: (state) => state.items.all,
      menuItems: (state) => state.menuItems.all,
      conceptsSorted() {
        if (!this.concepts) {
          return [];
        }
        return this.concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
      },
      menus: (state) => state.gatewayMenus.menus,
      menusSorted() {
        if (!this.menus) {
          return [];
        }
        return this.menus.sort((menu1, menu2) => menu1.label.localeCompare(menu2.label));
      },
      menuItemsLibrary: (state) => state.gatewayMenus.menuItemsLibrary,
    }),
  },
  methods: {
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('gatewayMenus', ['getMenus', 'getMenuItemsLibrary']),
    ...mapActions('items', {
      fetchAllItems: 'fetchAll',
    }),
    ...mapActions('menus', {
      fetchMenuItemsUsedInConceptLocation: 'fetchMenuItemsUsedInConceptLocation',
    }),
    ...mapActions('menuItems', { fetchAllMenuItems: 'fetchAll' }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    openCreateRecipeDialog() {
      this.$buefy.modal.open({
        component: CreateRecipe,
        parent: this,
        hasModalCard: true,
        events: {
          done: (recipeUUID) => this.$router.push(`/items/${recipeUUID}`),
        },
      });
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getConcepts() {
      // to benefit from then()
      if (this.$store.state.concepts.all === null || this.$store.state.concepts.all.length == 0) {
        return this.fetchConcepts();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getItems() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.items.all)) {
        return this.fetchAllItems();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getMenuItems() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.menuItems.all)) {
        return this.fetchAllMenuItems({ summary: true });
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getMenusWithCache() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.gatewayMenus.menus)) {
        return this.getMenus();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getMenuItemsLibraryWithCache() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.gatewayMenus.menuItemsLibrary)) {
        return this.getMenuItemsLibrary();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
  },
  async mounted() {
    Promise.all([this.getItems()]);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}
</style>
