<template>
  <div class="box modal-card" style="overflow: visible">
    <h3 class="subtitle">Edit item</h3>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching this item. </b-message>
    <div v-if="!fetchError">
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <ItemForm
        :item.sync="item"
        @save="update"
        @close="close"
        buttonMessage="Update"
        :displayProducts="item.generic_uuid"
        :products="products"
        v-if="item.uuid"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import ItemForm from '@/components/ItemForm.vue';

export default {
  components: { ItemForm },
  props: ['id', 'usage'],
  methods: {
    ...mapActions('products', { fetchProducts: 'fetchAllSummary' }),
    ...mapActions('items', ['fetchOne', 'updateOne']),
    ...mapActions('products', {
      computeProductPrice: 'computeProductPrice',
    }),
    ...mapMutations('items', { setCurrent: 'SET_CURRENT' }),
    update() {
      var unitsFromUsage = this.getUnitsFromUsage();
      var unitsFromMeasure = this.getUnitsFroItemMeasure();
      if (unitsFromUsage.every((val) => unitsFromMeasure.includes(val))) {
        if (this.current.product_uuid) {
          var quantity = this.current.item_measure[0].quantity;
          var unit = this.current.item_measure[0].unit;
          this.computeProductPrice({
            uuid: this.current.product_uuid,
            quantity: quantity,
            unit: unit,
            location_uuids: this.currentLocation.location_tree,
            item_conversions: this.current.item_measure,
          }).then(() => {
            if (
              this.currentProductPrice &&
              this.currentProductPrice.kitchen_cost != null &&
              this.currentProductPrice.supplier_cost != null
            ) {
              this.updateOne().then(() => this.$emit('close'));
            } else {
              this.$buefy.dialog.confirm({
                title: `Can not save Item`,
                message: `ERROR : ${this.getProductAlertMessage('product', quantity, unit)}`,
                type: 'is-danger',
                hasIcon: true,
                cancelText: `Go to product`,
                onCancel: () => this.goToProducts(this.current.product_uuid),
              });
            }
          });
        } else {
          this.updateOne().then(() => this.$emit('close'));
        }
      } else {
        this.$buefy.dialog.alert({
          title: `Error`,
          message: `Some Units used in 'Usages' are not available in Item Measures`,
          type: 'is-danger',
          hasIcon: true,
          confirmText: `OK`,
        });
      }
    },
    getUnitsFromUsage() {
      var units = new Set();
      var allUsages = Object.values(this.usage);
      for (let usages of allUsages) {
        for (let usage of usages) {
          units.add(usage.unit);
        }
      }
      return Array.from(units);
    },
    getUnitsFroItemMeasure() {
      var units = new Set();
      for (let itemMeasure of this.current.item_measure) {
        units.add(itemMeasure.unit);
      }
      return Array.from(units);
    },
    goToProducts(productUuid) {
      return this.toPage('/products/' + productUuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    getProductAlertMessage(label, quantity, unit) {
      if (this.currentProductPrice && this.currentProductPrice.kitchen_cost == null) {
        return `Kitchen Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      if (this.currentProductPrice && this.currentProductPrice.supplier_cost == null) {
        return `Supplier Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      return `<b>Can not compute prices for <b>${quantity} ${unit}</b> of <b>${label}`;
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      current: (state) => state.items.current,
      currentProductPrice: (state) => state.products.productPrices,
      fetchError: (state) => state.items.fetchOne.error,
      isFetchLoading: (state) => state.items.fetchOne.pending || state.products.computeProductPrice.pending,
      products: (state) => state.products.summary,
    }),
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  mounted() {
    this.fetchOne(this.id);
    if (this.$store.state.products.summary === null || this.$store.state.products.summary.length == 0) {
      this.fetchProducts();
    }
  },
};
</script>
