import Vue from 'vue';

import { ConceptsApi } from '@tasterkitchens/kitchens-manager-v2-client';

const conceptsApi = new ConceptsApi({
  accessToken: () => Vue.prototype.$auth.getToken(),
  isJsonMime: () => true,
  basePath: process.env.VUE_APP_KITCHENS_ENDPOINT,
});

export default {
  createOne(conceptRequest) {
    return conceptsApi.createConcept(conceptRequest);
  },
  fetchAll() {
    return conceptsApi.listConcepts();
  },
  fetchOne(uuid) {
    return conceptsApi.getConcept(uuid);
  },
  updateOne(conceptUuid, opts) {
    return conceptsApi.updateConcept(conceptUuid, opts);
  },
  uploadPicture(conceptUuid, picture, pictureType, platformUuid) {
    return conceptsApi.uploadConceptPicture(conceptUuid, picture, pictureType, platformUuid);
  },
};
