<template>
  <div class="box modal-card" style="overflow: visible">
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <h4 class="title is-4">Add Recipe Item</h4>
    <div v-if="parentItem">
      <div class="columns">
        <div class="column" label="Quantity" label-position="on-border">
          <b-input type="number" size="is-small" v-model.number="quantity" step=".01"> </b-input>
        </div>
        <div class="column" label="Unit" label-position="on-border">
          <b-select size="is-small" placeholder="Select a unit" v-model="unit">
            <option value="g">g</option>
            <option value="ml">ml</option>
            <option value="each">each</option>
          </b-select>
        </div>
        <div class="column is-two-thirds" expanded>
          <b-autocomplete
            size="is-small"
            v-model="searchTerm"
            :data="filteredItems"
            expanded
            placeholder="Select item..."
            icon="plus"
            field="label"
            @select="select"
          >
            <template slot="empty">No results found</template>
          </b-autocomplete>
        </div>
      </div>
      <b-button style="width: 100%" class="is-info" @click="compose">Add</b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import itemsModule from '@/store/modules/items';

export default {
  props: ['parentItemUuid'],
  data: () => ({
    activeStep: 0,
    parentItem: null,
    quantity: 0,
    unit: 'g',
    searchTerm: '',
  }),
  watch: {},
  methods: {
    ...mapActions('composeItem', [
      'fetchAll',
      'fetchOne',
      'buildOne',
      'createOne',
      'addLocation',
      'addToParent',
      'computeItemPrice',
    ]),
    ...mapMutations('composeItem', { setCurrent: 'SET_CURRENT' }),
    select(item) {
      if (item) {
        this.setCurrent(item);
        if (this.item.location_uuids.includes(this.currentLocation.uuid)) {
          const localizedItem = this.item.locations.find(({ uuid }) => uuid === this.currentLocation.uuid);
          this.fetchOne(localizedItem.item_uuid);
        } else {
          this.addLocation();
        }
      }
    },
    compose() {
      this.computeItemPrice({
        uuid: this.current.uuid,
        quantity: this.quantity,
        unit: this.unit,
        locationUuids: this.getLocationUUIDs(),
        save: false,
      }).then(() => {
        if (
          this.currentItemPrice &&
          this.currentItemPrice.cost_details &&
          this.currentItemPrice.cost_details.kitchen_cost != null &&
          this.currentItemPrice.cost_details.supplier_cost != null
        ) {
          this.addToParent({
            parentItemUuid: this.parentItem.uuid,
            quantity: this.quantity,
            unit: this.unit,
          }).then(() => {
            this.$emit('done');
            this.$parent.close();
          });
        } else {
          this.$buefy.dialog.confirm({
            title: `Editing ${this.current.label}`,
            message: `You can not <b>edit</b> quantity: <br> ${this.getAlertMessage(this.current)}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: `Go to ${this.current.label} page`,
            onCancel: () => this.goToItems(this.current),
          });
        }
      });
    },
    getLocationUUIDs() {
      if (!this.currentLocation.parent_uuid) {
        return [this.currentLocation.uuid];
      }
      return [this.currentLocation.uuid, this.currentLocation.parent_uuid];
    },
    getAlertMessage(item) {
      if (this.currentItemPrice == null || this.currentItemPrice.cost_details == null) {
        return `Item Price is not defined for <b>'${this.unit}'</b>`;
      }
      if (this.currentItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price of <b>${this.quantity} ${this.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      if (this.currentItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price of <b>${this.quantity} ${this.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      return `<b>'${this.unit}'</b> is not in ${item.label}' s item measures`;
    },
    goToItems(item) {
      return this.toPage('/items/' + item.generic_uuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.composeItem.all,
      fetchError: (state) => state.composeItem.computeItemPrice.error,
      isLoading: (state) => state.composeItem.computeItemPrice.pending,
      current: (state) => state.composeItem.current,
      currentItemPrice: (state) => state.composeItem.itemPrices,
      currentLocation: (state) => state.locations.current,
    }),
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
    filteredItems() {
      return this.items.filter((item) => item.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    isItemLocalized() {
      return (
        (this.item.location_uuids && this.item.location_uuids.includes(this.currentLocation.uuid)) ||
        this.item.location_uuid === this.currentLocation.uuid
      );
    },
  },
  beforeCreate() {
    if (!this.$store._modules.root.state.composeItem) {
      this.$store.registerModule('composeItem', itemsModule);
    }
  },
  mounted() {
    this.fetchOne(this.parentItemUuid).then(() => {
      this.parentItem = this.item;
      this.buildOne();
      if (this.$store.state.composeItem.all === null || this.$store.state.composeItem.all.length == 0) {
        this.fetchAll();
      }
    });
  },
};
</script>
