import clientHook from '@/apis';

const ProductsManagerClient = require('@tasterkitchens/products-manager-client');

const tagsApi = new ProductsManagerClient.TagsApi();
tagsApi.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  fetchAll() {
    return clientHook(tagsApi).appLegacyApisV2TagsListTags();
  },
};
