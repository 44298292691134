<template>
  <div class="box modal-card">
    <b-steps v-model="activeStep" size="is-small" :has-navigation="false">
      <b-step-item label="Item">
        <h4 class="title has-text-centered is-4">Item selection</h4>
        <ItemAutocomplete :items="items" @select="select" :key="activeStep" />
        <div class="is-divider" data-content="OR"></div>
        <ItemForm :item.sync="item" @save="create" buttonMessage="Add" />
      </b-step-item>
      <b-step-item label="Location">
        <div v-if="item.label">
          <h4 class="title has-text-centered is-4">Location availability</h4>
          <div v-if="isItemLocalized">{{ item.label }} is available in {{ currentLocation.label }}</div>
          <AddLocationDialog
            v-else
            :itemLabel="item.label"
            :locationLabel="currentLocation.label"
            @cancel="activeStep = 0"
            @confirm="createLocalizedItem"
          />
        </div>
      </b-step-item>
      <b-step-item label="Quantity">
        <div v-if="parentItem">
          <h4 class="title has-text-centered is-4">Quantity</h4>
          {{ parentItem.item_measure[0].quantity }} {{ parentItem.item_measure[0].unit }} {{ parentItem.label }} should
          contain
          <b-field grouped>
            <b-field label="Quantity" label-position="on-border" style="margin-bottom: 4px; margin-top: 8px">
              <b-input type="number" size="is-small" v-model.number="quantity" step=".01"> </b-input>
            </b-field>
            <b-field label="Unit" label-position="on-border" style="margin-top: 8px">
              <b-select size="is-small" placeholder="Select a unit" v-model="unit">
                <option value="g">g</option>
                <option value="ml">ml</option>
                <option value="each">each</option>
              </b-select>
            </b-field>
          </b-field>
          of {{ item.label }}
          <b-field><b-button class="is-primary" @click="compose">Done</b-button></b-field>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import itemsModule from '@/store/modules/items';
import ItemForm from '@/components/ItemForm.vue';
import ItemAutocomplete from '@/components/ItemAutocomplete.vue';
import AddLocationDialog from '@/components/Composition/AddLocationDialog.vue';

export default {
  props: ['parentItemUuid'],
  data: () => ({
    activeStep: 0,
    parentItem: null,
    quantity: 0,
    unit: 'g',
  }),
  watch: {
    activeStep() {
      if (!this.activeStep) {
        this.setCurrent(null);
      }
    },
  },
  components: { ItemForm, ItemAutocomplete, AddLocationDialog },
  methods: {
    ...mapActions('composeItem', ['fetchAll', 'fetchOne', 'buildOne', 'createOne', 'addLocation', 'addToParent']),
    ...mapMutations('composeItem', { setCurrent: 'SET_CURRENT' }),
    create() {
      this.createOne().then(this.checkLocalizedItem);
    },
    select(item) {
      this.setCurrent(item);
      this.checkLocalizedItem();
    },
    checkLocalizedItem() {
      if (this.item.location_uuids.includes(this.currentLocation.uuid)) {
        const localizedItem = this.item.locations.find(({ uuid }) => uuid === this.currentLocation.uuid);
        this.fetchOne(localizedItem.item_uuid);
        this.activeStep = 2;
      } else {
        this.activeStep = 1;
      }
    },
    createLocalizedItem() {
      this.addLocation().then(() => (this.activeStep = 2));
    },
    compose() {
      this.addToParent({
        parentItemUuid: this.parentItem.uuid,
        quantity: this.quantity,
        unit: this.unit,
      }).then(() => {
        this.$emit('done');
        this.$parent.close();
      });
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.composeItem.all,
      current: (state) => state.composeItem.current,
      currentLocation: (state) => state.locations.current,
    }),
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
    isItemLocalized() {
      return (
        (this.item.location_uuids && this.item.location_uuids.includes(this.currentLocation.uuid)) ||
        this.item.location_uuid === this.currentLocation.uuid
      );
    },
  },
  beforeCreate() {
    if (!this.$store._modules.root.state.composeItem) {
      this.$store.registerModule('composeItem', itemsModule);
    }
  },
  mounted() {
    this.fetchOne(this.parentItemUuid).then(() => {
      this.parentItem = this.item;
      this.buildOne();
      if (this.$store.state.composeItem.all === null || this.$store.state.composeItem.all.length == 0) {
        this.fetchAll();
      }
    });
  },
};
</script>
