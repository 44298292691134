<template>
  <article class="modal-card">
    <header class="modal-header">
      <h1 class="modal-title">Are you sure?</h1>
    </header>
    <section class="modal-body">
      <p class="modal-content">Do you really want to delete this substitute rule? This process cannot be undone.</p>
    </section>
    <footer class="modal-footer">
      <b-button type="is-taster-primary" :loading="isLoading" @click="$emit('confirm')"> Yes, delete it </b-button>
      <b-button type="is-taster-primary" outlined @click="$emit('close')"> No, cancel </b-button>
    </footer>
  </article>
</template>

<script>
export default {
  name: 'DeleteSubstituteRuleModal',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  max-width: 300px;
  margin: auto !important;
  background-color: white;
  border-radius: 6px;
  padding: 16px 24px;
}
.modal-header {
  margin-bottom: 6px;
}
.modal-title {
  font-size: 18px;
  line-height: 24px;
  color: black;
}
.modal-body {
  margin-bottom: 20px;
}
.modal-content {
  font-size: 12px;
  color: #74747b;
}
.modal-footer {
  display: flex;
  gap: 16px;
}
</style>
