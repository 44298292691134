<template>
  <div class="box modal-card">
    <b-steps v-model="activeStep" size="is-small" :has-navigation="false">
      <b-step-item label="Parameters">
        <hr />
        <div class="has-text-centered">
          <h4 class="title has-text-centered is-6">Select Concept</h4>
          <b-radio
            size="is-small"
            v-model="selectedConcept"
            v-for="concept in concepts"
            :native-value="concept"
            :key="concept.uuid"
          >
            <img :src="concept.logo" width="30" />
          </b-radio>
        </div>
        <hr />
        <div class="has-text-centered">
          <h4 class="title has-text-centered is-6">Select Source Location</h4>
          <b-radio
            size="is-small"
            v-model="selectedSourceLocation"
            v-for="location in getRelatedLocations()"
            :native-value="location"
            :key="location.uuid"
          >
            {{ location.label }}
          </b-radio>
        </div>
        <hr />
        <div class="has-text-centered">
          <h4 class="title has-text-centered is-6">Select Destination Location</h4>
          <b-radio
            size="is-small"
            v-model="selectedDestinationLocation"
            v-for="location in getRelatedLocations()"
            :native-value="location"
            :key="location.uuid"
          >
            {{ location.label }}
          </b-radio>
        </div>
        <b-button type="is-info" class="button-gap" @click="goToCopyPrices()" :disabled="copyDisabled()" expanded>
          Copy Prices
        </b-button>
      </b-step-item>
      <b-step-item label="Copy">
        <div>
          <b-table
            class="is-hoverable is-fullwidth is-striped"
            hoverable
            striped
            :data="prodcutsToCopy"
            :checked-rows.sync="productsToCopyValidated"
            checkable
            sticky-checkbox
            :sticky-header="true"
            checkbox-position="left"
          >
            <template slot-scope="props">
              <b-table-column field="label" label="Products" v-if="props.row.label" sortable>
                {{ props.row.label }}
              </b-table-column>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered">
          <b-button class="is-success" @click="copy()" expanded> Copy </b-button>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import summaryModule from '@/store/modules/summary';

export default {
  props: ['concepts'],
  data: () => ({
    activeStep: 0,
    selectedConcept: null,
    selectedSourceLocation: null,
    selectedDestinationLocation: null,
    prodcutsToCopy: [],
    productsToCopyValidated: [],
    localizedInformationsToUpdate: [],
    localizedInformationsToCreate: [],
  }),
  watch: {
    activeStep() {
      if (!this.activeStep) {
        this.setCurrent(null);
      }
    },
  },
  methods: {
    ...mapActions('locationSourceSummary', { fetchSourceConceptProducts: 'fetchConceptProducts' }),
    ...mapActions('locationDestinationSummary', { fetchDestinationConceptProducts: 'fetchConceptProducts' }),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('products', ['createLocalizedInformation', 'updateLocalizedInformation']),
    ...mapActions('summary', { fetchConceptProducts: 'fetchConceptProducts' }),
    getRelatedLocations() {
      var parentUuid = null;
      if (!this.currentLocation.parent_uuid) {
        parentUuid = this.currentLocation.uuid;
      } else {
        parentUuid = this.currentLocation.parent_uuid;
      }
      return this.locationsUnnested.filter((location) => location.parent_uuid == parentUuid);
    },
    copyDisabled() {
      if (
        this.selectedConcept === null ||
        this.selectedSourceLocation === null ||
        this.selectedDestinationLocation === null ||
        this.selectedSourceLocation.uuid == this.selectedDestinationLocation.uuid
      ) {
        return true;
      }
      return false;
    },
    goToCopyPrices() {
      var parentLocation = this.locations.filter(
        (location) => location.uuid == this.selectedSourceLocation.parent_uuid
      )[0];
      this.fetchConceptProducts({ conceptUuid: this.selectedConcept.uuid, location: parentLocation }).then(() => {
        if (this.conceptProducts.length > 0) {
          var products = this.products.filter((product) =>
            this.conceptProducts[0].product_uuids.includes(product.uuid)
          );
          for (let product of products) {
            if (product.localized_informations && product.localized_informations.length > 0) {
              var localizedInformationSource = product.localized_informations.filter(
                (localizedInfo) => localizedInfo.location_uuid == this.selectedSourceLocation.uuid
              );
              var localizedInformationDestination = product.localized_informations.filter(
                (localizedInfo) => localizedInfo.location_uuid == this.selectedDestinationLocation.uuid
              );
              var localizedInformationRequest = { ...localizedInformationSource[0] };
              delete localizedInformationRequest.uuid;
              localizedInformationRequest.location_uuid = this.selectedDestinationLocation.uuid;
              var productLocationInformationRequest = {
                ...localizedInformationRequest,
                supplierUUID: product.supplier.uuid,
                productUUID: product.uuid,
              };
              this.prodcutsToCopy.push(product);
              this.productsToCopyValidated.push(product);
              if (localizedInformationSource.length > 0) {
                if (localizedInformationDestination.length > 0) {
                  this.localizedInformationsToUpdate.push(productLocationInformationRequest);
                } else {
                  this.localizedInformationsToCreate.push(productLocationInformationRequest);
                }
              }
            }
          }
        }
      });
      this.activeStep = 1;
    },
    copy() {
      var productsToCopyValidatedUUIDS = this.productsToCopyValidated.map((product) => product.uuid);
      var localizedInformationsToUpdateValidated = this.localizedInformationsToUpdate.filter((localizedInfo) =>
        productsToCopyValidatedUUIDS.includes(localizedInfo.productUUID)
      );
      var localizedInformationsToCreateValidated = this.localizedInformationsToCreate.filter((localizedInfo) =>
        productsToCopyValidatedUUIDS.includes(localizedInfo.productUUID)
      );
      Promise.all([
        ...localizedInformationsToUpdateValidated.map((localizedInformation) =>
          this.updateLocalizedInformation(localizedInformation)
        ),
        ...localizedInformationsToCreateValidated.map((localizedInformation) =>
          this.createLocalizedInformation(localizedInformation)
        ),
      ]).then(() => this.$parent.close());
    },
  },
  computed: {
    ...mapState({
      conceptProducts: (state) => state.summary.conceptProducts,
      // sourceConceptProducts: (state) => state.locationSourceSummary.conceptProducts,
      // destinationConceptProducts: (state) => state.locationDestinationSummary.conceptProducts,
      currentLocation: (state) => state.locations.current,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      products: (state) => state.products.summary,
    }),
  },
  beforeCreate() {
    if (!this.$store._modules.root.state.locationSourceSummary) {
      this.$store.registerModule('locationSourceSummary', summaryModule);
    }
    if (!this.$store._modules.root.state.locationDestinationSummary) {
      this.$store.registerModule('locationDestinationSummary', summaryModule);
    }
  },
  mounted() {
    if (this.$store.state.locations.all === null || this.$store.state.locations.all.length == 0) {
      this.fetchAllLocations();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      this.fetchUnnestedLocations();
    }
  },
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
