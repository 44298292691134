<template>
  <div class="check-container box card">
    <div class="card-content">
      <div class="comparison-container">
        <div class="menu-item">
          <MenuItemModal
            ref="taster"
            class="comparison-side"
            :menu-item="comparison.tasterMenuItem"
            :logo="logos.tasterLogo"
            :menuItems="menuItems"
            :modifierGroups="modifierGroups"
            :currentLocation="currentLocation"
          />
        </div>

        <div class="border">
          <div class="circle">
            <b-icon icon="arrow-right" type="is-light" />
          </div>
        </div>

        <div class="menu-item">
          <MenuItemModal
            ref="platform"
            class="comparison-side"
            :menu-item="comparison.platformMenuItem"
            :logo="logos.platformLogo"
            :menuItems="menuItems"
            :modifierGroups="modifierGroups"
            :currentLocation="currentLocation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import MenuItemModal from '@/components/Menu/MenuItemModal.vue';

export default {
  name: 'RestaurantsCheckComparison',
  components: {
    MenuItemModal,
  },
  props: {
    comparison: {
      type: Object,
      required: true,
    },
    logos: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('locations', ['getLanguageCode']),
    ...mapState({
      menuItems: (state) => state.menuItems.all,
      modifierGroups: (state) => state.modifierGroups.all,
      currentLocation: (state) => state.locations.current,
    }),
  },
  methods: {
    localizedText(obj) {
      const key = Object.keys(obj).find((key) => key.includes(this.getLanguageCode));
      if (key) {
        return obj[key];
      }
      return null;
    },
    localizedMenuItem(menuItem) {
      const menuItemLocation = { ...menuItem };
      menuItemLocation.label = this.localizedText(menuItem.labels);
      menuItemLocation.description = this.localizedText(menuItem.description);
      menuItemLocation.modifierGroups = [
        ...menuItem.modifier_groups.map(
          (modifierGroup) => (modifierGroup = { ...modifierGroup, label: this.localizedText(modifierGroup.labels) })
        ),
      ];
      menuItemLocation.picture_url = menuItemLocation.image_url;
      return menuItemLocation;
    },
  },
  mounted() {
    // get the card of each child
    var s1 = this.$refs.taster.$refs.card;
    var s2 = this.$refs.platform.$refs.card;
    // synchronize scrolling
    function select_scroll() {
      s2.scrollTop = s1.scrollTop;
    }
    if (s1 && s2) {
      s1.addEventListener('scroll', select_scroll, false);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.check-container {
  min-width: 1000px;
}

.card {
  padding: 0;

  &-content {
    padding: 0;

    &-title {
      text-align: center;
      padding: 20px;

      .title {
        margin: 0;
      }
    }

    .comparison-container {
      min-height: 100px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .menu-item {
        width: calc(50% - 2px);
        height: 100%;

        .comparison-side {
          max-width: 100%;
          padding: 0;
        }
      }

      .border {
        width: 2px;
        border: solid 2px black;
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
          position: absolute;
          background-color: black;
          height: 40px;
          width: 40px;
          border-radius: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
      }
    }
  }
}

.overflow {
  max-height: 95vh;
  overflow-y: auto;
}
</style>
