<template>
  <div class="box modal-card">
    <h3 class="subtitle">Add product</h3>
    <b-field grouped>
      <b-autocomplete
        expanded
        rounded
        class="autocomplete"
        v-model="searchTerm"
        placeholder="Search for an existing product..."
        icon="magnify"
        field="label"
        :data="filteredData"
        @select="({ uuid }) => $emit('close', uuid)"
      >
        <template slot="empty">No products found.</template>
        <template slot-scope="props">
          <div>
            {{ props.option.label }}<b-tag rounded>{{ props.option.supplier.label }}</b-tag>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    searchTerm: '',
  }),
  methods: {
    ...mapActions('products', ['fetchAll']),
  },
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    filteredData() {
      return this.products.filter((option) => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  created() {
    if (this.$store.state.products.all === null || this.$store.state.products.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .autocomplete {
  > .dropdown-menu {
    display: contents;
  }
}
</style>
