<template>
  <div class="box modal-card">
    <div class="has-text-centered">
      <h4 class="title has-text-centered is-6">Select Concept</h4>
      <b-radio
        size="is-small"
        v-model="selectedConcept"
        v-for="concept in concepts"
        :native-value="concept"
        :key="concept.uuid"
      >
        <img :src="concept.logo" width="30" />
      </b-radio>
    </div>
    <div class="has-text-centered">
      <b-button class="is-success" @click="$emit('close', selectedConcept)" expanded :disabled="!selectedConcept">
        Select
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['concepts'],
  data: () => ({
    selectedConcept: null,
  }),
  watch: {},
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
