import combosAPI from '@/apis/items/combos';
import { combosTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    all: [],
    categories: null,
    current: null,
    currentModifierGroups: null,
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    addConcept: {
      pending: false,
      error: null,
    },
    removeConcept: {
      pending: false,
      error: null,
    },
    addLocation: {
      pending: false,
      error: null,
    },
    removeLocation: {
      pending: false,
      error: null,
    },
    updateLocation: {
      pending: false,
      error: null,
    },
    fetchModifierGroups: {
      pending: false,
      error: null,
    },
    addModifierGroup: {
      pending: false,
      error: null,
    },
    removeModifierGroup: {
      pending: false,
      error: null,
    },
    uploadPicture: {
      pending: false,
      error: null,
    },
    fetchCategories: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [combosTypes.FETCH_ALL_SUCCESS](state, combos) {
      state.all = combos;
      state.fetchAll.pending = false;
    },
    [combosTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [combosTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [combosTypes.FETCH_ONE_SUCCESS](state, combo) {
      state.current = combo;
      state.fetchOne.pending = false;
    },
    [combosTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [combosTypes.FETCH_ONE_REQUEST](state) {
      state.fetchOne.pending = true;
      state.fetchOne.error = null;
    },
    [combosTypes.CREATE_ONE_SUCCESS](state, combo) {
      // TODO: Update all
      state.current = combo;
      state.createOne.pending = false;
    },
    [combosTypes.CREATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [combosTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [combosTypes.UPDATE_ONE_SUCCESS](state, combo) {
      state.current = combo;
      state.updateOne.pending = false;
    },
    [combosTypes.UPDATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [combosTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
    },
    [combosTypes.SET_CURRENT](state, combo) {
      state.current = combo;
    },
    [combosTypes.ADD_CONCEPT_SUCCESS](state, { combo }) {
      const index = state.all.findIndex(({ uuid }) => combo.uuid === uuid);

      if (index > -1) {
        state.all.splice(index, 1, combo);
      } else {
        state.all.push(combo);
      }

      state.current = combo;
      state.addConcept.pending = false;
    },
    [combosTypes.ADD_CONCEPT_FAILURE](state, error) {
      state.current = null;
      state.addConcept.pending = false;
      state.addConcept.error = error;
    },
    [combosTypes.ADD_CONCEPT_REQUEST](state) {
      state.addConcept.pending = true;
    },
    [combosTypes.REMOVE_CONCEPT_SUCCESS](state, { comboUuid, conceptUuid }) {
      const combo = state.all.find(({ uuid }) => uuid === comboUuid);
      combo.concept_uuids.splice(
        combo.concept_uuids.findIndex(({ uuid }) => uuid === conceptUuid),
        1
      );
      state.removeConcept.pending = false;
    },
    [combosTypes.REMOVE_CONCEPT_FAILURE](state, error) {
      state.current = null;
      state.removeConcept.pending = false;
      state.removeConcept.error = error;
    },
    [combosTypes.REMOVE_CONCEPT_REQUEST](state) {
      state.removeConcept.pending = true;
    },
    [combosTypes.ADD_LOCATION_SUCCESS](state, { combo, locationUuid }) {
      const location = combo.locations.find(({ uuid }) => uuid === locationUuid);
      state.current.locations.push(location);
      state.current.location_uuids.push(location.uuid);
      state.addLocation.pending = false;
    },
    [combosTypes.ADD_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.addLocation.pending = false;
      state.addLocation.error = error;
    },
    [combosTypes.ADD_LOCATION_REQUEST](state) {
      state.addLocation.pending = true;
    },
    [combosTypes.REMOVE_LOCATION_SUCCESS](state, locationUuid) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1
      );
      const index = state.current.location_uuids.indexOf(locationUuid);
      if (index > -1) {
        state.current.location_uuids.splice(index, 1);
      }
      state.removeLocation.pending = false;
    },
    [combosTypes.REMOVE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.removeLocation.pending = false;
      state.removeLocation.error = error;
    },
    [combosTypes.REMOVE_LOCATION_REQUEST](state) {
      state.removeLocation.pending = true;
    },
    [combosTypes.UPDATE_LOCATION_SUCCESS](state, { combo, locationUuid }) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1,
        combo.locations.find(({ uuid }) => uuid === locationUuid)
      );
      state.updateLocation.pending = false;
    },
    [combosTypes.UPDATE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.updateLocation.pending = false;
      state.updateLocation.error = error;
    },
    [combosTypes.UPDATE_LOCATION_REQUEST](state) {
      state.updateLocation.pending = true;
    },
    [combosTypes.SET_CURRENT_LOCATION](state, location) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === location.uuid),
        1,
        location
      );
    },
    [combosTypes.FETCH_MODIFIER_GROUPS_REQUEST](state) {
      state.fetchModifierGroups.pending = true;
      state.fetchModifierGroups.error = null;
    },
    [combosTypes.FETCH_MODIFIER_GROUPS_SUCCESS](state, modifierGroups) {
      state.fetchModifierGroups.pending = false;
      state.currentModifierGroups = modifierGroups;
    },
    [combosTypes.FETCH_MODIFIER_GROUPS_FAILURE](state, error) {
      state.fetchModifierGroups.pending = false;
      state.fetchModifierGroups.error = error;
    },
    [combosTypes.ADD_MODIFIER_GROUP_REQUEST](state) {
      state.addModifierGroup.pending = true;
      state.addModifierGroup.error = null;
    },
    [combosTypes.ADD_MODIFIER_GROUP_SUCCESS](state, menuItem) {
      state.addModifierGroup.pending = false;
      state.current.modifier_group_uuids = menuItem.modifier_group_uuids;
      state.current.modifier_group_uuids_sorted = menuItem.modifier_group_uuids_sorted;
    },
    [combosTypes.ADD_MODIFIER_GROUP_FAILURE](state, error) {
      state.addModifierGroup.pending = false;
      state.addModifierGroup.error = error;
    },
    [combosTypes.REMOVE_MODIFIER_GROUP_REQUEST](state) {
      state.removeModifierGroup.pending = true;
      state.removeModifierGroup.error = null;
    },
    [combosTypes.REMOVE_MODIFIER_GROUP_SUCCESS](state, modifierGroupUuid) {
      state.removeModifierGroup.pending = false;
      state.current.modifier_group_uuids.splice(
        state.current.modifier_group_uuids.findIndex((uuid) => uuid === modifierGroupUuid),
        1
      );
      state.current.modifier_group_uuids_sorted.splice(
        state.current.modifier_group_uuids_sorted.findIndex((uuid) => uuid === modifierGroupUuid),
        1
      );
      state.currentModifierGroups.splice(
        state.currentModifierGroups.findIndex(({ uuid }) => uuid === modifierGroupUuid),
        1
      );
      state.all.splice(
        state.all.findIndex(({ uuid }) => uuid === state.current.uuid),
        1,
        state.current
      );
    },
    [combosTypes.REMOVE_MODIFIER_GROUP_FAILURE](state, error) {
      state.removeModifierGroup.pending = false;
      state.removeModifierGroup.error = error;
    },
    [combosTypes.UPLOAD_PICTURE_SUCCESS](state, combo) {
      state.current = combo;
      state.uploadPicture.pending = false;
    },
    [combosTypes.UPLOAD_PICTURE_FAILURE](state, error) {
      state.current = null;
      state.uploadPicture.pending = false;
      state.uploadPicture.error = error;
    },
    [combosTypes.UPLOAD_PICTURE_REQUEST](state) {
      state.uploadPicture.pending = true;
    },
    [combosTypes.FETCH_CATEGORIES_REQUEST](state) {
      state.fetchCategories.pending = true;
      state.fetchCategories.error = null;
    },
    [combosTypes.FETCH_CATEGORIES_SUCCESS](state, categories) {
      state.fetchCategories.pending = false;
      state.categories = categories;
    },
    [combosTypes.FETCH_CATEGORIES_FAILURE](state, error) {
      state.fetchCategories.pending = false;
      state.fetchCategories.error = error;
    },
    [combosTypes.UPDATE_CURRENT_MODIFIER_GROUPS](state, modifierGroup) {
      const index = state.currentModifierGroups.findIndex(({ uuid }) => modifierGroup.uuid === uuid);
      if (index > -1) {
        state.currentModifierGroups.splice(index, 1, modifierGroup);
      } else {
        state.currentModifierGroups.push(modifierGroup);
      }
    },
  },
  actions: {
    buildOne({ commit }) {
      commit(combosTypes.SET_CURRENT, combosAPI.buildOne());
    },
    async fetchAll({ commit }) {
      commit(combosTypes.FETCH_ALL_REQUEST);

      return combosAPI
        .fetchAll()
        .then((combos) => commit(combosTypes.FETCH_ALL_SUCCESS, combos))
        .catch((error) => commit(combosTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, id) {
      commit(combosTypes.FETCH_ONE_REQUEST);

      return combosAPI
        .fetchOne(id)
        .then((combo) => commit(combosTypes.FETCH_ONE_SUCCESS, combo))
        .catch((error) => commit(combosTypes.FETCH_ONE_FAILURE, error));
    },
    async createOne({ commit, state }) {
      commit(combosTypes.CREATE_ONE_REQUEST);

      return combosAPI
        .createOne(state.current)
        .then((combo) => commit(combosTypes.CREATE_ONE_SUCCESS, combo))
        .catch((error) => commit(combosTypes.CREATE_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(combosTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.concept_uuids;
      delete data.item_uuids;
      delete data.location_uuids;
      delete data.modifier_group_uuids;
      delete data.pictures;
      delete data.recipe;
      delete data.locations;

      return combosAPI
        .updateOne(state.current.uuid, data)
        .then((combo) => commit(combosTypes.UPDATE_ONE_SUCCESS, combo))
        .catch((error) => commit(combosTypes.UPDATE_ONE_FAILURE, error));
    },
    async addToConcept({ commit }, { comboUuid, conceptUuid }) {
      commit(combosTypes.ADD_CONCEPT_REQUEST);

      return combosAPI
        .addToConcept(comboUuid, conceptUuid)
        .then((combo) => commit(combosTypes.ADD_CONCEPT_SUCCESS, { combo, conceptUuid }))
        .catch((error) => commit(combosTypes.ADD_CONCEPT_FAILURE, error));
    },
    async removeFromConcept({ commit }, { comboUuid, conceptUuid }) {
      commit(combosTypes.REMOVE_CONCEPT_REQUEST);

      return combosAPI
        .removeFromConcept(comboUuid, conceptUuid)
        .then(() => commit(combosTypes.REMOVE_CONCEPT_SUCCESS, { comboUuid, conceptUuid }))
        .catch((error) => commit(combosTypes.REMOVE_CONCEPT_FAILURE, error));
    },
    async addLocation({ commit, state }, locationUuid) {
      commit(combosTypes.ADD_LOCATION_REQUEST);

      return combosAPI
        .addLocation(state.current.uuid, locationUuid)
        .then((combo) => commit(combosTypes.ADD_LOCATION_SUCCESS, { combo, locationUuid }))
        .catch((error) => commit(combosTypes.ADD_LOCATION_FAILURE, error));
    },
    async removeLocation({ commit, state }, locationUuid) {
      commit(combosTypes.REMOVE_LOCATION_REQUEST);

      return combosAPI
        .removeLocation(state.current.uuid, locationUuid)
        .then(() => commit(combosTypes.REMOVE_LOCATION_SUCCESS, locationUuid))
        .catch((error) => commit(combosTypes.REMOVE_LOCATION_FAILURE, error));
    },
    async updateLocation({ commit, state }, locationUuid) {
      commit(combosTypes.UPDATE_LOCATION_REQUEST);

      const location = state.current.locations.find(({ uuid }) => uuid === locationUuid);
      const data = { ...location };

      delete data.uuid;

      return combosAPI
        .updateLocation(state.current.uuid, location.uuid, data)
        .then((combo) => commit(combosTypes.UPDATE_LOCATION_SUCCESS, { combo, locationUuid: location.uuid }))
        .catch((error) => commit(combosTypes.UPDATE_LOCATION_FAILURE, error));
    },
    async fetchModifierGroups({ commit, state }) {
      commit(combosTypes.FETCH_MODIFIER_GROUPS_REQUEST);

      return combosAPI
        .fetchModifierGroups(state.current.uuid)
        .then((modifierGroups) => commit(combosTypes.FETCH_MODIFIER_GROUPS_SUCCESS, modifierGroups))
        .catch((error) => commit(combosTypes.FETCH_MODIFIER_GROUPS_FAILURE, error));
    },
    async addModifierGroup({ commit, state }, modifierGroupUuid) {
      commit(combosTypes.ADD_MODIFIER_GROUP_REQUEST);

      return combosAPI
        .addModifierGroup(state.current.uuid, modifierGroupUuid)
        .then((combo) => commit(combosTypes.ADD_MODIFIER_GROUP_SUCCESS, combo))
        .catch((error) => commit(combosTypes.ADD_MODIFIER_GROUP_FAILURE, error));
    },
    async removeModifierGroup({ commit, state }, modifierGroupUuid) {
      commit(combosTypes.REMOVE_MODIFIER_GROUP_REQUEST);

      return combosAPI
        .removeModifierGroup(state.current.uuid, modifierGroupUuid)
        .then(() => commit(combosTypes.REMOVE_MODIFIER_GROUP_SUCCESS, modifierGroupUuid))
        .catch((error) => commit(combosTypes.REMOVE_MODIFIER_GROUP_FAILURE, error));
    },
    async uploadPicture({ commit, state }, { conceptUuid, picture, platformUuid, basewidth }) {
      commit(combosTypes.UPLOAD_PICTURE_REQUEST);

      return combosAPI
        .uploadPicture(state.current.uuid, conceptUuid, picture, platformUuid, basewidth)
        .then((combo) => commit(combosTypes.UPLOAD_PICTURE_SUCCESS, combo))
        .catch((error) => commit(combosTypes.UPLOAD_PICTURE_FAILURE, error));
    },
    async fetchCategories({ commit }) {
      commit(combosTypes.FETCH_CATEGORIES_REQUEST);

      return combosAPI
        .fetchCategories()
        .then((categories) => commit(combosTypes.FETCH_CATEGORIES_SUCCESS, categories))
        .catch((error) => commit(combosTypes.FETCH_CATEGORIES_FAILURE, error));
    },
  },
  getters: {
    getLocationByUuid: (state) => (uuid) => state.current.locations.find((location) => location.uuid === uuid),
    filterCombosByConcept: (state) => (conceptUUID) =>
      state.all.filter((combo) => combo.concept_uuids.includes(conceptUUID)),
  },
};
