<template>
  <div>
    <MenuItemPlatformForm
      @save="updateLocation"
      @addPlatformInformations="
        (platforms, conceptUuid) => addPlatformInformations(platforms, conceptUuid, currentLocation.uuid)
      "
      @add="(platforms, concept) => add(platforms, currentLocation.uuid, concept)"
      @remove="remove"
      @update:location="setCurrentComboLocation"
      :location="getLocationByUuid(currentLocation.uuid)"
      :currentMenuItem="currentCombo"
      :currentMenuItemInformations="currentItemInformations"
      :currentUuid="currentCombo.uuid"
      :currentLocation="currentLocation"
      :currentConcept="currentConcept"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import { combosTypes } from '@/store/mutation-types';
import MenuItemPlatformForm from '@/components/MenuItem/Platform/MenuItemPlatformForm.vue';

export default {
  components: { MenuItemPlatformForm },
  data() {
    return {
      currentIndex: 0,
      newLocation: {},
    };
  },
  computed: {
    ...mapState({
      currentCombo: (state) => state.combos.current,
      currentConcept: (state) => state.concepts.current,
      currentLocation: (state) => state.locations.current,
      currentItemInformations: (state) => state.itemsInformations.all,
    }),
    ...mapGetters('combos', ['getLocationByUuid']),
    menuItem: {
      get() {
        return { ...this.currentCombo };
      },
      set(value) {
        this.setCurrentCombo(value);
      },
    },
  },
  methods: {
    ...mapActions('itemsInformations', ['createOne', 'removeOne']),
    ...mapActions('combos', ['addLocation', 'removeLocation', 'updateLocation']),
    ...mapMutations('combos', {
      setCurrentCombo: combosTypes.SET_CURRENT,
      setCurrentComboLocation: combosTypes.SET_CURRENT_LOCATION,
    }),
    addPlatformInformations(platforms, conceptUuid, locationUuid) {
      var existingInformations = this.currentItemInformations
        .filter(
          (menuItemInformation) =>
            menuItemInformation.concept_uuid == conceptUuid && menuItemInformation.location_uuid == locationUuid
        )
        .map((itemInformation) => itemInformation.platform_uuid);
      var platformsToCreate = platforms.filter((platform) => !existingInformations.includes(platform.uuid));
      return Promise.all(
        platformsToCreate.map((platform) =>
          this.createOne({
            concept_uuid: conceptUuid,
            item_uuid: this.currentCombo.uuid,
            location_uuid: locationUuid,
            platform_uuid: platform.uuid,
            selling_price: 0.0,
            selling_unit_price: this.currentLocation.location_country.currency,
          })
        )
      );
    },
    add(platforms, locationUuid, concept) {
      this.addPlatformInformations(platforms, concept.uuid, locationUuid).then(() => this.addLocation(locationUuid));
    },
    removePlatformInformations(locationUuid) {
      var existingInformations = this.currentItemInformations.filter(
        (menuItemInformation) => menuItemInformation.location_uuid == locationUuid
      );
      return Promise.all(existingInformations.map((information) => this.removeOne(information)));
    },
    remove(locationUuid) {
      this.removePlatformInformations(locationUuid).then(() => this.removeLocation(locationUuid));
    },
  },
};
</script>
