export default {
  itemsManagerViewer: 'Item Manager Viewer',
  itemConceptsViewer: 'Item Concepts Viewer',
  itemMenuItemRecipeEditor: 'Item Concepts MenuItem Editor',
  itemComboRecipeEditor: 'Item Concepts Combos Editor',
  itemRecipesViewer: 'Item Recipes Viewer',
  itemRecipesCreator: 'Item Recipes Creator',
  itemRecipesEditor: 'Item Recipes Editor',
  itemRecipesProductEditor: 'Item Recipes Product Editor',
  itemProductEditor: 'Item Product Editor',
  itemMenuViewer: 'Item Menu Viewer',
  itemMenuEditor: 'Item Menu Editor',
  itemMenuUploader: 'Item Menu Uploader',
  itemManagerAdmin: 'Item Manager Admin',
  itemHealthchecksViewer: 'Item Healthchecks Viewer',
};
