<template>
  <div>
    <b-dropdown ref="dropdown" v-model="selectedLocation" position="is-bottom-left" aria-role="menu">
      <button class="button is-light" type="button" slot="trigger">
        <span>{{ selectedLocation ? selectedLocation.label : 'Locations' }}</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <LocationSelectorItem
        @select="selectLocation"
        :location="getAuthorizedSubLocations(location)"
        v-for="location in authorizedLocations"
        :key="location.uuid"
      />
    </b-dropdown>
  </div>
</template>

<script>
import { locationsTypes } from '@/store/mutation-types';
import { mapState, mapActions, mapMutations } from 'vuex';
import LocationSelectorItem from './LocationSelectorItem.vue';
import { auth } from '../../mixins';

const TASTER_ORG = process.env.VUE_APP_IAM_ORGANIZATION;

export default {
  mixins: [auth],
  components: { LocationSelectorItem },
  computed: {
    ...mapState({
      locations: (state) => state.locations.all,
      currentLocation: (state) => state.locations.current,
    }),
    selectedLocation: {
      get() {
        return this.currentLocation;
      },
      set(value) {
        this.setCurrentLocation(value);
        localStorage.setItem('currentLocation', JSON.stringify(value));
      },
    },
    authorizedLocations() {
      if (!this.locations) {
        return [];
      }
      return this.locations.filter(
        (location) => this.getUserLocations(TASTER_ORG) && this.getUserLocations(TASTER_ORG).includes(location.uuid)
      );
    },
  },
  methods: {
    ...mapActions('locations', ['fetchOne', 'fetchAll']),
    ...mapMutations('locations', { setCurrentLocation: locationsTypes.SET_CURRENT_LOCATION }),
    selectLocation(location) {
      this.selectedLocation = location;
      this.$refs.dropdown.isActive = false;
    },
    getAuthorizedSubLocations(location) {
      return {
        ...location,
        locations: [
          ...location.locations
            .filter(
              (location) =>
                this.getUserLocations(TASTER_ORG) && this.getUserLocations(TASTER_ORG).includes(location.uuid)
            )
            .sort((location1, location2) => location1.label.localeCompare(location2.label)),
        ],
      };
    },
  },
  async mounted() {
    await this.fetchAll();
    if (!this.currentLocation) {
      this.selectedLocation = this.locations[0];
    }
  },
};
</script>
