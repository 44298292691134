<template>
  <div class="columns">
    <div class="column" v-if="recipe && recipe.length">
      <div class="columns item-row" v-for="item in recipeSortByLabel" :key="item.uuid">
        <div class="column" style="margin: auto">
          <span v-if="hasAccessToRecipe()">
            {{ item.label }}
            <b-button
              size="is-small"
              icon-right="open-in-new"
              type="is-text"
              @click="$router.push(`/items/${item.uuid}`)"
              rounded
            />
          </span>
          <div v-else>
            {{ item.label }}
          </div>
        </div>
        <div class="column is-narrow">
          <MenuItemRecipeItemForm
            :quantity.sync="item.quantity"
            :unit.sync="item.unit"
            :currentLocation="currentLocation"
            @remove="$emit('removeItem', item.uuid)"
            @save="$emit('saveItem', item)"
          />
        </div>
      </div>
    </div>
    <div class="column" v-else>
      <b-message class="is-warning">No items in recipe</b-message>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { auth } from '@/mixins';

import MenuItemRecipeItemForm from '@/components/MenuItem/Recipe/MenuItemRecipeItemForm.vue';

export default {
  mixins: [auth],
  components: { MenuItemRecipeItemForm },
  props: ['recipe', 'currentLocation'],
  computed: {
    recipeSortByLabel() {
      return _.orderBy(this.recipe, [(item) => item.label.toLowerCase()], ['asc']);
    },
  },
  methods: {},
};
</script>

<style scoped>
.item-row:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}
</style>
