import Vue from 'vue';
import Vuex from 'vuex';
import excel from 'vue-excel-export';

import concepts from './modules/concepts';
import menuItems from './modules/menuItems';
import suppliers from './modules/suppliers';
import locations from './modules/locations';
import items from './modules/items';
import itemsInformations from './modules/itemsInformations';
import products from './modules/products';
import hubs from './modules/hubs';
import modifierGroups from './modules/modifierGroups';
import combos from './modules/combos';
import platforms from './modules/platforms';
import menus from './modules/menus';
import categories from './modules/categories';
import restaurants from './modules/restaurants';
import shippingPolicies from './modules/shippingPolicies';
import summary from './modules/summary';
import substitutesManagement from './modules/substituteManagement';
import actions from './modules/actions';
import gatewayMenus from './modules/gatewayMenus';

Vue.use(Vuex);
Vue.use(excel);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    categories,
    combos,
    concepts,
    hubs,
    items,
    itemsInformations,
    locations,
    menuItems,
    modifierGroups,
    menus,
    platforms,
    restaurants,
    products,
    shippingPolicies,
    summary,
    suppliers,
    substitutesManagement,
    actions,
    gatewayMenus,
  },
});

export default store;
