<template>
  <div>
    <b-tabs>
      <b-tab-item>
        <template #header> General Information </template>
        <SupplierInfoForm
          :supplier="supplier"
          @supplier:update="(supplier) => $emit('supplier:update', supplier)"
          :current-location="currentLocation"
          :localized-infos="parentLocalizedInformation"
        />
      </b-tab-item>
      <b-tab-item v-if="showKitchensSpecific">
        <template #header>
          <span v-show="updatedLocalizedInfo.length > 0" class="dirty-tab">
            <b-icon pack="fas" icon="asterisk" type="is-danger" size="is-small" />
          </span>
          Kitchens Specific Information
        </template>
        <SupplierLocalizedInfoForm
          :current-location="currentLocation"
          :localized-infos="supplier.localized_informations || []"
          :parent-localized-infos="parentLocalizedInformation"
          :is-fetching-localized-info="isFetchingLocalizedInfo"
          :updated-locations="updatedLocalizedInfo"
          @localized-supplier:fetch="(locationUuid) => $emit('localized-supplier:fetch', locationUuid)"
          @localized-supplier:update="onLocalizedSupplier"
        />
      </b-tab-item>
    </b-tabs>
    <div class="buttons is-right">
      <b-button type="is-primary" :disabled="errorMessage !== null" :loading="isSaving" @click="onSave">
        <template v-if="supplier.uuid">Save</template>
        <template v-else>Create</template>
      </b-button>
      <b-button @click="() => $emit('cancel')">Cancel</b-button>
    </div>
    <p v-show="errorMessage" class="is-size-7 is-italic has-text-right has-text-weight-light has-text-danger">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import SupplierLocalizedInfoForm from './SupplierLocalizedInfoForm.vue';
import SupplierInfoForm from './SupplierInfoForm.vue';

export default {
  name: 'SupplierForm',
  components: {
    SupplierLocalizedInfoForm,
    SupplierInfoForm,
  },
  model: {
    prop: 'supplier',
    event: 'supplier:update',
  },
  props: {
    currentLocation: {
      type: Object,
      required: true,
    },
    isFetchingLocalizedInfo: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: Object,
      validator: (obj) => 'delivery_days' in obj && 'open_days' in obj,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    showKitchensSpecific: {
      type: Boolean,
      default: false,
    },
    parentLocalizedInformation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selectedLocations: [],
    };
  },
  computed: {
    availableLocations() {
      return [...this.currentLocation.locations].sort((left, right) => left.id.localeCompare(right.id));
    },
    errorMessage() {
      const { uuid, label, mails } = this.supplier;

      if (!uuid && !label) {
        return 'Missing label';
      } else if (!Array.isArray(mails) || !mails.length) {
        return 'Missing supplier mails';
      }

      return null;
    },
    updatedLocalizedInfo() {
      if (this.currentLocation.parent_uuid !== null) {
        return [];
      }

      const localizedInfos = this.supplier.localized_informations || [];
      return localizedInfos
        .filter((info) => info.update !== undefined)
        .map((info) => {
          const location = this.currentLocation.locations.find((location) => location.uuid === info.location_uuid);
          return location ? location.id : undefined;
        })
        .filter((label) => label !== undefined);
    },
  },
  methods: {
    getParentLocation() {
      return this.currentLocation.parent_uuid === null ? this.currentLocation.uuid : this.currentLocation.parent_uuid;
    },
    onLocationSelected(locations) {
      this.selectedLocations = locations;
    },
    onLocalizedSupplier(
      location_uuid,
      { account_code, contact_email, mails, use_translation_with_provider, available_for_ordering }
    ) {
      const localized_informations = Array.isArray(this.supplier.localized_informations)
        ? [...this.supplier.localized_informations]
        : [];

      const idx = localized_informations.findIndex((info) => info.location_uuid === location_uuid);
      if (idx > -1) {
        localized_informations[idx] = {
          update: true,
          ...localized_informations[idx],
          account_code,
          contact_email,
          mails,
          use_translation_with_provider,
          available_for_ordering,
        };
      } else {
        localized_informations.push({
          update: false,
          location_uuid,
          account_code,
          contact_email,
          mails,
          use_translation_with_provider,
          available_for_ordering,
        });
      }
      this.$emit('supplier:update', {
        ...this.supplier,
        localized_informations,
      });
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style scoped>
.dirty-tab {
  font-size: 8px;
}

.dirty-tab > * {
  margin: 0px !important;
}
</style>
