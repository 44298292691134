<template>
  <div class="box">
    <div class="is-flex column-drag-handle is-size-9" style="justify-content: space-between">
      <div>
        &#x2630; {{ modifierGroup.label }}
        <b-button rounded size="is-small" outlined @click="isVisible = !isVisible">
          <b-icon icon="chevron-down" size="is-small" />
        </b-button>
      </div>
      <div class="buttons" v-if="isItemMenuEditor()">
        <b-button style="margin-left: 4px" icon-left="pencil" @click="edit()" size="is-small" outlined type="is-info"
          >Edit
        </b-button>
        <b-button outlined icon-left="delete" size="is-small" @click="$emit('remove')"> Remove </b-button>
        <b-checkbox-button
          size="is-small"
          v-model="modifierGroupRequired"
          :native-value="isModifierGroupRequired"
          :type="modifierGroupRequiredType"
          :disabled="requiredStateUpdating"
          v-if="itemType == 'menuItem'"
        >
          <b-icon :icon="modifierGroupRequiredIcon"></b-icon>
          <span>{{ modifierGroupRequiredText }}</span>
        </b-checkbox-button>
      </div>
    </div>
    <div v-if="localizedModifierGroup && localizedModifierGroup.length" v-show="isVisible">
      <div>
        <b-field grouped group-multiline style="align-items: baseline">
          <div class="control title is-6">
            {{ localizedModifierGroup[0].label }}
          </div>
          <div class="control">
            <b-taglist attached>
              <b-tag type="is-info">{{ localizedModifierGroup[0].included_number }}</b-tag>
              <b-tag>included</b-tag>
            </b-taglist>
          </div>
          <div class="control">
            <b-taglist attached>
              <b-tag type="is-info is-light">{{ localizedModifierGroup[0].max_number }}</b-tag>
              <b-tag>max</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
      <div v-for="menuItem in localizedModifierGroup[0].menu_items" :key="menuItem.uuid">
        <div class="menuItem is-size-7">
          <div class="is-flex" style="justify-content: space-between">
            <div>
              {{ menuItem.label }}
              <b-tag type="is-info"> {{ menuItem.category }} </b-tag>
            </div>
            <div class="has-text-weight-bold">{{ menuItem.selling_price }}{{ menuItem.selling_unit_price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';
/* eslint-disable */
export default {
  mixins: [auth],
  props: ['item', 'modifierGroup', 'currentLocation', 'requiredStateUpdating', 'itemType'],
  data: () => ({
    isVisible: false,
  }),
  computed: {
    modifierGroupRequired: {
      get() {
        return this.isModifierGroupRequired;
      },
      set(value) {
        this.$emit('update', !this.modifierGroupRequired, this.modifierGroup.uuid);
      },
    },
    isModifierGroupRequired: function () {
      if (this.item.modifier_group_uuids_required && this.itemType == 'menuItem') {
        return this.item.modifier_group_uuids_required.includes(this.modifierGroup.uuid);
      }
      return false;
    },
    modifierGroupRequiredText: function () {
      if (this.isModifierGroupRequired) {
        return 'Combo Required';
      }
      return 'Not Combo Required';
    },
    modifierGroupRequiredIcon: function () {
      if (this.isModifierGroupRequired) {
        return 'check';
      }
      return 'close';
    },
    modifierGroupRequiredType: function () {
      if (this.isModifierGroupRequired) {
        return 'is-success';
      }
      return 'is-danger';
    },
    localizedModifierGroup: function () {
      return this.modifierGroup.locations.filter((location) => location.uuid === this.currentLocation.uuid);
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
      this.isVisible = true;
    },
  },
};
</script>

<style scoped>
.menuItem {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
