<template>
  <ValidationObserver ref="observer" tag="form" @submit.prevent="onSave" v-slot="{ changed, invalid }">
    <LocationDropdown v-model="locations" :location-root="locationRoot" />
    <ThresholdInput v-model="threshold" :currency="currency" />
    <ActionSelect v-model="action" />
    <template v-if="action === 'ADD_CHARGES'">
      <DeliveryChargesInput v-model="deliveryCharges" :article-unit="threshold.unit" :currency="currency" />
    </template>
    <button
      class="button is-fullwidth is-taster-primary"
      :class="{ 'is-loading': loading }"
      :disabled="!changed || invalid"
    >
      <span v-if="success" class="icon">
        <i class="mdi mdi-checkbox-marked-circle mdi-24px" />
      </span>
      <template v-else> Save this rule </template>
    </button>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ActionSelect from './ActionSelect.vue';
import DeliveryChargesInput from './DeliveryChargesInput.vue';
import LocationDropdown from './LocationDropdown.vue';
import ThresholdInput from './ThresholdInput.vue';

export default {
  name: 'ShippingPolicyInput',
  components: {
    ActionSelect,
    DeliveryChargesInput,
    LocationDropdown,
    ThresholdInput,
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    locationRoot: {
      type: Object,
      required: true,
      validator(value) {
        return 'locations' in value;
      },
    },
    policy: {
      type: Object,
      default: () => ({}),
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const initialLocations = this.policy.locations ? [...this.policy.locations] : [];
    const locations = initialLocations
      .map((uuid) => {
        const location = this.locationRoot.locations.find((location) => location.uuid === uuid);
        if (!location) {
          return null;
        }

        return { uuid, label: location.label };
      })
      .filter((location) => location !== null)
      .sort((left, right) => {
        const leftLabel = left.label.toUpperCase();
        const rightLabel = right.label.toUpperCase();

        if (leftLabel < rightLabel) {
          return -1;
        } else if (leftLabel > rightLabel) {
          return 1;
        }

        return 0;
      });

    return {
      action: this.policy.actions ? this.policy.actions[0] : null,
      deliveryCharges: {
        kind: this.policy.delivery_charge_kind || null,
        unit: this.policy.delivery_charge_unit || null,
        value: this.policy.delivery_charge || null,
      },
      locations,
      initialLocations,
      removedLocations: [],
      threshold: {
        type: this.policy.threshold_type || null,
        unit: this.policy.threshold_unit || null,
        value: this.policy.threshold || null,
      },
    };
  },
  computed: {
    currency() {
      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: this.locationRoot.location_country.currency_code,
      });

      return formatter
        .formatToParts()
        .filter((part) => part.type === 'currency')
        .map((part) => part.value)[0];
    },
  },
  watch: {
    locations(current) {
      const currentLocations = current.map(({ uuid }) => uuid);

      this.removedLocations = this.initialLocations.filter((uuid) => !currentLocations.includes(uuid));
    },
  },
  methods: {
    onSave() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }

        const locations = this.locations.map(({ uuid }) => uuid);

        this.$emit('shipping-policy:save', {
          ids: this.policy.ids.map(({ uuid, location_uuid }) => ({ uuid, location_uuid })),
          locations,
          removedLocations: [...this.removedLocations],
          actions: [this.action],
          threshold: this.threshold.value,
          threshold_type: this.threshold.type,
          threshold_unit: this.threshold.unit,
          delivery_charge: this.deliveryCharges.value,
          delivery_charge_kind: this.deliveryCharges.kind,
          delivery_charge_unit: this.deliveryCharges.unit || this.threshold.unit,
        });
        this.$refs.observer.reset();

        this.initialLocations = locations;
        this.removedLocations = [];
      });
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.field .label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
</style>
