<template>
  <div>
    <b-message type="is-danger" v-if="menuItemFetchError">
      {{ menuItemFetchError.body.detail }}
    </b-message>
    <b-loading :active.sync="isLoading" v-if="!menuItemFetchError" :is-full-page="false"></b-loading>
    <div class="section" v-if="menuItem && currentLocation">
      <div v-if="!getCurrentMenuItemCurrentLocation()">
        <b-message type="is-warning" v-if="!isParentLocation()">
          {{ menuItem.label }} is not available in {{ currentLocation.label }}.
        </b-message>
        <b-button
          v-if="isParentLocation()"
          icon-left="plus-circle"
          class="is-info"
          expanded
          @click="activateLocation(currentLocation.uuid)"
        >
          Create Recipe for {{ currentLocation.label }}
        </b-button>
      </div>
      <div v-else>
        <CompositionHeader
          :areLogisticsCosts="areLogisticsCostsDefined()"
          :grossFoodCost="getGrossFoodCost()"
          :kitchenPrice="getKitchenPrice()"
          :label="menuItemLocationLabel"
          :logisticsCosts="getLogisticsCosts()"
          :netFoodCost="getNetFoodCost()"
          :isKitchenPrice="isKitchenPriceDefined()"
          :isSupplierPrice="isSupplierPriceDefined()"
          :sellingPrice="getSellingPrice()"
          :sellingPriceWithoutVAT="getSellingPriceWithoutVAT()"
          :supplierPrice="getSupplierPrice()"
          :recipe="recipe"
          :itemCosts="itemCosts"
          :products="products"
          v-if="currentMenuItemInformations"
          @refreshPrice="() => refreshPrices()"
        />
        <b-tabs size="medium" type="is-toggle" expanded>
          <b-tab-item>
            <template slot="header">Recipe</template>
            <div class="columns" v-if="recipe && recipe.length">
              <div class="column is-8">
                <div class="composition-table-header">
                  <div class="name-col">Item</div>
                  <div class="info-col">Quantity</div>
                  <div class="info-col">Unit</div>
                  <div class="info-col">Supplier Price</div>
                  <div class="info-col">Logistics Costs</div>
                  <div class="info-col">Kitchen Price</div>
                </div>
                <div v-for="ingredient in orderedRecipe" :key="ingredient.item.uuid" class="composition">
                  <CompositionRow
                    :level="0"
                    :ingredient="ingredient"
                    :isSelected="itemUuid === ingredient.item.uuid"
                    :cost="itemCosts[ingredient.item.uuid]"
                    :canCompose="currentLocation.uuid == ingredient.item.location_uuid"
                    :showRemove="false"
                    @expand="
                      () =>
                        (visibility = {
                          ...visibility,
                          [ingredient.item.uuid]: visibility[ingredient.item.uuid] ? false : true,
                        })
                    "
                    @selectItem="() => setCurrentItem(null, ingredient.item.uuid)"
                    @addItem="() => openAddRecipeItemModal(ingredient.item.uuid)"
                    @removeItem="removeItemFromComposition"
                  />
                  <div v-show="visibility[ingredient.item.uuid]">
                    <CompositionItem
                      :level="1"
                      :ingredient="ingredient"
                      :selectedItemUuid="itemUuid"
                      :itemCosts="itemCosts"
                      :canCompose="currentLocation.uuid == ingredient.item.location_uuid"
                      :showRemove="ingredient.item.recipe && ingredient.item.recipe.length > 1"
                      @selectItem="setCurrentItem"
                      @addItem="openAddRecipeItemModal"
                      @removeItem="removeItemFromComposition"
                      v-if="ingredient.item.recipe"
                    />
                  </div>
                </div>
              </div>
              <ItemCard
                v-if="itemUuid"
                :itemUuid="itemUuid"
                :parentItemUuid="parentItemUuid"
                :parentItemUnit="currentParentItemUnit"
                :products="products"
                :menuItemRecipe="orderedRecipe"
                @save="updateItem"
                @remove="openRemoveItemDialog"
              />
            </div>
            <div v-else><b-message class="is-warning">No recipe found</b-message></div>
          </b-tab-item>
          <b-tab-item>
            <template slot="header">Food Costs</template>
            <CompositionFooter :menus="menus" :menuItemVariants="menuItemVariants" :menuItemCost="menuItemCost" />
          </b-tab-item>
          <b-tab-item v-if="isParentLocation()">
            <template slot="header">Master Recipe</template>
            <EditMenuItemRecipe :menuItem="menuItem" />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { displayFormatter } from '@/mixins';

import AddRecipeItem from '../Composition/AddRecipeItem.vue';
import EditMenuItemRecipe from '../MenuItem/EditMenuItemRecipe.vue';
import ComposeItem from './ComposeItem.vue';
import CompositionHeader from '@/components/Composition/CompositionHeader.vue';
import CompositionFooter from '@/components/Composition/CompositionFooter.vue';
import CompositionItem from '@/components/Composition/CompositionItem.vue';
import CompositionRow from '@/components/Composition/CompositionRow.vue';
import ItemCard from '@/components/Composition/ItemCard.vue';
import itemsAPI from '@/apis/items/items';
import costsAPI from '@/apis/pricer/costs';

export default {
  mixins: [displayFormatter],
  components: { CompositionItem, CompositionRow, CompositionFooter, CompositionHeader, ItemCard, EditMenuItemRecipe },
  data() {
    return {
      visibility: {},
      currentParentItemUnit: null,
      itemUuid: null,
      parentItemUuid: null,
      menuItemCost: null,
      itemCosts: {},
    };
  },
  watch: {
    currentLocation(newVal, oldVal) {
      if (newVal.uuid !== oldVal.uuid) {
        // Reset item card to make sure parent and item are still coherent
        this.itemUuid = null;
        this.parentItemUuid = null;
        this.itemCosts = {};
        this.menuItemCost = null;

        this.fetchRecipeInCurrentLocation();
        // this.fetchItemInformations({ itemUuid: this.$route.params.menuItemId });
      }
    },
  },
  computed: {
    ...mapState({
      menuItem: (state) => state.menuItems.current,
      currentMenuItemPrice: (state) => state.menuItems.menuItemPrices,
      currentMenuItemInformations: (state) => state.itemsInformations.all,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      currentLocation: (state) => state.locations.current,
      prices: (state) => state.summary.prices,
      products: (state) => state.products.summary,
      recipe: (state) => state.menuItems.currentRecipe,
      menuItemFetchError: (state) => state.menuItems.fetchOne.error,
      restaurants: (state) => state.restaurants.all,
      isLoading: (state) =>
        state.menuItems.fetchOne.pending ||
        state.locations.fetchAll.pending ||
        state.locations.fetchOne.pending ||
        state.menuItems.fetchRecipe.pending ||
        state.products.fetchAllSummary.pending ||
        state.gatewayMenus.fetchMenuItemVariants.pending ||
        state.gatewayMenus.fetchMenus.pending,
      menus: (state) => state.gatewayMenus.menus,
      menuItemVariants: (state) => state.gatewayMenus.menuItemVariants,
    }),
    orderedRecipe() {
      return _.orderBy(this.recipe, [(ingredient) => ingredient.item.label.toLowerCase()], ['asc']);
    },
    menuItemLocationLabel() {
      var menuItemLabel = this.menuItem.label;
      var menuItemLocationLabel = '';
      var menuItemLocations = this.currentLocation.location_tree.filter((location) =>
        this.menuItem.location_uuids.includes(location)
      );
      if (menuItemLocations.length > 0) {
        var commonLocations = this.locationsUnnested.filter((location) => location.uuid == menuItemLocations[0]);
        if (commonLocations.length > 0) {
          menuItemLocationLabel = commonLocations[0].label;
        }
      }
      return menuItemLabel.concat(`(${menuItemLocationLabel})`);
    },
  },
  methods: {
    ...mapActions('items', ['fetchOne', 'fetchRecipe', 'fetchUsage', 'removeOne', 'addLocation']),
    ...mapActions('menuItems', {
      fetchMenuItem: 'fetchOne',
      fetchMenuItemRecipe: 'fetchRecipe',
      addLocation: 'addLocation',
      computeMenuItemPriceGlobal: 'computeMenuItemPrice',
    }),
    ...mapActions('products', { fetchProduct: 'fetchOne', fetchProducts: 'fetchAllSummary' }),
    ...mapActions('restaurants', { fetchRestaurants: 'fetchAll' }),
    ...mapGetters('menuItems', ['getCurrentMenuItemCurrentLocation']),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    // ...mapActions('itemsInformations', { fetchItemInformations: 'fetchAll' }),
    ...mapActions('summary', { fetchPrices: 'fetchPrices' }),
    ...mapActions('gatewayMenus', ['getMenus', 'getMenuItemVariants']),
    computeItemPrice(ingredient) {
      this.itemCosts = {};
      costsAPI
        .compute({
          uuid: ingredient.item.uuid,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
          locationUuids: this.currentLocation.location_tree,
          type: 'ITEM',
          save: true,
        })
        .then(({ data }) => {
          var priceFormatted = {
            kitchen_currency: data.cost_details.currency,
            kitchen_price: data.cost_details.kitchen_cost,
            logistics_costs: data.cost_details.logistics_cost,
            net_price: data.net_food_cost,
            price: data.cost_details.supplier_cost,
            type: 'ITEM',
            uuid: ingredient.item.uuid,
          };
          this.$set(this.itemCosts, ingredient.item.uuid, priceFormatted);
        });
      ingredient.item.recipe && ingredient.item.recipe.map((ingredient) => this.computeItemPrice(ingredient));
    },
    computeMenuItemPrice() {
      this.menuItemCost = null;
      costsAPI
        .compute({
          uuid: this.menuItem.uuid,
          locationUuids: this.currentLocation.location_tree,
          unit: 'each',
          quantity: 1,
          type: 'MENU_ITEM',
          save: true,
        })
        .then(({ data }) => {
          var priceFormatted = {
            kitchen_currency: data.cost_details.currency,
            kitchen_price: data.cost_details.kitchen_cost,
            logistics_costs: data.cost_details.logistics_cost,
            net_price: data.net_food_cost,
            price: data.cost_details.supplier_cost,
            type: 'MENU_ITEM',
            uuid: this.menuItem.uuid,
          };
          this.menuItemCost = priceFormatted;
        });
    },
    getRecipeItems(recipe) {
      var recipeItems = [];
      for (let recipeItem of recipe) {
        recipeItems.push({
          uuid: recipeItem.item.generic_uuid,
          located_uuid: recipeItem.item.uuid,
          quantity: recipeItem.quantity,
          unit: recipeItem.unit,
          location_uuid: this.currentLocation.uuid,
          type: 'ITEM',
        });
        if (recipeItem.item.recipe) {
          recipeItems = recipeItems.concat(this.getRecipeItems(recipeItem.item.recipe));
        }
      }
      return recipeItems;
    },
    getPrices(recipeItems) {
      this.fetchPrices(recipeItems).then(() => {
        if (this.prices) {
          this.prices.map((itemPrice) => {
            if (itemPrice) {
              if (itemPrice.type == 'ITEM') {
                var recipeItem = recipeItems.find((recipeItem) => recipeItem.uuid == itemPrice.uuid);
                this.$set(this.itemCosts, recipeItem.located_uuid, itemPrice);
              } else {
                this.menuItemCost = itemPrice;
              }
            }
          });
        }
      });
    },
    refreshPrices() {
      this.computeMenuItemPrice();
      this.recipe.map((item) => this.computeItemPrice(item));
      this.setItemVisibility();
    },
    fetchRecipeInCurrentLocation() {
      var menuItemUUID = this.$route.params.menuItemId;
      this.fetchMenuItemRecipe(menuItemUUID).then(() => {
        var recipeItems = [];
        recipeItems.push({
          uuid: menuItemUUID,
          quantity: 1,
          unit: 'each',
          location_uuid: this.currentLocation.uuid,
          type: 'MENU_ITEM',
        });
        recipeItems = recipeItems.concat(this.getRecipeItems(this.recipe));
        this.getPrices(recipeItems);
        this.setItemVisibility();
      });
    },
    setItemVisibility() {
      this.recipe.forEach((ingredient) => {
        if (ingredient && ingredient.item.recipe && !this.visibility[ingredient.item.uuid]) {
          this.$set(this.visibility, ingredient.item.uuid, false);
        }
      });
    },
    updateItem() {
      this.itemUuid = null;
      this.parentItemUuid = null;
      this.fetchRecipeInCurrentLocation();
    },
    openAddRecipeItemModal(parentItemUuid) {
      this.$buefy.modal.open({
        component: AddRecipeItem,
        parent: this,
        hasModalCard: true,
        props: { parentItemUuid },
        events: {
          done: () => this.fetchRecipeInCurrentLocation(),
        },
      });
    },
    openModal(parentItemUuid) {
      this.$buefy.modal.open({
        component: ComposeItem,
        parent: this,
        hasModalCard: true,
        props: { parentItemUuid },
        events: {
          done: () => this.fetchRecipeInCurrentLocation(),
        },
      });
    },
    removeItemFromComposition(parentItemUuid, itemUuid) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Recipe Item',
        message: `Are you sure you want to <b>delete</b> a Recipe Element?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          itemsAPI
            .removeChild(parentItemUuid, { location_uuid: this.currentLocation.uuid, child_uuid: itemUuid })
            .then(() => this.fetchRecipeInCurrentLocation());
        },
      });
    },
    setCurrentItem(parentIngredient, itemUuid) {
      this.currentParentItemUnit = parentIngredient ? parentIngredient.unit : null;
      this.parentItemUuid = parentIngredient ? parentIngredient.item.uuid : null;
      this.itemUuid = itemUuid;
    },
    // getItemPriceInformations(menuItem) {
    //   var currentMenuItemInformationsWithPrices = this.currentMenuItemInformations.filter(
    //     (info) => info.selling_price && info.selling_unit_price
    //   );
    //   const initialValue = {};
    //   const itemsInformations = currentMenuItemInformationsWithPrices.reduce((obj, item) => {
    //     return {
    //       ...obj,
    //       [item['item_uuid']]: item,
    //     };
    //   }, initialValue);
    //   if (!itemsInformations[menuItem.uuid]) {
    //     return {
    //       selling_price: 0,
    //       selling_unit_price: 0,
    //     };
    //   } else {
    //     return {
    //       selling_price: itemsInformations[menuItem.uuid].selling_price,
    //       selling_unit_price: itemsInformations[menuItem.uuid].selling_unit_price,
    //     };
    //   }
    // },
    // getCurrentMenuItemVAT() {
    // var correspondingRestaurants = this.restaurants
    //   .filter((restaurant) => this.menuItem.concept_uuids.includes(restaurant.concept_uuid))
    //   .filter((restaurant) =>
    //     restaurant.kitchen_restaurant.kitchen_location.location_tree.includes(this.currentLocation.uuid)
    //   );
    // if (correspondingRestaurants.length > 0) {
    //   return correspondingRestaurants[0].restaurant_information.vat / 100;
    // }
    // return 0;
    // },
    openRemoveItemDialog(item) {
      var itemLocation = this.getLocation(item);
      this.$buefy.dialog.confirm({
        title: 'Deleting item',
        message: `Are you sure you want to <b>delete</b> ${item.label}'s recipe in ${itemLocation.id}?`,
        confirmText: 'Delete item',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.removeOne(item.uuid).then(() => this.updateItem());
        },
      });
    },
    getLocation(item) {
      var locations = this.locationsUnnested.filter((location) => location.uuid == item.location_uuid);
      if (locations) {
        return locations[0];
      }
      return '';
    },
    getSellingPrice() {
      // var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      // return `${this.getSellingPriceValue()} ${deviseSign}`;
      return '';
    },
    getSellingPriceWithoutVAT() {
      // var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      // return `${(this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT())).toFixed(2)} ${deviseSign}`;
      return '';
    },
    getSellingPriceValue() {
      // return this.getItemPriceInformations(this.menuItem).selling_price;
      return '';
    },
    // getMargin() {
    //   // return `${Math.round( (this.price * (1 - this.getCurrentMenuItemVAT()) - this.menuItemCost.supplier_price) * 100 / (this.price * (1 - this.getCurrentMenuItemVAT())) )}%`
    //   return `${Math.round(
    //     ((this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()) - this.menuItemCost.price) * 100) /
    //       (this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()))
    //   )}%`;
    // },
    getGrossFoodCost() {
      // if (this.menuItemCost) {
      //   return `${Math.round(
      //     100 -
      //       ((this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()) - this.menuItemCost.kitchen_price) *
      //         100) /
      //         (this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()))
      //   )}%`;
      // }
      return '';
    },
    getNetFoodCost() {
      // if (this.menuItemCost) {
      //   return `${Math.round(
      //     100 -
      //       ((this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()) - this.menuItemCost.net_price) * 100) /
      //         (this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()))
      //   )}%`;
      // }
      return '';
    },
    isSupplierPriceDefined() {
      if (this.menuItemCost && (this.menuItemCost.price || this.menuItemCost.price === 0)) {
        return true;
      }
      return false;
    },
    getSupplierPrice() {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (this.menuItemCost && this.menuItemCost.price) {
        return `${this.menuItemCost.price.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    isKitchenPriceDefined() {
      if (this.menuItemCost && (this.menuItemCost.kitchen_price || this.menuItemCost.kitchen_price === 0)) {
        return true;
      }
      return false;
    },
    getKitchenPrice() {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (this.menuItemCost && this.menuItemCost.kitchen_price) {
        return `${this.menuItemCost.kitchen_price.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    areLogisticsCostsDefined() {
      if (this.menuItemCost && (this.menuItemCost.logistics_costs || this.menuItemCost.logistics_costs === 0)) {
        return true;
      }
      return false;
    },
    getLogisticsCosts() {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (this.menuItemCost && this.menuItemCost.logistics_costs) {
        return `${this.menuItemCost.logistics_costs.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getMenusWithCache() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.gatewayMenus.menus)) {
        return this.getMenus();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    activateLocation(locationUuid) {
      if (this.menuItem.recipe && this.menuItem.recipe.length > 0) {
        this.computeMenuItemPriceGlobal({
          uuid: this.menuItem.uuid,
          locationUuids: this.currentLocation.location_tree,
          quantity: 1,
          unit: 'each',
          save: false,
        }).then(() => {
          if (
            this.currentMenuItemPrice &&
            this.currentMenuItemPrice.cost_details &&
            this.currentMenuItemPrice.cost_details.kitchen_cost != null &&
            this.currentMenuItemPrice.cost_details.supplier_cost != null
          ) {
            this.addLocation(locationUuid);
          } else {
            this.$buefy.dialog.confirm({
              title: `Adding ${this.menuItem.label}`,
              message: `You can not <b>add</b> this ${this.menuItem.label}: <br> ${this.getAlertMessage()}`,
              type: 'is-danger',
              hasIcon: true,
            });
          }
        });
      } else {
        this.addLocation(locationUuid);
      }
    },
    getAlertMessage() {
      if (this.currentMenuItemPrice == null || this.currentMenuItemPrice.cost_details == null) {
        return `Price can not be computed or is not computed yet`;
      }
      if (this.currentMenuItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price can not be computed in ${this.currentLocation.label}`;
      }
      if (this.currentMenuItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price can not be computed in ${this.currentLocation.label}`;
      }
      return `Price Issue`;
    },
  },
  mounted() {
    this.fetchMenuItem(this.$route.params.menuItemId).then(() => this.fetchRecipeInCurrentLocation());
    // this.fetchItemInformations({ itemUuid: this.$route.params.menuItemId });
    // if (this.$store.state.restaurants.all === null || this.$store.state.restaurants.all.length == 0) {
    //   this.fetchRestaurants();
    // }
    if (this.$store.state.locations.all === null || this.$store.state.locations.all.length == 0) {
      this.fetchAllLocations();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      this.fetchUnnestedLocations();
    }
    if (this.$store.state.products.summary === null || this.$store.state.products.summary.length == 0) {
      this.fetchProducts();
    }
    if (
      this.$store.state.gatewayMenus.menuItemVariants === null ||
      this.$store.state.gatewayMenus.menuItemVariants.length == 0
    ) {
      this.getMenuItemVariants({ menu_item_uuid: this.$route.params.menuItemId });
    }
    this.getMenusWithCache();
  },
};
</script>

<style scoped>
.composition-table-header {
  font-weight: bold;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
}
.name-col {
  width: 80%;
}
.info-col {
  text-align: center;
  width: 100px;
  padding: 4px;
}
</style>
