<template>
  <div>
    <div class="is-flex header">
      <img :src="concept.logo" class="header-logo" />
      <h1 class="title">{{ concept.label }}</h1>
    </div>
    <div v-if="activeTab == 1">
      <div class="title is-4">Menu Versions</div>
      <b-taglist>
        <b-tag type="is-warning" v-for="(menu, index) in menuVersions" :key="index">{{ menu }}</b-tag>
      </b-taglist>
      <hr />
    </div>
    <b-tabs size="medium" type="is-boxed" vertical v-model="activeTab">
      <b-tab-item label="General">
        <div class="container">
          <!-- Concept Info Form -->
          <ConceptForm :concept.sync="concept" v-if="concept.uuid" :locations="locations" @saveConcept="saveConcept" />
          <!-- Concept Logo and Photo Heroes -->
          <ConceptPictures v-if="concept.uuid" :concept="concept" />
        </div>
      </b-tab-item>
      <b-tab-item label="Menu">
        <div class="container">
          <!-- Menu -->
          <MenuDetails v-if="concept.uuid" />
        </div>
      </b-tab-item>
      <b-tab-item label="Restaurants">
        <div class="container">
          <!-- Restaurants -->
          <RestaurantsTable v-if="concept.uuid" />
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { conceptsTypes } from '@/store/mutation-types';

import ConceptForm from '@/components/Menu/Concept/ConceptForm.vue';
import ConceptPictures from '@/components/Menu/Concept/ConceptPictures.vue';
import MenuDetails from '@/components/Menu/MenuDetails/MenuDetails.vue';
import RestaurantsTable from '@/components/Menu/Restaurants/RestaurantsTable.vue';

export default {
  name: 'EditMenu',
  components: {
    ConceptForm,
    ConceptPictures,
    MenuDetails,
    RestaurantsTable,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapState({
      allGenericMenus: (state) => state.menus.allGeneric,
      currentLocation: (state) => state.locations.current,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      currentConcept: (state) => state.concepts.current,
    }),
    concept: {
      get() {
        return { ...this.currentConcept };
      },
      set(value) {
        this.setCurrentConcept(value);
      },
    },
    menuVersions() {
      var menuVersions = [];
      var conceptMenuGeneric = this.allGenericMenus.find(
        (menuGeneric) => menuGeneric.concept_uuid == this.concept.uuid
      );
      if (conceptMenuGeneric) {
        for (let locationUUID of conceptMenuGeneric.location_uuids) {
          var location = this.locationsUnnested.find((location) => location.uuid == locationUUID);
          if (
            location &&
            location.location_tree[location.location_tree.length - 1] ==
              this.currentLocation.location_tree[this.currentLocation.location_tree.length - 1]
          ) {
            menuVersions.push(location.label);
          }
        }
        menuVersions.sort();
      }
      return menuVersions;
    },
  },
  methods: {
    ...mapActions('concepts', { saveConcept: 'updateOne' }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
  },
};
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  padding: 20px 0;

  .header-logo {
    height: 64px;
    width: auto;
    margin-right: 25px;
  }
}

.container {
  padding: 10px 20px;
}
</style>
