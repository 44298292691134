import StackdriverErrorReporter from 'stackdriver-errors-js';
const errorHandler = new StackdriverErrorReporter();
const key = process.env.VUE_APP_STACKDRIVER_KEY;
const projectId = process.env.VUE_APP_STACKDRIVER_PROJECT_ID;
const service = process.env.VUE_APP_NAME;
const version = process.env.VUE_APP_VERSION;
const enabled = process.env.VUE_APP_STACKDRIVER_ENABLED;
errorHandler.start({
  key: key,
  projectId: projectId,
  service: service,
  version: version,
  reportUncaughtExceptions: true,
  reportUnhandledPromiseRejections: true,
  disabled: enabled !== 'true',
});
export default {
  install: function (Vue) {
    Vue.config.errorHandler = function (err) {
      errorHandler.report(err);
    };
    Vue.prototype.$errorReporting = errorHandler;
  },
};
