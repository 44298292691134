<template>
  <div class="container">
    <div class="block">
      <b-dropdown
        v-model="menuSelected"
        aria-role="list"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        v-if="itemType == 'menuItem'"
      >
        <button class="button is-link" type="button" slot="trigger">
          <span> Menu </span>
          <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item v-for="(menu, index) in menusWithAll" :key="index" :value="menu" aria-role="listitem">
          <span> {{ menu.label }} </span>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        v-model="selectedCategories"
        aria-role="list"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        multiple
      >
        <button class="button" type="button" slot="trigger">
          <span> Category </span>
          <b-icon icon="menu-down"></b-icon>
        </button>
        <b-dropdown-item
          v-for="(category, index) in itemCategories"
          :key="index"
          :value="category"
          aria-role="listitem"
        >
          <span> {{ category }} </span>
        </b-dropdown-item>
      </b-dropdown>
      <b-button v-if="filteredItems" disabled style="margin-left: 40px" type="is-text">
        {{ filteredItems.length }} Recipes
      </b-button>
    </div>
    <b-field>
      <b-input size="is-medium" expanded rounded v-model="searchTerm" placeholder="Search for a Recipe..."></b-input>
    </b-field>
    <b-table
      @click="(row) => openItemPage(row)"
      class="is-fullwidth"
      hoverable
      striped
      :data="filteredItems"
      paginated
      per-page="10"
      default-sort-direction="asc"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-if="all"
    >
      <template slot-scope="props">
        <b-table-column field="label" label="Label" sortable>
          {{ props.row.label }}
        </b-table-column>
        <b-table-column label="Type" field="item_type" sortable>
          <b-tag v-if="itemType == 'menuItem'">{{ props.row.category }}</b-tag>
          <b-tag v-else>{{ props.row.item_type }}</b-tag>
        </b-table-column>
        <b-table-column>
          <b-field grouped v-if="all.find((item) => item.uuid === props.row.uuid)"> </b-field>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import lunr from 'lunr';
import { mapState, mapActions, mapMutations } from 'vuex';
import { auth, errorToasterDetailled } from '@/mixins';

import { itemsTypes, menusTypes } from '@/store/mutation-types';
import EditItem from './EditItem.vue';

export default {
  mixins: [auth, errorToasterDetailled],
  props: ['all', 'concepts', 'itemType', 'menus', 'menuItemsLibrary'],
  data: () => ({
    selectedConcept: null,
    selectedCategories: [],
    menuSelected: { label: 'All', uuid: null },
    searchIndex: null,
    maxHeight: 200,
    isScrollable: true,
  }),
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      searchFilters: (state) => state.items.searchFilters,
      conceptLocationMenuItems: (state) => state.menus.conceptLocationMenuItems,
      currentConceptLocationMenuItems: (state) => state.menus.currentConceptLocationMenuItems,
    }),
    itemCategories() {
      var categories = [];
      if (this.itemType == 'menuItem') {
        categories = this.all.map((item) => item.category);
      } else {
        categories = this.all.map((item) => item.item_type);
      }
      categories = [...new Set(categories)];
      categories.sort();
      return categories;
    },
    searchTerm: {
      get() {
        return this.searchFilters.term;
      },
      set(value) {
        this.setSearchFilters({ ...this.searchFilters, term: value });
      },
    },
    menusWithAll() {
      return [
        { label: 'All', uuid: null },
        { label: 'Not on Menu', uuid: 'null' },
      ].concat(this.menus);
    },
    filteredItems() {
      var recipes = [];
      if (!this.all) {
        return [];
      }
      if (this.searchIndex && this.searchTerm) {
        var searchTermModified = this.searchTerm;
        searchTermModified = searchTermModified.replaceAll('-', ' ');
        var results = this.searchIndex.search(`${searchTermModified}`);
        results.forEach((d) => {
          this.all.forEach((p) => {
            if (d.ref == p.uuid) {
              recipes.push(p);
            }
          });
        });
        if (recipes.length == 0) {
          recipes = [...this.all].filter((item) => item.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
        }
      } else {
        recipes = [...this.all];
      }
      if (this.itemType == 'menuItem') {
        if (this.selectedConcept) {
          recipes = recipes.filter((item) => item.concept_uuids && item.concept_uuids.includes(this.selectedConcept));
        }
        if (this.selectedCategories.length > 0) {
          recipes = recipes.filter((item) => this.selectedCategories.includes(item.category));
        }
        if (this.menuSelected && this.menuSelected.uuid) {
          var menuItemsFromMenu = this.menuItemsLibrary;
          if (this.menuSelected.uuid != 'null') {
            menuItemsFromMenu = this.menuItemsLibrary
              .filter((item) => item.menus && item.menus.map((menu) => menu.menu_uuid).includes(this.menuSelected.uuid))
              .map((item) => item.menu_item_uuid);
          } else {
            menuItemsFromMenu = this.menuItemsLibrary
              .filter((item) => item.menus.length == 0)
              .map((item) => item.menu_item_uuid);
          }
          recipes = recipes.filter((item) => menuItemsFromMenu.includes(item.uuid));
        }
      } else {
        if (this.selectedCategories.length > 0) {
          recipes = recipes.filter((item) => this.selectedCategories.includes(item.item_type));
        }
      }
      return recipes.sort((recipe1, recipe2) => recipe1.label.localeCompare(recipe2.label));
    },
  },
  methods: {
    ...mapActions('items', {
      fetchAllItems: 'fetchAll',
      updateItem: 'updateOne',
      fetchItem: 'fetchOne',
      removeItem: 'removeOne',
    }),
    ...mapActions('menuItems', { fetchAllMenuItems: 'fetchAll' }),
    ...mapMutations('items', {
      setCurrentItem: itemsTypes.SET_CURRENT,
      setSearchFilters: itemsTypes.SET_SEARCH_FILTERS,
    }),
    ...mapMutations('menus', {
      setCurrentConceptLocationMenuItems: menusTypes.SET_CURRENT_CONCEPT_LOCATION_MENU_ITEMS,
    }),
    buildIndex(documents) {
      this.searchIndex = lunr(function () {
        this.ref('uuid');
        this.field('label');
        documents.forEach((doc) => {
          this.add(doc);
        });
      });
    },
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    openEditItemModal(item) {
      this.$buefy.modal.open({
        component: EditItem,
        parent: this,
        hasModalCard: true,
        props: { id: item.uuid },
        events: {
          close: () => this.fetchAllItems(),
        },
      });
    },
    openRemoveItemDialog(item) {
      this.$buefy.dialog.confirm({
        title: 'Deleting item',
        message: `Are you sure you want to <b>delete</b> ${item.label}?`,
        confirmText: 'Delete item',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeItem(item.uuid),
      });
    },
    openItemPage(item) {
      let route = '';
      if (item.recipe) {
        route = '/composition/' + item.uuid;
      } else {
        route = '/items/' + item.uuid;
      }
      var routeData = this.$router.resolve(route);
      window.open(routeData.href, '_blank');
    },
  },
  async mounted() {
    this.buildIndex(this.all);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}
</style>
