<template>
  <div>
    <MenuItemPlatformForm
      @save="updateLocation"
      @add="(platforms, concept) => add(platforms, currentLocation.uuid, concept)"
      @addPlatformInformations="
        (platforms, conceptUuid) => addPlatformInformations(platforms, conceptUuid, currentLocation.uuid)
      "
      @remove="remove"
      @update:location="setCurrentMenuItemLocation"
      :location="getLocationByUuid(currentLocation.uuid)"
      :currentMenuItem="currentMenuItem"
      :currentMenuItemInformations="currentMenuItemInformations"
      :currentUuid="currentMenuItem.uuid"
      :currentLocation="currentLocation"
      :currentConcept="currentConcept"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import { menuItemsTypes } from '@/store/mutation-types';
import MenuItemPlatformForm from '@/components/MenuItem/Platform/MenuItemPlatformForm.vue';

export default {
  components: { MenuItemPlatformForm },
  data() {
    return {
      currentIndex: 0,
      newLocation: {},
    };
  },
  computed: {
    ...mapState({
      currentConcept: (state) => state.concepts.current,
      currentLocation: (state) => state.locations.current,
      currentMenuItem: (state) => state.menuItems.current,
      currentMenuItemPrice: (state) => state.menuItems.menuItemPrices,
      currentMenuItemInformations: (state) => state.itemsInformations.all,
    }),
    ...mapGetters('menuItems', ['getLocationByUuid']),
  },
  methods: {
    ...mapActions('itemsInformations', ['createOne', 'removeOne']),
    ...mapActions('menuItems', ['addLocation', 'removeLocation', 'updateLocation', 'computeMenuItemPrice']),
    ...mapMutations('menuItems', {
      setCurrentMenuItem: menuItemsTypes.SET_CURRENT,
      setCurrentMenuItemLocation: menuItemsTypes.SET_CURRENT_LOCATION,
    }),
    addPlatformInformations(platforms, conceptUuid, locationUuid) {
      var existingInformations = this.currentMenuItemInformations
        .filter(
          (menuItemInformation) =>
            menuItemInformation.concept_uuid == conceptUuid && menuItemInformation.location_uuid == locationUuid
        )
        .map((itemInformation) => itemInformation.platform_uuid);
      var platformsToCreate = platforms.filter((platform) => !existingInformations.includes(platform.uuid));
      return Promise.all(
        platformsToCreate.map((platform) =>
          this.createOne({
            concept_uuid: conceptUuid,
            item_uuid: this.currentMenuItem.uuid,
            location_uuid: locationUuid,
            platform_uuid: platform.uuid,
            selling_price: 0.0,
            selling_unit_price: this.currentLocation.location_country.currency,
          })
        )
      );
    },
    add(platforms, locationUuid, concept) {
      if (this.currentMenuItem.recipe && this.currentMenuItem.recipe.length > 0) {
        this.computeMenuItemPrice({
          uuid: this.currentMenuItem.uuid,
          locationUuids: this.currentLocation.location_tree,
          quantity: 1,
          unit: 'each',
          save: false,
        }).then(() => {
          if (
            this.currentMenuItemPrice &&
            this.currentMenuItemPrice.cost_details &&
            this.currentMenuItemPrice.cost_details.kitchen_cost != null &&
            this.currentMenuItemPrice.cost_details.supplier_cost != null
          ) {
            this.addPlatformInformations(platforms, concept.uuid, locationUuid).then(() =>
              this.addLocation(locationUuid)
            );
          } else {
            this.$buefy.dialog.confirm({
              title: `Adding ${this.currentMenuItem.label}`,
              message: `You can not <b>add</b> this ${this.currentMenuItem.label}: <br> ${this.getAlertMessage()}`,
              type: 'is-danger',
              hasIcon: true,
            });
          }
        });
      } else {
        this.addPlatformInformations(platforms, concept.uuid, locationUuid).then(() => this.addLocation(locationUuid));
      }
    },
    getAlertMessage() {
      if (this.currentMenuItemPrice == null || this.currentMenuItemPrice.cost_details == null) {
        return `Price can not be computed or is not computed yet`;
      }
      if (this.currentMenuItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price can not be computed in ${this.currentLocation.label}`;
      }
      if (this.currentMenuItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price can not be computed in ${this.currentLocation.label}`;
      }
      return `Price Issue`;
    },
    removePlatformInformations(locationUuid) {
      var existingInformations = this.currentMenuItemInformations.filter(
        (menuItemInformation) => menuItemInformation.location_uuid == locationUuid
      );
      return Promise.all(existingInformations.map((information) => this.removeOne(information)));
    },
    remove(locationUuid) {
      this.removePlatformInformations(locationUuid).then(() => this.removeLocation(locationUuid));
    },
  },
};
</script>
