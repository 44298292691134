export const conceptsTypes = {
  SET_CURRENT_CONCEPT: 'SET_CURRENT_CONCEPT',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
};

export const productsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ALL_SUMMARY_REQUEST: 'FETCH_ALL_SUMMARY_REQUEST',
  FETCH_ALL_SUMMARY_SUCCESS: 'FETCH_ALL_SUMMARY_SUCCESS',
  FETCH_ALL_SUMMARY_FAILURE: 'FETCH_ALL_SUMMARY_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  FETCH_USAGE_REQUEST: 'FETCH_USAGE_REQUEST',
  FETCH_USAGE_SUCCESS: 'FETCH_USAGE_SUCCESS',
  FETCH_USAGE_FAILURE: 'FETCH_USAGE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  REMOVE_ONE: 'REMOVE_ONE',
  SET_CURRENT: 'SET_CURRENT',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  CREATE_PRODUCT_LOCALIZED_INFO_REQUEST: 'CREATE_PRODUCT_LOCALIZED_INFO_REQUEST',
  CREATE_PRODUCT_LOCALIZED_INFO_SUCCESS: 'CREATE_PRODUCT_LOCALIZED_INFO_SUCCESS',
  CREATE_PRODUCT_LOCALIZED_INFO_FAILURE: 'CREATE_PRODUCT_LOCALIZED_INFO_FAILURE',
  UPDATE_PRODUCT_LOCALIZED_INFO_REQUEST: 'UPDATE_PRODUCT_LOCALIZED_INFO_REQUEST',
  UPDATE_PRODUCT_LOCALIZED_INFO_SUCCESS: 'UPDATE_PRODUCT_LOCALIZED_INFO_SUCCESS',
  UPDATE_PRODUCT_LOCALIZED_INFO_FAILURE: 'UPDATE_PRODUCT_LOCALIZED_INFO_FAILURE',
  REMOVE_PRODUCT_LOCALIZED_INFO_REQUEST: 'REMOVE_PRODUCT_LOCALIZED_INFO_REQUEST',
  REMOVE_PRODUCT_LOCALIZED_INFO_SUCCESS: 'REMOVE_PRODUCT_LOCALIZED_INFO_SUCCESS',
  REMOVE_PRODUCT_LOCALIZED_INFO_FAILURE: 'REMOVE_PRODUCT_LOCALIZED_INFO_FAILURE',
  COMPUTE_PRICE_PRODUCT_REQUEST: 'COMPUTE_PRICE_PRODUCT_REQUEST',
  COMPUTE_PRICE_PRODUCT_SUCCESS: 'COMPUTE_PRICE_PRODUCT_SUCCESS',
  COMPUTE_PRICE_PRODUCT_FAILURE: 'COMPUTE_PRICE_PRODUCT_FAILURE',
};

export const locationsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ALL_UNNESTED_REQUEST: 'FETCH_ALL_UNNESTED_REQUEST',
  FETCH_ALL_UNNESTED_SUCCESS: 'FETCH_ALL_UNNESTED_SUCCESS',
  FETCH_ALL_UNNESTED_FAILURE: 'FETCH_ALL_UNNESTED_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
};

export const combosTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  ADD_CONCEPT_REQUEST: 'ADD_CONCEPT_REQUEST',
  ADD_CONCEPT_SUCCESS: 'ADD_CONCEPT_SUCCESS',
  ADD_CONCEPT_FAILURE: 'ADD_CONCEPT_FAILURE',
  REMOVE_CONCEPT_REQUEST: 'REMOVE_CONCEPT_REQUEST',
  REMOVE_CONCEPT_SUCCESS: 'REMOVE_CONCEPT_SUCCESS',
  REMOVE_CONCEPT_FAILURE: 'REMOVE_CONCEPT_FAILURE',
  ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  REMOVE_LOCATION_REQUEST: 'REMOVE_LOCATION_REQUEST',
  REMOVE_LOCATION_SUCCESS: 'REMOVE_LOCATION_SUCCESS',
  REMOVE_LOCATION_FAILURE: 'REMOVE_LOCATION_FAILURE',
  UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
  FETCH_MODIFIER_GROUPS_REQUEST: 'FETCH_MODIFIER_GROUPS_REQUEST',
  FETCH_MODIFIER_GROUPS_SUCCESS: 'FETCH_MODIFIER_GROUPS_SUCCESS',
  FETCH_MODIFIER_GROUPS_FAILURE: 'FETCH_MODIFIER_GROUPS_FAILURE',
  ADD_MODIFIER_GROUP_REQUEST: 'ADD_MODIFIER_GROUP_REQUEST',
  ADD_MODIFIER_GROUP_SUCCESS: 'ADD_MODIFIER_GROUP_SUCCESS',
  ADD_MODIFIER_GROUP_FAILURE: 'ADD_MODIFIER_GROUP_FAILURE',
  REMOVE_MODIFIER_GROUP_REQUEST: 'REMOVE_MODIFIER_GROUP_REQUEST',
  REMOVE_MODIFIER_GROUP_SUCCESS: 'REMOVE_MODIFIER_GROUP_SUCCESS',
  REMOVE_MODIFIER_GROUP_FAILURE: 'REMOVE_MODIFIER_GROUP_FAILURE',
  SET_CURRENT: 'SET_CURRENT',
  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
  UPLOAD_PICTURE_REQUEST: 'UPLOAD_PICTURE_REQUEST',
  UPLOAD_PICTURE_SUCCESS: 'UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_FAILURE: 'UPLOAD_PICTURE_FAILURE',
  FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',
  UPDATE_CURRENT_MODIFIER_GROUPS: 'UPDATE_CURRENT_MODIFIER_GROUPS',
};

export const modifierGroupsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  REMOVE_LOCATION_REQUEST: 'REMOVE_LOCATION_REQUEST',
  REMOVE_LOCATION_SUCCESS: 'REMOVE_LOCATION_SUCCESS',
  REMOVE_LOCATION_FAILURE: 'REMOVE_LOCATION_FAILURE',
  UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
  SET_CURRENT: 'SET_CURRENT',
};

export const itemsTypes = {
  SET_CURRENT: 'SET_CURRENT',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  FETCH_RECIPE_REQUEST: 'FETCH_RECIPE_REQUEST',
  FETCH_RECIPE_SUCCESS: 'FETCH_RECIPE_SUCCESS',
  FETCH_RECIPE_FAILURE: 'FETCH_RECIPE_FAILURE',
  FETCH_USAGE_REQUEST: 'FETCH_USAGE_REQUEST',
  FETCH_USAGE_SUCCESS: 'FETCH_USAGE_SUCCESS',
  FETCH_USAGE_FAILURE: 'FETCH_USAGE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  REMOVE_ONE_REQUEST: 'REMOVE_ONE_REQUEST',
  REMOVE_ONE_SUCCESS: 'REMOVE_ONE_SUCCESS',
  REMOVE_ONE_FAILURE: 'REMOVE_ONE_FAILURE',
  ADD_CHILD_REQUEST: 'ADD_CHILD_REQUEST',
  ADD_CHILD_SUCCESS: 'ADD_CHILD_SUCCESS',
  ADD_CHILD_FAILURE: 'ADD_CHILD_FAILURE',
  UPDATE_CHILD_REQUEST: 'UPDATE_CHILD_REQUEST',
  UPDATE_CHILD_SUCCESS: 'UPDATE_CHILD_SUCCESS',
  UPDATE_CHILD_FAILURE: 'UPDATE_CHILD_FAILURE',
  SET_PRODUCT_REQUEST: 'SET_PRODUCT_REQUEST',
  SET_PRODUCT_SUCCESS: 'SET_PRODUCT_SUCCESS',
  SET_PRODUCT_FAILURE: 'SET_PRODUCT_FAILURE',
  UNSET_PRODUCT_REQUEST: 'UNSET_PRODUCT_REQUEST',
  UNSET_PRODUCT_SUCCESS: 'UNSET_PRODUCT_SUCCESS',
  UNSET_PRODUCT_FAILURE: 'UNSET_PRODUCT_FAILURE',
  ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  CREATE_LOCALIZED_ITEM_REQUEST: 'CREATE_LOCALIZED_ITEM_REQUEST',
  CREATE_LOCALIZED_ITEM_SUCCESS: 'CREATE_LOCALIZED_ITEM_SUCCESS',
  CREATE_LOCALIZED_ITEM_FAILURE: 'CREATE_LOCALIZED_ITEM_FAILURE',
  COMPUTE_PRICE_ITEM_REQUEST: 'COMPUTE_PRICE_ITEM_REQUEST',
  COMPUTE_PRICE_ITEM_SUCCESS: 'COMPUTE_PRICE_ITEM_SUCCESS',
  COMPUTE_PRICE_ITEM_FAILURE: 'COMPUTE_PRICE_ITEM_FAILURE',
};

export const menuItemsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  SET_MENU_ITEMS: 'SET_MENU_ITEMS',
  REMOVE_MENU_ITEMS: 'REMOVE_MENU_ITEMS',
  ADD_MENU_ITEM_REQUEST: 'ADD_MENU_ITEM_REQUEST',
  ADD_MENU_ITEM_SUCCESS: 'ADD_MENU_ITEM_SUCCESS',
  ADD_MENU_ITEM_FAILURE: 'ADD_MENU_ITEM_FAILURE',
  CREATE_MENU_ITEM: 'CREATE_MENU_ITEM',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  FETCH_MODIFIER_GROUPS_REQUEST: 'FETCH_MODIFIER_GROUPS_REQUEST',
  FETCH_MODIFIER_GROUPS_SUCCESS: 'FETCH_MODIFIER_GROUPS_SUCCESS',
  FETCH_MODIFIER_GROUPS_FAILURE: 'FETCH_MODIFIER_GROUPS_FAILURE',
  ADD_MODIFIER_GROUP_REQUEST: 'ADD_MODIFIER_GROUP_REQUEST',
  ADD_MODIFIER_GROUP_SUCCESS: 'ADD_MODIFIER_GROUP_SUCCESS',
  ADD_MODIFIER_GROUP_FAILURE: 'ADD_MODIFIER_GROUP_FAILURE',
  UPDATE_MODIFIER_GROUP_REQUEST: 'UPDATE_MODIFIER_GROUP_REQUEST',
  UPDATE_MODIFIER_GROUP_SUCCESS: 'UPDATE_MODIFIER_GROUP_SUCCESS',
  UPDATE_MODIFIER_GROUP_FAILURE: 'UPDATE_MODIFIER_GROUP_FAILURE',
  REMOVE_MODIFIER_GROUP_REQUEST: 'REMOVE_MODIFIER_GROUP_REQUEST',
  REMOVE_MODIFIER_GROUP_SUCCESS: 'REMOVE_MODIFIER_GROUP_SUCCESS',
  REMOVE_MODIFIER_GROUP_FAILURE: 'REMOVE_MODIFIER_GROUP_FAILURE',
  FETCH_RECIPE_REQUEST: 'FETCH_RECIPE_REQUEST',
  FETCH_RECIPE_SUCCESS: 'FETCH_RECIPE_SUCCESS',
  FETCH_RECIPE_FAILURE: 'FETCH_RECIPE_FAILURE',
  UPDATE_RECIPE_ITEM_REQUEST: 'UPDATE_RECIPE_ITEM_REQUEST',
  UPDATE_RECIPE_ITEM_SUCCESS: 'UPDATE_RECIPE_ITEM_SUCCESS',
  UPDATE_RECIPE_ITEM_FAILURE: 'UPDATE_RECIPE_ITEM_FAILURE',
  REMOVE_RECIPE_ITEM_REQUEST: 'REMOVE_RECIPE_ITEM_REQUEST',
  REMOVE_RECIPE_ITEM_SUCCESS: 'REMOVE_RECIPE_ITEM_SUCCESS',
  REMOVE_RECIPE_ITEM_FAILURE: 'REMOVE_RECIPE_ITEM_FAILURE',
  ADD_RECIPE_ITEM_REQUEST: 'ADD_RECIPE_ITEM_REQUEST',
  ADD_RECIPE_ITEM_SUCCESS: 'ADD_RECIPE_ITEM_SUCCESS',
  ADD_RECIPE_ITEM_FAILURE: 'ADD_RECIPE_ITEM_FAILURE',
  ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',
  REMOVE_LOCATION_REQUEST: 'REMOVE_LOCATION_REQUEST',
  REMOVE_LOCATION_SUCCESS: 'REMOVE_LOCATION_SUCCESS',
  REMOVE_LOCATION_FAILURE: 'REMOVE_LOCATION_FAILURE',
  UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
  FETCH_RECIPE_ITEMS_SUCCESS: 'FETCH_RECIPE_ITEMS_SUCCESS',
  FETCH_TODOS: 'FETCH_TODOS',
  SET_CURRENT: 'SET_CURRENT',
  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  UPLOAD_PICTURE_REQUEST: 'UPLOAD_PICTURE_REQUEST',
  UPLOAD_PICTURE_SUCCESS: 'UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_FAILURE: 'UPLOAD_PICTURE_FAILURE',
  FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',
  FETCH_TAGS_REQUEST: 'FETCH_TAGS_REQUEST',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAILURE: 'FETCH_TAGS_FAILURE',
  FETCH_USAGE_REQUEST: 'FETCH_USAGE_REQUEST',
  FETCH_USAGE_SUCCESS: 'FETCH_USAGE_SUCCESS',
  FETCH_USAGE_FAILURE: 'FETCH_USAGE_FAILURE',
  UPDATE_CURRENT_MODIFIER_GROUPS: 'UPDATE_CURRENT_MODIFIER_GROUPS',
  COMPUTE_PRICE_MENU_ITEM_REQUEST: 'COMPUTE_PRICE_MENU_ITEM_REQUEST',
  COMPUTE_PRICE_MENU_ITEM_SUCCESS: 'COMPUTE_PRICE_MENU_ITEM_SUCCESS',
  COMPUTE_PRICE_MENU_ITEM_FAILURE: 'COMPUTE_PRICE_MENU_ITEM_FAILURE',
};

export const suppliersTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  FETCH_ONE_LOCALIZED_INFORMATION_REQUEST: 'FETCH_ONE_LOCALIZED_INFORMATION_REQUEST',
  FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS: 'FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS',
  FETCH_ONE_LOCALIZED_INFORMATION_FAILURE: 'FETCH_ONE_LOCALIZED_INFORMATION_FAILURE',
  CREATE_LOCALIZED_INFORMATION_REQUEST: 'CREATE_LOCALIZED_INFORMATION_REQUEST',
  CREATE_LOCALIZED_INFORMATION_SUCCESS: 'CREATE_LOCALIZED_INFORMATION_SUCCESS',
  CREATE_LOCALIZED_INFORMATION_FAILURE: 'CREATE_LOCALIZED_INFORMATION_FAILURE',
  UPDATE_LOCALIZED_INFORMATION_REQUEST: 'UPDATE_LOCALIZED_INFORMATION_REQUEST',
  UPDATE_LOCALIZED_INFORMATION_SUCCESS: 'UPDATE_LOCALIZED_INFORMATION_SUCCESS',
  UPDATE_LOCALIZED_INFORMATION_FAILURE: 'UPDATE_LOCALIZED_INFORMATION_FAILURE',
  REMOVE_LOCALIZED_INFORMATION_REQUEST: 'REMOVE_LOCALIZED_INFORMATION_REQUEST',
  REMOVE_LOCALIZED_INFORMATION_SUCCESS: 'REMOVE_LOCALIZED_INFORMATION_SUCCESS',
  REMOVE_LOCALIZED_INFORMATION_FAILURE: 'REMOVE_LOCALIZED_INFORMATION_FAILURE',
  CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: 'CREATE_PRODUCT_FAILURE',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  REMOVE_ONE: 'REMOVE_ONE',
  FETCH_TAGS: 'FETCH_TAGS',
  SET_CURRENT_SUPPLIER: 'SET_CURRENT_SUPPLIER',
  SET_NEW_PRODUCT: 'SET_NEW_PRODUCT',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
};

export const kitchenPlatformsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  SET_CURRENT_PLATFORM: 'SET_CURRENT_PLATFORM',
};

export const menusTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ALL_GENERIC_REQUEST: 'FETCH_ALL_GENERIC_REQUEST',
  FETCH_ALL_GENERIC_SUCCESS: 'FETCH_ALL_GENERIC_SUCCESS',
  FETCH_ALL_GENERIC_FAILURE: 'FETCH_ALL_GENERIC_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  SET_CURRENT_MENU: 'SET_CURRENT_MENU',
  ADD_CATEGORY: 'ADD_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  FETCH_STATUSES_REQUEST: 'FETCH_STATUSES_REQUEST',
  FETCH_STATUSES_SUCCESS: 'FETCH_STATUSES_SUCCESS',
  FETCH_STATUSES_FAILURE: 'FETCH_STATUSES_FAILURE',
  FETCH_STATUS_REQUEST: 'FETCH_STATUS_REQUEST',
  FETCH_STATUS_SUCCESS: 'FETCH_STATUS_SUCCESS',
  FETCH_STATUS_FAILURE: 'FETCH_STATUS_FAILURE',
  CHECK_MENU_REQUEST: 'CHECK_MENU_REQUEST',
  CHECK_MENU_SUCCESS: 'CHECK_MENU_SUCCESS',
  CHECK_MENU_FAILURE: 'CHECK_MENU_FAILURE',
  TEST_MENU_REQUEST: 'TEST_MENU_REQUEST',
  TEST_MENU_SUCCESS: 'TEST_MENU_SUCCESS',
  TEST_MENU_FAILURE: 'TEST_MENU_FAILURE',
  UPLOAD_MENU_REQUEST: 'UPLOAD_MENU_REQUEST',
  UPLOAD_MENU_SUCCESS: 'UPLOAD_MENU_SUCCESS',
  UPLOAD_MENU_FAILURE: 'UPLOAD_MENU_FAILURE',
  SWAP_CATEGORIES: 'SWAP_CATEGORIES',
  SWAP_MENUITEMS_INLINE: 'SWAP_MENUITEMS_INLINE',
  SWAP_MENUITEMS_INGROUPS: 'SWAP_MENUITEMS_INGROUPS',
  UPDATE_CHECK_STATUS: 'UPDATE_CHECK_STATUS',
  ADD_CHECK_STATUS: 'ADD_CHECK_STATUS',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  FETCH_CURRENT_MENU_ITEMS_REQUEST: 'FETCH_CURRENT_MENU_ITEMS_REQUEST',
  FETCH_CURRENT_MENU_ITEMS_SUCCESS: 'FETCH_CURRENT_MENU_ITEMS_SUCCESS',
  FETCH_CURRENT_MENU_ITEMS_FAILURE: 'FETCH_CURRENT_MENU_ITEMS_FAILURE',
  FETCH_CONCEPT_LOCATION_MENU_ITEMS_REQUEST: 'FETCH_CONCEPT_LOCATION_MENU_ITEMS_REQUEST',
  FETCH_CONCEPT_LOCATION_MENU_ITEMS_SUCCESS: 'FETCH_CONCEPT_LOCATION_MENU_ITEMS_SUCCESS',
  FETCH_CONCEPT_LOCATION_MENU_ITEMS_FAILURE: 'FETCH_CONCEPT_LOCATION_MENU_ITEMS_FAILURE',
  FETCH_CURRENT_MENU_REQUEST: 'FETCH_CURRENT_MENU_REQUEST',
  FETCH_CURRENT_MENU_SUCCESS: 'FETCH_CURRENT_MENU_SUCCESS',
  FETCH_CURRENT_MENU_FAILURE: 'FETCH_CURRENT_MENU_FAILURE',
  SET_CURRENT_MENU_REFERENTIAL: 'SET_CURRENT_MENU_REFERENTIAL',
  SET_CURRENT_CONCEPT_LOCATION_MENU_ITEMS: 'SET_CURRENT_CONCEPT_LOCATION_MENU_ITEMS',
  COPY_MENU_REQUEST: 'COPY_MENU_REQUEST',
  COPY_MENU_SUCCESS: 'COPY_MENU_SUCCESS',
  COPY_MENU_FAILURE: 'COPY_MENU_FAILURE',
};

export const categoriesTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
};

export const restaurantsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ALL_BY_CONCEPT_REQUEST: 'FETCH_ALL_BY_CONCEPT_REQUEST',
  FETCH_ALL_BY_CONCEPT_SUCCESS: 'FETCH_ALL_BY_CONCEPT_SUCCESS',
  FETCH_ALL_BY_CONCEPT_FAILURE: 'FETCH_ALL_BY_CONCEPT_FAILURE',
};

export const summaryTypes = {
  FETCH_HEALTHCHECKS_RESULTS_REQUEST: 'FETCH_HEALTHCHECKS_RESULTS_REQUEST',
  FETCH_HEALTHCHECKS_RESULTS_SUCCESS: 'FETCH_HEALTHCHECKS_RESULTS_SUCCESS',
  FETCH_HEALTHCHECKS_RESULTS_FAILURE: 'FETCH_HEALTHCHECKS_RESULTS_FAILURE',
  REFRESH_HEALTHCHECKS_RESULTS_REQUEST: 'REFRESH_HEALTHCHECKS_RESULTS_REQUEST',
  REFRESH_HEALTHCHECKS_RESULTS_SUCCESS: 'REFRESH_HEALTHCHECKS_RESULTS_SUCCESS',
  REFRESH_HEALTHCHECKS_RESULTS_FAILURE: 'REFRESH_HEALTHCHECKS_RESULTS_FAILURE',
  REFRESH_PRODUCT_USAGE_RESULTS_REQUEST: 'REFRESH_PRODUCT_USAGE_RESULTS_REQUEST',
  REFRESH_PRODUCT_USAGE_RESULTS_SUCCESS: 'REFRESH_PRODUCT_USAGE_RESULTS_SUCCESS',
  REFRESH_PRODUCT_USAGE_RESULTS_FAILURE: 'REFRESH_PRODUCT_USAGE_RESULTS_FAILURE',
  FETCH_CONCEPT_PRODUCTS_REQUEST: 'FETCH_CONCEPT_PRODUCTS_REQUEST',
  FETCH_CONCEPT_PRODUCTS_SUCCESS: 'FETCH_CONCEPT_PRODUCTS_SUCCESS',
  FETCH_CONCEPT_PRODUCTS_FAILURE: 'FETCH_CONCEPT_PRODUCTS_FAILURE',
  FETCH_PRICES_REQUEST: 'FETCH_PRICES_REQUEST',
  FETCH_PRICES_SUCCESS: 'FETCH_PRICES_SUCCESS',
  FETCH_PRICES_FAILURE: 'FETCH_PRICES_FAILURE',
};

export const hubsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  CREATE_DELIVERY_CHARGE_INFORMATION_REQUEST: 'CREATE_DELIVERY_CHARGE_INFORMATION_REQUEST',
  CREATE_DELIVERY_CHARGE_INFORMATION_SUCCESS: 'CREATE_DELIVERY_CHARGE_INFORMATION_SUCCESS',
  CREATE_DELIVERY_CHARGE_INFORMATION_FAILURE: 'CREATE_DELIVERY_CHARGE_INFORMATION_FAILURE',
  UPDATE_DELIVERY_CHARGE_INFORMATION_REQUEST: 'UPDATE_DELIVERY_CHARGE_INFORMATION_REQUEST',
  UPDATE_DELIVERY_CHARGE_INFORMATION_SUCCESS: 'UPDATE_DELIVERY_CHARGE_INFORMATION_SUCCESS',
  UPDATE_DELIVERY_CHARGE_INFORMATION_FAILURE: 'UPDATE_DELIVERY_CHARGE_INFORMATION_FAILURE',
  REMOVE_DELIVERY_CHARGE_INFORMATION_REQUEST: 'REMOVE_DELIVERY_CHARGE_INFORMATION_REQUEST',
  REMOVE_DELIVERY_CHARGE_INFORMATION_SUCCESS: 'REMOVE_DELIVERY_CHARGE_INFORMATION_SUCCESS',
  REMOVE_DELIVERY_CHARGE_INFORMATION_FAILURE: 'REMOVE_DELIVERY_CHARGE_INFORMATION_FAILURE',
  FETCH_ONE_LOCALIZED_INFORMATION_REQUEST: 'FETCH_ONE_LOCALIZED_INFORMATION_REQUEST',
  FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS: 'FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS',
  FETCH_ONE_LOCALIZED_INFORMATION_FAILURE: 'FETCH_ONE_LOCALIZED_INFORMATION_FAILURE',
  CREATE_LOCALIZED_INFORMATION_REQUEST: 'CREATE_LOCALIZED_INFORMATION_REQUEST',
  CREATE_LOCALIZED_INFORMATION_SUCCESS: 'CREATE_LOCALIZED_INFORMATION_SUCCESS',
  CREATE_LOCALIZED_INFORMATION_FAILURE: 'CREATE_LOCALIZED_INFORMATION_FAILURE',
  UPDATE_LOCALIZED_INFORMATION_REQUEST: 'UPDATE_LOCALIZED_INFORMATION_REQUEST',
  UPDATE_LOCALIZED_INFORMATION_SUCCESS: 'UPDATE_LOCALIZED_INFORMATION_SUCCESS',
  UPDATE_LOCALIZED_INFORMATION_FAILURE: 'UPDATE_LOCALIZED_INFORMATION_FAILURE',
  REMOVE_LOCALIZED_INFORMATION_REQUEST: 'REMOVE_LOCALIZED_INFORMATION_REQUEST',
  REMOVE_LOCALIZED_INFORMATION_SUCCESS: 'REMOVE_LOCALIZED_INFORMATION_SUCCESS',
  REMOVE_LOCALIZED_INFORMATION_FAILURE: 'REMOVE_LOCALIZED_INFORMATION_FAILURE',
  ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
  REMOVE_ONE: 'REMOVE_ONE',
  REMOVE_PRODUCT_REQUEST: 'REMOVE_PRODUCT_REQUEST',
  REMOVE_PRODUCT_SUCCESS: 'REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_FAILURE: 'REMOVE_PRODUCT_FAILURE',
  SET_CURRENT_HUB: 'SET_CURRENT_HUB',
  UPDATE_PRODUCT_HUB_ASSOCIATION_REQUEST: 'UPDATE_PRODUCT_HUB_ASSOCIATION_REQUEST',
  UPDATE_PRODUCT_HUB_ASSOCIATION_SUCCESS: 'UPDATE_PRODUCT_HUB_ASSOCIATION_SUCCESS',
  UPDATE_PRODUCT_HUB_ASSOCIATION_FAILURE: 'UPDATE_PRODUCT_HUB_ASSOCIATION_FAILURE',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  FETCH_TAGS: 'FETCH_TAGS',
};

export const itemsInformationsTypes = {
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',
  FETCH_ONE_REQUEST: 'FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'FETCH_ONE_FAILURE',
  UPDATE_ONE_REQUEST: 'UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'UPDATE_ONE_FAILURE',
  CREATE_ONE_REQUEST: 'CREATE_ONE_REQUEST',
  CREATE_ONE_SUCCESS: 'CREATE_ONE_SUCCESS',
  CREATE_ONE_FAILURE: 'CREATE_ONE_FAILURE',
  REMOVE_ONE_REQUEST: 'REMOVE_ONE_REQUEST',
  REMOVE_ONE_SUCCESS: 'REMOVE_ONE_SUCCESS',
  REMOVE_ONE_FAILURE: 'REMOVE_ONE_FAILURE',
  SET_CURRENT_ITEMS_INFORMATIONS_REFERENTIAL: 'SET_CURRENT_ITEMS_INFORMATIONS_REFERENTIAL',
  UPLOAD_PICTURE_REQUEST: 'UPLOAD_PICTURE_REQUEST',
  UPLOAD_PICTURE_SUCCESS: 'UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_FAILURE: 'UPLOAD_PICTURE_FAILURE',
};

export const shippingPolicyTypes = {
  ADD_SHIPPING_POLICY_REQUEST: 'ADD_SHIPPING_POLICY_REQUEST',
  ADD_SHIPPING_POLICY_SUCCESS: 'ADD_SHIPPING_POLICY_SUCCESS',
  ADD_SHIPPING_POLICY_FAILURE: 'ADD_SHIPPING_POLICY_FAILURE',
  LIST_SHIPPING_POLICY_REQUEST: 'LIST_SHIPPING_POLICY_REQUEST',
  LIST_SHIPPING_POLICY_SUCCESS: 'LIST_SHIPPING_POLICY_SUCCESS',
  LIST_SHIPPING_POLICY_FAILURE: 'LIST_SHIPPING_POLICY_FAILURE',
  REMOVE_SHIPPING_POLICY_REQUEST: 'REMOVE_SHIPPING_POLICY_REQUEST',
  REMOVE_SHIPPING_POLICY_SUCCESS: 'REMOVE_SHIPPING_POLICY_SUCCESS',
  REMOVE_SHIPPING_POLICY_FAILURE: 'REMOVE_SHIPPING_POLICY_FAILURE',
  UPDATE_SHIPPING_POLICY_REQUEST: 'UPDATE_SHIPPING_POLICY_REQUEST',
  UPDATE_SHIPPING_POLICY_SUCCESS: 'UPDATE_SHIPPING_POLICY_SUCCESS',
  UPDATE_SHIPPING_POLICY_FAILURE: 'UPDATE_SHIPPING_POLICY_FAILURE',
};

export const substitutesTypes = {
  FETCH_SUBSTITUTE_RULES_REQUEST: 'FETCH_SUBSITUTE_RULES_REQUEST',
  FETCH_SUBSTITUTE_RULES_SUCCESS: 'FETCH_SUBSITUTE_RULES_SUCCESS',
  FETCH_SUBSTITUTE_RULES_ERROR: 'FETCH_SUBSITUTE_RULES_ERROR',
  FETCH_PRODUCT_SUBSTITUTES_REQUEST: 'FETCH_PRODUCT_SUBSTITUTES_REQUEST',
  FETCH_PRODUCT_SUBSTITUTES_SUCCESS: 'FETCH_PRODUCT_SUBSTITUTES_SUCCESS',
  FETCH_PRODUCT_SUBSTITUTES_ERROR: 'FETCH_PRODUCT_SUBSTITUTES_ERROR',
  UPDATE_SUBSTITUTE_RULE_REQUEST: 'UPDATE_SUBSTITUTE_RULE_REQUEST',
  UPDATE_SUBSTITUTE_RULE_SUCCESS: 'UPDATE_SUBSTITUTE_RULE_SUCCESS',
  UPDATE_SUBSTITUTE_RULE_ERROR: 'UPDATE_SUBSTITUTE_RULE_ERROR',
};

export const actionsTypes = {
  FETCH_ACTION_PLANS_SUCCESS: 'FETCH_ACTION_PLANS_SUCCESS',
  FETCH_ACTION_PLANS_ERROR: 'FETCH_ACTION_PLANS_ERROR',
  FETCH_ACTION_PLANS: 'FETCH_ACTION_PLANS',

  FETCH_ACTIONS_SUCCESS: 'FETCH_ACTIONS_SUCCESS',
  FETCH_ACTIONS_ERROR: 'FETCH_ACTIONS_ERROR',
  FETCH_ACTIONS: 'FETCH_ACTIONS',
};

export const gatewayMenusTypes = {
  FETCH_GATEWAY_MENUS_REQUEST: 'FETCH_GATEWAY_MENUS_REQUEST',
  FETCH_GATEWAY_MENUS_SUCCESS: 'FETCH_GATEWAY_MENUS_SUCCESS',
  FETCH_GATEWAY_MENUS_ERROR: 'FETCH_GATEWAY_MENUS_ERROR',
  FETCH_GATEWAY_MENU_ITEM_LIBRARY_REQUEST: 'FETCH_GATEWAY_MENU_ITEM_LIBRARY_REQUEST',
  FETCH_GATEWAY_MENU_ITEM_LIBRARY_SUCCESS: 'FETCH_GATEWAY_MENU_ITEM_LIBRARY_SUCCESS',
  FETCH_GATEWAY_MENU_ITEM_LIBRARY_ERROR: 'FETCH_GATEWAY_MENU_ITEM_LIBRARY_ERROR',
  FETCH_GATEWAY_MENU_ITEM_VARIANTS_REQUEST: 'FETCH_GATEWAY_MENU_ITEM_VARIANTS_REQUEST',
  FETCH_GATEWAY_MENU_ITEM_VARIANTS_SUCCESS: 'FETCH_GATEWAY_MENU_ITEM_VARIANTS_SUCCESS',
  FETCH_GATEWAY_MENU_ITEM_VARIANTS_ERROR: 'FETCH_GATEWAY_MENU_ITEM_VARIANTS_ERROR',
};
