import { uniq, cloneDeep } from 'lodash';

import menuItemsAPI from '@/apis/items/menuItems';
import itemsAPI from '@/apis/items/items';
import costsAPI from '@/apis/pricer/costs';
import summaryAPI from '@/apis/items/summary';
import { menuItemsTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    all: [],
    todos: {},
    categories: null,
    current: null,
    currentRecipe: null,
    currentRecipeItems: [],
    currentModifierGroups: null,
    currentUsage: null,
    menuItemPrices: null,
    searchFilters: {
      concept: 0,
      term: '',
      categories: [],
    },
    tags: [],
    fetchAll: {
      error: null,
      pending: false,
    },
    addOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    fetchOne: {
      error: null,
      pending: false,
    },
    fetchRecipe: {
      error: null,
      pending: false,
    },
    fetchUsage: {
      pending: false,
      error: null,
    },
    fetchModifierGroups: {
      error: null,
      pending: false,
    },
    addModifierGroup: {
      error: null,
      pending: false,
    },
    updateModifierGroup: {
      error: null,
      pending: false,
    },
    removeModifierGroup: {
      error: null,
      pending: false,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    updateRecipeItem: {
      pending: false,
      error: null,
    },
    removeRecipeItem: {
      pending: false,
      error: null,
    },
    addRecipeItem: {
      pending: false,
      error: null,
    },
    updateLocation: {
      pending: false,
      error: null,
    },
    removeLocation: {
      pending: false,
      error: null,
    },
    addLocation: {
      pending: false,
      error: null,
    },
    uploadPicture: {
      pending: false,
      error: null,
    },
    fetchCategories: {
      pending: false,
      error: null,
    },
    fetchTags: {
      pending: false,
      error: null,
    },
    computeMenuItemPrice: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [menuItemsTypes.FETCH_ALL_REQUEST](state) {
      state.all = null;
      state.fetchAll.pending = true;
      state.fetchAll.error = null;
    },
    [menuItemsTypes.FETCH_ALL_SUCCESS](state, menuItems) {
      state.fetchAll.pending = false;
      state.all = menuItems;
    },
    [menuItemsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [menuItemsTypes.SET_MENU_ITEMS](state, menuItems) {
      state.all = menuItems;
    },
    [menuItemsTypes.REMOVE_MENU_ITEMS](state, { conceptUuid, menuItemUuid }) {
      const menuItem = state.all.find(({ uuid }) => uuid === menuItemUuid);

      menuItem.concept_uuids.splice(
        menuItem.concept_uuids.findIndex(({ uuid }) => uuid === conceptUuid),
        1
      );
    },
    [menuItemsTypes.CREATE_ONE_SUCCESS](state, menuItem) {
      const index = state.all.findIndex(({ uuid }) => menuItem.uuid === uuid);

      if (index > -1) {
        state.all.splice(index, 1, menuItem);
      } else {
        state.all.push(menuItem);
      }
      state.createOne.pending = false;
      state.createOne.error = null;
      state.current = menuItem;
    },
    [menuItemsTypes.CREATE_ONE_FAILURE](state, error) {
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [menuItemsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [menuItemsTypes.ADD_MENU_ITEM_SUCCESS](state, menuItem) {
      const index = state.all.findIndex(({ uuid }) => menuItem.uuid === uuid);
      if (index > -1) {
        state.all.splice(index, 1, menuItem);
      } else {
        state.all.push(menuItem);
      }
      state.addOne.pending = false;
      state.addOne.error = null;
      state.current = menuItem;
    },
    [menuItemsTypes.ADD_MENU_ITEM_REQUEST](state, error) {
      state.addOne.pending = false;
      state.addOne.error = error;
    },
    [menuItemsTypes.ADD_MENU_ITEM_FAILURE](state) {
      state.addOne.pending = true;
    },
    [menuItemsTypes.FETCH_ONE_REQUEST](state) {
      state.current = null;
      state.fetchOne.pending = true;
      state.fetchOne.error = null;
    },
    [menuItemsTypes.FETCH_ONE_SUCCESS](state, menuItem) {
      state.fetchOne.pending = false;
      state.current = menuItem;
    },
    [menuItemsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [menuItemsTypes.UPDATE_ONE_SUCCESS](state, menuItem) {
      state.current = menuItem;
      state.updateOne.pending = false;
    },
    [menuItemsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [menuItemsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
    },
    [menuItemsTypes.FETCH_RECIPE_REQUEST](state) {
      state.fetchRecipe.pending = true;
      state.fetchRecipe.error = null;
    },
    [menuItemsTypes.FETCH_RECIPE_SUCCESS](state, recipe) {
      state.fetchRecipe.pending = false;
      state.currentRecipe = recipe;
    },
    [menuItemsTypes.FETCH_RECIPE_FAILURE](state, error) {
      state.fetchRecipe.pending = false;
      state.fetchRecipe.error = error;
    },
    [menuItemsTypes.FETCH_MODIFIER_GROUPS_REQUEST](state) {
      state.fetchModifierGroups.pending = true;
      state.fetchModifierGroups.error = null;
    },
    [menuItemsTypes.FETCH_MODIFIER_GROUPS_SUCCESS](state, modifierGroups) {
      state.fetchModifierGroups.pending = false;
      state.currentModifierGroups = modifierGroups;
    },
    [menuItemsTypes.FETCH_MODIFIER_GROUPS_FAILURE](state, error) {
      state.fetchModifierGroups.pending = false;
      state.fetchModifierGroups.error = error;
    },
    [menuItemsTypes.ADD_MODIFIER_GROUP_REQUEST](state) {
      state.addModifierGroup.pending = true;
      state.addModifierGroup.error = null;
    },
    [menuItemsTypes.ADD_MODIFIER_GROUP_SUCCESS](state, menuItem) {
      state.addModifierGroup.pending = false;
      state.current.modifier_group_uuids_sorted = menuItem.modifier_group_uuids_sorted;
      state.current.modifier_group_uuids = menuItem.modifier_group_uuids;
    },
    [menuItemsTypes.ADD_MODIFIER_GROUP_FAILURE](state, error) {
      state.addModifierGroup.pending = false;
      state.addModifierGroup.error = error;
    },
    [menuItemsTypes.UPDATE_MODIFIER_GROUP_REQUEST](state) {
      state.updateModifierGroup.pending = true;
      state.updateModifierGroup.error = null;
    },
    [menuItemsTypes.UPDATE_MODIFIER_GROUP_SUCCESS](state, menuItem) {
      state.updateModifierGroup.pending = false;
      state.current = menuItem;
    },
    [menuItemsTypes.UPDATE_MODIFIER_GROUP_FAILURE](state, error) {
      state.updateModifierGroup.pending = false;
      state.updateModifierGroup.error = error;
    },
    [menuItemsTypes.REMOVE_MODIFIER_GROUP_REQUEST](state) {
      state.removeModifierGroup.pending = true;
      state.removeModifierGroup.error = null;
    },
    [menuItemsTypes.REMOVE_MODIFIER_GROUP_SUCCESS](state, modifierGroupUuid) {
      state.removeModifierGroup.pending = false;
      state.current.modifier_group_uuids.splice(
        state.current.modifier_group_uuids.findIndex((uuid) => uuid === modifierGroupUuid),
        1
      );
      state.current.modifier_group_uuids_sorted.splice(
        state.current.modifier_group_uuids_sorted.findIndex((uuid) => uuid === modifierGroupUuid),
        1
      );
      state.currentModifierGroups.splice(
        state.currentModifierGroups.findIndex(({ uuid }) => uuid === modifierGroupUuid),
        1
      );
      state.all.splice(
        state.all.findIndex(({ uuid }) => uuid === state.current.uuid),
        1,
        state.current
      );
    },
    [menuItemsTypes.REMOVE_MODIFIER_GROUP_FAILURE](state, error) {
      state.removeModifierGroup.pending = false;
      state.removeModifierGroup.error = error;
    },
    [menuItemsTypes.SET_CURRENT](state, menuItem) {
      state.current = menuItem;
    },
    [menuItemsTypes.FETCH_RECIPE_ITEMS_SUCCESS](state, items) {
      state.currentRecipeItems = items;
    },
    [menuItemsTypes.UPDATE_RECIPE_ITEM_SUCCESS](state, { menuItem, itemUuid }) {
      state.current.recipe.splice(
        state.current.recipe.findIndex(({ uuid }) => uuid === itemUuid),
        1,
        menuItem.recipe.find(({ uuid }) => uuid === itemUuid)
      );
      state.updateRecipeItem.pending = false;
    },
    [menuItemsTypes.UPDATE_RECIPE_ITEM_FAILURE](state, error) {
      state.current = null;
      state.updateRecipeItem.pending = false;
      state.updateRecipeItem.error = error;
    },
    [menuItemsTypes.UPDATE_RECIPE_ITEM_REQUEST](state) {
      state.updateRecipeItem.pending = true;
    },
    [menuItemsTypes.REMOVE_RECIPE_ITEM_SUCCESS](state, itemUuid) {
      state.current.recipe.splice(
        state.current.recipe.findIndex((item) => item.uuid === itemUuid),
        1
      );
      state.removeRecipeItem.pending = false;
    },
    [menuItemsTypes.REMOVE_RECIPE_ITEM_FAILURE](state, error) {
      state.current = null;
      state.removeRecipeItem.pending = false;
      state.removeRecipeItem.error = error;
    },
    [menuItemsTypes.REMOVE_RECIPE_ITEM_REQUEST](state) {
      state.removeRecipeItem.pending = true;
    },
    [menuItemsTypes.ADD_RECIPE_ITEM_SUCCESS](state, menuItem) {
      state.current.recipe = menuItem.recipe;
      state.addRecipeItem.pending = false;
    },
    [menuItemsTypes.ADD_RECIPE_ITEM_FAILURE](state, error) {
      state.current = null;
      state.addRecipeItem.pending = false;
      state.addRecipeItem.error = error;
    },
    [menuItemsTypes.ADD_RECIPE_ITEM_REQUEST](state) {
      state.addRecipeItem.pending = true;
    },
    [menuItemsTypes.ADD_LOCATION_SUCCESS](state, { menuItem, locationUuid }) {
      const location = menuItem.locations.find(({ uuid }) => uuid === locationUuid);
      state.current.locations.push(location);
      state.current.location_uuids.push(location.uuid);
      state.addLocation.pending = false;
    },
    [menuItemsTypes.ADD_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.addLocation.pending = false;
      state.addLocation.error = error;
    },
    [menuItemsTypes.ADD_LOCATION_REQUEST](state) {
      state.addLocation.pending = true;
    },
    [menuItemsTypes.REMOVE_LOCATION_SUCCESS](state, locationUuid) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1
      );
      const index = state.current.location_uuids.indexOf(locationUuid);
      if (index > -1) {
        state.current.location_uuids.splice(index, 1);
      }
      state.removeLocation.pending = false;
    },
    [menuItemsTypes.REMOVE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.removeLocation.pending = false;
      state.removeLocation.error = error;
    },
    [menuItemsTypes.REMOVE_LOCATION_REQUEST](state) {
      state.removeLocation.pending = true;
    },
    [menuItemsTypes.UPDATE_LOCATION_SUCCESS](state, { menuItem, locationUuid }) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === locationUuid),
        1,
        menuItem.locations.find(({ uuid }) => uuid === locationUuid)
      );
      state.updateLocation.pending = false;
    },
    [menuItemsTypes.UPDATE_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.updateLocation.pending = false;
      state.updateLocation.error = error;
    },
    [menuItemsTypes.UPDATE_LOCATION_REQUEST](state) {
      state.updateLocation.pending = true;
    },
    [menuItemsTypes.FETCH_TODOS](state, { todos, conceptUuid, locationUuid }) {
      state.todos = { ...state.todos, [locationUuid]: { ...state.todos[locationUuid], [conceptUuid]: todos } };
    },
    [menuItemsTypes.SET_CURRENT_LOCATION](state, location) {
      state.current.locations.splice(
        state.current.locations.findIndex(({ uuid }) => uuid === location.uuid),
        1,
        location
      );
    },
    [menuItemsTypes.SET_SEARCH_FILTERS](state, searchFilters) {
      state.searchFilters = searchFilters;
    },
    [menuItemsTypes.UPLOAD_PICTURE_SUCCESS](state, menuItem) {
      state.current = menuItem;
      state.uploadPicture.pending = false;
    },
    [menuItemsTypes.UPLOAD_PICTURE_FAILURE](state, error) {
      state.current = null;
      state.uploadPicture.pending = false;
      state.uploadPicture.error = error;
    },
    [menuItemsTypes.UPLOAD_PICTURE_REQUEST](state) {
      state.uploadPicture.pending = true;
    },
    [menuItemsTypes.FETCH_CATEGORIES_REQUEST](state) {
      state.fetchCategories.pending = true;
      state.fetchCategories.error = null;
    },
    [menuItemsTypes.FETCH_CATEGORIES_SUCCESS](state, categories) {
      state.fetchCategories.pending = false;
      state.categories = categories;
    },
    [menuItemsTypes.FETCH_CATEGORIES_FAILURE](state, error) {
      state.fetchCategories.pending = false;
      state.fetchCategories.error = error;
    },
    [menuItemsTypes.FETCH_TAGS_REQUEST](state) {
      state.fetchTags.pending = true;
      state.fetchTags.error = null;
    },
    [menuItemsTypes.FETCH_TAGS_SUCCESS](state, tags) {
      state.fetchTags.pending = false;
      state.tags = tags;
    },
    [menuItemsTypes.FETCH_TAGS_FAILURE](state, error) {
      state.fetchTags.pending = false;
      state.fetchTags.error = error;
    },
    [menuItemsTypes.FETCH_USAGE_REQUEST](state) {
      state.currentUsage = null;
      state.fetchUsage.pending = true;
      state.fetchUsage.error = null;
    },
    [menuItemsTypes.FETCH_USAGE_SUCCESS](state, usage) {
      state.fetchUsage.pending = false;
      state.currentUsage = usage;
    },
    [menuItemsTypes.FETCH_USAGE_FAILURE](state, error) {
      state.fetchUsage.pending = false;
      state.fetchUsage.error = error;
    },
    [menuItemsTypes.UPDATE_CURRENT_MODIFIER_GROUPS](state, modifierGroup) {
      const index = state.currentModifierGroups.findIndex(({ uuid }) => modifierGroup.uuid === uuid);
      if (index > -1) {
        state.currentModifierGroups.splice(index, 1, modifierGroup);
      } else {
        state.currentModifierGroups.push(modifierGroup);
      }
    },
    [menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_REQUEST](state) {
      state.computeMenuItemPrice.pending = true;
      state.computeMenuItemPrice.error = null;
    },
    [menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_SUCCESS](state, prices) {
      state.menuItemPrices = prices.data;
      state.computeMenuItemPrice.pending = false;
    },
    [menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_FAILURE](state, error) {
      state.menuItemPrices = null;
      state.computeMenuItemPrice.pending = false;
      state.computeMenuItemPrice.error = error;
    },
  },
  actions: {
    async fetchAll({ commit }, opts) {
      commit(menuItemsTypes.FETCH_ALL_REQUEST);

      return menuItemsAPI
        .fetchAll(opts)
        .then((menuItems) => commit(menuItemsTypes.FETCH_ALL_SUCCESS, menuItems))
        .catch((error) => commit(menuItemsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, uuid) {
      commit(menuItemsTypes.FETCH_ONE_REQUEST);

      return menuItemsAPI
        .fetchOne(uuid)
        .then((menuItem) => commit(menuItemsTypes.FETCH_ONE_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.FETCH_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(menuItemsTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.concept_uuids;
      delete data.item_uuids;
      delete data.location_uuids;
      delete data.modifier_group_uuids;
      delete data.pictures;
      delete data.recipe;
      delete data.locations;

      return menuItemsAPI
        .updateOne(state.current.uuid, data)
        .then((menuItem) => commit(menuItemsTypes.UPDATE_ONE_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.UPDATE_ONE_FAILURE, error));
    },
    async fetchRecipe({ commit, rootState }, menuItemUUID) {
      commit(menuItemsTypes.FETCH_RECIPE_REQUEST, menuItemUUID);
      if (menuItemUUID) {
        return menuItemsAPI
          .getRecipe(menuItemUUID, rootState.locations.current.location_tree)
          .then((recipe) => commit(menuItemsTypes.FETCH_RECIPE_SUCCESS, recipe))
          .catch((error) => commit(menuItemsTypes.FETCH_RECIPE_FAILURE, error));
      }
    },
    async fetchRecipeItems({ commit, state }) {
      return Promise.all(state.current.recipe.map((item) => itemsAPI.fetchOne(item.uuid))).then((items) =>
        commit(menuItemsTypes.FETCH_RECIPE_ITEMS_SUCCESS, items)
      );
    },
    async fetchModifierGroups({ commit, state }) {
      commit(menuItemsTypes.FETCH_MODIFIER_GROUPS_REQUEST);

      return menuItemsAPI
        .fetchModifierGroups(state.current.uuid)
        .then((modifierGroups) => commit(menuItemsTypes.FETCH_MODIFIER_GROUPS_SUCCESS, modifierGroups))
        .catch((error) => commit(menuItemsTypes.FETCH_MODIFIER_GROUPS_FAILURE, error));
    },
    async fetchCategories({ commit }) {
      commit(menuItemsTypes.FETCH_CATEGORIES_REQUEST);

      return menuItemsAPI
        .fetchCategories()
        .then((categories) => commit(menuItemsTypes.FETCH_CATEGORIES_SUCCESS, categories))
        .catch((error) => commit(menuItemsTypes.FETCH_CATEGORIES_FAILURE, error));
    },
    async fetchTags({ commit }) {
      commit(menuItemsTypes.FETCH_TAGS_REQUEST);

      return menuItemsAPI
        .fetchTags()
        .then((tags) => commit(menuItemsTypes.FETCH_TAGS_SUCCESS, tags))
        .catch((error) => commit(menuItemsTypes.FETCH_TAGS_FAILURE, error));
    },
    async addModifierGroup({ commit, state }, modifierGroupUuid) {
      commit(menuItemsTypes.ADD_MODIFIER_GROUP_REQUEST);

      return menuItemsAPI
        .addModifierGroup(state.current.uuid, modifierGroupUuid)
        .then((menuItem) => commit(menuItemsTypes.ADD_MODIFIER_GROUP_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.ADD_MODIFIER_GROUP_FAILURE, error));
    },
    async updateModifierGroup({ commit, state }, parameters) {
      commit(menuItemsTypes.UPDATE_MODIFIER_GROUP_REQUEST);
      return menuItemsAPI
        .updateModifierGroup(state.current.uuid, parameters.modifierGroupUuid, { required: parameters.isRequired })
        .then((menuItem) => commit(menuItemsTypes.UPDATE_MODIFIER_GROUP_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.UPDATE_MODIFIER_GROUP_FAILURE, error));
    },
    async removeModifierGroup({ commit, state }, modifierGroupUuid) {
      commit(menuItemsTypes.REMOVE_MODIFIER_GROUP_REQUEST);

      return menuItemsAPI
        .removeModifierGroup(state.current.uuid, modifierGroupUuid)
        .then(() => commit(menuItemsTypes.REMOVE_MODIFIER_GROUP_SUCCESS, modifierGroupUuid))
        .catch((error) => commit(menuItemsTypes.REMOVE_MODIFIER_GROUP_FAILURE, error));
    },
    async fetchByConcept({ commit }, conceptUUID) {
      return menuItemsAPI
        .fetchByConcept(conceptUUID)
        .then(function (response) {
          commit(menuItemsTypes.SET_MENU_ITEMS, response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async removeFromConcept({ commit }, { conceptUuid, menuItemUuid }) {
      return menuItemsAPI
        .removeFromConcept(conceptUuid, menuItemUuid)
        .then(() => commit(menuItemsTypes.REMOVE_MENU_ITEMS, { conceptUuid, menuItemUuid }))
        .catch(console.error);
    },
    async addMenuItemToConcept({ commit }, { conceptUuid, menuItemUuid }) {
      commit(menuItemsTypes.ADD_MENU_ITEM_REQUEST);
      return menuItemsAPI
        .addMenuItemToConcept(conceptUuid, menuItemUuid)
        .then(function ({ data }) {
          commit(menuItemsTypes.ADD_MENU_ITEM_SUCCESS, data);
        })
        .catch(function (error) {
          commit(menuItemsTypes.ADD_MENU_ITEM_FAILURE, error);
        });
    },
    async createMenuItem({ commit }, { conceptUuid, menuItemLabel, menuItemCategory }) {
      commit(menuItemsTypes.CREATE_ONE_REQUEST);
      return menuItemsAPI
        .createMenuItem(conceptUuid, menuItemLabel, menuItemCategory)
        .then(function (response) {
          return commit(menuItemsTypes.CREATE_ONE_SUCCESS, response);
        })
        .catch(function (error) {
          commit(menuItemsTypes.CREATE_ONE_FAILURE, error);
        });
    },
    async updateItemRecipe({ commit, state }, item) {
      commit(menuItemsTypes.UPDATE_RECIPE_ITEM_REQUEST);

      const data = { ...item };

      delete data.uuid;

      return menuItemsAPI
        .updateItemRecipe(state.current.uuid, item.uuid, data)
        .then((menuItem) => commit(menuItemsTypes.UPDATE_RECIPE_ITEM_SUCCESS, { menuItem, itemUuid: item.uuid }))
        .catch((error) => commit(menuItemsTypes.UPDATE_RECIPE_ITEM_FAILURE, error));
    },
    async removeItemFromRecipe({ commit, state }, itemUuid) {
      commit(menuItemsTypes.REMOVE_RECIPE_ITEM_REQUEST);

      return menuItemsAPI
        .removeItemFromRecipe(state.current.uuid, itemUuid)
        .then(() => commit(menuItemsTypes.REMOVE_RECIPE_ITEM_SUCCESS, itemUuid))
        .catch((error) => commit(menuItemsTypes.REMOVE_RECIPE_ITEM_FAILURE, error));
    },
    async addItemToRecipe({ commit, state }, item) {
      commit(menuItemsTypes.ADD_RECIPE_ITEM_REQUEST);

      return menuItemsAPI
        .addItemToRecipe(state.current.uuid, [item])
        .then((menuItem) => commit(menuItemsTypes.ADD_RECIPE_ITEM_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.ADD_RECIPE_ITEM_FAILURE, error));
    },
    async addLocation({ commit, state }, locationUuid) {
      commit(menuItemsTypes.ADD_LOCATION_REQUEST);

      return menuItemsAPI
        .addLocation(state.current.uuid, locationUuid)
        .then((menuItem) => commit(menuItemsTypes.ADD_LOCATION_SUCCESS, { menuItem, locationUuid }))
        .catch((error) => commit(menuItemsTypes.ADD_LOCATION_FAILURE, error));
    },
    async removeLocation({ commit, state }, locationUuid) {
      commit(menuItemsTypes.REMOVE_LOCATION_REQUEST);

      return menuItemsAPI
        .removeLocation(state.current.uuid, locationUuid)
        .then(() => commit(menuItemsTypes.REMOVE_LOCATION_SUCCESS, locationUuid))
        .catch((error) => commit(menuItemsTypes.REMOVE_LOCATION_FAILURE, error));
    },
    async updateLocation({ commit, state }, locationUuid) {
      commit(menuItemsTypes.UPDATE_LOCATION_REQUEST);

      const location = state.current.locations.find(({ uuid }) => uuid === locationUuid);
      const data = { ...location };

      delete data.uuid;

      return menuItemsAPI
        .updateLocation(state.current.uuid, location.uuid, data)
        .then((menuItem) => commit(menuItemsTypes.UPDATE_LOCATION_SUCCESS, { menuItem, locationUuid: location.uuid }))
        .catch((error) => commit(menuItemsTypes.UPDATE_LOCATION_FAILURE, error));
    },
    async fetchTodos({ commit, rootState }, conceptUuid) {
      return menuItemsAPI
        .fetchTodos([rootState.locations.current.uuid], conceptUuid)
        .then((todos) =>
          commit(menuItemsTypes.FETCH_TODOS, { todos, conceptUuid, locationUuid: rootState.locations.current.uuid })
        )
        .catch((error) => console.error(error));
    },
    async uploadPicture({ commit, state }, { conceptUuid, picture, platformUuid, basewidth }) {
      commit(menuItemsTypes.UPLOAD_PICTURE_REQUEST);

      return menuItemsAPI
        .uploadPicture(state.current.uuid, conceptUuid, picture, platformUuid, basewidth)
        .then((menuItem) => commit(menuItemsTypes.UPLOAD_PICTURE_SUCCESS, menuItem))
        .catch((error) => commit(menuItemsTypes.UPLOAD_PICTURE_FAILURE, error));
    },
    async fetchUsage({ commit, state }, locationUuids) {
      commit(menuItemsTypes.FETCH_USAGE_REQUEST);

      return summaryAPI
        .fetchMenuItemUsage(state.current.uuid, locationUuids)
        .then((usage) => commit(menuItemsTypes.FETCH_USAGE_SUCCESS, usage))
        .catch((error) => commit(menuItemsTypes.FETCH_USAGE_FAILURE, error));
    },
    async computeMenuItemPrice({ commit }, opts) {
      commit(menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_REQUEST);
      opts.type = 'MENU_ITEM';
      return costsAPI
        .compute(opts)
        .then((prices) => commit(menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_SUCCESS, prices))
        .catch((error) => commit(menuItemsTypes.COMPUTE_PRICE_MENU_ITEM_FAILURE, error));
    },
  },
  getters: {
    getMenuItemCurrentLocation: (_state, _getters, rootState) => (menuItem) => {
      for (let locationUuid of [...rootState.locations.current.location_tree]) {
        const localizedMenuItem = cloneDeep(menuItem.locations.find(({ uuid }) => uuid === locationUuid));

        if (localizedMenuItem) {
          localizedMenuItem.uuid = menuItem.uuid;
          delete localizedMenuItem.selling_price;
          delete localizedMenuItem.selling_unit_price;
          return localizedMenuItem;
        }
      }
    },
    getCurrentMenuItemCurrentLocation: (state, getters) => {
      return getters.getMenuItemCurrentLocation(state.current);
    },
    getLocationByUuid: (state) => (uuid) => state.current.locations.find((location) => location.uuid === uuid),
    getMenuItemsByCategoryAndConcept: (state) => (category, conceptUUID) =>
      state.all.filter((menuItem) => menuItem.category == category && menuItem.concept_uuids.includes(conceptUUID)),
    filterMenuItemsByLabel: (state) => (search) =>
      state.all.filter((menuItem) => menuItem.label.toLowerCase().includes(search.toLowerCase())),
    filterMenuItemsByConcept: (state) => (conceptUUID) =>
      state.all.filter((menuItem) => menuItem.concept_uuids.includes(conceptUUID)),
    menuItemsCategoriesByConcept: (state) => (conceptUUID) =>
      state.all
        ? uniq(
            state.all
              .filter((menuItem) => menuItem.concept_uuids.includes(conceptUUID))
              .map((menuItem) => menuItem.category)
          )
        : [],
  },
};
