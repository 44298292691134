import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_COPY_MENU_ENDPOINT,
});

export default {
  run(opts) {
    return axios.post('/copy_menu', {
      concept_uuid: opts.conceptUuid,
      location_uuid_destination: opts.locationUuidDestination,
      location_uuid_source: opts.locationUuidSource,
    });
  },
};
