<template>
  <div class="box modal-card">
    <b-loading :active.sync="isLoading"></b-loading>
    <h3 class="subtitle">{{ $t('add.menu-item') }}</h3>
    <div class="is-divider" :data-content="$t('add.choose')"></div>
    <b-field grouped>
      <b-autocomplete
        expanded
        rounded
        v-model="searchTerm"
        :placeholder="$t('search.menu-item-placeholder')"
        icon="magnify"
        field="label"
        :data="filteredData"
        @select="(option) => selectMenuItem(option)"
      >
        <template slot="empty">{{ $t('search.empty') }}</template>
      </b-autocomplete>
    </b-field>
    <div class="is-divider" :data-content="$t('add.create_one')"></div>

    <b-field :label="$t('menu-item.label')" label-position="on-border">
      <b-input placeholder="Chicken burger" v-model="newMenuItem.label"></b-input>
    </b-field>
    <b-field label="Internal Category" label-position="on-border">
      <b-select placeholder="Select a category" v-model="newMenuItem.category">
        <option v-for="category in categories" :value="category.label" :key="category.label">
          {{ category.label }}
        </option>
      </b-select>
    </b-field>
    <button class="button is-primary" @click="createMenuItem">{{ $t('add.create') }}</button>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'AddMenuItemModal',
  props: ['categories'],
  data: () => ({
    searchTerm: '',
    newMenuItem: {
      label: '',
      category: '',
    },
  }),
  methods: {
    ...mapActions('menuItems', ['fetchAll', 'computeMenuItemPrice']),
    createMenuItem: function () {
      this.$emit('created', this.newMenuItem.label, this.newMenuItem.category);
      this.closeModal();
    },
    selectMenuItem: function (option) {
      if (option) {
        this.computeMenuItemPrice({
          uuid: option.uuid,
          locationUuids: this.currentLocation.location_tree,
          unit: 'each',
          quantity: 1,
          save: false,
        }).then(() => {
          if (
            this.currentMenuItemPrice &&
            this.currentMenuItemPrice.cost_details &&
            this.currentMenuItemPrice.cost_details.kitchen_cost != null &&
            this.currentMenuItemPrice.cost_details.supplier_cost != null
          ) {
            this.$emit('selected', option, option.uuid);
            this.closeModal();
          } else {
            this.$buefy.dialog.confirm({
              title: `Adding ${option.label}`,
              message: `You can not <b>add</b> this ${option.label} to <b>${
                this.currentConcept.id
              }</b>: <br> ${this.getAlertMessage()}`,
              type: 'is-danger',
              hasIcon: true,
              cancelText: `Go to ${option.label} page`,
              onCancel: () => this.goToMenuItem(option.uuid),
            });
          }
        });
      }
    },
    getAlertMessage() {
      if (this.currentMenuItemPrice == null || this.currentMenuItemPrice.cost_details == null) {
        return `Kitchen Price can not be computed in ${this.currentLocation.label}`;
      }
      if (this.currentMenuItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price can not be computed in ${this.currentLocation.label}`;
      }
      if (this.currentMenuItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price can not be computed in ${this.currentLocation.label}`;
      }
      return `Price Issue`;
    },
    goToMenuItem(uuid) {
      return this.toPage('/menu-items/' + uuid + '/edit');
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    closeModal: function () {
      this.$parent.close();
    },
  },
  computed: {
    ...mapState({
      currentConcept: (state) => state.concepts.current,
      currentLocation: (state) => state.locations.current,
      currentMenuItemPrice: (state) => state.menuItems.menuItemPrices,
      menuItems: (state) => state.menuItems.all,
      isSaveLoading: (state) => state.menuItems.createOne.pending,
      isLoading: (state) => state.menuItems.computeMenuItemPrice.pending,
      saveError: (state) => state.menuItems.createOne.error,
    }),
    ...mapGetters('menuItems', ['filterMenuItemsByLabel']),
    filteredData() {
      return this.menuItems.filter(
        (option) =>
          option.label.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          !option.concept_uuids.includes(this.currentConcept.uuid)
      );
    },
  },
  mounted: function () {
    if (this.$store.state.menuItems.all === null || this.$store.state.menuItems.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>
