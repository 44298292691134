import { ProductsApi } from '@tasterkitchens/products-manager-client';
import clientHook from '@/apis';

const productsApi = new ProductsApi();
productsApi.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  fetchAll(opts) {
    return clientHook(productsApi).appLegacyApisV2ProductsListProducts(opts);
  },
  fetchAllSummary(opts) {
    return clientHook(productsApi).appLegacyApisV2ProductsListProducts({ summary: true, ...opts });
  },
  fetchOne(productUUID) {
    return clientHook(productsApi).appLegacyApisV2ProductsGetProductByUuid(productUUID);
  },
  addToHub(productUUID, hubUUID, locationUUID) {
    return clientHook(productsApi).appLegacyApisV2ProductsAddHubToProduct(productUUID, {
      hub_uuid: hubUUID,
      location_uuid: locationUUID,
    });
  },
  removeFromHub(productUUID, hubUUID, locationUUID) {
    return clientHook(productsApi).appLegacyApisV2ProductsRemoveHubFromProduct(productUUID, {
      hub_uuid: hubUUID,
      location_uuid: locationUUID,
    });
  },
  updateProductHubAssociation(
    hubUuid,
    productUuid,
    {
      locationUuid: location_uuid,
      productReference: product_reference,
      excludeFromShippingPolicies: exclude_from_shipping_policies,
    }
  ) {
    if (!location_uuid) {
      throw Error('A location UUID is required to make this call');
    }

    return clientHook(productsApi).appLegacyApisV2ProductsUpdateProductHubAssociation(productUuid, hubUuid, {
      location_uuid,
      product_reference,
      exclude_from_shipping_policies,
    });
  },
  getProductCosts(productUUID, opts) {
    return clientHook(productsApi).appLegacyApisV2ProductsGetProductCosts(productUUID, opts);
  },
};
