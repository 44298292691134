<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">Products {{ products && `(${displayedProducts.length})` }}</h1>
        </div>
        <div style="float: right">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <button class="button is-info" slot="trigger">
              <span>Actions</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item aria-role="listitem" @click="openImportPricesModal">
              <div class="media">
                <b-icon class="media-left" icon="credit-card"></b-icon>
                <div class="media-content">
                  <h3>Copy Prices</h3>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="openProductUsageRefreshModal">
              <div class="media">
                <b-icon class="media-left" icon="refresh"></b-icon>
                <div class="media-content">
                  <h3>Refresh Product Usage</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-message type="is-danger" v-if="error"> An error has occured while fetching suppliers. </b-message>
      <div v-if="!error">
        <b-loading :active.sync="isLoading && !products.length"></b-loading>
        <b-field>
          <b-input
            size="is-medium"
            expanded
            rounded
            v-model="computedSearchTerm"
            placeholder="Search for a product..."
          ></b-input>
        </b-field>
        <div style="width: 100%">
          <div class="columns">
            <div class="column is-three-quarters">
              <b-dropdown
                placeholder="Supplier"
                multiple
                v-model="selectedSuppliers"
                aria-role="list"
                :scrollable="true"
              >
                <template #trigger>
                  <b-button type="is-info" icon-right="menu-down">
                    Supppliers Selected ({{ selectedSuppliers.length }})
                  </b-button>
                </template>
                <b-dropdown-item @click="batchSelectSuppliers" :value="fakeSupplier" v-if="!allSupplierSelected">
                  Select All
                </b-dropdown-item>
                <b-dropdown-item @click="batchSelectSuppliers" :value="fakeSupplier" v-if="allSupplierSelected">
                  Select None
                </b-dropdown-item>
                <b-dropdown-item v-for="supplier in filteredSuppliers" :value="supplier" :key="supplier.uuid">
                  {{ supplier.label }}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown placeholder="In Use" v-model="usedProducts" aria-role="list" :scrollable="true">
                <template #trigger>
                  <b-button v-if="!selectedConcept" type="is-info" icon-right="menu-down">
                    Used Products in {{ currentLocation.label }}
                  </b-button>
                  <b-button v-else type="is-info" icon-right="menu-down">
                    Used Products in {{ currentLocation.label }} ({{ selectedConcept.id }})
                  </b-button>
                </template>
                <b-dropdown-item value="All"> All </b-dropdown-item>
                <b-dropdown-item :value="true"> In use </b-dropdown-item>
                <b-dropdown-item :value="false"> Not use </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="column">
              <b-switch @input="() => fetchAllProduct()" v-model="useLocation" class="is-pulled-right">
                Localized
              </b-switch>
            </div>
          </div>
        </div>
        <div class="block" style="float: right">
          <b-radio size="is-small" v-model="selectedConcept" :native-value="null"> All </b-radio>
          <b-radio
            size="is-small"
            v-model="selectedConcept"
            v-for="concept in conceptsSorted"
            :native-value="concept"
            :key="concept.uuid"
          >
            <img :src="concept.logo" width="30" />
          </b-radio>
        </div>
        <b-table
          v-if="(!isLoading || products.length) && currentLocation"
          :loading="isLoading"
          @click="(row) => $router.push('/products/' + row.uuid)"
          class="is-hoverable is-fullwidth is-striped"
          hoverable
          striped
          :data="displayedProducts"
          paginated
          per-page="10"
          default-sort="label"
          default-sort-direction="asc"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Name" sortable>
              {{ props.row.label }}
            </b-table-column>
            <b-table-column field="supplier.label" label="Supplier" sortable>
              <router-link :to="'/suppliers/' + props.row.supplier.uuid">{{ props.row.supplier.label }}</router-link>
            </b-table-column>
            <b-table-column field="product_reference" label="Reference">
              {{ props.row.product_reference }}
            </b-table-column>
            <b-table-column field="kitchen_price" label="Kitchen Price">
              {{ getKitchenPrice(props.row) }}
            </b-table-column>
            <b-table-column>
              <b-field grouped>
                <b-button
                  style="margin-left: 8px"
                  icon-left="pencil"
                  size="is-small"
                  outlined
                  type="is-info"
                  disabled
                  @click.stop="openEditProductModal(props.row.uuid)"
                  >Edit</b-button
                >
              </b-field>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { productsTypes } from '@/store/mutation-types';
import { displayFormatter } from '@/mixins';
import EditProduct from './EditProduct';
import ImportProductPrices from '@/views/Product/ImportProductPrices.vue';
import ProductUsageRefresh from '@/components/Product/ProductUsageRefresh.vue';

export default {
  mixins: [displayFormatter],
  data: () => ({
    allSupplierSelected: false,
    fakeSupplier: {
      label: null,
      uuid: null,
    },
    useLocation: false,
    selectedConcept: null,
    selectedSuppliers: [],
    usedProducts: 'All',
  }),
  computed: {
    ...mapState({
      searchTerm: (state) => state.products.searchTerm,
      products: (state) => state.products.summary,
      isLoading: (state) => state.products.fetchAllSummary.pending,
      error: (state) => state.products.fetchAll.error,
      currentLocation: (state) => state.locations.current,
      concepts: (state) => state.concepts.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      suppliers: (state) => state.suppliers.all,
      conceptsSorted() {
        if (!this.concepts) {
          return [];
        }
        return this.concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
      },
      conceptProducts: (state) => state.summary.conceptProducts,
    }),
    filteredProducts() {
      if (!this.products) {
        return [];
      }
      var filteredProducts = this.products.filter((product) =>
        product.label.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      if (this.selectedConcept) {
        var selectedConceptProducts = this.conceptProducts.filter(
          (conceptProducts) => conceptProducts.concept_uuid == this.selectedConcept.uuid
        );
        if (this.currentLocation.parent_uuid) {
          selectedConceptProducts = selectedConceptProducts.filter(
            (conceptProducts) => conceptProducts.location_uuid == this.currentLocation.uuid
          );
        }
        if (selectedConceptProducts.length > 0 && (this.usedProducts || this.usedProducts == 'All')) {
          filteredProducts = filteredProducts.filter((product) =>
            selectedConceptProducts[0].product_uuids.includes(product.uuid)
          );
        } else if (selectedConceptProducts.length > 0 && this.usedProducts == false) {
          filteredProducts = filteredProducts.filter(
            (product) => !selectedConceptProducts[0].product_uuids.includes(product.uuid)
          );
        } else {
          filteredProducts = [];
        }
      } else {
        if (this.usedProducts == false || this.usedProducts == true) {
          var productUuids = [];
          for (let conceptProduct of this.conceptProducts) {
            productUuids = productUuids.concat(conceptProduct.product_uuids);
          }
          filteredProducts = filteredProducts.filter(
            (product) => productUuids.includes(product.uuid) == this.usedProducts
          );
        }
      }
      return filteredProducts;
    },
    filteredSuppliers() {
      var supplierUuids = Array.from(new Set([...this.filteredProducts.map((product) => product.supplier.uuid)]));
      return this.suppliers
        .filter((supplier) => supplierUuids.includes(supplier.uuid))
        .map((supplier) => {
          return { label: supplier.label, uuid: supplier.uuid };
        })
        .sort((supplier1, supplier2) => supplier1.label.localeCompare(supplier2.label));
    },
    displayedProducts() {
      if (this.selectedSuppliers.length > 0) {
        var selectedSupplierUuids = this.selectedSuppliers
          .filter((supplier) => supplier.uuid != null)
          .map((supplier) => supplier.uuid);
        return this.filteredProducts.filter((product) => selectedSupplierUuids.includes(product.supplier.uuid));
      }
      return this.filteredProducts;
    },
    computedSearchTerm: {
      get() {
        return this.searchTerm;
      },
      set(value) {
        this.setSearchTerm(value);
      },
    },
  },
  methods: {
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('products', ['fetchAll', 'removeOne']),
    ...mapActions('products', { removeOne: 'removeOne', fetchAll: 'fetchAllSummary' }),
    ...mapActions('summary', ['fetchConceptProducts']),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('suppliers', { fetchSuppliers: 'fetchAll' }),
    ...mapMutations('products', { setSearchTerm: productsTypes.SET_SEARCH_TERM }),
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    batchSelectSuppliers() {
      if (!this.allSupplierSelected) {
        this.selectedSuppliers = [...this.filteredSuppliers];
        this.allSupplierSelected = true;
      } else {
        this.selectedSuppliers = [];
        this.allSupplierSelected = false;
      }
    },
    openImportPricesModal() {
      this.$buefy.modal.open({
        component: ImportProductPrices,
        parent: this,
        hasModalCard: true,
        props: { concepts: this.conceptsSorted },
      });
    },
    openEditProductModal(id) {
      this.$buefy.modal.open({
        component: EditProduct,
        parent: this,
        hasModalCard: true,
        props: { id },
      });
    },
    openProductUsageRefreshModal() {
      this.$buefy.modal.open({
        component: ProductUsageRefresh,
        parent: this,
        hasModalCard: true,
        props: {
          currentLocation: this.currentLocation,
          locationsUnnested: this.locationsUnnested,
          concepts: this.concepts,
        },
      });
    },
    getKitchenPrice(product) {
      var localizedInformation = this.getLocalizedInformations(product);
      if (
        localizedInformation &&
        localizedInformation.kitchen_price &&
        Object.keys(localizedInformation.kitchen_price).length > 0 &&
        localizedInformation.kitchen_price.price
      ) {
        var unit = this.getKitchenPriceUnit(product, localizedInformation);
        var deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.kitchen_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getKitchenPriceUnit(product, localizedInformation) {
      if (localizedInformation.kitchen_price.type == 'PER_CATALOGUE_UNIT') {
        return this.getCatalogueUnit(product);
      } else {
        return this.getPackagingUnit(product);
      }
    },
    getLocalizedInformations(product) {
      var localizedInformation = null;
      if (product && product.localized_informations && product.localized_informations.length > 0) {
        var localizedInformations = product.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
        );
        if (localizedInformations.length > 0) {
          localizedInformation = { ...localizedInformations[0], location_id: location.id };
        }
      }
      return localizedInformation;
    },
    getCountryLocalizedInformation(product) {
      if (!this.currentLocation.parent_uuid) {
        return (
          product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
          )[0] || {}
        );
      } else {
        return (
          product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.parent_uuid
          )[0] || {}
        );
      }
    },
    getCatalogueUnit(product) {
      return this.getCountryLocalizedInformation(product).catalogue_unit;
    },
    getPackagingUnit(product) {
      return this.getCountryLocalizedInformation(product).packaging_unit;
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getConcepts() {
      // to benefit from then()
      if (this.$store.state.concepts.all === null || this.$store.state.concepts.all.length == 0) {
        return this.fetchConcepts();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getSuppliers() {
      // to benefit from then()
      if (this.$store.state.suppliers.all === null || this.$store.state.suppliers.all.length == 0) {
        this.fetchSuppliers();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getUnnestedLocations() {
      // to benefit from then()
      if (!this.checkExistence(this.$store.state.locations.allUnnested)) {
        return this.fetchUnnestedLocations();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    getConceptsProducts() {
      // to benefit from then()
      if (this.$store.state.summary.conceptProducts === null || this.$store.state.summary.conceptProducts == 0) {
        return this.fetchConceptProducts({ location: this.currentLocation });
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
    fetchAllProduct() {
      let opts = {};
      if (this.useLocation) {
        opts = { locationUuid: this.currentLocation.uuid };
      }
      this.fetchAll(opts);
    },
  },
  watch: {
    currentLocation(newVal, oldVal) {
      if (!oldVal || newVal.uuid !== oldVal.uuid) {
        this.fetchConceptProducts({ location: this.currentLocation });
        if (this.useLocation) {
          this.fetchAllProduct();
        }
      }
    },
  },
  mounted() {
    if (this.$store.state.products.summary === null || this.$store.state.products.summary.length == 0) {
      this.fetchAllProduct();
    }
    this.getSuppliers();
    this.getConcepts();
    this.getConceptsProducts();
    this.getUnnestedLocations();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}
.concept {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__label {
    margin-left: 12px;
  }
}
</style>
