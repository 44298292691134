<template>
  <div>
    <div class="header">
      <h2 class="subtitle">Pictures</h2>
      <hr />
    </div>
    <div>
      <b-message type="is-danger" v-if="fetchError">
        An error has occured while fetching the platforms list.
      </b-message>
      <div v-if="!fetchError">
        <b-tabs size="is-small" type="is-boxed" horizontal destroy-on-hide>
          <b-tab-item key="logo">
            <template slot="header">
              <img :src="require(`@/assets/images/logo.png`)" width="21" />
              Logo
            </template>

            <PictureUpload content-type="logo" :picture="concept.logo" @submitFile="sendFile" />
          </b-tab-item>
          <b-tab-item v-for="platform in getUpdatablePlatforms" :key="platform.uuid">
            <template slot="header">
              <img :src="platform.logo" style="height: 21px" />
              {{ platform.label }}
            </template>

            <PictureUpload
              :content-type="platform.uuid"
              :picture="platform_picture(platform.uuid)"
              @submitFile="sendFile"
            />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import PictureUpload from '@/components/PictureUpload.vue';

export default {
  name: 'ConceptPictures',
  components: {
    PictureUpload,
  },
  props: {
    concept: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isFetchLoading: (state) => state.platforms.fetchAll.pending,
      fetchError: (state) => state.platforms.fetchAll.error,
      platforms: (state) => state.platforms.all,
    }),
    ...mapGetters('platforms', ['getUpdatablePlatforms']),
  },
  methods: {
    ...mapActions('concepts', ['uploadPicture']),
    platform_picture(platform_uuid) {
      if (this.concept.platform_pictures[platform_uuid]) {
        if (this.concept.platform_pictures[platform_uuid].formatted) {
          return this.concept.platform_pictures[platform_uuid].formatted;
        }
        return null;
      }
    },
    sendFile(contentType, file) {
      this.uploadPicture({
        conceptUuid: this.concept.uuid,
        pictureType: contentType === 'logo' ? contentType : 'platform',
        picture: file,
        platformUuid: contentType !== 'logo' ? contentType : null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 25px;
}
</style>
