import kitchenPlatformsApi from '@/apis/kitchens/platforms.js';
import { kitchenPlatformsTypes } from '../mutation-types.js';

const updatablePlatforms = ['uber', 'deliveroo', 'foodkit'];

export default {
  namespaced: true,
  state: {
    all: [],
    current: null,
    fetchAll: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [kitchenPlatformsTypes.FETCH_ALL_SUCCESS](state, platforms) {
      state.all = platforms;
      state.fetchAll.pending = false;
    },
    [kitchenPlatformsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [kitchenPlatformsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [kitchenPlatformsTypes.SET_CURRENT_PLATFORM](state, platform) {
      state.current = platform;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(kitchenPlatformsTypes.FETCH_ALL_REQUEST);

      return kitchenPlatformsApi
        .fetchAll()
        .then(({ data }) => commit(kitchenPlatformsTypes.FETCH_ALL_SUCCESS, data))
        .catch((error) => commit(kitchenPlatformsTypes.FETCH_ALL_FAILURE, error));
    },
  },
  getters: {
    getUpdatablePlatforms: (state) => {
      if (!state.all) {
        return [];
      }
      return state.all.filter((platform) => updatablePlatforms.includes(platform.id));
    },
  },
};
