import itemsAPI from '@/apis/items/items';
import costsAPI from '@/apis/pricer/costs';
import summaryAPI from '@/apis/items/summary';
import { itemsTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: () => ({
    all: [],
    current: null,
    currentRecipe: null,
    currentUsage: null,
    itemPrices: null,
    searchFilters: {
      term: '',
      types: {},
    },
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    fetchUsage: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    addChild: {
      pending: false,
      error: null,
    },
    updateChild: {
      pending: false,
      error: null,
    },
    setProduct: {
      pending: false,
      error: null,
    },
    unsetProduct: {
      pending: false,
      error: null,
    },
    addLocation: {
      pending: false,
      error: null,
    },
    fetchRecipe: {
      pending: false,
      error: null,
    },
    removeOne: {
      pending: false,
      error: null,
    },
    createLocalizedItem: {
      pending: false,
      error: null,
    },
    computeItemPrice: {
      pending: false,
      error: null,
    },
  }),
  mutations: {
    [itemsTypes.FETCH_ALL_SUCCESS](state, items) {
      state.all = items;
      state.fetchAll.pending = false;
    },
    [itemsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [itemsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [itemsTypes.FETCH_ONE_SUCCESS](state, item) {
      state.current = item;
      state.fetchOne.pending = false;
    },
    [itemsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [itemsTypes.FETCH_ONE_REQUEST](state) {
      state.fetchOne.pending = true;
    },
    [itemsTypes.FETCH_RECIPE_REQUEST](state) {
      state.currentRecipe = null;
      state.fetchRecipe.pending = true;
      state.fetchRecipe.error = null;
    },
    [itemsTypes.FETCH_RECIPE_SUCCESS](state, recipe) {
      state.fetchRecipe.pending = false;
      state.currentRecipe = recipe;
    },
    [itemsTypes.FETCH_RECIPE_FAILURE](state, error) {
      state.fetchRecipe.pending = false;
      state.fetchRecipe.error = error;
    },
    [itemsTypes.FETCH_USAGE_REQUEST](state) {
      state.currentUsage = null;
      state.fetchUsage.pending = true;
      state.fetchUsage.error = null;
    },
    [itemsTypes.FETCH_USAGE_SUCCESS](state, usage) {
      state.fetchUsage.pending = false;
      state.currentUsage = usage;
    },
    [itemsTypes.FETCH_USAGE_FAILURE](state, error) {
      state.fetchUsage.pending = false;
      state.fetchUsage.error = error;
    },
    [itemsTypes.UPDATE_ONE_SUCCESS](state, item) {
      state.current = item;
      state.updateOne.pending = false;
    },
    [itemsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.current = null;
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [itemsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
    },
    [itemsTypes.CREATE_ONE_SUCCESS](state, item) {
      state.all.push(item);
      state.current = item;
      state.createOne.pending = false;
      state.createOne.error = null;
    },
    [itemsTypes.CREATE_ONE_FAILURE](state, error) {
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [itemsTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [itemsTypes.ADD_CHILD_SUCCESS](state, item) {
      state.current = item;
      state.addChild.pending = false;
    },
    [itemsTypes.ADD_CHILD_FAILURE](state, error) {
      state.current = null;
      state.addChild.pending = false;
      state.addChild.error = error;
    },
    [itemsTypes.ADD_CHILD_REQUEST](state) {
      state.addChild.pending = true;
    },
    [itemsTypes.UPDATE_CHILD_SUCCESS](state, item) {
      state.current = item;
      state.updateChild.pending = false;
    },
    [itemsTypes.UPDATE_CHILD_FAILURE](state, error) {
      state.current = null;
      state.updateChild.pending = false;
      state.updateChild.error = error;
    },
    [itemsTypes.UPDATE_CHILD_REQUEST](state) {
      state.updateChild.pending = true;
    },
    [itemsTypes.SET_PRODUCT_SUCCESS](state, item) {
      state.current = item;
      state.setProduct.pending = false;
    },
    [itemsTypes.SET_PRODUCT_FAILURE](state, error) {
      state.current = null;
      state.setProduct.pending = false;
      state.setProduct.error = error;
    },
    [itemsTypes.SET_PRODUCT_REQUEST](state) {
      state.setProduct.pending = true;
    },
    [itemsTypes.UNSET_PRODUCT_SUCCESS](state, item) {
      state.current = item;
      state.unsetProduct.pending = false;
    },
    [itemsTypes.UNSET_PRODUCT_FAILURE](state, error) {
      state.current = null;
      state.unsetProduct.pending = false;
      state.unsetProduct.error = error;
    },
    [itemsTypes.UNSET_PRODUCT_REQUEST](state) {
      state.unsetProduct.pending = true;
    },
    [itemsTypes.ADD_LOCATION_SUCCESS](state, item) {
      state.current = item;
      state.addLocation.pending = false;
    },
    [itemsTypes.ADD_LOCATION_FAILURE](state, error) {
      state.current = null;
      state.addLocation.pending = false;
      state.addLocation.error = error;
    },
    [itemsTypes.ADD_LOCATION_REQUEST](state) {
      state.addLocation.pending = true;
    },
    [itemsTypes.SET_CURRENT](state, item) {
      state.current = item ? { ...item } : null;
    },
    [itemsTypes.SET_SEARCH_FILTERS](state, searchFilters) {
      state.searchFilters = searchFilters;
    },
    [itemsTypes.REMOVE_ONE_REQUEST](state) {
      state.removeOne.pending = true;
    },
    [itemsTypes.REMOVE_ONE_SUCCESS](state, uuid) {
      state.all.splice(
        state.all.findIndex((item) => uuid === item.uuid),
        1
      );
      state.removeOne.pending = false;
    },
    [itemsTypes.REMOVE_ONE_FAILURE](state, error) {
      state.removeOne.error = error;
      state.removeOne.pending = false;
    },
    [itemsTypes.CREATE_LOCALIZED_ITEM_SUCCESS](state, item) {
      state.current = item;
      state.createLocalizedItem.pending = false;
    },
    [itemsTypes.CREATE_LOCALIZED_ITEM_FAILURE](state, error) {
      state.current = null;
      state.createLocalizedItem.pending = false;
      state.createLocalizedItem.error = error;
    },
    [itemsTypes.CREATE_LOCALIZED_ITEM_REQUEST](state) {
      state.createLocalizedItem.pending = true;
    },
    [itemsTypes.COMPUTE_PRICE_ITEM_REQUEST](state) {
      state.computeItemPrice.pending = true;
      state.computeItemPrice.error = null;
    },
    [itemsTypes.COMPUTE_PRICE_ITEM_SUCCESS](state, prices) {
      state.itemPrices = prices.data;
      state.computeItemPrice.pending = false;
    },
    [itemsTypes.COMPUTE_PRICE_ITEM_FAILURE](state, error) {
      state.itemPrices = null;
      state.computeItemPrice.pending = false;
      state.computeItemPrice.error = error;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(itemsTypes.FETCH_ALL_REQUEST);

      return itemsAPI
        .fetchAll()
        .then((items) => commit(itemsTypes.FETCH_ALL_SUCCESS, items))
        .catch((error) => commit(itemsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, uuid) {
      commit(itemsTypes.FETCH_ONE_REQUEST);

      return itemsAPI
        .fetchOne(uuid)
        .then((item) => commit(itemsTypes.FETCH_ONE_SUCCESS, item))
        .catch((error) => commit(itemsTypes.FETCH_ONE_FAILURE, error));
    },
    async fetchRecipe({ commit, state, rootState }, { quantity, unit }) {
      commit(itemsTypes.FETCH_RECIPE_REQUEST);

      return itemsAPI
        .fetchRecipe(
          state.current.generic_uuid ? state.current.generic_uuid : state.current.uuid,
          rootState.locations.current.location_tree,
          {
            quantity,
            unit,
          }
        )
        .then((recipe) => commit(itemsTypes.FETCH_RECIPE_SUCCESS, recipe))
        .catch((error) => commit(itemsTypes.FETCH_RECIPE_FAILURE, error));
    },
    async fetchUsage({ commit, state }) {
      commit(itemsTypes.FETCH_USAGE_REQUEST);

      return summaryAPI
        .fetchItemUsage(state.current.uuid)
        .then((usage) => commit(itemsTypes.FETCH_USAGE_SUCCESS, usage))
        .catch((error) => commit(itemsTypes.FETCH_USAGE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(itemsTypes.UPDATE_ONE_REQUEST);

      // If we update an item from its localized version,
      // We use the generic_uuid as the item uuid parameter.
      // When the update is completed,
      // Because the generic version is returned, we re-fetch the localized item.
      // Otherwise if we use the generic version, classical update.

      const data = { ...state.current };

      delete data.uuid;
      delete data.location_uuids;
      delete data.type;
      delete data.product_uuid;
      delete data.item_uuids;
      delete data.location_uuid;
      delete data.recipe;
      delete data.recipe_uuids;
      delete data.generic_uuid;
      delete data.link_to_menu_item;
      delete data.menu_item_uuids;
      delete data.locations;

      return itemsAPI
        .updateOne(state.current.generic_uuid ? state.current.generic_uuid : state.current.uuid, data)
        .then((item) => {
          if (state.current.generic_uuid) {
            return itemsAPI.fetchOne(state.current.uuid).then((item) => commit(itemsTypes.UPDATE_ONE_SUCCESS, item));
          }

          return commit(itemsTypes.UPDATE_ONE_SUCCESS, item);
        })
        .catch((error) => commit(itemsTypes.UPDATE_ONE_FAILURE, error));
    },
    async createOne({ commit, state }) {
      commit(itemsTypes.CREATE_ONE_REQUEST);

      return itemsAPI
        .createOne(state.current)
        .then((item) => commit(itemsTypes.CREATE_ONE_SUCCESS, item))
        .catch((error) => commit(itemsTypes.CREATE_ONE_FAILURE, error));
    },
    async updateChild({ commit, state, rootState }, { quantity, unit, itemUuid }) {
      commit(itemsTypes.UPDATE_CHILD_REQUEST);

      return itemsAPI
        .updateChild(state.current.uuid, {
          location_uuid: rootState.locations.current.uuid,
          quantity,
          unit,
          item_uuid: itemUuid,
        })
        .then((item) => commit(itemsTypes.UPDATE_CHILD_SUCCESS, item))
        .catch((error) => commit(itemsTypes.UPDATE_CHILD_FAILURE, error));
    },
    async setProduct({ commit, state, rootState }, productUuid) {
      commit(itemsTypes.SET_PRODUCT_REQUEST);

      return itemsAPI
        .setProduct(state.current.uuid, { location_uuid: rootState.locations.current.uuid, product_uuid: productUuid })
        .then((item) => commit(itemsTypes.SET_PRODUCT_SUCCESS, item))
        .catch((error) => commit(itemsTypes.SET_PRODUCT_FAILURE, error));
    },
    async unsetProduct({ commit, state, rootState }, productUuid) {
      commit(itemsTypes.UNSET_PRODUCT_REQUEST);

      return itemsAPI
        .unsetProduct(state.current.uuid, {
          location_uuid: rootState.locations.current.uuid,
          product_uuid: productUuid,
        })
        .then((item) => commit(itemsTypes.UNSET_PRODUCT_SUCCESS, item))
        .catch((error) => commit(itemsTypes.UNSET_PRODUCT_FAILURE, error));
    },
    async addLocation({ commit, state, rootState }) {
      commit(itemsTypes.ADD_LOCATION_REQUEST);

      // If we update an item from its localized version,
      // We use the generic_uuid as the item uuid parameter.

      return itemsAPI
        .addLocation(
          state.current.generic_uuid ? state.current.generic_uuid : state.current.uuid,
          rootState.locations.current.uuid
        )
        .then((item) => commit(itemsTypes.ADD_LOCATION_SUCCESS, item))
        .catch((error) => commit(itemsTypes.ADD_LOCATION_FAILURE, error));
    },
    async addToParent({ commit, state, rootState }, { parentItemUuid, quantity, unit }) {
      commit(itemsTypes.ADD_CHILD_REQUEST);

      return itemsAPI
        .addChild(parentItemUuid, {
          location_uuid: rootState.locations.current.uuid,
          quantity,
          unit,
          item_uuid: state.current.uuid,
        })
        .then((item) => commit(itemsTypes.ADD_CHILD_SUCCESS, item))
        .catch((error) => commit(itemsTypes.ADD_CHILD_FAILURE, error));
    },
    // async removeChild({ commit, state, rootState }, { itemUuid }) {
    //   commit(itemsTypes.REMOVE_CHILD_REQUEST);

    //   return itemsAPI
    //     .addChild(state.current.uuid, { location_uuid: rootState.locations.current.uuid, child_uuid: itemUuid })
    //     .then((item) => commit(itemsTypes.REMOVE_CHILD_SUCCESS, item))
    //     .catch((error) => commit(itemsTypes.REMOVE_CHILD_FAILURE, error));
    // },
    removeOne({ commit }, uuid) {
      commit(itemsTypes.REMOVE_ONE_REQUEST);

      return itemsAPI
        .removeOne(uuid)
        .then(() => commit(itemsTypes.REMOVE_ONE_SUCCESS, uuid))
        .catch((error) => commit(itemsTypes.REMOVE_ONE_FAILURE, error));
    },
    buildOne({ commit }) {
      const item = itemsAPI.buildOne();

      commit(itemsTypes.SET_CURRENT, item);
    },
    async createLocalizedItem({ commit }, opts) {
      var itemUuid = opts.itemGenericUuid;
      delete opts.itemGenericUuid;
      commit(itemsTypes.CREATE_LOCALIZED_ITEM_REQUEST);
      return itemsAPI
        .createLocalizedItem(itemUuid, opts)
        .then((item) => commit(itemsTypes.CREATE_LOCALIZED_ITEM_SUCCESS, item))
        .catch((error) => commit(itemsTypes.CREATE_LOCALIZED_ITEM_FAILURE, error));
    },
    async addLocationItem({ commit, rootState }, genericUUID) {
      commit(itemsTypes.ADD_LOCATION_REQUEST);
      return itemsAPI
        .addLocation(genericUUID, rootState.locations.current.uuid)
        .then((item) => commit(itemsTypes.ADD_LOCATION_SUCCESS, item))
        .catch((error) => commit(itemsTypes.ADD_LOCATION_FAILURE, error));
    },
    async computeItemPrice({ commit }, opts) {
      commit(itemsTypes.COMPUTE_PRICE_ITEM_REQUEST);
      opts.type = 'ITEM';
      return costsAPI
        .compute(opts)
        .then((prices) => {
          commit(itemsTypes.COMPUTE_PRICE_ITEM_SUCCESS, prices);
          return { prices: prices.data, opts: opts };
        })
        .catch((error) => {
          commit(itemsTypes.COMPUTE_PRICE_ITEM_FAILURE, error);
          return { prices: null, opts: opts };
        });
    },
  },
};
