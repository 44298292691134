<template>
  <div class="box modal-card" style="overflow: visible">
    <h3 class="subtitle">Add a Modifier Group</h3>
    <b-field>
      <b-autocomplete
        :loading="isFetchLoading"
        expanded
        rounded
        v-model="searchTerm"
        :data="filteredData"
        placeholder="Search for an existing Modifier Group..."
        icon="magnify"
        field="label"
        @select="({ uuid }) => $emit('close', uuid)"
      >
        <template slot="empty">No results found</template>
      </b-autocomplete>
    </b-field>
    <div class="is-divider" data-content="OR"></div>
    <b-field
      label="Internal Label"
      label-position="on-border"
      :message="saveError && saveError.body.detail"
      :type="saveError ? 'is-danger' : ''"
    >
      <b-input placeholder="Chicken" v-model="label"></b-input>
    </b-field>
    <b-button class="button is-primary" :loading="isSaveLoading" @click="createOne">Create</b-button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { errorToaster } from '@/mixins';
import { modifierGroupsTypes } from '@/store/mutation-types';

export default {
  name: 'ChooseModifierGroup',
  mixins: [errorToaster],
  data: () => ({
    searchTerm: '',
  }),
  watch: {
    isSaveLoading() {
      if (!this.isSaveLoading && !this.saveError) {
        this.$emit('close', this.modifierGroup.uuid);
      }
    },
  },
  computed: {
    ...mapState({
      modifierGroup: (state) => state.modifierGroups.current,
      modifierGroups: (state) => state.modifierGroups.all,
      isFetchLoading: (state) => state.modifierGroups.fetchAll.pending,
      isSaveLoading: (state) => state.modifierGroups.createOne.pending,
      saveError: (state) => state.modifierGroups.createOne.error,
    }),
    label: {
      get() {
        return this.modifierGroup.label;
      },
      set(value) {
        this.setCurrent({ label: value });
      },
    },
    filteredData() {
      return (
        this.modifierGroups &&
        this.modifierGroups.filter((option) => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()))
      );
    },
  },
  methods: {
    ...mapActions('modifierGroups', ['buildOne', 'fetchAll', 'createOne']),
    ...mapMutations('modifierGroups', { setCurrent: modifierGroupsTypes.SET_CURRENT }),
  },
  created() {
    this.buildOne();
    if (this.$store.state.modifierGroups.all === null || this.$store.state.modifierGroups.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>
