<template>
  <div>
    <b-navbar shadow>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/images/logo.png" alt="Taster Logo" />
        </b-navbar-item>
      </template>
    </b-navbar>
    <section class="section">
      <p class="title is-3 is-spaced">Not authorized</p>
      <article class="message is-danger">
        <div class="message-body">
          You don't have access to this application. Please contact
          <a href="mailto:engineers@taster.com">engineers@taster.com</a>.
        </div>
      </article>
      <button class="button is-danger" @click="logout" v-if="isLoggedIn">
        <span class="icon">
          <i class="mdi mdi-logout"></i>
        </span>
        <span>Logout</span>
      </button>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$router.push('/');
      this.$auth.logout();
    },
  },
};
</script>
