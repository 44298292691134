<template>
  <div
    class="item-row"
    :style="isSelected ? 'background: rgba(0,0,0,0.04);' : ''"
    @click="$emit('selectItem')"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="name-col" :style="level ? 'padding-left:' + level * 24 + 'px' : 'padding-left: 0px'">
      <div :class="[isValid(ingredient.item) ? 'item-row--valid' : 'item-row--error']">
        <div class="center">
          {{ ingredient.item.label }}
          <span @click.stop="$emit('expand')">
            <b-icon
              icon="chevron-down"
              size="is-small"
              v-if="ingredient.item.recipe && ingredient.item.recipe.length"
            />
          </span>
          <b-tooltip
            :label="ingredient.status"
            size="is-small"
            multilined
            v-if="ingredient.status !== 'success'"
            type="is-light"
          >
            <b-icon style="margin: auto; padding-left: 4px" icon="alert" size="is-small"></b-icon>
          </b-tooltip>
          <b-button
            v-if="hover"
            size="is-small"
            @click.stop="() => toPage(`/items/${ingredient.item.generic_uuid}`, true)"
            icon-right="open-in-new"
            type="is-text"
            rounded
          />
        </div>
      </div>
      <div class="center">
        <b-button
          class="remove-button"
          @click.stop="$emit('removeItem')"
          icon-right="close"
          rounded
          v-if="showRemove"
        />
        <b-button
          class="compose-button is-primary"
          v-if="canCompose && isItemRecipeCreator()"
          :disabled="ingredient.item.uuid === ingredient.item.generic_uuid"
          @click.stop="$emit('addItem')"
          icon-left="plus"
          rounded
          outlined
        >
          Compose
        </b-button>
      </div>
    </div>
    <div class="quantity-col">
      <span>{{
        ingredient.quantity
          ? ingredient.quantity
          : ingredient.item.item_measure
          ? ingredient.item.item_measure[0].quantity
          : ' '
      }}</span>
    </div>

    <div class="unit-col">
      <span>{{
        ingredient.unit ? ingredient.unit : ingredient.item.item_measure ? ingredient.item.item_measure[0].unit : ' '
      }}</span>
    </div>
    <div class="unit-col has-text-danger">
      <span v-if="isSupplierPriceDefined(cost)"> {{ getSupplierPrice(cost) }} </span>
      <div class="is-flex" style="justify-content: space-around" v-if="!isTimeout && !cost && cost !== 0">
        <span class="loader"></span>
      </div>
      <b-tooltip
        label="Pricing error"
        size="is-small"
        type="is-light"
        class="has-text-dark"
        v-if="isTimeout && !cost && cost !== 0"
      >
        <b-icon icon="alert" size="is-small"></b-icon>
      </b-tooltip>
    </div>
    <div class="unit-col has-text-danger">
      <span v-if="areLogisticsCostsDefined(cost)"> {{ getLogisticsCosts(cost) }} </span>
      <div class="is-flex" style="justify-content: space-around" v-if="!isTimeout && !cost && cost !== 0">
        <span class="loader"></span>
      </div>
      <b-tooltip
        label="Pricing error"
        size="is-small"
        type="is-light"
        class="has-text-dark"
        v-if="isTimeout && !cost && cost !== 0"
      >
        <b-icon icon="alert" size="is-small"></b-icon>
      </b-tooltip>
    </div>
    <div class="unit-col has-text-danger">
      <span v-if="isKitchenPriceDefined(cost)"> {{ getKitchenPrice(cost) }} </span>
      <div class="is-flex" style="justify-content: space-around" v-if="!isTimeout && !cost && cost !== 0">
        <span class="loader"></span>
      </div>
      <b-tooltip
        label="Pricing error"
        size="is-small"
        type="is-light"
        class="has-text-dark"
        v-if="isTimeout && !cost && cost !== 0"
      >
        <b-icon icon="alert" size="is-small"></b-icon>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { auth, displayFormatter } from '@/mixins';
import { mapState } from 'vuex';

export default {
  mixins: [auth, displayFormatter],
  props: ['ingredient', 'level', 'isSelected', 'cost', 'canCompose', 'showRemove'],
  data: () => ({
    hover: false,
    isTimeout: false,
  }),
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
    }),
    devise() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
  },
  methods: {
    isSupplierPriceDefined(cost) {
      if (cost && (cost.price || cost.price === 0)) {
        return true;
      }
      return false;
    },
    getSupplierPrice(cost) {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (cost && cost.price) {
        return `${cost.price.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    isKitchenPriceDefined(cost) {
      if (cost && (cost.kitchen_price || cost.kitchen_price === 0)) {
        return true;
      }
      return false;
    },
    getKitchenPrice(cost) {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (cost && cost.kitchen_price) {
        return `${cost.kitchen_price.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    areLogisticsCostsDefined(cost) {
      if (cost && (cost.logistics_costs || cost.logistics_costs === 0)) {
        return true;
      }
      return false;
    },
    getLogisticsCosts(cost) {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (cost && cost.logistics_costs) {
        return `${cost.logistics_costs.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
    isValid(item) {
      if (item.product_uuid) {
        return true;
      }

      if (item.recipe && item.recipe.length) {
        return item.recipe
          .map((ingredient) => this.isValid(ingredient.item))
          .flat()
          .reduce((isValid, result) => isValid && result, true);
      } else {
        return false;
      }
    },
    toPage(suffix, newPage) {
      var routeData = this.$router.resolve(suffix);
      if (newPage) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(suffix);
      }
    },
  },
  mounted() {
    setTimeout(() => (this.isTimeout = true), 15000);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.center {
  margin-bottom: auto;
  margin-top: auto;
}
.item-row {
  min-height: 2.25em;
  display: flex;
  margin-bottom: 2px;

  &--error {
    border-left: 3px solid $danger;
    padding-left: 6px;
    display: flex;
  }

  &--valid {
    border-left: 3px solid $success;
    padding-left: 6px;
    display: flex;
  }

  &:hover {
    cursor: pointer;
  }
}
.item-row:hover {
  background: rgba(0, 0, 0, 0.04);
}
.name-col {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.quantity-col,
.unit-col {
  width: 100px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin: auto;
}
.compose-button,
.remove-button {
  font-size: 0.7rem;
  font-weight: bold;
  margin-right: 2px;
}
</style>
