import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const actionPlansApi = new PurchaseOrdersClient.ActionPlansApi();
actionPlansApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  fetchActionPlans(params) {
    return clientHook(actionPlansApi).appApisV1ActionPlansListActionPlans(params);
  },
  deleteActionPlan(planUuid) {
    return clientHook(actionPlansApi).appApisV1ActionPlansDeleteActionPlan(planUuid);
  },
  createActionPlan(body) {
    return clientHook(actionPlansApi).appApisV1ActionPlansCreateActionPlan(body);
  },
  createActionPlanStep(planUuid, addActionToPlanRequest) {
    return clientHook(actionPlansApi).appApisV1ActionPlansAddStepToActionPlan(planUuid, {
      addActionToPlanRequest,
    });
  },
};
