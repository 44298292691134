<template>
  <div>
    <b-field style="display: flex; flex-direction: row-reverse">
      <b-button size="is-small" icon-left="delete" outlined @click="$emit('remove')"> Remove </b-button>
    </b-field>
    <b-field grouped>
      <b-field label="Label" label-position="on-border" expanded>
        <b-input size="is-small" v-model="value.label" required />
      </b-field>
      <b-field label="Included" label-position="on-border" style="max-width: 100px">
        <b-input size="is-small" type="number" step="1" v-model.number="value.included_number" required />
      </b-field>
      <b-field label="Max" label-position="on-border" style="max-width: 100px">
        <b-input size="is-small" type="number" step="1" v-model.number="value.max_number" required />
      </b-field>
    </b-field>

    <b-field>
      <b-autocomplete
        size="is-small"
        :data="filteredData"
        v-model="searchTerm"
        expanded
        placeholder="Add a menu item..."
        icon="plus"
        field="label"
        clear-on-select
        @select="addMenuItem"
      >
        <template slot="empty">No results found</template>
      </b-autocomplete>
    </b-field>
    <Container
      @drop="onDrop"
      drag-handle-selector=".column-drag-handle"
      :get-child-payload="(itemIndex) => getChildPayload(value)"
    >
      <Draggable v-for="menuItem in value.menu_items" :key="menuItem.uuid">
        <b-field class="modifier" grouped>
          <div class="modifier-fields">
            <b-field class="column-drag-handle is-size-9 modifier-fields-label">
              <h1>&#x2630; {{ menuItem.label }}</h1>
              <b-tag type="is-info">
                {{ menuItem.category }}
              </b-tag>
            </b-field>
            <b-field class="modifier-fields-price">
              <b-input v-model="menuItem.selling_unit_price" size="is-small" disabled>{{
                currentLocation.location_country.currency
              }}</b-input>
              <b-input
                style="max-width: 100px"
                size="is-small"
                type="number"
                placeholder="0,00"
                step="0.01"
                v-model.number="menuItem.selling_price"
              ></b-input>
            </b-field>
          </div>
          <b-button size="is-small" @click="remove(menuItem.uuid)">
            <b-icon icon="delete" size="is-small" />
          </b-button>
        </b-field>
      </Draggable>
    </Container>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { DragAndDrop } from '@/mixins';
import { mapState } from 'vuex';

export default {
  mixins: [DragAndDrop],
  props: ['modifierGroupLocation', 'menuItems'],
  components: { Container, Draggable },
  data() {
    return {
      searchTerm: '',
      value: { ...this.modifierGroupLocation },
    };
  },
  watch: {
    value: {
      handler() {
        this.$emit('update:modifierGroupLocation', { ...this.value });
      },
      deep: true,
    },
  },
  methods: {
    addMenuItem(menuItem) {
      if (menuItem) {
        this.value.menu_items.push({
          uuid: menuItem.uuid,
          label: menuItem.label,
          selling_unit_price: this.currentLocation.location_country.currency,
          category: menuItem.category,
        });
      }
    },
    remove(menuItemUuid) {
      const index = this.value.menu_items.findIndex(({ uuid }) => uuid == menuItemUuid);

      this.value.menu_items.splice(index, 1);
    },
    onDrop(dropResult) {
      this.value.menu_items = this.applyDrag(this.value.menu_items, dropResult);
    },
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentConcept: (state) => state.concepts.current,
    }),
    filteredData() {
      if (this.currentConcept && Object.keys(this.currentConcept).length > 0) {
        var conceptMenuItems = this.menuItems.filter((menuItem) =>
          menuItem.concept_uuids.includes(this.currentConcept.uuid)
        );
        return conceptMenuItems.filter((menuItem) =>
          menuItem.label.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      return this.menuItems.filter((menuItem) => menuItem.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
};
</script>

<style lang="scss" scoped>
.modifier {
  &-fields {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0%;

    &-label {
      flex: 0.7 1 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-price {
      flex: 0.3 1 0;
    }
  }
}
</style>
