<template>
  <div>
    <b-field grouped>
      <b-field label="Internal Label" expanded label-position="on-border">
        <b-input placeholder="Label" v-model="computedLabel"></b-input>
      </b-field>
      <b-field label="Category" label-position="on-border" v-if="displayCategory">
        <b-select placeholder="Category" v-model="computedCategory">
          <option v-for="category in categories" :value="category.label" :key="category.label">
            {{ category.label }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Tags" label-position="on-border">
        <b-taginput
          v-model="computedTags"
          :data="menuItemTags"
          maxtags="1"
          readonly
          ellipsis
          icon="label"
          :open-on-focus="openOnFocus"
          placeholder="Add a tag"
        >
        </b-taginput>
      </b-field>
      <b-field label="MEP" label-position="on-border" v-if="displayMep">
        <b-select placeholder="MEP" v-model="computedMep">
          <option v-for="mepValue in mepValues" :value="mepValue" :key="mepValue">
            {{ mepValue }}
          </option>
        </b-select>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  props: ['label', 'category', 'tags', 'categories', 'menuItemTags', 'mep', 'displayMep', 'displayCategory'],
  data() {
    return {
      mepValues: [true, false],
      newLabel: this.label,
      newMep: this.mep,
      newCategory: this.category,
      newTags: this.tags ? [...this.tags] : [],
      openOnFocus: true,
    };
  },
  watch: {
    label(value) {
      this.newLabel = value;
    },
    category(value) {
      this.newCategory = value;
    },
    tags(value) {
      this.newTags = value ? [...value] : [];
    },
  },
  computed: {
    computedLabel: {
      get() {
        return this.newLabel;
      },
      set(value) {
        this.newLabel = value;
        this.$emit('update:label', value);
      },
    },
    computedMep: {
      get() {
        return this.newMep;
      },
      set(value) {
        this.newMep = value;
        this.$emit('update:mep', value);
      },
    },
    computedCategory: {
      get() {
        return this.newCategory;
      },
      set(value) {
        this.newCategory = value;
        this.$emit('update:category', value);
      },
    },
    computedTags: {
      get() {
        return this.newTags;
      },
      set(value) {
        this.newTags = value;
        this.$emit('update:tags', value);
      },
    },
  },
};
</script>
