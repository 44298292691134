<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.navbar-item.is-active {
  font-weight: 500;
  background-color: #fafafa !important;
}
</style>
