<template>
  <div class="box modal-card">
    <h3 class="title is-5">Create Recipe</h3>
    <ItemForm :item.sync="item" @save="create" buttonMessage="Create" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { errorToasterDetailled } from '../../mixins';

import ItemForm from '@/components/ItemForm.vue';

export default {
  mixins: [errorToasterDetailled],
  props: [],
  data: () => ({
    quantity: 0,
    unit: 'g',
  }),
  watch: {},
  components: { ItemForm },
  methods: {
    ...mapActions('items', ['buildOne', 'createOne', 'addLocation']),
    ...mapMutations('items', { setCurrent: 'SET_CURRENT' }),
    create() {
      this.createOne().then(() => {
        if (!this.saveError) {
          this.addLocation().then(() => {
            this.$emit('done', this.current.generic_uuid);
            this.$parent.close();
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      current: (state) => state.items.current,
      currentLocation: (state) => state.locations.current,
      saveError: (state) => state.items.createOne.error || state.items.addLocation.error,
      isSaveLoading: (state) => state.items.createOne.pending,
    }),
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  created() {
    this.buildOne();
  },
};
</script>
