import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const actionsApi = new PurchaseOrdersClient.ActionsApi();
actionsApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  fetchActions(params) {
    return clientHook(actionsApi).appApisV1ActionsListActions(params);
  },
};
