<template>
  <section class="section">
    <b-message type="is-danger" v-if="fetchError">
      {{ fetchError.body.detail }}
    </b-message>
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <div class="container" v-if="product && currentLocation">
      <div class="columns">
        <div class="column">
          <h1 class="title">{{ product.label }}</h1>
        </div>
        <div class="column is-narrow">
          <b-button disabled class="is-link" icon-left="pencil" style="margin-left: 8px" @click="openEditProductDialog">
            Edit
          </b-button>
          <b-button
            class="is-danger"
            style="margin-left: 8px"
            icon-left="delete"
            @click="openRemoveProductDialog"
            v-if="isParentLocation && usage"
          >
            Delete
          </b-button>
          <b-button
            class="is-danger"
            style="margin-left: 8px"
            icon-left="delete"
            @click="openRemoveLocalizedInformationProductDialog"
            v-if="!isParentLocation && areLocalizedInformations"
          >
            Delete {{ currentLocation.id }} Info
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <div class="card">
            <header class="card-header" style="background-color: hsl(0, 0%, 96%)">
              <p class="card-header-title is-centered">Global Informations</p>
            </header>
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <h2 class="title is-6">Supplier</h2>
                    <router-link :to="'/suppliers/' + product.supplier.uuid">{{ product.supplier.label }}</router-link>
                  </div>
                  <div class="column">
                    <h5 class="title is-6">Reference</h5>
                    <div>{{ product.product_reference }}</div>
                  </div>
                  <div class="column">
                    <h5 class="title is-6">Category</h5>
                    <div>{{ product.category.label }}</div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <h5 class="title is-6">Yield Ratio</h5>
                  <div>{{ product.yield_ratio }}</div>
                </div>
                <div class="column">
                  <h5 class="title is-6">Shelf Life</h5>
                  <div>{{ product.shelf_life }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="card">
            <header class="card-header" style="background-color: hsl(0, 0%, 96%)">
              <p class="card-header-title is-centered" style="text-align: center">Conversions</p>
            </header>
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <p class="title is-6">Base Unit</p>
                    <div>{{ product.base_unit }}</div>
                  </div>
                  <div class="column">
                    <p class="title is-6">Catalogue Unit</p>
                    <div>{{ getCatalogueUnit() }}</div>
                  </div>
                  <div class="column">
                    <p class="title is-6">Packaging Unit</p>
                    <div>{{ getPackagingUnit() }}</div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <p class="title is-6">Batch Size</p>
                    <div>{{ getBatchSize() }}</div>
                  </div>
                  <div class="column">
                    <p class="title is-6">Gross Weight</p>
                    <div>
                      {{ getLocalizedInformation(this.currentLocation).gross_weight_per_packaging_unit || '-' }}
                    </div>
                  </div>
                </div>
                <table class="table is-hoverable">
                  <thead>
                    <tr>
                      <th>Quantity</th>
                      <th>Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(conversion, index) in product.conversions" :key="index">
                      <td>{{ conversion.quantity }}</td>
                      <td>{{ conversion.unit }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <div class="card">
            <header class="card-header" style="background-color: hsl(0, 0%, 96%)">
              <p class="card-header-title is-centered">Localized Informations</p>
            </header>
            <div class="card-content">
              <div class="content">
                <b-table
                  class="is-hoverable is-fullwidth is-striped"
                  hoverable
                  striped
                  :data="localizedInformations"
                  default-sort="label"
                  default-sort-direction="asc"
                >
                  <template slot-scope="props">
                    <b-table-column field="location" label="Location" centered sortable>
                      <b-tag type="is-info" size="small"> {{ props.row.location_id }} </b-tag>
                      <p v-if="props.row.labelSelected" class="has-text-success">{{ props.row.label }}</p>
                      <p v-else type="is-dark">{{ props.row.label }}</p>
                    </b-table-column>
                    <b-table-column field="supplier_price" label="Supplier Price" centered>
                      <p class="has-text-success" v-if="props.row.supplierPriceSelected">
                        {{ props.row.supplierPrice }}
                      </p>
                      <p v-else>{{ props.row.supplierPrice }}</p>
                    </b-table-column>
                    <b-table-column field="logistics_costs" label="Logistics Costs" centered>
                      <p class="has-text-success" v-if="props.row.logisticsCostsSelected">
                        {{ props.row.logisticsCosts }}
                      </p>
                      <p v-else>{{ props.row.logisticsCosts }}</p>
                    </b-table-column>
                    <b-table-column field="kitchen_price" label="Kitchen Price" centered>
                      <p class="has-text-success" v-if="props.row.kitchenPriceSelected">{{ props.row.kitchenPrice }}</p>
                      <p v-else>{{ props.row.kitchenPrice }}</p>
                    </b-table-column>
                    <b-table-column field="vat_rate" label="VAT" centered>
                      <p class="has-text-success" v-if="props.row.vatRateSelected">{{ props.row.vatRate }}</p>
                      <p v-else>{{ props.row.vatRate }}</p>
                    </b-table-column>
                    <b-table-column field="orderable" label="Orderable" centered>
                      <p v-if="props.row.orderableSelected" class="has-text-success">{{ props.row.orderable }}</p>
                      <p v-else type="is-dark">{{ props.row.orderable }}</p>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <div class="card availability-wrapper">
            <ProductAvailability
              :levels="availabilityLevels"
              :selectedLevel.sync="selectedLevel"
              :locationsAvailability="locationsAvailability"
              :isSwitchAll="isSwitchAll"
              :isSwitchAllLoading="isSwitchAllLoading"
              :isLocationsAvailabilityLoading="isLocationsAvailabilityLoading"
              :isAvailabilityLoading="isAvailabilityLoading"
              :parentLocationLabel="parentLocationlabel"
              @update:out-of-stock="updateOutOfStock"
              @select-all="updateAllLocationsOutOfStock"
              @update:selected-level="updateSelectedLevel"
            />
          </div>
        </div>
        <div class="column">
          <div class="card">
            <header class="card-header" style="background-color: hsl(0, 0%, 96%)">
              <p class="card-header-title is-centered">Usages</p>
            </header>
            <UsageTabs :usage="usage" source="product" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { displayFormatter } from '@/mixins';
import EditProduct from './EditProduct.vue';
import UsageTabs from '@/components/UsageTabs.vue';
import ProductAvailability from '@/components/Product/ProductAvailability.vue';
import { keyBy, uniqBy, chunk } from 'lodash';

export default {
  mixins: [displayFormatter],
  components: { UsageTabs, ProductAvailability },
  data() {
    return {
      noAvailabilityLevels: false,
      isAvailabilityLoading: true,
      availabilityLevels: [],
      selectedLevel: null,
      isSwitchAll: false,
      isSwitchAllLoading: false,
      locationsAvailability: [],
      isLocationsAvailabilityLoading: false,
    };
  },
  computed: {
    ...mapState({
      product: (state) => state.products.current,
      usage: (state) => state.products.currentUsage,
      isLoading: (state) =>
        state.products.fetchOne.pending || state.products.updateOne.pending || state.products.removeOne.pending,
      fetchError: (state) => state.products.fetchOne.error,
      currentLocation: (state) => state.locations.current,
      locationsUnnested: (state) => state.locations.allUnnested,
      parentLocations: (state) => state.locations.all,
      location: (state) => state.locations.current,
    }),
    locationsAvailabilityMapLocationUuid() {
      return keyBy(this.locationsAvailability, 'uuid');
    },
    isParentLocation() {
      return !this.currentLocation.parent_uuid;
    },
    areLocalizedInformations() {
      if (this.product && this.product.localized_informations && this.product.localized_informations.length > 0) {
        let localizedInformation = this.product.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
        );
        if (localizedInformation.length > 0) {
          return true;
        }
      }
      return false;
    },
    parentLocationlabel() {
      if (this.location && this.location.parent_uuid) {
        const parent = this.parentLocations.find(({ uuid }) => uuid === this.location.parent_uuid);

        return parent.label;
      }
      return this.location.label;
    },
    currentLocations() {
      if (this.location && this.location.parent_uuid) {
        const parent = this.parentLocations.find(({ uuid }) => uuid === this.location.parent_uuid);

        return parent.locations;
      }
      return this.location.locations;
    },
    localizedInformations() {
      let localizedInformations = [];
      let localizedInformation = {};
      if (this.currentLocation.parent_uuid === null) {
        for (let location of [this.currentLocation, ...this.currentLocation.locations]) {
          localizedInformation = this.getLocalizedInformation(location);
          if (
            localizedInformation &&
            ((localizedInformation.kitchen_price && Object.keys(localizedInformation.kitchen_price).length > 0) ||
              (localizedInformation.supplier_price && Object.keys(localizedInformation.supplier_price).length > 0) ||
              (localizedInformation.logistics_costs && localizedInformation.logistics_costs.length > 0) ||
              localizedInformation.vat_rate != null ||
              localizedInformation.orderable != null ||
              !!localizedInformation.label)
          ) {
            localizedInformation.globalLogisticsCosts = this.getGlobalLogisticsCosts(
              localizedInformation.logistics_costs
            );
            localizedInformations.push({
              kitchenPrice: this.getKitchenPrice(localizedInformation),
              location_id: localizedInformation.location_id,
              supplierPrice: this.getSupplierPrice(localizedInformation),
              logisticsCosts: this.getTotallLogisticsCosts(localizedInformation),
              vatRate: this.getVatRate(localizedInformation),
              orderable: this.getOrderable(localizedInformation),
              kitchenPriceSelected: localizedInformation.location_uuid == this.currentLocation.uuid,
              supplierPriceSelected: localizedInformation.location_uuid == this.currentLocation.uuid,
              logisticsCostsSelected: localizedInformation.location_uuid == this.currentLocation.uuid,
              vatRateSelected: localizedInformation.location_uuid == this.currentLocation.uuid,
              orderableSelected: localizedInformation.location_uuid == this.currentLocation.uuid,
              label: localizedInformation.label,
              labelSelected: !!localizedInformation.label,
            });
          }
        }
      } else {
        let countrylocalizedInformation = this.getCountryLocalizedInformation();
        let countyLocalizedPrice = {};
        let locationLocalizedPrice = {};
        if (
          countrylocalizedInformation &&
          ((countrylocalizedInformation.kitchen_price &&
            Object.keys(countrylocalizedInformation.kitchen_price).length > 0) ||
            (countrylocalizedInformation.supplier_price &&
              Object.keys(countrylocalizedInformation.supplier_price).length > 0) ||
            (countrylocalizedInformation.logistics_costs && countrylocalizedInformation.logistics_costs.length > 0) ||
            !!countrylocalizedInformation.label)
        ) {
          countrylocalizedInformation.globalLogisticsCosts = this.getGlobalLogisticsCosts(
            countrylocalizedInformation.logistics_costs
          );
          countyLocalizedPrice = {
            kitchenPrice: this.getKitchenPrice(countrylocalizedInformation),
            location_id: countrylocalizedInformation.location_id,
            supplierPrice: this.getSupplierPrice(countrylocalizedInformation),
            logisticsCosts: this.getTotallLogisticsCosts(countrylocalizedInformation),
            vatRate: this.getVatRate(countrylocalizedInformation),
            orderable: this.getOrderable(countrylocalizedInformation),
            label: countrylocalizedInformation.label,
          };
        }
        localizedInformation = this.getLocalizedInformation(this.currentLocation);
        if (
          localizedInformation &&
          ((localizedInformation.kitchen_price && Object.keys(localizedInformation.kitchen_price).length > 0) ||
            (localizedInformation.supplier_price && Object.keys(localizedInformation.supplier_price).length > 0) ||
            (localizedInformation.logistics_costs && localizedInformation.logistics_costs.length > 0) ||
            localizedInformation.vat_rate != null ||
            localizedInformation.orderable != null ||
            !!localizedInformation.label)
        ) {
          localizedInformation.globalLogisticsCosts = this.getGlobalLogisticsCosts(
            localizedInformation.logistics_costs
          );
          locationLocalizedPrice = {
            kitchenPrice: this.getKitchenPrice(localizedInformation),
            location_id: localizedInformation.location_id,
            supplierPrice: this.getSupplierPrice(localizedInformation),
            logisticsCosts: this.getTotallLogisticsCosts(localizedInformation),
            vatRate: this.getVatRate(localizedInformation),
            orderable: this.getOrderable(localizedInformation),
            label: localizedInformation.label,
          };
        }
        if (locationLocalizedPrice.kitchenPrice && locationLocalizedPrice.kitchenPrice != '-') {
          countyLocalizedPrice.kitchenPriceSelected = false;
          locationLocalizedPrice.kitchenPriceSelected = true;
        } else {
          countyLocalizedPrice.kitchenPriceSelected = true;
          locationLocalizedPrice.kitchenPriceSelected = false;
        }
        if (locationLocalizedPrice.supplierPrice && locationLocalizedPrice.supplierPrice != '-') {
          countyLocalizedPrice.supplierPriceSelected = false;
          locationLocalizedPrice.supplierPriceSelected = true;
        } else {
          countyLocalizedPrice.supplierPriceSelected = true;
          locationLocalizedPrice.supplierPriceSelected = false;
        }
        if (locationLocalizedPrice.logisticsCosts && locationLocalizedPrice.logisticsCosts != '-') {
          countyLocalizedPrice.logisticsCostsSelected = false;
          locationLocalizedPrice.logisticsCostsSelected = true;
        } else {
          countyLocalizedPrice.logisticsCostsSelected = true;
          locationLocalizedPrice.logisticsCostsSelected = false;
        }
        localizedInformations.push(countyLocalizedPrice);
        if (
          'kitchenPrice' in locationLocalizedPrice ||
          'supplierPrice' in locationLocalizedPrice ||
          'logisticsCosts' in locationLocalizedPrice
        ) {
          localizedInformations.push(locationLocalizedPrice);
        }
        if (locationLocalizedPrice.vatRate == '-') {
          countyLocalizedPrice.vatRateSelected = true;
          locationLocalizedPrice.vatRateSelected = false;
        } else {
          countyLocalizedPrice.vatRateSelected = false;
          locationLocalizedPrice.vatRateSelected = true;
        }
        if (locationLocalizedPrice.orderable == '-') {
          countyLocalizedPrice.orderableSelected = true;
          locationLocalizedPrice.orderableSelected = false;
        } else {
          countyLocalizedPrice.orderableSelected = false;
          locationLocalizedPrice.orderableSelected = true;
        }
      }
      return localizedInformations;
    },
  },
  watch: {
    currentLocation() {
      this.setupAvailability();
    },
  },
  methods: {
    ...mapActions('products', [
      'fetchOne',
      'fetchUsage',
      'removeOne',
      'removeLocalizedInformation',
      'searchOutOfStocks',
      'enableOutOfStock',
      'disableOutOfStock',
    ]),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    setAvailabilityLevels() {
      if (this.product) {
        const { hubs, supplier } = this.product;
        let formattedHubs = [];
        let formattedSuppliers = [];

        if (hubs.length) {
          formattedHubs = uniqBy(
            hubs.map(({ uuid, label }) => ({ uuid, label, type: 'HUB' })),
            'uuid'
          );
        }

        if (supplier) {
          formattedSuppliers.push({ uuid: supplier.uuid, label: supplier.label, type: 'SUPPLIER' });
        }

        return [...formattedHubs, ...formattedSuppliers];
      }
      return [];
    },
    updateSelectedLevel(newSelectedLevel) {
      this.selectedLevel = newSelectedLevel;

      this.locationsAvailability = this.setLocationsAvailability();
      this.fetchOutOfStocks();
    },
    onOutOfStockChange() {
      this.isSwitchAll = this.locationsAvailability.every(({ isProductAvailable }) => isProductAvailable);
    },
    updateLocationsAvailability(locationUuid, { isProductAvailable, isProductAvailableLoading }) {
      const findIndex = this.locationsAvailability.findIndex(({ uuid }) => uuid === locationUuid);
      if (findIndex === -1) {
        return;
      }
      const newLocationAvailability = { ...this.locationsAvailability[findIndex] };

      if (typeof isProductAvailable !== 'undefined') {
        newLocationAvailability.isProductAvailable = isProductAvailable;
      }
      if (typeof isProductAvailableLoading !== 'undefined') {
        newLocationAvailability.isProductAvailableLoading = isProductAvailableLoading;
      }

      this.locationsAvailability.splice(findIndex, 1, newLocationAvailability);
    },
    async updateAllLocationsBatches(batches, isSelectAll) {
      const errorlabels = [];

      for (const batch of batches) {
        const results = await Promise.allSettled(
          batch.map(async ({ uuid }) => {
            const request = {
              location_uuid: uuid,
              product_uuid: this.product.uuid,
              provider_type: this.selectedLevel.type,
              provider_uuid: this.selectedLevel.uuid,
            };
            try {
              this.updateLocationsAvailability(uuid, { isProductAvailableLoading: true });

              isSelectAll ? await this.disableOutOfStock(request) : await this.enableOutOfStock(request);

              this.updateLocationsAvailability(uuid, { isProductAvailable: isSelectAll });
            } catch (err) {
              this.updateLocationsAvailability(uuid, { isProductAvailable: !isSelectAll });
              throw err;
            } finally {
              this.updateLocationsAvailability(uuid, { isProductAvailableLoading: false });
            }
          })
        );

        results
          .filter((result) => result.status === 'rejected' && result.reason.response.req._data.location_uuid)
          .forEach((result) => {
            const locationUuid = result.reason.response.req._data.location_uuid;
            const { label } = this.locationsAvailabilityMapLocationUuid[locationUuid];

            errorlabels.push(label);
          });
      }

      return errorlabels;
    },
    async updateAllLocationsOutOfStock(isSelectAll) {
      this.isSwitchAll = isSelectAll;
      this.isSwitchAllLoading = true;

      const filteredLocations = this.locationsAvailability.filter(
        ({ isProductAvailable }) => isProductAvailable !== isSelectAll
      );
      const chunks = chunk(filteredLocations, 5);

      const errorLabelLocations = await this.updateAllLocationsBatches(chunks, isSelectAll);

      if (errorLabelLocations.length) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: `Unable to update out of stock for ${errorLabelLocations.join(', ')}`,
        });
      }

      this.onOutOfStockChange();
      this.isSwitchAllLoading = false;
    },
    async updateOutOfStock({ isProductAvailable, locationUuid }) {
      this.updateLocationsAvailability(locationUuid, { isProductAvailableLoading: true });
      this.updateLocationsAvailability(locationUuid, { isProductAvailable });
      try {
        const request = {
          location_uuid: locationUuid,
          product_uuid: this.product.uuid,
          provider_type: this.selectedLevel.type,
          provider_uuid: this.selectedLevel.uuid,
        };

        isProductAvailable ? await this.disableOutOfStock(request) : await this.enableOutOfStock(request);
      } catch (err) {
        this.updateLocationsAvailability(locationUuid, { isProductAvailable: !isProductAvailable });

        const { label } = this.locationsAvailabilityMapLocationUuid[locationUuid];
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: `Unable to update out of stock for ${label}`,
        });
      } finally {
        this.updateLocationsAvailability(locationUuid, { isProductAvailableLoading: false });
        this.onOutOfStockChange();
      }
    },
    getParentLocation() {
      return this.locationsUnnested.find((location) => location.uuid == this.currentLocation.parent_uuid);
    },
    openRemoveProductDialog() {
      if (this.usage.recipes.length || this.usage.menu_items.length) {
        this.$buefy.dialog.alert({
          title: `Deleting ${this.product.label}`,
          message: `You can not <b>delete</b> this product because it is used in recipes. <br><b> See 'Usages' section</b>`,
          type: 'is-danger',
          hasIcon: true,
        });
      } else {
        let supplierUUID = this.product.supplier.uuid;
        this.$buefy.dialog.confirm({
          title: 'Deleting product',
          message: `Are you sure you want to <b>delete</b> ${this.product.label}?`,
          confirmText: 'Delete product',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.removeOne({ supplierUuid: this.product.supplier.uuid, productUuid: this.product.uuid }).then(() =>
              this.$router.push(`/suppliers/${supplierUUID}`)
            );
          },
        });
      }
    },
    openRemoveLocalizedInformationProductDialog() {
      this.$buefy.dialog.confirm({
        title: 'Deleting Informations',
        message: `Are you sure you want to <b>delete</b> localized informations for <b>${this.product.label}</b> in <b>${this.currentLocation.id}</b> ?`,
        confirmText: 'Delete infos',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.removeLocalizedInformation({
            supplierUuid: this.product.supplier.uuid,
            productUuid: this.product.uuid,
            locationUuid: this.currentLocation.uuid,
          });
        },
      });
    },
    openEditProductDialog() {
      this.$buefy.modal.open({
        component: EditProduct,
        parent: this,
        hasModalCard: true,
        props: { id: this.product.uuid },
        events: {
          close: () => this.fetchOne(this.$route.params.productId),
        },
      });
    },
    getLocalizedInformation(location) {
      let newLocalizedInformation = {};
      if (this.product && this.product.localized_informations && this.product.localized_informations.length > 0) {
        let localizedInformation = this.product.localized_informations.filter(
          (localizedInfo) => localizedInfo.location_uuid == location.uuid
        );
        if (localizedInformation.length > 0) {
          return { ...localizedInformation[0], location_id: location.id };
        }
      }
      return newLocalizedInformation;
    },
    getCountryLocalizedInformation() {
      let countryLocalizedInformation = {};
      if (!this.currentLocation.parent_uuid) {
        countryLocalizedInformation =
          this.product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.uuid
          )[0] || {};
        return { ...countryLocalizedInformation, ...{ location_id: this.currentLocation.id } };
      } else {
        countryLocalizedInformation =
          this.product.localized_informations.filter(
            (localizedInfo) => localizedInfo.location_uuid == this.currentLocation.parent_uuid
          )[0] || {};
        if (this.getParentLocation()) {
          return { ...countryLocalizedInformation, ...{ location_id: this.getParentLocation().id } };
        }
        return countryLocalizedInformation;
      }
    },
    getGlobalLogisticsCosts(logistics_costs) {
      if (logistics_costs && logistics_costs.length > 0) {
        let logisticsCostsTotal = logistics_costs
          .map((cost) => cost.price)
          .reduce((cost1, cost2) => {
            return cost1 + cost2;
          });
        return { ...logistics_costs[0], ...{ price: logisticsCostsTotal } };
      }
      return {};
    },
    getSupplierPrice(localizedInformation) {
      if (
        localizedInformation.supplier_price &&
        Object.keys(localizedInformation.supplier_price).length > 0 &&
        localizedInformation.supplier_price.price
      ) {
        let unit = this.getSupplierPriceUnit(localizedInformation);
        let deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.supplier_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getSupplierPriceUnit(localizedInformation) {
      if (localizedInformation.supplier_price.type == 'PER_CATALOGUE_UNIT') {
        return this.getCatalogueUnit();
      } else {
        return this.getPackagingUnit();
      }
    },
    getKitchenPrice(localizedInformation) {
      if (
        localizedInformation &&
        localizedInformation.kitchen_price &&
        Object.keys(localizedInformation.kitchen_price).length > 0 &&
        localizedInformation.kitchen_price.price
      ) {
        let unit = this.getKitchenPriceUnit(localizedInformation);
        let deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.kitchen_price.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getKitchenPriceUnit(localizedInformation) {
      if (localizedInformation.kitchen_price.type == 'PER_CATALOGUE_UNIT') {
        return this.getCatalogueUnit();
      } else {
        return this.getPackagingUnit();
      }
    },
    getTotallLogisticsCosts(localizedInformation) {
      if (
        localizedInformation &&
        localizedInformation.globalLogisticsCosts &&
        Object.keys(localizedInformation.globalLogisticsCosts).length > 0 &&
        localizedInformation.globalLogisticsCosts.price
      ) {
        let unit = this.getTotalLogisticsCostsUnit(localizedInformation);
        let deviseSign = this.getDeviseSign(localizedInformation.currency);
        return `${localizedInformation.globalLogisticsCosts.price} ${deviseSign} / ${unit}`;
      }
      return '-';
    },
    getTotalLogisticsCostsUnit(localizedInformation) {
      if (localizedInformation.globalLogisticsCosts.type == 'PER_PACKAGING_UNIT') {
        return this.getPackagingUnit();
      } else {
        return 'kg';
      }
    },
    getCatalogueUnit() {
      return this.getCountryLocalizedInformation().catalogue_unit;
    },
    getPackagingUnit() {
      return this.getCountryLocalizedInformation().packaging_unit;
    },
    getBatchSize() {
      return this.getCountryLocalizedInformation().batch_size;
    },
    getVatRate(localizedInformation) {
      return localizedInformation.vat_rate == null ? '-' : `${localizedInformation.vat_rate}%`;
    },
    getOrderable(localizedInformation) {
      if (localizedInformation.orderable == true) {
        return 'True';
      }
      if (localizedInformation.orderable == false) {
        return 'False';
      }
      return '-';
      // let countrylocalizedInformation = this.getCountryLocalizedInformation();
      // return countrylocalizedInformation.orderable
    },
    async fetchOutOfStocks() {
      try {
        this.isLocationsAvailabilityLoading = true;

        const bodyRequest = {
          product_uuid: this.product.uuid,
          provider_type: this.selectedLevel.type,
          provider_uuid: this.selectedLevel.uuid,
          location_uuids: this.locationsAvailability.map(({ uuid }) => uuid),
          unavailability_date: new Date().toISOString(),
          page: 1,
          per_page: 50,
        };

        const response = await this.searchOutOfStocks(bodyRequest);
        const lastPage = response.last_page;
        let outOfStockLocations = response.items;

        let callMap = [];
        for (let page = 2; page <= lastPage; page++) {
          callMap.push(this.searchOutOfStocks({ ...bodyRequest, page }));
        }
        const responses = await Promise.all(callMap);

        outOfStockLocations = [...outOfStockLocations, ...responses.flatMap(({ items }) => items)];

        if (Array.isArray(outOfStockLocations) && outOfStockLocations.length) {
          const outOfStockLocationsByLocationUuid = keyBy(outOfStockLocations, 'location_uuid');

          this.locationsAvailability = this.locationsAvailability.map((location) =>
            outOfStockLocationsByLocationUuid[location.uuid]
              ? { ...location, isProductAvailable: false }
              : { ...location, isProductAvailable: true }
          );
        }
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: `Unable to fetch out of stock`,
        });
      } finally {
        this.onOutOfStockChange();
        this.isLocationsAvailabilityLoading = false;
      }
    },
    setLocationsAvailability() {
      return this.currentLocations.map(({ uuid, label }) => ({
        label,
        uuid,
        isProductAvailable: true,
        isProductAvailableLoading: false,
      }));
    },
    async setupAvailability() {
      this.isAvailabilityLoading = true;
      this.availabilityLevels = this.setAvailabilityLevels();

      if (this.availabilityLevels && this.availabilityLevels.length) {
        this.selectedLevel = { ...this.availabilityLevels[0] };

        this.locationsAvailability = this.setLocationsAvailability();
        try {
          await this.fetchOutOfStocks();
        } finally {
          this.isAvailabilityLoading = false;
        }
      }
    },
  },
  async created() {
    await this.fetchOne(this.$route.params.productId);
    if (this.product.uuid) {
      await this.fetchUsage();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      await this.fetchUnnestedLocations();
    }
    await this.setupAvailability();
  },
};
</script>

<style scoped>
.availability-wrapper {
  margin-top: 24px;
}

.conversion {
  background: grey;
}
</style>
