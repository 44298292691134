<template>
  <div>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching the restaurants. </b-message>
    <div v-else>
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <template v-if="restaurants && statuses">
        <b-table
          :data="getStatusesInCurrentLocation"
          ref="table"
          hoverable
          default-sort="kitchen_restaurant.kitchen_location.label"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          detailed
          detail-key="id"
          :opened-detailed="defaultOpenedDetails"
          @click="toggle"
          @details-open="(row) => getLastCheck(row.uuid, row)"
        >
          <template slot-scope="props">
            <b-table-column field="kitchen_restaurant.kitchen_location.label" label="Kitchen" sortable>
              {{ props.row.kitchen_restaurant.kitchen_location.label }}
            </b-table-column>

            <b-table-column field="platform_restaurant.id" label="Platform" sortable>
              <figure class="image is-48x48">
                <a :href="props.row.url" target="_blank">
                  <img :src="props.row.platform_restaurant.logo" />
                </a>
              </figure>
            </b-table-column>

            <b-table-column field="menu_status.status" label="Status" sortable>
              <div v-if="props.row.menu_status">
                <b-icon
                  v-if="props.row.menu_status.status === 'Success'"
                  icon="check"
                  size="is-large"
                  type="is-success"
                />
                <b-icon
                  v-else-if="props.row.menu_status.status === 'Fail'"
                  icon="close"
                  size="is-large"
                  type="is-danger"
                />
                <b-tag v-else type="is-dark">{{ props.row.menu_status.status }}</b-tag>
              </div>
              <div v-else>
                <b-tag type="is-dark">No Status</b-tag>
              </div>
            </b-table-column>

            <b-table-column label="Actions">
              <b-button
                @click.stop="testRestaurantMenu(props.row.uuid)"
                class="table-action"
                type="is-light"
                :disabled="
                  !canUploadMenu() ||
                  (isUploadLoading && props.row.platform_restaurant.uuid == '46dffa06-0413-4cdf-af87-c06f40544c57')
                "
                rounded
                :loading="isTestLoading && isTestTarget === props.row.uuid"
              >
                Test
              </b-button>
              <b-button
                @click.stop="
                  openUploadConfirmationDialog(
                    props.row.uuid,
                    props.row.kitchen_restaurant.kitchen_location.label,
                    props.row.platform_restaurant.label
                  )
                "
                class="table-action"
                type="is-primary"
                :disabled="
                  !canUploadMenu() ||
                  (isUploadLoading && props.row.platform_restaurant.uuid == '46dffa06-0413-4cdf-af87-c06f40544c57')
                "
                rounded
                :loading="isUploadLoading && isUploadTarget === props.row.uuid"
              >
                Upload
              </b-button>
            </b-table-column>

            <b-table-column label="Last Uploaded">
              <b-taglist>
                <b-tag type="is-info" v-if="props.row.menu_status && props.row.menu_status.backup_date">
                  {{ props.row.menu_status.backup_date }}
                </b-tag>
                <b-tag type="is-info" v-else>Not Uploaded</b-tag>
              </b-taglist>
            </b-table-column>

            <b-table-column label="Last Check">
              <b-taglist>
                <b-tag type="is-light" v-if="props.row.menu_status && props.row.menu_status.check_date">
                  {{ props.row.menu_status.check_date }}
                </b-tag>
                <b-tag type="is-danger" v-else>Not Check</b-tag>
              </b-taglist>
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <div v-if="getStatusByRestaurantUuid(props.row.uuid)" class="comparison-container">
              <RestaurantsCheckComparison
                :comparison="getStatusByRestaurantUuid(props.row.uuid)"
                :platform="props.row.platform_restaurant"
                @compareMenu="compareMenu(props.row.uuid)"
              />
            </div>
            <div v-else>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </div>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>
                  No restaurant for the current location. <b>({{ currentLocation.label }})</b>
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import RestaurantsCheckComparison from '@/components/Menu/Restaurants/RestaurantsCheckComparison.vue';
import { errorToasterDetailled } from '@/mixins';
import { auth } from '@/mixins';

export default {
  name: 'RestaurantsTable',
  mixins: [auth, errorToasterDetailled],
  components: {
    RestaurantsCheckComparison,
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    ...mapState({
      concept: (state) => state.concepts.current,
      isFetchLoading: (state) => state.menus.fetchStatuses.pending || state.restaurants.fetchByConcept.pending,
      fetchError: (state) => state.menus.fetchStatuses.error || state.restaurants.fetchByConcept.error,
      statuses: (state) => state.menus.statuses,
      restaurants: (state) => state.restaurants.allByConcept,
      isTestLoading: (state) => state.menus.testOne.pending,
      isTestTarget: (state) => state.menus.testOne.target,
      isUploadLoading: (state) => state.menus.uploadOne.pending,
      isUploadTarget: (state) => state.menus.uploadOne.target,
      testError: (state) => state.menus.testOne.error,
      currentLocation: (state) => state.locations.current,
      isSaveLoading: (state) => state.menus.uploadOne.pending,
      saveError: (state) => state.menus.uploadOne.error,
    }),
    ...mapGetters('restaurants', ['getStatusesInCurrentLocation']),
    ...mapGetters('menus', ['getStatusByRestaurantUuid']),
  },
  watch: {
    concept(newValue) {
      this.fetchRestaurants(newValue.uuid).then(() => this.fetchStatuses({ conceptUuid: newValue.uuid }));
    },
  },
  mounted() {
    if (this.concept) {
      this.fetchRestaurants(this.concept.uuid).then(() => this.fetchStatuses({ conceptUuid: this.concept.uuid }));
    }
  },
  methods: {
    ...mapActions('menus', {
      fetchStatuses: 'fetchStatuses',
      fetchStatus: 'fetchStatus',
      checkMenu: 'checkMenu',
      testMenu: 'testMenu',
      uploadMenu: 'uploadMenu',
      saveMenu: 'saveMenu',
    }),
    ...mapActions('restaurants', { fetchRestaurants: 'fetchByConcept' }),
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    getLastCheck(restaurantUuid, row) {
      const status = this.getStatusByRestaurantUuid(restaurantUuid);
      if (status && status.platform_menu && status.taster_menu) {
        this.closeAllOtherDetails(row);
      } else {
        this.fetchStatus(restaurantUuid).then(() => this.closeAllOtherDetails(row));
      }
    },
    compareMenu(restaurantUuid) {
      this.checkMenu(restaurantUuid);
    },
    testRestaurantMenu(restaurantUuid) {
      this.testMenu(restaurantUuid).then(() => {
        if (this.testError) {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: this.testError.body.detail,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true,
          });
        } else {
          this.$buefy.dialog.alert({
            title: 'Success',
            message: 'Everything looks fine!',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true,
          });
        }
      });
    },
    openUploadConfirmationDialog(restaurantUuid, restaurant, platform) {
      this.$buefy.dialog.confirm({
        title: 'Upload Menu',
        message: `Are you sure you want to upload the menu on <b>${platform}</b> for <b>${restaurant}</b>?`,
        confirmText: 'Upload Menu',
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => this.uploadMenu(restaurantUuid).then(() => this.fetchStatus(restaurantUuid)),
      });
    },
    closeAllOtherDetails(row) {
      this.defaultOpenedDetails = [row.id];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-action {
  margin: 5px;
}

.comparison-container {
  max-width: 1000px;
}
</style>
