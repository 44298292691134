<template>
  <div>
    <div class="item" :value="location.uuid" @click.stop="$emit('select', location)">
      {{ location.label }}
      <span @click.stop="() => expand(location.uuid)">
        <b-icon icon="chevron-down" size="is-small" v-if="location.locations.length" />
      </span>
    </div>
    <div v-if="location.locations.length && visibility[location.uuid]" style="margin-left: 8px">
      <LocationSelectorItem
        @select="(location) => $emit('select', location)"
        :location="location"
        v-for="location in location.locations"
        :key="location.uuid"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationSelectorItem',
  props: ['location'],
  data: () => ({
    visibility: {},
  }),
  methods: {
    expand(uuid) {
      this.visibility = { ...this.visibility, [uuid]: this.visibility[uuid] ? false : true };
    },
  },
  mounted() {
    if (this.location.locations.length) {
      this.location.locations.forEach((location) => {
        this.$set(this.visibility, location.uuid, false);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 0.375rem 1rem;

  &:hover {
    background: #f5f5f5;
  }
}
</style>
