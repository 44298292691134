import { ShippingPoliciesApi } from '@tasterkitchens/products-manager-client';
import clientHook from '@/apis';

const api = new ShippingPoliciesApi();
api.apiClient.basePath = process.env.VUE_APP_PRODUCTS_ENDPOINT;

export default {
  /**
   * List shipping policies by grouping them by their threshold parameters.
   * @param {Object} params Request parameters
   * @param {string} [params.hubUuid=undefined] The UUID of the hub bound to the shipping policies
   * @param {string} [params.supplierUuid=undefined] The UUID of the supplier bound to the shipping policies
   * @param {string} [params.locationUuid=undefined] A location UUID which have specific shipping policies
   * @param {string} [params.locationUuids=undefined] A collection of location UUIDs which have specific shipping policies
   * @param {boolean} [params.composeLocations=false] Should shipping policies of the current location be composed with its parent policies?
   * @returns
   */
  list({
    hubUuid = undefined,
    supplierUuid = undefined,
    locationUuid = undefined,
    locationUuids = undefined,
    composeLocations = false,
  }) {
    if (!hubUuid && !supplierUuid) {
      throw new TypeError('You must provide at least a Hub or Supplier UUID');
    }

    return clientHook(api).appLegacyApisV2ShippingPoliciesListShippingPolicies({
      listShippingPoliciesRequest: {
        hub_uuid: hubUuid,
        supplier_uuid: supplierUuid,
        location_uuid: locationUuid,
        location_uuids: locationUuids,
        compose_locations: composeLocations,
      },
    });
  },
};
