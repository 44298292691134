import Vue from 'vue';

import { LocationsApi } from '@tasterkitchens/kitchens-manager-v2-client';

const locationsApi = new LocationsApi({
  accessToken: () => Vue.prototype.$auth.getToken(),
  isJsonMime: () => true,
  basePath: process.env.VUE_APP_KITCHENS_ENDPOINT,
});

export default {
  fetchAll() {
    return locationsApi.listLocations(true, undefined, false);
  },
  fetchAllUnnested() {
    return locationsApi.listLocations(false, undefined, false);
  },
  fetchOne(id) {
    return locationsApi.getLocation(id);
  },
};
