import clientHook from '@/apis';
const ItemsManagerClient = require('@tasterkitchens/items-manager-client-v2');

const combosApi = new ItemsManagerClient.CombosApi();
combosApi.apiClient.basePath = process.env.VUE_APP_ITEMS_ENDPOINT;

export default {
  buildOne() {
    return new ItemsManagerClient.ComboCreationRequest('');
  },
  fetchAll() {
    return clientHook(combosApi).appApisV1CombosListCombos();
  },
  fetchOne(uuid) {
    return clientHook(combosApi).appApisV1CombosGetCombo(uuid);
  },
  updateOne(uuid, combo) {
    return clientHook(combosApi).appApisV1CombosUpdateCombo(uuid, combo);
  },
  createOne(combo) {
    return clientHook(combosApi).appApisV1CombosCreateCombo(combo);
  },
  addLocation(uuid, locationUuid) {
    return clientHook(combosApi).appApisV1CombosAddComboLocations(uuid, [{ uuid: locationUuid }]);
  },
  removeLocation(uuid, locationUuid) {
    return clientHook(combosApi).appApisV1CombosRemoveComboLocation(uuid, locationUuid);
  },
  updateLocation(uuid, locationUuid, location) {
    return clientHook(combosApi).appApisV1CombosUpdateComboLocation(uuid, locationUuid, location);
  },
  fetchModifierGroups(uuid) {
    return clientHook(combosApi).appApisV1CombosGetModifierGroups(uuid);
  },
  addModifierGroup(uuid, modifierGroupUuid) {
    return clientHook(combosApi).appApisV1CombosAddComboModifierGroup(uuid, { modifier_group_uuid: modifierGroupUuid });
  },
  removeModifierGroup(uuid, modifierGroupUuid) {
    return clientHook(combosApi).appApisV1CombosRemoveComboModifierGroup(uuid, modifierGroupUuid);
  },
  addToConcept(uuid, conceptUuid) {
    return clientHook(combosApi).appApisV1CombosAddComboConcept(uuid, { concept_uuid: conceptUuid });
  },
  removeFromConcept(uuid, conceptUuid) {
    return clientHook(combosApi).appApisV1CombosRemoveComboConcept(uuid, conceptUuid);
  },
  uploadPicture(uuid, conceptUuid, picture, platformUuid, basewidth) {
    return clientHook(combosApi).appApisV1CombosUploadPicture(uuid, conceptUuid, picture, platformUuid, { basewidth });
  },
  fetchCategories() {
    return clientHook(combosApi).appUtilsUtilGenerateComboCategories();
  },
};
