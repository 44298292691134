<template>
  <article class="location-filter">
    <b-button
      ref="button"
      @click="toggleShowLocations"
      class="filter-button"
      expanded
      type="is-info"
      :outlined="showLocationFilter"
    >
      <span class="icon">
        <i class="mdi mdi-filter" />
      </span>
      <span v-show="filteredLocations.length === 0">Filter</span>
      <span v-show="filteredLocations.length > 0">Filter ({{ filteredLocations.length }})</span>
    </b-button>
    <Portal>
      <div v-show="showLocationFilter" ref="dropdownContent" class="dropdown-content">
        <LocationFilter
          :location-root="currentLocation"
          :selected-locations="filteredLocations"
          @location-filter:confirm="onConfirm($event)"
        />
      </div>
    </Portal>
  </article>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';
import LocationFilter from '@/components/ShippingPolicy/LocationFilter';
import { computePosition } from '@floating-ui/dom';

export default {
  name: 'SubstituteRulesLocationsFilter',
  components: {
    Portal,
    LocationFilter,
  },
  props: {
    currentLocation: {
      type: Object,
      required: true,
    },
    filteredLocations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showLocationFilter: false,
    };
  },
  methods: {
    onConfirm(locations) {
      this.toggleShowLocations();
      this.$emit('location-filter:confirm', locations);
    },
    onClickOutside(event) {
      if (event.target === this.$refs.button.$el || event.target === this.$refs.dropdownContent) {
        return;
      }

      let children = this.$refs.button.$el.querySelectorAll('*');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      children = this.$refs.dropdownContent.querySelectorAll('*');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      this.showLocationFilter = false;
    },
    updateDropdownContentPosition() {
      if (!this.$refs.button || !this.$refs.dropdownContent) {
        return;
      }

      const button = this.$refs.button.$el;
      const dropdownContent = this.$refs.dropdownContent;

      this.$refs.dropdownContent.style.width = `${button.offsetWidth}px`;
      computePosition(button, dropdownContent, {
        placement: 'bottom-start',
      }).then(({ x, y }) => {
        Object.assign(this.$refs.dropdownContent.style, {
          top: `${y}px`,
          left: `${x}px`,
        });
      });
    },
    toggleShowLocations() {
      this.showLocationFilter = !this.showLocationFilter;

      if (this.showLocationFilter) {
        this.updateDropdownContentPosition();
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  border-radius: 0;
}

.dropdown-content {
  box-sizing: border-box;
  position: absolute;
  z-index: 999;
  section {
    padding: 24px;
  }
}
</style>
