<template>
  <article class="availability">
    <header class="availability__header">
      <h1 class="availability__title">Availability</h1>
    </header>

    <div class="loading-wrapper" v-if="isAvailabilityLoading">
      <b-loading :active.sync="isAvailabilityLoading" :is-full-page="false" />
    </div>
    <div class="no-locations" v-else-if="!locationsAvailability.length">No locations</div>
    <section v-else class="availability__content">
      <b-field class="level-select" label="What level ?">
        <b-select v-model="currentLevel" placeholder="Select a name">
          <option v-for="level in levels" :value="level" :key="`${level.uuid}`">
            {{ level.label }}
          </option>
        </b-select>
      </b-field>

      <div class="loading-wrapper" v-if="isLocationsAvailabilityLoading">
        <b-loading :active="true" :is-full-page="false" />
      </div>
      <template v-else>
        <div class="locations">
          <h2 class="locations-title">Locations</h2>
          <b-field class="global-switch" :label="`all ${parentLocationLabel} kitchens`">
            <b-switch type="is-info" :disabled="isSwitchAllLoading" v-model="isSwitchAllModel" />
          </b-field>
        </div>

        <div class="loading-wrapper" v-if="isSwitchAllLoading">
          <b-loading :active="true" :is-full-page="false" />
        </div>
        <ul v-else class="switch-list">
          <li
            class="switch-list__elem"
            v-for="{ label, uuid, isProductAvailableLoading, isProductAvailable } in locationsAvailability"
            :key="uuid"
          >
            <b-field :label="label">
              <b-switch
                :disabled="isProductAvailableLoading"
                type="is-info"
                @input="updateOutOfStock($event, uuid)"
                :value="isProductAvailable"
              />
            </b-field>
          </li>
        </ul>
      </template>
    </section>
  </article>
</template>

<script>
export default {
  name: 'ProductAvailability',
  props: {
    isAvailabilityLoading: {
      type: Boolean,
      required: true,
    },
    levels: {
      type: Array,
      required: true,
    },
    selectedLevel: {
      type: Object,
      default: null,
    },
    isSwitchAll: {
      type: Boolean,
      default: false,
    },
    isSwitchAllLoading: {
      type: Boolean,
      default: false,
    },
    locationsAvailability: {
      type: Array,
      required: true,
    },
    isLocationsAvailabilityLoading: {
      type: Boolean,
      default: false,
    },
    parentLocationLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentLevel: {
      get() {
        return this.selectedLevel;
      },
      set(value) {
        this.$emit('update:selected-level', value);
      },
    },
    isSwitchAllModel: {
      get() {
        return this.isSwitchAll;
      },
      set(value) {
        this.$emit('select-all', value);
      },
    },
  },
  methods: {
    updateOutOfStock(isProductAvailable, locationUuid) {
      this.$emit('update:out-of-stock', { isProductAvailable, locationUuid });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-locations {
  padding: 1rem;
  text-align: center;
}
.loading-wrapper {
  position: relative;
  height: 250px;
}

.availability {
  &__header {
    background: #f4f5f5;
    padding: 0.75rem;
  }
  &__title {
    text-align: center;
    font-weight: 700;
    color: #363636;
  }

  &__content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .level-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ::v-deep .label {
      margin-bottom: 0;
    }
  }

  .locations {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #dadadb;
    padding-bottom: 6px;

    &-title {
      font-weight: bold;
    }

    .global-switch {
      display: flex;
      align-items: center;
      gap: 8px;

      ::v-deep .label {
        margin-bottom: 0;
      }
    }

    ::v-deep .switch {
      margin-right: 0;
    }
  }

  .switch-list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    &__elem ::v-deep .label:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
