<template>
  <div @mouseover="active = true" @mouseleave="active = false">
    <div class="wrap" :class="{ addVisible: active, addHidden: !active, wrapFirst: !position }">
      <b-button icon-left="plus" rounded size="is-small" class="is-light" @click="$emit('addCategory', position)">
        Add
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuDetailsCategoryDivider',
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  text-align: center;
  margin: 25px 50px;
  position: relative;

  .btn-add {
    color: white !important;
  }

  &First {
    margin-top: 0;
  }
}
.wrap::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid gainsboro;
  width: 100%;
  transform: translateY(-50%);
}
.addHidden {
  visibility: hidden;
}
.addVisible {
  visibility: visible;
}
</style>
