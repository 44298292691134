<template>
  <div
    class="card"
    :class="{ cardActive: active, cardDefault: !active }"
    @mouseover="hoverable && (active = true)"
    @mouseleave="hoverable && (active = false)"
  >
    <div class="card-content">
      <div class="card-details">
        <div class="media">
          <div class="media-content item-label">
            <p class="title is-6">{{ menuItem.label }}</p>
          </div>
        </div>
        <div class="content subtitle is-7">
          {{ menuItem.description }}
        </div>
        <b-tag class="is-taster-pale-pink" v-if="menuItem.selling_price != null">
          {{ menuItem.selling_price }} {{ menuItem.selling_unit_price }}
        </b-tag>
      </div>
      <div class="media-right img-container">
        <img
          :src="menuItemPicture ? menuItemPicture : 'https://semantic-ui.com/images/wireframe/image.png'"
          alt="Placeholder image"
          style="height: 100%; object-fit: cover"
        />
      </div>
    </div>
    <button
      v-if="deletable && isItemMenuEditor()"
      class="delete"
      :class="{ deleteActive: active, deleteDisabled: !active }"
      @click.stop="$emit('delete', menuItem.uuid)"
    />
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  name: 'MenuItemCard',
  mixins: [auth],
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    conceptId: {
      type: String,
    },
    platformUuid: {
      type: String,
    },
    deletable: {
      type: Boolean,
    },
    hoverable: {
      type: Boolean,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    menuItemPicture() {
      if (this.menuItem.picture) {
        return this.menuItem.picture;
      } else if (this.menuItem.image_url) {
        return this.menuItem.image_url;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.card {
  .card-content {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .card-details {
      flex: 0.65 1 0;
      min-width: 0%;

      .media {
        margin-bottom: 10px;
      }

      .content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 10px;
      }
    }

    .img-container {
      flex: 0.3 1 0;
      height: 80px;
    }

    .tag {
      margin: 0;
    }
  }

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: lightgray;
  }
  .deleteDisabled {
    visibility: hidden;
  }
  .deleteActive {
    visibility: visible;
  }
}
.cardDefault {
  box-sizing: border-box;
  // border: 1px solid transparent;
}
.cardActive {
  border-radius: 5px;
  box-shadow: 0.5px 0.9px 7px 0 rgba(0, 0, 0, 0.1);
  // background-color: $light !important;
  box-sizing: border-box;
  // border-style: dashed !important;
  border-width: 1px;
}
</style>
