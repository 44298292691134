<template>
  <b-field label="Unit" label-position="on-border" expanded>
    <b-select
      placeholder="Select a unit"
      v-model="computedValue"
      size="is-small"
      :disabled="!isItemMenuItemRecipeEditor()"
      expanded
    >
      <option value="g" v-if="authorizedUnits.includes('g')">g</option>
      <option value="ml" v-if="authorizedUnits.includes('ml')">ml</option>
      <option value="each" v-if="authorizedUnits.includes('each')">each</option>
    </b-select>
  </b-field>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: {
    value: {
      type: String,
    },
    authorizedUnits: {
      type: Array,
      default: () => ['g', 'ml', 'each'],
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
};
</script>

<style scoped>
div.field {
  margin-bottom: 0;
}
</style>
