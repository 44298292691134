<template>
  <div>
    <div class="columns">
      <div class="column">
        <span
          class="title link"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          @click="$router.push(`/composition/${menuItem.uuid}`)"
          v-if="hasAccessToRecipe()"
        >
          {{ menuItem.label }}
          <b-button v-if="hover" icon-right="open-in-new" type="is-text" rounded />
        </span>
        <div class="title" v-else>
          {{ menuItem.label }}
        </div>
      </div>
      <div class="column">
        <div style="display: flex; padding-bottom: 12px">
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Food Cost</p>
              <p class="title is-5" v-if="isKitchenPrice">{{ kitchenPrice }}</p>
              <span v-if="!isTimeout && !isKitchenPrice" class="loader"></span>
              <b-tooltip label="Pricing error" size="is-small" type="is-light" v-if="isTimeout && !isKitchenPrice">
                <b-icon icon="alert" size="is-small"></b-icon>
              </b-tooltip>
            </div>
          </div>
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div v-if="grossFoodCost">
              <p class="heading">Food Cost (%)</p>
              <p class="title is-5 has-text-success">{{ grossFoodCost }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow" v-if="isItemMenuItemRecipeEditor() || isItemComboRecipeEditor()">
        <router-link to="/menu-items" class="button">Cancel</router-link>
        <b-button
          type="is-primary"
          :loading="isSaveLoading"
          icon-left="content-save"
          @click="$emit('save')"
          style="margin-left: 8px"
          >Save</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['grossFoodCost', 'isKitchenPrice', 'isSaveLoading', 'kitchenPrice', 'menuItem'],
  data: () => ({
    isTimeout: false,
    hover: false,
  }),
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
    }),
  },
  methods: {},
  mounted() {
    setTimeout(() => (this.isTimeout = true), 15000);
  },
};
</script>
