<template>
  <div class="box modal-card">
    <!-- <b-loading :active.sync="isLoading"></b-loading> -->
    <b-steps v-model="activeStep" size="is-small" :has-navigation="false">
      <b-step-item label="Recipe Template">
        <div class="has-text-centered">
          <b-button icon-left="plus" type="is-link" class="button-gap" @click="goToExistingRecipe()" expanded>
            Start from Existing Recipe
          </b-button>
        </div>
        <!-- <div class="has-text-centered">
        <b-button icon-left="plus" type="is-info" class="button-gap"  @click="goToEmptyRecipe()" expanded>
          Start from Empty Recipe
        </b-button>
      </div> -->
      </b-step-item>
      <b-step-item label="Recipes">
        <h4 class="title has-text-centered is-4">Select Among Existing Recipes</h4>
        <div class="has-text-centered">
          <b-button
            v-for="location in getGenericLocations()"
            :key="location.uuid"
            class="is-info button-location-gap"
            @click="createLocalizedRecipe(location.uuid)"
            outlined
          >
            {{ location.label }}
          </b-button>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import itemsModule from '@/store/modules/items';

export default {
  props: ['itemLocated', 'genericItem'],
  data: () => ({
    activeStep: 0,
    quantity: 0,
    unit: 'g',
  }),
  watch: {
    activeStep() {
      if (!this.activeStep) {
        this.setCurrent(null);
      }
    },
  },
  methods: {
    ...mapActions('composeRecipe', ['addLocationItem', 'createLocalizedItem']),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    goToExistingRecipe() {
      this.activeStep = 1;
    },
    goToEmptyRecipe() {
      this.addLocationItem(this.genericItem.uuid)
        .then(() => this.$emit('done'))
        .then(() => this.$parent.close());
    },
    createLocalizedRecipe(locationUuid) {
      this.createLocalizedItem({
        itemGenericUuid: this.genericItem.uuid,
        parentLocationUuid: locationUuid,
        childrenLocationUuid: this.currentLocation.uuid,
      })
        .then(() => this.$parent.close())
        .then(() => this.$emit('done'));
    },
    getGenericLocations() {
      // var parentLocationUUID = this.getParentLocationUUID();
      return this.locationsUnnested.filter((location) => this.genericItem.location_uuids.includes(location.uuid));
      // return this.locationsUnnested.filter((location) => this.genericItem.location_uuids.includes(location.uuid) && (location.uuid == parentLocationUUID || location.parent_uuid == parentLocationUUID));
    },
    getParentLocationUUID() {
      if (!this.currentLocation.parent_uuid) {
        return this.currentLocation.uuid;
      }
      return this.currentLocation.parent_uuid;
    },
  },
  computed: {
    ...mapState({
      current: (state) => state.composeRecipe.current,
      currentLocation: (state) => state.locations.current,
      isLoading: (state) => state.items.createLocalizedItem.pending,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
    }),
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  beforeCreate() {
    if (!this.$store._modules.root.state.composeRecipe) {
      this.$store.registerModule('composeRecipe', itemsModule);
    }
  },
  mounted() {
    if (this.$store.state.locations.all === null || this.$store.state.locations.all.length == 0) {
      this.fetchAllLocations();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      this.fetchUnnestedLocations();
    }
  },
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
