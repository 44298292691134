<template>
  <div>
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <b-field label="Label *" label-position="on-border">
      <b-input placeholder="Chicken" v-model="value.label"></b-input>
    </b-field>
    <b-field grouped>
      <b-field expanded label="Category" label-position="on-border">
        <b-input placeholder="Ingredient" v-model="value.category"></b-input>
      </b-field>
      <b-field label="MEP" label-position="on-border">
        <b-select placeholder="MEP" v-model="value.mep">
          <option v-for="mepValue in mepValues" :value="mepValue" :key="mepValue">
            {{ mepValue }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <div style="margin-bottom: 16px">
      <b>Item Measures </b>
      <b-button
        icon-left="plus"
        size="is-small"
        class="button-gap"
        @click="() => value.item_measure.push({ quantity: 0, unit: '' })"
      >
        Add
      </b-button>
      <p class="is-size-7 is-italic">All following measures should be equivalent.</p>
    </div>
    <b-field grouped v-for="(item_measure, index) in value.item_measure" :key="index">
      <b-input type="number" size="is-small" rounded expanded v-model.number="item_measure.quantity"></b-input>
      <b-select placeholder="g" v-model="item_measure.unit" size="is-small">
        <option value="g">g</option>
        <option value="ml">ml</option>
        <option value="each">each</option>
      </b-select>
      <b-button icon-left="delete" size="is-small" @click="() => value.item_measure.splice(index, 1)">Remove</b-button>
    </b-field>
    <b-field>
      <ItemProductForm
        v-if="
          displayProducts &&
          products.length &&
          item &&
          item.uuid &&
          item.item_measure.length > 0 &&
          item.item_measure[0].unit &&
          item.item_measure[0].quantity
        "
        :product="itemProduct"
        :products="products"
        @set="setProduct"
        @unset="unsetProduct"
        :isDisabled="false"
        @close="close"
      />
    </b-field>
    <button style="width: 100%" class="button is-primary" @click="$emit('save')" :disabled="savedDisabled()">
      {{ buttonMessage }}
    </button>
    <p v-if="saveMessage" class="is-size-7 is-italic has-text-right has-text-weight-light has-text-danger">
      {{ saveMessage }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ItemProductForm from '@/components/Composition/Item/ItemProductForm.vue';

export default {
  components: { ItemProductForm },
  props: ['item', 'buttonMessage', 'products', 'displayProducts'],
  data() {
    return {
      value: { ...this.item },
      mepValues: [true, false],
    };
  },
  watch: {
    value: {
      handler() {
        this.$emit('update:item', this.value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentProductPrice: (state) => state.products.productPrices,
      fetchError: (state) => state.products.computeProductPrice.error,
      isLoading: (state) => state.products.computeProductPrice.pending,
    }),
    itemProduct() {
      if (!this.item.product_uuid) {
        return null;
      }
      const product = this.products.find((product) => product.uuid === this.item.product_uuid);
      if (!product || !product.uuid) {
        return null;
      }
      return product;
    },
    saveMessage() {
      var messages = [this.labelMessage(), this.itemMeasureMessage()];
      for (let message of messages) {
        if (message) {
          return message;
        }
      }
      return '';
    },
  },
  methods: {
    ...mapActions('items', {
      setProductItem: 'setProduct',
      unsetProductItem: 'unsetProduct',
    }),
    ...mapActions('products', {
      computeProductPrice: 'computeProductPrice',
    }),
    close() {
      this.$emit('close');
    },
    savedDisabled() {
      if (!this.value.label) {
        return true;
      }
      if (!this.value.item_measure) {
        return true;
      }
      if (!this.value.item_measure.length > 0) {
        return true;
      }
      if (!this.value.item_measure[0].quantity || !this.value.item_measure[0].unit) {
        return true;
      }
      return false;
    },
    setProduct(label, productUuid) {
      var quantity = this.item.item_measure[0].quantity;
      var unit = this.item.item_measure[0].unit;
      this.computeProductPrice({
        uuid: productUuid,
        quantity: quantity,
        unit: unit,
        location_uuids: this.currentLocation.location_tree,
        item_conversions: this.item.item_measure,
      }).then(() => {
        if (
          this.currentProductPrice &&
          this.currentProductPrice.kitchen_cost != null &&
          this.currentProductPrice.supplier_cost != null
        ) {
          this.setProductItem(productUuid);
        } else {
          this.$buefy.dialog.confirm({
            title: `Adding ${label}`,
            message: `You can not <b>link</b> this product: <br> ${this.getProductAlertMessage(label, quantity, unit)}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: `Go to ${label}`,
            onCancel: () => this.goToProducts(productUuid),
          });
        }
      });
    },
    goToProducts(productUuid) {
      return this.toPage('/products/' + productUuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    getProductAlertMessage(label, quantity, unit) {
      if (this.currentProductPrice && this.currentProductPrice.kitchen_cost == null) {
        return `Kitchen Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      if (this.currentProductPrice && this.currentProductPrice.supplier_cost == null) {
        return `Supplier Price of <b>${quantity} ${unit}</b> of <b>${label}</b> can not be computed`;
      }
      return `<b>Can not compute prices for <b>${quantity} ${unit}</b> of <b>${label}`;
    },
    unsetProduct(productUuid) {
      this.unsetProductItem(productUuid);
    },
    labelMessage() {
      if (!this.value.label) {
        return 'Missing Label';
      }
      return '';
    },
    itemMeasureMessage() {
      if (!this.value.item_measure) {
        return 'Missing Item Measure';
      }
      if (!this.value.item_measure.length > 0) {
        return 'Missing Item Measure';
      }
      if (!this.value.item_measure[0].quantity || !this.value.item_measure[0].unit) {
        return 'Item Measure not complete';
      }
    },
  },
};
</script>
