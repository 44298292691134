<template>
  <div>
    <div class="header">
      <h5 class="subtitle">Pictures</h5>
      <hr />
    </div>
    <div>
      <b-message type="is-danger" v-if="fetchError">
        An error has occured while fetching the platforms list.
      </b-message>
      <div v-if="!fetchError">
        <b-tabs size="is-small" type="is-boxed" horizontal destroy-on-hide v-model="currentConceptIndex">
          <b-tab-item v-for="concept in menuItemConcepts" :key="concept.uuid">
            <template slot="header">
              <img :src="concept.logo" width="21" />
              {{ concept.label }}
            </template>
            <b-tabs size="is-small" type="is-boxed" horizontal destroy-on-hide v-model="currentPlatformIndex">
              <b-tab-item v-for="platform in getUpdatablePlatforms" :key="platform.uuid">
                <template slot="header">
                  <img :src="platform.logo" width="21" />
                  {{ platform.label }}
                </template>
                <div v-if="!areItemInformationsInCurrentLocationAndCurrentConcept(concept.uuid)">
                  <b-message type="is-info" :closable="false">
                    You should first "Create Specific Informations" in "Platforms tab" before uploading pictures in this
                    Location or "Create Prices" for this concept.
                  </b-message>
                </div>
                <div v-if="areItemInformationsInCurrentLocationAndCurrentConcept(concept.uuid)">
                  <PictureUpload
                    content-type="platforms"
                    :picture="menuItemPlatformPicture"
                    :uploadDisabled="false"
                    @submitFile="uploadPicture"
                  />
                </div>
                <div v-if="!isParentLocation">
                  <div
                    v-if="
                      !areItemInformationsInCurrentLocationAndCurrentConcept(concept.uuid) &&
                      areLocatedInformations(itemInformations, currentLocation.parent_uuid, concept.uuid) &&
                      menuItemCountryPlatformPicture
                    "
                  >
                    <hr />
                    <b-message type="is-warning" :closable="false">
                      Pictures are inherited from Kitchen's Country.
                    </b-message>
                    <PictureUpload
                      content-type="platforms"
                      :picture="menuItemCountryPlatformPicture"
                      :uploadDisabled="true"
                      @submitFile="uploadPicture"
                    />
                  </div>
                  <div v-else>
                    <hr />
                    <b-message type="is-warning" :closable="false">
                      No Pictures are defined Kitchen's Country.
                    </b-message>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import PictureUpload from '@/components/PictureUpload.vue';

export default {
  name: 'MenuItemPictures',
  components: {
    PictureUpload,
  },
  props: ['menuItem', 'sendPicture', 'itemInformations'],
  data() {
    return {
      currentPlatformIndex: 0,
      currentConceptIndex: 0,
    };
  },
  computed: {
    ...mapState({
      fetchError: (state) => state.platforms.fetchAll.error,
      concepts: (state) => state.concepts.all,
      currentLocation: (state) => state.locations.current,
      platforms: (state) => state.platforms.all,
      isParentLocation() {
        if (!this.currentLocation.parent_uuid) {
          return true;
        }
        return false;
      },
      menuItemConcepts() {
        return this.concepts.filter((concept) => this.menuItem.concept_uuids.includes(concept.uuid));
      },
      currentConcept() {
        if (this.currentConceptIndex >= 0) {
          return this.menuItemConcepts[this.currentConceptIndex];
        }
        return null;
      },
      currentPlatform() {
        if (this.currentPlatformIndex >= 0) {
          return this.getUpdatablePlatforms[this.currentPlatformIndex];
        }
        return null;
      },
      menuItemPlatformPicture() {
        if (this.currentConcept && this.currentPlatform && this.currentLocation && this.itemInformations) {
          var correspondingMenuItemInformations = this.itemInformations.filter(
            (itemInformations) =>
              itemInformations.concept_uuid == this.currentConcept.uuid &&
              itemInformations.platform_uuid == this.currentPlatform.uuid &&
              itemInformations.location_uuid == this.currentLocation.uuid
          );
          if (
            correspondingMenuItemInformations &&
            correspondingMenuItemInformations.length > 0 &&
            correspondingMenuItemInformations[0].pictures
          ) {
            return correspondingMenuItemInformations[0].pictures.formatted;
          }
          return null;
        }
        return null;
      },
      menuItemCountryPlatformPicture() {
        if (this.currentConcept && this.currentPlatform && this.currentLocation && this.itemInformations) {
          var correspondingMenuItemInformations = this.itemInformations.filter(
            (itemInformations) =>
              itemInformations.concept_uuid == this.currentConcept.uuid &&
              itemInformations.platform_uuid == this.currentPlatform.uuid &&
              itemInformations.location_uuid == this.currentLocation.parent_uuid
          );
          if (
            correspondingMenuItemInformations &&
            correspondingMenuItemInformations.length > 0 &&
            correspondingMenuItemInformations[0].pictures
          ) {
            return correspondingMenuItemInformations[0].pictures.formatted;
          }
          return null;
        }
        return null;
      },
    }),
    ...mapGetters('platforms', ['getUpdatablePlatforms']),
  },
  methods: {
    areItemInformationsInCurrentLocationAndCurrentConcept(conceptUuid) {
      if (
        this.itemInformations &&
        this.itemInformations.length > 0 &&
        this.areLocatedInformations(this.itemInformations, this.currentLocation.uuid, conceptUuid)
      ) {
        return true;
      }
      return false;
    },
    areLocatedInformations(informations, locationUuid, conceptUuid) {
      return (
        informations.filter(
          (itemInformation) =>
            itemInformation.location_uuid == locationUuid && itemInformation.concept_uuid == conceptUuid
        ).length > 0
      );
    },
    uploadPicture(contentType, file) {
      this.sendPicture(
        file,
        this.currentConcept.uuid,
        this.currentPlatform.uuid,
        this.currentLocation.uuid,
        this.currentPlatform.platform_information.picture_width
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 25px;
}
</style>
