<template>
  <div>
    <b-field label="Label" label-position="on-border">
      <b-input v-model="value.label" />
    </b-field>
    <div>
      <ModifierGroupLocationForm
        :modifierGroupLocation="localizedModifierGroup[0]"
        :menuItems="menuItems"
        v-if="localizedModifierGroup.length > 0"
        @update:modifierGroupLocation="updateModifierGroupLocation"
        @remove="$emit('removeLocation', currentLocation.uuid)"
        :key="localizedModifierGroup[0].uuid"
      />
      <div v-else>
        <b-button
          size="is-small"
          icon-left="plus-circle"
          class="is-info"
          outlined
          @click="$emit('addLocation', currentLocation.uuid)"
          >Activate for {{ currentLocation.label }}
        </b-button>
      </div>
    </div>
    <b-field grouped style="flex-direction: row-reverse">
      <b-button
        class="button is-primary"
        @click="$emit('save')"
        style="margin-left: 8px"
        :disabled="saveDisabled"
        :loading="isSaving"
      >
        <span>Save</span>
      </b-button>
      <b-button @click="$emit('cancel')">Cancel</b-button>
    </b-field>
  </div>
</template>

<script>
import ModifierGroupLocationForm from './ModifierGroupLocationForm.vue';

export default {
  props: ['modifierGroup', 'menuItems', 'isSaving', 'currentLocation'],
  components: { ModifierGroupLocationForm },
  data() {
    return {
      value: { ...this.modifierGroup },
    };
  },
  watch: {
    modifierGroup(newValue, oldValue) {
      if (newValue.locations.length !== oldValue.locations.length) {
        this.value = { ...this.modifierGroup };
      }
    },
    value: {
      handler() {
        this.$emit('update:modifierGroup', { ...this.value });
      },
      deep: true,
    },
  },
  methods: {
    updateModifierGroupLocation(modifierGroupLocation) {
      const index = this.value.locations.findIndex(({ uuid }) => modifierGroupLocation.uuid === uuid);

      this.value.locations.splice(index, 1, modifierGroupLocation);
    },
    modificationDisabled(locationUuid) {
      return this.currentLocation.uuid !== locationUuid;
    },
  },
  computed: {
    saveDisabled: function () {
      if (
        this.localizedModifierGroup.length == 0 ||
        this.localizedModifierGroup[0].label === undefined ||
        this.localizedModifierGroup[0].label === '' ||
        this.localizedModifierGroup[0].included_number === undefined ||
        this.localizedModifierGroup[0].included_number === '' ||
        this.localizedModifierGroup[0].max_number === undefined ||
        this.localizedModifierGroup[0].max_number === ''
      ) {
        return true;
      }
      if (this.localizedModifierGroup.length > 0 && this.localizedModifierGroup[0].menu_items.length > 0) {
        for (let menuItem of this.localizedModifierGroup[0].menu_items) {
          if (!menuItem.selling_price && menuItem.selling_price != 0) {
            return true;
          }
        }
      }
      return false;
    },
    localizedModifierGroup: function () {
      return this.value.locations.filter((location) => location.uuid === this.currentLocation.uuid);
    },
  },
};
</script>
