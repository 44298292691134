<template>
  <div v-if="menuItem" ref="card" class="box modal-card overflow">
    <div class="modal-card-image">
      <figure class="image is-16by9">
        <img
          :src="menuItemFormattedPicture ? menuItemFormattedPicture : menuItemDefaultPicture"
          alt="Placeholder image"
          style="object-fit: cover"
        />
      </figure>
    </div>
    <div class="modal-card-content">
      <div class="modal-card-content-header">
        <span v-if="logo">
          <figure class="image is-48x48">
            <img :src="logo" alt="Logo" />
          </figure>
        </span>
        <h3
          class="title header-title"
          :class="{ hoverable: hover }"
          @click="toItemPage()"
          @mouseover="menuItem.category && (hover = true)"
          @mouseleave="menuItem.category && (hover = false)"
        >
          {{ menuItem.label }}
          <b-icon
            v-if="menuItem.category && hasAccessToConcept()"
            size="is-small"
            type="is-black"
            icon="pencil"
            style="margin-left: 10px"
          />
        </h3>
        <b-tag class="is-taster-pale-pink header-tag" v-if="menuItem.selling_price">
          {{ menuItem.selling_price }} {{ menuItem.selling_unit_price }}
        </b-tag>
      </div>
      <div class="modal-card-content-description">
        {{ menuItem.description }}
      </div>
      <template v-for="modifierGroup in menuItem.modifierGroups">
        <div v-if="modifierGroup" :key="modifierGroup.uuid" class="modal-card-content-modifier">
          <div class="modifier-header">
            <div class="subtitle">
              {{ modifierGroup.label }}
            </div>
            <div class="modifier-tags">
              <b-taglist attached>
                <b-tag type="is-dark">Included</b-tag>
                <b-tag type="is-info">{{ modifierGroup.included_number }}</b-tag>
              </b-taglist>
              <b-taglist attached>
                <b-tag type="is-dark">Max</b-tag>
                <b-tag type="is-info">{{ modifierGroup.max_number }}</b-tag>
              </b-taglist>
            </div>
          </div>
          <div class="modifier-items-list">
            <div
              v-for="menuItemModifierGroup in modifierGroup.menu_items"
              :key="menuItemModifierGroup.uuid"
              class="modifier-item"
            >
              <div
                class="modifier-item-label"
                v-if="!(menuItem.category == 'combo' && hasRequiredModifierGroups(menuItemModifierGroup.uuid))"
              >
                {{ menuItemModifierGroup.label }}
              </div>
              <div
                class="modifier-item-price"
                v-if="!(menuItem.category == 'combo' && hasRequiredModifierGroups(menuItemModifierGroup.uuid))"
              >
                {{ menuItemModifierGroup.selling_price }} {{ menuItemModifierGroup.selling_unit_price }}
              </div>
              <div
                class="submodifier-content"
                v-if="menuItem.category == 'combo' && hasRequiredModifierGroups(menuItemModifierGroup.uuid)"
              >
                <div class="submodifier-content-price">
                  {{ menuItemModifierGroup.label }} {{ menuItemModifierGroup.selling_price }}
                  {{ menuItemModifierGroup.selling_unit_price }}
                </div>
                <template v-for="submodifierGroup in getRequiredModifierGroups(menuItemModifierGroup.uuid)">
                  <div
                    v-if="submodifierGroup && menuItem.category == 'combo'"
                    :key="submodifierGroup.uuid"
                    class="modal-card-content-submodifier"
                  >
                    <div class="submodifier-header">
                      <div class="subtitle">
                        {{ submodifierGroup.label }}
                      </div>
                      <div class="submodifier-tags">
                        <b-taglist attached>
                          <b-tag type="is-light">Included</b-tag>
                          <b-tag type="is-info">{{ modifierGroup.included_number }}</b-tag>
                        </b-taglist>
                        <b-taglist attached>
                          <b-tag type="is-light">Max</b-tag>
                          <b-tag type="is-info">{{ modifierGroup.max_number }}</b-tag>
                        </b-taglist>
                      </div>
                    </div>
                    <div class="submodifier-items-list">
                      <div
                        v-for="menuItemSubModifierGroup in submodifierGroup.menu_items"
                        :key="menuItemSubModifierGroup.uuid"
                        class="submodifier-item"
                      >
                        <div class="submodifier-item-label">{{ menuItemSubModifierGroup.label }}</div>
                        <div class="submodifier-item-price">
                          {{ menuItemSubModifierGroup.selling_price }} {{ menuItemSubModifierGroup.selling_unit_price }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-else class="empty">
    <div class="content has-text-grey has-text-centered">
      <p>
        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
      </p>
      <p>
        No matching menu item
        <span v-if="logo">
          on this side
          <figure class="image is-96x96" style="margin: auto">
            <img :src="logo" alt="Logo" />
          </figure>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  name: 'MenuItemModal',
  mixins: [auth],
  props: {
    menuItem: {
      type: Object,
    },
    conceptId: {
      type: String,
    },
    logo: {
      type: String,
    },
    menuItems: {
      type: Array,
    },
    modifierGroups: {
      type: Array,
    },
    currentLocation: {
      type: Object,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    menuItemFormattedPicture() {
      if (this.menuItem.picture) {
        return this.menuItem.picture;
      } else if (this.menuItem.image_url) {
        return this.menuItem.image_url;
      }
      return null;
    },
    menuItemDefaultPicture() {
      return 'https://semantic-ui.com/images/wireframe/image.png';
    },
  },
  methods: {
    toItemPage() {
      if (!this.hasAccessToConcept()) {
        return '';
      }
      if (this.menuItem.category) {
        let routeData;
        if (this.menuItem.category === 'combo') {
          routeData = this.$router.resolve(`/combos/${this.menuItem.uuid}/edit`);
        } else {
          routeData = this.$router.resolve(`/menu-items/${this.menuItem.uuid}/edit`);
        }
        window.open(routeData.href, '_blank');
      }
    },
    hasRequiredModifierGroups(uuid) {
      var menuItem = this.menuItems.filter((menuItem) => menuItem.uuid == uuid);
      if (
        menuItem &&
        menuItem[0].modifier_group_uuids_required != undefined &&
        menuItem[0].modifier_group_uuids_required.length > 0
      ) {
        return true;
      }
      return false;
    },
    getRequiredModifierGroups(uuid) {
      var requiredModifierGroups = [];
      var menuItem = this.menuItems.filter((menuItem) => menuItem.uuid == uuid);
      if (
        menuItem &&
        menuItem[0].modifier_group_uuids_required != undefined &&
        menuItem[0].modifier_group_uuids_required.length > 0
      ) {
        for (let modifierGroupRequiredUuid of menuItem[0].modifier_group_uuids_required) {
          var modifierGroup = this.modifierGroups.filter(
            (modifierGroup) => modifierGroup.uuid == modifierGroupRequiredUuid
          );
          if (modifierGroup) {
            let localizedModifierGroup = null;
            for (let locationUuid of [...this.currentLocation.location_tree]) {
              localizedModifierGroup = modifierGroup[0].locations.find(({ uuid }) => uuid === locationUuid);
              if (localizedModifierGroup) {
                requiredModifierGroups = requiredModifierGroups.concat(localizedModifierGroup);
                break;
              }
            }
          }
        }
        return requiredModifierGroups;
      }
      return requiredModifierGroups;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.modal-card {
  padding: 0;
  &-content {
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .header-title {
        margin: 0;
        flex: 0.85 1 0;
      }
      .hoverable {
        cursor: pointer;
      }
      .header-tag {
        flex: 0.1 1 0;
      }
      .tag {
        font-size: 20px;
        margin: 0;
      }
    }
    &-description {
      padding: 0 20px;
      padding-bottom: 20px;
    }
    &-modifier {
      .modifier-header {
        padding: 10px 20px;
        background-color: $light;
        display: flex;
        justify-content: space-between;
        .subtitle {
          flex: 0.5 1 0;
          margin: 0;
        }
        .modifier-tags {
          flex: 0.4 1 0;
          display: flex;
          justify-content: flex-end;
          .tags {
            margin: 0;
            margin-left: 5px;
            .tag {
              margin: 0;
            }
          }
        }
      }
      .modifier-items-list {
        padding: 10px 0;
        .modifier-item {
          font-weight: 600;
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;
          min-width: 0%;
          &-label {
            flex: 0.9 1 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &-price {
            flex: 0.1 1 0;
            text-align: right;
          }
        }
      }
    }
    &-submodifier {
      .submodifier-header {
        padding: 10px 20px;
        background-color: $dark;
        display: flex;
        justify-content: space-between;
        .subtitle {
          color: white;
          flex: 0.5 1 0;
          margin: 0;
        }
        .submodifier-tags {
          flex: 0.4 1 0;
          display: flex;
          justify-content: flex-end;
          .tags {
            margin: 0;
            margin-left: 5px;
            .tag {
              margin: 0;
            }
          }
        }
      }
      .submodifier-items-list {
        padding: 10px 0;
        background-color: rgba(0, 0, 0, 0.2);
        .submodifier-item {
          font-weight: 600;
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;
          min-width: 0%;
          &-label {
            flex: 0.9 1 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &-price {
            flex: 0.1 1 0;
            text-align: right;
          }
        }
      }
    }
  }
}

.submodifier-content {
  width: 100%;
}

.empty {
  height: 100%;
  padding: 100px 0 !important;
}

.overflow {
  max-height: 95vh;
  overflow-y: auto;
}
</style>
