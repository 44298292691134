import restaurantsApi from '@/apis/kitchens/restaurants.js';
import { restaurantsTypes } from '../mutation-types.js';

const availablePlatformsApi = ['uber', 'deliveroo', 'foodkit'];

export default {
  namespaced: true,
  state: {
    all: [],
    allByConcept: [],
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchByConcept: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [restaurantsTypes.FETCH_ALL_SUCCESS](state, restaurants) {
      state.all = restaurants;
      state.fetchAll.pending = false;
    },
    [restaurantsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [restaurantsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [restaurantsTypes.FETCH_ALL_BY_CONCEPT_SUCCESS](state, restaurants) {
      state.allByConcept = restaurants;
      state.fetchByConcept.pending = false;
    },
    [restaurantsTypes.FETCH_ALL_BY_CONCEPT_FAILURE](state, error) {
      state.allByConcept = null;
      state.fetchByConcept.pending = false;
      state.fetchByConcept.error = error;
    },
    [restaurantsTypes.FETCH_ALL_BY_CONCEPT_REQUEST](state) {
      state.fetchByConcept.pending = true;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(restaurantsTypes.FETCH_ALL_REQUEST);

      return restaurantsApi
        .fetchAll()
        .then(({ data }) => commit(restaurantsTypes.FETCH_ALL_SUCCESS, data))
        .catch((error) => commit(restaurantsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchByConcept({ commit }, conceptUuid) {
      commit(restaurantsTypes.FETCH_ALL_BY_CONCEPT_REQUEST);

      return restaurantsApi
        .fetchByConcept(conceptUuid)
        .then(({ data }) => commit(restaurantsTypes.FETCH_ALL_BY_CONCEPT_SUCCESS, data))
        .catch((error) => commit(restaurantsTypes.FETCH_ALL_BY_CONCEPT_FAILURE, error));
    },
  },
  getters: {
    getStatusesInCurrentLocation: (state, _, rootState) =>
      state.allByConcept
        .filter(
          (restaurant) =>
            restaurant.kitchen_restaurant.kitchen_location.uuid === rootState.locations.current.uuid ||
            restaurant.kitchen_restaurant.kitchen_location.parent_uuid === rootState.locations.current.uuid
        )
        .filter((restaurant) => availablePlatformsApi.includes(restaurant.platform_restaurant.id))
        .filter((restaurant) => restaurant.is_pos_integrated)
        .map((restaurant) => {
          return {
            ...restaurant,
            menu_status: rootState.menus.statuses.find((status) => status.restaurant_uuid === restaurant.uuid),
          };
        }),
  },
};
