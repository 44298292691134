import categoriesAPI from '@/apis/products/categories';
import { categoriesTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    all: [],
    fetchAll: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [categoriesTypes.FETCH_ALL_SUCCESS](state, categories) {
      state.all = categories;
      state.fetchAll.pending = false;
    },
    [categoriesTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [categoriesTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(categoriesTypes.FETCH_ALL_REQUEST);

      return categoriesAPI
        .fetchAll()
        .then((categories) => commit(categoriesTypes.FETCH_ALL_SUCCESS, categories))
        .catch((error) => commit(categoriesTypes.FETCH_ALL_FAILURE, error));
    },
  },
};
