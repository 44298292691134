<template>
  <ValidationProvider
    ref="provider"
    class="field"
    :class="{ 'has-error': hasError }"
    rules="required"
    :skip-if-empty="false"
    tag="div"
    v-slot="{ errors }"
  >
    <label class="label"> Actions </label>
    <div class="control has-icons-right is-expanded">
      <div class="select is-fullwidth is-multiple" :class="{ 'is-taster-critical': errors.length > 0 }">
        <select v-model="inner" @blur="onBlur">
          <option :value="null" disabled hidden>Selection</option>
          <option value="ADD_CHARGES">Add charges</option>
          <option value="BLOCK_PURCHASE_ORDER">Block purchase order</option>
        </select>
      </div>
      <div class="icon is-right">
        <i class="mdi mdi-chevron-down mdi-24px" />
      </div>
    </div>
    <p v-show="errors.length > 0" class="help is-taster-critical">Select an action</p>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ActionSelect',
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'action',
    event: 'action:select',
  },
  props: {
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hasError: false,
    };
  },
  computed: {
    inner: {
      get() {
        return this.action;
      },
      set(action) {
        this.$refs.provider.validate(action).then((result) => {
          this.hasError = !result.valid;
          this.$emit('action:select', action);
        });
      },
    },
  },
  methods: {
    onBlur() {
      this.$refs.provider.validate(this.inner).then((result) => (this.hasError = !result.valid));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

.field {
  margin: 0px !important;

  &.has-error {
    margin-bottom: -16px !important;
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

.help {
  line-height: 12px;
}

.is-taster-critical select {
  color: $taster-critical;
}
</style>
