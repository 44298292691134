<template>
  <form>
    <b-message type="is-info" has-icon v-if="!localizedInfos && currentLocation.parent_uuid === null">
      Supplier is not set for this country {{ currentLocation.id }}, click on save to link it to this country
    </b-message>
    <b-field label="Name" label-position="on-border">
      <b-input type="text" v-model="label" />
    </b-field>
    <b-field label="Supplier emails" label-position="on-border">
      <b-taginput v-model="mails" :before-adding="validateMailInput" />
    </b-field>
    <b-field label="Phone" label-position="on-border">
      <b-input v-model="phone" type="tel" maxlenght="25" />
    </b-field>
    <b-field label="Address" label-position="on-border">
      <b-input type="text" v-model="address" />
    </b-field>
    <b-field label="Website" label-position="on-border">
      <b-input type="text" v-model="website" />
    </b-field>
    <b-field>
      <b-switch v-model="automaticEmail">Automatic email</b-switch>
    </b-field>
    <b-field v-if="currentLocation.parent_uuid === null">
      <b-switch v-model="useTranslationWithProvider"
        >Use Translation with provider ({{ currentLocation.label }})</b-switch
      >
    </b-field>
    <b-field v-if="currentLocation.parent_uuid === null">
      <b-switch v-model="availableForOrdering">Available for ordering ({{ currentLocation.label }})</b-switch>
    </b-field>
    <b-field label="Tags" label-position="on-border">
      <b-taginput
        v-model="innerTags"
        :data="filteredTags"
        autocomplete
        :allow-new="true"
        :open-on-focus="true"
        icon="label"
        placeholder="Add a tag"
        @typing="getFilteredTags"
      >
        <template #defautl="{ option }">
          {{ option }}
        </template>
      </b-taginput>
    </b-field>
    <DaySelection label="Open days" :days="openDays" @update="(selectedDays) => (openDays = selectedDays)" />
    <DaySelection
      label="Delivery days"
      :days="deliveryDays"
      @update="(selectedDays) => (deliveryDays = selectedDays)"
    />
  </form>
</template>

<script>
import DaySelection from '@/components/Supplier/DaySelection.vue';
import { MAIL_REGEX } from '@/constants';

export default {
  name: 'SupplierInfoForm',
  components: {
    DaySelection,
  },
  props: {
    supplier: {
      type: Object,
      validator(obj) {
        return 'delivery_days' in obj && 'open_days' in obj;
      },
    },
    tags: {
      type: Array,
      default: () => [],
    },
    currentLocation: { type: Object, required: true },
    localizedInfos: { type: Object, required: false },
  },
  data() {
    return {
      filteredTags: [],
    };
  },
  computed: {
    label: {
      get() {
        return this.supplier.label;
      },
      set(label) {
        this.$emit('supplier:update', {
          ...this.supplier,
          label,
        });
      },
    },
    mails: {
      get() {
        return this.supplier.mails;
      },
      set(mails) {
        this.$emit('supplier:update', {
          ...this.supplier,
          mails,
        });
      },
    },
    phone: {
      get() {
        return this.supplier.phone;
      },
      set(phone) {
        this.$emit('supplier:update', {
          ...this.supplier,
          phone,
        });
      },
    },
    address: {
      get() {
        return this.supplier.address;
      },
      set(address) {
        this.$emit('supplier:update', {
          ...this.supplier,
          address,
        });
      },
    },
    website: {
      get() {
        return this.supplier.website;
      },
      set(website) {
        this.$emit('supplier:update', {
          ...this.supplier,
          website,
        });
      },
    },
    automaticEmail: {
      get() {
        return this.supplier.automatic_email;
      },
      set(automatic_email) {
        this.$emit('supplier:update', {
          ...this.supplier,
          automatic_email,
        });
      },
    },
    innerTags: {
      get() {
        return this.supplier.tags;
      },
      set(tags) {
        this.$emit('supplier:update', {
          ...this.supplier,
          tags,
        });
      },
    },
    openDays: {
      get() {
        return Object.keys(this.supplier.open_days).filter((day) => this.supplier.open_days[day]);
      },
      set(selectedDays) {
        const open_days = { ...this.supplier.open_days };
        for (const day in open_days) {
          open_days[day] = selectedDays.includes(day);
        }

        this.$emit('supplier:update', {
          ...this.supplier,
          open_days,
        });
      },
    },
    deliveryDays: {
      get() {
        return Object.keys(this.supplier.delivery_days).filter((day) => this.supplier.delivery_days[day]);
      },
      set(selectedDays) {
        const delivery_days = { ...this.supplier.delivery_days };
        for (const day in delivery_days) {
          delivery_days[day] = selectedDays.includes(day);
        }

        this.$emit('supplier:update', {
          ...this.supplier,
          delivery_days,
        });
      },
    },
    useTranslationWithProvider: {
      get() {
        if (this.localizedInfos) {
          return this.localizedInfos.use_translation_with_provider;
        }
        return true;
      },
      set(value) {
        if (this.currentLocation.parent_uuid !== null) {
          return;
        }
        this.$emit('supplier:update', {
          ...this.supplier,
          parentLocalizedInformation: {
            ...this.localizedInfos,
            use_translation_with_provider: value,
            location_uuid: this.currentLocation.uuid,
            update: this.localizedInfos !== null,
          },
        });
      },
    },
    availableForOrdering: {
      get() {
        if (this.localizedInfos) {
          return this.localizedInfos.available_for_ordering;
        }
        return false;
      },
      set(value) {
        if (this.currentLocation.parent_uuid !== null) {
          return;
        }
        this.$emit('supplier:update', {
          ...this.supplier,
          parentLocalizedInformation: {
            ...this.localizedInfos,
            available_for_ordering: value,
            location_uuid: this.currentLocation.uuid,
            update: this.localizedInfos !== null,
          },
        });
      },
    },
  },
  methods: {
    getFilteredTags(text) {
      this.filteredTags = this.tags.filter((tag) => tag.toLowerCase().include(text.toLowerCase()));
    },
    validateMailInput(value) {
      if (!MAIL_REGEX.test(value)) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Invalid email',
        });

        return false;
      }

      return true;
    },
  },
};
</script>
