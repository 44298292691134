<template>
  <div>
    <b-message v-if="isLoading" class="is-info">Usage Loading....</b-message>
    <b-message v-if="noUsage() && !isLoading" class="is-warning">No usage</b-message>
    <b-tabs size="is-small" style="max-width: 80%" v-else>
      <b-tab-item v-if="usage && usage.combos">
        <template slot="header">
          <span>
            Combos
            <b-tag rounded>{{ usage.combos.length }}</b-tag>
          </span>
        </template>
        <b-table
          narrowed
          striped
          bordered
          hoverable
          :data="usage.combos"
          v-if="usage.combos.length"
          @click="(row) => this.goToCombo(row)"
          default-sort="label"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" v-if="props.row.label" sortable>
              {{ props.row.label }}
            </b-table-column>
            <b-table-column field="quantity" label="Quantity" v-if="props.row.quantity">
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column field="unit" label="Unit" v-if="props.row.unit">
              {{ props.row.unit }}
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item v-if="usage && usage.modifier_groups">
        <template slot="header">
          <span>
            Modifier Groups
            <b-tag rounded>{{ usage.modifier_groups.length }}</b-tag>
          </span>
        </template>
        <b-table
          narrowed
          striped
          bordered
          hoverable
          :data="usage.modifier_groups"
          v-if="usage.modifier_groups.length"
          default-sort="label"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" v-if="props.row.label" sortable>
              {{ props.row.label }}
            </b-table-column>
            <b-table-column field="quantity" label="Quantity" v-if="props.row.quantity">
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column field="unit" label="Unit" v-if="props.row.unit">
              {{ props.row.unit }}
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item v-if="usage && usage.menu_items">
        <template slot="header">
          <span>
            Menu items
            <b-tag rounded>{{ usage.menu_items.length }}</b-tag>
          </span>
        </template>
        <b-table
          class="is-hoverable is-fullwidth is-striped"
          narrowed
          striped
          bordered
          hoverable
          :data="usage.menu_items"
          v-if="usage.menu_items.length"
          default-sort="label"
          :sticky-header="true"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" v-if="props.row.label" sortable>
              {{ props.row.label }}
            </b-table-column>
            <b-table-column field="quantity" label="Quantity" v-if="props.row.quantity">
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column field="unit" label="Unit" v-if="props.row.unit">
              {{ props.row.unit }}
            </b-table-column>
            <b-table-column field="link" label="Links" sortable>
              <!-- <b-button type="is-small is-light" @click="goToMenuItem(props.row)">Concept</b-button> -->
              <b-button type="is-small is-dark" @click="goToComposition(props.row)">Recipe</b-button>
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
      <b-tab-item v-if="usage && usage.recipes">
        <template slot="header">
          <span>
            Items
            <b-tag rounded>{{ usage.recipes.length }}</b-tag>
          </span>
        </template>
        <b-table
          narrowed
          striped
          bordered
          hoverable
          :data="usage.recipes"
          v-if="usage.recipes.length"
          @click="(row) => goToItems(row)"
          default-sort="label"
        >
          <template slot-scope="props">
            <b-table-column field="label" label="Label" sortable>
              {{ props.row.label }}
            </b-table-column>
            <b-table-column field="quantity" label="Quantity">
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column field="unit" label="Unit">
              {{ props.row.unit }}
            </b-table-column>
            <b-table-column field="location" label="Location">
              {{ getLocationLabel(props.row.location_uuid) }}
            </b-table-column>
          </template>
        </b-table>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['usage', 'source'],
  computed: {
    ...mapState({
      isLoading: (state) =>
        state.products.fetchUsage.pending || state.items.fetchUsage.pending || state.menuItems.fetchUsage.pending,
      locationsUnnested: (state) => state.locations.allUnnested,
    }),
  },
  methods: {
    getLocationLabel(locationUUID) {
      var location = this.locationsUnnested.filter((location) => location.uuid == locationUUID);
      if (location && location.length > 0) {
        return location[0].label;
      }
      return '';
    },
    noUsage() {
      if (!this.usage) {
        return true;
      }
      if (this.usage.recipes && this.usage.menu_items) {
        if (!this.usage.menu_items.length && !this.usage.recipes.length) {
          return true;
        }
        return false;
      }
      if (this.usage.combos && this.usage.menu_items) {
        if (!this.usage.menu_items.length && !this.usage.combos.length) {
          return true;
        }
        return false;
      }
    },
    moveToMenuItemPage(row) {
      if (this.source == 'item' || this.source == 'product') {
        this.$router.push('/composition/' + row.uuid);
      }
      this.toPage('/menu-items/' + row.uuid + '/edit', true);
    },
    goToItems(row) {
      if (this.hasAccessToRecipe()) {
        return this.$router.push('/items/' + row.generic_uuid);
      }
      return '';
    },
    goToComposition(row) {
      if (this.hasAccessToRecipe()) {
        return this.toPage('/composition/' + row.uuid, false);
      }
      return '';
    },
    goToMenuItem(row) {
      if (this.hasAccessToConcept()) {
        return this.toPage('/menu-items/' + row.uuid + '/edit', false);
      }
      return '';
    },
    goToCombo(row) {
      if (this.hasAccessToConcept()) {
        return this.toPage('/combos/' + row.uuid + '/edit', true);
      }
      return '';
    },
    toPage(suffix, newPage) {
      var routeData = this.$router.resolve(suffix);
      if (newPage) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(suffix);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .is-hoverable > tbody > tr {
  cursor: pointer;
}
</style>
