<template>
  <div class="box modal-card">
    <h3 class="subtitle">New Hub</h3>
    <HubForm
      v-model="hub"
      :current-location="currentLocation"
      :tags="tags"
      :is-saving="isSaveLoading"
      @save="create"
      @cancel="$emit('close')"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import HubForm from '@/components/Hub/HubForm.vue';
import { errorToaster } from '@/mixins';
import { hubsTypes } from '@/store/mutation-types';

export default {
  name: 'NewHub',
  components: { HubForm },
  mixins: [errorToaster],
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
      currentHub: (state) => state.hubs.current,
      tags: (state) => state.hubs.tags,
      isSaveLoading: (state) => state.hubs.createOne.pending,
      saveError: (state) => state.hubs.createOne.error,
    }),
    hub: {
      get() {
        return { ...this.currentHub };
      },
      set(value) {
        this.setCurrentHub(value);
      },
    },
  },
  created() {
    this.buildOne();
    if (this.$store.state.hubs.tags === null || this.$store.state.hubs.tags.length == 0) {
      this.fetchTags();
    }
  },
  methods: {
    ...mapActions('hubs', ['buildOne', 'createOne', 'fetchTags', 'addLocalizedInformation']),
    ...mapMutations('hubs', { setCurrentHub: hubsTypes.SET_CURRENT_HUB }),
    async create() {
      const localizedInfos = Array.isArray(this.hub.localized_informations) ? [...this.hub.localized_informations] : [];
      await this.createOne(this.hub);

      // Creating localized informations
      for (const info of localizedInfos) {
        const { account_code, contact_email, mails, location_uuid } = info;
        await this.addLocalizedInformation({
          hubUuid: this.currentHub.uuid,
          payload: { account_code, contact_email, mails, location_uuid },
        });
      }

      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.box {
  min-width: 760px;
}
</style>
