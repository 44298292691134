import Vue from 'vue';

const errorToaster = {
  watch: {
    isSaveLoading(value) {
      if (!value) {
        if (!this.saveError) {
          this.$buefy.toast.open({
            message: 'Successfully saved',
            type: 'is-success',
            position: 'is-bottom',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Saving failed',
            type: 'is-danger',
            position: 'is-bottom',
          });
        }
      }
    },
  },
};

const errorToasterDetailled = {
  watch: {
    isSaveLoading(value) {
      if (!value) {
        if (!this.saveError) {
          this.$buefy.toast.open({
            message: 'Successfully saved',
            type: 'is-success',
            position: 'is-bottom',
          });
        } else {
          if (this.saveError.response && this.saveError.response.body) {
            this.$buefy.toast.open({
              duration: 10000,
              message: this.saveError.response.body.detail,
              type: 'is-danger',
              position: 'is-bottom',
            });
          }
        }
      }
    },
  },
};

import { findKey } from 'lodash';
import roles from '@/router/roles';

const DragAndDrop = {
  methods: {
    getChildPayload(groupIndex) {
      return groupIndex;
    },
    getChildPayloadBetweenGroups(groupIndex, globalArray, itemsPerGroup) {
      return (index) => {
        return globalArray[groupIndex * itemsPerGroup + index];
      };
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    applyDragBetweenGroups(globalArray, itemToSwap, swapEvents, itemsPerGroup) {
      const resultArray = [...globalArray];
      let itemToAdd = itemToSwap;

      // remove item from position
      const rowOfItemToRemove = findKey(swapEvents, (event) => event.removedIndex !== null);
      const indexOfItemToRemove = rowOfItemToRemove * itemsPerGroup + swapEvents[rowOfItemToRemove].removedIndex;
      resultArray.splice(indexOfItemToRemove, 1);

      // add item at new position
      const rowOfItemToAdd = findKey(swapEvents, (event) => event.addedIndex !== null);
      const indexOfItemToAdd = rowOfItemToAdd * itemsPerGroup + swapEvents[rowOfItemToAdd].addedIndex;
      resultArray.splice(indexOfItemToAdd, 0, itemToAdd.uuid);

      // return new order
      return resultArray;
    },
  },
};

const auth = {
  methods: {
    getUserLocations(orga) {
      let groups = Vue.prototype.$auth.groups;

      return this.getUserRigths(groups, 'locations')[orga];
    },
    getUserRigths(groups, right) {
      let reg = new RegExp(`/(.+?)/rights/${right}/(.*?)/(.*?)[^/]$`);
      let matched = groups.filter((group) => {
        return group.match(reg);
      });
      let res = {};
      matched.forEach((group) => {
        let splitted = group.split('/');
        let orga = splitted[1];
        let uuid = splitted[splitted.length - 1];
        if (!(orga in res)) {
          res[orga] = [];
        }
        res[orga].push(uuid);
      });
      return res;
    },
    hasAccessToConcept() {
      return this.$auth.getUserRoles().includes(roles.itemConceptsViewer);
    },
    hasAccessToProduct() {
      return this.$auth.getUserRoles().includes(roles.itemProductEditor);
    },
    hasAccessToRecipe() {
      return this.$auth.getUserRoles().includes(roles.itemRecipesViewer);
    },
    isItemManagerAdmin() {
      return this.$auth.getUserRoles().includes(roles.itemManagerAdmin);
    },
    isItemComboRecipeEditor() {
      return this.$auth.getUserRoles().includes(roles.itemComboRecipeEditor);
    },
    isItemMenuItemRecipeEditor() {
      return this.$auth.getUserRoles().includes(roles.itemMenuItemRecipeEditor);
    },
    isItemRecipeCreator() {
      return this.$auth.getUserRoles().includes(roles.itemRecipesCreator);
    },
    isItemRecipeEditor() {
      return this.$auth.getUserRoles().includes(roles.itemRecipesEditor);
    },
    isItemRecipeProductEditor() {
      return this.$auth.getUserRoles().includes(roles.itemRecipesProductEditor);
    },
    isItemMenuEditor() {
      return this.$auth.getUserRoles().includes(roles.itemMenuEditor);
    },
    isItemMenuViewer() {
      return this.$auth.getUserRoles().includes(roles.itemMenuViewer);
    },
    canUploadMenu() {
      return this.$auth.getUserRoles().includes(roles.itemMenuUploader);
    },
  },
};

const displayFormatter = {
  methods: {
    getDeviseSign(devise) {
      if (devise == 'EUR') {
        return '€';
      } else if (devise == 'GBP') {
        return '£';
      }
      return devise;
    },
  },
};

export { errorToaster, errorToasterDetailled, DragAndDrop, auth, displayFormatter };
