<template>
  <form>
    <b-message type="is-info" has-icon v-if="!localizedInfos && currentLocation.parent_uuid === null">
      Hub is not set for this country {{ currentLocation.id }}, click on save to link it to this country
    </b-message>
    <b-field label="Name" label-position="on-border">
      <b-input type="text" v-model="label" />
    </b-field>
    <b-field label="Hub emails" label-position="on-border">
      <b-taginput v-model="mails" :before-adding="validateMailInput" />
    </b-field>
    <b-field label="Phone" label-position="on-border">
      <b-input type="tel" v-model="phone" maxlenght="25" />
    </b-field>
    <b-field label="Address" label-position="on-border">
      <b-input type="text" v-model="address" />
    </b-field>
    <b-field label="Website" label-position="on-border">
      <b-input type="text" v-model="website" />
    </b-field>
    <b-field>
      <b-switch v-model="automaticEmail">Automatic email</b-switch>
    </b-field>
    <b-field v-if="currentLocation.parent_uuid === null">
      <b-switch v-model="useTranslationWithProvider"
        >Use Translation with provider ({{ currentLocation.label }})</b-switch
      >
    </b-field>
    <b-field v-if="currentLocation.parent_uuid === null">
      <b-switch v-model="availableForOrdering">Available for ordering ({{ currentLocation.label }})</b-switch>
    </b-field>
    <b-field label="Tags" label-position="on-border">
      <b-taginput
        v-model="innerTags"
        :data="filteredTags"
        autocomplete
        :allow-new="true"
        :open-on-focus="true"
        icon="label"
        placeholder="Add a tag"
        @typing="getFilteredTags"
      >
        <template #default="{ option }">
          {{ option }}
        </template>
      </b-taginput>
    </b-field>
  </form>
</template>

<script>
import { MAIL_REGEX } from '@/constants';

export default {
  name: 'HubInfoForm',
  props: {
    hub: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    currentLocation: { type: Object, required: true },
    localizedInfos: { type: Object, required: false },
  },
  data() {
    return {
      filteredTags: [],
    };
  },
  computed: {
    label: {
      get() {
        return this.hub.label;
      },
      set(label) {
        this.$emit('hub:update', {
          ...this.hub,
          label,
        });
      },
    },
    mails: {
      get() {
        return this.hub.mails;
      },
      set(mails) {
        this.$emit('hub:update', {
          ...this.hub,
          mails,
        });
      },
    },
    phone: {
      get() {
        return this.hub.phone;
      },
      set(phone) {
        this.$emit('hub:update', {
          ...this.hub,
          phone,
        });
      },
    },
    address: {
      get() {
        return this.hub.address;
      },
      set(address) {
        this.$emit('hub:update', {
          ...this.hub,
          address,
        });
      },
    },
    website: {
      get() {
        return this.hub.website;
      },
      set(website) {
        this.$emit('hub:update', {
          ...this.hub,
          website,
        });
      },
    },
    automaticEmail: {
      get() {
        return this.hub.automatic_email;
      },
      set(automatic_email) {
        this.$emit('hub:update', {
          ...this.hub,
          automatic_email,
        });
      },
    },
    innerTags: {
      get() {
        return this.hub.tags;
      },
      set(tags) {
        this.$emit('hub:update', {
          ...this.hub,
          tags,
        });
      },
    },
    useTranslationWithProvider: {
      get() {
        if (this.localizedInfos) {
          return this.localizedInfos.use_translation_with_provider;
        }
        return true;
      },
      set(value) {
        if (this.currentLocation.parent_uuid !== null) {
          return;
        }
        this.$emit('supplier:update', {
          ...this.supplier,
          parentLocalizedInformation: {
            ...this.localizedInfos,
            use_translation_with_provider: value,
            location_uuid: this.currentLocation.uuid,
            update: this.localizedInfos !== null,
          },
        });
      },
    },
    availableForOrdering: {
      get() {
        if (this.localizedInfos) {
          return this.localizedInfos.available_for_ordering;
        }
        return false;
      },
      set(value) {
        if (this.currentLocation.parent_uuid !== null) {
          return;
        }
        this.$emit('hub:update', {
          ...this.hub,
          parentLocalizedInformation: {
            ...this.localizedInfos,
            available_for_ordering: value,
            location_uuid: this.currentLocation.uuid,
            update: this.localizedInfos !== null,
          },
        });
      },
    },
  },
  methods: {
    getFilteredTags(text) {
      this.filteredTags = this.tags.filter((tag) => tag.toLowerCase().includes(text.toLowerCase()));
    },
    validateMailInput(value) {
      if (!MAIL_REGEX.test(value)) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Invalid email',
        });

        return false;
      }

      return true;
    },
  },
};
</script>
