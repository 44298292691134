<template>
  <div>
    <p>{{ itemLabel }} is not available in {{ locationLabel }}.</p>
    <p>Do you want to make it available ?</p>
    <b-field grouped>
      <b-field v-if="canCancel">
        <b-button @click="$emit('cancel')">Back</b-button>
      </b-field>
      <b-field>
        <b-button class="is-primary" outlined @click="$emit('confirm')">Yes</b-button>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    locationLabel: {
      type: String,
      required: true,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
