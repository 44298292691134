<template>
  <div>
    <b-message type="is-danger" v-if="itemFetchError">
      {{ itemFetchError.body.detail }}
    </b-message>
    <b-loading :active.sync="isLoading" v-if="!itemFetchError" :is-full-page="false"></b-loading>
    <div class="section" v-if="item && currentLocation">
      <div v-if="item && !item.generic_uuid && !isLoading">
        <b-message type="is-warning"> {{ item.label }} is not available in {{ currentLocation.label }}. </b-message>
        <b-button class="is-info" style="margin-left: 8px" @click="addLocationToItem">
          Enable in {{ currentLocation.id }}
        </b-button>
        <b-button class="is-primary" icon-left="pencil" style="margin-left: 8px" @click="openEditItemModal">
          Edit
        </b-button>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column">
            <!-- TODO: Move to CompositionHeader Component -->
            <div class="is-flex">
              <span class="title">{{ item.label }} ({{ itemLocationLabel }})</span>
              <span v-if="item.item_measure.length">
                {{ item.item_measure[0].quantity }}{{ item.item_measure[0].unit }}
              </span>
            </div>
          </div>
          <div class="column is-narrow">
            <b-dropdown hoverable aria-role="list" position="is-bottom-left">
              <button class="button is-info" slot="trigger">
                <span>Actions</span>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <b-dropdown-item v-if="enableModification()" aria-role="listitem" @click="openEditItemModal">
                <div class="media">
                  <b-icon class="media-left" icon="pencil"></b-icon>
                  <div class="media-content">
                    <h3>Edit</h3>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="refreshPrices">
                <div class="media">
                  <b-icon class="media-left" icon="refresh"></b-icon>
                  <div class="media-content">
                    <h3>Refresh Prices</h3>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="enableModification() && isItemRecipeCreator() && !isParentLocation() && hasNoUsage()"
                aria-role="listitem"
                @click="openRemoveItemDialog(item)"
              >
                <div class="media">
                  <b-icon class="media-left" icon="delete"></b-icon>
                  <div class="media-content">
                    <h3>Remove {{ currentLocation.id }} Recipe</h3>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="enableModification() && isItemRecipeCreator() && isParentLocation() && hasNoUsage()"
                aria-role="listitem"
                @click="openRemoveItemDialog(item)"
              >
                <div class="media">
                  <b-icon class="media-left" icon="delete"></b-icon>
                  <div class="media-content">
                    <h3>Remove Recipe</h3>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="columns" v-if="currentLocation.uuid != item.location_uuid">
          <div class="column is-narrow">
            <b-notification type="is-warning" has-icon aria-close-label="Close notification">
              <div>
                No Specific Recipe exists for <b> `{{ item.label }}` </b> in <b>`{{ currentLocation.label }}`</b>
              </div>
              <div>
                The recipe below is inherited from <b> `{{ itemLocationLabel }}` </b>
              </div>
              <div><br /></div>
              <div>You can either:</div>
              <div>
                - Move to <b>{{ itemLocationLabel }} </b> location to edit <b>Global Recipe .</b>
              </div>
              <div>
                - Create Specific Recipe for <b>{{ currentLocation.label }}</b> clicking on "Create Recipe" (All the
                modifications made on this New Recipe won't be reflected on <b>{{ itemLocationLabel }} Recipe</b>)
              </div>
              <button @click="createRecipe()" class="button is-dark" style="margin-top: 20px">
                <b-icon icon="content-copy" type="is-warning"></b-icon>
                <span class="has-text-warning">Create Recipe</span>
              </button>
            </b-notification>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8">
            <div class="title is-4">
              <b-button
                v-if="currentLocation.uuid == item.location_uuid && isItemRecipeCreator()"
                type="is-info is-small"
                @click="openAddRecipeItemValidationModal(item.uuid)"
                outlined
                >Add Recipe Item</b-button
              >
              <b-button
                v-if="currentLocation.uuid == item.location_uuid && isItemRecipeCreator()"
                style="margin-left: 8px"
                type="is-primary is-small"
                @click="openEditItemValidationModal()"
                outlined
                >Set Product</b-button
              >
            </div>
            <div v-if="item.product_uuid">
              <div class="title is-4">Product</div>
              <div v-if="product">
                <router-link :to="`/products/${product.uuid}`" v-if="product.active && hasAccessToProduct()">
                  {{ product.label }}
                  <b-tag>{{ product.supplier.label }}</b-tag>
                </router-link>
                <div v-else-if="product.active">
                  {{ product.label }}
                  <b-tag>{{ product.supplier.label }}</b-tag>
                </div>
                <b-message v-else class="is-warning">No Product linked found</b-message>
              </div>
            </div>
            <div v-else>
              <div class="title is-4">Recipe</div>
              <div v-if="recipe && recipe.length && !item.product_uuid">
                <div class="composition-table-header">
                  <div class="name-col">Item</div>
                  <div class="info-col">Quantity</div>
                  <div class="info-col">Unit</div>
                  <div class="info-col">Supplier Price</div>
                  <div class="info-col">Logistics Costs</div>
                  <div class="info-col">Kitchen Price</div>
                </div>
                <div v-for="ingredient in recipe" :key="ingredient.item.uuid" class="composition">
                  <CompositionRow
                    :level="0"
                    :ingredient="ingredient"
                    :isSelected="itemUuid === ingredient.item.uuid"
                    :cost="itemCosts[ingredient.item.uuid]"
                    :canCompose="currentLocation.uuid == item.location_uuid"
                    :showRemove="recipe && recipe.length > 1"
                    @expand="
                      () =>
                        (visibility = {
                          ...visibility,
                          [ingredient.item.uuid]: visibility[ingredient.item.uuid] ? false : true,
                        })
                    "
                    @addItem="() => openAddRecipeItemModal(ingredient.item.uuid)"
                    @removeItem="removeItemFromComposition(item.uuid, ingredient.item.uuid)"
                    @selectItem="() => setCurrentItem(null, ingredient.item.uuid)"
                  />
                  <div v-show="visibility[ingredient.item.uuid]">
                    <CompositionItem
                      :level="1"
                      :ingredient="ingredient"
                      :selectedItemUuid="itemUuid"
                      :itemCosts="itemCosts"
                      :canCompose="currentLocation.uuid == item.location_uuid"
                      @selectItem="setCurrentItem"
                      @addItem="openAddRecipeItemModal"
                      @removeItem="removeItemFromComposition"
                      v-if="ingredient.item.recipe"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <b-message class="is-warning">No recipe found</b-message>
              </div>
            </div>
            <div class="columns" style="margin-top: 1.5rem">
              <div class="column is-narrow" v-if="itemVersions && itemVersions.length > 0">
                <div class="title is-4">Recipe Versions</div>
                <b-taglist v-for="i in Math.ceil(itemVersions.length / recipeVersionPerRow)" :key="i">
                  <b-tag
                    type="is-link"
                    v-for="(location, index) in itemVersions.slice(
                      (i - 1) * recipeVersionPerRow,
                      i * recipeVersionPerRow
                    )"
                    :key="index"
                    >{{ location }}</b-tag
                  >
                </b-taglist>
              </div>
            </div>
            <div class="title is-4" style="margin-top: 1.5rem">Usage</div>
            <UsageTabs :usage="usage" source="item" />
          </div>
          <ItemCard
            v-if="itemUuid"
            :itemUuid="itemUuid"
            :parentItemUuid="parentItemUuid"
            :parentItemUnit="currentParentItemUnit"
            :products="products"
            @save="updateItem"
            @remove="openRemoveItemDialog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { auth } from '@/mixins';

import AddRecipeItem from '../Composition/AddRecipeItem.vue';
import ComposeItem from '../Composition/ComposeItem.vue';
import CompositionItem from '@/components/Composition/CompositionItem.vue';
import ComposeRecipe from '@/views/Composition/ComposeRecipe.vue';
import CompositionRow from '@/components/Composition/CompositionRow.vue';
import EditItem from '../../components/Recipes/EditItem.vue';
import ItemCard from '@/components/Composition/ItemCard.vue';
import itemsAPI from '@/apis/items/items';
import costsAPI from '@/apis/pricer/costs';
import UsageTabs from '@/components/UsageTabs.vue';

export default {
  mixins: [auth],
  components: { CompositionItem, ItemCard, CompositionRow, UsageTabs },
  data() {
    return {
      visibility: {},
      currentParentItemUnit: null,
      itemUuid: null,
      genericItem: {},
      parentItemUuid: null,
      itemCosts: {},
      recipeVersionPerRow: 10,
    };
  },
  watch: {
    $route: 'fetchRecipeInCurrentLocation',
    currentLocation(newVal, oldVal) {
      if (newVal.uuid !== oldVal.uuid) {
        // Reset item card to make sure parent and item are still coherent
        this.itemUuid = null;
        this.parentItemUuid = null;
        this.itemCosts = {};
        this.fetchRecipeInCurrentLocation();
      }
    },
  },
  computed: {
    ...mapState({
      product: (state) => state.products.current,
      currentLocation: (state) => state.locations.current,
      item: (state) => state.items.current,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      prices: (state) => state.summary.prices,
      products: (state) => state.products.summary,
      recipe: (state) => state.items.currentRecipe,
      usage: (state) => state.items.currentUsage,
      itemFetchError: (state) => state.items.fetchOne.error,
      isLoading: (state) =>
        state.items.fetchOne.pending ||
        state.locations.fetchOne.pending ||
        state.locations.fetchAll.pending ||
        state.products.fetchOne.pending ||
        state.items.fetchRecipe.pending ||
        state.products.fetchAllSummary.pending ||
        state.items.createLocalizedItem.pending,
    }),
    itemLocationLabel() {
      var itemLocationLabel = '';
      var recipeLocation = this.locationsUnnested.find((location) => location.uuid == this.item.location_uuid);
      if (recipeLocation) {
        itemLocationLabel = recipeLocation.id;
      }
      return itemLocationLabel;
    },
    itemVersions() {
      var itemVersions = [];
      if (this.genericItem && this.genericItem.location_uuids.length > 0) {
        itemVersions = this.locationsUnnested
          .filter((location) => this.genericItem.location_uuids.includes(location.uuid))
          .map((location) => location.id);
      }
      itemVersions.sort();
      return itemVersions;
    },
  },
  methods: {
    ...mapActions('items', ['fetchOne', 'fetchRecipe', 'fetchUsage', 'removeOne', 'addLocation']),
    ...mapActions('products', { fetchProduct: 'fetchOne', fetchProducts: 'fetchAllSummary' }),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('summary', { fetchPrices: 'fetchPrices' }),
    setItemVisibility() {
      this.recipe.forEach((ingredient) => {
        if (ingredient && ingredient.item.recipe && !this.visibility[ingredient.item.uuid]) {
          this.$set(this.visibility, ingredient.item.uuid, false);
        }
      });
    },
    openEditItemValidationModal() {
      if (this.item.item_uuids && this.item.item_uuids.length > 0) {
        this.$buefy.dialog.confirm({
          title: 'Setting Product',
          message: `Setting <b> Product </b> will replace the current Recipe`,
          confirmText: 'Continue',
          cancelText: 'Cancel',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            this.openEditItemModal();
          },
        });
      } else {
        this.openEditItemModal();
      }
    },
    openEditItemModal() {
      this.$buefy.modal.open({
        component: EditItem,
        parent: this,
        hasModalCard: true,
        props: { id: this.item.uuid, usage: this.usage },
        events: {
          close: () => this.fetchRecipeInCurrentLocation(),
        },
      });
    },
    openRemoveItemDialog(item) {
      var itemLocation = this.getLocation(item);
      this.$buefy.dialog.confirm({
        title: 'Deleting item',
        message: `Are you sure you want to <b>delete</b> ${item.label}'s recipe in ${itemLocation.id}?`,
        confirmText: 'Delete item',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.removeOne(item.uuid).then(() => this.updateItem());
        },
      });
    },
    getLocation(item) {
      var locations = this.locationsUnnested.filter((location) => location.uuid == item.location_uuid);
      if (locations) {
        return locations[0];
      }
      return '';
    },
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    openAddRecipeItemValidationModal(parentItemUuid) {
      if (this.item.product_uuid) {
        this.$buefy.dialog.confirm({
          title: 'Adding Recipe Item',
          message: `Adding <b> Recipe Item </b> will replace <b>${this.product.label}</b>`,
          confirmText: 'Continue',
          cancelText: 'Cancel',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            this.openAddRecipeItemModal(parentItemUuid);
          },
        });
      } else {
        this.openAddRecipeItemModal(parentItemUuid);
      }
    },
    openAddRecipeItemModal(parentItemUuid) {
      this.$buefy.modal.open({
        component: AddRecipeItem,
        parent: this,
        hasModalCard: true,
        props: { parentItemUuid },
        events: {
          done: () => this.fetchRecipeInCurrentLocation(),
        },
      });
    },
    openModal(parentItemUuid) {
      this.$buefy.modal.open({
        component: ComposeItem,
        parent: this,
        hasModalCard: true,
        props: { parentItemUuid },
        events: {
          done: () => this.fetchRecipeInCurrentLocation(),
        },
      });
    },
    removeItemFromComposition(parentItemUuid, itemUuid) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Recipe Item',
        message: `Are you sure you want to <b>delete</b> a Recipe Element?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          itemsAPI
            .removeChild(parentItemUuid, { location_uuid: this.currentLocation.uuid, child_uuid: itemUuid })
            .then(() => this.fetchRecipeInCurrentLocation());
        },
      });
    },
    setCurrentItem(parentIngredient, itemUuid) {
      // TODO: Don't use null in line 59 and remove ternary
      this.currentParentItemUnit = parentIngredient ? parentIngredient.unit : this.item.item_measure[0].unit;
      this.parentItemUuid = parentIngredient ? parentIngredient.item.uuid : this.item.uuid;
      this.itemUuid = itemUuid;
    },
    computeItemPrice(ingredient) {
      this.itemCosts = {};
      costsAPI
        .compute({
          uuid: ingredient.item.uuid,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
          locationUuids: this.currentLocation.location_tree,
          save: true,
          type: 'ITEM',
        })
        .then(({ data }) => {
          var priceFormatted = {
            kitchen_currency: data.cost_details.currency,
            kitchen_price: data.cost_details.kitchen_cost,
            logistics_costs: data.cost_details.logistics_cost,
            net_price: data.net_food_cost,
            price: data.cost_details.supplier_cost,
            type: 'ITEM',
            uuid: ingredient.item.uuid,
          };
          this.$set(this.itemCosts, ingredient.item.uuid, priceFormatted);
        });

      ingredient.item.recipe && ingredient.item.recipe.map((ingredient) => this.computeItemPrice(ingredient));
    },
    updateItem() {
      this.itemUuid = null;
      this.parentItemUuid = null;
      this.fetchRecipeInCurrentLocation();
    },
    fetchRecipeInCurrentLocation() {
      const { itemId } = this.$route.params;

      this.fetchOne(itemId)
        .then(() => {
          this.genericItem = this.item;
          let localizedItem = null;
          for (let locationUuid of [...this.currentLocation.location_tree]) {
            localizedItem = this.item.locations.find(({ uuid }) => uuid === locationUuid);

            if (localizedItem) {
              return this.fetchOne(localizedItem.item_uuid);
            }
          }
        })
        .then(() => this.fetchUsage())
        .then(() => {
          if (this.item.product_uuid) return this.fetchProduct(this.item.product_uuid);
        })
        //TODO: Handle better quantity unit
        .then(() => {
          if (!this.item.item_measure.length) throw `${this.item.uuid} has no item measures`;

          return this.fetchRecipe({
            quantity: this.item.item_measure[0].quantity,
            unit: this.item.item_measure[0].unit,
          });
        })
        .then(() => {
          var recipeItems = this.getRecipeItems(this.recipe);
          this.getPrices(recipeItems);
          this.setItemVisibility();
        })
        .catch(console.warn);
    },
    addLocationToItem() {
      this.addLocation().then(() => this.updateItem());
    },
    enableModification() {
      return this.item.location_uuid == this.currentLocation.uuid;
    },
    hasNoUsage() {
      if (!this.usage) {
        return true;
      }
      if (!this.usage.menu_items && !this.usage.recipes) {
        return true;
      }
      if (this.usage.menu_items.length == 0 && this.usage.recipes.length == 0) {
        return true;
      }
      return false;
    },
    refreshPrices() {
      this.recipe.map((item) => this.computeItemPrice(item));
      this.setItemVisibility();
    },
    createRecipe() {
      this.$buefy.modal.open({
        component: ComposeRecipe,
        parent: this,
        hasModalCard: true,
        props: { itemLocated: this.item, genericItem: this.genericItem },
        events: {
          done: () => this.updateItem(),
        },
      });
    },
    getRecipeItems(recipe) {
      var recipeItems = [];
      if (this.recipe) {
        for (let recipeItem of recipe) {
          recipeItems.push({
            uuid: recipeItem.item.generic_uuid,
            located_uuid: recipeItem.item.uuid,
            quantity: recipeItem.quantity,
            unit: recipeItem.unit,
            location_uuid: this.currentLocation.uuid,
            type: 'ITEM',
          });
          if (recipeItem.item.recipe) {
            recipeItems = recipeItems.concat(this.getRecipeItems(recipeItem.item.recipe));
          }
        }
      }
      return recipeItems;
    },
    getPrices(recipeItems) {
      this.fetchPrices(recipeItems).then(() => {
        if (this.prices) {
          this.prices.map((itemPrice) => {
            if (itemPrice) {
              var recipeItem = recipeItems.find((recipeItem) => recipeItem.uuid == itemPrice.uuid);
              this.$set(this.itemCosts, recipeItem.located_uuid, itemPrice);
            }
          });
        }
      });
    },
  },
  mounted() {
    this.fetchRecipeInCurrentLocation();
    if (this.$store.state.locations.all === null || this.$store.state.locations.all.length == 0) {
      this.fetchAllLocations();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      this.fetchUnnestedLocations();
    }
    if (this.$store.state.products.summary === null || this.$store.state.products.summary.length == 0) {
      this.fetchProducts();
    }
  },
};
</script>

<style scoped>
.composition:nth-child(odd) {
  /* background: rgba(0, 0, 0, 0.03); */
}
.composition-table-header {
  font-weight: bold;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
}
.name-col {
  width: 80%;
}
.info-col {
  text-align: center;
  width: 100px;
  padding: 4px;
}
</style>
