<template>
  <section class="section">
    <b-message type="is-danger" v-if="fetchError && fetchError.body">{{ fetchError.body.detail }}</b-message>
    <b-loading :active.sync="isLoading" v-if="!fetchError"></b-loading>
    <div class="container" v-if="menuItem">
      <MenuItemHeader
        :kitchenPrice="getKitchenPrice()"
        :grossFoodCost="getGrossFoodCost()"
        :menuItem="menuItem"
        :isSaveLoading="isSaveLoading"
        :isKitchenPrice="isKitchenPriceDefined()"
        v-if="menuItem"
        @save="() => save()"
      />
      <b-tabs size="medium" type="is-boxed" vertical>
        <b-tab-item>
          <template slot="header">General</template>
          <div class="columns">
            <div class="column">
              <h5 class="title is-5">Internal Informations</h5>
              <MenuItemForm
                :label.sync="label"
                :category.sync="category"
                :tags.sync="tags"
                :categories="categories"
                :menuItemTags="menuItemTags"
                :mep.sync="mep"
                :displayMep="true"
                :displayCategory="true"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <h5 class="title is-5">Usage</h5>
            </div>
          </div>
          <UsageTabs :usage="usage" source="menuItem" />
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Platforms</template>
          <MenuItemPlatform />
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Recipe</template>
          <div class="columns" v-if="isItemMenuItemRecipeEditor() && !currentLocation.parent_uuid">
            <div class="column is-narrow">
              <b-button class="is-info" outlined icon-left="plus-circle" @click="openModal">Add item</b-button>
            </div>
            <div class="columns"></div>
            <div class="column is-narrow">
              <b-button class="is-link is-light" outlined icon-left="plus-circle" @click="openImportRecipeModal"
                >Import Recipe</b-button
              >
            </div>
          </div>
          <MenuItemRecipe
            v-if="recipe && recipe.length"
            :recipe="recipe"
            :currentLocation="currentLocation"
            @removeItem="removeItemFromRecipe"
            @saveItem="(item) => saveItemRecipe(item)"
          />
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Modifiers Groups</template>
          <MenuItemModifierGroup :modifiers="modifiers" :modifierGroupUuidsSorted.sync="modifierGroupUuidsSorted" />
        </b-tab-item>
        <b-tab-item>
          <template slot="header">Pictures</template>
          <MenuItemPictures
            :menuItem="currentMenuItem"
            :sendPicture="sendPicture"
            :itemInformations="currentMenuItemInformations"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { displayFormatter } from '@/mixins';
import { conceptsTypes } from '@/store/mutation-types';
import { menuItemsTypes } from '@/store/mutation-types';
import ChooseItem from '@/views/ChooseItem.vue';
import MenuItemHeader from '@/components/MenuItem/MenuItemHeader.vue';
import MenuItemForm from '@/components/MenuItem/MenuItemForm.vue';
import MenuItemModifierGroup from '@/components/MenuItem/ModifierGroup/MenuItemModifierGroup.vue';
import MenuItemPlatform from '@/components/MenuItem/Platform/MenuItemPlatform.vue';
import ImportMenuItemRecipe from '@/components/MenuItem/Recipe/ImportMenuItemRecipe.vue';
import MenuItemRecipe from '@/components/MenuItem/Recipe/MenuItemRecipe.vue';
import MenuItemPictures from '@/components/MenuItem/Pictures/MenuItemPictures.vue';
import UsageTabs from '@/components/UsageTabs.vue';

import { errorToasterDetailled, auth } from '@/mixins';

export default {
  name: 'EditMenuItem',
  mixins: [errorToasterDetailled, displayFormatter, auth],
  components: {
    MenuItemHeader,
    MenuItemForm,
    MenuItemModifierGroup,
    MenuItemPlatform,
    MenuItemRecipe,
    MenuItemPictures,
    UsageTabs,
  },
  data: () => ({
    modifiers: [],
    hover: false,
    menuItemCost: null,
  }),
  watch: {
    currentLocation(newVal, oldVal) {
      if (newVal.uuid !== oldVal.uuid) {
        if (this.currentMenuItem) {
          this.fetchUsage(this.currentLocation.location_tree);
          this.getPrices();
        }
        this.fetchItemInformations({ itemUuid: this.$route.params.menuItemId });
      }
    },
    concepts(newVal) {
      if (newVal && newVal.length > 0) {
        if (!this.currentConcept || (this.currentConcept && Object.keys(this.currentConcept) == 0)) {
          if (
            this.concepts &&
            this.currentMenuItem &&
            this.currentMenuItem.concept_uuids &&
            this.currentMenuItem.concept_uuids.length == 1
          ) {
            this.setCurrentConcept(
              this.concepts.filter((concept) => concept.uuid == this.currentMenuItem.concept_uuids)[0]
            );
          }
        }
      }
    },
    currentMenuItem(newVal) {
      if (newVal) {
        if (!this.currentConcept || (this.currentConcept && Object.keys(this.currentConcept) == 0)) {
          if (
            this.concepts &&
            this.currentMenuItem &&
            this.currentMenuItem.concept_uuids &&
            this.currentMenuItem.concept_uuids.length == 1
          ) {
            this.setCurrentConcept(
              this.concepts.filter((concept) => concept.uuid == this.currentMenuItem.concept_uuids)[0]
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      currentItemPrice: (state) => state.items.itemPrices,
      currentMenuItemInformations: (state) => state.itemsInformations.all,
      currentConcept: (state) => state.concepts.current,
      currentMenuItem: (state) => state.menuItems.current,
      currentLocation: (state) => state.locations.current,
      fetchError: (state) => state.menuItems.fetchOne.error || state.items.computeItemPrice.error,
      isLoading: (state) =>
        state.menuItems.fetchOne.pending ||
        state.menuItems.fetchModifierGroups.pending ||
        state.itemsInformations.uploadPicture.pending ||
        state.itemsInformations.createOne.pending ||
        state.itemsInformations.updateOne.pending ||
        state.menuItems.computeMenuItemPrice.pending ||
        state.items.computeItemPrice.pending,
      isSaveLoading: (state) =>
        state.menuItems.updateOne.pending ||
        state.menuItems.updateRecipeItem.pending ||
        state.menuItems.removeRecipeItem.pending ||
        state.menuItems.updateLocation.pending ||
        state.itemsInformations.uploadPicture.pending,
      prices: (state) => state.summary.prices,
      restaurants: (state) => state.restaurants.all,
      saveError: (state) =>
        state.menuItems.updateOne.error ||
        state.menuItems.updateRecipeItem.error ||
        state.menuItems.removeRecipeItem.error ||
        state.menuItems.updateLocation.error,
      locations: (state) => state.locations.all,
      categories: (state) => state.menuItems.categories,
      menuItemTags: (state) => state.menuItems.tags,
      usage: (state) => state.menuItems.currentUsage,
    }),
    label: {
      get() {
        return this.menuItem.label;
      },
      set(value) {
        this.setCurrentMenuItem({ ...this.menuItem, label: value });
      },
    },
    mep: {
      get() {
        return this.menuItem.mep;
      },
      set(value) {
        this.setCurrentMenuItem({ ...this.menuItem, mep: value });
      },
    },
    category: {
      get() {
        return this.menuItem.category;
      },
      set(value) {
        this.setCurrentMenuItem({ ...this.menuItem, category: value });
      },
    },
    tags: {
      get() {
        return this.menuItem.tags;
      },
      set(value) {
        this.setCurrentMenuItem({ ...this.menuItem, tags: value });
      },
    },
    menuItem: {
      get() {
        return this.currentMenuItem;
      },
      set(value) {
        this.setCurrentMenuItem(value);
      },
    },
    modifierGroupUuidsSorted: {
      get() {
        return this.menuItem.modifier_group_uuids_sorted;
      },
      set(value) {
        this.setCurrentMenuItem({ ...this.menuItem, modifier_group_uuids_sorted: value });
      },
    },
    recipe() {
      return this.menuItem.recipe;
    },
  },
  methods: {
    ...mapActions('menuItems', [
      'fetchOne',
      'updateOne',
      'fetchModifierGroups',
      'updateItemRecipe',
      'removeItemFromRecipe',
      'addItemToRecipe',
      'fetchUsage',
    ]),
    ...mapActions('items', ['computeItemPrice']),
    ...mapActions('concepts', { fetchConcepts: 'fetchAll' }),
    ...mapActions('itemsInformations', {
      fetchItemInformations: 'fetchAll',
      updateItemInformation: 'updateOne',
      uploadPicture: 'uploadPicture',
    }),
    ...mapActions('locations', { fetchLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapActions('menuItems', ['updateLocation', 'fetchCategories', 'fetchTags']),
    ...mapActions('modifierGroups', { fetchAllModifierGroups: 'fetchAll' }),
    ...mapActions('platforms', { fetchAllPlatforms: 'fetchAll' }),
    ...mapActions('restaurants', { fetchRestaurants: 'fetchAll' }),
    ...mapActions('summary', { fetchPrices: 'fetchPrices' }),
    ...mapMutations('menuItems', { setCurrentMenuItem: menuItemsTypes.SET_CURRENT }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
    save() {
      if (this.currentMenuItem.location_uuids.includes(this.currentLocation.uuid)) {
        this.updateLocation(this.currentLocation.uuid)
          .then(() => this.updateOne())
          .then(() => this.$router.push('/menu-items'));
      } else {
        this.updateOne().then(() => this.$router.push('/menu-items'));
      }
    },
    saveItemRecipe(item) {
      this.computeItemPrice({
        uuid: item.uuid,
        quantity: item.quantity,
        unit: item.unit,
        locationUuids: [this.getParentLocationUUID()],
        save: false,
      }).then(() => {
        if (
          this.currentItemPrice &&
          this.currentItemPrice.cost_details &&
          this.currentItemPrice.cost_details.kitchen_cost != null &&
          this.currentItemPrice.cost_details.supplier_cost != null
        ) {
          this.updateItemRecipe(item);
        } else {
          this.$buefy.dialog.confirm({
            title: `Editing ${item.label}`,
            message: `You can not <b>edit</b> quantity: <br> ${this.getAlertMessage(item)}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: `Go to ${item.label} page`,
            onCancel: () => this.goToItems(item),
          });
        }
      });
    },
    getParentLocationUUID() {
      if (!this.currentLocation.parent_uuid) {
        return this.currentLocation.uuid;
      }
      return this.currentLocation.parent_uuid;
    },
    getAlertMessage(item) {
      if (this.currentItemPrice == null || this.currentItemPrice.cost_details == null) {
        return `Item Price is not defined for <b>'${item.unit}'</b>`;
      }
      if (this.currentItemPrice.cost_details.kitchen_cost == null) {
        return `Kitchen Price of <b>${item.quantity} ${item.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      if (this.currentItemPrice.cost_details.supplier_cost == null) {
        return `Supplier Price of <b>${item.quantity} ${item.unit}</b> of <b>${item.label}</b> can not be computed`;
      }
      return `<b>'${item.unit}'</b> is not in ${item.label}' s item measures`;
    },
    goToItems(item) {
      return this.toPage('/items/' + item.uuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
    openModal() {
      this.$buefy.modal.open({
        component: ChooseItem,
        parent: this,
        hasModalCard: true,
        props: {
          locationUuids: this.currentMenuItem.location_uuids,
        },
        events: {
          created: (item) => this.addItemToRecipe({ uuid: item.uuid, quantity: 1, unit: 'g' }),
          selected: (item, quantity, unit) => this.addItemToRecipe({ uuid: item.uuid, quantity: quantity, unit: unit }),
        },
      });
    },
    openImportRecipeModal() {
      this.$buefy.modal.open({
        component: ImportMenuItemRecipe,
        parent: this,
        hasModalCard: true,
        props: {
          locationUuids: this.currentMenuItem.location_uuids,
        },
        events: {
          selected: (menuItemSelected) => {
            menuItemSelected.recipe.map((recipeItem) =>
              this.addItemToRecipe({ uuid: recipeItem.uuid, quantity: recipeItem.quantity, unit: recipeItem.unit })
            );
          },
        },
      });
    },
    sendPicture(file, conceptUuid, platformUuid, locationUuid, basewidth) {
      this.uploadPicture({
        itemUuid: this.currentMenuItem.uuid,
        conceptUuid: conceptUuid,
        locationUuid: locationUuid,
        picture: file,
        platformUuid: platformUuid,
        basewidth: basewidth,
      });
    },
    checkExistence(element) {
      if (element === null || element === undefined || (element && element.length == 0)) {
        return false;
      }
      return true;
    },
    getItemPriceInformations(menuItem) {
      const initialValue = {};
      const itemsInformations = this.currentMenuItemInformations.reduce((obj, item) => {
        return {
          ...obj,
          [item['item_uuid']]: item,
        };
      }, initialValue);
      if (!itemsInformations[menuItem.uuid]) {
        return {
          selling_price: 0,
          selling_unit_price: 0,
        };
      } else {
        return {
          selling_price: itemsInformations[menuItem.uuid].selling_price,
          selling_unit_price: itemsInformations[menuItem.uuid].selling_unit_price,
        };
      }
    },
    getPrices() {
      var recipeItems = [
        {
          uuid: this.menuItem.uuid,
          quantity: 1,
          unit: 'each',
          location_uuid: this.currentLocation.uuid,
          type: 'MENU_ITEM',
        },
      ];
      this.fetchPrices(recipeItems).then(() => {
        if (this.prices) {
          this.prices.map((itemPrice) => {
            this.menuItemCost = itemPrice;
          });
        }
      });
    },
    getSellingPrice() {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      return `${this.getSellingPriceValue()} ${deviseSign}`;
    },
    getSellingPriceValue() {
      return this.getItemPriceInformations(this.menuItem).selling_price;
    },
    getCurrentMenuItemVAT() {
      var correspondingRestaurants = this.restaurants
        .filter((restaurant) => this.menuItem.concept_uuids.includes(restaurant.concept_uuid))
        .filter((restaurant) =>
          restaurant.kitchen_restaurant.kitchen_location.location_tree.includes(this.currentLocation.uuid)
        );
      if (correspondingRestaurants.length > 0) {
        return correspondingRestaurants[0].restaurant_information.vat / 100;
      }
      return 0;
    },
    getGrossFoodCost() {
      if (this.menuItemCost) {
        return `${Math.round(
          100 -
            ((this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()) - this.menuItemCost.kitchen_price) *
              100) /
              (this.getSellingPriceValue() / (1 + this.getCurrentMenuItemVAT()))
        )}%`;
      }
      return '';
    },
    isKitchenPriceDefined() {
      if (this.menuItemCost && (this.menuItemCost.kitchen_price || this.menuItemCost.kitchen_price === 0)) {
        return true;
      }
      return false;
    },
    getKitchenPrice() {
      var deviseSign = this.getDeviseSign(this.currentLocation.location_country.currency_code);
      if (this.menuItemCost && this.menuItemCost.kitchen_price) {
        return `${this.menuItemCost.kitchen_price.toFixed(3)} ${deviseSign}`;
      }
      return '-';
    },
  },
  mounted() {
    if (!this.checkExistence(this.$store.state.locations.all)) {
      this.fetchLocations();
    }
    if (!this.checkExistence(this.$store.state.locations.allUnnested)) {
      this.fetchUnnestedLocations();
    }
    if (!this.checkExistence(this.$store.state.concepts.all)) {
      this.fetchConcepts();
    }
    if (!this.checkExistence(this.$store.state.platforms.all)) {
      this.fetchAllPlatforms();
    }
    if (!this.checkExistence(this.$store.state.modifierGroups.all)) {
      this.fetchAllModifierGroups();
    }
    this.fetchOne(this.$route.params.menuItemId)
      .then(() => {
        if (this.currentMenuItem && this.currentMenuItem.uuid) {
          this.fetchModifierGroups();
        }
      })
      .then(() => {
        if (this.currentMenuItem && this.currentMenuItem.uuid) {
          this.fetchUsage(this.currentLocation.location_tree);
          this.getPrices();
        }
      });
    if (!this.checkExistence(this.$store.state.menuItems.categories)) {
      this.fetchCategories();
    }
    if (!this.checkExistence(this.$store.state.menuItems.tags)) {
      this.fetchTags();
    }
    this.fetchItemInformations({ itemUuid: this.$route.params.menuItemId });
    if (this.$store.state.restaurants.all === null || this.$store.state.restaurants.all.length == 0) {
      this.fetchRestaurants();
    }
  },
};
</script>
