<template>
  <div class="box modal-card" style="overflow: visible">
    <b-loading :active="multipleComputePriceLoading" v-if="!fetchError"></b-loading>
    <h3 class="subtitle">Add an item</h3>
    <div class="columns">
      <div class="column" label="Quantity" label-position="on-border">
        <b-input type="number" size="is-small" v-model.number="quantity" step=".01"> </b-input>
      </div>
      <div class="column" label="Unit" label-position="on-border">
        <b-select size="is-small" placeholder="Select a unit" v-model="unit">
          <option value="g">g</option>
          <option value="ml">ml</option>
          <option value="each">each</option>
        </b-select>
      </div>
      <div class="column is-two-thirds" expanded>
        <b-autocomplete
          expanded
          rounded
          v-model="itemName"
          :data="filteredDataArray"
          placeholder="Search for an existing item..."
          icon="magnify"
          field="label"
          @select="select"
        >
          <template slot="empty">No results found</template>
        </b-autocomplete>
      </div>
    </div>
    <b-button style="width: 100%" class="is-info" @click="add">Add</b-button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ChooseItem',
  props: ['locationUuids'],
  data: () => ({
    itemName: '',
    quantity: 1,
    unit: 'g',
    displayLink: false,
    multipleComputePriceLoading: false,
  }),
  methods: {
    ...mapActions('items', ['buildOne', 'createOne', 'fetchAll', 'computeItemPrice']),
    ...mapMutations('items', { setCurrent: 'SET_CURRENT' }),
    select(item) {
      this.setCurrent(item);
      this.displayLink = true;
    },
    add() {
      this.multipleComputePriceLoading = true;
      var costLocations = this.getCostLocationUUIDs();
      Promise.all(
        costLocations.map((location) =>
          this.computeItemPrice({
            uuid: this.current.uuid,
            quantity: this.quantity,
            unit: this.unit,
            locationUuids: this.getLocationUUIDs(location),
            save: false,
          })
        )
      ).then((responses) => {
        this.multipleComputePriceLoading = false;
        var addItem = true;
        for (let response of responses) {
          var prices = response.prices;
          var locationUUIDS = response.opts.locationUuids;
          if (
            !(
              prices &&
              prices.cost_details &&
              prices.cost_details.kitchen_cost != null &&
              prices.cost_details.supplier_cost != null
            )
          ) {
            addItem = false;
            this.$buefy.dialog.confirm({
              title: `Adding ${this.current.label}`,
              message: `You can not <b>add</b> this ${this.current.label} to recipe: <br> ${this.getAlertMessage(
                prices,
                locationUUIDS
              )}`,
              type: 'is-danger',
              hasIcon: true,
              cancelText: `Go to ${this.current.label} page`,
              onCancel: () => this.goToItems(),
            });
          }
        }
        if (addItem) {
          this.$emit('selected', this.current, this.quantity, this.unit);
          this.$parent.close();
        }
      });
    },
    getAlertMessage(prices, locationUUIDS) {
      var locationLabel = this.getLocationLabel(locationUUIDS[0]);
      if (prices == null || prices.cost_details == null) {
        return `Item Price is not defined for <b>'${this.unit}'</b> in <b> ${locationLabel} </b>`;
      }
      if (!this.current.item_measure.map((item_measure) => item_measure.unit).includes(this.unit)) {
        return `<b>'${this.unit}'</b> is not in ${this.current.label}' s item measures`;
      }
      if (prices.cost_details.kitchen_cost == null) {
        return `Kitchen Price of <b>${this.quantity} ${this.unit}</b> of <b>${this.current.label}</b> can not be computed in <b> ${locationLabel} </b>`;
      }
      if (prices.cost_details.supplier_cost == null) {
        return `Supplier Price of <b>${this.quantity} ${this.unit}</b> of <b>${this.current.label}</b> can not be computed in <b> ${locationLabel} </b>`;
      }
      return `Price Issue in <b> ${locationLabel} </b>`;
    },
    getLocationLabel(locationUUID) {
      var location = this.locationsUnnested.filter((location) => location.uuid == locationUUID);
      if (location && location.length > 0) {
        return location[0].label;
      }
      return '';
    },
    getCostLocationUUIDs() {
      return this.locationsUnnested.filter(
        (location) => this.locationUuids.includes(location.uuid) && !location.is_deleted
      );
    },
    getLocationUUIDs(location) {
      if (!location.parent_uuid) {
        return [location.uuid];
      }
      return [location.uuid, location.parent_uuid];
    },
    goToItems() {
      return this.toPage('/items/' + this.current.uuid);
    },
    toPage(suffix) {
      var routeData = this.$router.resolve(`${suffix}?locationUuid=${this.currentLocation.uuid}`);
      window.open(routeData.href, '_blank');
    },
  },
  computed: {
    ...mapState({
      current: (state) => state.items.current,
      currentItemPrice: (state) => state.items.itemPrices,
      items: (state) => state.items.all,
      currentLocation: (state) => state.locations.current,
      fetchError: (state) => state.items.computeItemPrice.error,
      locationsUnnested: (state) => state.locations.allUnnested,
    }),
    filteredDataArray() {
      // Remove from filtered data array items in recipe
      return this.items.filter((option) => option.label.toLowerCase().includes(this.itemName.toLowerCase()));
    },
    item: {
      get() {
        return { ...this.current };
      },
      set(value) {
        this.setCurrent(value);
      },
    },
  },
  created() {
    this.buildOne();
    if (this.$store.state.items.all === null || this.$store.state.items.all.length == 0) {
      this.fetchAll();
    }
  },
};
</script>
