<template>
  <div class="card menu-item">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-96x96">
            <img
              :src="menuItemPicture ? menuItemPicture : 'https://semantic-ui.com/images/wireframe/image.png'"
              alt=""
            />
          </figure>
        </div>
        <div class="media-content">
          <div class="is-flex" style="align-items: baseline">
            <h6 class="title is-6" style="margin-bottom: 4px">
              {{ menuItem.label }}
            </h6>
            <b-tag class="is-taster-pale-pink" v-if="menuItem.selling_price != null">
              {{ menuItem.selling_price }}{{ menuItem.selling_unit_price }}
            </b-tag>
          </div>
          <div class="is-size-7">
            {{ menuItem.description ? menuItem.description : '' }}
          </div>
        </div>
        <button
          v-if="(isMenuItem && isItemMenuItemRecipeEditor()) || (!isMenuItem && isItemComboRecipeEditor())"
          class="delete"
          @click.stop="removeMenuItemFromConcept(menuItem)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  name: 'menuitemcard',
  props: ['menuItem', 'conceptId', 'locationUuid', 'concept', 'platformUuid', 'isMenuItem'],
  computed: {
    menuItemPicture() {
      return this.menuItem.picture || null;
    },
  },
  methods: {
    removeMenuItemFromConcept(menuItem) {
      this.$buefy.dialog.confirm({
        title: 'Removing Menu Item From Concept',
        message: `If you confirm this action, you are going to remove <b>${menuItem.label}</b> from <b>${this.concept.label}</b> for <b> all countries</b>.`,
        confirmText: 'Remove',
        onConfirm: () => this.$emit('delete', menuItem.uuid),
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
  },
};
</script>

<style lang="scss">
.tag {
  margin: 5px;
}
.subtitle {
  text-transform: capitalize;
}
.menu-item.card {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 45%;
  &:hover {
    cursor: pointer;
  }
}
</style>
