<template>
  <div>
    <div class="is-size-7 has-text-weight-bold section-title" v-if="value.item_measure">
      MEASURE
      <a
        v-if="!isDisabled && isItemRecipeProductEditor()"
        @click="() => value.item_measure.push({ quantity: 0, unit: 'g' })"
        class="is-size-7"
        >Add</a
      >
    </div>
    <div v-for="(itemMeasure, index) in value.item_measure" :key="index" style="padding: 8px 8px 0px 8px">
      <b-field grouped>
        <b-field label="Quantity" label-position="on-border">
          <b-input
            type="number"
            step="0.001"
            v-model.number="itemMeasure.quantity"
            size="is-small"
            :disabled="isDisabled"
          ></b-input>
        </b-field>
        <b-field label="Unit" label-position="on-border">
          <b-select placeholder="Select a unit" v-model="itemMeasure.unit" size="is-small" :disabled="isDisabled">
            <option value="g">g</option>
            <option value="ml">ml</option>
            <option value="each">each</option>
          </b-select>
        </b-field>
        <b-field
          ><a
            v-if="!isDisabled && isItemRecipeProductEditor()"
            @click="() => value.item_measure.splice(index, 1)"
            class="is-size-7"
            >Remove</a
          ></b-field
        >
      </b-field>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['item', 'isDisabled'],
  data() {
    return {
      value: { ...this.item },
    };
  },
  watch: {
    item(newValue, oldValue) {
      if (newValue.uuid !== oldValue.uuid) {
        this.value = { ...newValue };
      }
    },
    value: {
      handler() {
        this.$emit('update:item', { ...this.value });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid #8080802e;
}
</style>
