<template>
  <div>
    <div class="columns">
      <div class="column">
        <div style="display: flex; padding-bottom: 12px">
          <!-- <span
            class="title link"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @click="$router.push(`/menu-items/${$route.params.menuItemId}/edit`)"
            v-if="hasAccessToConcept()"
          >
            {{ label }}
            <b-button v-if="hover" icon-right="open-in-new" type="is-text" rounded />
          </span>
          <div class="title" v-else>
            {{ label }}
          </div> -->
          <div class="title">
            {{ label }}
          </div>
          <!-- <div style="margin-left: 80px; display: flex">
            <div class="level-item has-text-centered" style="margin-left: 40px">
              <div v-if="grossFoodCost">
                <p class="heading">Kitchen Food Cost</p>
                <p class="title is-5 has-text-success">{{ grossFoodCost }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered" style="margin-left: 40px">
              <div v-if="netFoodCost">
                <p class="heading">Net Food Cost</p>
                <p class="title is-5 has-text-success">{{ netFoodCost }}</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="column is-narrow">
        <b-dropdown hoverable aria-role="list" position="is-bottom-left">
          <button class="button is-info" slot="trigger">
            <span>Actions</span>
            <b-icon icon="menu-down"></b-icon>
          </button>
          <!-- <b-dropdown-item
            v-if="isItemMenuItemRecipeEditor()"
            aria-role="listitem"
            @click="$router.push(`/menu-items/${$route.params.menuItemId}/edit`)"
          >
            <div class="media">
              <b-icon class="media-left" icon="pencil"></b-icon>
              <div class="media-content">
                <h3>Edit</h3>
              </div>
            </div>
          </b-dropdown-item> -->
          <b-dropdown-item aria-role="listitem" @click="$emit('refreshPrice')">
            <div class="media">
              <b-icon class="media-left" icon="refresh"></b-icon>
              <div class="media-content">
                <h3>Refresh Prices</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="isItemManagerAdmin()" aria-role="listitem">
            <export-excel class="btn btn-default" :data="formattedRecipe" type="csv" :name="filename">
              <div class="media">
                <b-icon class="media-left" icon="download"></b-icon>
                <div class="media-content">
                  <h3>Download Recipe</h3>
                </div>
              </div>
            </export-excel>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div style="display: flex; padding-bottom: 12px">
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Supplier Price</p>
              <p class="title is-5" v-if="isSupplierPrice">{{ supplierPrice }}</p>
              <span v-if="!isTimeout && !isSupplierPrice" class="loader"></span>
              <b-tooltip label="Pricing error" size="is-small" type="is-light" v-if="isTimeout && !isSupplierPrice">
                <b-icon icon="alert" size="is-small"></b-icon>
              </b-tooltip>
            </div>
          </div>
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Logistics Costs</p>
              <p class="title is-5" v-if="areLogisticsCosts">{{ logisticsCosts }}</p>
              <span v-if="!isTimeout && !areLogisticsCosts" class="loader"></span>
              <b-tooltip label="Pricing error" size="is-small" type="is-light" v-if="isTimeout && !areLogisticsCosts">
                <b-icon icon="alert" size="is-small"></b-icon>
              </b-tooltip>
            </div>
          </div>
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Kitchen Price</p>
              <p class="title is-5" v-if="isKitchenPrice">{{ kitchenPrice }}</p>
              <span v-if="!isTimeout && !isKitchenPrice" class="loader"></span>
              <b-tooltip label="Pricing error" size="is-small" type="is-light" v-if="isTimeout && !isKitchenPrice">
                <b-icon icon="alert" size="is-small"></b-icon>
              </b-tooltip>
            </div>
          </div>
          <!-- <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Selling price</p>
              <p class="title is-5">{{ sellingPrice }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered" style="margin-left: 40px">
            <div>
              <p class="heading">Selling price (Without VAT)</p>
              <p class="title is-5">{{ sellingPriceWithoutVAT }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { displayFormatter } from '@/mixins';
import { auth } from '@/mixins';

export default {
  mixins: [auth, displayFormatter],
  props: [
    'areLogisticsCosts',
    'grossFoodCost',
    'isKitchenPrice',
    'isSupplierPrice',
    'kitchenPrice',
    'label',
    'logisticsCosts',
    'netFoodCost',
    'recipe',
    'sellingPrice',
    'sellingPriceWithoutVAT',
    'supplierPrice',
    'itemCosts',
    'products',
  ],
  data: () => ({
    isTimeout: false,
    hover: false,
  }),
  computed: {
    ...mapState({
      currentLocation: (state) => state.locations.current,
    }),
    filename() {
      return `${this.label}.csv`;
    },
    formattedRecipe() {
      var recipes = [];
      var subRecipes = [];
      if (this.recipe && this.itemCosts && Object.keys(this.itemCosts).length > 0 && this.products.length > 0) {
        for (let recipeItem of this.recipe) {
          if (recipeItem.item.product_uuid) {
            recipes.push(this.getProductRecipe(this.label, recipeItem));
          } else if (recipeItem.item.recipe) {
            var recipeItemSupplierPrice = null;
            if (this.itemCosts[recipeItem.item.uuid]) {
              recipeItemSupplierPrice = this.itemCosts[recipeItem.item.uuid].price;
            }
            var recipeItemKitchenPrice = null;
            if (this.itemCosts[recipeItem.item.uuid]) {
              recipeItemKitchenPrice = this.itemCosts[recipeItem.item.uuid].kitchen_price;
            }
            var recipeItemLogisticsCosts = null;
            if (this.itemCosts[recipeItem.item.uuid]) {
              recipeItemLogisticsCosts = this.itemCosts[recipeItem.item.uuid].logistics_costs;
            }
            recipes.push({
              'Recipe Name': this.label,
              Ingredient: recipeItem.item.label,
              Product: '',
              Quantity: recipeItem.quantity,
              Unit: recipeItem.unit,
              'Yield Ratio': '',
              'Supplier Price': recipeItemSupplierPrice,
              'Logistics Costs': recipeItemLogisticsCosts,
              'Kitchen Price': recipeItemKitchenPrice,
            });
            subRecipes = subRecipes.concat(this.getItemRecipes(recipeItem.item.label, recipeItem, false));
          }
        }
      }
      return recipes.concat(subRecipes);
    },
  },
  methods: {
    getProductRecipe(recipeLabel, recipeItem) {
      var product = this.getProduct(recipeItem.item.product_uuid);
      var recipeItemSupplierPrice = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemSupplierPrice = this.itemCosts[recipeItem.item.uuid].price;
      }
      var recipeItemKitchenPrice = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemKitchenPrice = this.itemCosts[recipeItem.item.uuid].kitchen_price;
      }
      var recipeItemLogisticsCosts = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemLogisticsCosts = this.itemCosts[recipeItem.item.uuid].logistics_costs;
      }
      return {
        'Recipe Name': recipeLabel,
        Ingredient: product.label,
        Product: 'x',
        Quantity: recipeItem.quantity,
        Unit: recipeItem.unit,
        'Yield Ratio': product.yield_ratio * 100,
        'Supplier Price': recipeItemSupplierPrice,
        'Logistics Costs': recipeItemLogisticsCosts,
        'Kitchen Price': recipeItemKitchenPrice,
      };
    },
    getItemRecipes(recipeLabel, recipeItem, addItself) {
      var recipes = [];
      var recipeItemSupplierPrice = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemSupplierPrice = this.itemCosts[recipeItem.item.uuid].price;
      }
      var recipeItemKitchenPrice = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemKitchenPrice = this.itemCosts[recipeItem.item.uuid].kitchen_price;
      }
      var recipeItemLogisticsCosts = null;
      if (this.itemCosts[recipeItem.item.uuid]) {
        recipeItemLogisticsCosts = this.itemCosts[recipeItem.item.uuid].logistics_costs;
      }
      if (addItself) {
        recipes.push({
          'Recipe Name': recipeLabel,
          Ingredient: recipeItem.item.label,
          Product: '',
          Quantity: recipeItem.quantity,
          Unit: recipeItem.unit,
          'Yield Ratio': '',
          'Supplier Price': recipeItemSupplierPrice,
          'Logistics Costs': recipeItemLogisticsCosts,
          'Kitchen Price': recipeItemKitchenPrice,
        });
      }
      if (recipeItem.item.product_uuid) {
        return [this.getProductRecipe(recipeLabel, recipeItem)];
      }
      if (recipeItem.item.recipe.length > 0) {
        for (let subRecipeItem of recipeItem.item.recipe) {
          recipes = recipes.concat(this.getItemRecipes(recipeItem.item.label, subRecipeItem, true));
        }
      }
      return recipes;
    },
    getProduct(productUUID) {
      return this.products.find((product) => product.uuid == productUUID);
    },
  },
  mounted() {
    setTimeout(() => (this.isTimeout = true), 15000);
  },
};
</script>
