<template>
  <div>
    <div class="is-size-7 has-text-weight-bold section-title">PRODUCT</div>
    <div v-if="product">
      <div v-if="hasAccessToProduct()">
        <a @click="goToProducts(product.uuid)"
          >{{ product.label }} <b-tag rounded type="is-small">{{ product.supplier.label }}</b-tag></a
        >
        <a class="is-size-7" v-if="!isDisabled && isItemRecipeProductEditor()" @click="isProductEditable = true"
          >Edit
        </a>
      </div>
      <div v-else>
        {{ product.label }} <b-tag rounded>{{ product.supplier.label }}</b-tag>
      </div>
      <br />
    </div>
    <b-field v-if="isProductEditable || !product">
      <b-autocomplete
        expanded
        v-model="searchTerm"
        :data="filteredData"
        placeholder="Search for an existing product..."
        icon="magnify"
        field="label"
        @select="setSelected($event)"
        size="is-small"
      >
        <template slot="empty">No results found</template>
        <template slot-scope="props">
          <div>
            {{ props.option.label }}<b-tag rounded>{{ props.option.supplier.label }}</b-tag>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: ['product', 'products', 'isDisabled'],
  data: () => ({
    searchTerm: '',
    selected: null,
    isProductEditable: false,
  }),
  methods: {
    setSelected(option) {
      if (option && option.uuid && option.label) {
        this.selected = null;
        this.searchTerm = '';
        this.isProductEditable = false;
        this.$emit('set', option.label, option.uuid);
      }
    },
    set(label, productUuid) {
      this.searchTerm = '';
      this.selected = null;
      this.isProductEditable = false;

      this.$emit('set', label, productUuid);
    },
    unset(productUuid) {
      this.$emit('unset', productUuid);
    },
    goToProducts(productUuid) {
      this.$emit('close');
      this.$router.push('/products/' + productUuid);
    },
  },
  computed: {
    filteredData() {
      return this.products.filter((option) => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid #8080802e;
}
</style>
