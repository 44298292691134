import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_COSTS_ENDPOINT,
});

export default {
  compute(opts) {
    return axios.post('/compute_costs', {
      location_uuids: opts.locationUuids,
      quantity: opts.quantity,
      save: opts.save,
      type: opts.type,
      unit: opts.unit,
      uuid: opts.uuid,
    });
  },
};
