<template>
  <div class="search-wrapper" :class="{ 'search-wrapper--active': showDropdown }">
    <b-field ref="searchWrapper" label="Add substitutes product">
      <b-input
        ref="searchInput"
        type="search"
        icon-right="magnify"
        placeholder="Search Products"
        v-model="searchProductSubstitutesModel"
        :disabled="disabled"
        :loading="searchLoading || searchMoreLoading"
        @focus="toggleDropdown"
      >
      </b-input>
    </b-field>
    <Portal>
      <div
        v-show="showDropdown"
        ref="dropdownContent"
        class="product-substitutes-popup"
        :class="{ 'product-substitutes-popup--loading': searchLoading }"
      >
        <b-loading v-if="searchLoading" :is-full-page="false" :active="true"></b-loading>
        <template v-else>
          <ul class="product-substitutes-options" v-if="productSubstituteOptions.length">
            <li
              class="product-substitutes-option-wrapper"
              v-for="{ label, reference, uuid, provider, base_unit: substituteBaseUnit } in productSubstituteOptions"
              :key="uuid"
            >
              <div class="product-substitutes-option">
                <div>{{ label }}</div>
                <div>{{ provider.label }}</div>
                <div>{{ reference }}</div>
                <b-button
                  :disabled="substituteBaseUnit !== mainBaseUnit"
                  size="is-small"
                  type="is-info"
                  @click="addSubstitute({ label, uuid, reference })"
                  >Add</b-button
                >
              </div>
              <b-message size="is-small" v-if="substituteBaseUnit !== mainBaseUnit" type="is-danger">
                We cannot associate this product because the base unit "{{ substituteBaseUnit }}" is different from the
                main product "{{ mainBaseUnit }}"
              </b-message>
            </li>
          </ul>
          <div v-else>no product found</div>
        </template>
      </div>
    </Portal>
  </div>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';
import { computePosition, shift, getScrollParents, hide } from '@floating-ui/dom';
import { debounce } from 'lodash';

export default {
  name: 'SearchProductSubstitutes',
  components: {
    Portal,
  },
  model: {
    prop: 'searchProductSubstitutes',
    event: 'update:searchProductSubstitutes',
  },
  props: {
    searchLoading: {
      type: Boolean,
      required: true,
    },
    searchMoreLoading: {
      type: Boolean,
      default: false,
    },
    searchProductSubstitutes: {
      type: String,
      required: true,
    },
    productSubstituteOptions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mainBaseUnit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      scrollParents: null,
    };
  },
  computed: {
    searchProductSubstitutesModel: {
      get() {
        return this.searchProductSubstitutes;
      },
      set: debounce(function (newSearchValue) {
        this.$emit('update:searchProductSubstitutes', newSearchValue);
      }, 300),
    },
  },
  methods: {
    addSubstitute(product) {
      this.$emit('add:substitute', product);
    },
    toggleDropdown() {
      this.updatePosition();
      this.showDropdown = true;
    },
    onClickOutside(event) {
      if (event.target === this.$refs.searchWrapper.$el || event.target === this.$refs.dropdownContent) {
        return;
      }

      let children = this.$refs.searchWrapper.$el.querySelectorAll('.control > *');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      children = this.$refs.dropdownContent.querySelectorAll('*');
      for (const child of children) {
        if (event.target === child) {
          return;
        }
      }

      this.showDropdown = false;
    },
    onDropdownContentScroll(e) {
      if (this.searchMoreLoading && this.productSubstituteOptions.length) {
        return;
      }
      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        this.$emit('nextPage');
      }
    },
    updatePosition() {
      const dropdownContent = this.$refs.dropdownContent;
      const searchWrapper = this.$refs.searchInput.$el;

      if (!dropdownContent || !searchWrapper) {
        return;
      }

      dropdownContent.style.width = `${searchWrapper.offsetWidth}px`;
      computePosition(searchWrapper, dropdownContent, {
        placement: 'bottom-start',
        middleware: [shift(), hide()],
      }).then(
        ({
          x,
          y,
          middlewareData: {
            hide: { referenceHidden },
          },
        }) => {
          Object.assign(dropdownContent.style, {
            left: `${x}px`,
            top: `${y}px`,
            visibility: referenceHidden ? 'hidden' : 'visible',
          });
        }
      );
    },
    setupListeners() {
      this.$refs.dropdownContent.addEventListener('scroll', this.onDropdownContentScroll);
      document.addEventListener('click', this.onClickOutside);

      this.scrollParents = [
        ...getScrollParents(this.$refs.searchWrapper.$el),
        ...getScrollParents(this.$refs.dropdownContent),
      ];

      for (const el of this.scrollParents) {
        el.addEventListener('scroll', this.updatePosition);
        el.addEventListener('resize', this.updatePosition);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updatePosition();
      this.setupListeners();
    });
  },
  beforeDestroy() {
    this.$refs.dropdownContent.removeEventListener('scroll', this.onDropdownContentScroll);
    document.removeEventListener('click', this.onClickOutside);

    for (const el of this.scrollParents) {
      el.removeEventListener('scroll', this.updatePosition);
      el.removeEventListener('resize', this.updatePosition);
    }
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  &--active {
    ::v-deep .input[type='search'] {
      border-bottom-width: 0px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #949494;
      }
      box-shadow: none;
      border-color: 949494;
    }
  }
}
.product-substitutes-popup {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #949494;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  color: black;
  padding: 0 14px;
  padding-bottom: 14px;
  font-size: 90%;
  z-index: 99;
  max-height: 200px;
  overflow: auto;
  border-top: 0;

  &--loading {
    height: 100px;
  }
}
.product-substitutes-options {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.product-substitutes-option-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 0;
  &:not(:last-child) {
    border-bottom: 0.5px solid #949494;
  }
}

.product-substitutes-option {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 50px;
  align-items: center;
}
</style>
