import productsAPI from '@/apis/products/products';
import suppliersAPI from '@/apis/products/suppliers';
import summaryAPI from '@/apis/items/summary';
import gatewaySupply from '@/apis/gatewaySupply/gatewaySupply.js';
import { productsTypes } from '../mutation-types.js';

export default {
  namespaced: true,
  state: {
    all: [],
    summary: [],
    current: null,
    currentUsage: null,
    productPrices: null,
    searchTerm: '',
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchAllSummary: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    removeOne: {
      pending: false,
      error: null,
    },
    fetchUsage: {
      pending: false,
      error: null,
    },
    createProductLocalizedInfo: {
      pending: false,
      error: null,
    },
    updateProductLocalizedInfo: {
      pending: false,
      error: null,
    },
    removeProductLocalizedInfo: {
      pending: false,
      error: null,
    },
    computeProductPrice: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [productsTypes.FETCH_ALL_SUCCESS](state, products) {
      state.all = products;
      state.fetchAll.pending = false;
    },
    [productsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [productsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [productsTypes.FETCH_ALL_SUMMARY_SUCCESS](state, products) {
      state.summary = products;
      state.fetchAllSummary.pending = false;
    },
    [productsTypes.FETCH_ALL_SUMMARY_FAILURE](state, error) {
      state.summary = null;
      state.fetchAllSummary.pending = false;
      state.fetchAllSummary.error = error;
    },
    [productsTypes.FETCH_ALL_SUMMARY_REQUEST](state) {
      state.fetchAllSummary.pending = true;
    },
    [productsTypes.FETCH_ONE_SUCCESS](state, product) {
      state.current = product;
      state.fetchOne.pending = false;
    },
    [productsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [productsTypes.FETCH_ONE_REQUEST](state) {
      state.current = null;
      state.fetchOne.error = null;
      state.fetchOne.pending = true;
    },
    [productsTypes.FETCH_USAGE_REQUEST](state) {
      state.currentUsage = null;
      state.fetchUsage.pending = true;
      state.fetchUsage.error = null;
    },
    [productsTypes.FETCH_USAGE_SUCCESS](state, usage) {
      state.fetchUsage.pending = false;
      state.currentUsage = usage;
    },
    [productsTypes.FETCH_USAGE_FAILURE](state, error) {
      state.fetchUsage.pending = false;
      state.fetchUsage.error = error;
    },
    [productsTypes.UPDATE_ONE_SUCCESS](state, response) {
      // TODO: Add categories state in products state
      // TODO: Update categories when updating a product
      var rootState = response.rootState;
      var product = response.product;
      const index = state.all.findIndex(({ uuid }) => uuid === product.uuid);
      state.all.splice(index, 1, product);
      if (rootState.suppliers.current) {
        const indexSupplier = rootState.suppliers.current.products.findIndex(
          (_product) => _product.uuid === product.uuid
        );
        rootState.suppliers.current.products.splice(indexSupplier, 1, product);
      }
      state.current = product;
      state.updateOne.pending = false;
    },
    [productsTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [productsTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
      state.updateOne.error = null;
    },
    [productsTypes.REMOVE_ONE](state, response) {
      var rootState = response.rootState;
      var productUuid = response.productUuid;
      const index = state.all.findIndex((product) => product.uuid === productUuid);
      if (rootState.suppliers.current) {
        const indexSupplier = rootState.suppliers.current.products.findIndex(
          (_product) => _product.uuid === productUuid
        );
        rootState.suppliers.current.products.splice(indexSupplier, 1);
      }
      state.all.splice(index, 1);
      state.removeOne.pending = false;
    },
    [productsTypes.SET_CURRENT](state, product) {
      state.current = { ...product };
    },
    [productsTypes.SET_SEARCH_TERM](state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    [productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_SUCCESS](state, response) {
      const indexResponse = response.product.localized_informations.findIndex(
        (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
      );
      if (indexResponse > -1) {
        state.current.localized_informations.push(response.product.localized_informations[indexResponse]);
      }
      const indexAllProducts = state.all.findIndex((product) => product.uuid == response.product.uuid);
      if (indexAllProducts > -1) {
        state.all[indexAllProducts].localized_informations.push(response.product.localized_informations[indexResponse]);
      }
      const indexSummaryProducts = state.summary.findIndex((product) => product.uuid == response.product.uuid);
      if (indexSummaryProducts > -1) {
        state.summary[indexSummaryProducts].localized_informations.push(
          response.product.localized_informations[indexResponse]
        );
      }
      state.createProductLocalizedInfo.pending = false;
      state.createProductLocalizedInfo.error = null;
    },
    [productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_FAILURE](state, error) {
      state.createProductLocalizedInfo.pending = false;
      state.createProductLocalizedInfo.error = error;
    },
    [productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_REQUEST](state) {
      state.createProductLocalizedInfo.pending = true;
      state.createProductLocalizedInfo.error = null;
    },
    [productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_SUCCESS](state, response) {
      const indexResponse = response.product.localized_informations.findIndex(
        (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
      );
      const indexState = state.current.localized_informations.findIndex(
        (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
      );
      if (indexResponse > -1 && indexState > -1) {
        state.current.localized_informations.splice(
          indexState,
          1,
          response.product.localized_informations[indexResponse]
        );
      }
      const indexAllProducts = state.all.findIndex((product) => product.uuid == response.product.uuid);
      if (indexAllProducts > -1) {
        const indexStateAll = state.all[indexAllProducts].localized_informations.findIndex(
          (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
        );
        if (indexStateAll > -1) {
          state.all[indexAllProducts].localized_informations.splice(
            indexStateAll,
            1,
            response.product.localized_informations[indexResponse]
          );
        }
      }
      const indexSummaryProducts = state.summary.findIndex((product) => product.uuid == response.product.uuid);
      if (indexSummaryProducts > -1) {
        const indexStateSumm = state.summary[indexSummaryProducts].localized_informations.findIndex(
          (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
        );
        if (indexStateSumm > -1) {
          state.summary[indexSummaryProducts].localized_informations.splice(
            indexStateSumm,
            1,
            response.product.localized_informations[indexResponse]
          );
        }
      }
      state.updateProductLocalizedInfo.pending = false;
      state.updateProductLocalizedInfo.error = null;
    },
    [productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_FAILURE](state, error) {
      state.updateProductLocalizedInfo.pending = false;
      state.updateProductLocalizedInfo.error = error;
    },
    [productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_REQUEST](state) {
      state.updateProductLocalizedInfo.pending = true;
      state.updateProductLocalizedInfo.error = null;
    },
    [productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_SUCCESS](state, response) {
      const indexState = state.current.localized_informations.findIndex(
        (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
      );
      if (indexState > -1) {
        state.current.localized_informations.splice(indexState, 1);
      }
      const indexAllProducts = state.all.findIndex((product) => product.uuid == response.product.uuid);
      if (indexAllProducts > -1) {
        const indexStateAll = state.all[indexAllProducts].localized_informations.findIndex(
          (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
        );
        if (indexStateAll > -1) {
          state.all[indexAllProducts].localized_informations.splice(indexStateAll, 1);
        }
      }
      const indexSummaryProducts = state.summary.findIndex((product) => product.uuid == response.product.uuid);
      if (indexSummaryProducts > -1) {
        const indexStateSumm = state.summary[indexSummaryProducts].localized_informations.findIndex(
          (localizedInfo) => localizedInfo.location_uuid === response.locationUUID
        );
        if (indexStateSumm > -1) {
          state.summary[indexSummaryProducts].localized_informations.splice(indexStateSumm, 1);
        }
      }
      state.removeProductLocalizedInfo.pending = false;
      state.removeProductLocalizedInfo.error = null;
    },
    [productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_FAILURE](state, error) {
      state.removeProductLocalizedInfo.pending = false;
      state.removeProductLocalizedInfo.error = error;
    },
    [productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_REQUEST](state) {
      state.removeProductLocalizedInfo.pending = true;
      state.removeProductLocalizedInfo.error = null;
    },
    [productsTypes.COMPUTE_PRICE_PRODUCT_REQUEST](state) {
      state.productPrices = null;
      state.computeProductPrice.pending = true;
      state.computeProductPrice.error = null;
    },
    [productsTypes.COMPUTE_PRICE_PRODUCT_SUCCESS](state, prices) {
      state.productPrices = prices;
      state.computeProductPrice.pending = false;
    },
    [productsTypes.COMPUTE_PRICE_PRODUCT_FAILURE](state, error) {
      state.productPrices = null;
      state.computeProductPrice.pending = false;
      state.computeProductPrice.error = error;
    },
  },
  actions: {
    async fetchAll({ commit }, opts) {
      commit(productsTypes.FETCH_ALL_REQUEST);

      return productsAPI
        .fetchAll(opts)
        .then((products) => commit(productsTypes.FETCH_ALL_SUCCESS, products))
        .catch((error) => commit(productsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchAllSummary({ commit }, opts) {
      commit(productsTypes.FETCH_ALL_SUMMARY_REQUEST);

      return productsAPI
        .fetchAllSummary(opts)
        .then((products) => commit(productsTypes.FETCH_ALL_SUMMARY_SUCCESS, products))
        .catch((error) => commit(productsTypes.FETCH_ALL_SUMMARY_FAILURE, error));
    },
    async fetchOne({ commit }, productUuid) {
      commit(productsTypes.FETCH_ONE_REQUEST);

      return productsAPI
        .fetchOne(productUuid)
        .then((product) => commit(productsTypes.FETCH_ONE_SUCCESS, product))
        .catch((error) => commit(productsTypes.FETCH_ONE_FAILURE, error));
    },
    async fetchUsage({ commit, state }) {
      commit(productsTypes.FETCH_USAGE_REQUEST);

      return summaryAPI
        .fetchProductUsage(state.current.uuid)
        .then((usage) => commit(productsTypes.FETCH_USAGE_SUCCESS, usage))
        .catch((error) => commit(productsTypes.FETCH_USAGE_FAILURE, error));
    },
    async updateOne({ commit, state, rootState }) {
      commit(productsTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.modification_date;
      delete data.creation_date;
      delete data.supplier;
      delete data.category;
      delete data.hubs;
      delete data.localized_informations;
      delete data.new_localized_information;

      return suppliersAPI
        .updateProduct(state.current.supplier.uuid, state.current.uuid, data)
        .then((product) => commit(productsTypes.UPDATE_ONE_SUCCESS, { rootState: rootState, product: product }))
        .catch((error) => commit(productsTypes.UPDATE_ONE_FAILURE, error));
    },
    async removeOne({ commit, state, rootState }, { supplierUuid, productUuid }) {
      state.removeOne.pending = true;
      return suppliersAPI
        .removeProduct(supplierUuid, productUuid)
        .then(() => commit(productsTypes.REMOVE_ONE, { rootState: rootState, productUuid: productUuid }))
        .catch((error) => console.error(error));
    },
    async createLocalizedInformation({ commit }, opts) {
      commit(productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_REQUEST);
      var productUUID = opts.productUUID;
      var supplierUUID = opts.supplierUUID;
      delete opts.supplierUUID;
      delete opts.productUUID;

      return suppliersAPI
        .createProductLocalizedInformation(supplierUUID, productUUID, opts)
        .then((product) =>
          commit(productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_SUCCESS, {
            locationUUID: opts.location_uuid,
            product: product,
          })
        )
        .catch((error) => commit(productsTypes.CREATE_PRODUCT_LOCALIZED_INFO_FAILURE, error));
    },
    async updateLocalizedInformation({ commit }, opts) {
      commit(productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_REQUEST);
      var locationUUID = opts.location_uuid;
      var productUUID = opts.productUUID;
      var supplierUUID = opts.supplierUUID;
      delete opts.location_uuid;
      delete opts.productUUID;
      delete opts.supplierUUID;
      if (opts.vat_rate == null) delete opts.vat_rate; // FIXME: Remove when product API fixed
      suppliersAPI
        .updateProductLocalizedInformation(supplierUUID, productUUID, locationUUID, opts)
        .then((product) =>
          commit(productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_SUCCESS, {
            locationUUID: locationUUID,
            product: product,
          })
        )
        .catch((error) => commit(productsTypes.UPDATE_PRODUCT_LOCALIZED_INFO_FAILURE, error));
    },
    async removeLocalizedInformation({ commit }, opts) {
      commit(productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_REQUEST);
      suppliersAPI
        .removeProductLocalizedInformation(opts.supplierUuid, opts.productUuid, opts.locationUuid)
        .then((product) =>
          commit(productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_SUCCESS, {
            locationUUID: opts.locationUuid,
            product: product,
          })
        )
        .catch((error) => commit(productsTypes.REMOVE_PRODUCT_LOCALIZED_INFO_FAILURE, error));
    },
    async computeProductPrice({ commit }, opts) {
      commit(productsTypes.COMPUTE_PRICE_PRODUCT_REQUEST);
      var productUUID = opts.uuid;
      delete opts.uuid;
      return productsAPI
        .getProductCosts(productUUID, opts)
        .then((prices) => commit(productsTypes.COMPUTE_PRICE_PRODUCT_SUCCESS, prices))
        .catch((error) => commit(productsTypes.COMPUTE_PRICE_PRODUCT_FAILURE, error));
    },
    searchOutOfStocks(_context, request) {
      return gatewaySupply.searchOutOfStocks(request);
    },
    enableOutOfStock(_context, request) {
      return gatewaySupply.enableOutOfStock(request);
    },
    disableOutOfStock(_context, request) {
      return gatewaySupply.disableOutOfStock(request);
    },
  },
  getters: {
    getLocalizedInformations: (_state, _getters, rootState) => {
      return {
        catalogue_unit: null,
        currency: rootState.locations.current.location_country.currency_code,
        gross_weight_per_packaging_unit: null,
        kitchen_price: null,
        label: null,
        location_id: rootState.locations.current.id,
        location_uuid: rootState.locations.current.uuid,
        logistics_costs: null,
        packaging_unit: null,
        batch_size: null,
        price: 0,
        orderable: null,
        supplier_price: null,
      };
    },
    getLocalizedPrice: () => {
      return {
        label: '',
        price: null,
        type: '',
      };
    },
  },
};
