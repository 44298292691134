import { substitutesTypes } from '@/store/mutation-types.js';
import gatewaySupply from '@/apis/gatewaySupply/gatewaySupply.js';

export default {
  namespaced: true,
  state: {
    fetchSubstituteRules: {
      pending: false,
      error: null,
    },
    substituteRules: null,
    fetchProductSubstitutes: {
      pending: false,
      error: null,
    },
    productSubstitutes: null,
    updateSubstituteRules: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [substitutesTypes.FETCH_SUBSTITUTE_RULES_REQUEST](state) {
      state.fetchSubstituteRules.pending = true;
    },
    [substitutesTypes.FETCH_SUBSTITUTE_RULES_SUCCESS](state, substituteRules) {
      state.fetchSubstituteRules.pending = false;
      state.substituteRules = substituteRules;
    },
    [substitutesTypes.FETCH_SUBSTITUTE_RULES_ERROR](state, err) {
      state.fetchSubstituteRules.pending = false;
      state.fetchSubstituteRules.error = err;
    },
    [substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_REQUEST](state) {
      state.fetchProductSubstitutes.pending = true;
    },
    [substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_SUCCESS](state, productSubstitutes) {
      state.fetchProductSubstitutes.pending = false;
      state.productSubstitutes = productSubstitutes;
    },
    [substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_ERROR](state, err) {
      state.fetchProductSubstitutes.pending = false;
      state.fetchProductSubstitutes.error = err;
    },
    [substitutesTypes.UPDATE_SUBSTITUTE_RULE_REQUEST](state) {
      state.updateSubstituteRules.pending = true;
    },
    [substitutesTypes.UPDATE_SUBSTITUTE_RULE_SUCCESS](state) {
      state.updateSubstituteRules.pending = false;
    },
    [substitutesTypes.UPDATE_SUBSTITUTE_RULE_ERROR](state, err) {
      state.updateSubstituteRules.pending = false;
      state.updateSubstituteRules.error = err;
    },
  },
  actions: {
    async getSubstituteRules({ commit }, queryParams) {
      try {
        commit(substitutesTypes.FETCH_SUBSTITUTE_RULES_REQUEST);
        const { locationUuid } = queryParams;

        const { items } = await gatewaySupply.fetchSubstituteRules(locationUuid, queryParams);

        commit(substitutesTypes.FETCH_SUBSTITUTE_RULES_SUCCESS, items);
        return items;
      } catch (err) {
        commit(substitutesTypes.FETCH_SUBSTITUTE_RULES_ERROR, err);
        throw err;
      }
    },
    updateSubstituteRules({ commit }, body) {
      commit(substitutesTypes.UPDATE_SUBSTITUTE_RULE_REQUEST);
      return gatewaySupply
        .updateSubstituteRules(body)
        .then(() => {
          commit(substitutesTypes.UPDATE_SUBSTITUTE_RULE_SUCCESS);
        })
        .catch((err) => {
          commit(substitutesTypes.UPDATE_SUBSTITUTE_RULE_ERROR, err);
          throw err;
        });
    },
    async getProductSubstitutes({ commit }, queryParams) {
      try {
        commit(substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_REQUEST);
        const { locationUuid } = queryParams;

        const response = await gatewaySupply.fetchProductSubstitutes(locationUuid, queryParams);

        commit(substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_SUCCESS, response.items);
        return response;
      } catch (err) {
        commit(substitutesTypes.FETCH_PRODUCT_SUBSTITUTES_ERROR, err);
        throw err;
      }
    },
  },
};
