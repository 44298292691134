<template>
  <div class="box modal-card">
    <div class="has-text-centered">
      <h4 class="title has-text-centered is-6">Select Concepts</h4>
      <div class="block">
        <b-checkbox
          size="is-small"
          v-model="selectedConcepts"
          v-for="concept in getActiveConcepts()"
          :native-value="concept.uuid"
          :key="concept.uuid"
        >
          {{ concept.label }}
        </b-checkbox>
      </div>
      <hr />
      <h4 class="title has-text-centered is-6">Select Locations {{ selectedLocations.length }}</h4>
      <div class="block">
        <b-checkbox size="is-small" @input="selectBatchLocations" v-if="!this.allLocationsSelected">
          Select All
        </b-checkbox>
        <b-checkbox size="is-small" @input="selectBatchLocations" v-if="this.allLocationsSelected">
          Unselect All
        </b-checkbox>
      </div>
      <div class="block">
        <b-checkbox
          size="is-small"
          v-for="location in getRelatedLocations()"
          :value="selectedLocations[location.uuid]"
          @input="updateSelectedLocation(location.uuid)"
          :key="location.uuid"
        >
          {{ location.label }}
        </b-checkbox>
      </div>
    </div>
    <hr />
    <b-button type="is-info" style="margin-bottom: 4px" @click="run()" :disabled="!addedValue" expanded>
      Run Healthchecks
    </b-button>
    <p class="is-size-7 is-italic has-text-right has-text-weight-light">
      NB : Run Healthchecks takes around 5 minutes by Location
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['currentLocation', 'locationsUnnested', 'concepts'],
  data: () => ({
    allLocationsSelected: false,
    selectedConcepts: [],
    selectedLocations: {},
    addedValue: false,
  }),
  methods: {
    ...mapActions('summary', ['refreshHealthcheckResults']),
    updateSelectedLocation(locationUUID) {
      this.$set(this.selectedLocations, locationUUID, !this.selectedLocations[locationUUID]);
      this.addedValue = true;
    },
    selectBatchLocations() {
      if (!this.allLocationsSelected) {
        for (var key of Object.keys(this.selectedLocations)) {
          this.selectedLocations[key] = true;
          this.allLocationsSelected = true;
          this.addedValue = true;
        }
      } else {
        for (var key2 of Object.keys(this.selectedLocations)) {
          this.selectedLocations[key2] = false;
          this.allLocationsSelected = false;
          this.addedValue = false;
        }
      }
    },
    getActiveConcepts() {
      var concepts = this.concepts.filter((concept) => !concept.is_deleted);
      return concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
    },
    getRelatedLocations() {
      var parentUuid = null;
      if (!this.currentLocation.parent_uuid) {
        parentUuid = this.currentLocation.uuid;
      } else {
        parentUuid = this.currentLocation.parent_uuid;
      }
      return this.locationsUnnested
        .filter((location) => location.parent_uuid == parentUuid)
        .sort((location1, location2) => location1.label.localeCompare(location2.label));
    },
    runDisabled() {
      if (Object.values(this.selectedLocations).filter((value) => value === true).length == 0) {
        return true;
      }
      return false;
    },
    run() {
      var locationUuids = [];
      for (let key of Object.keys(this.selectedLocations)) {
        if (this.selectedLocations[key]) {
          locationUuids.push(key);
        }
      }
      if (locationUuids.length > 0 && this.selectedConcepts.length > 0) {
        this.refreshHealthcheckResults({ locationUuids: locationUuids, conceptUuids: this.selectedConcepts });
      }
      this.$parent.close();
    },
  },
  computed: {
    ...mapState({}),
  },
  mounted() {
    for (let location of this.getRelatedLocations()) {
      this.selectedLocations[location.uuid] = false;
    }
  },
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
