import suppliersAPI from '@/apis/products/suppliers';
import { suppliersTypes } from '../mutation-types';
import tagsAPI from '@/apis/products/tags';

export default {
  namespaced: true,
  state: {
    all: [],
    tags: [],
    current: null,
    newProduct: null,
    searchTerm: '',
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
    updateOne: {
      pending: false,
      error: null,
    },
    createOne: {
      pending: false,
      error: null,
    },
    fetchLocalizedInformation: {
      pending: false,
      error: null,
    },
    createLocalizedInformation: {
      pending: false,
      error: null,
    },
    updateLocalizedInformation: {
      pending: false,
      error: null,
    },
    createProduct: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [suppliersTypes.FETCH_ALL_SUCCESS](state, suppliers) {
      state.all = suppliers;
      state.fetchAll.pending = false;
    },
    [suppliersTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [suppliersTypes.FETCH_ALL_REQUEST](state) {
      state.current = null;
      state.fetchAll.pending = true;
    },
    [suppliersTypes.FETCH_ONE_SUCCESS](state, supplier) {
      state.current = supplier;
      state.fetchAll.pending = false;
    },
    [suppliersTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [suppliersTypes.FETCH_ONE_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [suppliersTypes.UPDATE_ONE_SUCCESS](state, supplier) {
      const index = state.all.findIndex((_supplier) => _supplier.uuid === supplier.uuid);
      state.all.splice(index, 1, supplier);
      state.current = supplier;
      state.updateOne.pending = false;
    },
    [suppliersTypes.UPDATE_ONE_FAILURE](state, error) {
      state.updateOne.pending = false;
      state.updateOne.error = error;
    },
    [suppliersTypes.UPDATE_ONE_REQUEST](state) {
      state.updateOne.pending = true;
      state.updateOne.error = null;
    },
    [suppliersTypes.CREATE_ONE_SUCCESS](state, supplier) {
      state.current = supplier;
      state.all.push(supplier);
      state.createOne.pending = false;
      state.createOne.error = null;
    },
    [suppliersTypes.CREATE_ONE_FAILURE](state, error) {
      state.createOne.pending = false;
      state.createOne.error = error;
    },
    [suppliersTypes.CREATE_ONE_REQUEST](state) {
      state.createOne.pending = true;
    },
    [suppliersTypes.REMOVE_ONE](state, uuid) {
      const index = state.all.findIndex((supplier) => supplier.uuid === uuid);
      state.all.splice(index, 1);
    },
    [suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.fetchLocalizedInformation.pending = true;
      state.fetchLocalizedInformation.error = null;
    },
    [suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS](state, { supplierUuid, info }) {
      if (state.current.uuid === supplierUuid) {
        const localized_informations = state.current.localized_informations
          ? [...state.current.localized_informations, info]
          : [info];

        state.current = { ...state.current, localized_informations };
      }

      state.all = state.all.map((supplier) => {
        if (supplier.uuid === supplierUuid) {
          supplier.localized_informations = supplier.localized_informations
            ? [...supplier.localized_informations, info]
            : [info];
        }

        return supplier;
      });
      state.fetchLocalizedInformation.pending = false;
    },
    [suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.fetchLocalizedInformation.pending = false;
      state.fetchLocalizedInformation.error = err;
    },
    [suppliersTypes.CREATE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.createLocalizedInformation.pending = true;
      state.createLocalizedInformation.error = null;
    },
    [suppliersTypes.CREATE_LOCALIZED_INFORMATION_SUCCESS](state, supplier) {
      state.createLocalizedInformation.pending = false;

      if (state.current.uuid === supplier.uuid) {
        state.current.localized_informations = supplier.localized_informations;
      }
      state.all = state.all.map((s) => {
        if (s.uuid === supplier.uuid) {
          s.localized_informations = supplier.localized_informations;
        }

        return s;
      });
    },
    [suppliersTypes.CREATE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.createLocalizedInformation.pending = false;
      state.createLocalizedInformation.error = err;
    },
    [suppliersTypes.UPDATE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.updateLocalizedInformation.pending = true;
      state.updateLocalizedInformation.error = null;
    },
    [suppliersTypes.UPDATE_LOCALIZED_INFORMATION_SUCCESS](state, supplier) {
      state.updateLocalizedInformation.pending = false;

      if (state.current.uuid === supplier.uuid) {
        state.current.localized_informations = supplier.localized_informations;
      }
      state.all = state.all.map((s) => {
        if (s.uuid === supplier.uuid) {
          s.localized_informations = supplier.localized_informations;
        }

        return s;
      });
    },
    [suppliersTypes.UPDATE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.updateLocalizedInformation.pending = false;
      state.updateLocalizedInformation.error = err;
    },
    [suppliersTypes.REMOVE_LOCALIZED_INFORMATION_REQUEST](state) {
      state.removeLocalizedInformation.pending = true;
      state.removeLocalizedInformation.error = null;
    },
    [suppliersTypes.REMOVE_LOCALIZED_INFORMATION_SUCCESS](state, { supplierUuid, locationUuid }) {
      state.removeLocalizedInformation.pending = false;

      if (state.current.uuid === supplierUuid) {
        state.current.localized_informations = state.current.localized_informations.filter(
          (info) => info.location_uuid !== locationUuid
        );
      }
      state.all = state.all.map((s) => {
        if (s.uuid === supplierUuid) {
          s.localized_informations = s.localized_informations.filter((info) => info.location_uuid === locationUuid);
        }
        return s;
      });
    },
    [suppliersTypes.REMOVE_LOCALIZED_INFORMATION_FAILURE](state, err) {
      state.removeLocalizedInformation.pending = false;
      state.removeLocalizedInformation.error = err;
    },
    [suppliersTypes.CREATE_PRODUCT_SUCCESS](state, response) {
      var rootState = response.rootState;
      var product = response.product;
      state.current.products.push(product);
      if (rootState.products.all.length > 0) {
        rootState.products.all.push(product);
      }
      if (rootState.products.summary.length > 0) {
        rootState.products.summary.push(product);
      }
      state.newProduct = response.product;
      state.createProduct.pending = false;
      state.createProduct.error = null;
    },
    [suppliersTypes.CREATE_PRODUCT_FAILURE](state, error) {
      state.createProduct.pending = false;
      state.createProduct.error = error;
    },
    [suppliersTypes.CREATE_PRODUCT_REQUEST](state) {
      state.createProduct.pending = true;
    },
    [suppliersTypes.FETCH_TAGS](state, tags) {
      state.tags = tags;
    },
    [suppliersTypes.SET_CURRENT_SUPPLIER](state, supplier) {
      state.current = { ...supplier };
    },
    [suppliersTypes.SET_NEW_PRODUCT](state, product) {
      state.newProduct = { ...product };
    },
    [suppliersTypes.SET_SEARCH_TERM](state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(suppliersTypes.FETCH_ALL_REQUEST);

      return suppliersAPI
        .fetchAll()
        .then((suppliers) => commit(suppliersTypes.FETCH_ALL_SUCCESS, suppliers))
        .catch((error) => commit(suppliersTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchOne({ commit }, { uuid, includeLocalizedInfos = false }) {
      commit(suppliersTypes.FETCH_ONE_REQUEST);

      return suppliersAPI
        .fetchOne(uuid, { includeLocalizedInfos })
        .then((supplier) => commit(suppliersTypes.FETCH_ONE_SUCCESS, supplier))
        .catch((error) => commit(suppliersTypes.FETCH_ONE_FAILURE, error));
    },
    async updateOne({ commit, state }) {
      commit(suppliersTypes.UPDATE_ONE_REQUEST);

      const data = { ...state.current };

      delete data.uuid;
      delete data.creation_date;
      delete data.modification_date;
      delete data.products;
      delete data.localized_informations;
      delete data.mail;

      if (!Array.isArray(data.mails) || data.mails.length === 0) {
        data.mails = null;
      }

      return suppliersAPI
        .updateOne(state.current.uuid, data)
        .then((supplier) => commit(suppliersTypes.UPDATE_ONE_SUCCESS, supplier))
        .catch((error) => commit(suppliersTypes.UPDATE_ONE_FAILURE, error));
    },
    async createOne({ commit }, supplier) {
      commit(suppliersTypes.CREATE_ONE_REQUEST);

      const data = { ...supplier };
      delete data.localized_informations;

      return suppliersAPI
        .createOne(data)
        .then((supplier) => commit(suppliersTypes.CREATE_ONE_SUCCESS, supplier))
        .catch((error) => commit(suppliersTypes.CREATE_ONE_FAILURE, error));
    },
    async removeOne({ commit }, uuid) {
      return suppliersAPI
        .removeOne(uuid)
        .then(() => commit(suppliersTypes.REMOVE_ONE, uuid))
        .catch((error) => console.error(error));
    },
    async fetchTags({ commit }) {
      return tagsAPI
        .fetchAll()
        .then((tags) => commit(suppliersTypes.FETCH_TAGS, tags))
        .catch((error) => console.error(error));
    },
    fetchLocalizedInformation({ commit }, { supplierUuid, locationUuid }) {
      commit(suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_REQUEST);
      return suppliersAPI
        .fetchOneLocalizedInformation(supplierUuid, locationUuid)
        .then((info) => {
          commit(suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_SUCCESS, { supplierUuid, info });
          return info;
        })
        .catch((err) => commit(suppliersTypes.FETCH_ONE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    addLocalizedInformation({ commit }, { supplierUuid, payload }) {
      commit(suppliersTypes.CREATE_LOCALIZED_INFORMATION_REQUEST);

      return suppliersAPI
        .addLocalizedInformation(supplierUuid, payload)
        .then((supplier) => commit(suppliersTypes.CREATE_LOCALIZED_INFORMATION_SUCCESS, supplier))
        .catch((err) => commit(suppliersTypes.CREATE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    updateLocalizedInformation({ commit }, { supplierUuid, locationUuid, payload }) {
      commit(suppliersTypes.UPDATE_LOCALIZED_INFORMATION_REQUEST);

      return suppliersAPI
        .updateLocalizedInformation(supplierUuid, locationUuid, payload)
        .then((supplier) => commit(suppliersTypes.UPDATE_LOCALIZED_INFORMATION_SUCCESS, supplier))
        .catch((err) => commit(suppliersTypes.UPDATE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    removeLocalizedInformation({ commit }, { supplierUuid, locationUuid }) {
      commit(suppliersTypes.REMOVE_LOCALIZED_INFORMATION_REQUEST);

      return suppliersAPI
        .removeLocalizedInformation(supplierUuid, locationUuid)
        .then(() =>
          commit(suppliersTypes.REMOVE_LOCALIZED_INFORMATION_SUCCESS, {
            supplierUuid,
            locationUuid,
          })
        )
        .catch((err) => commit(suppliersTypes.REMOVE_LOCALIZED_INFORMATION_FAILURE, err));
    },
    async createProduct({ commit, state, rootState, dispatch }) {
      commit(suppliersTypes.CREATE_PRODUCT_REQUEST);
      var product = state.newProduct;
      var localizedInformation = product.new_localized_information;
      delete product.new_localized_information;
      delete localizedInformation.location_id;
      return suppliersAPI
        .createProduct(state.current.uuid, product)
        .then((product) => {
          commit(suppliersTypes.CREATE_PRODUCT_SUCCESS, { rootState: rootState, product: product });
          dispatch(
            'products/createLocalizedInformation',
            {
              ...localizedInformation,
              supplierUUID: product.supplier.uuid,
              productUUID: product.uuid,
            },
            { root: true }
          );
        })
        .catch((error) => commit(suppliersTypes.CREATE_PRODUCT_FAILURE, error));
    },
    buildOne({ commit }) {
      const supplier = suppliersAPI.buildOne();

      commit(suppliersTypes.SET_CURRENT_SUPPLIER, supplier);
    },
    buildProduct({ commit }) {
      const product = suppliersAPI.buildOneProduct();

      commit(suppliersTypes.SET_NEW_PRODUCT, product);
    },
  },
};
