<template>
  <div>
    <div v-for="_ingredient in orderedIngredients" :key="_ingredient.item.uuid">
      <CompositionRow
        :level="level"
        :ingredient="_ingredient"
        :cost="itemCosts[_ingredient.item.uuid]"
        :isSelected="selectedItemUuid === _ingredient.item.uuid"
        :canCompose="canCompose"
        :showRemove="showRemove"
        @expand="
          () =>
            (visibility = { ...visibility, [_ingredient.item.uuid]: visibility[_ingredient.item.uuid] ? false : true })
        "
        @selectItem="$emit('selectItem', ingredient, _ingredient.item.uuid)"
        @addItem="$emit('addItem', _ingredient.item.uuid)"
        @removeItem="$emit('removeItem', ingredient.item.uuid, _ingredient.item.uuid)"
      />
      <div v-show="visibility[_ingredient.item.uuid]">
        <CompositionItem
          :level="level + 1"
          :ingredient="_ingredient"
          v-if="_ingredient.item.recipe"
          :selectedItemUuid="selectedItemUuid"
          :itemCosts="itemCosts"
          :canCompose="canCompose"
          :showRemove="_ingredient.item.recipe && _ingredient.item.recipe.length > 1"
          @selectItem="(parentItem, itemUuid) => $emit('selectItem', parentItem, itemUuid)"
          @addItem="(itemUiid) => $emit('addItem', itemUiid)"
          @removeItem="(parentItemUuid, itemUuid) => $emit('removeItem', parentItemUuid, itemUuid)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CompositionRow from './CompositionRow.vue';

export default {
  components: { CompositionRow },
  props: ['ingredient', 'level', 'selectedItemUuid', 'itemCosts', 'canCompose', 'showRemove'],
  name: 'CompositionItem',
  data: () => ({
    visibility: {},
  }),
  computed: {
    orderedIngredients() {
      return _.orderBy(this.ingredient.item.recipe, [(ingredient) => ingredient.item.label.toLowerCase()], ['asc']);
    },
  },
  mounted() {
    this.ingredient.item.recipe.forEach((ingredient) => {
      if (ingredient.item.recipe) {
        this.$set(this.visibility, ingredient.item.uuid, false);
      }
    });
  },
};
</script>
