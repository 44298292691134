import { render, staticRenderFns } from "./RestaurantsTable.vue?vue&type=template&id=36d4849f&scoped=true&"
import script from "./RestaurantsTable.vue?vue&type=script&lang=js&"
export * from "./RestaurantsTable.vue?vue&type=script&lang=js&"
import style0 from "./RestaurantsTable.vue?vue&type=style&index=0&id=36d4849f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d4849f",
  null
  
)

export default component.exports