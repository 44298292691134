<template>
  <div v-if="status.status && status.status === 400">
    <RestaurantsCheckError message="An error occured while performing the check." />
  </div>
  <div v-else-if="status.status && status.status === 500">
    <RestaurantsCheckError
      message="You can't perform a check if there is no existing menu for the concept and/or location selected."
    />
  </div>
  <div v-else-if="status === 'Not Uploaded'">
    <RestaurantsCheckError message='No check was recorded on our part, please perform a "Check" manually.' />
  </div>
  <div v-else>
    <RestaurantsCheckError message="Nothing here." />
  </div>
</template>

<script>
import RestaurantsCheckError from '@/components/Menu/Restaurants/RestaurantsCheckError.vue';

export default {
  name: 'RestaurantsCheckComparisonErrors',
  components: {
    RestaurantsCheckError,
  },
  props: ['status'],
};
</script>
