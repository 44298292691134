import Vue from 'vue';
import Keycloak from 'keycloak-js';
import jwt from 'jwt-decode';

const realm = process.env.VUE_APP_IAM_REALM;
const authServerURL = process.env.VUE_APP_IAM_AUTH_SERVER_URL;
const clientId = process.env.VUE_APP_IAM_CLIENT_ID;

let auth = new Vue({
  data: () => ({
    keycloak: null,
    isAuthenticated: false,
    user: null,
    groups: [],
    resourceAccess: {},
  }),
  computed: {
    userGroups() {
      const regexp = /(.+?)\/groups/;

      return this.groups.filter((group) => group.match(regexp)).map((group) => group.split('/').pop());
    },
  },
  methods: {
    async signIn() {
      return new Promise((resolve, reject) => {
        this.keycloak
          .init({
            onLoad: 'login-required',
            checkLoginIframe: false,
            responseMode: 'query',
            scope: 'groups',
          })
          .then(async (auth) => {
            if (auth) {
              await this.updateUser();
              setInterval(() => {
                this.keycloak.updateToken(60).then((isRefreshed) => {
                  if (isRefreshed) {
                    this.updateUser();
                  }
                });
              }, 3000);
              resolve();
            } else {
              reject('Not able to authenticate the user.');
            }
            this.isAuthenticated = auth;
          });
      });
    },
    logout() {
      this.keycloak.logout();
    },
    getToken() {
      return this.keycloak.token;
    },
    async updateUser() {
      await new Promise((resolve, reject) => {
        this.keycloak
          .loadUserInfo()
          .then((userInfo) => {
            this.groups = userInfo.groups;
            this.user = this.keycloak.userInfo;
            this.resourceAccess = this.keycloak.resourceAccess;
            resolve();
          })
          .catch((error) => {
            this.$buefy.toast.open({ message: 'Unable to retrieve user infos', type: 'is-danger' });
            reject(error);
          });
      });
    },
    refreshToken() {
      return this.keycloak.updateToken(-1);
    },
    isExpiring(token) {
      const decodedToken = jwt(token);
      const nowTimestamp = Math.floor(Date.now() / 1000);
      const expirationTimestamp = decodedToken.exp;
      if (expirationTimestamp - nowTimestamp < 60) {
        return true;
      }
      return false;
    },
    getUserRoles() {
      var roles = this.user ? this.user.realmRoles : [];
      return roles ? roles : [];
    },
  },
  created() {
    this.keycloak = Keycloak({
      url: authServerURL,
      realm: realm,
      clientId: clientId,
    });
  },
});

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth;
  },
};
