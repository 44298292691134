<template>
  <NewConceptForm :concept-categories="conceptCategories" @newConcept="newConcept" />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import NewConceptForm from '@/components/Menu/NewConceptForm.vue';

export default {
  name: 'NewMenu',
  components: {
    NewConceptForm,
  },
  computed: {
    ...mapState({
      conceptCategories: (state) => state.concepts.categories,
      concept: (state) => state.concepts.current,
    }),
  },
  methods: {
    ...mapActions('concepts', { createConcept: 'createOne' }),
    newConcept(newConcept) {
      this.createConcept(newConcept).then(() => this.$router.push(`/platforms/${this.concept.uuid}`));
    },
  },
};
</script>
