<template>
  <section>
    <b-field>
      <b-upload v-model="file" drag-drop accept="image/jpeg" :disabled="uploadDisabled">
        <section v-if="!file && !url" class="section">
          <div class="content has-text-centered" v-if="!uploadDisabled">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Drop your file here or click to upload</p>
          </div>
        </section>
        <section v-else>
          <div class="content has-text-centered">
            <div class="btn-centered">
              <a class="button is-primary" v-if="!uploadDisabled">
                <b-icon icon="swap-vertical"></b-icon>
                <span>Replace</span>
              </a>
              <a v-if="file" @click.prevent="sendFile" class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Submit</span>
              </a>
            </div>
            <img :src="url" class="preview-img" v-if="url" />
          </div>
        </section>
      </b-upload>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'PictureUpload',
  props: {
    contentType: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: false,
    },
    uploadDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    url: function () {
      if (this.file) {
        return URL.createObjectURL(this.file);
      } else {
        return this.picture;
      }
    },
  },
  methods: {
    deleteDropFile() {
      this.file = null;
    },
    sendFile() {
      this.$emit('submitFile', this.contentType, this.file);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-img {
  width: 300px;
}

.btn-delete {
  position: absolute;
  top: 2%;
  right: 2%;
}

.btn-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  a {
    margin: 5px;
  }
}
</style>
