<template>
  <div class="columns" :class="{ 'has-errors': hasErrors() }">
    <div class="column is-two-fifths">
      <ValidationProvider
        ref="typeProvider"
        class="field"
        rules="required"
        :skip-if-empty="false"
        tag="div"
        vid="type"
        v-slot="{ errors }"
      >
        <label class="label" :class="{ 'has-text-taster-critical': errors.length > 0 }"> When </label>
        <div class="control has-icons-right is-expanded">
          <div class="select is-fullwidth is-multiple" :class="{ 'is-taster-critical': errors.length > 0 }">
            <select v-model="type">
              <option disabled hidden :value="null">Selection</option>
              <option value="UNITS">Number of articles</option>
              <option value="VOLUME">Order value</option>
              <option value="WEIGHT">Order weight</option>
            </select>
          </div>
          <div class="icon is-right">
            <i class="mdi mdi-chevron-down mdi-24px" />
          </div>
        </div>
        <p v-show="errors.length > 0" class="help has-text-taster-critical">Select a metric</p>
      </ValidationProvider>
    </div>
    <div class="column">
      <ValidationObserver class="field" tag="div" v-slot="{ errors: valueErrors }">
        <label class="label" :class="{ 'has-text-taster-critical': valueErrors.value && valueErrors.value.length > 0 }">
          Is below
          <span class="is-italic">(net value)</span>
        </label>
        <div class="field has-addons">
          <ValidationProvider
            ref="valueProvider"
            class="control is-clearfix is-expanded"
            rules="required|min_value:0"
            :skip-if-empty="false"
            tag="div"
            vid="value"
            v-slot="{ errors }"
          >
            <div
              class="input has-addons-right"
              :class="{
                'has-all-borders': type !== 'UNITS',
                'is-taster-critical': errors.length > 0,
              }"
            >
              <input v-model.number="value" type="number" />
              <p v-if="type === 'VOLUME'" class="addon is-right">
                {{ currency }}
              </p>
              <p v-else-if="type === 'WEIGHT'" class="addon is-right">kg</p>
            </div>
          </ValidationProvider>
          <template v-if="type === 'UNITS'">
            <p class="control">
              <a href="#" class="button is-static"> &times; </a>
            </p>
            <ValidationProvider
              ref="unitProvider"
              class="control"
              rules="required"
              tag="div"
              :skip-if-empty="false"
              v-slot="{ pristine }"
            >
              <input
                v-model="unit"
                type="text"
                class="input"
                :class="{ 'is-taster-critical': !unit && !pristine && type === 'UNITS' }"
                placeholder="Article unit"
              />
            </ValidationProvider>
          </template>
        </div>
        <p v-show="valueErrors.value && valueErrors.value.length > 0" class="help is-taster-critical">
          Set a positive number
        </p>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ThresholdInput',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  model: {
    event: 'threshold:input',
    prop: 'threshold',
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
    threshold: {
      type: Object,
      default: () => ({
        type: null,
        unit: null,
        value: null,
      }),
    },
  },
  computed: {
    type: {
      get() {
        return this.threshold.type;
      },
      set(type) {
        const patch = { ...this.threshold, type };
        if (type !== 'UNITS') {
          patch.unit = null;
        }

        this.$emit('threshold:input', patch);
      },
    },
    unit: {
      get() {
        return this.threshold.unit;
      },
      set(unit) {
        this.$emit('threshold:input', { ...this.threshold, unit });
      },
    },
    value: {
      get() {
        return this.threshold.value;
      },
      set(value) {
        this.$emit('threshold:input', { ...this.threshold, value });
      },
    },
  },
  methods: {
    hasErrors() {
      if (!this.$refs.typeProvider || !this.$refs.valueProvider) {
        return false;
      }

      return this.$refs.typeProvider.errors.length > 0 || this.$refs.valueProvider.errors.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';

input[type='number'] {
  appearance: textfield;
}

.select.is-taster-critical select,
.input.is-taster-critical {
  color: $taster-critical;
}

.columns {
  margin: 0px !important;

  &.has-errors {
    margin-bottom: -16px !important;
  }

  .column {
    padding-bottom: 0px;
    padding-top: 0px;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }
  }
}

.field {
  &.has-addons {
    margin-bottom: 0px;
  }

  .input {
    &.has-addons-right {
      padding-right: 2.25em;
    }

    input {
      background: transparent;
      border: none;
      font-size: 1em;
      outline: none;
      width: 100%;
    }

    .addon {
      position: absolute;
      top: 0px;

      padding: calc(0.375em - 1px);
      padding-right: calc(0.75em - 1px);
      user-select: none;
      z-index: 4;
    }

    .addon.is-right {
      color: #949494;
      right: 0px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

.has-all-borders {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.help {
  line-height: 12px;
}

.select.is-taster-critical select {
  color: $taster-critical;
}
</style>
