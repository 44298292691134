<template>
  <div>
    <div
      class="is-flex"
      style="justify-content: space-between"
      v-if="isItemMenuItemRecipeEditor() || isItemComboRecipeEditor()"
    >
      <div v-if="!isCurrentLocationInMenuItem">
        <b-button icon-left="plus-circle" class="is-info" outlined @click="createSpecificInformations()"
          >Create Specific informations for {{ currentLocation.label }}</b-button
        >
        <hr v-if="isCurrentLocationInMenuItem || isParentLocationInMenuItem" />
      </div>
    </div>
    <div class="columns" v-if="isCurrentLocationInMenuItem || isParentLocationInMenuItem">
      <div class="column">
        <h2 class="title is-5">Informations</h2>
      </div>
    </div>
    <div
      class="is-flex"
      style="justify-content: space-between"
      v-if="isItemMenuItemRecipeEditor() || isItemComboRecipeEditor()"
    >
      <div>
        <b-button
          size="is-small"
          icon-left="delete"
          outlined
          v-if="isCurrentLocationInMenuItem && !isParentLocation"
          @click="remove(value.uuid)"
        >
          Remove
        </b-button>
        <b-button
          @click="$emit('save', value.uuid)"
          size="is-small"
          class="is-info"
          outlined
          icon-left="content-save"
          v-if="isCurrentLocationInMenuItem"
          style="margin-left: 0.25rem"
          >Save</b-button
        >
      </div>
    </div>
    <div class="form" v-if="isCurrentLocationInMenuItem">
      <b-field label-position="on-border" label="Platform Label">
        <b-input type="text" v-model="value.label"></b-input>
      </b-field>
      <b-field label-position="on-border" label="Platform Description">
        <b-input type="textarea" v-model="value.description"></b-input>
      </b-field>
      <hr />
    </div>
    <div class="form" v-if="!isCurrentLocationInMenuItem && isParentLocationInMenuItem">
      <b-message type="is-warning" v-if="!isParentLocation" :closable="false">
        Label and Description are inherited from Kitchen's Country.
      </b-message>
      <b-field label-position="on-border" label="Platform Label">
        <b-input type="text" v-model="menuItemParentInformations.label" :disabled="true"></b-input>
      </b-field>
      <b-field label-position="on-border" label="Platform Description">
        <b-input type="textarea" v-model="menuItemParentInformations.description" :disabled="true"></b-input>
      </b-field>
      <hr />
    </div>
    <div v-if="isCurrentLocationInMenuItem || isParentLocationInMenuItem">
      <MenuItemInformations
        :menuItemInformations="currentMenuItemInformations"
        :platforms="getUpdatablePlatforms"
        :concepts="menuItemConcepts"
        :currentLocation="currentLocation"
        :isParentLocation="isParentLocation"
        :isCurrentLocationInMenuItem="isCurrentLocationInMenuItem"
        @addPlatformInformations="(conceptUuid) => $emit('addPlatformInformations', getUpdatablePlatforms, conceptUuid)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { auth } from '@/mixins';

import MenuItemConceptSelector from '@/components/MenuItem/Platform/Informations/MenuItemConceptSelector.vue';
import MenuItemInformations from '@/components/MenuItem/Platform/Informations/MenuItemInformations.vue';

export default {
  mixins: [auth],
  components: { MenuItemInformations },
  props: [
    'location',
    'currentMenuItemInformations',
    'currentUuid',
    'currentLocation',
    'currentMenuItem',
    'currentConcept',
  ],
  data() {
    return {
      value: { ...this.location },
    };
  },
  watch: {
    location: {
      handler(newValue, oldValue) {
        if (
          !oldValue ||
          (newValue && newValue.label !== oldValue.label) ||
          (newValue && newValue.uuid !== oldValue.uuid)
        ) {
          this.value = { ...this.location };
        }
      },
      deep: true,
    },
    value: {
      handler() {
        this.$emit('update:location', this.value);
      },
      deep: true,
    },
  },
  methods: {
    createSpecificInformations() {
      if (this.currentConcept && this.currentConcept.uuid) {
        this.$emit('add', this.getUpdatablePlatforms, this.currentConcept);
      } else {
        this.$buefy.modal.open({
          component: MenuItemConceptSelector,
          parent: this,
          hasModalCard: true,
          props: { concepts: this.menuItemConcepts },
          events: {
            close: (selectedConcept) => this.$emit('add', this.getUpdatablePlatforms, selectedConcept),
          },
        });
      }
    },
    remove(locationUuuid) {
      this.$buefy.dialog.confirm({
        title: `Remove ${this.currentLocation.id} information`,
        message: `You will remove all informations for all concepts for  ${this.currentLocation.id}. This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('remove', locationUuuid),
      });
    },
  },
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
    }),
    isParentLocation() {
      if (!this.currentLocation.parent_uuid) {
        return true;
      }
      return false;
    },
    isCurrentLocationInMenuItem() {
      var menuItemLocations = this.currentMenuItem.location_uuids || [];
      return menuItemLocations.includes(this.currentLocation.uuid);
    },
    isParentLocationInMenuItem() {
      var menuItemLocations = this.currentMenuItem.location_uuids || [];
      return this.currentLocation.parent_uuid && menuItemLocations.includes(this.currentLocation.parent_uuid);
    },
    menuItemParentInformations() {
      return (
        this.currentMenuItem.locations.find((location) => location.uuid === this.currentLocation.parent_uuid) || {}
      );
    },
    isActive() {
      if (this.location && this.location.uuid) {
        return true;
      }
      return false;
    },
    menuItemConcepts() {
      if (!this.concepts || !this.currentMenuItem.concept_uuids) {
        return [];
      }
      return this.concepts.filter((concept) => this.currentMenuItem.concept_uuids.includes(concept.uuid));
    },
    ...mapGetters('platforms', ['getUpdatablePlatforms']),
  },
};
</script>

<style scoped>
.form {
  margin-top: 16px;
}
</style>
