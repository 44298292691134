import actionsApi from '@/apis/purchaseOrders/actions';
import actionPlansApi from '@/apis/purchaseOrders/actionPlans';
import { actionsTypes } from '@/store/mutation-types';

export default {
  namespaced: true,
  state: {
    actions: null,
    fetchActions: {
      pending: false,
      error: null,
    },
    actionPlans: null,
    actionPlansTotal: null,
    fetchActionPlans: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [actionsTypes.FETCH_ACTIONS](state, value) {
      state.fetchActions.pending = value;
    },
    [actionsTypes.FETCH_ACTIONS_SUCCESS](state, actions) {
      state.actions = actions;
    },
    [actionsTypes.FETCH_ACTIONS_ERROR](state, error) {
      state.fetchActions.error = error;
    },
    [actionsTypes.FETCH_ACTION_PLANS](state, value) {
      state.fetchActions.pending = value;
    },
    [actionsTypes.FETCH_ACTION_PLANS_SUCCESS](state, { actionPlans, total }) {
      state.actionPlans = actionPlans;
      state.actionPlansTotal = total;
    },
    [actionsTypes.FETCH_ACTION_PLANS_ERROR](state, error) {
      state.fetchActionPlans.error = error;
    },
  },
  actions: {
    async fetchActions({ commit }, params) {
      try {
        commit(actionsTypes.FETCH_ACTIONS, true);

        const actions = await actionsApi.fetchActions(params);

        commit(actionsTypes.FETCH_ACTIONS_SUCCESS, actions);
      } catch (err) {
        commit(actionsTypes.FETCH_ACTIONS_ERROR, err);
      } finally {
        commit(actionsTypes.FETCH_ACTIONS, false);
      }
    },
    async fetchActionPlans({ commit }, params) {
      try {
        commit(actionsTypes.FETCH_ACTION_PLANS, true);

        const { items: actionPlans, total } = await actionPlansApi.fetchActionPlans(params);

        commit(actionsTypes.FETCH_ACTION_PLANS_SUCCESS, { actionPlans, total });
        return { actionPlans, total };
      } catch (err) {
        commit(actionsTypes.FETCH_ACTION_PLANS_ERROR, err);
      } finally {
        commit(actionsTypes.FETCH_ACTION_PLANS, false);
      }
    },
    createActionPlan(_context, body) {
      return actionPlansApi.createActionPlan(body);
    },

    createActionPlanStep(_context, { planUuid, actionUuid, executionRule, step }) {
      return actionPlansApi.createActionPlanStep(planUuid, {
        action_uuid: actionUuid,
        execution_rule: executionRule,
        step,
      });
    },
    deleteActionPlan(_context, planUuid) {
      return actionPlansApi.deleteActionPlan(planUuid);
    },
  },
};
