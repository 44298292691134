import Vue from 'vue';

import { PlatformsApi } from '@tasterkitchens/kitchens-manager-v2-client';

const platformsApi = new PlatformsApi({
  accessToken: () => Vue.prototype.$auth.getToken(),
  isJsonMime: () => true,
  basePath: process.env.VUE_APP_KITCHENS_ENDPOINT,
});

export default {
  fetchAll() {
    return platformsApi.listPlatforms();
  },
};
