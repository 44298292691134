import clientHook from '@/apis';
const ItemsManagerClient = require('@tasterkitchens/items-manager-client-v2');

const modifierGroupsApi = new ItemsManagerClient.ModifierGroupsApi();
modifierGroupsApi.apiClient.basePath = process.env.VUE_APP_ITEMS_ENDPOINT;

export default {
  buildOne() {
    return new ItemsManagerClient.ModifierGroupCreationRequest('');
  },
  fetchAll() {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsListModifierGroups();
  },
  fetchOne(modifierGroupUuid) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsGetModifierGroup(modifierGroupUuid);
  },
  createOne(modifierGroup) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsCreateModifierGroup(modifierGroup);
  },
  updateOne(modifierGroupUuid, modifierGroup) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsUpdateModifierGroup(modifierGroupUuid, modifierGroup);
  },
  addLocation(uuid, locationUuid) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsAddModifierGroupLocations(uuid, [
      { uuid: locationUuid },
    ]);
  },
  removeLocation(uuid, locationUuid) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsRemoveModifierGroupLocation(uuid, locationUuid);
  },
  updateLocation(uuid, locationUuid, location) {
    return clientHook(modifierGroupsApi).appApisV1ModifierGroupsUpdateModifierGroupLocation(
      uuid,
      locationUuid,
      location
    );
  },
};
