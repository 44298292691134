<template>
  <div>
    <div>
      <b-loading :active.sync="isLoading"></b-loading>
      <h2 class="subtitle">Information</h2>
      <hr />
    </div>
    <!-- Basic info inputs -->
    <div>
      <b-field grouped>
        <b-field label="Label" expanded label-position="on-border">
          <b-input name="label" placeholder="Label" expanded v-model="newConcept.label" disabled />
        </b-field>
        <b-field label="ID" expanded label-position="on-border">
          <b-input name="id" placeholder="ID" expanded v-model="newConcept.id" disabled />
        </b-field>
      </b-field>
    </div>
    <!-- Description inputs -->
    <ConceptFormDescription :description.sync="description" />
    <!-- Form buttons -->
    <div class="form-buttons" v-if="isItemMenuEditor()">
      <b-button @click="cancelForm"> Cancel </b-button>
      <b-button
        type="is-primary"
        icon-left="content-save"
        style="margin-left: 8px"
        @click="$emit('saveConcept', newConcept)"
      >
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConceptFormDescription from '@/components/Menu/Concept/ConceptFormDescription.vue';
import { auth } from '@/mixins';

export default {
  name: 'ConceptForm',
  mixins: [auth],
  components: {
    ConceptFormDescription,
  },
  props: {
    concept: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newConcept: { ...this.concept },
      initialConcept: { ...this.concept },
    };
  },
  watch: {
    concept(newValue, oldValue) {
      if (newValue.uuid !== oldValue.uuid) {
        this.newConcept = { ...newValue };
      }
    },
    newConcept: {
      handler() {
        this.$emit('update:concept', this.newConcept);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.concepts.updateOne.pending,
    }),
    description: {
      get() {
        return this.newConcept.description;
      },
      set(value) {
        this.newConcept.description = value;
      },
    },
  },
  methods: {
    cancelForm() {
      this.newConcept = { ...this.initialConcept };
    },
  },
};
</script>

<style lang="scss" scoped>
.field-expanded {
  width: 100%;
}

.form-buttons {
  text-align: right;
  padding-top: 10px;
}
</style>
