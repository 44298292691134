<template>
  <b-field :label="this.label">
    <b-checkbox-button v-model="selectedDays" native-value="Monday" type="is-taster-bright-pink">
      <span>M</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Tuesday" type="is-taster-bright-pink">
      <span>T</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Wednesday" type="is-taster-bright-pink">
      <span>W</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Thursday" type="is-taster-bright-pink">
      <span>T</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Friday" type="is-taster-bright-pink">
      <span>F</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Saturday" type="is-taster-bright-pink">
      <span>S</span>
    </b-checkbox-button>
    <b-checkbox-button v-model="selectedDays" native-value="Sunday" type="is-taster-bright-pink">
      <span>S</span>
    </b-checkbox-button>
  </b-field>
</template>

<script>
export default {
  props: ['label', 'days'],
  computed: {
    selectedDays: {
      get() {
        return [...this.days];
      },
      set(days) {
        this.$emit('update', days);
      },
    },
  },
};
</script>
