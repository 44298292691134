import clientHook from '@/apis';

const GatewaySupplyClient = require('@tasterkitchens/gateway-supply-client');

const outOfStocksApi = new GatewaySupplyClient.OutOfStocksApi();
outOfStocksApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const substituteRulesApi = new GatewaySupplyClient.SubstituteRulesApi();
substituteRulesApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const localizedProductsApi = new GatewaySupplyClient.LocalizedProductsApi();
localizedProductsApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

export default {
  fetchSubstituteRules(locationUuid, queryParams) {
    return clientHook(substituteRulesApi).listSubstituteRules(locationUuid, queryParams);
  },
  updateSubstituteRules(body) {
    return clientHook(substituteRulesApi).createOrUpdateSubstituteRule(body);
  },
  fetchProductSubstitutes(locationUuid, queryParams) {
    return clientHook(localizedProductsApi).listLocalizedProducts(locationUuid, queryParams);
  },
  enableOutOfStock(body) {
    return clientHook(outOfStocksApi).enableOutOfStocks(body);
  },
  disableOutOfStock(body) {
    return clientHook(outOfStocksApi).disableOutOfStocks(body);
  },
  searchOutOfStocks(body) {
    return clientHook(outOfStocksApi).searchOutOfStocks(body);
  },
};
