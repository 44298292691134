import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import EditMenuItem from '../views/MenuItem/EditMenuItem.vue';
import ListMenuItems from '../views/MenuItem/ListMenuItems.vue';
import EditCombo from '../views/Combo/EditCombo.vue';
import ListSuppliers from '../views/Supplier/ListSuppliers.vue';
import ListProducts from '../views/Product/ListProducts.vue';
import ShowSupplier from '../views/Supplier/ShowSupplier.vue';
import ShowHub from '../views/Hub/ShowHub.vue';
import EditComposition from '../views/Composition/EditComposition.vue';
import ListHealthchecks from '../views/Healthchecks/ListHealthchecks.vue';
import ListItems from '../views/Items/ListItems.vue';
import ShowItem from '../views/Items/ShowItem.vue';
import ShowProduct from '../views/Product/ShowProduct.vue';
import MenuIndex from '../views/Menu/MenuIndex.vue';
import EditMenu from '../views/Menu/EditMenu.vue';
import NewMenu from '../views/Menu/NewMenu.vue';
import ListHubs from '../views/Hub/ListHubs.vue';
import Main from '../views/Main';
import Unauthorized from '../views/Unauthorized';
import roles from './roles';
import store from '@/store';

import { locationsTypes } from '../store/mutation-types.js';

Vue.use(VueRouter);

let redirect_to = null;

const isAuthorized = (authorize) => {
  if (!authorize.length) {
    return true;
  } else if (Vue.prototype.$auth.getUserRoles().filter((value) => authorize.includes(value)).length) {
    return true;
  }
  return false;
};

const isAuthenticated = (to, from, next) => {
  var { authorize } = to.meta;

  if (!Vue.prototype.$auth.isAuthenticated) {
    Vue.prototype.$auth.signIn().then(() => {
      if (isAuthorized(authorize)) {
        return next();
      }
      return next('Unauthorized');
    });
  } else {
    Vue.prototype.$errorReporting.setUser(Vue.prototype.$auth.user.sub);
    if (isAuthorized(authorize)) {
      return next();
    }
    return next('Unauthorized');
  }
};

const updateStoreLocation = (to, from, next) => {
  if (to && to.query && to.query.locationUuid) {
    store.watch(
      (state) => state.locations.all,
      (value) => {
        var queryLocation = [...value, ...value.map((l) => l.locations)]
          .flat()
          .find((l) => l.uuid == to.query.locationUuid);
        if (queryLocation) {
          store.commit(`locations/${locationsTypes.SET_CURRENT_LOCATION}`, queryLocation);
        }
      }
    );
  }
  next();
};

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) => {
          next(redirect_to && redirect_to.path !== '/' ? redirect_to : { name: 'homepage' });
        },
        meta: { authorize: [roles.itemsManagerViewer] },
      },
      {
        name: 'homepage',
        path: '/homepage',
        component: Home,
        meta: { authorize: [roles.itemsManagerViewer] },
      },
      {
        name: 'menu-items',
        path: '/menu-items',
        component: ListMenuItems,
        meta: { authorize: [roles.itemConceptsViewer] },
      },
      {
        path: '/menu-items/:menuItemId/edit',
        component: EditMenuItem,
        beforeEnter: updateStoreLocation,
        meta: { authorize: [roles.itemConceptsViewer] },
      },
      {
        path: '/combos/:comboId/edit',
        component: EditCombo,
        beforeEnter: updateStoreLocation,
        meta: { authorize: [roles.itemConceptsViewer] },
      },
      {
        path: '/items',
        component: ListItems,
        meta: { authorize: [roles.itemRecipesViewer] },
      },
      {
        path: '/items/:itemId',
        component: ShowItem,
        beforeEnter: updateStoreLocation,
        meta: { authorize: [roles.itemRecipesViewer] },
      },
      {
        path: '/suppliers',
        component: ListSuppliers,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/suppliers/:supplierId',
        component: ShowSupplier,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/products',
        component: ListProducts,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/products/:productId',
        component: ShowProduct,
        beforeEnter: updateStoreLocation,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/hubs',
        component: ListHubs,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/hubs/:hubId',
        component: ShowHub,
        meta: { authorize: [roles.itemProductEditor] },
      },
      {
        path: '/composition/:menuItemId',
        component: EditComposition,
        beforeEnter: updateStoreLocation,
        meta: { authorize: [roles.itemRecipesViewer] },
      },
      {
        path: '/healthchecks',
        component: ListHealthchecks,
        meta: { authorize: [roles.itemHealthchecksViewer] },
      },
      {
        path: '/platforms',
        component: MenuIndex,
        children: [
          {
            path: 'new',
            name: 'NewMenu',
            component: NewMenu,
            meta: { authorize: [roles.itemMenuViewer] },
          },
          {
            path: ':conceptUuid?',
            name: 'EditMenu',
            component: EditMenu,
            meta: { authorize: [roles.itemMenuViewer] },
          },
        ],
      },
    ],
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: { authorize: [] },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(isAuthenticated);

export default router;
