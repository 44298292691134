import Vue from 'vue';

export const clientHook = function (client) {
  // To avoir Refused to set unsafe header "User-Agent" error from browser
  client.apiClient.defaultHeaders = {};
  client.apiClient.timeout = 120000;
  if (Object.keys(client.apiClient.authentications).includes('oauth2')) {
    client.apiClient.authentications['oauth2']['accessToken'] = Vue.prototype.$auth.getToken();
  }

  if (client.apiClient.authentications.OAuth2) {
    client.apiClient.authentications.OAuth2['accessToken'] = Vue.prototype.$auth.getToken();
  }
  return client;
};

export default clientHook;
