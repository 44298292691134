import Vue from 'vue';

import { RestaurantsApi } from '@tasterkitchens/kitchens-manager-v2-client';

const restaurantsApi = new RestaurantsApi({
  accessToken: () => Vue.prototype.$auth.getToken(),
  isJsonMime: () => true,
  basePath: process.env.VUE_APP_KITCHENS_ENDPOINT,
});

export default {
  fetchByConcept(conceptUuid) {
    return restaurantsApi.listRestaurants(
      conceptUuid,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      true
    );
  },
  fetchAll() {
    return restaurantsApi.listRestaurants();
  },
};
