import locationsAPI from '@/apis/kitchens/locations';
import { locationsTypes } from '../mutation-types.js';
import { keyBy } from 'lodash';

export default {
  namespaced: true,
  state: {
    all: [],
    allUnnested: [],
    current: null,
    fetchAll: {
      pending: false,
      error: null,
    },
    fetchAllUnnested: {
      pending: false,
      error: null,
    },
    fetchOne: {
      pending: false,
      error: null,
    },
  },
  mutations: {
    [locationsTypes.FETCH_ALL_SUCCESS](state, locations) {
      state.all = locations;
      state.fetchAll.pending = false;
    },
    [locationsTypes.FETCH_ALL_FAILURE](state, error) {
      state.all = null;
      state.fetchAll.pending = false;
      state.fetchAll.error = error;
    },
    [locationsTypes.FETCH_ALL_REQUEST](state) {
      state.fetchAll.pending = true;
    },
    [locationsTypes.FETCH_ALL_UNNESTED_SUCCESS](state, locations) {
      state.allUnnested = locations;
      state.fetchAllUnnested.pending = false;
    },
    [locationsTypes.FETCH_ALL_UNNESTED_FAILURE](state, error) {
      state.allUnnested = null;
      state.fetchAllUnnested.pending = false;
      state.fetchAllUnnested.error = error;
    },
    [locationsTypes.FETCH_ALL_UNNESTED_REQUEST](state) {
      state.fetchAllUnnested.pending = true;
    },
    [locationsTypes.FETCH_ONE_SUCCESS](state, location) {
      state.current = location;
      state.fetchOne.pending = false;
    },
    [locationsTypes.FETCH_ONE_FAILURE](state, error) {
      state.current = null;
      state.fetchOne.pending = false;
      state.fetchOne.error = error;
    },
    [locationsTypes.FETCH_ONE_REQUEST](state) {
      state.fetchOne.pending = true;
    },
    [locationsTypes.SET_CURRENT_LOCATION](state, location) {
      state.current = location;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit(locationsTypes.FETCH_ALL_REQUEST);

      return locationsAPI
        .fetchAll()
        .then(({ data }) => commit(locationsTypes.FETCH_ALL_SUCCESS, data))
        .catch((error) => commit(locationsTypes.FETCH_ALL_FAILURE, error));
    },
    async fetchAllUnnested({ commit }) {
      commit(locationsTypes.FETCH_ALL_UNNESTED_REQUEST);

      return locationsAPI
        .fetchAllUnnested()
        .then(({ data }) => commit(locationsTypes.FETCH_ALL_UNNESTED_SUCCESS, data))
        .catch((error) => commit(locationsTypes.FETCH_ALL_UNNESTED_FAILURE, error));
    },
    async fetchOne({ commit }, id) {
      commit(locationsTypes.FETCH_ONE_REQUEST);

      return locationsAPI
        .fetchOne(id)
        .then(({ data }) => commit(locationsTypes.FETCH_ONE_SUCCESS, data))
        .catch((error) => commit(locationsTypes.FETCH_ONE_FAILURE, error));
    },
  },
  getters: {
    getLanguageCode: (state) => state.current.location_country.language_code.taster,
    getLanguageCodeByPlatform: (state) => (platformId) => state.current.location_country.language_code[platformId],
    locationsKeyByUuid: (state) => keyBy(state.allUnnested, 'uuid'),
    getLocationByUuid: (_state, getters) => (locationUuid) => getters.locationsKeyByUuid[locationUuid],
  },
};
