<template>
  <div style="display: flex">
    <div style="padding-top: 13px">
      <b-icon :icon="open ? 'menu-down' : 'menu-right'"> </b-icon>
    </div>
    <div class="category-header">
      <div class="category-title">
        <EditableTitle :title.sync="categoryTitle" />
        <b-button
          size="is-small"
          outlined
          type="is-danger"
          :class="{ actionsActive: active, actionsHidden: !active }"
          @click="$emit('delete')"
          icon-right="delete"
          v-if="isItemMenuEditor()"
        />
      </div>
      <div class="category-subtitle">
        <EditableTitle :title.sync="categoryDescription" />
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/mixins';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import EditableTitle from '@/components/EditableTitle.vue';

export default {
  name: 'MenuDetailsCategoryHeader',
  mixins: [auth],
  components: { EditableTitle },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
    },
  },
  data() {
    return {
      newCategory: cloneDeep(this.category),
    };
  },
  watch: {
    category() {
      this.newCategory = cloneDeep(this.category);
    },
  },
  computed: {
    ...mapGetters('locations', ['getLanguageCode']),
    categoryTitle: {
      get() {
        const title = this.newCategory.labels.find(({ language_code }) => language_code === this.getLanguageCode);

        return title ? title.translation : '';
      },
      set(value) {
        this.newCategory.labels.find(({ language_code }) => language_code === this.getLanguageCode).translation = value;

        this.$emit('update:category', this.newCategory);
      },
    },
    categoryDescription: {
      get() {
        const description = this.newCategory.description.find(
          ({ language_code }) => language_code === this.getLanguageCode
        );

        return description ? description.translation : '';
      },
      set(value) {
        const description = this.newCategory.description.find(
          ({ language_code }) => language_code === this.getLanguageCode
        );
        description
          ? (description.translation = value)
          : this.newCategory.description.push({ language_code: this.getLanguageCode, translation: value });

        this.$emit('update:category', this.newCategory);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.category-header {
  padding: 10px;
  width: 100%;

  .category-title {
    font-weight: 600;
    font-size: 20px;

    display: flex;
    justify-content: space-between;
  }

  .category-subtitle {
    margin-top: 5px;
    font-weight: 400;
    font-size: 18px;
    min-height: 18px;
  }

  .label-input {
    font-size: 20px;
    font-weight: 600;
  }
  .desc-input {
    font-size: 18px;
    font-weight: 400;
  }
}
.actionsActive {
  visibility: visible;
}
.actionsHidden {
  visibility: hidden;
}
</style>
