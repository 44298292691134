<template>
  <div class="box modal-card">
    <h3 class="subtitle">Edit Hub</h3>
    <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching this hub. </b-message>
    <div v-else>
      <b-loading :active.sync="isFetchLoading"></b-loading>
      <HubForm
        v-if="hub && hub.uuid"
        v-model="hub"
        :parent-localized-information="parentLocalizedInformation"
        :current-location="currentLocation"
        :is-fetching-localized-info="isKitchenSpecificInformationsLoading"
        :is-saving="isSaveLoading"
        :tags="tags"
        :showKitchensSpecific="isSupplyAdmin"
        :hasAutomaticEdiIntegration.sync="automaticEdiIntegration"
        :showAutomaticEdiIntegration="isAutomaticEdiIntegrationContext"
        :automaticEdiIntegrationHandler="automaticEdiIntegrationHandler"
        @on-change:location="onChangeLocation"
        @save="update"
        @cancel="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import HubForm from '@/components/Hub/HubForm.vue';
import { errorToaster } from '@/mixins';
import { hubsTypes } from '@/store/mutation-types';

export default {
  name: 'EditHub',
  components: { HubForm },
  mixins: [errorToaster],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      actionToUse: null,
      currentEditedLocation: null,
      isAutomaticEdiIntegrationContext: false,
      isSaveLoading: false,
      isKitchenSpecificInformationsLoading: false,
      automaticEdiIntegrationHandler: {},
    };
  },
  computed: {
    ...mapState({
      actions: (state) => {
        const hash = {};
        state.actions.actions.items.forEach((item) => {
          hash[item.uuid] = item;
        });

        return hash;
      },
      tags: (state) => state.hubs.tags,
      isFetchingLocalizedInfo: (state) => state.hubs.fetchLocalizedInformation.pending,
      fetchError: (state) => state.hubs.fetchOne.error,
      saveError: (state) => state.hubs.updateOne.error,
      currentLocation: (state) => state.locations.current,
      currentHub: (state) => state.hubs.current,
    }),
    automaticEdiIntegration: {
      get() {
        if (this.currentEditedLocation && this.automaticEdiIntegrationHandler[this.currentEditedLocation]) {
          const { updatedValue, initialValue } = this.automaticEdiIntegrationHandler[this.currentEditedLocation];

          return updatedValue ?? initialValue;
        }
        return false;
      },
      set(updatedValue) {
        this.automaticEdiIntegrationHandler = {
          ...this.automaticEdiIntegrationHandler,
          [this.currentEditedLocation]: {
            ...this.automaticEdiIntegrationHandler[this.currentEditedLocation],
            updatedValue,
          },
        };
      },
    },
    hub: {
      get() {
        return this.currentHub;
      },
      set(value) {
        this.setCurrentHub(value);
      },
    },
    isFetchLoading() {
      return this.$store.state.hubs.fetchOne.pending;
    },
    isSupplyAdmin() {
      return this.$auth.getUserRoles().includes('Supply Admin');
    },
    parentLocalizedInformation() {
      if (this.hub.parentLocalizedInformation) {
        let { location_uuid, available_for_ordering, use_translation_with_provider } =
          this.hub.parentLocalizedInformation;
        let parentLocation = this.currentHub.localized_informations.find(
          (info) => info.location_uuid === location_uuid
        );
        if (available_for_ordering !== undefined) {
          parentLocation.available_for_ordering = available_for_ordering;
        }
        if (use_translation_with_provider != undefined) {
          parentLocation.use_translation_with_provider = use_translation_with_provider;
        }
      }
      return this.currentHub.localized_informations
        ? this.currentHub.localized_informations.find(({ location_uuid }) => {
            return location_uuid === this.currentLocation.uuid && this.currentLocation.parent_uuid === null;
          })
        : null;
    },
  },
  mounted() {
    if (this.$store.state.hubs.tags === null || this.$store.state.hubs.tags.length == 0) {
      this.fetchTags();
    }

    this.fetchOne({ uuid: this.id }).then(() => {
      this.getLocalizedInfo(this.currentLocation.uuid);
      this.setAutomaticEdiIntegrationContext().then(() => {
        if (!this.isAutomaticEdiIntegrationContext) {
          return;
        }
        Promise.all([this.fetchActions(), this.setHasAutomaticEdiIntegration(this.currentLocation.uuid)]);
      });
    });
  },
  methods: {
    ...mapActions('hubs', [
      'fetchOne',
      'updateOne',
      'fetchTags',
      'fetchLocalizedInformation',
      'addLocalizedInformation',
      'updateLocalizedInformation',
    ]),
    ...mapActions('actions', [
      'fetchActionPlans',
      'fetchActions',
      'createActionPlan',
      'createActionPlanStep',
      'deleteActionPlan',
    ]),
    ...mapMutations('hubs', { setCurrentHub: hubsTypes.SET_CURRENT_HUB }),
    async getLocalizedInfo(locationUuid) {
      try {
        this.currentEditedLocation = locationUuid;

        return await this.fetchLocalizedInformation({ hubUuid: this.id, locationUuid });
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: 'An error happened while fetching localized information',
        });
      }
    },
    async onChangeLocation(locationUuid) {
      try {
        this.isKitchenSpecificInformationsLoading = true;
        await this.getLocalizedInfo(locationUuid);

        if (!this.isAutomaticEdiIntegrationContext) {
          return;
        }

        await this.setHasAutomaticEdiIntegration(locationUuid);
      } finally {
        this.isKitchenSpecificInformationsLoading = false;
      }
    },
    async setAutomaticEdiIntegrationContext() {
      try {
        const { total, actionPlans } = await this.fetchActionPlans({
          deliverUuid: this.id,
          withSteps: true,
          perPage: 1,
          observedState: 'VALIDATED',
        });

        this.isAutomaticEdiIntegrationContext = total > 0;
        if (!this.isAutomaticEdiIntegrationContext) {
          return;
        }

        this.actionToUse = actionPlans[0].steps[0].action.uuid;
      } catch {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: 'An error happened while fetching action plans',
        });
      }
    },
    async setHasAutomaticEdiIntegration(locationUuid) {
      try {
        const { total, actionPlans } = await this.fetchActionPlans({
          deliverUuid: this.id,
          locationUuid,
          withSteps: true,
          perPage: 1,
          observedState: 'VALIDATED',
        });

        if (!this.automaticEdiIntegrationHandler[locationUuid]) {
          this.automaticEdiIntegrationHandler = {
            ...this.automaticEdiIntegrationHandler,
            [locationUuid]: {
              initialValue: total >= 1 && actionPlans && actionPlans[0].steps.length >= 1,
              updatedValue: null,
              actionPlanUuid: actionPlans.length ? actionPlans[0].uuid : null,
            },
          };
        }
      } catch {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          message: 'An error happened while activating automatic EDI integration',
        });
      }
    },
    async activateAutomaticEdiIntegration(locationUuid) {
      const { uuid: actionUuid } = this.actions[this.actionToUse];
      const { uuid } = await this.createActionPlan({
        deliver_uuid: this.id,
        location_uuid: locationUuid,
        observed_state: 'VALIDATED',
      });

      if (actionUuid) {
        await this.createActionPlanStep({
          planUuid: uuid,
          actionUuid,
          executionRule: 'AUTOMATIC',
          step: 1,
        });
      }
    },
    async saveAutomaticEdiIntegration() {
      const results = await Promise.allSettled(
        Object.entries(this.automaticEdiIntegrationHandler).map(
          async ([locationUuid, { initialValue, updatedValue, actionPlanUuid }]) => {
            if (updatedValue === null || updatedValue === initialValue) {
              return;
            }

            try {
              updatedValue
                ? await this.activateAutomaticEdiIntegration(locationUuid)
                : await this.deleteActionPlan(actionPlanUuid);
            } catch {
              const { label } = !this.currentLocation.parent_uuid
                ? this.currentLocation.locations.find(({ uuid }) => uuid === locationUuid)
                : this.currentLocation.label;

              throw { label, isActivation: updatedValue };
            }
          }
        )
      );

      const { locationsErrorsActivate, locationsErrorsDelete } = results
        .filter(({ status }) => status === 'rejected')
        .reduce(
          (errors, { reason }) => {
            const { isActivation, label } = reason;
            isActivation ? errors.locationsErrorsActivate.push(label) : errors.locationsErrorsDelete.push(label);
            return errors;
          },
          { locationsErrorsActivate: [], locationsErrorsDelete: [] }
        );

      if (locationsErrorsActivate.length || locationsErrorsDelete.length) {
        const message = `An error happened while
        ${
          locationsErrorsActivate.length
            ? `activating automatic EDI integration for ${locationsErrorsActivate.join(',')}`
            : ''
        }
        ${
          locationsErrorsDelete.length
            ? `disabling automatic EDI integration for ${locationsErrorsDelete.join(',')}`
            : ''
        }`;

        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 6000,
          message,
        });

        throw new Error('Error while activating or disabling automatic EDI integration');
      }
    },
    async saveLocalizedInformation(localizedInfos) {
      this.hub = { ...this.hub, localized_informations: localizedInfos };

      // Creating or updating localized informations
      const results = await Promise.allSettled(
        localizedInfos.map(
          async ({
            account_code,
            contact_email,
            mails,
            location_uuid,
            update,
            use_translation_with_provider,
            available_for_ordering,
          }) => {
            // Skipping unupdated localized information
            if (update === undefined) {
              return null;
            }

            try {
              update
                ? await this.updateLocalizedInformation({
                    locationUuid: location_uuid,
                    hubUuid: this.hub.uuid,
                    payload: {
                      account_code,
                      contact_email,
                      mails,
                      use_translation_with_provider,
                      available_for_ordering,
                    },
                  })
                : await this.addLocalizedInformation({
                    hubUuid: this.hub.uuid,
                    payload: {
                      account_code,
                      contact_email,
                      mails,
                      location_uuid,
                      use_translation_with_provider,
                      available_for_ordering,
                    },
                  });
            } catch {
              const { label } = this.currentLocation.parent_uuid
                ? this.currentLocation
                : this.currentLocation.locations.find(({ uuid }) => uuid === location_uuid);

              throw label;
            }
          }
        )
      );

      const locationsErrorsLocalizedInformation = results
        .filter(({ status }) => status === 'rejected')
        .map(({ reason }) => reason);

      if (locationsErrorsLocalizedInformation.length) {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 6000,
          message: `An error happened while saving localized information for ${locationsErrorsLocalizedInformation.join(
            ','
          )}`,
        });
        throw new Error('An error happened while saving localized information');
      }
    },
    async update() {
      try {
        const parentLocalizedInformation = { ...this.hub.parentLocalizedInformation };
        delete this.hub.parentLocalizedInformation;
        this.isSaveLoading = true;
        const localizedInfos = Array.isArray(this.hub.localized_informations)
          ? [...this.hub.localized_informations]
          : [];

        await this.updateOne();
        if (parentLocalizedInformation) {
          const { use_translation_with_provider, available_for_ordering, location_uuid, update } =
            parentLocalizedInformation;
          if (update && location_uuid !== undefined) {
            await this.updateLocalizedInformation({
              hubUuid: this.hub.uuid,
              locationUuid: location_uuid,
              payload: { available_for_ordering, use_translation_with_provider },
            });
          } else if (location_uuid !== undefined) {
            await this.addLocalizedInformation({
              hubUuid: this.hub.uuid,
              payload: {
                location_uuid,
                available_for_ordering,
                use_translation_with_provider,
              },
            });
          }
        }
        await this.saveLocalizedInformation(localizedInfos);
        await this.saveAutomaticEdiIntegration();

        this.$emit('close');
      } finally {
        this.isSaveLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.box {
  min-width: 760px;
}
</style>
