<template>
  <div class="concept-form">
    <div class="header">
      <h1 class="title">Add Concept</h1>
    </div>
    <div>
      <h2 class="subtitle">Information</h2>
      <hr />
    </div>
    <div>
      <b-field grouped>
        <b-field label="ID" label-position="on-border" expanded>
          <b-input
            name="id"
            placeholder="ID"
            required
            v-model="newConcept.id"
            :minlength="validators.id.min"
            :maxlength="validators.id.max"
          />
        </b-field>
        <!-- <b-switch v-model="newConcept.is_active" style="align-items: start">
          Active
        </b-switch> -->
      </b-field>
      <b-field label="Label" label-position="on-border">
        <b-input
          name="label"
          placeholder="Label"
          required
          v-model="newConcept.label"
          :minlength="validators.label.min"
          :maxlength="validators.label.max"
        />
      </b-field>
      <b-field label="Category">
        <b-select placeholder="Select a category" required expanded v-model="newConcept.category">
          <option v-for="(conceptCategory, index) in conceptCategories" :key="index" :value="conceptCategory">
            {{ conceptCategory }}
          </option>
        </b-select>
      </b-field>
    </div>
    <!-- Form buttons -->
    <div class="form-buttons">
      <b-button @click="cancelForm"> Cancel </b-button>
      <b-button
        type="is-primary"
        icon-left="content-save"
        style="margin-left: 8px"
        :disabled="createDisabled"
        @click="openCreateConfirmationDialog"
      >
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewConceptForm',
  props: ['conceptCategories'],
  data() {
    return {
      initialConcept: {
        id: '',
        label: '',
        is_deleted: false,
      },
      newConcept: {
        id: '',
        label: '',
        is_deleted: false,
      },
      validators: {
        id: {
          min: 2,
          max: 20,
        },
        label: {
          min: 1,
          max: 20,
        },
      },
    };
  },
  computed: {
    createDisabled: function () {
      if (
        this.newConcept.label.length < this.validators.label.min ||
        this.newConcept.label.length > this.validators.label.max ||
        this.newConcept.id.length < this.validators.id.min ||
        this.newConcept.id.length > this.validators.id.max ||
        !this.newConcept.category
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    cancelForm() {
      this.newConcept = { ...this.initialConcept };
    },
    openCreateConfirmationDialog() {
      this.$buefy.dialog.confirm({
        title: 'Create Concept',
        message: `Are you sure you want to create a new concept titled <b>${this.newConcept.label}</b>?`,
        confirmText: 'Create',
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => this.$emit('newConcept', this.newConcept),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.concept-form {
  max-width: 500px;
  margin: auto;

  .header {
    text-align: center;
    align-items: center;
    padding: 20px 0 40px 0;
  }

  .form-buttons {
    text-align: right;
    padding-top: 10px;
  }
}
</style>
