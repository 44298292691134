import clientHook from '@/apis';
const PlatformsManagerClient = require('@tasterkitchens/platforms-manager-client-v2');

const platformMenusApi = new PlatformsManagerClient.PlatformMenusApi();
platformMenusApi.apiClient.basePath = process.env.VUE_APP_PLATFORMS_ENDPOINT;
platformMenusApi.apiClient.timeout = 120000;

export default {
  fetchStatuses(opts) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusGetRestaurantsMenusStatus(opts);
  },
  fetchStatus(restaurantUuid) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusGetRestaurantMenuStatus(restaurantUuid);
  },
  checkMenu(restaurantUuid) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusCheckMenu(restaurantUuid);
  },
  testMenu(restaurantUuid) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusTestMenu(restaurantUuid);
  },
  uploadMenu(restaurantUuid) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusPostMenu(restaurantUuid);
  },
  saveMenu(restaurantUuid) {
    return clientHook(platformMenusApi).appApisV1PlatformMenusSaveMenu(restaurantUuid);
  },
};
