<template>
  <div class="box modal-card">
    <div class="has-text-centered">
      <b-field label="Kitchen Source" expanded>
        <b-select type="text" v-model="selectedLocation" placeholder="Kitchen" expanded>
          <option v-for="(location, index) in getRelatedLocations()" :key="index" :value="location">
            {{ location.id }}
          </option>
        </b-select>
      </b-field>
    </div>
    <hr />
    <b-button type="is-info" style="margin-bottom: 4px" @click="run()" :disabled="selectedLocation === null" expanded>
      Copy Menu
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['currentLocation', 'locationsUnnested', 'conceptUUID'],
  data: () => ({
    selectedLocation: null,
  }),
  methods: {
    ...mapActions('menus', ['copyMenu']),
    getRelatedLocations() {
      var parentUuid = null;
      if (!this.currentLocation.parent_uuid) {
        parentUuid = this.currentLocation.uuid;
      } else {
        parentUuid = this.currentLocation.parent_uuid;
      }
      return this.locationsUnnested
        .filter((location) => location.parent_uuid == parentUuid)
        .sort((location1, location2) => location1.label.localeCompare(location2.label));
    },
    run() {
      this.$emit('copy', {
        conceptUuid: this.conceptUUID,
        locationUuidDestination: this.currentLocation.uuid,
        locationUuidSource: this.selectedLocation.uuid,
      });
      this.$parent.close();
    },
  },
  computed: {
    ...mapState({}),
  },
  mounted() {},
};
</script>

<style scoped>
.button-gap {
  margin-top: 10px;
}

.button-location-gap {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
