<template>
  <section class="section">
    <div class="container">
      <b-message type="is-danger" v-if="fetchError"> An error has occured while fetching the concepts list. </b-message>
      <div v-if="!fetchError">
        <b-loading :active.sync="isFetchLoading"></b-loading>

        <div class="tabs is-boxed">
          <ul>
            <li
              v-for="concept in conceptsSorted"
              :key="concept.uuid"
              @click="redirect(concept.uuid)"
              :class="{ 'is-active': activeTab === concept.uuid }"
            >
              <a>
                <img :src="concept.logo" style="height: 21px" />
                <span class="label-pos">{{ concept.label }}</span>
              </a>
            </li>
            <li @click="redirect()" :class="{ 'is-active': activeTab === 'new' }">
              <a>
                <span><b-icon icon="plus" size="is-small" /></span>
              </a>
            </li>
          </ul>
        </div>

        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { conceptsTypes } from '@/store/mutation-types';
import { errorToasterDetailled } from '@/mixins';

export default {
  name: 'MenuIndex',
  mixins: [errorToasterDetailled],
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    ...mapState({
      concepts: (state) => state.concepts.all,
      conceptsSorted() {
        return this.concepts.sort((concept1, concept2) => concept1.label.localeCompare(concept2.label));
      },
      isFetchLoading: (state) =>
        state.concepts.fetchAll.pending ||
        state.menuItems.fetchAll.pending ||
        state.combos.fetchAll.pending ||
        state.modifierGroups.fetchAll.pending ||
        state.menus.fetchAllGeneric.pending,
      fetchError: (state) => state.concepts.fetchAll.error,
      platforms: (state) => state.platforms.all,
      isSaveLoading: (state) => state.concepts.updateOne.pending || state.menus.updateOne.pending,
      saveError: (state) => state.concepts.updateOne.error || state.menus.updateOne.error,
    }),
    ...mapGetters('concepts', ['getConceptsCount']),
  },
  methods: {
    ...mapActions('concepts', { fetchAllConcepts: 'fetchAll' }),
    ...mapActions('platforms', { fetchAllPlatforms: 'fetchAll' }),
    ...mapActions('menuItems', { fetchMenuItems: 'fetchAll' }),
    ...mapActions('menus', { fetchGenericMenus: 'fetchAllGeneric' }),
    ...mapActions('combos', { fetchCombos: 'fetchAll' }),
    ...mapActions('modifierGroups', { fetchModifiers: 'fetchAll' }),
    ...mapActions('locations', { fetchAllLocations: 'fetchAll', fetchUnnestedLocations: 'fetchAllUnnested' }),
    ...mapMutations('concepts', { setCurrentConcept: conceptsTypes.SET_CURRENT_CONCEPT }),
    redirect(conceptUuid) {
      if (!conceptUuid && this.$route.name !== 'NewMenu') {
        this.$router.push('/platforms/new');
        this.activeTab = 'new';
      } else if (this.$route.params.conceptUuid !== conceptUuid) {
        if (conceptUuid) {
          this.$router.push('/platforms/' + conceptUuid);
          this.activeTab = conceptUuid;
          this.setCurrentConcept(this.concepts.find((concept) => concept.uuid === conceptUuid));
        }
      }
    },
    getConcepts() {
      if (this.$store.state.concepts.all === null || this.$store.state.concepts.all.length == 0) {
        return this.fetchAllConcepts();
      }
      var p = new Promise(function (resolve) {
        resolve('Success!');
      });
      return p;
    },
  },
  mounted() {
    this.getConcepts().then(() => {
      if (this.$route.name === 'NewMenu') {
        this.activeTab = 'new';
      } else {
        // apply the current tab if exists
        // else use the default tab
        const tabMatch = this.concepts.find((concept) => concept.uuid === this.$route.params.conceptUuid);
        this.activeTab = tabMatch ? tabMatch.uuid : this.concepts[0].uuid;
        this.setCurrentConcept(this.concepts.find((concept) => concept.uuid === this.activeTab));
      }
    });
    if (this.$store.state.platforms.all === null || this.$store.state.platforms.all.length == 0) {
      this.fetchAllPlatforms();
    }
    // fetch all elements needed to display menu
    if (this.$store.state.menuItems.all === null || this.$store.state.menuItems.all.length == 0) {
      this.fetchMenuItems();
    }
    if (this.$store.state.combos.all === null || this.$store.state.combos.all.length == 0) {
      this.fetchCombos();
    }
    if (this.$store.state.modifierGroups.all === null || this.$store.state.modifierGroups.all.length == 0) {
      this.fetchModifiers();
    }
    if (this.$store.state.locations.allUnnested === null || this.$store.state.locations.allUnnested.length == 0) {
      this.fetchUnnestedLocations();
    }
    if (this.$store.state.menus.allGeneric === null || this.$store.state.menus.allGeneric.length == 0) {
      this.fetchGenericMenus();
    }
  },
};
</script>

<style lang="scss" scoped>
.label-pos {
  margin-left: 5px;
}
</style>
