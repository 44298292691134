import { extend } from 'vee-validate';
import { min_value, required } from 'vee-validate/dist/rules';

extend('min_length', {
  validate(value, args) {
    if (!Array.isArray(value)) {
      return false;
    }

    return value.length >= args.length;
  },
  params: ['length'],
  message: 'This field should have at least have {length} items selected.',
});
extend('min_value', min_value);
extend('required', required);
