<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-5">Prices</h2>
      </div>
    </div>
    <b-tabs size="is-small" type="is-boxed" horizontal destroy-on-hide v-model="currentConceptIndex">
      <b-tab-item v-for="concept in concepts" :key="concept.uuid">
        <template slot="header">
          <img :src="concept.logo" width="21" />
          {{ concept.label }}
        </template>
        <div v-if="!areMenuItemInformationsInLocation">
          <b-button
            v-if="isCurrentLocationInMenuItem"
            icon-left="plus-circle"
            class="is-info"
            outlined
            @click="$emit('addPlatformInformations', concept.uuid)"
          >
            Create Prices for {{ concept.label }}
          </b-button>
          <b-message type="is-info" :closable="false" v-if="!isCurrentLocationInMenuItem">
            To edit prices in this location, you should "Create Specific Informations" with the button on top.
          </b-message>
          <div v-if="!isParentLocation && areMenuItemInformationsInParentLocation">
            <hr />
            <b-message type="is-warning" :closable="false"> Prices are inherited from Kitchen's Country. </b-message>
            <hr />
          </div>
          <div v-else>
            <hr />
            <b-message type="is-warning" :closable="false"> No information from Kitchen's Country. </b-message>
          </div>
        </div>
        <div class="columns" v-if="menuItemInformationsByConceptUuid && menuItemInformationsByConceptUuid.length > 0">
          <div class="column" v-if="platforms && platforms.length">
            <div class="columns item-row" v-for="platform in platforms" :key="platform.uuid">
              <div class="column" style="margin: auto">
                <span>
                  <img :src="platform.logo" width="21" />
                  {{ platform.label }}
                </span>
              </div>
              <div
                class="column is-narrow"
                v-if="Object.keys(menuItemInformationsByPlatformUuid).includes(platform.uuid)"
              >
                <MenuItemInformationsForm
                  :selling_price.sync="menuItemInformationsByPlatformUuid[platform.uuid].selling_price"
                  :unit.sync="menuItemInformationsByPlatformUuid[platform.uuid].selling_unit_price"
                  :updateDisabled="
                    menuItemInformationsByPlatformUuid[platform.uuid].location_uuid != currentLocation.uuid
                  "
                  @remove="$emit('removeOne', menuItemInformationsByPlatformUuid[platform.uuid].uuid)"
                  @save="updateOne(menuItemInformationsByPlatformUuid[platform.uuid])"
                />
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import MenuItemInformationsForm from '@/components/MenuItem/Platform/Informations/MenuItemInformationsForm.vue';

export default {
  components: { MenuItemInformationsForm },
  props: [
    'menuItemInformations',
    'platforms',
    'concepts',
    'currentLocation',
    'isParentLocation',
    'isCurrentLocationInMenuItem',
  ],
  data() {
    return {
      currentConceptIndex: 0,
    };
  },
  computed: {
    currentConcept() {
      return this.concepts[this.currentConceptIndex];
    },
    menuItemInformationsByConceptUuid() {
      return this.menuItemInformations.filter(
        (menuInformation) => menuInformation.concept_uuid == this.currentConcept.uuid
      );
    },
    menuItemInformationsByPlatformUuid() {
      const initialValue = {};
      return this.menuItemInformationsByConceptUuid.reduce((obj, info) => {
        return {
          ...obj,
          [info['platform_uuid']]: info,
        };
      }, initialValue);
    },
    areMenuItemInformationsInLocation() {
      if (
        this.menuItemInformationsByConceptUuid &&
        this.menuItemInformationsByConceptUuid.length > 0 &&
        this.platforms &&
        this.platforms.length
      ) {
        for (let info of Object.values(this.menuItemInformationsByPlatformUuid)) {
          if (info.location_uuid == this.currentLocation.uuid) {
            return true;
          }
        }
      }
      return false;
    },
    areMenuItemInformationsInParentLocation() {
      if (
        this.menuItemInformationsByConceptUuid &&
        this.menuItemInformationsByConceptUuid.length > 0 &&
        this.platforms &&
        this.platforms.length
      ) {
        for (let info of Object.values(this.menuItemInformationsByPlatformUuid)) {
          if (info.location_uuid == this.currentLocation.parent_uuid) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions('itemsInformations', ['updateOne', 'removeOne']),
  },
};
</script>

<style scoped>
.item-row:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}
</style>
