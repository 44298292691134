import Axios from 'axios';
import Vue from 'vue';

import clientHook from '@/apis';
const ItemsManagerClient = require('@tasterkitchens/items-manager-client-v2');

const menuItemsApi = new ItemsManagerClient.MenuItemsApi();
menuItemsApi.apiClient.basePath = process.env.VUE_APP_ITEMS_ENDPOINT;

const axios = Axios.create({
  baseURL: process.env.VUE_APP_ITEMS_ENDPOINT,
});

Axios.interceptors.request.use(
  (config) => {
    var accessToken = Vue.prototype.$auth.getToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers['Content-Type'] = 'application/json';
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ressourcePath = '/menu-items';

export default {
  fetchAll(opts) {
    return clientHook(menuItemsApi).appApisV1MenuItemsListMenuItems(opts);
  },
  fetchByConcept(conceptUUID) {
    return clientHook(menuItemsApi).appApisV1MenuItemsListMenuItems({ concept: conceptUUID });
  },
  fetchTodos(locationUuids, conceptUUID) {
    return clientHook(menuItemsApi).appApisV1MenuItemsGetMenuItemsTodo(locationUuids, { conceptUuid: conceptUUID });
  },
  removeFromConcept(conceptUUID, menuItemUUID) {
    return clientHook(menuItemsApi).appApisV1MenuItemsRemoveMenuItemConcept(menuItemUUID, conceptUUID);
  },
  createMenuItem(conceptUUID, menuItemLabel, menuItemCategory) {
    return clientHook(menuItemsApi).appApisV1MenuItemsCreateMenuItem({
      label: menuItemLabel,
      concept_uuid: conceptUUID,
      category: menuItemCategory,
    });
  },
  addMenuItemToConcept(conceptUUID, menuItemUUID) {
    // TODO: We should use clientHook(menuItemsApi), but for some reasons it's not working.
    const path = `${ressourcePath}/${menuItemUUID}/concepts`;
    return axios.patch(path, {
      concept_uuid: conceptUUID,
    });
  },
  fetchOne(uuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsGetMenuItem(uuid);
  },
  getRecipe(uuid, locationUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsGetMenuItemRecipe(uuid, locationUuid);
  },
  fetchModifierGroups(uuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsGetModifierGroups(uuid);
  },
  addModifierGroup(uuid, modifierGroupUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsAddMenuItemModifierGroup(uuid, {
      modifier_group_uuid: modifierGroupUuid,
    });
  },
  updateModifierGroup(uuid, modifierGroupUuid, body) {
    return clientHook(menuItemsApi).appApisV1MenuItemsUpdateMenuItemModifierGroup(uuid, modifierGroupUuid, body);
  },
  removeModifierGroup(uuid, modifierGroupUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsRemoveMenuItemModifierGroup(uuid, modifierGroupUuid);
  },
  updateOne(uuid, menuItem) {
    return clientHook(menuItemsApi).appApisV1MenuItemsUpdateMenuItem(uuid, menuItem);
  },
  removeItemFromRecipe(uuid, itemUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsRemoveMenuItemRecipe(uuid, itemUuid);
  },
  updateItemRecipe(uuid, itemUuid, item) {
    return clientHook(menuItemsApi).appApisV1MenuItemsUpdateMenuItemRecipe(uuid, itemUuid, item);
  },
  addItemToRecipe(uuid, item) {
    return clientHook(menuItemsApi).appApisV1MenuItemsAddMenuItemRecipe(uuid, item);
  },
  addLocation(uuid, locationUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsAddMenuItemLocations(uuid, [{ uuid: locationUuid }]);
  },
  removeLocation(uuid, locationUuid) {
    return clientHook(menuItemsApi).appApisV1MenuItemsRemoveMenuItemLocation(uuid, locationUuid);
  },
  updateLocation(uuid, locationUuid, location) {
    return clientHook(menuItemsApi).appApisV1MenuItemsUpdateMenuItemLocation(uuid, locationUuid, location);
  },
  uploadPicture(uuid, conceptUuid, picture, platformUuid, basewidth) {
    return clientHook(menuItemsApi).appApisV1MenuItemsUploadPicture(uuid, conceptUuid, picture, platformUuid, {
      basewidth,
    });
  },
  fetchCategories() {
    return clientHook(menuItemsApi).appUtilsUtilGenerateCategories();
  },
  fetchTags() {
    return clientHook(menuItemsApi).appUtilsUtilGenerateTags();
  },
};
