import clientHook from '@/apis';
const ItemsManagerClient = require('@tasterkitchens/items-manager-client-v2');

const itemsApi = new ItemsManagerClient.ItemsApi();
itemsApi.apiClient.basePath = process.env.VUE_APP_ITEMS_ENDPOINT;

export default {
  buildOne() {
    return {
      ...new ItemsManagerClient.ItemCreationRequest(),
      item_measure: [
        {
          quantity: null,
          unit: null,
        },
      ],
      mep: false,
    };
  },
  fetchAll() {
    return clientHook(itemsApi).appApisV1ItemsListItems();
  },
  fetchOne(uuid) {
    return clientHook(itemsApi).appApisV1ItemsGetItem(uuid);
  },
  fetchRecipe(uuid, locationUuids, { quantity, unit }) {
    return clientHook(itemsApi).appApisV1ItemsGetItemRecipe(uuid, locationUuids, { quantity, unit });
  },
  createOne(item) {
    return clientHook(itemsApi).appApisV1ItemsCreateItem(item);
  },
  updateOne(uuid, item) {
    return clientHook(itemsApi).appApisV1ItemsUpdateItem(uuid, item);
  },
  removeOne(uuid) {
    return clientHook(itemsApi).appApisV1ItemsDeleteItem(uuid);
  },
  addChild(uuid, child) {
    return clientHook(itemsApi).appApisV1ItemsAddChildToItem(uuid, child);
  },
  removeChild(uuid, child) {
    return clientHook(itemsApi).appApisV1ItemsRemoveChildFromItem(uuid, child);
  },
  updateChild(uuid, child) {
    return clientHook(itemsApi).appApisV1ItemsUpdateChildItem(uuid, child);
  },
  setProduct(uuid, product) {
    return clientHook(itemsApi).appApisV1ItemsSetProductToItem(uuid, product);
  },
  unsetProduct(uuid, product) {
    return clientHook(itemsApi).appApisV1ItemsUnsetProductToItem(uuid, product);
  },
  addLocation(uuid, locationUuid, itemLabelInLocation) {
    return clientHook(itemsApi).appApisV1ItemsAddLocationToItem(uuid, {
      uuid: locationUuid,
      item_label: itemLabelInLocation,
    });
  },
  createLocalizedItem(itemUuid, opts) {
    var itemCreateLocalizedChildrendRequest = {
      children_location_uuid: opts.childrenLocationUuid,
      parent_location_uuid: opts.parentLocationUuid,
    };
    return clientHook(itemsApi).appApisV1ItemsCreateLocalizedChildren(itemUuid, itemCreateLocalizedChildrendRequest);
  },
};
