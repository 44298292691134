<template>
  <div class="menu-notification">
    <!-- Create localized menu from an existing parent location menu -->
    <b-notification
      v-if="menuExists && currentLocation.uuid !== currentMenu.location_uuid"
      type="is-warning"
      has-icon
      role="alert"
      :closable="false"
    >
      <div>
        No Specific Menu exists for <b>{{ currentLocation.label }} !</b>
      </div>
      <div><br /></div>
      <div>You can either:</div>
      <div>
        - Move to <b>{{ parentLocation }} </b> location to edit <b>Global Menu .</b>
      </div>
      <div>
        - Create Specific Menu for <b>{{ currentLocation.label }}</b> clicking on "Create Menu" (All the modifications
        made on this new menu won't be reflected on <b>{{ parentLocation }} Menu</b>)
      </div>
      <button
        @click="$emit('addLocation', { genericMenuUuid: null })"
        class="button is-dark"
        style="margin-top: 20px; margin-right: 10px"
      >
        <b-icon icon="content-copy" type="is-warning"></b-icon>
        <span class="has-text-warning">Copy Menu from Country</span>
      </button>
      <button @click="openCopyMenuModal()" class="button is-dark" style="margin-top: 20px">
        <b-icon icon="content-copy" type="is-warning"></b-icon>
        <span class="has-text-warning">Copy Menu from Kitchen</span>
      </button>
    </b-notification>
    <!-- Create localized menu from an existing generic menu -->
    <b-notification
      v-else-if="!menuExists && genericMenuExists"
      type="is-warning"
      has-icon
      role="alert"
      :closable="false"
    >
      <div>A menu exists for this concept but is disabled on this location</div>
      <div>
        You can enable it for <b>{{ currentLocation.label }}</b
        >:
      </div>
      <button
        @click="$emit('addLocation', { genericMenuUuid: genericMenuUuid })"
        class="button is-dark"
        style="margin-top: 20px"
      >
        <b-icon icon="content-copy" type="is-warning"></b-icon>
        <span class="has-text-warning">Enable menu</span>
      </button>
    </b-notification>
    <!-- Create menu from scratch -->
    <b-notification
      v-else-if="!menuExists && !genericMenuExists"
      type="is-info"
      has-icon
      role="alert"
      :closable="false"
    >
      <div>No menu exists for this concept</div>
      <div>
        You can create one and enable it for <b>{{ currentLocation.label }}</b
        >:
      </div>
      <button @click="$emit('createMenu')" class="button is-white" style="margin-top: 20px">
        <b-icon icon="plus" type="is-info"></b-icon>
        <span class="has-text-info">Create menu</span>
      </button>
    </b-notification>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';

import CopyMenu from '@/components/Menu/MenuDetails/CopyMenu.vue';

export default {
  name: 'MenuDetailsAddMenu',
  computed: {
    ...mapState({
      concept: (state) => state.concepts.current,
      currentMenu: (state) => state.menus.current,
      locations: (state) => state.locations.all,
      locationsUnnested: (state) => state.locations.allUnnested,
      currentLocation: (state) => state.locations.current,
      menusByConcept: (state) => state.menus.all,
    }),
    menuExists() {
      return this.currentMenu && !isEmpty(this.currentMenu);
    },
    genericMenuExists() {
      if (!this.menusByConcept) {
        return {};
      }
      return this.menusByConcept.find((menu) => menu.concept_uuid === this.concept.uuid);
    },
    genericMenuUuid() {
      return this.menusByConcept.find((menu) => menu.concept_uuid === this.concept.uuid).uuid;
    },
    parentLocation() {
      const parentLocation = this.locations.find((parentLocation) =>
        parentLocation.locations.find((location) => location.uuid === this.currentLocation.uuid)
      );
      if (parentLocation) {
        return parentLocation.label;
      }
      return null;
    },
  },
  methods: {
    openCopyMenuModal() {
      this.$buefy.modal.open({
        component: CopyMenu,
        parent: this,
        hasModalCard: true,
        props: {
          currentLocation: this.currentLocation,
          locationsUnnested: this.locationsUnnested,
          conceptUUID: this.concept.uuid,
        },
        events: {
          copy: (opts) => this.$emit('copyMenu', opts),
        },
      });
    },
  },
};
</script>
