<template>
  <div id="app">
    <b-navbar shadow>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/images/logo.png" alt="Taster Logo" />
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/items' }"
          :active="$route.path.includes('/items')"
          v-if="displayRecipesTab()"
        >
          Recipes
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/products' }"
          :active="$route.path.includes('/products')"
          v-if="displayProductsTab()"
        >
          Products
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/suppliers' }"
          :active="$route.path.includes('/suppliers')"
          v-if="displaySuppliersTab()"
        >
          Suppliers
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/hubs' }"
          :active="$route.path.includes('/hubs')"
          v-if="displayHubsTab()"
        >
          Hubs
        </b-navbar-item>
        <!-- <b-navbar-item tag="router-link" :to="{ path: '/platforms' }" :active="$route.path.includes('/platforms')" v-if="displayPlatformsTab()">
          Platforms
        </b-navbar-item> -->
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/healthchecks' }"
          :active="$route.path.includes('/healthchecks')"
          v-if="displayHealthchecksTab()"
        >
          Healthchecks
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <LocationSelector />
        </b-navbar-item>
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import LocationSelector from '@/components/LocationSelector/LocationSelector.vue';
import roles from '@/router/roles';

export default {
  components: { LocationSelector },
  methods: {
    displayConceptsTab() {
      return this.$auth.getUserRoles().includes(roles.itemConceptsViewer);
    },
    displayHealthchecksTab() {
      return this.$auth.getUserRoles().includes(roles.itemHealthchecksViewer);
    },
    displayHubsTab() {
      return this.$auth.getUserRoles().includes(roles.itemProductEditor);
    },
    displayPlatformsTab() {
      return this.$auth.getUserRoles().includes(roles.itemMenuViewer);
    },
    displayProductsTab() {
      return this.$auth.getUserRoles().includes(roles.itemProductEditor);
    },
    displayRecipesTab() {
      return this.$auth.getUserRoles().includes(roles.itemRecipesViewer);
    },
    displaySuppliersTab() {
      return this.$auth.getUserRoles().includes(roles.itemProductEditor);
    },
  },
};
</script>

<style scoped>
.navbar-item.is-active {
  font-weight: 500;
  background-color: #fafafa !important;
}
</style>
